<template>
  <div>
    <v-row>
      <v-col :cols="12" :sm="6">
        <LeasingParticipant
          v-model:signatoryId="agreementData.creditorSignatoryId"
          v-model:basisId="agreementData.creditorBasisId"
          label="Кредитор"
          :typeId="2"
          :participant="agreementData.creditor"
          :readonly="readonly"
          :v$="{
            contactId: $v.agreement.creditorSignatoryId,
            basisId: $v.agreement.creditorBasisId,
          }"
          single />
      </v-col>
      <v-col :cols="12" :sm="6">
        <LeasingParticipant
          v-model:signatories="agreementData.guarantorSignatories"
          label="Поручитель"
          :readonly="readonly"
          :typeId="agreementData.guarantor.leasingClientType"
          :participant="agreementData.guarantor"
          :v$="$v.agreement.guarantorSignatories" />
      </v-col>
      <v-col style="margin-top: -10px" :cols="12" :sm="6">
        <v-checkbox
          v-model="agreementData.tripartiteAgreement"
          style="margin-left: 12px"
          class="pt-0"
          hide-details
          :true-value="false"
          :false-value="true"
          :readonly="readonly"
          color="#fc7247">
          <template #label>
            <span style="font-size: 12px">Двосторонній договір</span>
          </template>
        </v-checkbox>
        <LeasingParticipant
          v-model:signatories="agreementData.debtorSignatories"
          label="Боржник"
          :readonly="readonly"
          :typeId="agreementData.debtor.leasingClientType"
          :participant="agreementData.debtor"
          :v$="$v.agreement.debtorSignatories"
          :hideSignatory="!agreementData.tripartiteAgreement" />
      </v-col>
    </v-row>
    <Agreements
      v-if="$route.name === 'surety-parties-details'"
      class="mb-5"
      style="margin-left: 15px"
      :agreements="agreement.agreements" />
  </div>
</template>
<script>
import LeasingParticipant from '@/pages/contracts/components/leasingParticipant/LeasingParticipant.vue'
import Agreements from '@/components/Agreements.vue'
export default {
  components: { LeasingParticipant, Agreements },
  props: {
    agreement: { type: Object },
    v: { type: Object },
    guarantorContractor: { type: Boolean },
    guarantorContact: { type: Boolean },
    readonly: { type: Boolean },
  },
  computed: {
    agreementData() {
      return this.agreement
    },
    $v() {
      return this.v
    },
  },
  mounted() {},
}
</script>
