<template>
  <v-row>
    <v-col>
      <v-data-table
        v-if="tasks"
        style="padding-top: 10px"
        :headers="planHeader"
        :items="tasks"
        :custom-sort="$sortByDate"
        mobileBreakpoint="750"
        class="--cust-cell-pd"
        dense
        hide-default-footer>
        <!-- eslint-disable-next-line -->
        <template #item.date="{ item }">
          {{ toFormatDate(item.date) }}
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.user="{ item }">
          {{ getContactInitials(item.user) || '---' }}
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.isDone="{ item }">
          {{ item.isDone ? 'Завершено' : 'Не завершено' }}
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.action="{ item }">
          <TableCrud
            data-readonly="hide"
            :actionsArray="[
              {
                action: () => openEditDialog(item),
                text: 'Редагувати',
                icon: 'EditPencil',
                customIcon: true,
                disabled: readonly,
              },
              {
                action: () => deleteTask(item),
                text: 'Видалити',
                icon: 'mdi-close',
                disabled: readonly,
              },
            ]">
          </TableCrud>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import { urlTaskPlanTaskDelete } from '@/pages/request'
import {
  toFormatDate,
  getContactInitials,
  getResponsibleShortName,
} from '@/utils/helperFunc'
import TableCrud from '@/components/table-crud.vue'
export default {
  components: {
    TableCrud,
  },
  props: {
    tasks: { type: Array, default: () => [] },
    leadId: { type: Number, default: null },
    applicationId: { type: Number, default: null },
    contactId: { type: Number, default: null },
    contractorId: { type: Number, default: null },
    readonly: { type: Boolean, default: false },
  },
  data: () => ({
    planHeader: [
      { title: 'Статус', key: 'isDone', sortable: false, align: 'start' },
      { title: 'Дата', key: 'date', sortable: false, align: 'start' },
      {
        title: 'Повідомлення',
        key: 'taskMessage',
        sortable: false,
        align: 'center',
      },
      {
        title: 'Відповідальний',
        key: 'user',
        sortable: false,
        align: 'center',
      },
      { title: 'Дії', key: 'action', sortable: false, align: 'center' },
    ],
  }),
  methods: {
    getContactInitials,
    toFormatDate,
    getResponsibleShortName,
    deleteTask(item) {
      return this.$axios
        .delete(urlTaskPlanTaskDelete(item.id))
        .then(() => {
          this.$setSnackbar({ text: 'Нагадування видалено' })
          this.$emit('update')
        })
        .catch(this.$err)
    },
    openEditDialog(item) {
      this.$store.commit('setDialog', {
        title: 'Редагування нагадування',
        dialogItem: item,
        params: {
          cb: () => this.$emit('update'),
        },
        action: 'task-plan-edit',
      })
    },
    openCreateNotificationDialog() {
      this.$store.commit('setDialog', {
        title: 'Деталі по нагадуванню',
        dialogItem: {
          leadId: this.leadId,
          applicationId: this.applicationId,
          contactId: this.contactId,
          contractId: this.contractorId,
        },
        params: {
          cb: () => this.$emit('update'),
          dialogHeight: 700,
        },
        action: 'task-plan-dialog',
      })
    },
  },
}
</script>

<style scoped>
.section-title {
  color: #08487a;
  font-weight: 700;
  font-size: 16px;
}
th.text-start {
  font-weight: 400;
}
</style>
