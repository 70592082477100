<template>
  <div style="padding-right: 15px">
    <section-loader v-if="pageLoad"> </section-loader>
    <v-fade-transition hide-on-leave>
      <div class="contractor-wrapper">
        <v-row
          v-if="!pageLoad && !contractorData.message"
          style="overflow: hidden"
          class="mr-0 ml-0">
          <v-col :cols="12" :md="3" class="pr-0 pl-0 common-data">
            <form-dialog
              v-model:dialog="dialog"
              v-model:dialogState="dialogState"
              :dialogItem="dialogItem"
              :dialogParams="dialogParams"
              :contractorId="contractorId"
              :ODContractor="ODContractor"
              :contractor="contractorData"
              @open-data-assign="assignOpenData()"
              @open-data-sync="syncWithOpenData()"
              @table-change="getContractorById()"
              @update-company-group="updateCompanyGroup($event)"
              @update-contractor="submit(false)"
              @edit-bank-account="editBankAccount($event)"
              @add-bank-account="addItem(...$event)"
              @disable-bank-account-statuses="disableBS($event)"
              @add-company-group="addCompanyGroup($event)"
              @add-founder="addFounder($event)">
            </form-dialog>
            <common-card
              ref="commonCard"
              v-model:mainContactId="mainContactId"
              v-model:contractor="contractorData"
              :readonly="readonly"
              :contractorId="contractorId"
              :contractorList="contractorList"
              :mainContact="mainContact"
              :checkPhone="checkPhone"
              :checkEmail="checkEmail"
              :pageLoad="pageLoad"
              @open-dialog="openDialog(...$event)">
            </common-card>
          </v-col>
          <v-col :cols="12" :md="9" class="pr-0">
            <tabs-column
              :readonly="readonly"
              :contractorList="contractorList"
              :openDataLoading="openDataLoading"
              :contractorId="contractorId"
              :contractor="contractorData"
              :checkEmail="checkEmail"
              :checkPhone="checkPhone"
              :pageLoad="pageLoad"
              :v="v$"
              @sync-with-open-data="syncWithOpenData()"
              @open-career-dialog="openContactCareerDialog($event)"
              @update-contractor="updateContractor()"
              @table-change="getContractorById()"
              @open-dialog="openDialog(...$event)"
              @delete-item="deleteItem(...$event)"
              @delete-bank-account="deleteBankAccount($event)"
              @data-changed="submit">
            </tabs-column>
          </v-col>
          <ActionButtons
            v-if="!readonly"
            :confirm="submit"
            :cancel="getContractorById"
            :cancelDisable="contractorCache === cache || loading || pageLoad"
            :confirmDisable="contractorCache === cache || loading || pageLoad">
          </ActionButtons>
        </v-row>
      </div>
    </v-fade-transition>
    <div v-if="contractorData.message">
      <span class="empty-data pt-6">
        {{ contractorData.message }}
      </span>
    </div>
  </div>
</template>
<script>
import commonCard from './common-card.vue'
import tabsColumn from './tabs/Index.vue'
import formDialog from './dialog.vue'
import sectionLoader from '@/components/section-loader.vue'
import ActionButtons from '@/components/action-buttons.vue'
import ObjectMapper from 'object-mapper'
import { contractorMap } from '@/pages/objects-map'
import { generateAddress } from '@/utils/apiAddresses'
import {
  urlEDRCompanyByEdrpou,
  urlGetContractor,
  urlContractorsType,
  urlContractorUpdate,
  urlCreateFounder,
  urlCreateContractorAddress,
  urlCheckPhoneExistence,
  urlCheckEmailExistence,
  urlEDRCompany,
  urlResendEdrCompanyInfo,
} from '@/pages/request'
import {
  syncOpendataQueds,
  setCache,
  backDate,
  toFormatDate,
  v$Notify,
} from '@/utils/helperFunc'

import { mapState } from 'vuex'
import { required, minLength, maxLength } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import CommonCard from '@/pages/transaction-participants/contractors/common-card.vue'
export default {
  components: {
    CommonCard,
    commonCard,
    tabsColumn,
    formDialog,
    ActionButtons,
    sectionLoader,
  },
  setup() {
    return { v$: useVuelidate() }
  },
  props: { contractorList: { type: Array } },
  validations() {
    const dateValidation = { required, minLength: minLength(10) }
    const inn = this.contractorData.inn
      ? {
          inn: { required, minLength: minLength(12), maxLength: maxLength(12) },
        }
      : {}
    const webSite = this.contractorData.isSite ? {} : { webSite: { required } }
    const stateRegistrationDate = this.contractorData.stateRegistrationDate
      ? dateValidation
      : {}
    const innWidthPayer = this.contractorData.isVatPayer
      ? {
          inn: { required, minLength: minLength(12), maxLength: maxLength(12) },
        }
      : {}
    return {
      contractorData: {
        stateRegistrationDate: stateRegistrationDate,
        responsibleId: { required },
        ...inn,
        ...webSite,
        ...innWidthPayer,
      },
    }
  },
  data: () => ({
    contractorData: {
      phones: [],
      emails: [],
      messangers: [],
      companyGroup: {},
      relatedCompanyId: null,
      isCompanyGroup: false,
      publicSector: 1,
      webSite: 'http://',
      addresses: [],
      applications: [],
      employees: [],
      resident: 1,
      stateId: 0,
      current_accounts: [],
      founders: [],
      beneficiar_owners: [],
      queds: [],
      signsOfTradeBoo: 0,
      posTerminals: 0,
      approvedDiscount: 0,
      individualPrograms: 0,
      generalProgram: 0,
      factors: [],
      edrpou: null,
      mainQuedId: null,
    },
    mapedContractorData: {},
    edrCompanyData: {},
    openDataLoading: false,
    pageLoad: false,
    pageLoadTimeOut: 380,
    ODContractor: {},
    dialog: false,
    dialogState: null,
    dialogItem: {},
    dialogParams: {},
    factors: [],
    contractorId: null,
    mainContactId: null,
    mainContact: null,
    cache: null,
    loading: false,
  }),
  computed: {
    ...mapState({
      contractor: state => state.contractor,
      selectItems: state => state.selectItems,
      contractorAddresses: state => state.contractor.addresses,
      token: state => state.common?.token,
    }),
    edrpouSame() {
      return this.$store.state.common.uniqueStr === this.contractorData.edrpou
    },
    mapedContractor() {
      return contractorMap
    },
    contractorCache() {
      return setCache([this.contractorData])
    },
    readonly() {
      return this.contractorData.readOnly
    },
  },
  methods: {
    ObjectMapper,
    urlEDRCompany,
    urlGetContractor,
    urlEDRCompanyByEdrpou,
    urlContractorsType,
    urlContractorUpdate,
    urlResendEdrCompanyInfo,
    syncOpendataQueds,
    urlCreateFounder,

    generateAddress,
    addCompanyGroup(companyGroup) {
      this.contractorData.relatedCompanyId = companyGroup.id
      return (this.contractorData.companyGroup = companyGroup)
    },
    addFounder() {
      this.submit()
    },
    openContactCareerDialog(e) {
      this.$refs.commonCard.openDialog(...e)
    },
    setBreadscrumb() {
      this.$store.commit('setBreadScrumbPart', [
        null,
        null,
        this.contractorData.shortName,
        null,
      ])
    },
    createContractorAddress(address) {
      const addresses = (this.contractorData?.addresses || []).map(
        a => a.house?.HouseNum + a.street?.Street
      )

      const addressHouseStreet =
        address?.house?.HouseNum + address?.street?.Street

      if (addresses.includes(addressHouseStreet)) return

      if (address && address.city && address.house && address.house.Index_) {
        address.contractorId = parseInt(this.$route.params.id)

        this.$axios
          .post(urlCreateContractorAddress(), address)
          .then(res => {
            return res
          })
          .catch(this.$err)
      }
    },
    checkPhone(phone) {
      return this.$axios
        .post(urlCheckPhoneExistence(), { phone: phone })
        .then(res => {
          if (
            res.data.status === true &&
            parseInt(this.$route.params.id) !== res.data.contractorId
          ) {
            this.contactAlreadyInUse('телефон', res.data)
          }

          return res.data.contractorId === this.contractorData.id
            ? false
            : res.data.status
        })
        .catch(this.$err)
    },
    checkEmail(email) {
      return this.$axios
        .post(urlCheckEmailExistence(), { email: email })
        .then(res => {
          if (
            res.data.status === true &&
            parseInt(this.$route.params.id) !== res.data.contractorId
          ) {
            this.contactAlreadyInUse('email', res.data)
          }

          return res.data.contractorId === this.contractorData.id
            ? false
            : res.data.status
        })
        .catch(this.$err)
    },
    contactAlreadyInUse(msgStartText, res) {
      const message = `Вказаний ${msgStartText} вже використовується. Сутність: ${res.name}. Відповідальний: ${res.responsible}`
      this.$setSnackbar({ text: message })
    },
    submit(validate = true) {
      validate && this.v$.$touch()
      if (validate && this.v$.$invalid) {
        this.v$.$anyError
        return v$Notify(this.v$.contractorData, 'contractor')
      }

      this.loading = true
      const req = Object.clone(this.contractorData)
      this.edrpouSame && delete req.edrpou

      req.stateRegistrationDate = backDate(req.stateRegistrationDate)
      delete req.responsible
      req.queds = req.queds || []
      req.queds = req.queds.map(q => (typeof q === 'number' ? q : q.id))

      if (!req.residentCountryId) {
        req.residentCountryId = 222
      }

      return this.$axios
        .post(this.urlContractorUpdate(this.contractorId), req)
        .then(res => {
          if (res?.data?.message) {
            return Promise.reject(res.data.message)
          }
          this.$setSnackbar({ text: 'Данi оновлено' })
          this.$store.commit('refreshPage')
          this.syncContractorId()
          this.loading = false
          return res
        })
        .catch(err => this.$err(err, () => (this.loading = false)))
    },
    updateContractor() {
      this.syncContractorId()
      this.getContractorById()
    },
    getContractorById() {
      this.pageLoad = true
      return this.$axios
        .get(this.urlGetContractor(this.contractorId))
        .then(res => {
          if (res?.data?.message) {
            this.$setSnackbar({ text: 'Доступ заборонено' })
            return
          }

          if (res.data.stateRegistrationDate)
            res.data.stateRegistrationDate = toFormatDate(
              res.data.stateRegistrationDate
            )
          Object.assign(this.contractorData, res.data)
          this.$store.commit('addContractorAddresses', res.data.addresses)
          this.setBreadscrumb()
          setTimeout(() => {
            this.pageLoad = false
          }, this.pageLoadTimeOut)
          this.$store.commit('setUnique', this.contractorData.edrpou)
          this.cache = setCache([this.contractorData])
          this.pageLoad = false
        })
        .catch(err => {
          if (err.response && err.response.status === 423) {
            return
          }
          this.$err(err, () => (this.pageLoad = false))
        })
    },
    async assignOpenData() {
      this.pageLoad = true
      const hasLegalAddress = (this.contractorData.addresses || [])
        .map(address => address?.type?.id)
        .includes(1)
      Object.assign(this.contractorData, this.mapedContractorData)
      // await this.getFounders(this.ODContractor)
      this.contractorData.inn = this.getCompanyInn(this.ODContractor)
      await this.$store.dispatch('addActivities')
      this.syncOpendataQueds(
        this.ODContractor.registry.activities,
        this.contractorData,
        this.selectItems.activityTypes
      )

      if (!hasLegalAddress) {
        const res = await this.generateAddress(
          this.token,
          this.mapedContractorData.addressParts
        )
        await this.createContractorAddress(res.data)
      }

      setTimeout(() => {
        this.$setSnackbar({ text: 'Синхронiзовано' })
        this.resendEdrpoInfo()
      }, 400)
    },
    resendEdrpoInfo() {
      return this.$axios
        .post(
          this.urlResendEdrCompanyInfo(this.contractorData.id),
          this.edrCompanyData
        )
        .then(() => {
          this.pageLoad = false
          this.dialog = false
          this.dialogState = null
          this.edrCompanyData = {}
        })
    },
    getCompanyInn(data) {
      const factorNumber =
        (data?.factors || []).find(factor => factor.number)?.number || null
      return factorNumber
    },
    syncWithOpenData() {
      this.openDataLoading = true
      return this.$axios
        .get(this.urlEDRCompany(this.contractorData.id))
        .then(res => {
          this.mapedContractorData = this.ObjectMapper(
            res?.data,
            this.mapedContractor
          )
          this.mapedContractorData.stateRegistrationDate =
            this.formatOpenDataDate(
              this.mapedContractorData.stateRegistrationDate
            )
          this.edrCompanyData = res.data
          this.mapedContractorData.contractorData = res.data || null

          if (this.contractorData) {
            if (this.contractorData && this.contractorData.phones) {
              this.mapedContractorData.phones =
                this.mapedContractorData.phones.filter(phone => {
                  return !this.contractorData.phones
                    .map(p => p.phone)
                    .includes(phone.phone)
                })
            }

            if (this.contractorData && this.contractorData.emails) {
              this.mapedContractorData.emails =
                this.mapedContractorData.emails.filter(email => {
                  return !this.contractorData.emails
                    .map(e => e.email)
                    .includes(email.email)
                })
            }
          }

          Object.assign(this.ODContractor, res.data)
          this.openDialog('syncWithOpenData', {}, { dialogwidth: 1100 })
          this.openDataLoading = false
        })
        .catch(err => {
          if (err.response && err.response.data) {
            if (
              err.response.data.reason === 'Limit reached, Payment Required'
            ) {
              this.$setSnackbar({
                text: 'Помилка синхронiзацiї. Перевищено лiмiт запитiв',
                color: 'warning',
              })
            } else if (err.response.data.reason === 'Not found') {
              this.$setSnackbar({
                name: 'За вказаним кодом нiчого не знайдено',
                color: 'error',
              })
            } else {
              this.$err(err.response.data)
            }
          } else {
            this.$err(err.message)
          }

          this.openDataLoading = false
        })
    },
    formatOpenDataDate(date) {
      if (!date) return
      return new Date(date).toLocaleDateString('ru-RU')
    },
    // async getFounders(data) {
    //   let beneficiaries = data?.registry?.beneficiaries
    //   if (!beneficiaries?.length) return
    //   const founders = this.contractorData?.founders?.map(f => f.name) || []
    //   await this.$store.dispatch('addFounderRoles')
    //   const addBeneficiars = async beneficiar => {
    //     await this.$axios.post(this.urlCreateFounder(), beneficiar).catch(this.$err)
    //   }
    //   beneficiaries = beneficiaries
    //     .filter(
    //       b =>
    //         b.name && b.amount && b.amountPercent && b.role && !founders.includes(b.name)
    //     )
    //     .map(b => {
    //       let role = this.selectItems.founderRoles.find(
    //         r => r.name.toUpperCase() === b.role.toUpperCase()
    //       )
    //       b.founderRoleId = role?.id || 2
    //       b.contractorId = this.contractorId
    //       if (!b.code) b.code = '00000000'
    //       delete b.role
    //       return b
    //     })
    //     .filter(b => b && b.founderRoleId)
    //     .forEach(addBeneficiars)
    // },
    /**
     * @param {String} state - dialog action
     * @param {Object} dialogItem - object to merge dialog window
     * @param {Array} params - additional parameters
     * @param {Function} cb - callback
     */
    openDialog(state, dialogItem = {}, params = {}, cb) {
      this.dialogState = state
      this.dialogItem = dialogItem
      this.dialog = true
      this.dialogParams = params
      typeof cb === 'function' && cb()
    },
    syncTaxPayer() {
      const taxPayer = this.getFactorsObj('tax')
      if (taxPayer) {
        this.inn = taxPayer?.number
        //...
      }
    },
    syncContractorId() {
      this.contractorId = parseInt(this.$route?.params?.id)
    },
    syncLocation() {
      if (!this.registrationCountry) this.registrationCountry = this.country
      if (!this.registrationPlace) this.registrationPlace = this.city
      if (!this.registrationAddress) this.registrationAddress = this.address
    },
    addItem(itemName, item, key) {
      item.main &&
        this.contractorData[itemName].map(item => {
          if (typeof item === 'string') return { item: item, main: false }
          item.main = false
          return item
        })

      Number.isInteger(key) &&
        this.contractorData[itemName].splice(key, 0, item)
      !Number.isInteger(key) && this.contractorData[itemName].push(item)
    },
    deleteItem(itemName, key) {
      this.contractorData[itemName] = this.contractorData[itemName].filter(
        (v, k) => k !== key
      )
    },
    getFactorsObj(prop) {
      if (this?.factors?.length)
        return this.factors.find(v => v?.factorGroup === prop)
    },
    editBankAccount(item) {
      const index = this.findIndex(item)
      this.current_accounts.splice(index, 1, item)
    },
    updateCompanyGroup(event) {
      this.contractorData.companyGroups.push(event)
    },
    deleteBankAccount(id) {
      this.current_accounts = this.current_accounts.filter(
        v => v.accountId !== id
      )
    },
    findIndex(item) {
      const accountId = item.accountId
      let index
      this.current_accounts.find(
        (v, i) => v.accountId === accountId && (index = i)
      )
      return index
    },
    disableBS(id) {
      // disable bank account statuses
      this.current_accounts.forEach(
        v => v.accountId !== id && (v.status = false)
      )
    },
  },
  watch: {
    '$route.name': function () {
      this.setBreadscrumb()
    },
    contractorAddresses(val) {
      this.contractorData.addresses = val
    },
    loading(val) {
      if (!val)
        setTimeout(() => {
          this.cache = setCache([this.contractorData])
        }, 500)
    },
    pageLoad(val) {
      if (!val)
        setTimeout(() => {
          this.cache = setCache([this.contractorData])
        }, 1000)
    },
  },
  created() {
    this.pageLoad = true
    this.$store.commit('setCustomBreadScrumbState', true)
  },
  async mounted() {
    this.syncContractorId()
    await this.getContractorById()
    this.syncLocation()
    this.syncTaxPayer()
  },
}
</script>
