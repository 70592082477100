<template>
  <div class="login">
    <div>
      <div class="login__header">
        <div class="d-flex justify-space-between w-100 align-center">
          <span class="login__header__text d-block">Відновлення паролю</span>
          <span class="link d-block" @click="$router.go(-1)">Назад</span>
        </div>
      </div>
      <div class="login_main">
        <div class="app__form-block">
          <div
            :class="`app__login-form ${
              $vuetify.breakpoint?.xs ? 'pl-2 pr-2' : ''
            }`"
            style="position: relative">
            <v-progress-linear
              :active="loading"
              :indeterminate="loading"
              absolute
              bottom
              color="red">
            </v-progress-linear>
            <div class="app__form-logo-wrapper">
              <div class="app__header-logo-card"></div>
            </div>
            <!-- fake autofill form -->
            <div style="position: absolute; opacity: 0">
              <label for="_email">email</label
              ><input name="_email" type="email" />
              <label for="_password">password</label
              ><input name="_password" type="password" />
            </div>
            <div class="app__form">
              <div v-if="userId === null" class="app__input-text-wrapper">
                <v-text-field
                  id="number"
                  v-model="number"
                  class="app__input-text"
                  type="tel"
                  placeholder="Вкажіть номер телефону"
                  @paste="pasteEvent = true"
                  @input="
                    e => {
                      phoneMask(e, 'number')
                      v$.number.$touch()
                    }
                  ">
                </v-text-field>
                <span
                  :class="
                    numberErrors.length > 0
                      ? 'app__input-error input-error--active'
                      : 'app__input-error'
                  ">
                  {{ numberErrors[0] }}
                </span>
              </div>
              <div v-if="userId && !showCaptcha" class="recovery-code-section">
                <div class="app__input-text-wrapper">
                  <v-text-field
                    v-model="verificationCode"
                    class="app__input-text"
                    type="text"
                    placeholder="Код з SMS"
                    @blur="v$.verificationCode.$touch()">
                  </v-text-field>
                  <div v-show="secondTimer" class="wrap">
                    <span
                      style="
                        font-size: 0.73rem;
                        color: #808080;
                        padding-top: 15px;
                        display: inline-flex;
                        max-width: 60%;
                        align-self: flex-end;
                        -webkit-align-self: flex-end;
                        -ms-flex-item-align: end;
                        padding-bottom: 2px;
                      ">
                      Повторно відправити код через
                    </span>
                    <div style="display: inline-block">
                      <div class="bounceball"></div>
                      <div id="bounceball-text" class="bounceball-text"></div>
                    </div>
                  </div>
                  <div v-if="repeatSendSms && !secondTimer" class="pt-5">
                    <div class="d-flex justify-end">
                      <v-btn
                        color="grey darken-3"
                        class="text-white no-transform"
                        style="border-radius: 0"
                        :loading="loading"
                        @click="sendSms()">
                        Надіслати код
                      </v-btn>
                    </div>
                  </div>
                  <span
                    :class="
                      verificationCodeErrors.length > 0
                        ? 'app__input-error input-error--active'
                        : 'app__input-error'
                    ">
                    {{ verificationCodeErrors[0] }}
                  </span>
                </div>
                <div style="height: 40px"></div>
                <div class="app__input-text-wrapper">
                  <v-text-field
                    v-model="password"
                    class="app__input-text"
                    type="password"
                    placeholder="Новий пароль"
                    @blur="v$.password.$touch()">
                  </v-text-field>
                  <span
                    :class="
                      passwordErrors.length > 0
                        ? 'app__input-error input-error--active'
                        : 'app__input-error'
                    ">
                    {{ passwordErrors[0] }}
                  </span>
                </div>
                <div class="app__input-text-wrapper">
                  <v-text-field
                    v-model="repeatedPassword"
                    class="app__input-text"
                    type="password"
                    placeholder="Підтвердіть пароль"
                    @blur="v$.repeatedPassword.$touch()">
                  </v-text-field>
                  <span
                    :class="
                      repeatedPasswordErrors.length > 0
                        ? 'app__input-error input-error--active'
                        : 'app__input-error'
                    ">
                    {{ repeatedPasswordErrors[0] }}
                  </span>
                </div>
              </div>
              <vue-recaptcha
                v-show="
                  !v$.number.$error && number && !secondTimer && showCaptcha
                "
                ref="recaptcha"
                sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                @verify="verify($event)"
                @expired="expired($event)">
              </vue-recaptcha>
              <div v-if="!showCaptcha" class="app__button-wrapper">
                <div class="d-flex justify-space-between">
                  <v-btn
                    class="recovery-btn no-transform mb-2"
                    :loading="loading"
                    @click="submit()">
                    <span v-if="!request">{{
                      userId ? 'Змінити пароль' : 'Продовжити'
                    }}</span>
                    <div v-if="request" class="lds-dual-ring"></div>
                  </v-btn>
                </div>
                <div class="app__sign-navigate"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { minLength, required } from '@vuelidate/validators'
import { phoneMask } from '@/utils/masks'
import { urlRecoveryPassword } from '@/pages/request'
import { useVuelidate } from '@vuelidate/core'
import vueRecaptcha from 'vue3-recaptcha2'
export default {
  components: { vueRecaptcha },
  setup() {
    return { v$: useVuelidate() }
  },
  validations() {
    return this.validationRules
  },
  data() {
    return {
      pasteEvent: false,
      request: false,
      loading: false,
      secondTimer: false,
      repeatSendSms: false,
      number: null,
      repeatedPassword: null,
      userId: null,
      password: null,
      verificationCode: null,
      captchaVerified: false,
      showCaptcha: true,
    }
  },
  computed: {
    passwordErrors() {
      const errors = []
      if (!this.v$.password.$error) return errors

      if (this.v$.password.required.$invalid) {
        errors.push("Обов'язкове поле")
      }
      if (this.v$.password.minLength.$invalid) {
        errors.push('Має містити мінімум 8 знаків')
      }
      if (this.v$.password.containNum.$invalid) {
        errors.push('Мінімум 1 цифра')
      }
      if (this.v$.password.containSpecial.$invalid) {
        errors.push('Мінімум 1 спец. символ')
      }

      return errors
    },
    numberErrors() {
      const errors = []
      if (!this.v$.number.$error) return errors

      if (this.v$.number.required.$invalid) {
        errors.push("Обов'язкове поле")
      }
      if (this.v$.number.minLength.$invalid) {
        errors.push('Невірний номер')
      }

      return errors
    },
    verificationCodeErrors() {
      const errors = []
      if (!this.v$.verificationCode.$error) return errors

      if (this.v$.verificationCode.required.$invalid) {
        errors.push('Невірний код')
      }

      return errors
    },
    repeatedPasswordErrors() {
      const errors = []
      if (!this.v$.repeatedPassword.$error) return errors

      if (this.v$.repeatedPassword.sameAsPassword.$invalid) {
        errors.push('Невірний пароль')
      }

      return errors
    },
    validationRules() {
      return {
        number:
          this.userId === null ? { required, minLength: minLength(23) } : {},
        password:
          this.userId !== null
            ? {
                required,
                minLength: minLength(8),
                // containNum: value => /[0-9]/.test(value),
                // containSpecial: value => /[#?!@$%^&*-]/.test(value),
              }
            : {},
        repeatedPassword:
          this.userId !== null
            ? this.password === this.repeatedPassword
              ? { required }
              : {}
            : {},
        verificationCode:
          this.userId !== null && !this.showCaptcha ? { required } : {},
      }
    },
  },
  methods: {
    phoneMask,
    verify() {
      console.log('reCaptcha loaded')
      this.captchaVerified = true
      this.showCaptcha = false
      console.log('reCaptcha Verified')
    },
    expired() {
      this.$refs.recaptcha.reset()
      this.captchaVerified = false
      console.log('reCaptcha expired')
    },
    sendSms() {
      this.loading = true
      if (!this.captchaVerified) {
        this.loading = false
        this.showCaptcha = true
        return
      }
      return this.$axios
        .post(urlRecoveryPassword(), { phone: this.number })
        .then(res => {
          this.loading = false
          this.userId = res.data.userId
          this.timer(120)
          return res
        })
        .catch(err => {
          if (err.response.status === 403) {
            this.loading = false
            return this.$setSnackbar({
              text: err.response.data.message,
              color: 'error',
            })
          }
          this.loading = false
        })
    },
    resetPassword() {
      this.loading = true
      const object = {
        userId: this.userId,
        password: this.password,
        verificationCode: this.verificationCode,
      }

      return this.$axios
        .post('/auth/password/reset/resetPassword', object)
        .then(response => {
          if (response.data.message) {
            this.$setSnackbar({ text: response.data.message })
            setTimeout(() => {
              this.$router.push({ name: 'login' })
            }, 1200)
          } else {
            this.$setSnackbar({ text: response.data.message })
            setTimeout(() => {
              this.$router.go()
            }, 2500)
          }
          this.userId = null
          this.loading = false
        })
        .catch(error => {
          console.log(error.response)
          this.loading = false
        })
    },
    highlightErrors() {
      this.v$.$anyError
      this.v$.$touch()
    },
    submit() {
      if (!this.v$.$invalid) {
        this.loading = true
        this.userId !== null ? this.resetPassword() : this.sendSms()
        this.loading = false
      } else this.highlightErrors()
    },
    signInByEnter() {
      window.addEventListener('keypress', event => {
        if (event.key === 'Enter') {
          this.submit()
        }
      })
    },
    timer(seconds) {
      this.repeatSendSms = false
      this.secondTimer = true
      this.showCaptcha = false
      const timer = setInterval(() => {
        if (seconds <= 0) {
          clearInterval(timer)
          document.getElementById('bounceball-text').innerHTML = ''
          this.secondTimer = false
          this.repeatSendSms = true
        } else {
          document.getElementById('bounceball-text').innerHTML = seconds + ' с.'
        }
        seconds -= 1
      }, 1000)
    },
  },
  watch: {
    userId(val) {
      if (val !== null) this.timer(120)
    },
  },
  mounted() {
    this.signInByEnter()
    const reCaptchaScript = document.createElement('script')
    reCaptchaScript.src =
      'https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit'
    reCaptchaScript.defer = true
    reCaptchaScript.async = true
    document.head.appendChild(reCaptchaScript)
  },
  beforeUnmount() {
    window.removeEventListener('keypress', event => {
      if (event.key === 'Enter') {
        this.submit()
      }
    })
  },
}
</script>

<style lang="scss">
.recovery-btn {
  background: #ffffff;
  border: 2px solid #08487a;
  border-radius: 4px;
  color: #08487a !important;
  font-weight: 600;
}
body {
  position: relative;
  width: 100%;
  height: 100vh;
  font-family: Roboto, Arial, Helvetica !important;
}
.wrap {
  display: flex;
  justify-content: space-around;
}
.bounceball-text {
  color: black;
  display: inline-block;
  margin-left: 5px;
  min-width: 60px;
  font-size: 1.3rem;
}
.bounceball {
  position: relative;
  display: inline-block;
  height: 35px;
  width: 15px;
  &:before {
    position: absolute;
    content: '';
    display: block;
    top: 0;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #5f6368;
    transform-origin: 50%;
    animation: bounce 500ms alternate infinite ease;
  }
}
@keyframes bounce {
  0% {
    top: 30px;
    height: 5px;
    border-radius: 60px 60px 20px 20px;
    transform: scaleX(2);
  }
  35% {
    height: 15px;
    border-radius: 50%;
    transform: scaleX(1);
  }
  100% {
    top: 0;
  }
}
.login {
  cursor: default;
  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    &__text {
      font-size: 34px;
      color: #1f1f1f;
      font-weight: 700;
    }
  }
}
</style>
