<template>
  <div>
    <v-fade-transition hide-on-leave>
      <div v-show="!pageLoad">
        <list-section :elevation="0" title="Запрошення" :mb="0" :mt="0">
          <template #body>
            <v-data-table
              :headers="tHeadInvitations"
              :items="waitAcceptInvitations"
              hide-default-footer
              :hide-default-header="!waitAcceptInvitations.length"
              class="pb-2 pt-1 custom-table head-line"
              dense>
              <!-- eslint-disable-next-line -->
              <template #no-data>
                <no-data></no-data>
              </template>
              <!-- eslint-disable-next-line -->
              <template #item.activity.responsible="{ item }">
                <span>
                  {{
                    `${getSafe(
                      () => item.activity.responsible.surname,
                      '-'
                    )} ${getSafe(
                      () => item.activity.responsible.name,
                      '-'
                    )} ${getSafe(
                      () => item.activity.responsible.patronymic,
                      '-'
                    )}`
                  }}
                </span>
              </template>
              <!-- eslint-disable-next-line -->
              <template #item.actions="{ item }">
                <TableCrud
                  :actionsArray="[
                    {
                      action: () => acceptInvitation(item),
                      text: 'Погодитись',
                      icon: 'mdi-plus',
                    },
                    {
                      action: () => rejectInvitation(item),
                      text: 'Вiдхилити',
                      icon: 'mdi-close',
                    },
                  ]">
                </TableCrud>
              </template>
            </v-data-table>
          </template>
        </list-section>
        <list-section :elevation="0" title="Погодженi запрошення" :mb="0">
          <template #body>
            <v-data-table
              :headers="tHeadInvitations"
              :items="agreedInvitations"
              hide-default-footer
              :hide-default-header="!agreedInvitations.length"
              class="pb-2 pt-1 custom-table head-line"
              dense>
              <!-- eslint-disable-next-line -->
              <template #no-data>
                <no-data></no-data>
              </template>
              <!-- eslint-disable-next-line -->
              <template #item.activity.responsible="{ item }">
                <span>
                  {{
                    `${getSafe(
                      () => item.activity.responsible.surname,
                      '-'
                    )} ${getSafe(
                      () => item.activity.responsible.name,
                      '-'
                    )} ${getSafe(
                      () => item.activity.responsible.patronymic,
                      '-'
                    )}`
                  }}
                </span>
              </template>
              <!-- eslint-disable-next-line -->
              <template #item.actions="{ item }">
                <TableCrud
                  :menu="false"
                  :actionsArray="[
                    {
                      action: (_, event) => openCard(item, event),
                      text: 'Переглянути',
                      icon: 'mdi-magnify-plus',
                    },
                  ]">
                </TableCrud>
              </template>
            </v-data-table>
          </template>
        </list-section>
        <list-section :elevation="0" title="Вiдхиленi запрошення" :mb="0">
          <template #body>
            <v-data-table
              :headers="tHeadInvitations"
              :items="rejectedInvitations"
              hide-default-footer
              :hide-default-header="!rejectedInvitations.length"
              class="pb-2 pt-1 custom-table head-line"
              dense>
              <!-- eslint-disable-next-line -->
              <template #no-data>
                <no-data></no-data>
              </template>
              <!-- eslint-disable-next-line -->
              <template
                v-if="rejectedInvitations.length"
                #item.activity.responsible="{ item }">
                <span>
                  {{
                    `${getSafe(
                      () => item.activity.responsible.surname,
                      '-'
                    )} ${getSafe(
                      () => item.activity.responsible.name,
                      '-'
                    )} ${getSafe(
                      () => item.activity.responsible.patronymic,
                      '-'
                    )}`
                  }}
                </span>
              </template>
              <!-- eslint-disable-next-line -->
              <template #item.actions="{ item }">
                <TableCrud
                  disabled
                  :actionsArray="[
                    {
                      action: (_, event) => openCard(item, event),
                      text: 'Переглянути',
                      icon: 'mdi-magnify-plus',
                    },
                  ]">
                </TableCrud>
              </template>
            </v-data-table>
          </template>
        </list-section>
      </div>
    </v-fade-transition>
    <v-fade-transition hide-on-leave>
      <section-loader v-if="pageLoad"> </section-loader>
    </v-fade-transition>
    <v-menu
      v-model="selectedOpen"
      :activator="selectedElement"
      :close-on-content-click="false"
      offset-y>
      <event-card
        :selectedEvent="selectedEvent"
        :selectedOpen="selectedOpen"
        :editable="false"
        :deletable="false"
        @close="selectedOpen = false"
        @change-selected-open="selectedOpen = $event" />
    </v-menu>
  </div>
</template>
<script>
import listSection from '@/components/list-section.vue'
import noData from '@/components/no-table-data.vue'
import eventCard from './event-card.vue'
import sectionLoader from '@/components/section-loader.vue'
import {
  urlGetAllUserInvitations,
  urlRejectInivation,
  urlAcceptInvitation,
} from '@/pages/request.js'
import { getSafe } from '../../utils/helperFunc'
import TableCrud from '@/components/table-crud.vue'
export default {
  components: {
    TableCrud,
    listSection,
    noData,
    eventCard,
    sectionLoader,
  },
  data: () => ({
    selectedOpen: false,
    selectedEvent: {},
    selectedElement: null,
    invitations: [],
    tHeadInvitations: [
      {
        title: 'Назва',
        key: 'activity.title',
        sortable: false,
        width: '30%',
      },
      { title: 'Початок', key: 'activity.startDate', sortable: false },
      { title: 'Завершення', key: 'activity.endDate', sortable: false },
      {
        title: 'Відповідальний',
        key: 'activity.responsible',
        sortable: false,
      },
      { title: 'Дії', key: 'actions', sortable: false, align: 'center' },
    ],
    pageLoad: false,
    pageLoadTimeOut: 280,
  }),
  computed: {
    waitAcceptInvitations() {
      return this.invitations.filter(inv => inv.inviteStatusId === 1)
    },
    agreedInvitations() {
      return this.invitations.filter(inv => inv.inviteStatusId === 2)
    },
    rejectedInvitations() {
      return this.invitations.filter(inv => inv.inviteStatusId === 3)
    },
  },
  methods: {
    getSafe,
    urlRejectInivation,
    urlAcceptInvitation,
    urlGetAllUserInvitations,
    getUserInvitations() {
      this.pageLoad = true
      return this.$axios
        .get(this.urlGetAllUserInvitations())
        .then(res => {
          this.invitations.push(...res.data)
          setTimeout(() => {
            this.pageLoad = false
          }, this.pageLoadTimeOut)
          return res
        })
        .catch(this.$err)
    },
    acceptInvitation(item) {
      return this.$axios
        .get(this.urlAcceptInvitation(item.id))
        .then(res => {
          this.$setSnackbar({ text: 'Зустрiч пiдтверджено' })
          this.$store.commit('refreshPage')
          return res
        })
        .catch(this.$err)
    },
    rejectInvitation(item) {
      return this.$axios
        .get(this.urlRejectInivation(item.id))
        .then(res => {
          this.$setSnackbar({ text: 'Зустрiч вiдхилено' })
          this.$store.commit('refreshPage')
          return res
        })
        .catch(this.$err)
    },
    openCard(item, e) {
      this.selectedElement = e.target
      this.selectedEvent = item.activity

      setTimeout(() => {
        this.selectedOpen = true
      }, 0)
    },
  },
  created() {
    this.getUserInvitations()
  },
}
</script>
