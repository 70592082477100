<template>
  <div>
    <span class="label">Назва</span>
    <v-text-field
      v-model="plan.name"
      placeholder="введіть текст"
      :error-messages="setErrHandler(v$.plan.name)"
      @blur="v$.plan.name.$touch()"></v-text-field>
    <span class="label">Тип</span>
    <v-select
      v-model="plan.typeId"
      placeholder="оберіть зі списку"
      :items="directoryItems('planTypes', plan.type)"
      item-title="name"
      item-value="id"
      :readonly="dialog.action === 'updatePlan'"
      :loading="asyncLoading('addPlanTypes')"
      :error-messages="setErrHandler(v$.plan.typeId)"
      @focus="asyncAction('addPlanTypes')"
      @blur="v$.plan.typeId.$touch()"></v-select>
    <span class="label">Департамент</span>
    <v-select
      v-model="plan.departmentId"
      placeholder="Оберіть зі списку"
      :items="directoryItems('departments', plan.department)"
      item-title="name"
      item-value="id"
      :loading="asyncLoading('addDepartments')"
      :error-messages="setErrHandler(v$.plan.departmentId)"
      @focus="asyncAction('addDepartments')"
      @blur="v$.plan.departmentId.$touch()"></v-select>
  </div>
</template>

<script>
import { setErrHandler } from '@/utils/helperFunc'
import { required } from '@vuelidate/validators'
import { urlCreatePlan, urlUpdatePlan } from '@/pages/request'
import { useVuelidate } from '@vuelidate/core'
import { useSelect } from '@/utils/mixins/useSelect'

export default {
  name: 'CreatePlan',
  setup() {
    return { v$: useVuelidate(), ...useSelect() }
  },
  props: {
    dialog: {
      type: Object,
    },
  },
  validations() {
    return {
      plan: {
        name: { required },
        typeId: { required },
        departmentId: { required },
      },
    }
  },
  data: () => {
    return {
      plan: {
        name: null,
        typeId: null,
        departmentId: null,
      },
    }
  },
  computed: {
    validation() {
      return { v$: this.v$.plan, name: 'plan' }
    },
    url() {
      switch (this.dialog.action) {
        case 'createPlan':
          return urlCreatePlan()
        case 'updatePlan':
          return urlUpdatePlan(this.plan.id)
        default:
          throw new Error('Plan dialog - invalid action')
      }
    },
  },
  methods: {
    setErrHandler,
    submit() {
      return this.$axios.post(this.url, this.plan)
    },
  },
  created() {
    if (this.dialog.action === 'updatePlan') {
      Object.assign(this.plan, this.dialog.dialogItem)
    }
  },
}
</script>
