<template>
  <v-card-text>
    <Fields class="mb-3" :fields="fields[0]" direction="column">
      <template #leasingObject_label>
        <div class="d-flex align-center gap-1">
          <v-icon color="#08487a">{{ 'mdi-car' }}</v-icon>
          <span class="font-weight-bold">Об'єкт лізингу</span>
        </div> </template
      ><template #amount_label>
        <div class="d-flex align-center gap-1">
          <MoneyEnvelope />
          <span class="font-weight-bold">Вартість об'єкта лізингу</span>
        </div>
      </template>
    </Fields>
    <Fields class="mb-3" :fields="fields[1]" direction="row">
      <template #provider_label>
        <div class="d-flex align-center gap-1">
          <v-icon color="#08487a">{{ 'mdi-card-account-details' }}</v-icon>
          <span class="font-weight-bold">Постачальник</span>
        </div>
      </template>
      <template #dkp_label>
        <v-icon color="#08487a">{{ 'mdi-file-document' }} </v-icon>
        <span class="font-weight-bold">Договір купівлі продажу</span>
      </template>
    </Fields>
    <!--    <div class="d-flex align-center gap-1 mb-4 mt-4">-->
    <!--      <span class="label">Договір купівлі-продажу</span>-->
    <!--      <ALink-->
    <!--        v-if="contract.dkp"-->
    <!--        :text="contract.dkp.number"-->
    <!--        :route="{ name: 'dkp-contract', params: { id: contract.dkp.id }}"/>-->
    <!--      <span v-else class="disabled">Відсутній</span>-->
    <!--    </div>-->
    <v-row>
      <v-col cols="3">
        <span class="label"
          >Гранична дата сплати<br />Авансового лізингового платежу</span
        >
        <DatePicker
          v-model="contract.lastAdvanceDate"
          single
          size="small"
          :readonly="readonly"
          placeholder="Оберіть дату" />
      </v-col>
      <v-col cols="3">
        <span class="label">Максимальний<br />термін передачі</span>
        <DatePicker
          v-model="contract.maxTermTransferDate"
          single
          size="small"
          :readonly="readonly"
          placeholder="Оберіть дату" />
      </v-col>
    </v-row>
  </v-card-text>
</template>
<script>
import DatePicker from '@/components/DatePicker.vue'
import Fields from '@/components/Fields.vue'
import MoneyEnvelope from '@/assets/svg/money-envelope.vue'
import {
  LODescription as getLoDescription,
  getEntityRoute,
  getEntityName,
} from '@/utils/helperFunc'
import { mapState } from 'vuex'
export default {
  components: {
    DatePicker,
    Fields,
    MoneyEnvelope,
  },
  props: {
    readonly: { type: Boolean },
  },
  computed: {
    ...mapState({
      calculation: state => state.contracts.dflContract.calculation,
      contract: state => state.contracts.dflContract,
      $v: state => state.contracts.$vDfl,
    }),
    LO() {
      return getLoDescription(this.calculation)
    },
    fields() {
      return [
        [
          {
            label: "Об'єкт лізингу",
            value: this.LO.description({ detailed: true }),
            slot: 'leasingObject',
          },
          {
            label: "Вартість об'єкта лізингу",
            value: this.LO.amountDkp,
            slot: 'amount',
          },
        ],
        [
          {
            label: 'Договір купівля-продажу',
            value: this.contract?.dkp?.number,
            class: { link: true },
            click: () => {
              this.$router.push({
                name: 'dkp-contract',
                params: { id: this.contract.dkp.id },
              })
            },
            slot: 'dkp',
          },
          {
            label: 'Постачальник',
            value: getEntityName(this.calculation.provider),
            class: { link: true },
            click: () => {
              this.$router.push(
                getEntityRoute(
                  this.calculation.provider.id,
                  this.calculation.providerTypeId
                )
              )
            },
            slot: 'provider',
          },
        ],
      ]
    },
  },
}
</script>
