<template>
  <div>
    <div ref="wrapper">
      <div id="legal-section">
        <div>
          <div
            class="d-flex align-center"
            style="justify-content: space-between">
            <div>
              <v-btn
                data-readonly="hide"
                class="mt-3 mb-4 no-transform text-white"
                :disabled="readonly"
                dense
                style="
                  background: #fc7247;
                  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
                  border-radius: 4px;
                "
                :loading="openDataLoading"
                @click="toggleSync()">
                Синхронізувати з ЄДР
              </v-btn>
            </div>
            <div>
              <span class="area-label d-block" style="margin-bottom: 0"
                >ЄДРПОУ</span
              >
              <span class="d-block" style="font-weight: 400; font-size: 14px">{{
                contractorData.edrpou
              }}</span>
            </div>
          </div>
          <v-row>
            <v-col :cols="12" :md="12" class="pt-1 pb-0">
              <span class="area-label"
                >Повна назва компанії (згідно установчих документів чи
                ЄДР)</span
              >
              <v-text-field
                v-model="contractorData.companyFullName"
                :readonly="readonly"
                placeholder="Введіть текст"
                dense>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row style="margin-top: -10px">
            <v-col :cols="12" :md="12" class="pt-0 pb-0">
              <span class="area-label">Повна назва компанії англійською:</span>
              <v-text-field
                v-model="contractorData.companyFullNameEnglish"
                :readonly="readonly"
                placeholder="Введіть текст"
                dense>
              </v-text-field>
            </v-col>
          </v-row>

          <!--  -->
          <div
            style="
              background: rgb(246 246 246);
              border-radius: 4px;
              padding: 5px;
            ">
            <v-row>
              <v-col :cols="12" :md="5">
                <span class="area-title">Форма власності</span>
              </v-col>
              <v-col :cols="12" :md="7">
                <span class="area-label">Організаційно-правова форма</span>
                <v-autocomplete
                  v-model="contractorData.formOwnershipId"
                  :readonly="readonly"
                  :items="
                    directoryItems(
                      'ownershipsList',
                      contractorData.formOwnershipCompany
                    )
                  "
                  placeholder="Введіть текст"
                  item-title="name"
                  item-value="code"
                  dense
                  @focus="asyncAction('addOwnerships')">
                  <template
                    v-if="
                      asyncLoading('addOwnerships') &&
                      directoryItems(
                        'ownershipsList',
                        contractorData.formOwnershipCompany
                      ).length
                    "
                    #append-item
                    ><Loader
                  /></template>
                  <template v-if="asyncLoading('addOwnerships')" #no-data
                    ><Loader
                  /></template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col :cols="12" :md="5">
                <span class="area-title">ІПН, належність до платників ПДВ</span>
              </v-col>
              <v-col :cols="12" :md="7">
                <v-row class="align-center">
                  <v-col style="padding-top: 0" :cols="12" :md="7">
                    <span class="area-label">ІПН:</span>
                    <v-text-field
                      id="cp-ac-ind-pp"
                      v-model="contractorData.inn"
                      v-mask:[innMask]
                      :readonly="readonly"
                      :error-messages="innErr"
                      placeholder="Введіть текст"
                      dense
                      :maxlength="12">
                    </v-text-field>
                  </v-col>
                  <v-col style="padding-top: 0" :cols="12" :md="5">
                    <v-checkbox
                      v-model="contractorData.isVatPayer"
                      :readonly="readonly"
                      class="mt-0"
                      color="red"
                      hide-details
                      dense>
                      <template #label>
                        <span style="font-size: 0.8rem"> Платник ПДВ </span>
                      </template>
                    </v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col :cols="12" :md="5" class="pt-0">
                <span class="area-title"
                  >Дата державної реєстрації та номер <br />
                  запису в Єдиному державному реєстрі</span
                >
              </v-col>
              <v-col :cols="12" :md="7" class="pt-0">
                <v-row class="align-center">
                  <v-col style="padding-top: 0" :cols="12" :md="7" class="pt-0">
                    <span class="area-label">Номер реєстрації</span>
                    <v-text-field
                      id="cp-ac-num-reg"
                      v-model="contractorData.stateRegistrationNumber"
                      :readonly="readonly"
                      placeholder="Введіть текст"
                      dense
                      @input="maxNumLength('cp-ac-num-reg', 20)">
                    </v-text-field>
                  </v-col>
                  <v-col style="padding-top: 0" :cols="12" :md="5" class="pt-0">
                    <span class="area-label d-block">Дата реєстрації</span>
                    <DatePicker
                      v-model="contractorData.stateRegistrationDate"
                      style="margin-bottom: 15px; margin-top: 10px"
                      single
                      :readonly="readonly" />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col :cols="12" :md="5" class="pt-0">
                <span class="area-title">Види діяльності</span>
              </v-col>
              <v-col :cols="12" :md="7" class="pt-0">
                <queds-table
                  v-model:queds="contractorData.queds"
                  v-model:mainQuedId="contractorData.mainQuedId"
                  :readonly="readonly"
                  :mainQuedObj="contractorData.mainQued">
                </queds-table>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </div>
    <div id="contact-section" class="pr-1">
      <div>
        <Contacts
          type="phone"
          :items="contractor.phones"
          :readonly="readonly"
          :entityTypeId="2"
          :entityId="contractorData.id"
          :checkFunc="checkPhone" />
        <Contacts
          style="margin-top: -10px"
          type="email"
          :items="contractor.emails"
          :readonly="readonly"
          :entityTypeId="2"
          :entityId="contractorData.id"
          :checkFunc="checkEmail" />
      </div>
    </div>
    <address-table
      class="mt-2"
      :readonly="readonly"
      :addresses="contractorData.addresses"
      :entityId="contractorData.id"
      :entityTypeId="2">
    </address-table>
    <list-section
      id="bank-section"
      style="margin-top: 0 !important"
      :title="'Банківські реквiзити'"
      :addActionDisabled="readonly"
      :addActionTitle="'Додати рахунок'"
      :addAction="addBankAccount">
      <template #body>
        <bank-account-table
          :readonly="readonly"
          class="mt-2"
          :bankAccounts="contractorData.currentAccounts || []"
          @open-dialog="$emit('openDialog', $event)"
          @item-deleted="$emit('tableChange')">
        </bank-account-table>
      </template>
    </list-section>
  </div>
</template>
<script>
import DatePicker from '@/components/DatePicker.vue'
import Contacts from '@/components/Contacts/Contacts.vue'
import listSection from '@/components/list-section.vue'
import addressTable from '@/components/address-table.vue'
import bankAccountTable from '@/components/bank-account-table.vue'
import quedsTable from '@/components/queds/queds-table.vue'
import { formutSum, keepDigit, changeInputVal } from '@/utils/formatFunc'
import { setErrHandler } from '@/utils/helperFunc'
import {
  urlDeleteContractorAddress,
  urlCreateContractorAddress,
} from '@/pages/request.js'
import { mapState } from 'vuex'
import { innMask, maxNumLength } from '@/utils/masks'
import Loader from '@/components/Loader.vue'
import { useVuelidate } from '@vuelidate/core'
import { useSelect } from '@/utils/mixins/useSelect'
export default {
  components: {
    listSection,
    addressTable,
    bankAccountTable,
    quedsTable,
    Contacts,
    DatePicker,
    Loader,
  },
  setup() {
    return { v$: useVuelidate(), ...useSelect(), innMask }
  },
  props: {
    readonly: { type: Boolean },
    contractor: { type: Object },
    openDataLoading: { type: Boolean },
    checkEmail: { type: Function },
    checkPhone: { type: Function },
    v: { type: Object },
  },
  data: () => ({
    tHeadAddresses: [
      { title: 'Тип', key: 'type.name', sortable: false },
      { title: 'Країна', key: 'country.name', sortable: false },
      { title: 'Область', key: 'region.region', sortable: false },
      { title: 'Район', key: 'district.district', sortable: false },
      { title: 'Мiсто', key: 'city.name', sortable: false },
      { title: 'Адреса', key: 'address', sortable: false },
      { title: 'Iндекс', key: 'index', sortable: false },
      { title: 'Дiї', key: 'actions', sortable: false, align: 'center' },
    ],
    loading: false,
    showAllQueds: false,
    showQuedsNum: 10,
  }),
  computed: {
    ...mapState({
      selectItems: state => state.selectItems,
    }),
    validation() {
      return { v$: this.v$.contractorData, name: 'contractor' }
    },
    innErr() {
      return setErrHandler(this.v$.contractorData?.inn)
    },
    contractorData() {
      return this.contractor
    },
  },
  methods: {
    urlCreateContractorAddress,
    maxNumLength,
    formutSum,
    keepDigit,
    changeInputVal,
    urlDeleteContractorAddress,
    addBankAccount() {
      const accounts = this.contractorData.currentAccounts
        ? this.contractorData.currentAccounts.map(account => account.type.id)
        : []
      this.$emit('openDialog', ['addBankAccount', { accounts }])
    },
    toggleSync() {
      if (
        this.contractorData.edrpou &&
        this.contractorData.edrpou.length === 8
      ) {
        this.$emit('syncWithOpenData')
        this.loading = !this.loading
      } else this.$setSnackbar({ text: 'Не вiрний ЄДРПОУ', color: 'error' })
    },
  },
  watch: {
    loading(val) {
      val &&
        setTimeout(() => {
          this.loading = !val
        }, 2500)
    },
  },
}
</script>

<style scoped>
.area-title {
  font-weight: 600;
  font-size: 14px;
  color: #1c1d21;
}
</style>
