<template>
  <div>
    <div v-if="calculationRoute">
      <RouterView :calculation="calculation" />
    </div>
    <span v-if="!calculation">Калькуляція на доопрацюванні</span>
    <div
      v-if="!calculationRoute"
      style="padding-top: 10px"
      class="verification-card">
      <div v-if="provider">
        <component
          :is="providerContractor ? 'ContractorCard' : 'ContactCard'"
          :contact="provider"
          :contractor="provider"
          :isNew="ssAnalyze.status?.id === 1 && roleAccess"
          legalDocs
          finDocs />
        <div class="white-block" style="margin-top: 10px">
          <v-row>
            <v-col :cols="12" :md="3">
              <span>Коментар:</span>
            </v-col>
            <v-col :cols="12" :md="9">
              <v-textarea
                v-model="providerConclusion"
                class="pt-0"
                :rows="3"
                placeholder="Напишіть коментар"
                hide-details
                :disabled="!isInWork"
                :readonly="!isInWork">
              </v-textarea>
            </v-col>
          </v-row>
        </div>

        <ActionButtons
          class="mt-5"
          :confirm="() => providerConclusionFunc(1)"
          :cancel="() => providerConclusionFunc(2)"
          confirmBtnText="Позитивне рішення"
          cancelBtnText="Негативне рішення"
          :confirmLoading="false"
          :cancelLoading="false"
          :cancelDisable="!isInWork || providerResultId === 2"
          :confirmDisable="!isInWork || providerResultId === 1">
        </ActionButtons>
      </div>
      <div v-else>
        <span class="justify-center" style="background: transparent">
          Данi Відсутні
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import ActionButtons from '@/components/action-buttons.vue'
import ContactCard from '@/components/ContactCard.vue'
import ContractorCard from '@/components/ContractorCard.vue'
import {
  getAddress,
  contactDetailsWithType,
  getPassport,
} from '@/utils/helperFunc'
import { formutSum } from '@/utils/formatFunc'
import { mapState } from 'vuex'
export default {
  emits: ['setConclusion'],
  components: {
    ContactCard,
    ContractorCard,
    ActionButtons,
  },
  props: {
    calculation: { type: Object },
    provider: { type: Object },
    ssAnalyze: { type: Object },
    id: { type: String },
    isInWork: { type: Boolean },
    conclusionsState: { type: Object },
  },
  data: () => ({
    providerConclusion: null,
  }),
  computed: {
    ...mapState({
      user: state => state.user,
    }),
    roleAccess() {
      return [2, 9].includes(this.user?.role?.id)
    },
    providerContractor() {
      return this.provider.providerTypeId === 2
    },
    providerContact() {
      return this.provider.providerTypeId !== 2
    },
    providerResultId() {
      const results = this.ssAnalyze.providerResults
      if (!results || !results.length) return null
      const result = results.find(
        r => `${r.providerTypeId}${r.providerId}` === this.provider.providerId
      )
      return result ? result.providerResultId : null
    },
    calculationRoute() {
      return this.$route.name === 'ss-leasing-object'
    },
  },
  methods: {
    getAddress,
    contactDetailsWithType,
    formutSum,
    getPassport,
    providerConclusionFunc(resultId) {
      const request = {
        providerResultId: resultId,
        providerConclusion: this.providerConclusion,
        providerId: this.provider.id,
        providerTypeId: this.provider.providerTypeId,
        type: 2,
      }
      this.$emit('setConclusion', { request })
    },
  },
  watch: {
    '$route.params.providerIdx': {
      handler() {
        if (this.ssAnalyze && this.ssAnalyze.providerResults) {
          this.providerConclusion =
            this.ssAnalyze.providerResults.find(
              r =>
                `${r.providerTypeId}${r.providerId}` ===
                this.provider.providerId
            )?.providerConclusion || null
        }
      },
      immediate: true,
    },
    conclusionsState: {
      handler(val) {
        if (val && val.providerResults) {
          this.providerConclusion =
            val.providerResults.find(
              p =>
                `${p.providerTypeId}${p.providerId}` ===
                this.provider.providerId
            )?.providerConclusion || null
        }
      },
      deep: true,
      immediate: true,
    },
  },
}
</script>
