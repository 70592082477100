/**
 * Leasing advantages - section (Static)
 *
 */
import { style } from './styles'

const operativeLeasingAdvantages = () => {
  return [
    [
      {
        margin: [0, 55, 0, 0],
        columns: [
          {
            width: '65%',
            text: 'ЛІЗИНГОВИЙ ПЛАТІЖ',
            margin: [0, 45, 0, 1],
            fontSize: 13,
            bold: true,
          },
          {
            width: '35%',
            text: 'ВЖЕ ВКЛЮЧАЄ:',
            margin: [0, 45, 0, 1],
            alignment: 'right',
            fontSize: 13,
            bold: true,
          },
        ],
      },
    ],
    {
      margin: [0, 15, 0, 0],
      layout: {
        hLineColor: () => '#e0e0e0',
        vLineColor: () => '#e0e0e0',
        paddingRight: () => 0,
        paddingLeft: () => 0,
      },
      table: {
        widths: ['30%', '70%'],
        body: [
          [
            {
              text: 'Фінансування',
              fillColor: style.colors.mainRed,
              color: 'white',
              margin: [5, 10],
              rowSpan: 2,
            },
            {
              columns: [
                {
                  text: 'Вартість об’єкту',
                  fontSize: 10.5,
                  margin: [5, 0],
                  width: '90%',
                },
                {
                  image: 'checkIcon',
                  margin: [5, 0],
                  fit: [11, 11],
                  alignment: 'right',
                  width: '10%',
                },
              ],
            },
          ],
          [
            '',
            {
              columns: [
                {
                  text: 'Вартість фінансування',
                  fontSize: 10.5,
                  margin: [5, 5, 0, 0],
                  width: '90%',
                },
                {
                  image: 'checkIcon',
                  margin: [15, 5, 0, 0],
                  fit: [11, 11],
                  alignment: 'right',
                  width: '10%',
                },
              ],
            },
          ],
        ],
      },
    },
    {
      margin: [0, 6],
      layout: {
        hLineColor: () => '#e0e0e0',
        vLineColor: () => '#e0e0e0',
        paddingRight: () => 0,
        paddingLeft: () => 0,
      },
      table: {
        widths: ['30%', '70%'],
        body: [
          [
            {
              text: 'Реєстрація',
              fillColor: style.colors.mainRed,
              color: 'white',
              margin: [5, 7],
              rowSpan: 2,
            },
            {
              columns: [
                {
                  text: 'Реєстраційні платежi',
                  fontSize: 10.5,
                  margin: [5, 0],
                  width: '90%',
                },
                {
                  image: 'checkIcon',
                  margin: [5, 0],
                  fit: [11, 11],
                  alignment: 'right',
                  width: '10%',
                },
              ],
            },
          ],
          [
            '',
            {
              columns: [
                {
                  text: 'Пенсійний фонд',
                  fontSize: 10.5,
                  margin: [5, 0],
                  width: '90%',
                },
                {
                  image: 'checkIcon',
                  margin: [5, 0],
                  fit: [11, 11],
                  alignment: 'right',
                  width: '10%',
                },
              ],
            },
          ],
        ],
      },
    },
    {
      margin: [0, 6],
      layout: {
        hLineColor: () => '#e0e0e0',
        vLineColor: () => '#e0e0e0',
        paddingRight: () => 0,
        paddingLeft: () => 0,
      },
      table: {
        widths: ['30%', '70%'],
        body: [
          [
            {
              text: 'Страхування',
              fillColor: style.colors.mainRed,
              color: 'white',
              margin: [5, 10],
              rowSpan: 2,
            },
            {
              columns: [
                {
                  text: 'Повне КАСКО (на весь термін лізингу)',
                  fontSize: 10.5,
                  margin: [5, 0],
                  width: '90%',
                },
                {
                  image: 'checkIcon',
                  margin: [5, 0],
                  fit: [11, 11],
                  alignment: 'right',
                  width: '10%',
                },
              ],
            },
          ],
          [
            '',
            {
              columns: [
                {
                  text: 'ОСЦПВ (на весь термін лізингу)',
                  fontSize: 10.5,
                  margin: [5, 0, 5, -10],
                  width: '90%',
                },
                {
                  image: 'checkIcon',
                  margin: [5, 0, 5, -10],
                  fit: [11, 11],
                  alignment: 'right',
                  width: '10%',
                },
              ],
            },
          ],
        ],
      },
    },
    {
      margin: [0, 6],
      layout: {
        hLineColor: () => '#e0e0e0',
        vLineColor: () => '#e0e0e0',
        paddingRight: () => 0,
        paddingLeft: () => 0,
      },
      table: {
        widths: ['30%', '70%'],
        body: [
          [
            {
              text: 'Сервіс',
              fillColor: style.colors.mainRed,
              color: 'white',
              margin: [5, 15],
              rowSpan: 8,
            },
            {
              columns: [
                {
                  text: 'Пакет "..."',
                  fontSize: 10.5,
                  margin: [5, 0],
                  width: '90%',
                },
                {
                  text: '...',
                  margin: [5, 0, 10, 0],
                  alignment: 'right',
                  width: '10%',
                  color: '#d7514f',
                },
              ],
            },
          ],
          [
            '',
            {
              columns: [
                {
                  text: 'Плановий пробiг, км/рiк',
                  fontSize: 10.5,
                  margin: [5, 0],
                  width: '90%',
                },
                {
                  margin: [5, 0, 10, 0],
                  alignment: 'right',
                  text: '...',
                  color: '#d7514f',
                  width: '10%',
                },
              ],
            },
          ],
          [
            '',
            {
              columns: [
                {
                  text: 'Знижка на сервісне обслуговування (ТО)',
                  fontSize: 10.5,
                  margin: [5, 0],
                  width: '82%',
                },
                {
                  margin: [5, 0],
                  alignment: 'right',
                  width: '18%',
                  text: 'до 20%',
                  bold: true,
                  color: '#d7514f',
                },
              ],
            },
          ],
          [
            '',
            {
              columns: [
                {
                  text: 'Додатковий комплект гуми, шт',
                  fontSize: 10.5,
                  margin: [5, 0],
                  width: '90%',
                },
                {
                  margin: [5, 0, 10, 0],
                  text: '...',
                  alignment: 'right',
                  width: '10%',
                  color: '#d7514f',
                },
              ],
            },
          ],
          [
            '',
            {
              columns: [
                {
                  text: 'Сезонний шиномонтаж',
                  fontSize: 10.5,
                  margin: [5, 0],
                  width: '90%',
                },
                {
                  text: '...',
                  margin: [5, 0, 10, 0],
                  alignment: 'right',
                  width: '10%',
                  color: '#d7514f',
                },
              ],
            },
          ],
          [
            '',
            {
              columns: [
                {
                  text: 'Зберігання шин',
                  fontSize: 10.5,
                  margin: [5, 0],
                  width: '90%',
                },
                {
                  text: '...',
                  margin: [5, 0, 10, 0],
                  alignment: 'right',
                  width: '10%',
                  color: '#d7514f',
                },
              ],
            },
          ],
          [
            '',
            {
              columns: [
                {
                  text: 'Підмінний автомобіль, днiв/рiк',
                  fontSize: 10.5,
                  margin: [5, 0],
                  width: '90%',
                },
                {
                  text: '...',
                  margin: [5, 0, 10, 0],
                  alignment: 'right',
                  width: '10%',
                  color: '#d7514f',
                },
              ],
            },
          ],
          [
            '',
            {
              columns: [
                {
                  text: 'Пiдбiр-Доставка авто, разiв/рiк',
                  fontSize: 10.5,
                  margin: [5, 0],
                  width: '90%',
                },
                {
                  text: '...',
                  margin: [5, 0, 10, 0],
                  alignment: 'right',
                  width: '10%',
                  color: '#d7514f',
                },
              ],
            },
          ],
        ],
      },
    },
  ]
}

export { operativeLeasingAdvantages }
