<template>
  <div>
    <NoData v-if="!contractor.projects" />
    <ProjectCard
      v-for="(project, idx) in filteredProjects"
      :key="project.id + '_' + idx"
      :project="project"
      :entityTypeId="2" />
  </div>
</template>
<script>
import ProjectCard from '@/components/ProjectCard.vue'
import NoData from '@/components/no-table-data.vue'
import { mapState } from 'vuex'
import { formatDate, humanDate } from '@/utils/formatFunc'
export default {
  components: {
    ProjectCard,
    NoData,
  },
  props: {
    contractor: { type: Object },
    pageLoad: { type: Boolean },
  },
  data: () => ({
    tableHeader: [
      { title: 'Статус', key: 'status.name', align: 'start', sortable: false },
      {
        title: '№ проекту',
        key: 'projectNumber',
        align: 'center',
        sortable: false,
      },
      { title: 'Дата', key: 'created_at', align: 'center', sortable: false },
      { title: 'Програма', key: 'program', align: 'center', sortable: false },
      {
        title: 'Кількість ОЛ',
        key: 'LOAmount',
        align: 'center',
        sortable: false,
      },
    ],
    filterDate: null,
  }),
  computed: {
    ...mapState({ selectItems: state => state.selectItems }),
    id() {
      return this.$route?.params?.id
    },
    contractorProjects() {
      return this.selectItems.projects.filter(
        p => p.lesseeId.contractorId == this.id
      )
    },
    filteredProjects() {
      if (!this.filterDate) {
        return this.contractor.projects
      }
      return this.contractor.projects.filter(project => {
        const createdAt = new Date(project.created_at)
        return createdAt >= this.filterDate
      })
    },
  },
  methods: {
    formatDate,
    humanDate,
  },
}
</script>
