<template>
  <div>
    <v-text-field
      v-model="application.serviceApplicationName"
      label="Назва заявки"
      :error-messages="serviceApplicationNameErr"
      @blur="v$.application.serviceApplicationName.$touch()">
    </v-text-field>
    <v-select
      v-model="application.typeId"
      label="Тип"
      :items="directoryItems('serviceApplicationTypes')"
      item-title="name"
      item-value="id"
      :error-messages="typeIdErr"
      @blur="v$.application.typeId.$touch()">
      <template #no-data><Loader /></template>
    </v-select>
    <v-text-field v-model="application.surname" label="Прізвище">
    </v-text-field>
    <v-text-field
      v-model="application.name"
      label="Ім'я"
      :error-messages="nameErr"
      @blur="v$.application.name.$touch()">
    </v-text-field>
    <v-text-field v-model="application.patronymic" label="По батькові">
    </v-text-field>
  </div>
</template>

<script>
import Loader from '@/components/Loader.vue'
import { required } from '@vuelidate/validators'
import { setErrHandler } from '@/utils/helperFunc'
import { urlCreateServiceApplication } from '@/pages/request'
import { useVuelidate } from '@vuelidate/core'
import { useSelect } from '@/utils/mixins/useSelect'

export default {
  components: { Loader },
  setup() {
    return { v$: useVuelidate(), ...useSelect() }
  },
  validations() {
    return {
      application: {
        serviceApplicationName: { required },
        sourceId: { required },
        typeId: { required },
        name: { required },
      },
    }
  },
  data() {
    return {
      application: {
        serviceApplicationName: null,
        sourceId: 3,
        typeId: null,
        name: null,
        patronymic: null,
        surname: null,
      },
    }
  },
  computed: {
    serviceApplicationNameErr() {
      return setErrHandler(this.v$.application.serviceApplicationName)
    },
    typeIdErr() {
      return setErrHandler(this.v$.application.typeId)
    },
    nameErr() {
      return setErrHandler(this.v$.application.name)
    },
  },
  methods: {
    submit() {
      return this.$axios.post(urlCreateServiceApplication(), this.application)
    },
  },
  created() {
    this.asyncAction('addServiceApplicationTypes')
  },
}
</script>

<style lang="scss" scoped></style>
