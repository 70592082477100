<template>
  <v-card-text>
    <Fields class="mb-3" :fields="fields[0]" direction="row" />
    <Fields class="mb-3" :fields="fields[1]" direction="row" />
    <v-row>
      <v-col :cols="12" :md="2">
        <EditableField
          v-model="calculation.vinCode"
          class="mb-3"
          :readonly="readonly"
          :minWidth="200">
          <template #label>
            <span style="font-weight: 600">VIN-код</span>
          </template>
        </EditableField>
      </v-col>
      <v-col :cols="12" :md="6">
        <EditableField
          v-model="contract.currencyCourse"
          :inputType="'number'"
          class="mb-3"
          :readonly="readonly"
          :minWidth="200">
          <template #label>
            <span style="font-weight: 600">Курс</span>
          </template>
        </EditableField>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="12" :md="6" class="pt-0 pb-0">
        <span class="label">Місце відвантаження (заповнюється вручну)</span>
        <div>
          <v-text-field
            v-model="calculation.placeOfShipment"
            placeholder="Введіть адресу"
            dense
            :readonly="readonly"
            :disabled="endorsement">
          </v-text-field>
        </div>
        <v-fade-transition hide-on-leave>
          <div>
            <v-checkbox
              v-model="contract.isGuarantees"
              class="mt-0"
              color="#fc7247"
              :readonly="readonly"
              hide-details
              dense
              size="small"
              @update:model-value="handleGuarantees">
              <template #label>
                <span style="font-size: 12px">Наявність гарантії </span>
              </template>
            </v-checkbox>
            <v-text-field
              v-show="contract.isGuarantees"
              id="guarantees-month"
              ref="quantityMonthGuarantees"
              v-model="contract.quantityMonthGuarantees"
              :readonly="!contract.isGuarantees"
              :disabled="readonly || !contract.isGuarantees"
              :error-messages="quantityMonthGuaranteesErr"
              dense
              @blur="$v.contract.quantityMonthGuarantees.$touch()">
            </v-text-field>
          </div>
        </v-fade-transition>
      </v-col>
      <v-col :cols="12" :md="6" class="pt-0 pb-0">
        <div>
          <div style="font-weight: 600">Специфікація</div>
          <v-hover>
            <template #default="{ isHovering, props }">
              <div v-bind="props" class="d-flex align-center gap-2 mt-2">
                <div>
                  <TextShort
                    class="mt-3"
                    :class="{
                      disabled: !specification,
                      'cursor-pointer': readonly,
                    }"
                    @click="() => readonly && openSpecificationDialog()">
                    {{ specification || 'Не вказано' }}
                  </TextShort>
                </div>
                <v-fade-transition hide-on-leave>
                  <v-btn
                    v-show="isHovering && !readonly"
                    class="mr-3"
                    size="x-small"
                    density="compact"
                    icon
                    @click="openSpecificationDialog">
                    <v-icon color="red" size="small">
                      {{ 'mdi-pencil' }}
                    </v-icon>
                  </v-btn>
                </v-fade-transition>
              </div>
            </template>
          </v-hover>
          <FilesUploader
            v-model:documents="calculation.specificationDocuments"
            class="mb-1"
            label="Специфікація"
            :list="false"
            :readonly="readonly">
          </FilesUploader>
        </div>
      </v-col>
    </v-row>
    <v-row class="align-center mt-2">
      <v-col :cols="12" :md="6">
        <div class="mb-3">
          <span class="label">Порядок розрахункiв за ДКП</span>
          <v-select
            v-model="contract.orderCalculationId"
            :items="
              directoryItems('dkpOrderCalculations', contract.orderCalculation)
            "
            :error-messages="orderCalculationIdErr"
            item-value="id"
            hide-details
            item-title="name"
            placeholder="оберiть порядок"
            :disabled="readonly || endorsement"
            @focus="asyncAction('addDkpOrderCalculations')"
            @blur="$v.contract.orderCalculationId.$touch()">
          </v-select>
        </div>
      </v-col>
      <v-col :cols="12" :md="6" class="pt-0 pb-0">
        <div style="margin-top: 10px">
          <span class="label">Співвідношення авансу (залишок) у %</span>
          <div>{{ `${calculation.prepayToSupplier || 0}` + '%' }}</div>
        </div>
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
import TextShort from '@/components/TextShort.vue'
import Fields from '@/components/Fields.vue'
import EditableField from '@/components/EditableField.vue'
import FilesUploader from '@/components/FilesUploader.vue'
import { mapState } from 'vuex'
import {
  getBeautyNum,
  LODescription as getLoDescription,
} from '@/utils/helperFunc'
import { setErrHandler, toFormatDate } from '@/utils/helperFunc'
import { maxNumLength } from '@/utils/masks'
import { useSelect } from '@/utils/mixins/useSelect'

export default {
  components: {
    TextShort,
    Fields,
    FilesUploader,
    EditableField,
  },
  setup() {
    return {
      ...useSelect(),
    }
  },
  props: {
    endorsement: { type: Boolean },
    readonly: { type: Boolean },
  },
  computed: {
    ...mapState({
      contract: state => state.contracts.dkpContract,
      selectItems: state => state.selectItems,
      calculation: state => state.contracts.dkpContract.calculation,
      $v: state => state.contracts.$vDkp,
    }),
    LO() {
      return this.getLoDescription(this.calculation)
    },
    orderCalculationIdErr() {
      return this.setErrHandler(this.$v.contract.orderCalculationId)
    },
    quantityMonthGuaranteesErr() {
      return this.setErrHandler(this.$v.contract.quantityMonthGuarantees)
    },
    specification() {
      const str = this.contract.specificationText || ''
      return str.length > 47 ? `${str.substring(0, 46)}...` : str
    },
    currency() {
      switch (this.calculation?.requestData?.leasingCurrency) {
        case 'UAH':
          return 'грн'
        case 'USD':
          return '$'
        case 'EUR':
          return '€'
        default:
          return console.warn(
            'calculation.requestData.leasingCurrency - invalid value'
          )
      }
    },
    amountDkp() {
      return (
        this.calculation.requestData.leasingAmountDkp *
        this.contract.currencyCourse
      )
    },
    generalAmountDkp() {
      return (
        this.calculation.requestData.leasingAmount *
        this.contract.currencyCourse
      )
    },
    fields() {
      return [
        [
          {
            label: "Об'єкт лізингу",
            value: this.LO.description({ detailed: true }),
          },
        ],
        [
          {
            label: 'Вартість',
            value: `${getBeautyNum(this.amountDkp, {
              float: 2,
            })} грн`,
          },
          {
            label: 'Загальна вартість',
            value: `${getBeautyNum(this.generalAmountDkp, {
              float: 2,
            })} грн`,
          },
          {
            label: 'Вартість додаткового обладнання',
            value: this.LO.additionalEquipSum,
          },
        ],
        [
          {
            label: 'Курс',
            slot: 'currencyCourse',
          },
        ][{ label: 'Vin-код', slot: 'vinCode' }],
        [{ label: 'Специфікація', slot: 'specification' }],
      ]
    },
  },
  methods: {
    getBeautyNum,
    getLoDescription,
    setErrHandler,
    toFormatDate,
    maxNumLength,
    openSpecificationDialog() {
      this.$store.commit('setDialog', {
        title: 'Специфікація',
        dialogItem: {
          specification: this.contract.specificationText || '',
        },
        params: {
          cb: data => (this.contract.specificationText = data),
          dialogWidth: '700px',
          readonly: this.readonly,
          hideActions: this.readonly,
        },
        action: 'editSpecification',
      })
    },
    handleGuarantees(v) {
      if (v) {
        this.$nextTick(() => this.$refs.quantityMonthGuarantees.focus())
      } else {
        this.$store.commit('clearDkpContractGuaranteesMonth')
      }
    },
  },
}
</script>

<style scoped>
.label {
  display: block;
  color: #838282;
  font-size: 12px;
}
</style>
