const baseUrl = 'https://api.dmsolutions.com.ua:2661'

function getToken(usr, pswd) {
  const credentials = new URLSearchParams({
    username: usr,
    password: pswd,
    grant_type: 'password',
  })
  return fetch(baseUrl + '/Token', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    body: credentials.toString(),
  })
    .then(res => {
      if (res.ok) return res.json()
    })
    .catch(handleErr)
}

function getCities(token, city) {
  if (!token) throw new Error('Token required')
  if (!city) throw new Error('city - required parameter')
  return fetch(baseUrl + `/api/Cities?sRequest=${city}&sLang=uk_UA`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then(res => {
      return res.ok ? res.json() : []
    })
    .catch(handleErr)
}

function getStreets(token, moniker, street) {
  if (!token) throw new Error('Token required')
  if (!street) throw new Error('street - required parameter')
  if (!moniker) throw new Error('moniker - required parameter')
  return fetch(
    baseUrl +
      `/api/Streets?sRequest=${street}&stMoniker=${moniker}&sLang=uk_UA`,
    {
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    }
  )
    .then(res => {
      if (res.ok) return res.json()
    })
    .catch(handleErr)
}

function getHouses(token, houseMoniker, houseNum) {
  if (!token) throw new Error('Token required')
  if (!houseMoniker) throw new Error('houseMoniker - required parameter')
  if (!houseNum) throw new Error('houseNum - required parameter')
  return fetch(
    baseUrl +
      `/api/Houses?sRequest=${houseNum}&houseMoniker=${houseMoniker}&sLang=uk_UA`,
    {
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    }
  )
    .then(res => {
      if (res.ok) return res.json()
    })
    .catch(handleErr)
}

function getFullAddress(token, string) {
  if (!token) throw new Error('Token required')
  if (!string) throw new Error('string - required parameter')
  return fetch(baseUrl + `/api/Address?sRequest=${string}&sLang=uk_UA`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then(res => {
      if (res.ok) return res.json()
    })
    .catch(handleErr)
}

async function generateAddress(token, address) {
  if (!token) throw new Error(`getEdrAddress: Token required ${token}`)
  if (!address || !address.parts || !address.zip) return

  function check(param, paramName) {
    if (!param) throw new Error(`getEdrAddress: "${paramName}" - required`)
  }
  function getCity(city) {
    if (!city) {
      throw new Error('City is required')
    }

    if (city.split(',').length > 1) {
      const cityPart = city.split(',').find(p => p.includes('місто'))

      if (cityPart) {
        return cityPart.replace(/місто[\s+]/gi, '').trim()
      } else {
        return city.trim()
      }
    } else {
      return city.replace(/місто[\s+]/gi, '').trim()
    }
  }

  function getStreet(street) {
    if (!street) return
    return street.replace(/ВУЛИЦЯ/gi, '').trim()
  }
  function getHouse(house) {
    if (!house) return
    return house.match(/^(\d+)/gi)[0]
  }
  function getZip(zip) {
    check(zip, 'zip')
    return zip
  }
  function up(str) {
    if (!str) return
    return str.toString().toUpperCase()
  }

  try {
    const parts = address.parts
    const cityString = getCity(parts.atu)
    const streetString = getStreet(parts.street)
    const houseString = getHouse(parts.house)
    const zipString = getZip(address.zip)

    let city, street, house
    city = await (cityString
      ? // @ts-ignore
        getCities(token, cityString)
      : Promise.resolve({
          City: cityString,
          Id: 1,
          st_moniker: 1,
        })
    ).then(res => {
      if (Array.isArray(res)) {
        return (city = res.find(cs => up(cs.City) === up(cityString)))
      } else return (city = res)
    })
    // @ts-ignore
    street = await (streetString && city?.st_moniker
      ? // @ts-ignore
        getStreets(token, city.st_moniker, streetString)
      : Promise.resolve({
          Street: streetString || null,
          StreetId: 1,
          house_moniker: 1,
        })
    ).then(res => {
      if (Array.isArray(res)) {
        return (street = res.find(cs => up(cs.Street) === up(streetString)))
      } else return (street = res)
    })
    // @ts-ignore
    house = await (houseString && street?.house_moniker
      ? // @ts-ignore
        getHouses(token, street.house_moniker, houseString)
      : Promise.resolve({
          HouseId: 1,
          HouseNum: houseString,
          Index_: zipString,
        })
    ).then(res => {
      if (Array.isArray(res)) {
        return (house = res.find(
          cs =>
            up(cs.HouseNum) === up(houseString) ||
            up(cs.HouseNum).includes(up(houseString))
        ))
      } else return (house = res)
    })

    if (cityString && zipString) {
      return Promise.all([city, street, house]).then(arr => {
        return {
          addressTypeId: 1,
          country: 'Україна',
          city: arr[0],
          street: arr[1],
          house: arr[2],
        }
      })
    } else {
      throw new Error(
        `getEdrAddress: "city" and "zip" required. city ${cityString} zip: ${zipString}`
      )
    }
  } catch (err) {
    console.log(err)
    throw err
  }
}

function handleErr(err) {
  console.log(err)
  throw err
}

export {
  getToken,
  getCities,
  getStreets,
  getHouses,
  getFullAddress,
  generateAddress,
}
