<template>
  <div class="d-flex justify-center">
    <v-progress-circular
      :width="width"
      :size="size"
      :color="color"
      indeterminate
    ></v-progress-circular>
  </div>
</template>

<script>
export default {
  props: {
    size: { type: Number, default: 32 },
    width: { type: Number, default: 2 },
    color: { type: String, default: 'primary' },
  }
}
</script>

<style lang="scss" scoped></style>