<template>
  <div>
    <v-row>
      <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
        <span class="label">Тип застави</span>
        <v-select
          v-model="project.pledgeTypeId"
          item-title="name"
          item-value="id"
          placeholder="Оберіть зі списку"
          hide-details
          :items="selectItems.pledgeTypes.filter(t => t.id !== 3)">
        </v-select>
      </v-col>
      <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
        <span class="label">Заставодавець</span>
        <v-autocomplete
          v-model="project.mortgagor"
          :items="selectItems.mortgagorsList"
          :error-messages="mortgagorIdErr"
          :item-value="
            deleteProjectContactMortgagor || deleteProjectContractorMortgagor
              ? 'name'
              : 'uuid'
          "
          placeholder="Оберіть зі списку"
          hide-details
          item-title="name"
          return-object
          :disabled="
            deleteProjectContactMortgagor || deleteProjectContractorMortgagor
          "
          :readonly="
            deleteProjectContactMortgagor || deleteProjectContractorMortgagor
          "
          @blur="v$.project.mortgagor.mortgagorId.$touch()">
          <template #item="{ item }">
            <v-list-item @click="selectEntityScope(item.raw)">
              <EntityScope
                :type="item.raw.mortgagorTypeId"
                :name="item.raw.name" />
            </v-list-item>
          </template>
          <template #selection="{ item }">
            <EntityScope
              :type="item.raw.mortgagorTypeId"
              :name="item.raw.name" />
          </template>
        </v-autocomplete>
      </v-col>
      <!-- <v-text-field
        v-if="project.pledgeTypeId === 1"
        v-model="project.pledgeObject"
        @blur="v$.project.pledgeObject.$touch()"
        :error-messages="pledgeObjectErr"
        label="Предмет застави"
        dense
        :readonly="deleteProjectContactMortgagor || deleteProjectContractorMortgagor"
        :disabled="deleteProjectContactMortgagor || deleteProjectContractorMortgagor">
      </v-text-field> -->
    </v-row>
    <h3
      v-if="deleteProjectContactMortgagor || deleteProjectContractorMortgagor">
      {{
        project.pledgeTypeId === 1
          ? 'Заставодавця буде видалено!'
          : 'Заставу майнових прав буде видалено'
      }}
    </h3>
  </div>
</template>
<script>
import EntityScope from '@/components/EntityScopeView.vue'
import { mapState } from 'vuex'
import { required } from '@vuelidate/validators'

import { setErrHandler } from '@/utils/helperFunc'
import {
  urlCreateContactMortgagor,
  urlCreateContractorMortgagor,
  urlDeleteContactMortgagor,
  urlDeleteContractorMortgagor,
  ulrCreatePledgeContract,
} from '@/pages/request'
import { useVuelidate } from '@vuelidate/core'
export default {
  components: { EntityScope },
  setup() {
    return { v$: useVuelidate() }
  },
  props: { dialog: { type: Object } },
  validations() {
    const projectId = this.addProjectPledge ? { required } : {}
    // const pledgeObject = this.addProjectPledge && this.project.pledgeTypeId !== 2 ? { required } : {}
    return {
      project: {
        mortgagor: { mortgagorId: { required } },
        projectId: projectId,
        // pledgeObject: pledgeObject,
      },
    }
  },
  data: () => ({ project: {} }),
  computed: {
    ...mapState({
      selectItems: state => state.selectItems,
    }),
    validation() {
      return { v$: this.v$.project, name: 'projectMortgagors' }
    },
    addProjectPledge() {
      return this.dialog.action === 'addProjectPledge'
    },
    deleteProjectContractorMortgagor() {
      return this.dialog.action === 'deleteProjectContractorMortgagor'
    },
    deleteProjectContactMortgagor() {
      return this.dialog.action === 'deleteProjectContactMortgagor'
    },
    mortgagorIdErr() {
      return setErrHandler(this.v$.project?.mortgagor?.mortgagorId)
    },
    // pledgeObjectErr() {
    //   return setErrHandler(this.v$.project.pledgeObject)
    // },
    sendObject() {
      const pledgeObject =
        this.project.pledgeTypeId === 1
          ? { pledgeObject: this.project.pledgeObject }
          : {}
      return {
        mortgagorTypeId: this.project.mortgagor.mortgagorTypeId,
        mortgagorId: this.project.mortgagor.mortgagorId,
        projectId: this.project.projectId,
        typeId: this.project.mortgagor.mortgagorTypeId,
        ...pledgeObject,
        pledgeTypeId: this.project.pledgeTypeId,
      }
    },
  },
  methods: {
    setErrHandler,
    submit() {
      switch (true) {
        case this.addProjectPledge:
          return this.createProjectPledge()
        case [1, 3].includes(this.project?.mortgagor?.mortgagorTypeId):
          return this.addContactGuarantor()
        case this.project?.mortgagor?.mortgagorTypeId === 2:
          return this.addContractorGuarantor()
        case this.deleteProjectContractorMortgagor:
          return this.deleteContractorMortgagor()
        case this.deleteProjectContactMortgagor:
          return this.deleteContactMortgagor()
        default:
          return Promise.reject('No handler')
      }
    },
    createProjectPledge() {
      return this.$axios.post(ulrCreatePledgeContract(), this.sendObject)
    },
    addContactGuarantor() {
      return this.$axios.post(urlCreateContactMortgagor(), this.sendObject)
    },
    addContractorGuarantor() {
      return this.$axios.post(urlCreateContractorMortgagor(), this.sendObject)
    },
    deleteContractorMortgagor() {
      return this.$axios.delete(
        urlDeleteContractorMortgagor(this.project.mortgagor.mortgagorId)
      )
    },
    deleteContactMortgagor() {
      return this.$axios.delete(
        urlDeleteContactMortgagor(this.project.mortgagor.mortgagorId)
      )
    },
    selectEntityScope(item) {
      this.project.mortgagor = item
    },
  },
  created() {
    this.$store.dispatch('addMortgagorsList')
    this.$store.dispatch('addPledgeTypes')
    Object.assign(this.project, this.dialog.dialogItem)
  },
}
</script>
