<template>
  <div
    v-if="getItems"
    class="d-flex align-center px-2"
    :class="{
      'justify-space-between': total,
      'justify-center': !pagination || !total,
    }">
    <div v-if="total" style="width: 10%"></div>
    <v-pagination
      active-color="#09487a"
      variant="elevated"
      :model-value="+curPage"
      :length="totalPage"
      density="comfortable"
      :total-visible="7"
      @update:model-value="onPaginate">
    </v-pagination>

    <span v-if="total" class="count">Всього: {{ getBeautyNum(total) }}</span>
  </div>

  <div
    v-else
    class="d-flex align-center px-2"
    :class="{
      'justify-space-between': total,
      'justify-center': !pagination || !total,
    }">
    <div v-if="total" style="width: 10%"></div>
    <v-pagination
      active-color="#09487a"
      variant="elevated"
      :model-value="+pagination.curPage"
      :length="pagination.totalPage"
      density="comfortable"
      :total-visible="7"
      @update:model-value="onPaginate">
    </v-pagination>

    <span v-if="total" class="count">Всього: {{ total }}</span>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getBeautyNum } from '@/utils/helperFunc'

export default {
  props: {
    getItems: { type: [Function, Object], default: null },
    trigger: { required: false },
    keepQuery: { type: Boolean, default: true },
  },
  data() {
    return {
      curPage: 0,
      totalPage: 0,
      source: null,
      total: 0,
    }
  },
  computed: {
    ...mapState({ pagination: state => state.pagination.pagination }),
  },
  methods: {
    getBeautyNum,
    onPaginate(page = this.$route.query.page || 1) {
      if (this.getItems) {
        this.keepQuery &&
          this.$router.push({ name: this.$route.name, query: { page } })
        this.source && this.source.cancel()
        const newSource = this.$axios.CancelToken.source()
        this.source = newSource
        return this.getItems(page, newSource.token).then(res => {
          if (res) {
            this.curPage = Math.min(res.data.last_page, res.data.current_page)
            this.totalPage = res.data.last_page
            this.total = res.data.total
          }
        })
      }
      this.$store.commit('setCurPage', page)
      // this.$store.commit('setCount', this.count)
    },
  },
  watch: {
    trigger: {
      handler() {
        this.onPaginate()
      },
      deep: true,
      immediate: true,
    },
  },
}
</script>

<style scoped>
.count {
  background: white;
  padding: 4px 10px;
  border-radius: 4px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
</style>
