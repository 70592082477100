<template>
  <v-row>
    <v-col cols="12" md="12" sm="12" class="pt-0">
      <span class="label">Назва ліда</span>
      <v-text-field
        v-model="leadByCall.name"
        :error-messages="nameErr"
        placeholder="Введіть текст"
        @blur="v$.leadByCall.name && v$.leadByCall.name.$touch()">
      </v-text-field>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { setSnackbar } from 'best-modules/plugins'
import { urlCreateAdminLeadCall } from '@/pages/request'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { setErrHandler } from '@/utils/helperFunc'

export default {
  props: {
    dialog: { type: Object },
  },
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
  validations() {
    return {
      leadByCall: {
        name: { required },
      },
    }
  },
  data: () => {
    return {
      leadByCall: {},
    }
  },
  computed: {
    validation() {
      return {
        v$: this.v$.leadByCall,
        name: 'leadByCall',
      }
    },
    nameErr() {
      return setErrHandler(this.v$?.leadByCall?.name)
    },
  },
  methods: {
    submit() {
      return this.$axios
        .post(urlCreateAdminLeadCall(this.dialog.dialogItem.id), {
          name: this.leadByCall.name,
        })
        .then(res => {
          setSnackbar({ text: 'Лід створено' })
          return res
        })
    },
  },
}
</script>

<style scoped></style>
