<template>
  <div>
    <DashboardNavigation @year-counter="yearCounter" />
    <v-row class="pr-3">
      <v-col
        v-if="graphs.leadsPerYear"
        class="pr-0 pb-0"
        cols="12"
        md="4"
        sm="12">
        <div class="dashboard-block">
          <div id="chart">
            <apexchart
              type="pie"
              :options="chartOptionsLeadsPie"
              width="380"
              :series="seriesLeadsPie"></apexchart>
          </div>
        </div>
      </v-col>
      <v-col class="pr-0" cols="12" md="4" sm="12">
        <div class="dashboard-block">
          <div id="chart">
            <apexchart
              type="pie"
              width="380"
              :options="chartOptionsApplicationsPie"
              :series="seriesApplicationsPie"></apexchart>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="graphs.leadsPerMonth" class="pr-3">
      <v-col cols="12" md="6" sm="12" class="pb-0 pt-0 pr-0">
        <div class="dashboard-block">
          <div id="chart">
            <apexchart
              type="area"
              height="350"
              :options="chartOptionsLeads"
              :series="seriesLeads"></apexchart>
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="6" sm="12" class="pr-0 pb-0 pt-0">
        <div class="dashboard-block">
          <div id="chart">
            <apexchart
              type="area"
              height="350"
              :options="chartOptionsApplications"
              :series="seriesApplications"></apexchart>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row class="pr-3">
      <v-col cols="12" md="6" sm="12" class="pr-0">
        <div class="dashboard-block">
          <div id="chart">
            <apexchart
              type="line"
              height="350"
              :options="chartSalesOptions"
              :series="salesSeries"></apexchart>
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="6" sm="12" class="pr-0">
        <div class="dashboard-block">
          <div id="chart">
            <apexchart
              type="line"
              height="350"
              :options="chartMeetingsOptions"
              :series="meetingsSeries"></apexchart>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ChartDataLabels from 'chartjs-plugin-datalabels'
import DashboardNavigation from '@/pages/dashboard/components/DashboardNavigation.vue'
import { getBeautyNum } from '@/utils/helperFunc'
export default {
  components: { DashboardNavigation },
  emits: ['yearCounter'],
  props: {
    graphs: { type: Object },
  },
  data: () => ({
    ChartDataLabels,
    chartOptionsLeads: {
      chart: {
        height: 350,
        type: 'area',
      },
      title: {
        text: 'Конверсія з лідів',
        align: 'left',
        style: {
          fontSize: '14px',
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        categories: [
          'Січ',
          'Лют',
          'Бер',
          'Квіт',
          'Трав',
          'Черв',
          'Лип',
          'Серп',
          'Вер',
          'Жов',
          'Лист',
          'Груд',
        ],
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return value
          },
        },
      },
    },
    chartOptionsApplications: {
      chart: {
        height: 350,
        type: 'area',
      },
      title: {
        text: 'Конверсія з заявок',
        align: 'left',
        style: {
          fontSize: '14px',
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        categories: [
          'Січ',
          'Лют',
          'Бер',
          'Квіт',
          'Трав',
          'Черв',
          'Лип',
          'Серп',
          'Вер',
          'Жов',
          'Лист',
          'Груд',
        ],
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return value
          },
        },
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm',
        },
      },
    },
    chartOptionsLeadsPie: {
      chart: {
        width: 380,
        type: 'pie',
      },
      title: {
        text: 'Ліди',
        align: 'left',
        fontSize: 16,
        fontWeight: 500,
      },
      noData: {
        text: 'Загрузка...',
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          color: undefined,
          fontSize: '14px',
          fontFamily: undefined,
        },
      },
      labels: ['В роботі', 'В заявках', 'В проекті', 'Відвантажено'],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 300,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    },
    chartOptionsApplicationsPie: {
      dataLabels: {
        style: {
          fontWeight: 'normal',
          padding: 4,
        },
      },
      title: {
        text: 'Заявки',
        align: 'left',
        fontSize: 16,
        fontWeight: 500,
      },
      chart: {
        width: 380,
        type: 'pie',
      },
      noData: {
        text: 'Загрузка...',
      },
      labels: ['В роботі', 'В проекті', 'Відвантажено'],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 300,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    },
    chartMeetingsOptions: {
      noData: {
        text: 'Загрузка...',
      },
      title: {
        text: 'Зустрічі',
        align: 'left',
        fontSize: 16,
        fontWeight: 500,
      },
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'],
          opacity: 0.5,
        },
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return getBeautyNum(value, { float: 0 })
          },
        },
      },
      xaxis: {
        categories: [
          'Січ',
          'Лют',
          'Бер',
          'Квіт',
          'Трав',
          'Черв',
          'Лип',
          'Серп',
          'Вер',
          'Жов',
          'Лист',
          'Груд',
        ],
      },
    },
    chartSalesOptions: {
      noData: {
        text: 'Загрузка...',
      },
      title: {
        text: 'Продажі',
        align: 'left',
        fontSize: 16,
        fontWeight: 500,
      },
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'],
          opacity: 0.5,
        },
      },
      tooltip: {
        y: {
          formatter: function (value) {
            return `${getBeautyNum(value, { float: 2 })} грн`
          },
        },
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return getBeautyNum(value, { float: 0 })
          },
        },
      },
      xaxis: {
        categories: [
          'Січ',
          'Лют',
          'Бер',
          'Квіт',
          'Трав',
          'Черв',
          'Лип',
          'Серп',
          'Вер',
          'Жов',
          'Лист',
          'Груд',
        ],
      },
    },
  }),
  computed: {
    meetingsSeries() {
      const monthsCount = 12
      const zeroPaddedArray = Array.from({ length: monthsCount }).fill(0)

      const meetings = []

      for (const month in this.graphs.finishMeetingsPerMonth) {
        const monthValue = this.graphs.finishMeetingsPerMonth[month]
        meetings.push(monthValue)
      }

      const resultMeetings = meetings
        .concat(zeroPaddedArray)
        .slice(0, monthsCount)

      return [
        {
          name: 'Зустрічі',
          data: resultMeetings,
        },
      ]
    },
    salesSeries() {
      const monthsCount = 12
      const zeroPaddedArray = Array.from({ length: monthsCount }).fill(0)

      const sales = []

      for (const month in this.graphs.salesBalancePerMonth) {
        const monthValue = this.graphs.salesBalancePerMonth[month]
        sales.push(monthValue)
      }

      const resultMeetings = sales.concat(zeroPaddedArray).slice(0, monthsCount)

      return [
        {
          name: 'Продажі',
          data: resultMeetings,
        },
      ]
    },
    seriesApplications() {
      const monthsCount = 12
      const zeroPaddedArray = Array.from({ length: monthsCount }).fill(0)

      const inProject = Object.values(this.graphs.applicationsPerMonth)
        .map(month => month?.applicationConversion?.inProject)
        .concat(zeroPaddedArray)
        .slice(0, monthsCount)

      const inWork = Object.values(this.graphs.applicationsPerMonth)
        .map(month => month?.applicationConversion?.inWork)
        .concat(zeroPaddedArray)
        .slice(0, monthsCount)

      const success = Object.values(this.graphs.applicationsPerMonth)
        .map(month => month?.applicationConversion?.success)
        .concat(zeroPaddedArray)
        .slice(0, monthsCount)

      return [
        {
          name: 'В роботі',
          data: inWork,
        },
        {
          name: 'В проекті',
          data: inProject,
        },
        {
          name: 'Відвантажено',
          data: success,
        },
      ]
    },
    seriesLeads() {
      const monthsCount = 12
      const zeroPaddedArray = Array.from({ length: monthsCount }).fill(0)

      const inApplication = Object.values(this.graphs.leadsPerMonth)
        .map(month => month?.leadsConversion?.inApplication)
        .concat(zeroPaddedArray)
        .slice(0, monthsCount)

      const inProject = Object.values(this.graphs.leadsPerMonth)
        .map(month => month?.leadsConversion?.inProject)
        .concat(zeroPaddedArray)
        .slice(0, monthsCount)

      const inWork = Object.values(this.graphs.leadsPerMonth)
        .map(month => month?.leadsConversion?.inWork)
        .concat(zeroPaddedArray)
        .slice(0, monthsCount)

      const success = Object.values(this.graphs.leadsPerMonth)
        .map(month => month?.leadsConversion?.success)
        .concat(zeroPaddedArray)
        .slice(0, monthsCount)

      return [
        {
          name: 'В роботі',
          data: inWork,
        },
        {
          name: 'В заявці',
          data: inApplication,
        },
        {
          name: 'В проекті',
          data: inProject,
        },
        {
          name: 'Відвантажено',
          data: success,
        },
      ]
    },
    seriesLeadsPie() {
      const data = this.graphs.leadsPerYear?.leadsConversion
      return [data?.inWork, data?.inApplication, data?.inProject, data?.success]
    },
    seriesApplicationsPie() {
      const data = this.graphs.applicationsPerYear?.applicationConversion
      return [data?.inWork, data?.inProject, data?.success]
    },
  },
  methods: {
    yearCounter(e) {
      this.$emit('yearCounter', e)
    },
  },
}
</script>

<style scoped lang="scss">
.dashboard-block {
  background: #fff;
  border-radius: 11px;
  padding: 10px;
  transition: 0.3s all;
  box-shadow: 0 0 15px -4px rgba(0, 0, 0, 0.11);
}
</style>
