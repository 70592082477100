<template>
  <div>
    <v-row>
      <v-col cols="12" md="12" sm="12" class="pt-0">
        <span class="label">{{ commentLabel }}</span>
        <v-textarea
          v-model="comment"
          placeholder="Введіть текст"
          :error-messages="commentErr"
          solo
          hide-details
          @blur="v$.comment.$touch()">
        </v-textarea>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { useVuelidate } from '@vuelidate/core'
import { minLength, required } from '@vuelidate/validators'
import { setErrHandler } from '@/utils/helperFunc'
import { urlRiskAnalyzeSetResult } from '@/pages/request'
import { setSnackbar } from 'best-modules/plugins'

export default {
  name: 'RiskResult',
  props: {
    dialog: { type: Object },
  },
  setup() {
    return { v$: useVuelidate() }
  },
  validations() {
    return {
      comment: {
        required,
        minLength: this.isReject ? minLength(20) : minLength(1),
      },
    }
  },
  data: () => ({
    resultId: null,
    comment: null,
  }),
  computed: {
    isReject() {
      return this.resultId !== 1
    },
    validation() {
      return { v$: this.v$, name: 'riskResult' }
    },
    commentErr() {
      const errors = []
      if (!this.v$.comment.$dirty) return errors
      if (!this.v$.comment.required) {
        errors.push("Поле обов'язкове для заповнення")
      }
      if (this.isReject && this.comment && this.comment.length < 20) {
        errors.push('Мінімальна кількість символів для коментаря: 20')
      }
      return errors
    },
    commentLabel() {
      return this.isReject ? 'Причина відмови' : 'Коментар'
    },
  },
  methods: {
    setErrHandler,
    async submit() {
      return this.$axios
        .post(urlRiskAnalyzeSetResult(this.dialog.dialogItem.riskId), {
          resultId: this.resultId,
          comment: this.comment,
        })
        .then(res => {
          if (res.data?.message) {
            setSnackbar({ text: res?.data?.message })
          }
          setSnackbar({ text: 'Аналіз завершено' })
        })
    },
  },
  created() {
    this.resultId = this.dialog.dialogItem.resultId
    if (!this.isReject) {
      this.comment = 'Погоджено'
    }
  },
}
</script>

<style scoped></style>
