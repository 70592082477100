<template>
  <div>
    <SectionLoader v-if="asyncLoading('getSingleLogs')" />
    <div v-else class="mt-5">
      <span v-if="!logs.length" class="logsHidden">Змін не знайдено</span>
      <v-data-table
        class="calc-table --cust-cell-pd"
        mobileBreakpoint="750"
        dense
        :headers="THead"
        hide-default-footer
        :hide-default-header="!logs.length"
        :items-per-page="-1"
        :items="logs || []">
        <template #item.date="{ item }">
          <span>
            {{ toFormatDate(item.dateTo) }}
          </span>
        </template>
        <template #item.field="{ item }">
          <span>{{ `${item.name || '---'} (${item.field})` }}</span>
        </template>
        <template #item.changes="{ item }">
          <div class="d-flex align-center">
            <span style="display: inline-block; min-width: 6px">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="6"
                height="6"
                viewBox="0 0 6 6"
                fill="none">
                <circle cx="3" cy="3" r="3" fill="#08487A" />
              </svg>
            </span>
            <span style="padding-left: 10px; font-size: 13px; width: 380px">
              <span v-if="isDocument(item.oldValue)">
                <span
                  v-for="(doc, idx) in Array.isArray(item.oldValue)
                    ? item.oldValue
                    : [item.oldValue]"
                  :key="idx"
                  style="
                    display: block;
                    width: 270px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                  ">
                  <span class="link" @click="() => openDocument(doc)">{{
                    doc.text
                  }}</span>
                  <span
                    v-if="
                      idx <
                      (Array.isArray(item.oldValue)
                        ? item.oldValue
                        : [item.oldValue]
                      ).length -
                        1
                    "
                    >,
                  </span>
                </span>
              </span>
              <span v-else :class="{ disabled: !item.oldValue }">{{
                item.oldValue || 'Не вказано'
              }}</span>
              {{ '&#8594;' }}<br />
              <span v-if="isDocument(item.newValue)">
                <span
                  v-for="(doc, idx) in Array.isArray(item.newValue)
                    ? item.newValue
                    : [item.newValue]"
                  :key="idx"
                  style="
                    display: block;
                    width: 270px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                  ">
                  <span class="link" @click="() => openDocument(doc)">{{
                    doc.text
                  }}</span>
                  <span
                    v-if="
                      idx <
                      (Array.isArray(item.newValue)
                        ? item.newValue
                        : [item.newValue]
                      ).length -
                        1
                    "
                    >,
                  </span>
                </span>
              </span>
              <span v-else :class="{ disabled: !item.newValue }">{{
                item.newValue || 'Не вказано'
              }}</span>
            </span>
          </div>
        </template>
        <template #item.responsible="{ item }">
          <span>
            {{
              getContactInitials(item.userName, { byName: true }) || '---'
            }}</span
          >
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import SectionLoader from '@/components/section-loader.vue'
import {
  urlGetContractorHistory,
  urlGetContactHistory,
  urlGetCalculationHistory,
  urlGetSliderHistory,
  urlGetContractHistory,
  urlGetDkpHistory,
  urlGetExtraAgreementHistory,
  urlGetExtraAgreementDkpHistory,
  urlGetGuaranteeContractHistory,
  urlGetPledgeContractHistory,
} from '@/pages/request'
import {
  getContactInitials,
  toFormatDate,
  getResponsibleShortName,
  openDocument,
} from '@/utils/helperFunc'
import { useSelect } from '@/utils/mixins/useSelect'

export default {
  components: { SectionLoader },
  props: {
    entityId: { type: Number, required: true },
    entityTypeId: { type: Number, required: true },
  },
  setup() {
    return {
      ...useSelect(),
    }
  },
  data() {
    return {
      THead: [
        { title: 'Дата', key: 'date', sortable: true },
        { title: 'Поле', key: 'field', sortable: true },
        { title: 'Тип події', key: 'changes', sortable: false },
        { title: 'Відповідальний', key: 'responsible', sortable: false },
      ],
      logs: [],
    }
  },
  computed: {
    url() {
      if (!this.entityId) {
        return null
      }
      switch (this.entityTypeId) {
        case 1:
          return urlGetContactHistory
        case 2:
          return urlGetContractorHistory
        case 3:
          return urlGetSliderHistory
        case 4:
          return urlGetCalculationHistory
        case 18:
          return urlGetContractHistory
        case 19:
          return urlGetDkpHistory
        case 20:
          return urlGetExtraAgreementHistory
        case 21:
          return urlGetGuaranteeContractHistory
        case 22:
          return urlGetPledgeContractHistory
        case 23:
          return urlGetExtraAgreementDkpHistory
        default:
          return null
      }
    },
  },
  methods: {
    urlGetContractorHistory,
    getContactInitials,
    toFormatDate,
    urlGetContactHistory,
    getResponsibleShortName,
    openDocument,
    urlGetSliderHistory,
    urlGetCalculationHistory,
    getCommentWidth(comment) {
      const maxWidth = 700
      const minWidth = 250
      const width = comment.length * 6.5
      const resultWidth =
        width > minWidth ? Math.min(maxWidth, width) : minWidth

      return `${resultWidth}px`
    },
    getSingleLogs() {
      if (this.url) {
        this.asyncAction('getSingleLogs', null, () => {
          return this.$axios
            .get(this.url(this.entityId))
            .then(res => {
              this.logs = res.data?.data || res.data || []
              return res
            })
            .catch(err => this.$err(err))
        })
      }
    },
    isDocument(value) {
      const isDoc = v => {
        return !!v && typeof v === 'object' && 'url' in v && 'text' in v
      }
      if (Array.isArray(value)) {
        return value.reduce((acc, cur) => isDoc(cur), false)
      } else {
        return isDoc(value)
      }
    },
  },
  mounted() {
    this.getSingleLogs()
  },
}
</script>

<style lang="scss" scoped>
.logsHidden {
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
}

.commentTable {
  display: block;
  width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.comment {
  &-cell {
    position: relative;
  }
  &-dialog {
    position: absolute;
    right: 0;
    bottom: 10px;
    z-index: 10000;
    &-backdrop {
      width: 100vw;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 9999;
    }
  }
}

.history-item {
  font-size: 12px !important;
}
.fullComment {
  position: absolute;
  top: 0;
  background: #fff;
  z-index: 10000;
  border-radius: 7px;
}
.fullComment span {
  padding: 10px 20px;
  color: #000;
}
.mobile-history {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}
.mobile-history td {
  align-items: center !important;
  height: 48px !important;
  display: flex !important;
  justify-content: flex-start !important;
}
</style>
