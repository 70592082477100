<template>
  <div>
    <v-data-table
      :items="dialog.dialogItem.callHistories || []"
      :headers="historyLeadCallHeaders"
      hide-default-footer
      class="calc-table --cust-cell-pd mt-3"
      :hide-default-header="!dialog.dialogItem.callHistories.length"
      :items-per-page="20">
      <template #item.startTime="{ item }">
        {{ toFormatDate(item.startTime, false, true) }}
      </template>
      <template #item.user="{ item }">
        {{ getContactInitials(item.user) }}
      </template>
    </v-data-table>
  </div>
</template>

<script lang="ts">
import { historyLeadCallHeaders } from '@/utils/tableHeaders'
import { getContactInitials, toFormatDate } from '@/utils/helperFunc'

export default {
  props: {
    dialog: { type: Object },
  },
  data() {
    return {
      historyLeadCallHeaders,
    }
  },
  methods: { getContactInitials, toFormatDate },
}
</script>

<style scoped></style>
