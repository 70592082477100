<template>
  <v-hover>
    <template #default="{ isHovering, props }">
      <div
        v-bind="props"
        :id="uniqueId"
        class="field inline-block"
        :style="{
          background: isHovering || onEdit ? '#F0F0F0' : 'transparent',
          border: invalid ? '1px solid red' : 'none',
          width: `${width}px`,
        }">
        <slot v-if="$slots.label" name="label"></slot>
        <span v-else class="label-field">{{ label }}</span>
        <div
          class="d-flex align-center justify-space-between"
          :style="{
            marginTop: onEdit ? '5px' : null,
          }">
          <v-scroll-y-transition hide-on-leave>
            <span
              v-show="!onEdit"
              :class="{ disabled: !modelValue }"
              :style="{ width: `${width - 36}px` }"
              >{{ modelValue || 'Не вказано' }}</span
            >
          </v-scroll-y-transition>
          <v-scroll-y-transition hide-on-leave>
            <input
              v-show="onEdit"
              ref="input"
              v-model="localValue"
              :class="[
                'field__input',
                { 'number-input': inputType === 'number' },
              ]"
              :style="{ width: `${width - 36}px` }"
              :placeholder="placeholder"
              :type="inputType"
              v-bind="$attrs"
              @keydown.enter="saveOnEnter" />
          </v-scroll-y-transition>
          <div style="width: 20px; height: 20px">
            <v-fade-transition hide-on-leave>
              <v-btn
                v-show="(!readonly && isHovering) || onEdit"
                width="20px"
                height="20px"
                size="x-small"
                icon
                @click="switchAction">
                <v-icon color="red" small>
                  {{ onEdit ? 'mdi-check' : 'mdi-pencil' }}
                </v-icon>
              </v-btn>
            </v-fade-transition>
          </div>
        </div>
        <v-fade-transition hide-on-leave>
          <v-btn
            v-show="onEdit"
            class="field__close"
            size="x-small"
            icon
            @click="reset">
            <v-icon small>
              {{ 'mdi-close' }}
            </v-icon>
          </v-btn>
        </v-fade-transition>
      </div>
    </template>
  </v-hover>
</template>

<script>
let counter = 0

export default {
  emits: ['update:model-value'],
  props: {
    label: { type: String },
    placeholder: { type: String },
    modelValue: { type: [String, Number] },
    invalid: { type: Boolean },
    readonly: { type: Boolean },
    minWidth: { type: Number },
    mask: { type: [String, Number] },
    inputType: { type: String },
  },
  data() {
    return {
      onEdit: false,
      localValue: null,
      uniqueId: `editable-field_${counter++}`,
    }
  },
  computed: {
    width() {
      return Math.max(
        (this.modelValue?.length || 10) * 8 + 44,
        this.minWidth || 100
      )
    },
  },
  methods: {
    switchAction() {
      switch (true) {
        case this.onEdit:
          return this.save()
        case !this.onEdit:
          return this.edit()
        default:
          throw new Error('EditableField.vue: no handler')
      }
    },
    saveOnEnter(event) {
      if (event.key === 'Enter') {
        this.save()
      }
    },
    save() {
      this.$emit('update:model-value', this.localValue)
      this.onEdit = false
    },
    edit() {
      this.onEdit = true
      this.$nextTick(() => this.$refs.input.focus())
    },
    reset() {
      this.localValue = this.modelValue
      this.onEdit = false
    },
  },
  watch: {
    modelValue: {
      handler(val) {
        this.localValue = val
      },
      immediate: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.label-field {
  color: #838282;
  font-size: 12px;
  display: block;
  font-weight: 500;
}
.field {
  transition: 0.3s all;
  border-radius: 4px;
  position: relative;

  &__input {
    outline: none;
    margin: 0;
    padding: 0 0 0 8px;
    border: 1px solid #fc7247;
    border-radius: 4px;
    min-width: 60px;
    height: 22px;
    line-height: 14px;
  }

  &__close {
    position: absolute;
    top: 0;
    right: -5px;
    height: 22px;
  }
}
</style>
