<template>
  <div>
    <SectionLoader v-if="pageLoad"></SectionLoader>
    <v-fade-transition hide-on-leave>
      <div v-if="!pageLoad" fluid class="pl-3 pr-3 pb-0 agent-block">
        <v-fade-transition hide-on-leave>
          <v-row>
            <CommonCard :agent="agentData" :readOnly="readOnly"> </CommonCard>
            <v-col :cols="12" :md="9" class="pr-0 pb-1">
              <div class="mb-2">
                <div style="min-height: 350px">
                  <v-tabs
                    v-model="currentTab"
                    style="background: white !important"
                    class="contact-tab-agent pl-2"
                    show-arrows
                    :height="$vuetify.breakpoint?.xs ? null : 38"
                    :direction="
                      $vuetify.breakpoint?.xs ? 'vertical' : 'horizontal'
                    "
                    :class="{ 'v-tabs--mobile': $vuetify.breakpoint?.xs }"
                    color="white"
                    dark
                    slider-color="#FC7247"
                    dense>
                    <v-tab
                      replace
                      :to="{
                        name: 'agent',
                        params: { id: $route.params.id },
                      }">
                      Агент
                    </v-tab>
                    <v-tab
                      replace
                      :to="{
                        name: 'payment-parameters',
                        params: { id: $route.params.id },
                      }">
                      Договір (агентський)
                    </v-tab>
                    <v-tab
                      replace
                      :to="{
                        name: 'applications-info',
                        params: { id: $route.params.id },
                      }">
                      Заявки
                    </v-tab>
                    <v-tab
                      replace
                      :to="{
                        name: 'agent-payment',
                        params: { id: $route.params.id },
                      }">
                      Виплати
                    </v-tab>
                  </v-tabs>
                  <div class="pa-0 pt-0">
                    <RouterView
                      :pageLoad="pageLoad"
                      :agent="agentData"
                      :readOnly="readOnly"
                      :v="v$"
                      @refresh-data="getAgentById">
                    </RouterView>
                  </div>
                </div>
              </div>
            </v-col>
            <ActionButtons
              :confirm="submit"
              :cancel="getAgentById"
              :cancelDisable="agentCache === cache || loading || readOnly"
              :confirmDisable="agentCache === cache || readOnly">
            </ActionButtons>
          </v-row>
        </v-fade-transition>
      </div>
    </v-fade-transition>
  </div>
</template>
<script>
import CommonCard from './CommonCard.vue'
import ActionButtons from '@/components/action-buttons.vue'
import SectionLoader from '@/components/section-loader.vue'
import {
  urlGetAgent,
  urlUpdateAgent,
  urlUpdateAgencyContract,
} from '@/pages/request'
import { setCache, v$Notify, toFormatDate, backDate } from '@/utils/helperFunc'
import { mapState } from 'vuex'

import { FRONT_MANAGER, SUPERVISOR, ADMIN } from '@/roles'
import { required, minLength } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
export default {
  components: { CommonCard, ActionButtons, SectionLoader },
  setup() {
    return { v$: useVuelidate() }
  },
  validations() {
    const pThrough = this.agentData.isPaymentThrough
    const agent = this.agentData

    const paymentThroughInn =
      pThrough && agent.paymentThroughInn
        ? { required, minLength: minLength(10) }
        : {}
    const paymentThroughIban =
      pThrough && agent.paymentThroughIban
        ? { required, minLength: minLength(29) }
        : {}
    const paymentThroughBankName =
      pThrough && agent.paymentThroughIban ? { required } : {}
    const paymentThroughMfo =
      pThrough && agent.paymentThroughIban ? { required } : {}
    const paymentThroughCardNumber =
      pThrough && agent.paymentThroughCardNumber
        ? { required, minLength: minLength(22) }
        : {}
    const agencyContract = {
      signedAgencyAgreement: {
        number: this.agencyContractData.isAgentContract ? { required } : {},
        date: this.agencyContractData.isAgentContract ? { required } : {},
        doc: this.agencyContractData.isAgentContract ? { required } : {},
      },
    }

    return {
      agentData: {
        paymentThroughInn: paymentThroughInn,
        paymentThroughIban: paymentThroughIban,
        paymentThroughBankName: paymentThroughBankName,
        paymentThroughMfo: paymentThroughMfo,
        paymentThroughCardNumber: paymentThroughCardNumber,
        ...agencyContract,
      },
    }
  },
  data: () => ({
    agentData: {
      contractorId: null,
      dealerPointId: null,
      isPaymentThrough: false,
      paymentThroughFio: null,
      paymentThroughInn: null,
      paymentThroughPassport: null,
      paymentThroughBankName: null,
      paymentThroughIban: null,
      paymentThroughMfo: null,
      paymentThroughCardNumber: null,
      paymentThroughAddress: {},
    },
    agencyContractData: {
      isAgentContract: null,
      signedAgencyAgreement: {
        doc: null,
        date: null,
        number: null,
      },
    },
    pageLoad: false,
    cache: null,
    loading: false,
    currentTab: 0,
  }),
  computed: {
    ...mapState({
      selectItems: state => state.selectItems,
      user: state => state.user,
    }),
    agentCache() {
      return this.setCache([this.agentData])
    },
    readOnly() {
      return (
        this.agentData?.contact?.responsible.id !== this.user?.id ||
        ![FRONT_MANAGER, SUPERVISOR, ADMIN].includes(this.user.roleId)
      )
    },
  },
  methods: {
    toFormatDate,
    setCache,
    getAgentById() {
      this.pageLoad = true
      return this.$axios
        .get(urlGetAgent(this.$route.params.id))
        .then(res => {
          if (res?.data?.message) {
            return Promise.reject(res.data.message)
          }
          res.data.dateContract = this.toFormatDate(res.data.dateContract, true)
          const paymentThroughAddress = res?.data?.paymentThroughAddress
            ? {}
            : { paymentThroughAddress: {} }
          Object.assign(this.agentData, {
            ...res.data,
            ...paymentThroughAddress,
          })
          this.setBreadscrumb()
          this.cache = setCache([this.agentData])
          this.pageLoad = false
          return res
        })
        .catch(err =>
          this.$err(
            err,
            () => (this.pageLoad = false),
            this.$router.push({ name: 'agent-list' })
          )
        )
    },
    setBreadscrumb() {
      this.$store.commit('setBreadScrumbPart', [
        null,
        null,
        this.agentData.contact.fullName,
        null,
      ])
    },
    async submit() {
      if (this.v$.$invalid) {
        this.v$.$anyError
        this.v$.$touch()
        return v$Notify(this.v$.agentData, 'agent')
      }

      const reqObject = JSON.parse(JSON.stringify(this.agentData))
      reqObject.dateContract = backDate(reqObject.dateContract)
      this.loading = true
      if (reqObject.isAgentContract) {
        await this.$axios.post(
          urlUpdateAgencyContract(this.$route.params.id),
          this.agencyContractData
        )
      }
      if (!reqObject.isActive) {
        const d = new Date()
        const [year, month, day, hour, minutes] = [
          d.getFullYear(),
          d.getMonth(),
          d.getDate(),
          d.getHours(),
          d.getMinutes(),
        ]
        const twoDigits = n => (String(n).length === 1 ? `0${n}` : n)
        reqObject.notActiveDate = `${year}-${twoDigits(month + 1)}-${twoDigits(
          day
        )} ${twoDigits(hour)}:${twoDigits(minutes)}`
      }
      return this.$axios
        .post(urlUpdateAgent(this.$route.params.id), reqObject)
        .then(res => {
          Object.assign(this.agentData, res.data)
          this.$setSnackbar({ text: 'Данi оновлено' })
          this.getAgentById()
          this.loading = false
          this.$emit('dataChanged')
          return res
        })
        .catch(err => this.$err(err, () => (this.loading = false)))
    },
  },
  watch: {
    '$route.name': function () {
      this.setBreadscrumb()
    },
    'agentData.isAgentContract': {
      handler(val) {
        this.agencyContractData.isAgentContract = val
      },
      immediate: true,
    },
    'agentData.signedAgencyAgreement.number': {
      handler(val) {
        this.agencyContractData.signedAgencyAgreement.number = val
      },
      immediate: true,
    },
    'agentData.signedAgencyAgreement.doc': {
      handler(val) {
        this.agencyContractData.signedAgencyAgreement.doc = val
      },
      immediate: true,
    },
    'agentData.signedAgencyAgreement.date': {
      handler(val) {
        this.agencyContractData.signedAgencyAgreement.date = backDate(val)
      },
      immediate: true,
    },
  },
  created() {
    this.$store.dispatch('addAllUsers')
    this.getAgentById()
  },
}
</script>
<style>
.agent-block {
  position: relative;
  align-items: start;
}
.v-tab {
  color: #838282;
  font-weight: 400;
  font-size: 14px;
}
.v-tab-item--selected.v-tab {
  font-weight: 700 !important;
  color: #08487a !important;
}
.contact-tab-agent .v-tabs-bar.v-item-group > * {
  background: white !important;
}
</style>

<style scoped lang="scss">
.contact-tab-agent .v-tabs-bar.v-item-group > * {
  background: white !important;
}
.v-tabs {
  & .v-tab {
    margin-right: 25px;
  }
  &--mobile {
    padding-right: 12px;
    .v-tab {
      margin-right: 0;
      margin-bottom: 12px;
      min-width: 100%;
      border: 1px solid #eaeaea;
      background: #eaeaea;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
      border-radius: 4px;
    }
  }
}
</style>
