<template>
  <div>
    <v-row>
      <v-col cols="12" md="12" sm="12">
        <span class="label">Причина відмови</span>
        <v-select
          v-model="rejection.rejectionTypeId"
          :items="selectItems.applicationRejectStatuses"
          :error-messages="rejectionTypeIdErr"
          item-title="name"
          item-value="id"
          hide-details
          placeholder="Оберіть зі списку"
          @focus="asyncAction('addApplicationRejectStatuses')">
          <template #append-item
            ><Loader v-if="asyncLoading('addApplicationRejectStatuses')"
          /></template>
        </v-select>
      </v-col>
      <v-col cols="12" md="12" sm="12">
        <span class="label">Коментар</span>
        <v-textarea
          v-model="rejection.rejectionComments"
          :error-messages="rejectionCommentsErr"
          placeholder="Введіть текст"
          :rows="2"
          hide-details>
        </v-textarea>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { required } from '@vuelidate/validators'
import { mapState } from 'vuex'
import { setErrHandler } from '@/utils/helperFunc'
import { useVuelidate } from '@vuelidate/core'
import Loader from '@/components/Loader.vue'
import { useSelect } from '@/utils/mixins/useSelect'
export default {
  components: { Loader },
  props: { dialog: { type: Object } },
  setup() {
    return { v$: useVuelidate(), ...useSelect() }
  },
  validations() {
    return {
      rejection: {
        statusId: { required },
        rejectionTypeId: { required },
        rejectionComments: { required },
      },
    }
  },
  data: () => ({
    rejection: {
      statusId: null,
      rejectionTypeId: null,
      rejectionComments: null,
    },
  }),
  computed: {
    ...mapState({ selectItems: state => state.selectItems }),
    rejectApplication() {
      return this.dialog.action === 'rejectApplication'
    },
    validation() {
      return { v$: this.v$.rejection, name: 'applicationRejection' }
    },
    rejectionTypeIdErr() {
      return setErrHandler(this.v$?.rejection?.rejectionTypeId)
    },
    rejectionCommentsErr() {
      return setErrHandler(this.v$?.rejection?.rejectionComments)
    },
  },
  methods: {
    submit() {
      return this.dialog.params.changeStatus(this.rejection)
    },
  },
  mounted() {
    Object.assign(this.rejection, this.dialog.dialogItem)
  },
}
</script>
