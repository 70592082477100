<template>
  <v-card-text class="pt-0 pb-0">
    <div v-show="updatedSliders.length" class="mb-6">
      <div class="subtitle-1 mb-2">Збереженi бiгунки:</div>
      <v-data-table
        :headers="tHead"
        :items="updatedSliders"
        class="calc-table --cust-cell-pd"
        mobileBreakpoint="750"
        dense
        hide-default-footer
        :hide-default-header="!updatedSliders.length"
        :items-per-page="100">
        <!-- eslint-disable-next-line -->
        <template #item.calculationName="{ item }">
          <ALink
            :route="{
              name: 'start-project',
              params: { calculationId: item.id },
            }"
            :text="item.calculationName"
            @click="$store.commit('closeDialog')">
          </ALink>
        </template>
      </v-data-table>
    </div>
    <div class="subtitle-1 mb-2">Обрати нову погоджену калькуляцiю:</div>
    <v-autocomplete
      v-model="calculationId"
      :items="rowSliders"
      :error-messages="calculationIdErr"
      item-value="id"
      item-title="calculationName"
      label="Калькуляцiя"
      dense
      hide-details
      @blur="v$.calculationId.$touch()">
    </v-autocomplete>
  </v-card-text>
</template>
<script>
import ALink from '@/components/Link.vue'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { setErrHandler } from '@/utils/helperFunc'
export default {
  components: { ALink },
  setup() {
    return { v$: useVuelidate() }
  },
  props: {
    dialog: { type: Object },
  },
  validations() {
    return {
      calculationId: { required },
    }
  },
  data: () => ({
    calculationId: null,
    tHead: [
      {
        title: 'Калькуляцiя',
        key: 'calculationName',
        align: 'start',
        sortable: false,
      },
      {
        title: 'Дата',
        key: 'sliderSavedDate',
        sortable: false,
        align: 'end',
      },
    ],
  }),
  computed: {
    validation() {
      return { v$: this.v$, name: 'approveSlider' }
    },
    /* Current state */
    approveSlider() {
      return this.dialog.action === 'approveSlider'
    },
    agreededCalculations() {
      const cs = this.dialog.params?.calculations
      if (!cs.length) return
      return cs.filter(c => c.isAgreed).map(this.setName)
    },
    updatedSliders() {
      return this.agreededCalculations.filter(c => c.isSavedSlider)
    },
    rowSliders() {
      return this.agreededCalculations.filter(c => !c.isSavedSlider)
    },
    calculationIdErr() {
      return setErrHandler(this.v$.calculationId, 'Оберiть опцію')
    },
  },
  methods: {
    setName(item) {
      item.calculationName = `${item?.requestId} ${
        item?.requestData?.leasedAssertMark?.name
      } ${item?.requestData?.leasedAssertModel?.name} ${
        item?.car_modification?.modification || ''
      }`
      return item
    },
    submit() {
      const sbm = this.$router.push({
        name: 'start-project',
        params: { calculationId: this.calculationId },
      })
      return Promise.resolve(sbm)
    },
  },
}
</script>
