<template>
  <div>
    <div v-if="supervisorListRoute && !pageLoad">
      <v-fade-transition hide-on-leave>
        <v-row>
          <v-col
            v-show="supervisorTeams.length"
            :cols="12"
            :md="4"
            class="d-flex pl-5">
            <v-text-field
              v-model="string"
              style="width: 500px"
              placeholder="Пошук"
              hide-details>
              <template #prepend-inner>
                <find-icon :width="17" :height="17" style="margin-top: 4px" />
              </template>
            </v-text-field>
          </v-col>
          <v-col :cols="12" :md="8" class="justify-end d-flex">
            <v-btn
              class="text-white mr-2"
              style="background: rgb(252, 114, 71); border-radius: 4px"
              @click="openSupervisorDialog({}, 'create')">
              Додати супервайзера
            </v-btn>
          </v-col>
          <v-col :cols="12" :md="12" :sm="12">
            <v-data-table
              :headers="adminSupervisorHeaders"
              :items="supervisorTeams"
              class="calc-table --cust-cell-pd"
              mobileBreakpoint="750"
              dense
              :hide-default-footer="!supervisorTeams.length"
              :hide-default-header="!supervisorTeams.length"
              :search="string"
              :custom-sort="tableDateSort()">
              <!-- eslint-disable-next-line -->
              <template #item.actions="{ item }">
                <TableCrud
                  :actionsArray="[
                    {
                      action: () => openSupervisorDialog(item, 'remove'),
                      text: 'Видалити',
                      icon: 'DeleteIcon',
                      customIcon: true,
                    },
                    {
                      action: () => openSupervisorDialog(item, 'update'),

                      text: 'Редагувати',
                      icon: 'EditPencil',
                      customIcon: true,
                    },
                  ]">
                </TableCrud>
              </template>
              <!-- eslint-disable-next-line -->
              <template #item.name="{ item }">
                <ALink
                  :route="{
                    name: 'supervisor-form',
                    params: { id: item.id },
                  }"
                  :text="item.name">
                </ALink>
              </template>
              <!-- eslint-disable-next-line -->
              <template #item.supervisor="{ item }">
                {{ getResponsible(item.supervisor) }}
              </template>
              <!-- eslint-disable-next-line -->
              <template #item.middle_manager="{ item }">
                {{ getResponsible(item.middle_manager) }}
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-fade-transition>
    </div>
    <SectionLoader v-if="pageLoad && supervisorListRoute"> </SectionLoader>
    <v-fade-transition hide-on-leave>
      <RouterView
        v-if="!supervisorListRoute"
        :supervisorTeams="supervisorTeams"
        @data-changed="getSuperVisors">
      </RouterView>
    </v-fade-transition>
  </div>
</template>
<script>
import ALink from '@/components/Link.vue'
import SectionLoader from '@/components/section-loader.vue'
import { tableDateSort, getResponsible } from '@/utils/helperFunc'
import { urlGetAllSupervisorTeams } from '@/pages/request'
import findIcon from '@/assets/svg/find-bold.vue'
import TableCrud from '@/components/table-crud.vue'
import { adminSupervisorHeaders } from '@/utils/tableHeaders'
export default {
  components: {
    TableCrud,
    findIcon,
    SectionLoader,
    ALink,
  },
  data() {
    return {
      adminSupervisorHeaders,
      pageLoad: false,
      supervisorTeams: [],
    }
  },
  computed: {
    supervisorListRoute() {
      return this.$route.name === 'admin-supervisor-list'
    },
  },
  methods: {
    tableDateSort,
    getResponsible,
    openSupervisorDialog(item, action) {
      let message = ''
      let storeAction = ''

      switch (action) {
        case 'remove':
          message = 'видалено'
          storeAction = 'deleteSupervisor'
          break
        case 'update':
          message = 'оновлено'
          storeAction = 'updateSupervisor'
          break
        case 'create':
          message = 'створено'
          storeAction = 'createSupervisor'
          break
        default:
          break
      }

      this.$store.commit('setDialog', {
        title: 'Супервайзер',
        dialogItem: item,
        params: {
          cb: () => {
            this.$setSnackbar({ text: `Групу супервайзера ${message}` })
            this.getSuperVisors()
          },
        },
        action: storeAction,
      })
    },
    getSuperVisors() {
      this.pageLoad = true

      return this.$axios
        .get(urlGetAllSupervisorTeams())
        .then(res => {
          this.pageLoad = false
          this.supervisorTeams.splice(0)
          this.supervisorTeams.push(...res.data)
          return res
        })
        .catch(err => this.$err(err, () => (this.pageLoad = false)))
    },
  },
  created() {
    this.getSuperVisors()
  },
}
</script>
