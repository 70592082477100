<template>
  <div style="background: #fff" class="pa-3 mt-2">
    <!-- Security Department -->
    <div>
      <div class="main-title mb-5">
        <div class="d-flex align-center">
          <FileCheck />
          <span class="card-block-title">Служба безпеки</span>
          <v-btn
            v-if="
              analyzeData?.securityResult ||
              analyzeData?.project?.securityResult
            "
            icon
            density="compact"
            @click="
              navigateToAnalysis(
                analyzeData?.securityResult?.id ||
                  analyzeData?.project?.securityResult?.id,
                'ss-project-verification'
              )
            ">
            <LinkIcon />
          </v-btn>
        </div>
        <div
          v-if="
            analyzeData?.project?.securityResult?.status?.id === 4 ||
            analyzeData?.securityResult?.status?.id === 4
          ">
          <v-tooltip bottom text="Результат перевірки">
            <template #activator="{ props }">
              <div
                class="conclusion link"
                v-bind="props"
                @click="() => downloadPrintedForm('security')">
                <span>Результат перевірки</span>
                <PrinterIcon />
              </div>
            </template>
            <span>Завантажити друковану форму</span>
          </v-tooltip>
        </div>
      </div>
      <v-data-table
        :headers="analyzeSecurityHeaders"
        :items="securityResult"
        hide-default-footer
        class="pb-2 custom-table head-line"
        dense>
        <!-- eslint-disable-next-line -->
        <template #item.finishDate="{ item }">
          {{ toFormatDate(item.finishDate) || '---' }}
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.status="{ item }">
          <span v-if="item.status.id === 1">{{ 'Не розглянуто' }}</span>
          <span v-else-if="item.status.id === 2">{{ 'На розгляді' }}</span>
          <span v-else>{{ item.status?.name || '---' }}</span>
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.responsible="{ item }">
          {{
            getContactInitials(item?.responsible?.fullName, { byName: true }) ||
            '---'
          }}
        </template>
      </v-data-table>
    </div>

    <!-- Legal Department -->
    <div v-if="!hideLegal">
      <div class="main-title mb-5">
        <div class="mt-3 d-flex align-center">
          <FileCheck />
          <span class="card-block-title">Юридичний департамент</span>
          <v-btn
            v-if="analyzeData?.legalResult || analyzeData?.project?.legalResult"
            icon
            density="compact"
            @click="
              navigateToAnalysis(
                analyzeData?.legalResult?.id ||
                  analyzeData?.project?.legalResult?.id,
                'ld-project-verification'
              )
            ">
            <LinkIcon />
          </v-btn>
        </div>
        <div
          v-if="
            analyzeData?.project?.legalResult?.status?.id === 4 ||
            analyzeData?.legalResult?.status?.id === 4
          ">
          <v-tooltip bottom text="Висновок">
            <template #activator="{ props }">
              <span
                class="conclusion link"
                v-bind="props"
                @click="() => downloadPrintedForm('legal')">
                <span>Висновок</span><Printer />
              </span>
            </template>
            <span>Переглянути ЮР висновок</span>
          </v-tooltip>
        </div>
      </div>
      <v-data-table
        :headers="analyzeLegalHeaders"
        :items="legalResult"
        hide-default-footer
        class="pb-2 custom-table head-line"
        dense>
        <!-- eslint-disable-next-line -->
        <template #item.finishDate="{ item }">
          <span>{{ toFormatDate(item.finishDate) || '---' }}</span>
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.status="{ item }">
          <span v-if="item.status.id === 1">{{ 'Не розглянуто' }}</span>
          <span v-else-if="item.status.id === 2">{{ 'На розгляді' }}</span>
          <span v-else>{{ item.status?.name || '---' }}</span>
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.responsible="{ item }">
          {{
            getContactInitials(item?.responsible?.fullName, { byName: true }) ||
            '---'
          }}
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.conclusion="{ item }">
          <div class="d-flex align-center justify-end">
            <span v-if="item.status && !item.conclusion">
              {{
                item.status.id === 4 && item.conclusion ? 'Готовий' : ''
              }}</span
            >
            <div v-if="item.conclusion">
              <v-menu v-if="item.conclusion" left offset-x>
                <template #activator="{ props }">
                  <span v-if="item.status" class="link" v-bind="props">
                    {{
                      item.status.id === 4 && item.conclusion ? 'Готовий' : ''
                    }}
                  </span>
                </template>
                <v-card>
                  <div class="pa-5" style="background: #fff">
                    {{ item.conclusion }}
                  </div>
                </v-card>
              </v-menu>
            </div>
            <span v-else>---</span>
          </div>
        </template>
      </v-data-table>
    </div>

    <!-- fin Department -->
    <div v-if="!hideFin">
      <div class="main-title">
        <div class="mb-5 mt-3 d-flex align-center">
          <FileCheck />
          <span class="card-block-title">Фінансовий моніторинг</span>
          <v-btn
            v-if="
              analyzeData?.project?.financialMonitoringResult ||
              analyzeData?.financialMonitoringResult ||
              analyzeData?.financialResult
            "
            icon
            density="compact"
            @click="
              navigateToAnalysis(
                analyzeData.financialMonitoringResult?.id ||
                  analyzeData?.project?.financialMonitoringResult?.id ||
                  analyzeData?.financialResult?.id,
                'fd-project-verification'
              )
            ">
            <LinkIcon />
          </v-btn>
        </div>
      </div>
      <v-data-table
        :headers="analyzeFinHeaders"
        :items="
          [
            analyzeData?.project?.financialMonitoringResult ||
              analyzeData?.financialMonitoringResult ||
              analyzeData?.financialResult,
          ].filter(v => v)
        "
        hide-default-footer
        class="pb-2 custom-table head-line"
        dense>
        <!-- eslint-disable-next-line -->
        <template #item.name="{ item }">
          {{ `${item.name}(Клієнт)` }}
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.finishDate="{ item }">
          {{ toFormatDate(item.finishDate) || '---' }}
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.status="{ item }">
          <div class="d-flex align-center">
            <span v-if="item.status.id === 1">{{ 'Не розглянуто' }}</span>
            <span v-else-if="item.status.id === 2">{{ 'На розгляді' }}</span>
            <span v-else>{{ item.status?.name || '---' }}</span>

            <div v-if="item.financialRiskAssessmentNote">
              <v-menu left offset-x>
                <template #activator="{ props }">
                  <div v-bind="props">
                    <v-tooltip bottom text="Зауваження">
                      <template #activator="{ props }">
                        <v-btn
                          icon
                          size="small"
                          density="comfortable"
                          rounded
                          v-bind="props">
                          <InformIcon />
                        </v-btn>
                      </template>
                      <span>Зауваження</span>
                    </v-tooltip>
                  </div>
                </template>
                <v-card>
                  <div class="pa-5" style="background: #fff">
                    <span>{{ item.financialRiskAssessmentNote }}</span>
                  </div>
                </v-card>
              </v-menu>
            </div>
          </div>
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.responsible="{ item }">
          {{
            getContactInitials(item?.responsible?.fullName, { byName: true }) ||
            '---'
          }}
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.conclusion="{ item }">
          <div class="d-flex align-center justify-end">
            <span v-if="!item.financialRiskLevel">---</span>
            <span
              v-if="item.financialRiskLevel && !item.financialRiskAssessment"
              >{{ item.financialRiskLevel?.name }}</span
            >

            <div v-if="item.financialRiskAssessment">
              <v-menu v-if="item.financialRiskAssessment" left offset-x>
                <template #activator="{ props }">
                  <span
                    v-if="item.financialRiskLevel"
                    class="link"
                    v-bind="props"
                    >{{ item.financialRiskLevel?.name }}</span
                  >
                </template>
                <v-card>
                  <div class="pa-5" style="background: #fff">
                    <span>{{ item.financialRiskAssessment }}</span>
                  </div>
                </v-card>
              </v-menu>
              <span v-else>---</span>
            </div>
          </div>
        </template>
      </v-data-table>
      <slot v-if="$slots.finDocuments" name="finDocuments"></slot>
    </div>

    <!-- risk Department -->
    <div v-if="!hideRisk">
      <div class="main-title">
        <div class="mb-5 mt-3 d-flex align-center">
          <FileCheck />
          <span class="card-block-title">Ризик департамент</span>
          <v-btn
            v-if="analyzeData?.project?.riskResult || analyzeData?.riskResult"
            icon
            density="compact"
            @click="
              navigateToAnalysis(
                analyzeData?.riskResult?.id ||
                  analyzeData?.project?.riskResult?.id,
                'rd-project-verification'
              )
            ">
            <LinkIcon />
          </v-btn>
        </div>
      </div>
      <v-data-table
        :headers="analyzeRiskHeaders"
        :items="
          [analyzeData?.project?.riskResult || analyzeData?.riskResult].filter(
            v => v
          )
        "
        hide-default-footer
        class="pb-2 custom-table head-line"
        dense>
        <!-- eslint-disable-next-line -->
        <template #item.finishDate="{ item }">
          <span>{{ toFormatDate(item.finishDate) || '---' }}</span>
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.status="{ item }">
          <span v-if="item.status.id === 1">{{ 'Не розглянуто' }}</span>
          <span v-else-if="item.status.id === 2">{{ 'На розгляді' }}</span>
          <span v-else>{{ item.status?.name || '---' }}</span>
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.conclusion="{ item }">
          {{ item.result?.name || '---' }}
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.name="{ item }">
          <span>{{ `${item.name}(Клієнт)` }}</span>
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.responsible="{ item }">
          {{
            getContactInitials(item?.responsible?.fullName, { byName: true }) ||
            '---'
          }}
        </template>
      </v-data-table>
      <slot v-if="$slots.riskDocuments" name="riskDocuments"></slot>
    </div>

    <!-- klp Department -->
    <div v-if="!hideKlp">
      <div class="main-title">
        <div class="mb-5 mt-3 d-flex align-center">
          <FileCheck />
          <span class="card-block-title">Комітет по лізинговим проектам</span>
          <v-btn
            v-if="analyzeData?.klp || analyzeData?.project?.klp"
            icon
            density="compact"
            @click="
              $router.push({
                name: 'leasing-committee-leasing-object',
                id: analyzeData?.klp?.id || analyzeData?.project?.klp?.id,
                idx: 1,
              })
            ">
            <LinkIcon />
          </v-btn>
        </div>
      </div>
      <v-data-table
        :headers="analyzeKlpHeaders"
        :items="[analyzeData?.klp || analyzeData?.project?.klp].filter(v => v)"
        hide-default-footer
        class="pb-2 custom-table head-line"
        dense>
        <!-- eslint-disable-next-line -->
        <template #item.finishDate="{ item }">
          {{ toFormatDate(item.finishDate) || '---' }}
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.status="{ item }">
          <span v-if="item.status.id === 1">{{ 'Не розглянуто' }}</span>
          <span v-else-if="item.status.id === 2">{{ 'На розгляді' }}</span>
          <span v-else>{{ item.status?.name || '---' }}</span>
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.conclusion="{ item }">
          {{ item.result?.name || '---' }}
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.number="{ item }">
          {{ `${item.number}` }}
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.responsible="{ item }">
          {{
            getContactInitials(item?.responsible?.fullName, { byName: true }) ||
            '---'
          }}
        </template>
      </v-data-table>
      <slot v-if="$slots.klpDocuments" name="klpDocuments"></slot>
    </div>
  </div>
</template>

<script>
import FileCheck from '@/assets/svg/file-check.vue'
import PrinterIcon from '@/assets/svg/Printer-icon.vue'
import { getContactInitials, toFormatDate } from '@/utils/helperFunc'
import Printer from '@/assets/svg/Printer-icon.vue'
import LinkIcon from '@/assets/svg/link-icon.vue'
import InformIcon from '@/assets/svg/information-icon.vue'
import {
  analyzeFinHeaders,
  analyzeKlpHeaders,
  analyzeLegalHeaders,
  analyzeRiskHeaders,
  analyzeSecurityHeaders,
} from '@/utils/tableHeaders'

export default {
  components: { InformIcon, LinkIcon, Printer, PrinterIcon, FileCheck },
  emits: ['downloadPrintedForm'],
  props: {
    analyzeData: { type: Object },
    securityResult: { type: Array },
    legalResult: { type: Array },
    hideLegal: { type: Boolean, default: false },
    hideKlp: { type: Boolean, default: false },
    hideRisk: { type: Boolean, default: false },
    hideFin: { type: Boolean, default: false },
  },
  data() {
    return {
      analyzeSecurityHeaders,
      analyzeRiskHeaders,
      analyzeLegalHeaders,
      analyzeFinHeaders,
      analyzeKlpHeaders,
      leasingDepartmentDialog: null,
      fav: true,
      menu: false,
      message: false,
      hints: true,
    }
  },
  methods: {
    getContactInitials,
    toFormatDate,
    downloadPrintedForm(service) {
      this.$emit('downloadPrintedForm', service)
    },
    navigateToAnalysis(id, name) {
      return this.$router.push({
        name: name,
        params: { id: id, tabState: 'main' },
      })
    },
  },
}
</script>

<style scoped>
.main-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.conclusion {
  font-weight: 500;
  font-size: 13px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 1rem;
}
</style>
