<template>
  <div>
    <PaymentHistory
      v-if="calculation"
      :key="paymentHistoryKey"
      class="pt-1"
      :requestDataProp="calculation"
      :elevation="0"
      :showHeader="false"
      :pageMinHeight="400"
      :showTabs="false"
      showExport
      noFetchRate>
    </PaymentHistory>
    <v-data-table
      v-else
      class="mt-8"
      :items="[]"
      :headers="[]"
      hide-default-footer
      hide-default-header>
    </v-data-table>
  </div>
</template>
<script>
import PaymentHistory from '@/components/payment-history.vue'
import { mapState } from 'vuex'

export default {
  components: {
    PaymentHistory,
  },
  data() {
    return {
      paymentHistoryKey: 1,
    }
  },
  computed: {
    ...mapState({
      calculation: state => state.contracts.extraAgreement?.calculation,
    }),
  },
  watch: {
    calculation: {
      handler() {
        this.paymentHistoryKey += 1
      },
      deep: true,
    },
  },
}
</script>
