<template>
  <div>
    <v-row>
      <v-col
        v-if="career.contractor && career.contractor.shortName"
        cols="12"
        md="12"
        sm="12"
        class="pt-0 pb-0">
        <span class="label d-block mb-1">Контрагент</span>
        <v-text-field
          v-model="career.contractor.shortName"
          placeholder="Введіть текст"
          hide-details
          readonly></v-text-field>
      </v-col>
      <v-col
        v-if="career.contact && career.contact.fullName"
        cols="12"
        md="12"
        sm="12"
        class="pt-0 pb-0">
        <span class="label d-block mb-1">ПІБ</span>
        <v-text-field
          v-model="career.contact.fullName"
          hide-details
          placeholder="Введіть текст"
          readonly></v-text-field>
      </v-col>
      <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
        <span class="label d-block mb-1">Посада</span>
        <v-select
          v-model="career.postId"
          :error-messages="postIdErr"
          :items="directoryItems('careerPosts', career.post)"
          item-title="name"
          item-value="id"
          hide-details
          placeholder="Оберіть зі списку"
          dense
          @blur="v$.career.postId.$touch()"
          @focus="asyncAction('addCareerPosts')"
          @change="
            () => {
              $emit('position', $event)
              career.fullPostName = selectItems.careerPosts.find(
                post => post.id === $event
              ).name
            }
          ">
          <template #append-item
            ><Loader v-if="asyncLoading('addCareerPosts')"
          /></template>
        </v-select>
      </v-col>
      <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
        <span class="label d-block mb-1">Повна назва посади</span>
        <v-text-field
          v-model="career.fullPostName"
          :error-messages="fullPostNameErr"
          placeholder="Введіть текст"
          dense
          hide-details
          @blur="v$.career.fullPostName.$touch()"
          @input="$emit('fullPostName', $event)">
        </v-text-field>
      </v-col>
      <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
        <span class="label d-block mb-1">Департамент</span>
        <v-select
          v-model="career.departmentId"
          :error-messages="departmentIdErr"
          :items="directoryItems('careerDepartments', career.department)"
          item-title="name"
          item-value="id"
          placeholder="Оберіть зі списку"
          dense
          hide-details
          @blur="v$.career.departmentId.$touch()"
          @change="$emit('department', $event)"
          @focus="asyncAction('addCareerDepartments')">
          <template #append-item
            ><Loader v-if="asyncLoading('addCareerDepartments')"
          /></template>
        </v-select>
      </v-col>
      <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
        <span class="label d-block mb-1">Роль</span>
        <v-select
          v-model="career.roleId"
          :error-messages="roleIdErr"
          :items="directoryItems('careerRoles', career.role)"
          item-title="name"
          item-value="id"
          placeholder="Оберіть зі списку"
          dense
          hide-details
          @blur="v$.career.roleId.$touch()"
          @change="$emit('role', $event)"
          @focus="asyncAction('addCareerRoles')">
          <template #append-item
            ><Loader v-if="asyncLoading('addCareerRoles')"
          /></template>
        </v-select>
      </v-col>
      <v-col
        v-if="!dialog.params.hideSignature"
        cols="12"
        md="12"
        sm="12"
        class="pt-0 pb-0">
        <v-checkbox v-model="career.isSignatureRight" :label="'Підписант'">
        </v-checkbox>
      </v-col>
    </v-row>
    <div v-if="career.isSignatureRight && !dialog.params.hideSignature">
      На підставі
      <v-btn
        color="grey darken-3"
        dark
        icon
        style="transition: 0.3s all"
        :style="{ transform: openReason ? 'rotate(45deg)' : 'rotate(0deg)' }"
        @click="openReason = !openReason">
        <v-icon :size="22">
          {{ 'mdi-plus-circle-outline' }}
        </v-icon>
      </v-btn>
      <div v-if="openReason" style="margin-bottom: 15px">
        <v-select
          v-model="newSignatureType"
          :items="
            selectItems.basisDocuments.filter(b => [1, 2, 8, 9].includes(b.id))
          "
          item-title="name"
          :error-messages="signatoryBasesErr"
          item-value="id"
          :label="'На підставі'"
          dense
          @blur="v$.career.signatoryBases.$touch()">
        </v-select>
        <v-text-field
          v-if="[2, 8, 9].includes(newSignatureType)"
          v-model="newSignatureName"
          :error-messages="newSignatureNameErr"
          :label="
            selectItems.basisDocuments.find(d => d.id === newSignatureType).name
          "
          @blur="v$.career.newSignatureName.$touch()">
        </v-text-field>
        <v-btn size="small" @click="changeSignatureReason">
          {{ signatureActionType === 'add' ? 'Додати' : 'Зберегти' }}
        </v-btn>
      </div>
      <v-data-table
        v-if="career.signatoryBases && career.signatoryBases.length !== 0"
        :headers="careerTHead"
        :items="career.signatoryBases"
        hide-default-footer
        hide-default-header>
        <!-- eslint-disable-next-line -->
        <template #item.basisDocumentId="{ item }">
          {{
            getSafe(
              () =>
                selectItems.basisDocuments.find(
                  d => d.id === item.basisDocumentId
                ).name
            ) || '---'
          }}
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.documentComment="{ item }">
          {{ item.documentComment }}
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.actions="{ item }">
          <TableCrud
            :actionsArray="[
              {
                action: () => deleteSignature(item),
                text: 'Видалити',
                disabled: openReason,
                icon: 'mdi-close',
              },
              {
                action: () => editSignature(item),
                text: 'Редагувати',
                disabled: openReason,
                icon: 'EditPencil',
                customIcon: true,
              },
            ]">
          </TableCrud>
        </template>
      </v-data-table>
      <no-table-data v-else></no-table-data>
    </div>
  </div>
</template>

<script>
import { required } from '@vuelidate/validators'
import { mapState } from 'vuex'
import {
  v$Notify,
  contractorFilter,
  setErrHandler,
  getSafe,
} from '@/utils/helperFunc'
import {
  urlContactUpdateCareer,
  urlCreateContactCareerSignatureBasis,
  urlDeleteContactCareerSignatureBasis,
  urlUpdateContactCareerSignatureBasis,
} from '@/pages/request.js'
import TableCrud from '@/components/table-crud.vue'
import NoTableData from '@/components/no-table-data.vue'
import Loader from '@/components/Loader.vue'
import { useVuelidate } from '@vuelidate/core'
import { useSelect } from '@/utils/mixins/useSelect'
import { setSnackbar } from 'best-modules/plugins/index'
export default {
  components: {
    TableCrud,
    NoTableData,
    Loader,
  },
  emits: ['fullPostName', 'role', 'department', 'position'],
  setup() {
    return { v$: useVuelidate(), ...useSelect() }
  },
  props: { dialog: { type: Object } },
  validations() {
    const career = {
      fullPostName: { required },
      postId: { required },
      departmentId: { required },
      roleId: { required },
      signatoryBases: this.career.isSignatureRight ? { required } : {},
      newSignatureName: [2, 8, 9].includes(this.newSignatureType)
        ? { required }
        : {},
    }

    return {
      career,
    }
  },
  data() {
    return {
      career: {
        contractorId: null,
        fullPostName: null,
        postId: null,
        departmentId: null,
        roleId: null,
        isSignatureRight: 0,
        signatoryBases: [],
      },
      newSignatureType: 1,
      newSignatureName: null,
      openReason: false,
      signatureActionType: 'add',
      editedSignatureReason: null,
      careerTHead: [
        {
          title: 'Тип',
          key: 'basisDocumentId',
          align: 'start',
          sortable: false,
        },
        {
          title: 'Довіреність',
          key: 'documentComment',
          align: 'start',
          sortable: false,
        },
        { title: 'Дiї', key: 'actions', align: 'end', sortable: false },
      ],
    }
  },
  computed: {
    ...mapState({
      selectItems: state => state.selectItems,
    }),
    validation() {
      return {
        v$: this.v$.career,
        name: 'contractorCrud',
      } /* add keys after back implementation */
    },
    contractorIdErr() {
      return setErrHandler(this.v$.career.contractorId)
    },
    fullPostNameErr() {
      return setErrHandler(this.v$.career.fullPostName)
    },
    postIdErr() {
      return setErrHandler(this.v$.career.postId)
    },
    signatoryBasesErr() {
      return setErrHandler(this.v$.career.signatoryBases)
    },
    newSignatureNameErr() {
      return setErrHandler(this.v$.career.newSignatureName)
    },
    departmentIdErr() {
      return setErrHandler(this.v$.career.departmentId)
    },
    roleIdErr() {
      return setErrHandler(this.v$.career.roleId)
    },
  },
  methods: {
    getSafe,
    contractorFilter,
    setErrHandler,
    urlContactUpdateCareer,
    urlCreateContactCareerSignatureBasis,
    changeSignatureReason() {
      if ([2, 8, 9].includes(this.newSignatureType) && !this.newSignatureName) {
        this.v$.$anyError
        this.v$.$touch()
        return v$Notify(this.v$.career, 'contractorCrud')
      }

      if (this.career.signatoryBases === null) {
        this.career.signatoryBases = []
      }

      if (this.signatureActionType === 'add') {
        if (
          this.newSignatureType === 1 &&
          this.career.signatoryBases.findIndex(e => e.basisDocumentId === 1) >
            -1
        ) {
          return setSnackbar({ text: 'Статут вже існує' })
        }
        const req = {
          contactCareerId: this.career.id,
          basisDocumentId: this.newSignatureType,
          documentComment: this.newSignatureName,
        }
        this.$axios
          .post(urlCreateContactCareerSignatureBasis(), req)
          .then(res => {
            setSnackbar({ text: 'Підставу додано' })
            return this.career.signatoryBases.push(res.data)
          })
          .catch(this.$err)
      } else if (this.signatureActionType === 'edit') {
        const req = {
          contactCareerId: this.career.id,
          basisDocumentId: this.newSignatureType,
          documentComment: this.newSignatureName,
        }

        this.career.signatoryBases = this.career.signatoryBases.map(el => {
          if (el.id === this.editedSignatureReason) {
            this.$axios
              .post(urlUpdateContactCareerSignatureBasis(el.id), req)
              .then(res => {
                setSnackbar({ text: 'Підставу оновлено' })
                return res
              })
              .catch(this.$err)

            return {
              basisDocumentId: this.newSignatureType,
              documentComment: this.newSignatureName,
              id: el.id,
            }
          } else {
            return el
          }
        })

        this.openReason = false
        this.editedSignatureReason = null
        this.signatureActionType = 'add'
      }

      this.openReason = false
      this.newSignatureType = 1
      this.newSignatureName = null
    },
    generateSignatoryId() {
      const l = this.career.signatoryBases.length
      return l > 0 ? this.career.signatoryBases[l - 1].id + 1 : 1
    },
    deleteSignature(item) {
      this.$axios
        .delete(urlDeleteContactCareerSignatureBasis(item.id))
        .then(res => {
          setSnackbar({ text: 'Підставу видалено' })
          return res
        })
        .catch(this.$err)
      this.career.signatoryBases = this.career.signatoryBases.filter(
        el => el.id !== item.id
      )
    },
    editSignature(item) {
      this.openReason = true
      this.newSignatureType = item.basisDocumentId
      this.newSignatureName = item.documentComment
      this.editedSignatureReason = item.id
      this.signatureActionType = 'edit'
    },
    submit() {
      this.career.contactCareerSignatureBases = this.career.signatoryBases

      if (this.career.contact?.id) {
        this.career.contactId = this.career.contact.id
      }
      if (this.career.contractor) {
        delete this.career.contractor
      }
      return this.$axios
        .post(this.urlContactUpdateCareer(this.career.id), this.career)
        .then(res => {
          setSnackbar({ text: "Кар'єру оновлено" })
          this.$store.commit('refreshPage')
          return res
        })
        .catch(this.$err)
    },
  },
  watch: {
    'career.isSignatureRight': function (val) {
      console.log('val', val)
      if (!val) this.career.signatoryBases = null
    },
  },
  created() {
    this.$store.dispatch('addBasisDocuments')
    this.career = this.dialog.dialogItem
  },
}
</script>

<style scoped></style>
