<template>
  <div>
    <v-row class="align-center">
      <v-col :cols="12" :md="3">
        <span class="area-label">Дата реєстрації</span>
        <DatePicker v-model="fop.date" single hide-details placeholder="Дата" />
      </v-col>
      <v-col :cols="12" :md="3">
        <span class="area-label">Номер запису</span>
        <v-text-field
          id="fop-number"
          v-model="fop.number"
          :error-messages="fopNumberErr"
          :readonly="dialog.params.readonly"
          placeholder="Введіть текст"
          dense
          hide-details
          @input="maxNumLength('fop-number', 19)"
          @blur="v$.fop.number.$touch()">
        </v-text-field>
      </v-col>
      <v-col :cols="12" :md="3" class="pt-6 pb-0">
        <v-checkbox
          v-model="fop.isVatPayer"
          color="red"
          :readonly="dialog.params.readonly"
          class="mt-0"
          hide-details
          dense>
          <template #label>
            <span style="font-size: 12px"> Платник ПДВ </span>
          </template>
        </v-checkbox>
      </v-col>
      <v-col v-if="fop.isVatPayer" cols="12" md="3">
        <span class="area-label">ІПН</span>
        <v-text-field
          id="contact-inn"
          v-model="fop.innPayerVAT"
          :readonly="dialog.params.readonly"
          hide-details
          placeholder="10 цифр"
          dense
          @input="maxNumLength('contact-inn', 10)">
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="12" :md="12">
        <FilesUploader
          v-model:documents="fop.extractOrExcerpt"
          label="Виписка або витяг з ЄДРЮОб ФОП та ГФ"
          :readonly="dialog.params.readonly" />
      </v-col>
      <v-col cols="12" md="12" sm="12">
        <queds-table
          v-model:queds="fop.queds"
          v-model:mainQuedId="fop.mainQuedId"
          :readonly="dialog.params.readonly"
          :mainQuedObj="fop.mainQued"
          :v$MainQuedId="v$.fop.mainQuedId"
          :v$Queds="v$.fop.queds">
        </queds-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DatePicker from '@/components/DatePicker.vue'
import FilesUploader from '@/components/FilesUploader.vue'
import { setErrHandler } from '@/utils/helperFunc'
import quedsTable from '@/components/queds/queds-table.vue'
import { urlContactFopCreate, urlContactFopUpdate } from '@/pages/request'
import { setSnackbar } from 'best-modules/plugins/index'
import { maxNumLength } from '@/utils/masks'
import { minLength, required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'

export default {
  components: { quedsTable, FilesUploader, DatePicker },
  props: {
    dialog: { type: Object },
  },
  setup() {
    return { v$: useVuelidate() }
  },
  validations() {
    return {
      fop: {
        extractOrExcerpt: { required },
        date: { required },
        number: { required, minLength: minLength(19) },
        isVatPayer: {},
        innPayerVAT: this.fop.isVatPayer ? { required } : {},
        queds: { required },
        mainQuedId: { required },
      },
    }
  },
  data: () => ({
    fop: {
      extractOrExcerpt: null,
      date: null,
      number: null,
      isVatPayer: false,
      innPayerVAT: null,
      queds: [],
      mainQuedId: null,
    },
  }),
  computed: {
    validation() {
      return { v$: this.v$.fop, name: 'contactFop' }
    },
    fopDateErr() {
      return setErrHandler(this.v$?.fop?.date)
    },
    fopNumberErr() {
      return setErrHandler(this.v$?.fop?.number)
    },
    mainQuedIdErr() {
      return setErrHandler(this.v$?.fop?.mainQuedId)
    },
  },
  methods: {
    maxNumLength,
    submit() {
      const url = this.dialog.params.isUpdate
        ? urlContactFopUpdate(this.fop.id)
        : urlContactFopCreate()
      return this.$axios
        .post(url, { ...this.fop, contactId: this.dialog.params.contactId })
        .then(res => {
          if (res) {
            setSnackbar({ text: 'Дані ФОП оновлено' })
          }
          return res
        })
    },
  },
  watch: {
    'fop.isVatPayer': function (val) {
      if (!val) {
        this.fop.innPayerVAT = null
      }
    },
  },
  mounted() {
    Object.assign(this.fop, this.dialog.dialogItem)
  },
}
</script>
