<template>
  <div>
    <v-row>
      <v-col
        v-if="createDealerOutletContactPerson"
        class="pb-0"
        cols="12"
        md="12"
        sm="12">
        <span class="label">Прізвище</span>
        <v-text-field
          v-model="dealerContactPerson.surname"
          :error-messages="dealerAgentSurnameErr"
          :loading="duplicatesLoading.surname"
          placeholder="Введіть текст"
          hide-details
          @blur="
            () => {
              v$.dealerContactPerson.surname.$touch()
              duplicatesLoading.surname = true
              if (dealerContactPerson.surname) {
                checkDuplicates(dealerContactPerson.surname).then(
                  () => (duplicatesLoading.surname = false)
                )
              }
            }
          ">
        </v-text-field>
      </v-col>
      <v-col
        v-if="createDealerOutletContactPerson"
        class="pb-0"
        cols="12"
        md="12"
        sm="12">
        <span class="label">Iм`я</span>
        <v-text-field
          v-model="dealerContactPerson.name"
          :error-messages="dealerAgentNameErr"
          placeholder="Введіть текст"
          hide-details
          @blur="v$.dealerContactPerson.name.$touch()">
        </v-text-field>
      </v-col>
      <v-col
        v-if="createDealerOutletContactPerson"
        class="pb-0"
        cols="12"
        md="12"
        sm="12">
        <span class="label">По батькові</span>
        <v-text-field
          v-model="dealerContactPerson.patronymic"
          placeholder="Введіть текст"
          hide-details>
        </v-text-field>
      </v-col>
      <v-col
        v-if="
          addDealerAgent || deleteDealerAgent || editDealerOutletContactPerson
        "
        class="pb-0"
        cols="12"
        md="12"
        sm="12">
        <span class="label">Співробітник</span>
        <v-autocomplete
          v-model="dealerContactPerson.contactId"
          :items="filteredContacts"
          :error-messages="contactIdErr"
          item-value="id"
          item-title="fullName"
          hide-details
          placeholder="Оберіть зі списку"
          :readonly="deleteDealerAgent"
          :disabled="deleteDealerAgent"
          @blur="v$.dealerContactPerson.contactId.$touch()">
          <template #append>
            <span v-if="deleteDealerAgent"></span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col class="pb-0" cols="12" md="12" sm="12">
        <span class="label">Посада</span>
        <v-select
          v-model="dealerContactPerson.postId"
          :error-messages="dealerAgentPostErr"
          :items="directoryItems('careerPosts', dealerContactPerson.post)"
          item-title="name"
          item-value="id"
          placeholder="Оберіть зі списку"
          hide-details
          :readonly="deleteDealerAgent"
          :disabled="deleteDealerAgent"
          @focus="asyncAction('addCareerPosts')"
          @blur="v$.dealerContactPerson.postId.$touch()"
          @change="
            dealerContactPerson.fullPostName = selectItems.careerPosts.find(
              post => post.id === $event
            ).name
          ">
          <template #append>
            <span v-if="deleteDealerAgent"></span>
          </template>
        </v-select>
      </v-col>
      <v-col
        v-if="
          addDealerAgent ||
          createDealerOutletContactPerson ||
          editDealerOutletContactPerson
        "
        class="pb-0"
        cols="12"
        md="12"
        sm="12">
        <span class="label">Повна назва посади</span>
        <v-text-field
          v-model="dealerContactPerson.fullPostName"
          :error-messages="dealerAgentFullPostErr"
          :readonly="deleteDealerAgent"
          :disabled="deleteDealerAgent"
          placeholder="Введіть текст"
          hide-details
          @blur="v$.dealerContactPerson.fullPostName.$touch()">
        </v-text-field>
      </v-col>
      <v-col
        v-if="
          addDealerAgent ||
          createDealerOutletContactPerson ||
          editDealerOutletContactPerson
        "
        class="pb-0"
        cols="12"
        md="12"
        sm="12">
        <span class="label">Департамент</span>
        <v-select
          v-model="dealerContactPerson.departmentId"
          :items="
            directoryItems('careerDepartments', dealerContactPerson.department)
          "
          :error-messages="departmentIdErr"
          item-title="name"
          item-value="id"
          placeholder="Оберіть зі списку"
          hide-details
          :readonly="deleteDealerAgent"
          :disabled="deleteDealerAgent"
          @focus="asyncAction('addCareerDepartments')"
          @blur="v$.dealerContactPerson.departmentId.$touch()">
          <template #append>
            <span v-if="deleteDealerAgent"></span>
          </template>
        </v-select>
      </v-col>
      <v-col
        v-if="
          addDealerAgent ||
          createDealerOutletContactPerson ||
          editDealerOutletContactPerson
        "
        class="pb-0"
        cols="12"
        md="12"
        sm="12">
        <span class="label">Роль</span>
        <v-select
          v-model="dealerContactPerson.roleId"
          :error-messages="roleIdErr"
          :items="directoryItems('careerRoles', dealerContactPerson.role)"
          item-title="name"
          item-value="id"
          placeholder="Оберіть зі списку"
          hide-details
          :readonly="deleteDealerAgent"
          :disabled="deleteDealerAgent"
          @focus="asyncAction('addCareerRoles')"
          @blur="v$.dealerContactPerson.roleId.$touch()">
          <template #append>
            <span v-if="deleteDealerAgent"></span>
          </template>
        </v-select>
      </v-col>
      <v-col
        v-if="createDealerOutletContactPerson"
        class="pb-0"
        cols="12"
        md="12"
        sm="12">
        <span class="label">Телефон</span>
        <v-text-field
          id="agent-id"
          v-model="dealerContactPerson.mainPhone"
          :error-messages="dealerAgentMainPhoneErr"
          :loading="duplicatesLoading.phone"
          placeholder="Введіть номер"
          hide-details
          @input="phoneMask($event, 'agent-id')"
          @blur="
            () => {
              v$.dealerContactPerson.mainPhone.$touch()

              duplicatesLoading.phone = true
              checkDuplicates(dealerContactPerson.mainPhone, 'phone')
                .then(res =>
                  res.hasDuplicates
                    ? (createErrorMsg.phone =
                        'Контакт з таким телефоном вже існує')
                    : (createErrorMsg.phone = null)
                )
                .finally(() => (duplicatesLoading.phone = false))
            }
          ">
        </v-text-field>
      </v-col>
      <v-col v-if="createDealerOutletContactPerson" cols="12" md="12" sm="12">
        <span class="label">Пошта</span>
        <v-text-field
          v-model="dealerContactPerson.mainEmail"
          v-mask:[emailMask]
          :error-messages="dealerAgentMainEmailErr"
          :loading="duplicatesLoading.email"
          placeholder="Введіть електронну пошту"
          hide-details
          @blur="
            () => {
              v$.dealerContactPerson.mainEmail.$touch()
              duplicatesLoading.email = true
              checkDuplicates(dealerContactPerson.mainEmail, 'email')
                .then(res =>
                  res.hasDuplicates
                    ? (createErrorMsg.email =
                        'Контакт з такою поштою вже існує')
                    : (createErrorMsg.email = null)
                )
                .finally(() => (duplicatesLoading.email = false))
            }
          ">
        </v-text-field>
      </v-col>
      <v-col class="pb-0" cols="12" md="12" sm="12">
        <v-checkbox
          v-model="dealerContactPerson.isAgent"
          class="d-inline-block mt-0"
          dense
          hide-details
          :false-value="false"
          :true-value="true"
          :readonly="deleteDealerAgent"
          :disabled="deleteDealerAgent">
          <template #label>
            <span style="font-size: 12px"> Агент </span>
          </template>
        </v-checkbox>
      </v-col>
    </v-row>
    <h4 v-if="deleteDealerAgent" class="mt-5">
      Контактну особу буде відкріплено!
    </h4>
  </div>
</template>
<script>
import { required, email, minLength } from '@vuelidate/validators'
import { mapState } from 'vuex'
import {
  urlSetDealerAgent,
  urlDeleteDealerOutletEmployee,
  urlUpdateDealerAgent,
  urlDealerOutletAddContactPerson,
} from '@/pages/request'
import {
  setErrHandler,
  contactFilter,
  checkDuplicates,
} from '@/utils/helperFunc'
import { phoneMask } from '@/utils/masks'
import { emailMask } from 'best-modules/utils'
import { useVuelidate } from '@vuelidate/core'
import { useSelect } from '@/utils/mixins/useSelect'
export default {
  setup() {
    return { v$: useVuelidate(), ...useSelect() }
  },
  props: { dialog: { type: Object } },
  validations() {
    const surname = this.createDealerOutletContactPerson ? { required } : {}
    const name = this.createDealerOutletContactPerson ? { required } : {}
    const mainPhone = this.createDealerOutletContactPerson
      ? { required, minLength: minLength(23) }
      : {}
    const mainEmail = this.createDealerOutletContactPerson
      ? { required, email }
      : {}
    const contactId =
      this.addDealerAgent || this.editDealerOutletContactPerson
        ? { required }
        : {}
    const postId = !this.deleteDealerAgent ? { required } : {}
    const fullPostName =
      this.createDealerOutletContactPerson ||
      this.addDealerAgent ||
      this.editDealerOutletContactPerson
        ? { required }
        : {}
    const departmentId =
      this.createDealerOutletContactPerson ||
      this.addDealerAgent ||
      this.editDealerOutletContactPerson
        ? { required }
        : {}
    const roleId =
      this.createDealerOutletContactPerson ||
      this.addDealerAgent ||
      this.editDealerOutletContactPerson
        ? { required }
        : {}

    return {
      dealerContactPerson: {
        dealerPointId: { required },
        postId: postId,
        contactId: contactId,
        surname: surname,
        name: name,
        mainPhone: mainPhone,
        mainEmail: mainEmail,
        fullPostName: fullPostName,
        departmentId: departmentId,
        roleId: roleId,
      },
    }
  },
  data: () => ({
    duplicatesLoading: {
      surname: false,
      phone: false,
      email: false,
    },
    createErrorMsg: {
      email: null,
      phone: null,
    },
    dealerContactPerson: {
      departmentId: null,
      dealerPointId: null,
      postId: null,
      contactId: null,
      surname: null,
      name: null,
      patronymic: null,
      mainPhone: null,
      mainEmail: null,
      isAgent: false,
      fullPostName: null,
      roleId: null,
    },
    emailMask,
  }),
  computed: {
    ...mapState({ selectItems: state => state.selectItems }),
    addDealerAgent() {
      return this.dialog.action === 'addDealerAgent'
    },
    deleteDealerAgent() {
      return this.dialog.action === 'deleteDealerAgent'
    },
    editDealerAgent() {
      return this.dialog.action === 'editDealerAgent'
    },
    createDealerOutletContactPerson() {
      return this.dialog.action === 'createDealerOutletContactPerson'
    },
    editDealerOutletContactPerson() {
      return this.dialog.action === 'editDealerOutletContactPerson'
    },
    filteredContacts() {
      const employeesIds = this.dealerContactPerson.employeesIds || []
      return this.selectItems.contacts.filter(
        contact => !employeesIds.includes(contact.id)
      )
    },
    validation() {
      return { v$: this.v$.dealerContactPerson, name: 'dealerContactPerson' }
    },
    dealerId() {
      return this.$store.state?.dialog?.params?.dealerId
    },
    agentId() {
      return this.$store.state?.dialog?.params?.agentId
    },
    contactIdErr() {
      return this.setErrHandler(this.v$.dealerContactPerson.contactId)
    },
    dealerAgentPostErr() {
      return this.setErrHandler(this.v$.dealerContactPerson.postId)
    },
    dealerAgentSurnameErr() {
      return this.setErrHandler(this.v$.dealerContactPerson.surname)
    },
    dealerAgentNameErr() {
      return this.setErrHandler(this.v$.dealerContactPerson.name)
    },
    dealerAgentMainPhoneErr() {
      return this.setErrHandler(this.v$.dealerContactPerson.mainPhone)
    },
    dealerAgentMainEmailErr() {
      return this.setErrHandler(this.v$.dealerContactPerson.mainEmail)
    },
    dealerAgentFullPostErr() {
      return this.setErrHandler(this.v$.dealerContactPerson.fullPostName)
    },
    departmentIdErr() {
      return this.setErrHandler(this.v$.dealerContactPerson.departmentId)
    },
    roleIdErr() {
      return this.setErrHandler(this.v$.dealerContactPerson.roleId)
    },
  },
  methods: {
    phoneMask,
    contactFilter,
    setErrHandler,
    checkDuplicates,
    submit() {
      if (this.createErrorMsg.phone || this.createErrorMsg.email) {
        return {
          message: this.createErrorMsg.phone || this.createErrorMsg.email,
        }
      }
      switch (true) {
        case this.addDealerAgent:
          return this.setAgent()
        case this.createDealerOutletContactPerson:
          return this.createAgent()
        case this.editDealerOutletContactPerson:
          return this.editAgent()
        case this.deleteDealerAgent:
          return this.deleteAgent()
        default:
          return Promise.reject('No handler')
      }
    },
    setAgent() {
      return this.$axios.post(
        urlSetDealerAgent(this.dealerContactPerson.dealerPointId),
        this.dealerContactPerson
      )
    },
    createAgent() {
      return this.$axios.post(
        urlDealerOutletAddContactPerson(this.dealerContactPerson.dealerPointId),
        this.dealerContactPerson
      )
    },
    deleteAgent() {
      return this.$axios.delete(
        urlDeleteDealerOutletEmployee(this.dealerContactPerson.id)
      )
    },
    editAgent() {
      return this.$axios.post(
        urlUpdateDealerAgent(this.agentId),
        this.dealerContactPerson
      )
    },
  },
  created() {
    this.$store.dispatch('addContacts')
  },
  mounted() {
    Object.assign(this.dealerContactPerson, this.dialog.dialogItem)
  },
}
</script>
