<template>
  <div>
    <v-textarea
      v-model="specificationText"
      :readonly="dialog.params.readonly"
      placeholder="Введіть текст"
      dense>
    </v-textarea>
  </div>
</template>

<script>
export default {
  props: {
    dialog: { type: Object },
  },
  data() {
    return {
      specificationText: '',
    }
  },
  methods: {
    submit() {
      return Promise.resolve(this.specificationText || null)
    },
  },
  mounted() {
    this.specificationText = this.dialog.dialogItem.specification
  },
}
</script>

<style scoped></style>
