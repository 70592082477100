<template>
  <div>
    <v-fade-transition hide-on-leave>
      <div v-if="routeContracts" class="contracts-wrapper">
        <Filters :filters="filters" class="ml-2" />
        <div v-show="!loading" class="pt-5">
          <v-data-table
            :headers="dolListHeaders"
            :items="dolContractList"
            class="calc-table --cust-cell-pd"
            mobileBreakpoint="750"
            dense
            :items-per-page="-1"
            hide-default-footer
            :hide-default-header="!dolContractList.length">
            <!-- eslint-disable-next-line -->
            <template #item.leasingObjectName="{ item }">
              {{ item.leasingObjectName }}
            </template>
            <!-- eslint-disable-next-line -->
            <template #item.lessee="{ item }">
              <ALink
                :text="item.lessee.name"
                :route="{
                  name: [1, 3].includes(item.lessee.id)
                    ? 'individuals-form'
                    : 'contractors-form',
                  params: { id: item.lessee.id },
                }">
              </ALink>
            </template>
            <!-- eslint-disable-next-line -->
            <template #item.agreementDate="{ item }">
              {{ humanDate(formatDate(item.agreementDate, true)) }}
            </template>
            <!-- eslint-disable-next-line -->
            <template #item.lesseeShortName="{ item }">
              <ALink
                :text="item.lessee.name"
                :route="getEntityRoute(item.lessee.id, item.lessee.typeId)">
              </ALink>
            </template>
            <!-- eslint-disable-next-line -->
            <template #item.number="{ item }">
              <ALink
                :text="item.number"
                :route="{ name: 'dfl-contract', params: { id: item.id } }">
              </ALink>
            </template>
            <!-- eslint-disable-next-line -->
            <template #item.actions="{ item }">
              <TableCrud
                :actionsArray="[
                  {
                    action: () => pushToDflContract(item),
                    text: 'Вiдкрити',
                    icon: 'mdi-folder-open-outline',
                  },
                ]">
              </TableCrud>
            </template>
          </v-data-table>
          <Pagination :getItems="getDolList" :trigger="paginationTrigger" />
        </div>
      </div>
    </v-fade-transition>
    <SectionLoader v-if="loading" />
    <v-fade-transition hide-on-leave>
      <RouterView v-if="!routeContracts && !loading" />
    </v-fade-transition>
  </div>
</template>
<script>
import TableCrud from '@/components/table-crud.vue'
import SectionLoader from '@/components/section-loader.vue'
import ALink from '@/components/Link.vue'
import Pagination from '@/components/Pagination.vue'
import { formatDate, humanDate } from '@/utils/formatFunc'
import { backDate, getEntityRoute } from '@/utils/helperFunc'
import { mapState } from 'vuex'
import { urlGetDflContracts } from '@/pages/request'
import Filters from '@/components/Filters.vue'
import { useSelect } from '@/utils/mixins/useSelect'
import { useFilter } from '@/utils/mixins/useFilter'
import { reactive } from 'vue'
import { dolListHeaders } from '@/utils/tableHeaders'
export default {
  components: {
    Filters,
    TableCrud,
    SectionLoader,
    ALink,
    Pagination,
  },
  setup() {
    const filters = reactive({
      statusId: {
        value: null,
        component: 'v-autocomplete',
        attrs: { items: 'dflStatuses', label: 'Статус' },
        focus: { action: 'addDFLStatuses' },
      },
      startDate: {
        value: null,
        component: 'DatePicker',
        attrs: { label: 'Період', range: true },
      },
      search: { value: null },
    })
    return {
      ...useSelect(),
      ...useFilter(filters),
      filters,
    }
  },
  data() {
    return {
      dolListHeaders,
      dolContractList: [],
      loading: false,
    }
  },
  computed: {
    ...mapState({
      selectItems: state => state.selectItems,
    }),
    routeContracts() {
      return this.$route.name === 'dol-contracts'
    },
  },
  methods: {
    backDate,
    formatDate,
    humanDate,
    getEntityRoute,
    pushToDflContract(item) {
      this.$router.push({ name: 'dfl-contract', params: { id: item.id } })
    },
    getDolList(page = 1, cancelToken, typeId = 2) {
      this.loading = true
      return this.$axios
        .get(urlGetDflContracts(page, this.filtersQueryObj, typeId, false), {
          cancelToken,
        })
        .then(res => {
          this.dolContractList = res.data.data
          this.loading = false
          return res
        })
        .catch(this.$err)
    },
  },
}
</script>
