<template>
  <v-row>
    <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
      <span class="label">Тема</span>
      <v-select
        v-model="ticket.themeId"
        :items="selectItems.ticketTheemes"
        :error-messages="themeIdErr"
        item-title="name"
        item-value="id"
        hide-details
        placeholder="Оберіть зі списку"
        @blur="v$.ticket.themeId.$touch()">
      </v-select>
    </v-col>
    <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
      <span class="label">Назва</span>
      <v-text-field
        v-model="ticket.title"
        hide-details
        placeholder="Введіть текст"
        :error-messages="titleErr"
        @blur="v$.ticket.title.$touch()">
      </v-text-field>
    </v-col>
    <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
      <span class="label">Опис</span>
      <v-textarea
        v-model="ticket.description"
        :error-messages="descriptionErr"
        hide-details
        placeholder="Введіть текст"
        :rows="2"
        @blur="v$.ticket.description.$touch()">
      </v-textarea>
    </v-col>
    <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
      <FilesUploader
        v-model:documents="ticket.file"
        class="mt-2"
        label="Тiкет файли"
        :list="false">
      </FilesUploader>
    </v-col>
  </v-row>
</template>
<script>
import FilesUploader from '@/components/FilesUploader.vue'
import { required } from '@vuelidate/validators'
import { mapState } from 'vuex'
import { urlPostCreateTicket } from '@/pages/request'

import { setErrHandler } from '@/utils/helperFunc'
import { useVuelidate } from '@vuelidate/core'
export default {
  components: { FilesUploader },
  props: { dialog: { type: Object } },
  setup() {
    return { v$: useVuelidate() }
  },
  validations() {
    return {
      ticket: {
        themeId: { required },
        title: { required },
        description: { required },
      },
    }
  },
  data: () => ({
    ticket: {
      themeId: null,
      title: null,
      description: null,
      file: null,
      statusId: null,
    },
  }),
  computed: {
    ...mapState({ selectItems: state => state.selectItems }),
    createTicket() {
      return this.dialog.action === 'createTicket'
    },
    validation() {
      return { v$: this.v$.ticket, name: 'ticket' }
    },
    themeIdErr() {
      return setErrHandler(this.v$.ticket.themeId)
    },
    titleErr() {
      return setErrHandler(this.v$.ticket.title)
    },
    descriptionErr() {
      return setErrHandler(this.v$.ticket.description)
    },
  },
  methods: {
    submit() {
      const createTicket = () =>
        this.$axios.post(urlPostCreateTicket(), this.ticket)

      switch (true) {
        case this.createTicket:
          return createTicket()
        default:
          return Promise.reject('No handler')
      }
    },
  },
  created() {
    this.$store.dispatch('addTicketTheemes')
  },
  mounted() {
    Object.assign(this.ticket, this.dialog.dialogItem)
  },
}
</script>
