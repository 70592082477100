<template>
  <v-row>
    <v-col cols="12" md="12" sm="12">
      <span
        >Співробітника {{ getContactInitials(user, { isResponsible: true }) }}
        <b>буде видалено!</b></span
      >
    </v-col>
  </v-row>
</template>
<script>
import { urlDeleteAdminUserDelete } from '@/pages/request'
import { getContactInitials } from '../../utils/helperFunc'

export default {
  props: { dialog: { type: Object } },
  data: () => ({ user: {} }),
  methods: {
    getContactInitials,
    submit() {
      return this.$axios
        .delete(urlDeleteAdminUserDelete(this.user.id))
        .then(res => {
          this.$setSnackbar({ text: 'Спiвробiтника успiшно видалено' })
          return res
        })
        .catch(this.$err)
    },
  },
  mounted() {
    Object.assign(this.user, this.dialog.dialogItem)
  },
}
</script>
