<template>
  <div>
    <SectionLoader v-if="loading" />
    <v-row v-if="!loading">
      <v-col :md="4" :cols="12">
        <v-autocomplete
          v-model="directory.tableName"
          :error-messages="tableNameErr"
          :items="directoryItems('directoryTableNames', directory.tableName)"
          item-title="table"
          placeholder="Оберiть таблицю"
          dense
          @focus="asyncAction('addDirectoryTableNames')"
          @change="fetchFieldItems"
          @blur="getSafe(() => v$.tableName.$touch())">
        </v-autocomplete>
        <v-autocomplete
          v-model="directory.field"
          :error-messages="fieldErr"
          :items="directoryItems(fieldItems, directory.field)"
          item-title="column"
          placeholder="Оберiть поле"
          dense
          @focus="fetchFieldItems"
          @blur="getSafe(() => v$.field.$touch())">
        </v-autocomplete>
        <v-text-field
          v-model="directory.name"
          placeholder="Введіть назву"
          dense
          :error-messages="nameErr"
          @blur="getSafe(() => v$.name.$touch())">
        </v-text-field>
      </v-col>
    </v-row>

    <ActionButtons
      :confirm="updateDirectory"
      :cancel="() => $store.commit('refreshPage')"
      :confirmLoading="loading"
      :confirmDisable="cacheState === cache || loading"
      :cancelDisable="cacheState === cache">
    </ActionButtons>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getSafe, setCache, setErrHandler } from '@/utils/helperFunc'
import { required } from '@vuelidate/validators'
import {
  urlGetAdminSingleDirectory,
  urlGetDirectoriesField,
  urlUpdateAdminSingleDirectory,
} from '@/pages/request'
import ActionButtons from '@/components/action-buttons.vue'
import SectionLoader from '@/components/section-loader.vue'
import { useVuelidate } from '@vuelidate/core'
import { useSelect } from '@/utils/mixins/useSelect'

export default {
  components: { SectionLoader, ActionButtons },
  setup() {
    return { v$: useVuelidate(), ...useSelect() }
  },
  data: () => ({
    loading: false,
    cache: null,
    fieldItems: [],
    directory: {},
  }),
  computed: {
    ...mapState({ selectItems: state => state.selectItems }),
    validation() {
      return { v$: this.v$, name: 'adminDirectory' }
    },
    tableNameErr() {
      return setErrHandler(this.v$.tableName)
    },
    fieldErr() {
      return setErrHandler(this.v$.field)
    },
    nameErr() {
      return setErrHandler(this.v$.name)
    },
    cacheState() {
      return setCache([this.directory])
    },
  },
  validations() {
    return {
      directory: {
        tableName: { required },
        field: { required },
        name: { required },
      },
    }
  },
  methods: {
    getSafe,
    urlGetAdminSingleDirectory,
    setDirectory(obj) {
      this.directory = obj
      this.cache = setCache([obj])
    },
    getSingleDirectory() {
      this.loading = true
      return this.$axios
        .get(urlGetAdminSingleDirectory(this.$route.params.id))
        .then(res => {
          this.loading = false
          this.setDirectory(res.data)
        })
    },
    fetchFieldItems() {
      if (this.directory.tableName) {
        return this.$axios
          .get(urlGetDirectoriesField(this.directory.tableName))
          .then(res => {
            if (res.data.message) {
              this.fieldItems.splice(0)
              return this.$setSnackbar({
                text: res.data.message,
                color: 'warning',
              })
            }
            this.fieldItems.splice(0)
            this.fieldItems.push(...res.data.columns)
          })
      }
    },
    updateDirectory() {
      this.loading = true
      return this.$axios
        .post(
          urlUpdateAdminSingleDirectory(this.$route.params.id),
          this.directory
        )
        .then(res => {
          this.$setSnackbar({ text: 'Довідник оновлено' })
          this.loading = false
          this.setDirectory(res.data)
        })
    },
  },
  mounted() {
    this.getSingleDirectory()
  },
}
</script>

<style scoped></style>
