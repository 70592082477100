<template>
  <div>
    <selectQuedsDialog
      v-model="dialog"
      :queds="computedQueds"
      @submit="changeQueds" />
    <div>
      <span class="label">Основний вид діяльності</span>
      <v-autocomplete
        v-model="computedMainQuedId"
        class="mb-3"
        :error-messages="mainQuedIdErr"
        :readonly="readonly"
        :items="directoryItems('activityTypes', mainQuedObj)"
        :item-title="item => `${item.code} ${item.title}`"
        item-value="id"
        placeholder="Введіть номер або назву КВЕДа (один)"
        dense
        @blur="v$MainQuedId && v$MainQuedId.$touch()"
        @focus="asyncAction('addActivities')">
        <template #append-item
          ><Loader
            v-if="
              asyncLoading('addActivities') &&
              directoryItems('activityTypes', mainQuedObj).length
            "
        /></template>
        <template v-if="asyncLoading('addActivities')" #no-data
          ><Loader
        /></template>
      </v-autocomplete>
    </div>
    <div class="d-flex justify-space-between">
      <span>
        <span class="label" style="margin-bottom: -6px"
          >Інші види діяльності (додайте за потреби):</span
        >
        <span
          class="error--text d-inline-block"
          style="font-size: 12px; height: 14px">
          {{ quedsErr }}
        </span>
      </span>
      <span
        v-if="!readonly"
        data-readonly="hide"
        class="underline-btn color-red"
        @click="dialog = true"
        >{{
          computedQueds && computedQueds.length ? 'Редагувати' : 'Додати'
        }}</span
      >
    </div>
    <div>
      <div v-for="qued in filteredQueds" :key="qued.code" class="qued">
        {{ `${qued.code} ${qued.title}` }}
      </div>
    </div>
    <span
      v-if="computedQueds.length > 4"
      class="underline-btn color-gray"
      @click="expandQueds = !expandQueds"
      >{{
        expandQueds ? 'Приховати' : `Показати всі (${computedQueds.length - 4})`
      }}</span
    >
  </div>
</template>
<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import selectQuedsDialog from './dialog.vue'
import { setErrHandler } from '@/utils/helperFunc'
import { useSelect } from '@/utils/mixins/useSelect'
export default {
  emits: ['update:queds', 'update:mainQuedId'],
  components: {
    Loader,
    selectQuedsDialog,
  },
  setup() {
    return {
      ...useSelect(),
    }
  },
  props: {
    readonly: { type: Boolean, default: false },
    queds: { type: Array, required: false },
    mainQuedId: { type: Number },
    mainQuedObj: { type: Object },
    v$MainQuedId: { type: Object },
    v$Queds: { type: Object },
  },
  data: () => ({
    dialog: false,
    expandQueds: false,
  }),
  computed: {
    ...mapState({
      selectItems: state => state.selectItems,
    }),
    computedMainQuedId: {
      get() {
        return this.mainQuedId
      },
      set(val) {
        this.$emit('update:mainQuedId', val)
      },
    },
    computedQueds: {
      get() {
        return this.queds || []
      },
      set(val) {
        this.$emit('update:queds', val)
      },
    },
    filteredQueds() {
      return this.computedQueds.filter((_, idx) => {
        return this.expandQueds || idx < 4
      })
    },
    mainQuedIdErr() {
      return setErrHandler(this.v$MainQuedId)
    },
    quedsErr() {
      return setErrHandler(this.v$Queds)
    },
  },
  methods: {
    changeQueds(queds) {
      this.computedQueds = queds
    },
  },
}
</script>

<style lang="scss" scoped>
//.v-text-field__details {
//  display: none !important;
//}
.qued {
  padding: 3px 10px;
  margin-bottom: 2px;
  border-radius: 3px;
}
</style>
