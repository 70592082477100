<template>
  <div>
    <v-fade-transition hide-on-leave>
      <div v-if="!route" style="min-height: 450px">
        <div class="parties-content">
          <Filters :filters="filters" class="ml-2">
            <template #header-action>
              <v-btn
                data-readonly="hide"
                class="text-white mr-3"
                style="
                  background: rgb(252, 114, 71);
                  border-radius: 4px;
                  color: #fff;
                "
                @click="newContractor()">
                <v-icon style="margin-right: 5px; color: #fff !important">
                  {{ 'mdi-plus' }}
                </v-icon>
                Додати контрагента
              </v-btn>
            </template>
          </Filters>
        </div>
        <v-row>
          <v-col class="pt-0">
            <div v-show="!pageLoad" class="pt-5">
              <v-data-table
                :headers="contractorHeaders"
                :items="contractorList"
                class="calc-table --cust-cell-pd"
                mobileBreakpoint="750"
                dense
                hide-default-footer
                :hide-default-header="!contractorList.length"
                :custom-sort="tableDateSort()"
                :items-per-page="-1">
                <!-- eslint-disable-next-line -->
                <template #item.type="{ item }">
                  {{
                    getSafe(() => item.type.map(t => t.name).join(', '), '---')
                  }}
                </template>
                <!-- eslint-disable-next-line -->
                <template #item.status="{ item }">
                  {{
                    item.status && item.status.name ? item.status.name : '---'
                  }}
                </template>
                <!-- eslint-disable-next-line -->
                <template #item.responsible="{ item }">
                  {{ item.responsible.fullName }}
                </template>
                <!-- eslint-disable-next-line -->
                <template #item.actions="{ item }">
                  <div style="margin-right: -15px">
                    <TableCrud
                      :actionsArray="[
                        {
                          action: () =>
                            $router.push({
                              name: 'contractors-form',
                              params: { id: item.id },
                            }),
                          text: 'Вiдкрити',
                          icon: 'mdi-folder-open-outline',
                        },
                      ]">
                    </TableCrud>
                  </div>
                </template>
                <!-- eslint-disable-next-line -->
                <template #item.shortName="{ item }">
                  <ALink
                    :text="item.shortName"
                    :route="{
                      name: 'contractors-form',
                      params: { id: item.id },
                    }"></ALink>
                </template>
                <!-- eslint-disable-next-line -->
                <template #item.archiveCode="{ item }">
                  {{ item.archiveCode || '---' }}
                </template>
                <!-- eslint-disable-next-line -->
                <template #item.clientStatus="{ item }">
                  <!--            {{ item.status && item.status.name ? item.status.name : '-&#45;&#45;' }}-->
                  {{ item.clientStatus ? item.clientStatus.name : '---' }}
                </template>
                <!-- eslint-disable-next-line -->
                <template #item.contractorTypeId="{ item }">
                  {{ getContractorTypes(item.contractorTypeId) }}
                </template>
              </v-data-table>
              <Pagination
                :getItems="getContractors"
                :trigger="paginationTrigger" />
            </div>
          </v-col>
        </v-row>
        <section-loader v-if="pageLoad"> </section-loader>
      </div>
    </v-fade-transition>
    <router-view
      :key="updateCounterpartyTrigger"
      :contractorList="contractorList">
    </router-view>
  </div>
</template>
<script>
import sectionLoader from '@/components/section-loader.vue'
import ALink from '@/components/Link.vue'
import Pagination from '@/components/Pagination.vue'
import { contractor as CT } from '@/type-ids'
import { urlContractorsType, urlGetFilteredContractors } from '@/pages/request'
import {
  tableDateSort,
  getContactInitials,
  getResponsibleShortName,
  getSafe,
} from '@/utils/helperFunc'
import { mapState, mapGetters } from 'vuex'
import Filters from '@/components/Filters.vue'
import { useSelect } from '@/utils/mixins/useSelect'
import { useFilter } from '@/utils/mixins/useFilter'
import { reactive } from 'vue'
import TableCrud from '@/components/table-crud.vue'
import { contractorHeaders } from '@/utils/tableHeaders'
export default {
  components: {
    TableCrud,
    Filters,
    sectionLoader,

    ALink,
    Pagination,
  },
  setup() {
    const filters = reactive({
      statusId: {
        value: null,
        component: 'v-autocomplete',
        attrs: { label: 'Статус', items: 'contactStatuses' },
      },
      contractorTypeId: {
        value: null,
        component: 'v-autocomplete',
        attrs: { label: 'Тип контрагента', items: 'contractorTypes' },
      },
      startDate: {
        value: null,
        component: 'DatePicker',
        attrs: { label: 'Період', range: true },
      },
      responsibleId: {
        value: null,
        component: 'v-autocomplete',
        attrs: {
          label: 'Відповідальний',
          itemTitle: u => getContactInitials(u, { isResponsible: true }),
          items: 'activeUsers',
        },
        focus: { action: 'addAllUsers' },
      },
      search: { value: null },
    })
    return {
      ...useSelect(),
      ...useFilter(filters),
      filters,
    }
  },
  data: () => {
    return {
      contractorHeaders,
      updateCounterpartyTrigger: 1,
      contractorList: [],
      currentAgent: { name: null, type: null },
      eSTitle: 'Контрагенти Відсутні',
      eSMessage:
        'На цiй сторiнцi буде перелік конрагентiв (юридичних та фiзичних осiб) пов`язаних з дiяльнiстю Best-Leasing.',
      eSActionTxt: 'Додати контрагента',
      dialog: false,
      dialogState: null,
      dialogItem: {},
      pageLoad: false,
    }
  },
  computed: {
    route() {
      return this.$route?.params?.id
    },
    ...mapState({
      selectItems: state => state.selectItems,
      pagination: state => state.pagination.pagination,
    }),
    ...mapGetters({
      activeUsers: 'activeUsers',
    }),
  },
  methods: {
    getSafe,
    tableDateSort,
    urlContractorsType,
    urlGetFilteredContractors,
    getContactInitials,
    getResponsibleShortName,
    getContractorTypes(types) {
      if (!types || !types.length) return '---'
      return types.map(t => CT.name[t]).join(', ')
    },
    getResponsible(responsible) {
      return `${responsible?.surname || '-'} ${responsible?.name || '-'}`
    },
    newContractor() {
      this.$store.commit('setDialog', {
        title: 'Контрагент',
        dialogItem: null,
        action: 'newContractor',
        params: {
          cb: () => this.updateList(),
        },
      })
    },
    getContractors(page) {
      this.pageLoad = true
      return this.$axios
        .get(this.urlGetFilteredContractors(page, this.filtersQueryObj))
        .then(res => {
          this.contractorList.splice(0)
          this.contractorList.push(...res.data.data)

          this.pageLoad = false

          return res
        })
        .catch(this.$err)
    },
    deleteContractor(contractor) {
      this.$store.commit('setDialog', {
        title: 'Контрагент',
        dialogItem: contractor,
        action: 'deleteContractor',
        params: {
          cb: () => this.updateList(),
        },
      })
    },
  },
  watch: {
    '$route.params.id': function () {
      this.updateCounterpartyTrigger += 1
    },
  },
  created() {
    this.$store.dispatch('addAllUsers')
  },
}
</script>
