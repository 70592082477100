<template>
  <div>
    <v-card-title class="pt-5" style="font-size: 1.1rem">
      Прикріплення документів
    </v-card-title>
    <v-card-text style="min-height: 200px">
      <FilesUploader
        v-model:documents="agreementData.signedGuarantorContract"
        :readonly="readonly"
        label="Договiр поруки (пiдписаний)"
        :iconSize="26"
        single>
      </FilesUploader>
      <FilesUploader
        v-model:documents="agreementData.otherDocuments"
        :readonly="readonly"
        label="Необов`язковi документи"
        icon="mdi-file-document-multiple-outline">
      </FilesUploader>
    </v-card-text>
  </div>
</template>
<script>
import FilesUploader from '@/components/FilesUploader.vue'
import { mapState } from 'vuex'

export default {
  components: { FilesUploader },
  props: { agreement: { type: Object } },
  computed: {
    ...mapState({
      user: state => state.user,
    }),
    readonly() {
      return ![1, 5, 6].includes(this.user.roleId)
    },
    agreementData() {
      return this.agreement
    },
  },
}
</script>
