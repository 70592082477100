<template>
  <div>
    <span class="label">Тип</span>
    <v-select
      v-model="planValue.typeId"
      :items="directoryItems('planValueTypes', planValue.type)"
      item-title="name"
      item-value="id"
      placeholder="оберіть зі списку"
      :loading="asyncLoading('addPlanValueTypes')"
      :error-messages="setErrHandler(v$.planValue.typeId)"
      @blur="v$.planValue.typeId.$touch()"></v-select>

    <span class="label">Місяць</span>
    <v-select
      v-model="planValue.month"
      placeholder="оберіть зі списку"
      :items="monthList"
      return-object
      :loading="asyncLoading('addMonths')"
      item-value="value"
      item-title="name"
      :error-messages="setErrHandler(v$.planValue.month)"
      @focus="asyncAction('addMonths')"
      @blur="v$.planValue.month.$touch()"></v-select>
  </div>
</template>

<script>
import { useSelect } from '@/utils/mixins/useSelect'
import { required } from '@vuelidate/validators'
import { urlCreatePlanValue, urlUpdatePlanValue } from '@/pages/request'
import { setErrHandler } from '@/utils/helperFunc'
import { pick } from 'lodash'
import { useVuelidate } from '@vuelidate/core'

export default {
  name: 'PlanValueType',
  setup() {
    return { v$: useVuelidate(), ...useSelect() }
  },
  props: {
    dialog: {
      type: Object,
    },
  },
  validations() {
    return {
      planValue: {
        typeId: { required },
        month: { required },
      },
    }
  },
  data: () => {
    return {
      planValue: {
        planId: null,
        typeId: null,
        month: null,
        year: new Date().getFullYear().toString(),
      },
    }
  },
  computed: {
    validation() {
      return { v$: this.v$.planValue, name: 'plan' }
    },
    createPlanValue() {
      return this.dialog.action === 'createPlanValueType'
    },
    updatePlanValue() {
      return this.dialog.action === 'updatePlanValueType'
    },
    existMonth() {
      const arr = []

      for (const planValue of this.dialog.dialogItem.plan.plan_values || []) {
        if (planValue.id !== this.planValue.id) {
          arr.push(planValue.month.id)
        }
      }

      return arr
    },
    monthList() {
      return this.directoryItems('months', this.planValue.month).filter(m => {
        return !this.existMonth.includes(m.id)
      })
    },
  },
  methods: {
    setErrHandler,
    submit() {
      const url = (() => {
        switch (true) {
          case this.createPlanValue:
            return urlCreatePlanValue
          case this.updatePlanValue:
            return urlUpdatePlanValue
          default:
            throw new Error('invalid action')
        }
      })()

      return this.$axios.post(
        url(this.planValue.id),
        pick(this.planValue, ['typeId', 'planId', 'month', 'year'])
      )
    },
  },
  created() {
    this.asyncAction('addPlanValueTypes')
    if (this.createPlanValue) {
      this.planValue.planId = this.dialog.dialogItem.plan.id
    }
    if (this.updatePlanValue) {
      Object.assign(this.planValue, this.dialog.dialogItem.planValue)
    }
  },
}
</script>
