<template>
  <div class="page">
    <div v-if="!isAccessGranted" style="z-index: 1000000; margin-right: 12px">
      <FaqPage @toggle-access="toggleAccess" />
    </div>
    <v-row v-if="isAccessGranted">
      <v-col cols="12" md="2" sm="12" class="pr-0">
        <v-tabs
          v-model="currentTab"
          color="white"
          slider-color="#fc7247"
          background
          direction="vertical"
          dense>
          <v-tab replace :to="{ name: 'faq-documents' }">Документація</v-tab>
          <v-tab replace :to="{ name: 'faq-videos' }">Відеоінструкція</v-tab>
          <v-tab replace :to="{ name: 'faq-others' }">Інше</v-tab>
        </v-tabs>
      </v-col>
      <v-col cols="12" md="10" sm="12" class="pl-0">
        <RouterView></RouterView>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import FaqPage from '@/components/FaqPage.vue'

export default {
  components: { FaqPage },
  data: () => ({
    currentTab: 0,
    isAccessGranted: true,
  }),
  methods: {
    toggleAccess() {
      this.isAccessGranted = !this.isAccessGranted
      localStorage.setItem('isAccessGranted', this.isAccessGranted.toString())
    },
  },
  mounted() {
    this.isAccessGranted = localStorage.getItem('isAccessGranted') === 'true'
  },
}
</script>

<style scoped>
.v-tabs-bar.v-item-group > * {
  background: #fff !important;
}

.v-tabs--vertical > .v-tabs-bar .v-tab {
  height: 35px !important;
}

a.v-tab {
  font-size: 12px !important;
}

a.v-tab-item--selected.v-tab {
  font-size: 12px !important;
}
</style>
