<template>
  <div>
    <v-data-table
      v-if="[1, 3].includes(executiveAuthorityId) && contractor.director"
      :headers="tableHeader"
      :items="contractor.director ? [contractor.director] : []"
      :mobile-breakpoint="mobileBreakpoint"
      hide-default-footer
      :hide-default-header="!contractor.director"
      class="pb-2 pt-1 custom-table"
      dense>
      <template #no-data>
        <NoData></NoData>
      </template>
      <!-- eslint-disable-next-line -->
      <template #item.fullName="{ item }">
        <ALink
          style="font-size: 13px !important"
          :text="director.fullName"
          :route="{ name: 'individuals-form', params: { id: director.id } }">
        </ALink>
      </template>
      <!-- eslint-disable-next-line -->
      <template #item.birthday="{ item }">
        <span style="font-size: 13px">{{ director.birthday || '---' }}</span>
      </template>
      <!-- eslint-disable-next-line -->
      <template #item.inn="{ item }">
        <span style="font-size: 13px">{{ director.inn || '---' }}</span>
      </template>
      <!-- eslint-disable-next-line -->
      <template #item.identity_document="{ item }">
        <span style="font-size: 13px">{{ getPassport(director) }}</span>
      </template>
      <!-- eslint-disable-next-line -->
      <template #item.passportScans="{ item }">
        <FilesUploader
          v-if="getSafe(() => director.documentPassport.length, false)"
          v-model:documents="director.documentPassport"
          :readonly="true">
        </FilesUploader>
        <span v-else>---</span>
      </template>
      <!-- eslint-disable-next-line -->
      <template #item.registrationAddress="{ item }">
        <span style="font-size: 13px">{{
          getAddress(director.addresses.find(a => a.type.id === 1)) || '---'
        }}</span>
      </template>
      <!-- eslint-disable-next-line -->
      <template #item.phones="{ item }">
        <span style="font-size: 13px">{{
          getSafe(() => director.phones, [])
            .map(ph => ph.phone)
            .join(', ') || '---'
        }}</span>
      </template>
      <!-- eslint-disable-next-line -->
      <template #item.post="{ item }">
        <span style="font-size: 13px">{{ item.post?.name || '---' }}</span>
      </template>
      <!-- eslint-disable-next-line -->
      <template #item.actions="{ item }">
        <div style="margin-right: -10px">
          <TableCrud :actionsArray="actions(item)"></TableCrud>
        </div>
      </template>
    </v-data-table>
    <v-data-table
      v-else-if="executiveAuthorityId === 2 && contractor.collegium"
      :headers="tableHeader"
      :items="contractor.collegium"
      hide-default-footer
      :hide-default-header="!contractor.collegium"
      class="pb-2 pt-1 custom-table"
      dense>
      <template #no-data>
        <NoData></NoData>
      </template>
      <!-- eslint-disable-next-line -->
      <template #item.fullName="{ item }">
        <ALink
          :text="item?.contact?.fullName"
          :route="{
            name: 'individuals-form',
            params: { id: item?.contact?.id },
          }">
        </ALink>
      </template>
      <!-- eslint-disable-next-line -->
      <template #item.birthday="{ item }">
        {{ getSafe(() => collegiumParticipant(item).birthday, '---') }}
      </template>
      <!-- eslint-disable-next-line -->
      <template #item.inn="{ item }">
        {{ getSafe(() => collegiumParticipant(item).inn, '---') }}
      </template>
      <!-- eslint-disable-next-line -->
      <template #item.post="{ item }">
        {{ getSafe(() => item.post?.name, '---') }}
      </template>
      <!-- eslint-disable-next-line -->
      <template #item.identity_document="{ item }">
        {{
          getSafe(
            () => getPassport(collegiumParticipant(item).identity_document),
            '---'
          )
        }}
      </template>
      <!-- eslint-disable-next-line -->
      <template #item.passportScans="{ item }">
        <FilesUploader
          v-if="
            getSafe(
              () => collegiumParticipant(item).identity_document.files.length,
              false
            )
          "
          v-model:documents="collegiumParticipant(item).identity_document.files"
          :disabled="true">
        </FilesUploader>
        <span v-else>---</span>
      </template>
      <!-- eslint-disable-next-line -->
      <template #item.registrationAddress="{ item }">
        {{
          getSafe(() =>
            getAddress(collegiumParticipant(item).addresses, []).find(
              a => a.type.id === 1
            )
          ) || '---'
        }}
      </template>
      <!-- eslint-disable-next-line -->
      <template #item.phones="{ item }">
        {{
          getSafe(
            () =>
              contactDetailsWithType(
                collegiumParticipant(item).phones,
                'phone'
              ),
            []
          )
        }}
      </template>
      <!-- eslint-disable-next-line -->
      <template #item.actions="{ item }">
        <div style="margin-right: -10px">
          <TableCrud :actionsArray="actions(item)"></TableCrud>
        </div>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import ALink from '@/components/Link.vue'
import NoData from '@/components/no-table-data.vue'
import FilesUploader from '@/components/FilesUploader.vue'
import { mapState } from 'vuex'
import {
  getPassport,
  getAddress,
  contactDetailsWithType,
  getSafe,
} from '@/utils/helperFunc'
import TableCrud from '@/components/table-crud.vue'
export default {
  components: { TableCrud, ALink, NoData, FilesUploader },
  props: {
    contractor: { type: Object },
    tableHeader: { type: Array },
    actions: { type: Function },
    mobileBreakpoint: { type: String, default: '750' },
  },
  computed: {
    ...mapState({
      selectItems: state => state.selectItems,
    }),
    executiveAuthorityId() {
      return this.contractor?.executiveAuthorityId
    },
    director() {
      const contact = this.contractor.director?.contact
      return contact || {}
    },
    post() {
      if (!this.selectItems?.contractorDirectorPosts?.length) return '---'
      const post = this.selectItems.contractorDirectorPosts.find(
        v => v.id === this.contractor?.director?.directorPostId
      )
      return post || {}
    },
  },
  methods: {
    getSafe,
    getAddress,
    getPassport,
    contactDetailsWithType,
    collegiumParticipant(item) {
      if (!this.selectItems?.detailedContacts?.length) return '---'
      const contact = this.selectItems.detailedContacts.find(
        v => v.id === item?.contactId
      )
      return contact || {}
    },
    collegiumParticipantPost(item) {
      if (!this.selectItems?.contractorDirectorPosts?.length) return '---'
      const post = this.selectItems.contractorDirectorPosts.find(
        v => v.id === item.post.id
      )
      return post?.name
    },
  },
}
</script>
