<template>
  <div>
    <div v-if="deleteTemplate">
      <p>
        Шаблон <strong>{{ dialog?.dialogItem?.item?.name }}</strong> буде
        видалено!
      </p>
    </div>
    <div v-show="editorRequired">
      <v-scroll-x-transition hide-on-leave>
        <div v-show="editorInitialized">
          <span class="label">Назва шаблону</span>
          <v-text-field
            v-if="editorRequired"
            v-model="template.name"
            :error-messages="templateTitleErr"
            placeholder="Введіть текст"
            dense
            hide-details
            @blur="v$.template.name.$touch()"
            @keyup.enter="submit()">
          </v-text-field>
        </div>
      </v-scroll-x-transition>
      <v-scroll-x-transition hide-on-leave>
        <section-loader v-show="!editorInitialized"></section-loader>
      </v-scroll-x-transition>
      <v-scroll-x-transition hide-on-leave>
        <div v-show="editorInitialized" ref="fakeClick" class="pt-4 pb-4">
          <editor
            :id="editorId"
            :api-key="key"
            language="uk"
            :initial-value="template.sign"
            :init="editorConfig(editorId, userSigns)"
            @on-init="customizeEditor($event)" />
        </div>
      </v-scroll-x-transition>
    </div>
  </div>
</template>

<script>
import sectionLoader from '@/components/section-loader.vue'
import Editor from '@tinymce/tinymce-vue'
import { required } from '@vuelidate/validators'
import { mapState } from 'vuex'
import { editorConfig, setHtml } from '@/utils/tinymce-config'
import {
  urlCreateClinetOfferTemplate,
  urlDeleteClientOfferTemplate,
  urlUpdateClientOfferTemplate,
} from '@/pages/request'
import { v$Notify, setErrHandler } from '@/utils/helperFunc'
import { useVuelidate } from '@vuelidate/core'

export default {
  components: { Editor, sectionLoader },
  setup() {
    return { v$: useVuelidate() }
  },
  props: {
    dialog: { type: Object },
  },
  validations() {
    if (this.editorRequired)
      return {
        template: {
          name: { required },
          sign: {},
        },
      }
    return {}
  },
  data: () => ({
    template: {
      name: null,
      sign: null,
    },
    userSigns: [],
    editorInit: false,
    showDelay: false,
    editorId: 'tiny-offer-template',
  }),
  computed: {
    ...mapState({
      user: state => state.user,
    }),
    key() {
      return import.meta.env.VITE_API_KEY_TINYMCE
    },
    validation() {
      return { v$: this.v$.template, name: 'template' }
    },
    editorInitialized() {
      return this.editorInit || window.tinymce
    },
    templateTitleErr() {
      return setErrHandler(this.v$?.template?.name)
    },
    templateHtmlErr() {
      return setErrHandler(this.v$?.template?.sign)
    },
    // dialog state
    addTemplate() {
      return this.dialog.action === 'addCustomTemplates'
    },
    editTemplate() {
      return this.dialog.action === 'editCustomTemplates'
    },
    deleteTemplate() {
      return this.dialog.action === 'deleteCustomTemplates'
    },
    viewTemplate() {
      return this.dialog.action === 'viewCustomTemplates'
    },
    userId() {
      return this.user.id
    },
    editorRequired() {
      return this.editTemplate || this.addTemplate || this.viewTemplate
    },
  },
  methods: {
    editorConfig,
    setHtml,
    urlCreateClinetOfferTemplate,
    urlUpdateClientOfferTemplate,
    urlDeleteClientOfferTemplate,
    initializeEditor() {
      this.editorInit = true
      this.getIframe()
    },
    getHtml() {
      this.template.sign = window.tinymce.get(this.editorId).getContent()
    },
    createTemplate() {
      return this.$axios
        .post(this.urlCreateClinetOfferTemplate(this.userId), this.template)
        .then(res => {
          this.successHandler('Створено шаблон')
          return res
        })
        .catch(this.$err)
    },
    updateTemplate() {
      return this.$axios
        .post(
          this.urlUpdateClientOfferTemplate(this.userId, this.template.id),
          this.template
        )
        .then(res => {
          this.successHandler('Шаблон оновлено')
          return res
        })
        .catch(this.$err)
    },
    getIframe() {
      const closeAutocomplete = () => this.$refs.fakeClick.click()
      const el = document.getElementById('tiny-send-offer_ifr').contentWindow
      el.addEventListener('click', closeAutocomplete)
    },
    removeTemplate() {
      return this.$axios
        .delete(
          this.urlDeleteClientOfferTemplate(this.userId, this.template.id)
        )
        .then(res => {
          this.successHandler('Шаблон видалено')
          return res
        })
        .catch(this.$err)
    },
    successHandler(successStr) {
      this.$setSnackbar({ text: successStr })
      this.$store.commit('refreshPage')
    },
    customizeEditor() {
      setTimeout(() => {
        setTimeout(() => {
          document.getElementById(this.editorId).style.display = 'none'
          this.editorInit = !this.editorInit
        }, 0)
      }, 0)
    },
    clearTemplate() {
      this.editorRequired && window.tinymce.remove()
      this.$forceUpdate()
      this.template.name = null
      this.template.sign = null
    },
    highlightErrors() {
      this.v$.$anyError
      this.v$.$touch()
      v$Notify({ ...this.v$.template }, 'template')
    },
    handleTemplate() {
      switch (true) {
        case this.addTemplate:
          return this.createTemplate()
          // eslint-disable-next-line no-unreachable
          break
        case this.editTemplate:
          return this.updateTemplate()
          // eslint-disable-next-line no-unreachable
          break
        case this.deleteTemplate:
          return this.removeTemplate()
          // eslint-disable-next-line no-unreachable
          break
      }
    },
    submit() {
      this.getHtml()
      if (this.v$.$invalid) {
        this.highlightErrors()
        return
      }
      if (!this.template.sign) {
        return this.$setSnackbar({ text: 'Повідомлення' })
      }
      return this.handleTemplate()
    },
  },
  watch: {
    editorInit(init) {
      if (init && (this.editTemplate || this.viewTemplate)) {
        this.setHtml(this.template.sign, this.editorId, this.initializeEditor)
      }
    },
  },
  created() {
    if (this.editTemplate || this.viewTemplate || this.deleteTemplate) {
      Object.assign(this.template, this.dialog.dialogItem.item)
    }
    this.editorInit = false
  },
  // mounted() {
  //   this.setHtml('', this.editorId, this.initializeEditor)
  // },
}
</script>

<style>
.tiny-offer-template {
  display: none !important;
}
</style>
