import Loader from '@/components/Loader.vue'
import { mapState } from 'vuex'
import CarIem from './CarIem.vue'
import { capitalize, isMatchWith } from 'lodash'
import {
  AGRICULTURAL,
  BUS,
  CAR,
  MOTO,
  SPECIAL,
  TRAILER,
  TRUCK,
} from '@/pages/calculations/object-types'
import {
  carSelectOrder,
  getCarItemsKey,
  getCarTopMarks,
  getUrl,
} from '@/pages/average-price/components/helpers'
import { inputNumber } from '@/utils/formatFunc'
import { getSafe, setErrHandler, sortItemsByText } from '@/utils/helperFunc'
import { getItemsKey } from '@/components/car-api/helpers'
import Item from '@/components/car-api/Item.vue'
import { urlGetSingleAverage } from '@/pages/request'
import { useSelect } from '@/utils/mixins/useSelect'

const isMatchCustomizer = (value1, value2) => {
  if (value1?.constructor.name !== value2?.constructor.name) {
    return false
  }
  if (typeof value1 === 'object') {
    return isMatchWith(value1, value2, isMatchCustomizer)
  }
  return value1 === value2
}

export default {
  name: 'CarGeneral',
  components: { Item, Loader, CarIem },
  setup() {
    return {
      ...useSelect(),
    }
  },
  emits: ['carDataChanged', 'initCarData'],
  props: {
    value: {
      type: Object,
    },
    v: {
      type: Object,
    },
  },
  data: () => {
    return {
      carData: {
        category: null,
        mark: null,
        model: null,
        fuel: null,
        gear: null,
        driverType: null,
        state: null,
        body: null,
        raceIntFrom: null,
        raceIntTo: null,
        isDamage: false,
        isCustom: false,
        isOnRepair: false,
        yearFrom: null,
        yearTo: null,
        engineVolume: null,
      },
      items: {
        categories: [],
        bodies: [],
        marks: [],
        models: [],
        fuels: [],
        driverTypes: [],
        states: [],
        gears: [],
        years: [],
      },
    }
  },
  computed: {
    ...mapState({
      selectItems: state => state.selectItems,
    }),
    car() {
      return this.carData.category?.value === CAR
    },
    moto() {
      return this.carData.category?.value === MOTO
    },
    truck() {
      return this.carData.category?.value === TRUCK
    },
    special() {
      return this.carData.category?.value === SPECIAL
    },
    agricultural() {
      return this.carData.category?.value === AGRICULTURAL
    },
    bus() {
      return this.carData.category?.value === BUS
    },
    categoryErr() {
      return setErrHandler(this.v?.category)
    },
    markErr() {
      return setErrHandler(this.v?.mark)
    },
    modelErr() {
      return setErrHandler(this.v?.model)
    },
    yearFromErr() {
      return setErrHandler(this.v?.yearFrom)
    },
    yearToErr() {
      return setErrHandler(this.v?.yearTo)
    },
  },
  methods: {
    getSafe,
    getCarTopMarks,
    inputNumber,
    breadScrumbName(obj) {
      return `${obj?.mark?.name + ' ' + obj?.model?.name + ' '} ${
        obj?.yearFrom
      } - ${obj?.yearTo || new Date().getFullYear()}`
    },
    clearFields(fields) {
      fields.forEach(f => {
        if (this.carData[f]) {
          this.carData[f] = null
        }
        if (f === 'year') {
          if (this.carData.yearTo) {
            this.carData.yearTo = null
          }
          if (this.carData.yearFrom) {
            this.carData.yearFrom = null
          }
        }
        if (getItemsKey(f) in this.items) {
          this.items[getItemsKey(f)] = []
        }
      })
    },
    carSelectHandler(fieldName) {
      const curOrder = carSelectOrder.find(n => n.name === fieldName).order

      this.clearFields(
        carSelectOrder.filter(n => n.order > curOrder).map(n => n.name)
      )

      const nextFieldNames = []
      for (const f of carSelectOrder) {
        if (f.order === curOrder + 1) {
          nextFieldNames.push(f.name)
        }
      }
      nextFieldNames.forEach(this.getCarData)
    },
    getCarData(fieldName) {
      const itemsKey = getCarItemsKey(fieldName)

      return this.asyncAction(`get${capitalize(itemsKey)}`, null, () => {
        const url = getUrl(fieldName, this.carData)

        if (!url) return
        return this.$axios.get(url).then(res => {
          if (fieldName === 'mark') {
            this.items[itemsKey] = sortItemsByText(
              res.data.map(item => {
                return {
                  name: item.name,
                  id: item.value,
                }
              }),
              this.getCarTopMarks(),
              'name',
              true
            )
            return
          }
          if (fieldName === 'body') {
            this.items[itemsKey] = sortItemsByText(res.data)
            return
          }

          this.items[itemsKey] = res.data || []
        })
      })
    },
    getSingleCard() {
      return this.$axios
        .get(urlGetSingleAverage(this.$route?.params?.id))
        .then(res => {
          this.carData = null
          this.carData = res?.data

          this.getCarData('category')
          this.getCarData('mark')
          this.carData.mark = {
            name: this.carData?.mark?.name,
            id: this.carData?.mark?.value,
          }
          this.getCarData('model')
          this.getCarData('body')
          this.getCarData('year')
          this.getCarData('gear')
          this.getCarData('driverType')

          this.$store.commit('setBreadScrumbPart', [
            null,
            this.breadScrumbName(res.data),
          ])

          this.$emit('initCarData', this.carData)
          return res
        })
    },
  },
  async created() {
    if (this.$route.name === 'average-price-edit-calculation') {
      await this.getSingleCard()
    } else {
      await this.getCarData('category')
    }
    this.$watch('carData', {
      handler(v) {
        if (v) {
          this.$emit('carDataChanged', this.carData)
        }
      },
      deep: true,
    })
  },
}
