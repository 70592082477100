<template>
  <v-card class="h-transparent" style="min-height: 400px" :elevation="0">
    <div class="pt-0 pb-0 pl-0 pr-0">
      <v-tabs
        v-model="currentTab"
        class="contact-tab"
        background-color="grey darken-2"
        show-arrows
        :direction="$vuetify.breakpoint?.xs ? 'vertical' : 'horizontal'"
        :class="{ 'v-tabs--mobile': $vuetify.breakpoint?.xs }"
        dark
        :height="$vuetify.breakpoint?.xs ? null : 38"
        slider-color="#FC7247"
        dense>
        <v-tab
          replace
          :to="{
            name: 'contractor-requisites',
            params: { id: $route.params.id },
          }">
          Реквiзити
        </v-tab>
        <v-tab
          replace
          :to="{
            name: 'contractor-organization-structure',
            params: { id: $route.params.id },
          }">
          Структура організації
        </v-tab>
        <v-tab
          replace
          :to="{ name: 'contractor-links', params: { id: $route.params.id } }"
          >Зв`язки
        </v-tab>
        <v-tab
          replace
          :to="{
            name: 'contractor-documents',
            params: { id: $route.params.id },
          }">
          Документи
        </v-tab>
        <v-tab
          v-show="contractorProvider || hasDealerOutlets"
          replace
          :to="{
            name: 'contractor-suplier',
            params: { id: $route.params.id },
          }">
          Торгова точка
        </v-tab>
        <v-tab
          replace
          :to="{
            name: 'contractor-projects',
            params: { id: $route.params.id },
          }">
          Проекти
        </v-tab>
        <v-tab
          replace
          :to="{
            name: 'contractor-agreements',
            params: { id: $route.params.id },
          }">
          Договори
        </v-tab>
        <v-tab
          replace
          :to="{
            name: 'contractor-activity',
            params: { id: $route.params.id },
          }">
          Активностi
        </v-tab>
        <v-tab
          replace
          :to="{
            name: 'contractor-changes',
            params: { id: $route.params.id },
          }">
          Iсторiя змiн
        </v-tab>
      </v-tabs>
    </div>
    <div class="h-transparent">
      <!--   todo v-model:contractor="contractor"  -->
      <router-view
        :contractor="contractor"
        :readonly="readonly"
        :contractorId="contractorId"
        :contractorList="contractorList"
        :openDataLoading="openDataLoading"
        :checkEmail="checkEmail"
        :checkPhone="checkPhone"
        :pageLoad="pageLoad"
        :v="v"
        @sync-with-open-data="$emit('syncWithOpenData')"
        @data-changed="$emit('dataChanged')"
        @table-change="$emit('tableChange')"
        @open-dialog="$emit('openDialog', $event)"
        @delete-item="$emit('deleteItem', $event)"
        @open-career-dialog="$emit('openCareerDialog', $event)"
        @update-contractor="$emit('updateContractor')">
      </router-view>
    </div>
  </v-card>
</template>
<script>
export default {
  emits: [
    'syncWithOpenData',
    'dataChanged',
    'tableChange',
    'openDialog',
    'deleteItem',
    'openCareerDialog',
    'updateContractor',
  ],
  props: {
    readonly: { type: Boolean },
    contractorList: { type: Array },
    openDataLoading: { type: Boolean },
    contractor: { type: Object },
    contractorId: { type: [Number, String] },
    pageLoad: { type: Boolean },
    computedEmails: { type: Array },
    computedPhones: { type: Array },
    temporaryPhones: { type: Array },
    temporaryEmails: { type: Array },
    checkEmail: { type: Function },
    checkPhone: { type: Function },
    v: { type: Object },
  },
  data: () => ({ currentTab: 0 }),
  computed: {
    contractorProvider() {
      return this.$array(() => this.contractor.contractorTypeId).includes(1)
    },
    hasDealerOutlets() {
      return this.contractor?.dealerPointOfSales?.length
    },
    contractorDiller() {
      return this.$array(() => this.contractor.contractorTypeId).includes(3)
    },
    contractorClient() {
      return this.$array(() => this.contractor.contractorTypeId).includes(2)
    },
    providerRoute() {
      return this.$route.name === 'contractor-suplier'
    },
  },
  watch: {
    contractorProvider(state) {
      !state && this.providerRoute && this.$router.go(-1)
    },
  },
}
</script>
<style scoped lang="scss">
.h-transparent {
  background: transparent;
}
a.v-tab-item--selected.v-tab {
  font-weight: 700 !important;
  color: #08487a !important;
  font-size: 13px;
}
a.v-tab {
  color: #838282 !important;
  font-size: 13px;
  letter-spacing: normal;
}
.container.pa-0.container--fluid {
  padding: 0 !important;
  margin: 0 !important;
}
.v-tabs {
  & a.v-tab {
    margin-right: 10px;
  }
  &--mobile {
    padding-right: 12px;
    a.v-tab {
      margin-right: 0;
      margin-bottom: 12px;
      min-width: 100%;
      border: 1px solid #eaeaea;
      background: #eaeaea;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
      border-radius: 4px;
    }
  }
}
</style>
