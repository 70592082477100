<template>
  <div class="pt-0 pb-0">
    <AnalyzeService
      :securityResult="securityResults"
      :legalResult="legalResults"
      :analyzeData="project"
      @download-printed-form="service => downloadPrintedForm(service)" />
  </div>
</template>
<script>
import {
  toFormatDate,
  openDocument,
  getContactInitials,
  getSafe,
} from '@/utils/helperFunc'
import { mapState } from 'vuex'
import {
  urlSecurityServicePrintedForm as securityPrintedForm,
  urlCreateLegalDepartmentPrintedForm as legalPrintedForm,
} from '@/pages/request'
import AnalyzeService from '@/components/AnalyzeService.vue'
export default {
  components: { AnalyzeService },
  computed: {
    ...mapState({
      project: state => state.projects.project,
    }),
    securityResults() {
      const results = []
      const s = this.project.securityResult
      if (!s) return results

      results.push({
        name: `${s.client.name} (Клієнт)`,
        finishDate: s.client.finishDate,
        responsible: s.responsible,
        result: s.client.result?.name || '---',
        status: s.status,
      })
      if (s.guarantors) {
        s.guarantors.forEach(g => {
          results.push({
            name: `${g.name} (Поручитель)`,
            finishDate: g.finishDate,
            responsible: s.responsible,
            result: g?.result?.name || '---',
            status: s.status,
          })
        })
      }
      if (s.subtenants) {
        s.subtenants.forEach(sub => {
          results.push({
            name: `${sub.name} (Суборендатор)`,
            finishDate: sub.finishDate,
            responsible: s.responsible,
            result: sub?.result?.name || '---',
            status: s.status,
          })
        })
      }
      if (s.providers) {
        s.providers.forEach(p => {
          results.push({
            name: `${p.name} (Постачальник)`,
            finishDate: p.finishDate,
            responsible: s.responsible,
            result: p?.result?.name || '---',
            status: s.status,
          })
        })
      }

      return results
    },
    legalResults() {
      const results = []
      const l = this.project.legalResult
      if (!l) return results

      results.push({
        name: `${l.client.name} (Клієнт)`,
        finishDate: l.client.finishDate,
        responsible: l.responsible,
        conclusion: l.client.clientConclusion,
        status: l.status,
      })

      if (l.guarantors) {
        l.guarantors.forEach(g => {
          results.push({
            name: `${g.name} (Поручитель)`,
            finishDate: g.finishDate,
            responsible: l.responsible,
            conclusion: g.guarantorConclusion,
            status: l.status,
          })
        })
      }
      if (l.subtenants) {
        l.subtenants.forEach(s => {
          results.push({
            name: `${s.name} (Суборендатор)`,
            finishDate: s.finishDate,
            responsible: l.responsible,
            conclusion: s.subtenantConclusion,
            status: l.status,
          })
        })
      }
      if (l.providers) {
        l.providers.forEach(p => {
          results.push({
            name: `${p.name} (Постачальник)`,
            finishDate: p.finishDate,
            responsible: l.responsible,
            conclusion: p.providerConclusion,
            status: l.status,
          })
        })
      }

      return results
    },
  },
  methods: {
    getSafe,
    getContactInitials,
    toFormatDate,
    securityPrintedForm, // used in urlPrintedForm
    legalPrintedForm, // used in urlPrintedForm
    async downloadPrintedForm(service) {
      const serviceId =
        service === 'security'
          ? this.project.securityResult.id
          : this.project.legalResult.id
      const urlPrintedForm = this[`${service}PrintedForm`]
      const { data } = await this.$axios.get(urlPrintedForm(serviceId))
      const url = data.url
      if (!url) return

      const fileName = url.split('/').at(-1)
      openDocument({ url, text: fileName })
    },
  },
}
</script>

<style lang="scss" scoped>
.card-block-title {
  display: block;
  color: #08487a;
  font-weight: 600;
  font-size: 16px;
  margin-left: 9px;
}
.v-data-table tbody tr {
  background: #fff !important;
}
</style>
