<template>
  <div>
    <!--    <div v-if="isNew" class="glass-wrapper">-->
    <!--      <div class="text">-->
    <!--        <span class="d-block font-weight-bold">🔐 Доступ обмежено</span>-->
    <!--        <span class="d-block">Для повного перегляду візьміть службу в роботу!</span>-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="white-block">
      <v-row>
        <v-col :cols="12" :sm="3" class="pt-1 pb-1">
          <div class="card-label">
            {{ contact.fop ? 'Фізична особа-підприємець:' : 'Фізична особа:' }}
          </div>
        </v-col>
        <v-col :cols="12" :sm="9" class="pt-1 pb-1 text-start">
          <span class="card-value">
            <ALink
              :route="{ name: 'individuals-form', params: { id: contact.id } }"
              :text="contact.fullName" />
          </span>
        </v-col>
      </v-row>

      <v-row>
        <v-col :cols="12" :sm="3" class="pt-1 pb-1"> </v-col>
        <v-col :cols="12" :sm="9" class="pt-1 pb-1 text-start">
          <div class="d-flex">
            <div class="card-sublable mr-1">Резиденство:</div>
            <span class="card-value">{{
              contact?.resident === true ? 'є резидентом' : 'не є резидентом'
            }}</span>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col :cols="12" :sm="3" class="pt-1 pb-1">
          <div class="card-label">IПН</div>
        </v-col>
        <v-col :cols="12" :sm="9" class="pt-1 pb-1 text-start">
          <span class="card-sublable">{{ contact.inn || '---' }}</span>
        </v-col>
      </v-row>

      <v-row>
        <v-col :cols="12" :sm="3" class="pt-1 pb-1">
          <div class="card-label">Сегмент</div>
        </v-col>
        <v-col :cols="12" :sm="9" class="pt-1 pb-1 text-start">
          <span class="card-value">{{
            contact?.segment?.name || 'Відсутній'
          }}</span>
        </v-col>
      </v-row>

      <v-row>
        <v-col :cols="12" :sm="3" class="pt-1 pb-1"> </v-col>
        <v-col :cols="12" :sm="9" class="pt-1 pb-1 text-start">
          <span class="card-sublable">Дата народження:</span>
          <span class="card-value mr-1">{{
            toFormatDate(contact.birthday) || '---'
          }}</span>
          <span class="card-sublable">Громадянство:</span>
          <span class="card-value mr-1">{{
            contact.nationality?.name || '---'
          }}</span>
          <span class="card-sublable">Мiсце народження:</span>
          <span class="card-value">{{ contact.placeOfBirth || '---' }}</span>
        </v-col>
      </v-row>

      <v-row>
        <v-col :cols="12" :sm="3" class="pt-1 pb-1"> </v-col>
        <v-col :cols="12" :sm="9" class="pt-1 pb-1 text-start">
          <div class="d-flex">
            <div class="card-sublable mr-1">
              {{ getPassport(contact, { label: true }) }}:
            </div>
            <span class="card-value"> {{ getPassport(contact) }}:</span>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col :cols="12" :sm="3" class="pt-1 pb-1">
          <div class="card-label">Фінансові дані</div>
        </v-col>
        <v-col :cols="12" :sm="9" class="pt-1 pb-1 text-start">
          <div class="d-flex flex-column gap-1">
            <div class="d-flex align-center">
              <span class="card-sublable mr-1"
                >Середньомісячний підтверджений сукупний чистий дохід:
              </span>
              <span class="card-value">{{
                getBeautyNum(contact?.provedIncome || 0) || 'Відсутній'
              }}</span>
            </div>
            <div class="d-flex align-center">
              <span class="card-sublable mr-1"
                >Середньомісячний непідтверджений сукупний чистий дохід:
              </span>
              <span class="card-value">{{
                getBeautyNum(contact?.unprovedIncome || 0) || 'Відсутній'
              }}</span>
            </div>
            <div class="d-flex align-center">
              <span class="card-sublable mr-1"
                >Кількість непрацездатних членів сімї та утриманців:
              </span>
              <span class="card-value">{{
                getBeautyNum(contact?.dependentsNumber || 0) || 'Відсутній'
              }}</span>
            </div>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col :cols="12" :md="3" class="pt-0">
          <div class="card-label">Контактні дані</div>
        </v-col>
        <v-col :cols="12" :md="9" class="pt-1">
          <div class="d-flex align-center">
            <div class="card-sublable mr-1 align-center">
              <AddressIcon class="mr-1" />
              Адрес реєстрації:
            </div>
            <span class="card-value">{{
              getAddress(contact.addresses?.find(a => a.typeId === 4)) || '---'
            }}</span>
          </div>
          <div class="d-flex align-center">
            <div class="card-sublable mr-1 align-center card-sublable">
              <AddressIcon class="mr-1" />
              Фактична адреса:
            </div>
            <span class="card-value">{{
              getAddress(contact.addresses?.find(a => a.typeId === 2)) || '---'
            }}</span>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col :cols="12" :md="3" class="pt-0 pb-0"></v-col>
        <v-col :cols="12" :md="9" class="pt-0 pb-0">
          <div class="d-flex align-center">
            <div class="card-sublable mr-1 align-center card-sublable">
              <PhoneIcon :width="16" class="mr-1" />
              Телефон:
            </div>
            <span class="card-sublable">
              {{ contactDetailsWithType(contact.phones, 'phone') }}
            </span>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col :cols="12" :md="3" class="pt-0 pb-0 pl-0"></v-col>
        <v-col :cols="12" :md="9" class="pt-0 pb-0">
          <div class="d-flex align-center">
            <div class="card-sublable mr-1 align-center card-sublable">
              <EmailIcon :width="16" class="mr-1" />
              Email:
            </div>
            <span class="card-sublable">
              {{ contactDetailsWithType(contact.emails, 'email') }}
            </span>
          </div>
        </v-col>
      </v-row>

      <v-row class="mt-2">
        <v-col :cols="12" :sm="3" class="pt-1 pb-1"> </v-col>
        <v-col :cols="12" :sm="9" class="pt-1 pb-1 text-start">
          <slot name="main-info"></slot>
        </v-col>
      </v-row>

      <slot name="before-docs"></slot>
      <v-row>
        <v-col :cols="12" :sm="3" class="pt-1 pb-1 pl-2"></v-col>
        <v-col :cols="12" :sm="9" class="pt-1 pb-1 pl-2">
          <div>
            <component
              :is="legalDocsOpenable ? 'OpenableList' : 'v-card-text'"
              v-if="hasDoc('legal')"
              title="юридичні документи">
              <FilesUploader
                v-for="doc in legalDocuments"
                :key="doc.label"
                :label="doc.label"
                :documents="doc.docs"
                :list="false"
                readonly />
              <template #append-title>
                <AllDocsBtn :id="contact.id" class="ml-2" contact legal />
              </template>
            </component>
            <component
              :is="finDocsOpenable ? 'OpenableList' : 'v-card-text'"
              v-if="hasDoc('fin')"
              title="фінансові документи">
              <FilesUploader
                v-for="doc in finDocuments"
                :key="doc.label"
                :info="doc.info"
                :label="doc.label"
                :documents="doc.docs"
                :list="false"
                readonly />
              <template #append-title>
                <AllDocsBtn :id="contact.id" class="ml-2" contact fin />
              </template>
            </component>
          </div>
        </v-col>
      </v-row>
      <div
        v-if="Object.keys(married).length"
        style="margin-top: 10px; background: white">
        <div>
          <v-row>
            <v-col :cols="12" :sm="3" class="pt-1 pb-1">
              <div class="card-label" style="color: #000 !important">
                Чоловік/Дружина
              </div>
            </v-col>
            <v-col :cols="12" :sm="9" class="pt-1 pb-1 text-start">
              <div class="d-flex">
                <ALink
                  :route="{
                    name: 'individuals-form',
                    params: { id: married.id },
                  }"
                  :text="married.fullName" />
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col :cols="12" :sm="3" class="pt-1 pb-1"> </v-col>
            <v-col :cols="12" :sm="9" class="pt-1 pb-1 text-start">
              <div class="d-flex">
                <div class="card-sublable mr-1">Резиденство:</div>
                <span class="card-value">{{
                  married?.resident === true
                    ? 'є резидентом'
                    : 'не є резидентом'
                }}</span>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col :cols="12" :sm="3" class="pt-1 pb-1"> </v-col>
            <v-col :cols="12" :sm="9" class="pt-1 pb-1 text-start">
              <div class="d-flex">
                <div class="card-sublable mr-1">Дата народження:</div>
                <span class="card-value mr-1">{{
                  toFormatDate(married.birthday) || '---'
                }}</span>
                <div class="card-sublable mr-1">Громадянство:</div>
                <span class="card-value">{{
                  married.nationality?.name || '---'
                }}</span>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col :cols="12" :sm="3" class="pt-1 pb-1"> </v-col>
            <v-col :cols="12" :sm="9" class="pt-1 pb-1 text-start">
              <div class="d-flex">
                <div class="card-sublable mr-1">Мiсце народження:</div>
                <span class="card-value">{{
                  married.placeOfBirth || '---'
                }}</span>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col :cols="12" :sm="3" class="pt-1 pb-1"> </v-col>
            <v-col :cols="12" :sm="9" class="pt-1 pb-1 text-start">
              <div class="d-flex">
                <div class="card-sublable mr-1">IПН:</div>
                <span class="card-value">{{ married.inn || '---' }}</span>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col :cols="12" :sm="3" class="pt-1 pb-1"> </v-col>
            <v-col :cols="12" :sm="9" class="pt-1 pb-1 text-start">
              <div class="d-flex">
                <div class="card-sublable mr-1">
                  {{ getPassport(married, { label: true }) }}
                </div>
                <span class="card-value">
                  {{ getPassport(married) }}
                </span>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col :cols="12" :sm="3" class="pt-1 pb-1"> </v-col>
            <v-col :cols="12" :md="9" class="pt-1">
              <div class="d-flex align-center">
                <div class="card-sublable mr-1 align-center card-sublable">
                  <AddressIcon class="mr-1" />
                  Адрес реєстрації:
                </div>
                <span class="card-value">{{
                  getAddress(married?.addresses?.find(a => a.typeId === 4)) ||
                  '---'
                }}</span>
              </div>
              <div class="d-flex align-center">
                <div class="card-sublable mr-1 align-center card-sublable">
                  <AddressIcon class="mr-1" />
                  Фактична адреса:
                </div>
                <span class="card-value">{{
                  getAddress(married?.addresses?.find(a => a.typeId === 2)) ||
                  '---'
                }}</span>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col :cols="12" :sm="3" class="pt-1 pb-1"> </v-col>
            <v-col :cols="12" :sm="9" class="pt-1 pb-1 text-start">
              <div class="d-flex align-center">
                <div class="card-sublable mr-1 align-center card-sublable">
                  <PhoneIcon :width="16" class="mr-1" />
                  Телефон:
                </div>
                <span class="card-sublable">
                  {{ contactDetailsWithType(married.phones, 'phone') }}
                </span>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col :cols="12" :sm="3" class="pt-1 pb-1"> </v-col>
            <v-col :cols="12" :sm="9" class="pt-1 pb-1 text-start">
              <div class="d-flex align-center">
                <div class="card-sublable mr-1 align-center card-sublable">
                  <EmailIcon :width="16" class="mr-1" />
                  Email:
                </div>
                <span class="card-sublable">
                  {{ contactDetailsWithType(married.emails, 'email') }}
                </span>
              </div>
            </v-col>
          </v-row>
          <div v-if="marriedDocs" class="mt-2">
            <v-row>
              <v-col :cols="12" :sm="3" class="pt-1 pb-1"> </v-col>
              <v-col :cols="12" :sm="9" class="pt-1 pb-1 text-start">
                <OpenableList
                  title="документи"
                  class="pb-1"
                  style="margin-left: -5px">
                  <FilesUploader
                    readonly
                    label="Паспорт"
                    :documents="married.identityDocument?.document" />
                  <FilesUploader
                    readonly
                    label="Ідентифiкацiйний номер"
                    :documents="married.identificationNumber" />
                  <FilesUploader
                    readonly
                    label="Довідка про доходи"
                    :documents="married.incomeStatement" />
                  <FilesUploader
                    readonly
                    label="Документи, які підтверджують право власності на майно"
                    :documents="married.propertyDocuments" />
                  <FilesUploader
                    readonly
                    label="Довідка з банку/лізингової компанії/ін.кредитора про стан поточної заборгованості"
                    :documents="married.debtCertificate" />
                  <FilesUploader
                    v-if="married.fop"
                    readonly
                    label="Податкова декларація платника єдиного податку"
                    info="Звіт з ДПС за попередній (підсумок) та за поточний (поквартально)"
                    :documents="married.declarationForInvoice" />
                  <FilesUploader
                    v-if="married.fop"
                    readonly
                    label="Копії договорів"
                    info="Копії договорів, за якими передбачається отримання доходу в 2023 р"
                    :documents="married.copiesOfContracts" />
                  <FilesUploader
                    readonly
                    label="Інші фінансові документи"
                    :documents="married.otherFinancialDocuments" />
                </OpenableList>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
      <slot name="after">
        <!-- Additional content -->
      </slot>
    </div>
  </div>
</template>

<script>
import AddressIcon from '@/assets/svg/Address-icon.vue'
import PhoneIcon from '@/assets/svg/phone-icon.vue'
import EmailIcon from '@/assets/svg//social/email-icon.vue'
import FilesUploader from './FilesUploader.vue'
import OpenableList from './OpenableList.vue'
import ALink from './Link.vue'
import AllDocsBtn from './AllDocsBtn.vue'
import {
  getAddress,
  getPassport,
  contactDetailsWithType,
  toFormatDate,
  getSafe,
  getBeautyNum,
} from '@/utils/helperFunc'

export default {
  components: {
    AddressIcon,
    FilesUploader,
    OpenableList,
    ALink,
    AllDocsBtn,
    PhoneIcon,
    EmailIcon,
  },
  props: {
    contact: { type: Object },
    finDocs: { type: [Array, Boolean], default: false },
    finDocsOpenable: { type: Boolean, default: true },
    legalDocs: { type: [Array, Boolean], default: false },
    legalDocsOpenable: { type: Boolean, default: true },
    marriedDocs: { type: Boolean, default: false },
    isNew: { type: Boolean, default: false },
  },
  computed: {
    married() {
      return this.contact?.marriedTo || {}
    },
    finDocuments() {
      const docs = {
        incomeStatement: {
          label: 'Довідка про доходи',
          docs: this.contact.incomeStatement,
        },
        propertyDocuments: {
          label: 'Документи, які підтверджують право власності на майно',
          docs: this.contact.propertyDocuments,
        },
        debtCertificate: {
          label:
            'Довідка з банку/лізингової компанії/ін.кредитора про стан поточної заборгованості',
          docs: this.contact.debtCertificate,
        },
        declarationForInvoice: {
          label: 'Податкова декларація платника єдиного податку',
          info: 'Звіт з ДПС за попередній (підсумок) та за поточний (поквартально)',
          docs: this.contact.declarationForInvoice,
        },
        copiesOfContracts: {
          label: 'Копії договорів',
          info: 'Копії договорів, за якими передбачається отримання доходу в 2023 р',
          docs: this.contact.copiesOfContracts,
        },
        otherFinancialDocuments: {
          label: 'Інші фінансові документи',
          docs: this.contact.otherFinancialDocuments,
        },
      }
      Array.isArray(this.finDocs) && this.filterDocs(docs, this.finDocs)
      return docs
    },
    legalDocuments() {
      const extractOrExcerpt = this.contact.fop
        ? {
            extractOrExcerpt: {
              label: 'Виписка або витяг з ЄДРЮОб ФОП та ГФ',
              docs: this.contact?.fop?.extractOrExcerpt,
            },
          }
        : {}
      const docs = {
        ...extractOrExcerpt,
        documentPassport: {
          label:
            'Паспорт (для нерезидента – посвідчення на тимчасове або постійне проживання в Україні, дозвіл на працевлаштування)',
          docs: this.contact.identityDocument?.document,
        },
        identificationNumber: {
          label:
            'Довідка про присвоєння ідентифікаційного номера фізичної особи-платника податків',
          docs: this.contact.identificationNumber,
        },
        documentsPowers: {
          label: 'Довіреність та інші док-ти про повноваження',
          docs: this.contact.documentsPowers,
        },
        marriageCertificate: {
          label: 'Свідоцтво про укладення/розірвання шлюбу',
          docs: this.contact.marriageCertificate,
        },
        otherLegalDocuments: {
          label: 'Інші юридичні документи',
          docs: this.contact.otherLegalDocuments,
        },
      }
      Array.isArray(this.legalDocs) && this.filterDocs(docs, this.legalDocs)
      return docs
    },
  },
  methods: {
    getBeautyNum,
    getSafe,
    toFormatDate,
    getAddress,
    getPassport,
    contactDetailsWithType,
    filterDocs(docs, includes) {
      Object.keys(docs).forEach(
        doc => !includes.includes(doc) && delete docs[doc]
      )
    },
    hasDoc(entity) {
      return Array.isArray(this[`${entity}Docs`])
        ? !!Object.keys(this[`${entity}Documents`]).length
        : this[`${entity}Docs`]
    },
  },
}
</script>

<style scoped>
.card-label {
  color: #000 !important;
  font-size: 13px !important;
}
.card-sublable {
  color: #838282;
  font-weight: 500;
  font-size: 13px;
}
</style>
