<template>
  <div class="text-center" style="min-height: 220px">
    <v-card-text class="pt-0">
      <div class="d-flex justify-space-between pt-1 pb-1">
        <span style="align-self: center">Голова КЛП</span>
        <span>
          <TableCrud
            data-readonly="hide"
            :actionsArray="[
              {
                action: () =>
                  action('addKlpHead', 'Голова КЛП', 'Затверджено голову КЛП'),
                text: 'Обрати голову КЛП',
                icon: '',
                disabled: readonly,
              },
            ]"
            icon="mdi-plus-circle-outline">
          </TableCrud>
        </span>
      </div>
      <v-data-table
        v-if="project.invitations"
        :headers="klpTHead"
        :items="headItems"
        class="calc-table"
        dense
        hide-default-footer
        hide-default-header
        :items-per-page="100">
        <!-- eslint-disable-next-line -->
        <template #item.invited="{ item }">
          <div class="d-flex align-center">
            <span style="width: 150px">{{
              getContactInitials(item.invited.fullName, { byName: true })
            }}</span>
            <span class="ml-3 ml-1">|</span>
            <span class="ml-5 d-block">{{
              '👨‍💻 ' + item.invited?.post || '---'
            }}</span>
          </div>
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.actions="{ item }">
          <TableCrud
            data-readonly="hide"
            style="margin-right: -10px"
            :actionsArray="[
              {
                action: () => invationSolution(item),
                text: 'Проставити рiшення',
                icon: 'mdi-check',
                disabled: item.solutionId,
              },
              {
                action: () => deleteInvation(item),
                text: 'Видалити',
                icon: 'DeleteIcon',
                customIcon: true,
              },
            ]">
          </TableCrud>
        </template>
      </v-data-table>
    </v-card-text>
    <v-divider class="ma-2"></v-divider>
    <v-card-text>
      <div class="d-flex justify-space-between pt-1 pb-1">
        <span style="align-self: center">Члени КЛП:</span>
        <span>
          <TableCrud
            data-readonly="hide"
            :actionsArray="[
              {
                action: () =>
                  action(
                    'chooseKlpParticipants',
                    'Члени КЛП',
                    'Внесено змiни в склад членiв КЛП'
                  ),
                text: 'Внести змiни в склад членiв КЛП',
                icon: 'mdi-account',
                disabled: readonly,
              },
            ]"
            icon="mdi-plus-circle-outline">
          </TableCrud>
        </span>
      </div>
      <v-data-table
        v-if="project.invitations"
        :headers="klpParticipantsTHead"
        :items="membersItems"
        class="calc-table"
        dense
        hide-default-footer
        hide-default-header
        :items-per-page="100">
        <!-- eslint-disable-next-line -->
        <template #item.invited="{ item }">
          <div class="d-flex align-center">
            <span style="width: 150px">{{
              getContactInitials(item.invited.fullName, { byName: true })
            }}</span>
            <span class="ml-3 ml-1">|</span>

            <span class="ml-5 d-block">{{
              '👨‍💻 ' + item.invited?.post || '---'
            }}</span>
          </div>
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.actions="{ item }">
          <TableCrud
            data-readonly="hide"
            style="margin-right: -10px"
            :actionsArray="[
              {
                action: () => invationSolution(item),
                text: 'Проставити рiшення',
                icon: 'mdi-check',
                disabled: item.solutionId,
              },
              {
                action: () => deleteInvation(item),
                text: 'Видалити',
                icon: 'DeleteIcon',
                customIcon: true,
              },
            ]">
          </TableCrud>
        </template>
      </v-data-table>
    </v-card-text>
    <v-divider class="ma-4"></v-divider>
    <v-card-text>
      <div class="d-flex justify-space-between pt-1 pb-1">
        <span style="align-self: center">Запрошенi до КЛП:</span>
        <span>
          <TableCrud
            data-readonly="hide"
            :actionsArray="[
              {
                action: () =>
                  action(
                    'chooseKlpInvited',
                    'Запрошенi на участь в КЛП',
                    'Внесено змiни в склад запрошеных до КЛП'
                  ),
                text: 'Внести змiни в склад запрошеных до КЛП',
                icon: '',
                disabled: readonly,
              },
            ]"
            icon="mdi-plus-circle-outline">
          </TableCrud>
        </span>
      </div>
      <v-data-table
        v-if="project.invitations"
        :headers="klpInvitedTHead"
        :items="invationsItems"
        class="calc-table"
        dense
        hide-default-footer
        hide-default-header
        :items-per-page="100">
        <!-- eslint-disable-next-line -->
        <template #item.actions="{ item }">
          <TableCrud
            data-readonly="hide"
            style="margin-right: -10px"
            :actionsArray="[
              {
                action: () => invationSolution(item),
                text: 'Проставити рiшення',
                icon: 'mdi-check',
                disabled: item.solutionId,
              },
              {
                action: () => deleteInvation(item),
                text: 'Видалити',
                icon: 'DeleteIcon',
                customIcon: true,
              },
            ]">
          </TableCrud>
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.invited="{ item }">
          <div class="d-flex align-center">
            <span style="width: 150px">{{
              getContactInitials(item.invited.fullName, { byName: true })
            }}</span>
            <span class="ml-3 ml-1">|</span>

            <span class="ml-5 d-block">{{
              '👨‍💻 ' + item.invited?.post || '---'
            }}</span>
          </div>
        </template>
      </v-data-table>
    </v-card-text>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { getContactInitials } from '@/utils/helperFunc'
import { urlKlpInvationsDelete } from '@/pages/request'
import TableCrud from '@/components/table-crud.vue'
export default {
  emits: ['dataChanged'],
  components: { TableCrud },
  props: {
    project: { type: Object },
    submit: { type: Function },
    readonly: { type: Boolean, default: false },
  },
  data: () => ({
    head: [],
    klpTHead: [
      { title: 'ПIБ', key: 'invited', align: 'start', sortable: false },
      { title: 'Посада', key: 'post', align: 'center', sortable: false },
      { title: 'Дії', key: 'actions', align: 'end', sortable: false },
    ],
    klpParticipantsTHead: [
      { title: 'ПIБ', key: 'invited', align: 'start', sortable: false },
      { title: 'Посада', key: 'post', align: 'center', sortable: false },
      { title: 'Дії', key: 'actions', align: 'end', sortable: false },
    ],
    klpInvitedTHead: [
      { title: 'ПIБ', key: 'invited', align: 'start', sortable: false },
      { title: 'Посада', key: 'post', align: 'center', sortable: false },
      { title: 'Дії', key: 'actions', align: 'end', sortable: false },
    ],
  }),
  computed: {
    ...mapState({
      selectItems: state => state.selectItems,
    }),
    projectHead() {
      const head = this.selectItems.allUsers.find(
        u => u.id === this.project.headId
      )
      return head ? [head] : []
    },
    invationsItems() {
      return this.project.invitations
        ? this.project.invitations.filter(e => e.type.id === 3)
        : []
    },
    membersItems() {
      return this.project.invitations
        ? this.project.invitations.filter(e => e.type.id === 2)
        : []
    },
    headItems() {
      return this.project.invitations
        ? this.project.invitations.filter(e => e.type.id === 1)
        : []
    },
    klpEnttities() {
      return {
        ...this.project,
        members: this.project?.members?.map(m => m.id) || [],
        invitations: this.project?.invitations?.map(i => i.id) || [],
        headId: this.project?.head?.id || null,
      }
    },
  },
  methods: {
    getContactInitials,
    urlKlpInvationsDelete,
    action(action, title, completeMsg) {
      this.$store.commit('setDialog', {
        title: title,
        dialogItem: this.klpEnttities,
        params: {
          project: this.project,
          submit: this.submit,
          cb: () => {
            this.$setSnackbar({ text: completeMsg })
            this.$emit('dataChanged')
          },
        },
        action: action,
      })
    },
    invationSolution(item) {
      this.$store.commit('setDialog', {
        title: 'Проставлення рiшення',
        action: 'klp-solution',
        params: {
          item: item,
        },
      })
    },
    deleteInvation(item) {
      return (
        this.$axios.delete(urlKlpInvationsDelete(item.id)),
        this.$setSnackbar({ text: 'Видалено' }),
        this.$store.commit('refreshPage')
      )
    },
  },
  created() {
    this.$store.dispatch('addAllUsers')
  },
}
</script>
