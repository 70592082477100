<template>
  <div class="pb-2">
    <div v-if="approveCalculation">
      <span style="font-size: 1rem"> Оберіть графік: </span>
      <v-radio-group
        v-model="graph"
        :error-messages="graphErr"
        class="mt-2"
        dense>
        <v-radio
          v-for="(item, key) in chartTypes"
          :key="key"
          :label="item.name"
          :value="item.value"
          color="#80c23f">
        </v-radio>
      </v-radio-group>
      <v-checkbox
        v-model="leaseReverse"
        label="Зворотній лізинг"
        color="red"
        class="mb-3 mt-4"
        dense
        hide-details
        @change="
          () => {
            providerId = null
            providerTypeId = null
            provider = null
            dealerPointId = null
            v$.$reset()
          }
        ">
      </v-checkbox>
      <v-row>
        <v-col v-if="!leaseReverse" cols="12" md="12" sm="12">
          <span class="label">Дилер</span>
          <v-autocomplete
            v-model="dealerPointId"
            :items="directoryItems('dealerOutlets')"
            :error-messages="dealerPointIdErr"
            item-value="id"
            item-title="name"
            placeholder="Оберіть зі списку"
            hide-details
            :loading="asyncLoading('addDealerOutlets')"
            dense
            @update:model-value="handleUpdateModelValue"
            @blur="v$.dealerPointId.$touch()">
          </v-autocomplete>
        </v-col>
        <v-col v-if="!leaseReverse" cols="12" md="12" sm="12" class="pt-0">
          <span class="label">Постачальник</span>
          <v-select
            v-model="provider"
            :items="dealerOutletProviders"
            :error-messages="providerIdErr"
            item-value="uuid"
            item-title="name"
            placeholder="Оберіть зі списку"
            dense
            hide-details
            return-object
            :loading="dealerOutletProvidersLoading"
            @update:model-value="handleUpdateProvider"
            @blur="v$.providerId.$touch()">
            <template #item="{ item, props }">
              <EntityScope
                v-bind="props"
                :type="item.raw.providerTypeId"
                :name="item.raw.name" />
            </template>
            <template #selection="{ item, props }">
              <EntityScope
                v-bind="props"
                :type="item.raw.providerTypeId"
                :name="item.raw.name" />
            </template>
          </v-select>
        </v-col>
        <v-col
          v-if="standardLeasingObjectType"
          cols="12"
          md="12"
          sm="12"
          class="pt-0">
          <span class="label">Специфікація</span>
          <v-select
            v-model="specification"
            placeholder="Оберіть зі списку"
            :items="directoryItems(specifications, specification)"
            item-title="name"
            item-value="id"
            hide-details
            return-object
            :loading="asyncLoading('getSpecifications')"
            :error-messages="specificationErr"
            @blur="v$.specification.$touch()"></v-select>
        </v-col>
        <v-col cols="12" md="12" sm="12" class="mt-0">
          <ActionButtons
            v-if="canApprove"
            confirm-btn-text="ПОГОДИТИ"
            cancel-btn-text="СКАСУВАТИ"
            :confirm="submit"
            :confirm-loading="loading"
            :cancel-disable="loading"
            :cancel="() => $store.commit('closeDialog')" />
          <v-alert v-else type="warning" elevation="2" dense>
            Для погодження потрібно вказати модифікацію
          </v-alert>
        </v-col>
      </v-row>
    </div>
    <div v-if="rejectCalculation">
      <h2 style="font-weight: normal; font-size: 1.2rem">
        Погодження калькуляції буде відмінено!
      </h2>
    </div>
  </div>
</template>
<script>
import EntityScope from '@/components/EntityScopeView.vue'
import {
  urlConfirmCalculation,
  urlUnsignCalculation,
  urlGetDealerOutletProviders,
  urlGetCarData,
} from '@/pages/request'
import { required } from '@vuelidate/validators'
import { setErrHandler } from '@/utils/helperFunc'
import { mapState } from 'vuex'
import ActionButtons from '@/components/action-buttons.vue'
import { useVuelidate } from '@vuelidate/core'
import { useSelect } from '@/utils/mixins/useSelect'
export default {
  name: 'ApproveCalculation',
  components: { ActionButtons, EntityScope },
  setup() {
    return { v$: useVuelidate(), ...useSelect() }
  },
  props: { dialog: { type: Object } },
  validations() {
    const graph = this.approveCalculation ? { required } : true
    const providerId =
      this.approveCalculation && !this.leaseReverse ? { required } : {}
    const providerTypeId =
      this.approveCalculation && !this.leaseReverse ? { required } : {}
    const dealerPointId =
      this.approveCalculation && !this.leaseReverse ? { required } : {}

    const specification =
      this.approveCalculation && this.standardLeasingObjectType
        ? { required }
        : {}

    return {
      graph: graph,
      providerId: providerId,
      providerTypeId: providerTypeId,
      dealerPointId: dealerPointId,
      specification: specification,
    }
  },
  data: () => ({
    graph: null,
    providerId: null,
    provider: null,
    providerTypeId: null,
    dealerPointId: null,
    LOModifications: [],
    dealerOutletProviders: [],
    dealerOutletProvidersLoading: false,
    leaseReverse: false,
    loading: false,
    specification: null,
    specifications: [],
  }),
  computed: {
    ...mapState({ selectItems: state => state.selectItems }),
    validation() {
      return { v$: this.v$, name: 'setApproveCalculation' }
    },
    c() {
      /* calculation */
      return this.dialog?.dialogItem?.requestData
    },
    standardLeasingObjectType() {
      return [1, 2, 6, 7].includes(this.c.leasingObjectType?.id)
    },
    canApprove() {
      return !this.standardLeasingObjectType || !!this.c.modification
    },
    chartTypes() {
      if (!this.dialog.dialogItem) return

      const charts = Object.clone(
        this.dialog.dialogItem.chartTypes.filter(c => c !== 'import')
      )

      return this.selectItems.chartTypes.filter(v => {
        return charts.includes(v.value)
      })
    },
    /* Validations */
    graphErr() {
      return setErrHandler(this.v$.graph)
    },
    providerIdErr() {
      return setErrHandler(this.v$.providerId)
    },
    dealerPointIdErr() {
      return setErrHandler(this.v$.dealerPointId)
    },
    specificationErr() {
      return setErrHandler(this.v$.specification)
    },
    /* Current state */
    rejectCalculation() {
      return this.dialog.action === 'rejectCalculation'
    },
    approveCalculation() {
      return this.dialog.action === 'approveCalculation'
    },
  },
  methods: {
    urlConfirmCalculation,
    urlUnsignCalculation,
    highlightErrors() {
      this.v$.$anyError
      this.v$.$touch()
    },
    handleUpdateModelValue(newValue) {
      this.provider = null
      this.providerId = null
      this.dealerOutletProviders.splice(0)
      this.getDealerProviders(newValue)
    },
    handleUpdateProvider(newProvider) {
      this.providerId = newProvider.providerId
      this.providerTypeId = newProvider.providerTypeId
      this.provider = newProvider
    },
    async submit() {
      this.v$.$touch()
      if (!this.v$.$invalid) {
        const res = {}
        if (this.rejectCalculation) {
          const unsignRes = await this.unsign()
          Object.assign(res, unsignRes)
        }
        if (this.approveCalculation) {
          const confirmRes = await this.confirmCalculation()
          Object.assign(res, confirmRes)
        }

        if (typeof this.dialog.params?.cb === 'function') {
          await this.dialog.params.cb(res)
        }

        this.$store.commit('closeDialog')
      }
    },
    unsign() {
      return this.$axios
        .get(this.urlUnsignCalculation(this.dialog.dialogItem.id))
        .then(res => {
          if (res.data.message) {
            this.$setSnackbar({ text: res.data.message, color: 'warning' })
          } else {
            this.$setSnackbar({ text: 'Погодження вiдмiнено' })
          }
        })
        .catch(this.$err)
    },
    confirmCalculation() {
      this.loading = true
      const object = {}
      object.graph = this.graph
      object.providerId = this.providerId
      object.providerTypeId = this.providerTypeId
      object.dealerPointId = this.dealerPointId
      object.leaseReverse = this.leaseReverse
      object.specificationId = this.specification?.id || null
      object.specification = this.specification

      return this.$axios
        .post(this.urlConfirmCalculation(this.dialog.dialogItem.id), object)
        .then(() => this.$setSnackbar({ text: 'Калькуляцiю погоджено' }))
        .finally(() => (this.loading = false))
        .catch(this.$err)
    },
    getDealerProviders(providerId) {
      if (!providerId) return

      this.dealerOutletProvidersLoading = true

      return this.$axios
        .get(urlGetDealerOutletProviders(providerId))
        .then(res => {
          const legalProvider = (res.data || []).filter(
            p => p.providerTypeId === 2
          )

          if (legalProvider?.length) {
            const provider = legalProvider?.[0]
            this.provider = provider
            this.provider.id = provider?.providerId
            this.providerId = provider?.providerId
            this.providerTypeId = provider.providerTypeId
          }

          this.dealerOutletProviders.splice(0)
          this.dealerOutletProviders.push(...res.data)
          this.dealerOutletProvidersLoading = false

          return res
        })
        .catch(err =>
          this.$err(err, () => (this.dealerOutletProvidersLoading = false))
        )
    },
    assignData() {
      this.specification = this.c.specification

      const query = {
        leasingObjectType: this.c.leasingObjectType.id,
        leasedAssertMark: this.c.leasedAssertMark.id,
        leasedAssertModel: this.c.leasedAssertModel.id,
        leasingObjectYear: this.c.leasingObjectYear,
        generation: this.c.generation.id,
        body: this.c.body.id,
        modification: this.c.modification.id,
      }
      return this.asyncAction('getSpecifications', null, () => {
        return this.$axios
          .get(urlGetCarData(query), {
            headers: { 'X-API-SECRET': import.meta.env.VITE_ERM_API_SECRET },
          })
          .then(res => {
            this.specifications = res.data.specifications
          })
      })
    },
  },
  created() {
    if (this.approveCalculation) {
      this.asyncAction('addDealerOutlets')
      this.asyncAction('addChartTypes')
      if (this.standardLeasingObjectType && this.canApprove) {
        this.assignData()
      }
    }
  },
}
</script>
