<script setup></script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="44"
    height="44"
    class="icon"
    viewBox="0 0 48 48"
    fill="none">
    <mask
      id="mask0_4315_27871"
      style="mask-type: luminance"
      maskUnits="userSpaceOnUse"
      x="4"
      y="4"
      width="40"
      height="40">
      <path
        d="M39 6H9C7.34315 6 6 7.34315 6 9V39C6 40.6569 7.34315 42 9 42H39C40.6569 42 42 40.6569 42 39V9C42 7.34315 40.6569 6 39 6Z"
        fill="white"
        stroke="white"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M32 30H36M24 24H36M28 18H36"
        stroke="black"
        stroke-width="4"
        stroke-linecap="round" />
    </mask>
    <g mask="url(#mask0_4315_27871)">
      <path d="M0 0H48V48H0V0Z" fill="#FC7247" />
    </g>
  </svg>
</template>

<style scoped>
.icon {
  width: 24px;
  height: 24px;
  margin-top: -2px;
}
</style>
