<template>
  <div>
    <v-card-text
      class="d-flex justify-space-between pa-0 pb-2"
      style="font-size: 0.8rem">
      <div>
        <span>Тема:</span>
        <span>
          {{ getSafe(() => ticket.theme.name) + '. ' + ticket.title }}
        </span>
      </div>
      <div style="font-size: 0.72rem">
        <span>{{ ticket.created_at }} ( {{ ticket.status.name }} )</span>
        <!-- <CRUD
					v-if="user.roleId === 1"
					:actionsArray="selectItems.ticketStatuses.map(status => {
						return {
							action: () => {
								$axios.post(urlPostAdminSetTicketStatus(ticket.id), {
									statusId: status.id
								});
								$emit('dataChanged');
								this.$setSnackbar('Статус успiшно змiнено');
							},
							text: status.name,
							icon: '',
							disabled: status.id === $getSafe(() => ticket.status.id)
						}
					})"
					small>
				</CRUD> -->
      </div>
    </v-card-text>
    <v-card-text class="pt-0 pb-0" style="color: black">
      {{ ticket.description }}
    </v-card-text>
    <v-card-text v-if="getSafe(() => ticket.file.length)" class="pb-0">
      <FilesUploader
        v-model:documents="ticket.file"
        label="Файли"
        disableDeleteAction>
      </FilesUploader>
    </v-card-text>
    <v-divider class="ma-5"></v-divider>
    <div v-for="(item, key) in ticketComments" :key="key" class="d-flex">
      <v-avatar size="45px" color="indigo" class="mt-3 mr-4">
        <v-icon
          v-if="![item.userId, item.adminId].includes(user.id) || !user.photo"
          color="white">
          {{ 'mdi-account-circle' }}
        </v-icon>
        <img
          v-if="[item.userId, item.adminId].includes(user.id) && user.photo"
          :src="user.photo" />
      </v-avatar>
      <v-card class="mt-3 comment-card" :elevation="0">
        <div class="arrow-left"></div>
        <v-card-text class="d-flex pb-2 pt-2">
          <div
            class="d-flex flex-column justify-space-between"
            style="flex: 1 1 auto">
            <div class="text-left date">
              {{ getName(item) }}
            </div>
            <div class="comment">
              {{ item.comment }}
            </div>
            <div class="text-right date">
              {{ item.created_at }}
            </div>
          </div>
        </v-card-text>
      </v-card>
    </div>
    <v-card-text v-show="!addCommentState" class="text-right pl-0 pr-0">
      <v-btn
        class="no-transform text-white"
        small
        color="grey darken-3"
        @click="addCommentState = !addCommentState">
        <v-icon size="small" color="white">
          {{ 'mdi-plus' }}
        </v-icon>
        <span>Додати коментар</span>
      </v-btn>
    </v-card-text>
    <div v-show="addCommentState">
      <v-card class="mt-5 add-comment-card" :elevation="0">
        <v-card-text class="pt-2">
          <div class="d-flex justify-space-between">
            <div>Коментар:</div>
            <div>
              <v-scroll-x-transition hide-on-leave>
                <v-btn
                  v-show="comment"
                  size="x-small"
                  color="green"
                  icon
                  @click="submitComment">
                  <v-icon small>
                    {{ 'mdi-check-circle' }}
                  </v-icon>
                </v-btn>
              </v-scroll-x-transition>
              <v-btn
                v-show="!comment"
                size="x-small"
                color="red"
                icon
                @click="addCommentState = !addCommentState">
                <v-icon small>
                  {{ 'mdi-close' }}
                </v-icon>
              </v-btn>
            </div>
          </div>
          <v-textarea
            v-model="comment"
            :rows="3"
            dense
            variant="outlined"
            hide-details>
          </v-textarea>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import FilesUploader from '@/components/FilesUploader.vue'
import { urlPostAdminSetTicketStatus } from '@/pages/request'
import { mapState } from 'vuex'
import { getSafe } from '@/utils/helperFunc'
export default {
  components: { FilesUploader /* CRUD */ },
  props: {
    ticket: { type: Object },
    user: { type: Object },
    addCommentAction: {
      type: Function,
      default: () =>
        Promise.resolve(
          this.$setSnackbar({ text: 'No handler', color: 'warning' })
        ),
    },
  },
  data: () => ({
    comment: null,
    addCommentState: false,
  }),
  computed: {
    ...mapState({
      selectItems: state => state.selectItems,
    }),
    ticketComments() {
      return this.ticket?.comments || []
    },
    params() {
      return {
        comment: this.comment,
        ticketId: parseInt(this.$route?.params?.id),
      }
    },
  },
  methods: {
    getSafe,
    urlPostAdminSetTicketStatus,
    getName(item) {
      if ([item.userId, item.adminId].includes(this.user.id))
        return 'Ваш коментар'
      return 'Вхідний коментар'
    },
    submitComment() {
      this.loading = true

      this.addCommentState = !this.addCommentState

      return this.addCommentAction(this.params)
        .then(res => {
          this.comment = null
          this.loading = false
          this.$emit('dataChanged')
          return res
        })
        .catch(err => this.$err(err, () => (this.loading = false)))
    },
  },
  created() {
    this.$store.dispatch('addTicketStatuses')
  },
}
</script>
<style lang="scss">
.comment-card {
  border: 1px solid gainsboro !important;
  flex: 1 1 auto;
  position: relative;
  min-height: 55px !important;
  .comment {
    color: black;
    font-size: 0.9rem;
  }
}
.arrow-left {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid gainsboro;
  position: absolute;
  top: 12px;
  left: -10px;
  &:before {
    content: '';
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid white;
    position: absolute;
    top: -10px;
    left: 1px;
  }
}
.add-comment-card {
  margin-left: 60px;
  border: 1px solid gainsboro !important;
}
</style>
<style scoped>
.date {
  font-size: 0.8rem;
}
</style>
