<template>
  <div style="margin-left: -10px">
    <div class="white-block">
      <v-row>
        <v-col :cols="12" :md="12">
          <div>
            <span class="text-title">Клієнт:</span>
            <ALink
              :text="payment.contract?.client?.shortName"
              :route="getEntityRoute(payment)" />
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col :cols="12" :md="12" class="pb-0">
          <div>
            <span class="text-title">№ договору:</span>
            <ALink
              :text="payment.contract.number"
              :route="{
                name: 'dfl-contract',
                params: { id: payment.contract.id },
              }" />
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col :cols="12" :md="12">
          <div>
            <span class="text-title">Об'єкт лізингу:</span>
            <span class="text-desc">{{
              payment.contract.leasingObjectName
            }}</span>
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="white-block mt-3">
      <v-row>
        <v-col :cols="12" :md="3" class="pb-0 pt-0">
          <div class="data-card">
            <span class="area-label">Сума АВ</span>
            <span class="title-label">{{
              getBeautyNum(payment.amountAB) + ' грн'
            }}</span>
          </div>
        </v-col>
        <v-col :cols="12" :md="3" class="pb-0 pt-0">
          <div class="data-card">
            <span class="area-label">Сума ОЛ</span>
            <span class="title-label">{{
              getBeautyNum(payment.amountOl) + ' грн'
            }}</span>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col :cols="12" :md="12" class="pb-0">
          <div class="data-card">
            <span class="area-label">Контрагент</span>
            <ALink
              :text="payment.agent.contact.contractor.shortName"
              :route="{
                name: 'contractors-form',
                params: { id: payment.agent.contact.contractor.id },
              }" />
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col :cols="12" :md="4">
          <div class="data-card">
            <span class="area-label">Агент</span>
            <ALink
              v-if="payment.agent.contact"
              :text="payment.agent.contact.fullName"
              :route="{
                name: 'contact-common-info',
                params: { id: payment.agent.contact.id },
              }" />
          </div>
        </v-col>
        <v-col :cols="12" :md="8">
          <div class="data-card">
            <span class="area-label">Торгова точка</span>
            <ALink
              v-if="payment.agent.dealerPoint"
              :text="payment.agent.dealerPoint.name"
              :route="{
                name: 'dealer-form',
                params: { id: payment.agent.dealerPoint.id },
              }" />
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="white-block mt-3 mb-3">
      <div class="d-flex mb-2 align-center">
        <span class="text-title">Виплата буде здiйснюватись через:</span>
        <span class="title-label" style="margin-top: 0 !important">{{
          payment?.broker?.shortName || '---'
        }}</span>
      </div>
      <span class="text-title">На реквiзити:</span>
      <div class="d-flex">
        <span class="mr-1 d-block">Банк:</span>
        <span class="link">{{
          payment?.broker?.basicCurrentAccount?.bankName || '---'
        }}</span>
      </div>
      <div class="d-flex">
        <span class="mr-1 d-block">Рахунок:</span>
        <div
          v-if="
            payment?.broker?.basicCurrentAccount &&
            payment?.broker?.basicCurrentAccount.accountNumber
          "
          class="d-flex align-center">
          <v-hover>
            <template #default="{ isHovering, props }">
              <div v-bind="props" class="d-flex align-center">
                <span
                  class="link"
                  @click="
                    () =>
                      copyText(payment.broker.basicCurrentAccount.accountNumber)
                  "
                  >{{
                    payment.broker?.basicCurrentAccount?.accountNumber || '---'
                  }}</span
                >
                <div style="width: 16px; margin-left: 5px">
                  <v-fade-transition hide-on-leave>
                    <v-icon
                      v-show="
                        isHovering &&
                        payment.broker.basicCurrentAccount.accountNumber !==
                          '---'
                      "
                      class="ml-1"
                      style="cursor: pointer"
                      size="small"
                      @click="
                        () =>
                          copyText(
                            payment.broker.basicCurrentAccount.accountNumber
                          )
                      ">
                      {{
                        copied.value ===
                        payment.broker.basicCurrentAccount.accountNumber
                          ? 'mdi-check'
                          : 'mdi-content-copy'
                      }}
                    </v-icon>
                  </v-fade-transition>
                </div>
              </div>
            </template>
          </v-hover>
        </div>
        <span v-else>---</span>
      </div>
      <div class="d-flex">
        <span class="mr-1 d-block">МФО:</span>
        <span class="link">{{
          payment.broker?.basicCurrentAccount?.mfo || '---'
        }}</span>
      </div>
    </div>
    <div class="white-block mt-3 mb-3" style="padding-bottom: 10px">
      <FilesUploader
        v-if="payment.signedActOfProvisionOfServices"
        label="Скан договору"
        readonly
        :documents="payment.signedActOfProvisionOfServices"
        list />
      <span v-else class="text-desc">Cкан документу Відсутній</span>
    </div>
  </div>
</template>

<script>
import ALink from '@/components/Link.vue'
import { getBeautyNum } from '@/utils/helperFunc'
import FilesUploader from '@/components/FilesUploader.vue'

export default {
  name: 'PaymentGeneral',
  components: { FilesUploader, ALink },
  props: {
    payment: { type: Object },
  },
  data() {
    return {
      copied: { value: false, timeout: null },
    }
  },
  methods: {
    getBeautyNum,
    getEntityRoute(item) {
      const typeContractor = item.entityTypeId === 2
      const name = typeContractor ? 'contractors-form' : 'individuals-form'
      const id = item.id
      return { name, params: { id } }
    },
    copyText(text) {
      clearTimeout(this.copied.timeout)
      this.copied.value = null
      this.$setSnackbar({ text: 'Текст скопiйовано' })
      navigator.clipboard
        .writeText(text)
        .then(() => {
          this.copied.value = text
          this.copied.timeout = setTimeout(
            () => (this.copied.value = null),
            3000
          )
        })
        .catch(() => {
          throw new Error('text copied failed')
        })
    },
  },
}
</script>

<style scoped>
.data-card {
  display: flex;
  flex-direction: column;
}
.title-label {
  font-size: 14px;
  color: #1c1d21;
  margin-top: 5px;
  font-weight: 400 !important;
}
.text-title {
  color: #1c1d21;
  font-weight: 600;
  font-size: 15px;
  margin-right: 6px;
}
.text-desc {
  font-weight: 400;
  font-size: 14px;
  color: #1c1d21;
}
.background {
  background: #fff;
  border-radius: 4px;
  padding: 10px;
}
</style>
