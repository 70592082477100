<template>
  <div>
    <list-section
      :title="'Кар\'єра'"
      :addActionDisabled="readonly"
      :addActionTitle="'Додати кар\'єру'"
      :addAction="() => $emit('openDialog', ['addCareer'])">
      <template #body>
        <v-data-table
          v-if="contactData.careers"
          :headers="tHeadCareers"
          :items="contactData.careers"
          hide-default-footer
          :hide-default-header="!contactData.careers"
          class="pb-2 pt-1 custom-table head-line"
          dense>
          <!-- eslint-disable-next-line -->
          <template #item.contractor="{ item }">
            <a
              @click="
                $router.push({
                  name: 'contractors-form',
                  params: { id: item.contractor.id },
                })
              ">
              {{ getSafe(() => item.contractor.shortName, '---') }}
            </a>
          </template>
          <!-- eslint-disable-next-line -->
          <template #no-data>
            <no-data></no-data>
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.actions="{ item }">
            <TableCrud
              data-readonly="hide"
              :actionsArray="[
                {
                  action: () => deleteCareer(item),
                  text: 'Видалити',
                  disabled: readonly,
                  icon: 'mdi-close',
                },
                {
                  action: () => editCareer(item, { hideSignature: true }),
                  text: 'Редагувати',
                  disabled: readonly,
                  icon: 'EditPencil',
                  customIcon: true,
                },
              ]">
            </TableCrud>
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.dateStart="{ item }">
            {{ item.startDate }}
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.dateEnd="{ item }">
            {{ item.startDate }}
          </template>
        </v-data-table>
      </template>
    </list-section>
    <!-- Education -->
    <list-section
      :title="'Освіта'"
      :addActionDisabled="readonly"
      :addActionTitle="'Додати оствіту'"
      :addAction="
        () =>
          $store.commit('setDialog', {
            title: 'Освіта',
            action: 'createEducation',
            params: {
              contactId: contactId,
              cb: () => $emit('tableChange'),
            },
          })
      ">
      <template #body>
        <v-data-table
          v-if="contactData.education"
          :headers="tHeadEducation"
          :items="contactData.education"
          hide-default-footer
          :hide-default-header="!contactData.education"
          class="pb-2 pt-1 custom-table head-line"
          dense>
          <!-- eslint-disable-next-line -->
          <template #no-data>
            <no-data></no-data>
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.actions="{ item }">
            <TableCrud
              :actionsArray="[
                {
                  action: () =>
                    $store.commit('setDialog', {
                      title: 'Освіта',
                      action: 'deleteEducation',
                      params: {
                        educationId: item.id,
                        cb: () => $emit('tableChange'),
                      },
                    }),
                  disabled: readonly,
                  text: 'Видалити',
                  icon: 'mdi-close',
                },
                {
                  action: () =>
                    $store.commit('setDialog', {
                      title: 'Освіта',
                      action: 'updateEducation',
                      params: {
                        contactId: contactId,
                        cb: () => $emit('tableChange'),
                      },
                      dialogItem: item,
                    }),
                  disabled: readonly,
                  text: 'Редагувати',
                  icon: 'EditPencil',
                  customIcon: true,
                },
              ]">
            </TableCrud>
          </template>
        </v-data-table>
      </template>
    </list-section>

    <list-section title="Зв'язок бенефіціара">
      <template #body>
        <v-data-table
          v-if="contact.beneficiaries"
          :headers="tHeadbeneficiar"
          :items="contact.beneficiaries"
          hide-default-footer
          :hide-default-header="!contact.beneficiaries"
          class="pb-2 pt-1 custom-table head-line"
          dense>
          <template #no-data>
            <no-data></no-data>
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.contractor="{ item }">
            <ALink
              :text="item.contractor.shortName"
              :route="{
                name: 'contractors-form',
                params: { id: item.contractor.id },
              }"></ALink>
          </template>
        </v-data-table>
      </template>
    </list-section>
  </div>
</template>
<script>
import listSection from '@/components/list-section.vue'
import noData from '@/components/no-table-data.vue'
import TableCrud from '@/components/table-crud.vue'
import { urlDeleteContactCareer } from '@/pages/request'
import { mapState } from 'vuex'
import { editCareer, getSafe } from '@/utils/helperFunc.js'
import ALink from '@/components/Link.vue'
export default {
  emits: ['openDialog', 'tableChange'],
  components: { ALink, listSection, noData, TableCrud },
  props: {
    contact: { type: Object },
    readonly: { type: Boolean },
  },
  data: () => ({
    tHeadCareers: [
      {
        title: 'Компанія',
        key: 'contractor',
        sortable: false,
        align: 'start',
      },
      { title: 'Посада', key: 'post.name', sortable: false, align: 'start' },
      {
        title: 'Повна назва посади',
        key: 'fullPostName',
        sortable: false,
        align: 'start',
      },
      {
        title: 'Департамент',
        key: 'department.name',
        sortable: false,
        align: 'start',
      },
      { title: 'Роль', key: 'role.name', sortable: false, align: 'start' },
      { title: 'Дiї', key: 'actions', sortable: false, align: 'end' },
    ],
    tHeadbeneficiar: [
      {
        title: 'Контрагент',
        key: 'contractor',
        sortable: false,
        align: 'start',
      },
      {
        title: 'Тип володiння',
        key: 'influence.name',
        sortable: false,
        align: 'start',
      },
      {
        title: 'Ознака КБВ',
        key: 'signKbv.name',
        sortable: false,
        align: 'start',
      },
      {
        title: 'Доля в статутному капіталі (%)',
        key: 'percent',
        sortable: false,
        align: 'center',
      },
    ],
    tHeadEducation: [
      {
        title: 'Освіта',
        key: 'type.name',
        sortable: false,
        align: 'start',
      },
      {
        title: 'Навчальный заклад',
        key: 'educationalInstitutionName',
        sortable: false,
        align: 'start',
      },
      { title: 'Дiї', key: 'actions', sortable: false, align: 'end' },
    ],
    tHeadDealerOutlets: [
      { title: 'Назва', key: 'name', align: 'start', sortable: false },
      {
        title: 'Відповідальний менеджер',
        key: 'responsibleId',
        align: 'start',
        sortable: false,
      },
    ],
  }),
  computed: {
    ...mapState({ selectItems: state => state.selectItems }),
    contactId() {
      return parseInt(this.$route.params.id)
    },
    contactData() {
      return this.contact
    },
    contactAgent() {
      return this.contact.contactTypeId.includes(2)
    },
  },
  methods: {
    getSafe,
    urlDeleteContactCareer,
    editCareer,
    deleteCareer(item) {
      return this.$axios
        .delete(this.urlDeleteContactCareer(item.id))
        .then(res => {
          this.$setSnackbar({ text: "Кар'єру видалено" })
          this.$emit('tableChange')
          return res
        })
        .catch(this.$err)
    },
  },
}
</script>
