<template>
  <div>
    <v-row>
      <v-col :cols="12" :md="6" class="pt-0 pb-0">
        <LeasingParticipant
          v-model:signatoryId="contract.signatoryId"
          v-model:basisId="contract.lessorBasisId"
          label="Лізингодавець"
          :typeId="2"
          :participant="lessor"
          :v$="{
            contactId: $v.contract.signatoryId,
            basisId: $v.contract.lessorBasisId,
          }"
          :readonly="readonly"
          single
          details />
      </v-col>
      <v-col :cols="12" :md="6" class="pt-0 pb-0">
        <LeasingParticipant
          v-model:signatories="contract.lesseeSignatories"
          label="Лізингоотримувач"
          :typeId="contract.lesseeTypeId"
          :participant="lessee"
          :v$="$v.contract.lesseeSignatories"
          :readonly="readonly"
          details />
      </v-col>
    </v-row>
    <Agreements style="margin-left: 15px" :agreements="contract.agreements" />
  </div>
</template>
<script>
import Agreements from '@/components/Agreements.vue'
import LeasingParticipant from '@/pages/contracts/components/leasingParticipant/LeasingParticipant.vue'
import { mapState } from 'vuex'
export default {
  components: { Agreements, LeasingParticipant },
  props: {
    readonly: { type: Boolean },
  },
  computed: {
    ...mapState({
      contract: state => state.contracts.dflContract,
      lessee: state => state.contracts.dflContract.lessee,
      lessor: state => state.contracts.dflContract.lessor,
      $v: state => state.contracts.$vDfl,
    }),
  },
}
</script>
