<template>
  <div>
    <v-row>
      <v-col
        v-if="addContractorKbv || editContractorKbv"
        cols="12"
        md="12"
        sm="12">
        <span class="area-label">Контакт</span>
        <v-autocomplete
          v-model="kbv.contactId"
          :error-messages="contactIdErr"
          hide-details
          placeholder="Оберiть зi списку"
          :items="directoryItems('contacts', kbv.contact)"
          item-title="fullName"
          item-value="id"
          :filter="contactFilter"
          :readonly="deleteContractorKbv"
          :disabled="deleteContractorKbv"
          @focus="asyncAction('addContacts')"
          @blur="v$.kbv.contactId.$touch()">
        </v-autocomplete>
      </v-col>
      <v-col
        v-if="createContractorKbv || deleteContractorKbv"
        class="pb-0"
        cols="12"
        md="12"
        sm="12">
        <span class="area-label">Прізвище</span>
        <v-text-field
          v-model="kbv.surname"
          placeholder="Введіть текст"
          :error-messages="surnameErr"
          :disabled="deleteContractorKbv"
          :loading="duplicatesLoading"
          :readonly="deleteContractorKbv"
          hide-details
          @blur="
            () => {
              v$.kbv.surname.$touch()
              checkNameSurname()
            }
          ">
        </v-text-field>
      </v-col>
      <v-col
        v-if="createContractorKbv || deleteContractorKbv"
        class="pb-0"
        cols="12"
        md="12"
        sm="12">
        <span class="area-label">Ім`я</span>
        <v-text-field
          v-model="kbv.name"
          placeholder="Введіть текст"
          hide-details
          :error-messages="nameErr"
          :disabled="deleteContractorKbv"
          :readonly="deleteContractorKbv"
          @blur="
            () => {
              v$.kbv.name.$touch()
              checkNameSurname()
            }
          ">
        </v-text-field>
      </v-col>
      <v-col v-if="createContractorKbv || deleteContractorKbv">
        <span class="area-label">По батькові</span>
        <v-text-field
          v-model="kbv.patronymic"
          hide-details
          placeholder="Введіть текст"
          :error-messages="patronymicErr"
          :disabled="deleteContractorKbv"
          :readonly="deleteContractorKbv"
          @blur="v$.kbv.patronymic.$touch()">
        </v-text-field>
      </v-col>
    </v-row>
    <div>
      <v-row>
        <v-col
          v-if="createContractorKbv || deleteContractorKbv"
          class="pb-0"
          cols="12"
          md="12"
          sm="12">
          <span class="area-label">Громадянство</span>
          <v-select
            v-model="kbv.nationality"
            hide-details
            :error-messages="nationalityIdErr"
            placeholder="Оберiть зi списку"
            :items="directoryItems('nationalityList', kbv.nationality)"
            item-title="name"
            item-value="id"
            dense
            return-object
            :disabled="deleteContractorKbv"
            :readonly="deleteContractorKbv"
            @focus="asyncAction('addNationalities')"
            @blur="v$.kbv.nationality.$touch()">
          </v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-if="createContractorKbv || deleteContractorKbv"
          :cols="12"
          :md="6">
          <span class="area-label">ІПН</span>
          <v-text-field
            id="kbv-inn"
            v-model="kbv.inn"
            hide-details
            placeholder="Введіть значення"
            :error-messages="innErr"
            :disabled="deleteContractorKbv"
            :readonly="deleteContractorKbv"
            @input="maxNumLength('kbv-inn', 10)"
            @blur="v$.kbv.inn.$touch()">
          </v-text-field>
        </v-col>
        <v-col
          v-if="createContractorKbv || deleteContractorKbv"
          :cols="12"
          :md="6">
          <span class="area-label">Дата народження</span>
          <DatePicker
            v-model="kbv.birthday"
            class="task-plan-date-range-fixed"
            single
            placeholder="Оберіть дату"
            hide-details
            :maxDate="maxDate" />
        </v-col>
      </v-row>
    </div>
    <div>
      <v-row>
        <v-col class="pb-0" :cols="12" :md="6">
          <span class="area-label">Тип впливу</span>
          <v-select
            v-model="kbv.influence"
            hide-details
            placeholder="Оберiть зi списку"
            :error-messages="influenceIdErr"
            :items="directoryItems('influenceList', kbv.influence)"
            item-title="name"
            :disabled="deleteContractorKbv"
            :readonly="deleteContractorKbv"
            item-value="id"
            dense
            return-object
            @focus="asyncAction('addInfluenceList')"
            @blur="v$.kbv.influence.$touch()">
          </v-select>
        </v-col>
        <v-col class="pb-0" :cols="12" :md="6">
          <span class="area-label">Ознака КБВ</span>
          <v-select
            v-model="kbv.signKbv"
            hide-details
            placeholder="Оберiть зi списку"
            :error-messages="signKbvIdErr"
            :items="directoryItems('signKbvList', kbv.signKbv)"
            item-title="name"
            item-value="id"
            dense
            return-object
            :disabled="deleteContractorKbv"
            :readonly="deleteContractorKbv"
            @focus="asyncAction('addSignKbvList')"
            @blur="v$.kbv.signKbv.$touch()">
          </v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12" sm="12">
          <span class="area-label">Доля володiння %</span>
          <v-text-field
            id="benfc-am-percent"
            v-model="kbv.percent"
            :error-messages="percentErr"
            placeholder="Вкажіть значення у %"
            hide-details
            dense
            :readonly="deleteContractorKbv"
            :disabled="deleteContractorKbv"
            @blur="v$.kbv.percent.$touch()"
            @input="
              maxNum(
                'benfc-am-percent',
                $number(() => dialog.params.maxPercent),
                { float: 3 }
              )
            ">
          </v-text-field>
        </v-col>
      </v-row>
    </div>
    <h3 v-if="deleteContractorKbv">КБВ буде видалено!</h3>
  </div>
</template>
<script>
import DatePicker from '@/components/DatePicker.vue'
import { required, minLength } from '@vuelidate/validators'
import {
  urlUnsetBeneficiarOwner,
  urlSetBeneficiarOwner,
  urlCreateBeneficiarOwner,
  urlUpdateBeneficiarOwner,
} from '@/pages/request'

import {
  setErrHandler,
  contactFilter,
  checkDuplicates,
} from '@/utils/helperFunc'
import { maxNum } from '@/utils/formatFunc'
import { maxNumLength } from '@/utils/masks'
import { mapState } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import { useSelect } from '@/utils/mixins/useSelect'
export default {
  components: { DatePicker },
  setup() {
    return { v$: useVuelidate(), ...useSelect() }
  },
  props: { dialog: { type: Object } },
  validations() {
    const contactId =
      this.addContractorKbv || this.editContractorKbv ? { required } : {}
    const surname = this.createContractorKbv ? { required } : {}
    const name = this.createContractorKbv ? { required } : {}
    const patronymic = {}
    const inn = this.createContractorKbv
      ? { required, minLength: minLength(10) }
      : {}
    const birthday = this.createContractorKbv
      ? { required, minLength: minLength(10) }
      : {}
    const percent = this.kbv.signKbv?.id === 1 ? {} : { required }
    const nationality = this.createContractorKbv ? { required } : {}
    const influence = { required }
    const signKbv = { required }

    const kbv = {
      percent: percent,
      surname: surname,
      name: name,
      patronymic: patronymic,
      inn: inn,
      birthday: birthday,
      contactId: contactId,
      nationality: nationality,
      influence: influence,
      signKbv: signKbv,
    }

    return {
      kbv: this.deleteContractorKbv ? {} : kbv,
    }
  },
  data: () => ({
    duplicatesLoading: false,
    kbv: {
      contactId: null,
      surname: null,
      name: null,
      patronymic: null,
      inn: null,
      birthday: null,
      percent: null,
      address: null,
      nationality: null,
      influence: null,
      signKbv: null,
    },
  }),
  computed: {
    ...mapState({ selectItems: state => state.selectItems }),
    validation() {
      return { v$: this.v$.kbv, name: 'contractorKbv' }
    },
    addContractorKbv() {
      return this.dialog.action === 'addContractorKbv'
    },
    editContractorKbv() {
      return this.dialog.action === 'editContractorKbv'
    },
    deleteContractorKbv() {
      return this.dialog.action === 'deleteContractorKbv'
    },
    createContractorKbv() {
      return this.dialog.action === 'createContractorKbv'
    },
    contractorId() {
      return this.$store.state?.dialog?.params?.contractorId
    },
    maxDate() {
      return new Date(new Date().setFullYear(new Date().getFullYear() - 15))
        .toISOString()
        .split('T')[0]
    },
    birthdayErr() {
      return this.setErrHandler(this.v$.kbv.birthday)
    },
    surnameErr() {
      return this.setErrHandler(this.v$.kbv.surname)
    },
    nameErr() {
      return this.setErrHandler(this.v$.kbv.name)
    },
    patronymicErr() {
      return this.setErrHandler(this.v$.kbv.patronymic)
    },
    innErr() {
      return this.setErrHandler(this.v$.kbv.inn)
    },
    percentErr() {
      return this.setErrHandler(this.v$.kbv.percent)
    },
    contactIdErr() {
      return this.setErrHandler(this.v$.kbv.contactId)
    },
    nationalityIdErr() {
      return this.setErrHandler(this.v$.kbv.nationality)
    },
    influenceIdErr() {
      return this.setErrHandler(this.v$.kbv.influence)
    },
    signKbvIdErr() {
      return this.setErrHandler(this.v$.kbv.signKbv)
    },
  },
  methods: {
    maxNum,
    contactFilter,

    urlUnsetBeneficiarOwner,
    urlSetBeneficiarOwner,
    urlCreateBeneficiarOwner,
    urlUpdateBeneficiarOwner,
    setErrHandler,
    maxNumLength,
    checkDuplicates,
    submit() {
      switch (true) {
        case this.editContractorKbv:
          return this.editKbv()
        case this.createContractorKbv:
          return this.createKbv()
        case this.deleteContractorKbv:
          return this.deleteKbv()
        case this.addContractorKbv:
          return this.addKbv()
        default:
          return Promise.reject('No handler')
      }
    },
    checkNameSurname() {
      if (this.kbv.surname && this.kbv.name) {
        this.duplicatesLoading = true
        checkDuplicates(`${this.kbv.surname} ${this.kbv.name}`).then(
          () => (this.duplicatesLoading = false)
        )
      }
    },
    editKbv() {
      const k = this.kbv
      k.percent = parseFloat(k.percent)
      const req = {
        percent: k.percent,
        contactId: k.contactId,
        influenceId: k.influence.id,
        signKbvId: k.signKbv.id,
        id: k.id,
        contractorId: this.dialog.params.contractorId,
      }
      return this.$axios.post(this.urlUpdateBeneficiarOwner(this.kbv.id), req)
    },
    createKbv() {
      const req = Object.clone(this.kbv)
      req.percent = parseFloat(this.kbv.percent)
      req.signKbvId = this.kbv.signKbv.id
      req.influenceId = this.kbv.influence.id
      req.nationalityId = this.kbv.nationality.id
      return this.$axios.post(
        this.urlCreateBeneficiarOwner(this.contractorId),
        req
      )
    },
    addKbv() {
      const req = Object.clone(this.kbv)
      req.percent = parseFloat(this.kbv.percent)
      req.signKbvId = this.kbv.signKbv.id
      req.influenceId = this.kbv.influence.id
      return this.$axios.post(
        this.urlSetBeneficiarOwner(this.contractorId),
        req
      )
    },
    deleteKbv() {
      return this.$axios.delete(this.urlUnsetBeneficiarOwner(this.kbv.id))
    },
  },
  mounted() {
    Object.assign(this.kbv, this.dialog.dialogItem)
  },
}
</script>
