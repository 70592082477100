<template>
  <div>
    <v-btn
      class="text-white no-transform mb-2"
      color="grey darken-3"
      small
      @click="choosedTable = !choosedTable">
      {{ toggleBtnText }}
    </v-btn>
    <v-scroll-x-transition hide-on-leave>
      <v-card v-show="!choosedTable" :elevation="0">
        <v-card-text>
          <pre>{{ JSON.stringify(contact.registry, null, 2) }}</pre>
          <pre>{{ JSON.stringify(contact.factors, null, 2) }}</pre>
          <pre>{{ JSON.stringify(contact.dpa, null, 2) }}</pre>
        </v-card-text>
      </v-card>
    </v-scroll-x-transition>
    <v-scroll-x-transition hide-on-leave>
      <div v-show="choosedTable">
        <!-- common data -->
        <list-section title="Загальнi данi" :mt="0" :mb="0" :elevation="0">
          <template #body>
            <v-data-table
              :headers="[
                { text: 'ПIБ', key: 'fullName', sortable: false },
                { text: 'Стать', key: 'sex', sortable: false, width: '7%' },
                {
                  text: 'Дата народження ',
                  key: 'birthDate',
                  sortable: false,
                  width: '12%',
                },
                {
                  text: 'Дата реєстрації',
                  key: 'registrationDate',
                  sortable: false,
                  width: '12%',
                },
                {
                  text: 'Номер реєстрації',
                  key: 'registrationNumber',
                  sortable: false,
                  width: '12%',
                },
                { text: 'Адреса ', key: 'location', sortable: false },
                { text: 'IПН ', key: 'code', sortable: false },
                { text: 'Email ', key: 'email', sortable: false },
              ]"
              :items="[contact.registry]"
              :hide-default-footer="true"
              :hide-default-header="false"
              class="pb-2 pt-1 custom-table head-line"
              dense>
              <template #no-data>
                <no-data></no-data>
              </template>
            </v-data-table>
          </template>
        </list-section>
        <!-- registrations -->
        <list-section title="Реєстрації" :mt="0" :mb="0" :elevation="0">
          <template #body>
            <v-data-table
              :headers="[
                {
                  title: 'Орган реєстрації',
                  key: 'name',
                  sortable: false,
                  width: '35%',
                },
                {
                  title: 'ЄДРПОУ установи',
                  key: 'code',
                  sortable: false,
                  width: '15%',
                },
                {
                  title: 'Опис',
                  key: 'description',
                  sortable: false,
                  width: '25%',
                },
                {
                  title: 'Номер реєстрації',
                  key: 'startNum',
                  sortable: false,
                  width: '15%',
                },
                {
                  title: 'Дата реєстрації',
                  key: 'startDate',
                  sortable: false,
                  width: '15%',
                },
              ]"
              :items="contact.registry.registrations"
              :hide-default-footer="true"
              :hide-default-header="false"
              class="pb-2 pt-1 custom-table head-line"
              dense>
              <template #no-data>
                <no-data></no-data>
              </template>
            </v-data-table>
          </template>
        </list-section>
        <!-- activities -->
        <list-section title="Види дiяльностi" :mt="0" :mb="0" :elevation="0">
          <template #body>
            <v-data-table
              :headers="[
                { title: 'Вид дiяльностi', key: 'name', sortable: false },
                { title: 'Код', key: 'code', sortable: false },
              ]"
              :items="contact.registry.activities"
              :hide-default-footer="true"
              :hide-default-header="false"
              class="pb-2 pt-1 custom-table head-line"
              dense>
              <template #no-data>
                <no-data></no-data>
              </template>
            </v-data-table>
          </template>
        </list-section>
        <!-- primary activity -->
        <list-section
          title="Основний вид дiяльностi"
          :mt="0"
          :mb="0"
          :elevation="0">
          <template #body>
            <span class="pa-2 d-inline-block">
              {{ contact.registry.primaryActivity }}
            </span>
          </template>
        </list-section>
        <!-- address -->
        <list-section title="Адреса" :mt="0" :mb="0" :elevation="0">
          <template #body>
            <v-data-table
              :headers="[
                { title: 'Країна', key: 'country', sortable: false },
                { title: 'Поштовий iндекс', key: 'zip', sortable: false },
                {
                  title: 'Адреса',
                  key: 'address',
                  sortable: false,
                  align: 'center',
                },
              ]"
              :items="[contact.registry.address]"
              :hide-default-footer="true"
              :hide-default-header="false"
              class="pb-2 pt-1 custom-table head-line"
              dense>
              <template #no-data>
                <no-data></no-data>
              </template>
            </v-data-table>
          </template>
        </list-section>
        <!-- termination -->
        <list-section
          title="Припинення дiяльностi"
          :mt="0"
          :mb="0"
          :elevation="0">
          <template #body>
            <v-data-table
              :headers="[
                { title: 'Статус', key: 'stateText', sortable: false },
                { title: 'Опис', key: 'cause', sortable: false },
                {
                  title: 'Дата припинення',
                  key: 'date',
                  sortable: false,
                  align: 'center',
                },
                {
                  title: 'Номер припинення',
                  key: 'recordNumber',
                  sortable: false,
                  align: 'center',
                },
              ]"
              :items="[contact.registry.termination]"
              :hide-default-footer="true"
              :hide-default-header="false"
              class="pb-2 pt-1 custom-table head-line"
              dense>
              <template #no-data>
                <no-data></no-data>
              </template>
            </v-data-table>
          </template>
        </list-section>
        <!-- termination cancel -->
        <list-section
          title="Скасування припинення"
          :mt="0"
          :mb="0"
          :elevation="0">
          <template #body>
            <v-data-table
              :headers="[
                { title: 'Дата', key: 'date', sortable: false },
                { text: 'Номер', key: 'recordNumber', sortable: false },
                {
                  title: 'Суд',
                  key: 'courtName',
                  sortable: false,
                  align: 'center',
                },
                {
                  title: 'Постанова',
                  key: 'docNumber',
                  sortable: false,
                  align: 'center',
                },
              ]"
              :items="[contact.registry.terminationCancel]"
              :hide-default-footer="true"
              :hide-default-header="false"
              class="pb-2 pt-1 custom-table head-line"
              dense>
              <template #no-data>
                <no-data></no-data>
              </template>
            </v-data-table>
          </template>
        </list-section>
        <!-- bankruptcy -->
        <list-section
          v-if="bankruptcy"
          title="Банкрутство"
          :mt="0"
          :mb="0"
          :elevation="0">
          <template #body>
            <v-data-table
              :headers="[
                { title: 'Дата банкрутства', key: 'date', sortable: false },
                { title: 'Опис', key: 'stateText', sortable: false },
                {
                  title: 'Дата судового рiшення',
                  key: 'dateJudge',
                  sortable: false,
                },
                {
                  title: 'Суд',
                  key: 'courtName',
                  sortable: false,
                  align: 'center',
                },
                {
                  title: 'Номер постановы',
                  key: 'docNumber',
                  sortable: false,
                  align: 'center',
                },
              ]"
              :items="[bankruptcy]"
              :hide-default-footer="true"
              :hide-default-header="false"
              class="pb-2 pt-1 custom-table head-line"
              dense>
              <template #no-data>
                <no-data></no-data>
              </template>
            </v-data-table>
          </template>
        </list-section>
        <!-- registration -->
        <list-section title="Реєстрація" :mt="0" :mb="0" :elevation="0">
          <template #body>
            <v-data-table
              :headers="[
                { title: 'Дата', key: 'date', sortable: false },
                {
                  title: 'Номер реєстрації ',
                  key: 'recordNumber',
                  sortable: false,
                },
              ]"
              :items="[contact.registry.registration]"
              :hide-default-footer="true"
              :hide-default-header="false"
              class="pb-2 pt-1 custom-table head-line"
              dense>
              <template #no-data>
                <no-data></no-data>
              </template>
            </v-data-table>
          </template>
        </list-section>
        <!-- changes -->
        <list-section title="Змiни" :mt="0" :mb="0" :elevation="0">
          <template #body>
            <v-data-table
              ref="dialogEdrTable"
              :headers="edrChangeTableHeaders"
              :items="contact.factors"
              :hide-default-footer="true"
              :hide-default-header="false"
              class="pb-2 pt-1 custom-table head-line"
              dense>
              <!-- eslint-disable-next-line -->
              <template #item.factorGroup="{ item }">
                {{ getFactor(item.factorGroup) }}
              </template>
              <!-- eslint-disable-next-line -->
              <template #item.indicator="{ item }">
                <v-icon :color="getIndicator(item.indicator).color" small>
                  {{ getIndicator(item.indicator).icon }}
                </v-icon>
              </template>
              <!-- eslint-disable-next-line -->
              <template #item.json="{ item }">
                <v-menu :close-on-content-click="false" top offset-y>
                  <template #activator="{ props }">
                    <v-btn size="small" icon v-bind="props">
                      <v-icon size="small" color="grey darken-3">
                        {{ 'mdi-text-box-search' }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-text>
                      <pre>{{ JSON.stringify(item, null, 2) }}</pre>
                    </v-card-text>
                  </v-card>
                </v-menu>
              </template>
            </v-data-table>
          </template>
        </list-section>
      </div>
    </v-scroll-x-transition>
    <v-row>
      <v-col :cols="12" :md="6">
        <v-alert prominent type="warning" elevation="2" dense>
          <v-row align="center">
            <v-col class="grow" style="font-size: 0.95rem">
              При пiдтвердженнi всi незбереженi данi в формі буде втрачено
            </v-col>
          </v-row>
        </v-alert>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import listSection from '@/components/list-section.vue'
import { edrChangeTableHeaders } from '@/utils/tableHeaders'
export default {
  components: { listSection },
  props: { contact: { type: Object } },
  data() {
    return {
      choosedTable: true,
      edrChangeTableHeaders,
    }
  },
  computed: {
    toggleBtnText() {
      return this.choosedTable ? 'Переглянути в JSON' : 'Переглянути в таблицях'
    },
    bankruptcy() {
      return this.contact?.registry?.bankruptcy
    },
  },
  methods: {
    getAddress(branch) {
      const addresses = branch.address.address
      branch.address
      branch.addresses = addresses
      return branch
    },
    getFactor(group) {
      switch (group) {
        case 'tax':
          return 'Податки'
        case 'court':
          return 'Суди'
        case 'sanction':
          return 'Cанкцiї'
        case 'edr':
          return 'ЄДР'
      }
    },
    getIndicator(indicator) {
      if (indicator === 'warning')
        return { icon: 'mdi-alert-circle', color: 'orange' }
      if (indicator === 'positive️')
        return { icon: 'mdi-check-circle', color: 'green' }
      else return { icon: 'mdi-help', color: 'red' }
    },
  },
}
</script>
