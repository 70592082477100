<template>
  <div class="sms-view">
    <span class="d-block mb-1">Повiдомлення:</span>
    <span class="d-block" style="color: #000; font-size: 16px;">{{ this.dialog.dialogItem.message }}</span>
  </div>
</template>

<script>
export default {
  props: {
    dialog: {type: Object}
  }
}
</script>