<template>
  <div>
    <div>
      <OpenableSection :title="`Проект №${project.projectNumber}`">
        <template #append-title>
          <v-tooltip bottom text="Перейти до проекту">
            <template #activator="{ props }">
              <v-btn
                :to="{ name: 'project', params: { projectId: project.id } }"
                class="mr-1"
                size="small"
                icon
                v-bind="props">
                <LinkIcon />
              </v-btn>
            </template>
            <span>Перейти до проекту</span>
          </v-tooltip>
        </template>
        <div style="background: #f9fbffc9">
          <AnalyzeService
            :analyzeData="project"
            :securityResult="securityResults"
            hideKlp
            hideRisk
            hideLegal />
          <v-row>
            <v-col :cols="12" :sm="12">
              <FilesUploader
                label="Опитувальник (Підписаний)"
                :documents="project.questionnaireSigned"
                readonly />
              <FilesUploader
                label="Заявка на лізинг (Підписаний)"
                :documents="
                  getSafe(
                    () =>
                      project.applicationFormSigned.find(
                        a => !(a.guarantorId && a.guarantorTypeId)
                      ).doc,
                    null
                  )
                "
                readonly />
              <FilesUploader
                v-if="entityTypeId === 2"
                label="Структура власності (Підписаний)"
                :documents="project.ownershipStructureSigned"
                readonly />
            </v-col>
          </v-row>
        </div>
      </OpenableSection>
    </div>
  </div>
</template>

<script>
import OpenableSection from '@/components/OpenableSection.vue'
import LinkIcon from '@/assets/svg/link-icon.vue'
import { getContactInitials, getSafe, toFormatDate } from '@/utils/helperFunc'
import FilesUploader from '@/components/FilesUploader.vue'
import AnalyzeService from '@/components/AnalyzeService.vue'

export default {
  components: {
    AnalyzeService,
    FilesUploader,
    LinkIcon,
    OpenableSection,
  },
  props: {
    project: { type: Object, required: true },
    entityTypeId: { type: Number, required: true },
  },
  computed: {
    securityResults() {
      const results = []
      const s = this.project.securityResult
      if (!s) return results

      results.push({
        name: `${s.client?.name} (Клієнт)`,
        finishDate: s.client?.finishDate,
        responsible: s.responsible,
        result: s.client.result?.name || '---',
        status: s.status,
      })
      if (s.guarantors) {
        s.guarantors.forEach(g => {
          results.push({
            name: `${g.name} (Поручитель)`,
            finishDate: g.finishDate,
            responsible: s.responsible,
            result: g?.result?.name || '---',
            status: s.status,
          })
        })
      }
      if (Array.isArray(s.providers)) {
        s.providers.forEach(p => {
          results.push({
            name: `${p.name} (Постачальник)`,
            finishDate: p.finishDate,
            responsible: s.responsible,
            result: p?.result?.name || '---',
            status: s.status,
          })
        })
      }
      return results
    },
  },
  methods: {
    getSafe,
    getContactInitials,
    toFormatDate,
  },
}
</script>

<style lang="scss">
.service-title {
  font-size: 17px;
}
.card-block-title {
  display: block;
  color: #08487a;
  font-weight: 600;
  font-size: 16px;
  margin-left: 9px;
}
.v-data-table tbody tr {
  background: #fff !important;
}
</style>
