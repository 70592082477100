<template>
  <v-col :cols="12" :md="3" class="pr-1 pl-0 pt-0 pb-0 common-data">
    <div class="mb-3 left-block">
      <span
        class="mb-3 mt-2 d-block"
        style="color: #08487a; font-weight: 700; font-size: 16px"
        >Загальнi дані</span
      >
      <div class="pt-0">
        <div class="d-flex align-center mb-2 justify-space-between">
          <div class="d-flex align-center">
            <div>
              <svg
                class="mr-2"
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 19 23"
                fill="none">
                <path
                  d="M7.91667 5.21429V3.64286H6.33333V5.21429H7.91667ZM9.5 6.78571V5.21429H7.91667V6.78571H9.5ZM7.91667 8.35714V6.78571H6.33333V8.35714H7.91667ZM9.5 9.92857V8.35714H7.91667V9.92857H9.5ZM18.1589 5.16518C18.3898 5.39435 18.5877 5.70536 18.7526 6.09821C18.9175 6.49107 19 6.85119 19 7.17857V21.3214C19 21.6488 18.8845 21.9271 18.6536 22.1562C18.4227 22.3854 18.1424 22.5 17.8125 22.5H1.1875C0.857639 22.5 0.577257 22.3854 0.346354 22.1562C0.115451 21.9271 0 21.6488 0 21.3214V1.67857C0 1.35119 0.115451 1.07292 0.346354 0.84375C0.577257 0.614583 0.857639 0.5 1.1875 0.5H12.2708C12.6007 0.5 12.9635 0.581845 13.3594 0.745536C13.7552 0.909226 14.0686 1.10565 14.2995 1.33482L18.1589 5.16518ZM12.6667 2.16964V6.78571H17.3177C17.2352 6.54836 17.1445 6.38058 17.0456 6.28237L13.1738 2.43973C13.0749 2.34152 12.9058 2.25149 12.6667 2.16964ZM17.4167 20.9286V8.35714H12.2708C11.941 8.35714 11.6606 8.24256 11.4297 8.01339C11.1988 7.78423 11.0833 7.50595 11.0833 7.17857V2.07143H9.5V3.64286H7.91667V2.07143H1.58333V20.9286H17.4167ZM9.66081 12.077L10.9844 16.3616C11.0503 16.5826 11.0833 16.7954 11.0833 17C11.0833 17.6793 10.7844 18.242 10.1865 18.6881C9.58865 19.1341 8.83203 19.3571 7.91667 19.3571C7.0013 19.3571 6.24468 19.1341 5.64681 18.6881C5.04894 18.242 4.75 17.6793 4.75 17C4.75 16.7954 4.78299 16.5826 4.84896 16.3616C5.02214 15.846 5.51693 14.2254 6.33333 11.5V9.92857H7.91667V11.5H8.89388C9.0753 11.5 9.23611 11.5532 9.3763 11.6596C9.51649 11.766 9.61133 11.9051 9.66081 12.077ZM7.91667 17.7857C8.35373 17.7857 8.72689 17.708 9.03613 17.5525C9.34538 17.3969 9.5 17.2128 9.5 17C9.5 16.7872 9.34538 16.6031 9.03613 16.4475C8.72689 16.292 8.35373 16.2143 7.91667 16.2143C7.4796 16.2143 7.10645 16.292 6.7972 16.4475C6.48796 16.6031 6.33333 16.7872 6.33333 17C6.33333 17.2128 6.48796 17.3969 6.7972 17.5525C7.10645 17.708 7.4796 17.7857 7.91667 17.7857Z"
                  fill="#08487A" />
              </svg>
            </div>
            <div class="d-flex flex-column">
              <span class="d-block area-label" style="margin-bottom: 3px"
                >Код архівної справи</span
              >
              <span style="font-size: 14px">{{
                contactData.archiveCode || 'Відсутній'
              }}</span>
            </div>
          </div>
        </div>
        <span class="area-label">Прізвище</span>
        <v-text-field
          v-model="contactData.surname"
          dense
          hide-details
          placeholder="Введіть текст"
          :readonly="readonly">
        </v-text-field>
        <span class="area-label">Ім’я</span>
        <v-text-field
          v-model="contactData.name"
          dense
          hide-details
          placeholder="Введіть текст"
          :readonly="readonly">
        </v-text-field>
        <span class="area-label">По-батькові</span>
        <v-text-field
          v-model="contactData.patronymic"
          dense
          :disabled="!contactData.isPatronymic"
          hide-details
          :placeholder="contactData.isPatronymic ? 'Введіть текст' : 'Відсутнє'"
          :readonly="readonly">
        </v-text-field>
        <v-checkbox
          v-model="contactData.isPatronymic"
          :true-value="false"
          :false-value="true"
          hide-details
          :readonly="readonly"
          color="red"
          class="mt-2"
          density="comfortable"
          dense>
          <template #label>
            <span style="font-size: 12px">Відсутнє по батькові</span>
          </template>
        </v-checkbox>
        <v-checkbox
          v-model="contactData.resident"
          :readonly="readonly"
          color="red"
          class="mt-0"
          hide-details
          density="comfortable"
          dense>
          <template #label>
            <span style="font-size: 12px"> Резидент </span>
          </template>
        </v-checkbox>
        <v-checkbox
          v-model="contactData.isAgroSectorClient"
          :readonly="readonly"
          color="red"
          class="mt-0"
          hide-details
          density="comfortable"
          dense>
          <template #label>
            <span style="font-size: 12px"> Клієнт із аграрного сектора </span>
          </template>
        </v-checkbox>
      </div>
    </div>
    <div class="left-block">
      <div v-if="contactData.fop">
        <span class="area-label">Сегмент</span>
        <v-autocomplete
          v-model="contactData.segmentId"
          :items="filteredSegmentTypes"
          item-title="name"
          item-value="id"
          hide-details
          placeholder="Оберiть зi списку"
          dense
          :readonly="readonly"
          @focus="asyncAction('addSegmentTypes')">
          <template #append-item
            ><Loader v-if="asyncLoading('addSegmentTypes')"
          /></template>
        </v-autocomplete>
      </div>
      <span class="area-label">Громадянство</span>
      <v-autocomplete
        v-model="contactData.nationalityId"
        :items="directoryItems('nationalityList', contactData.nationality)"
        item-title="name"
        item-value="id"
        hide-details
        placeholder="Оберiть зi списку"
        dense
        :readonly="readonly"
        @focus="asyncAction('addNationalities')">
        <template #append-item
          ><Loader v-if="asyncLoading('addNationalities')"
        /></template>
      </v-autocomplete>
      <v-row>
        <v-col :cols="12" :md="6">
          <span class="area-label">Стать</span>
          <v-select
            v-model="contactData.genderId"
            :readonly="readonly"
            hide-details
            :items="directoryItems('genderList', contactData.gender)"
            item-value="id"
            placeholder="Оберiть стать"
            item-title="name"
            dense
            @change="() => (contactData.familyStatusId = null)"
            @focus="asyncAction('addGenders')">
            <template #append-item
              ><Loader v-if="asyncLoading('addGenders')"
            /></template>
          </v-select>
        </v-col>
        <v-col :cols="12" :md="6">
          <span class="area-label">Сімейний статус</span>
          <v-select
            v-model="contactData.familyStatusId"
            :items="directoryItems('familyStatuses', contactData.familystatus)"
            :readonly="readonly"
            hide-details
            placeholder="Оберiть"
            item-title="name"
            item-value="id"
            @focus="asyncAction('addFamilyStatuses')">
            <template #append-item
              ><Loader v-if="asyncLoading('addFamilyStatuses')"
            /></template>
          </v-select>
        </v-col>
        <v-col cols="12" md="12">
          <span v-if="!contactData.familyStatusId === 2" class="area-label">{{
            contactData.familyStatusId !== null
              ? contactData.familyStatusId === 1
                ? 'Дружина'
                : 'Чоловік'
              : ''
          }}</span>
          <v-autocomplete
            v-if="contactData.familyStatusId === 1"
            v-model="contactData.marriedId"
            :items="directoryItems('contacts', contactData.marriedTo)"
            :readonly="readonly"
            item-value="id"
            hide-details
            item-title="fullName"
            placeholder="Оберiть чоловіка або дружину"
            :filter="contactFilter"
            dense
            :required="contactData.familyStatusId === 1"
            @focus="asyncAction('addContacts')">
            <template #append-item
              ><Loader v-if="asyncLoading('addContacts')"
            /></template>
            <template #prepend-inner>
              <v-scroll-x-transition hide-on-leave class="d-none">
                <InputPusher
                  v-if="contactData.marriedId"
                  size="small"
                  :routeObject="{
                    name: 'individuals-form',
                    params: { id: contactData.marriedId },
                  }"
                  text="Перейти до контакту">
                </InputPusher>
              </v-scroll-x-transition>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
    </div>
    <div class="left-block mt-3">
      <!-- change responsible -->
      <ChangeRespobsible
        v-model:responsibleId="contactData.responsibleId"
        :read-only="contactData.readOnly"
        :responsible="contactData.responsible" />
    </div>
    <MainContractorCard
      :readonly="readonly"
      :mainContractor="contactData.contractor"
      :callback="callback">
    </MainContractorCard>
  </v-col>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { email, phone } from '@/type-ids'
import { contactFilter } from '@/utils/helperFunc'
import {
  getContactInitials,
  getResponsibleShortName,
} from '@/utils/helperFunc.js'
import { contact as C } from '@/type-ids.js'
import MainContractorCard from './main-contractor.vue'
import Loader from '@/components/Loader.vue'
import InputPusher from '@/components/InputPusher.vue'
import ChangeRespobsible from '@/components/ChangeRespobsible.vue'
import { useVuelidate } from '@vuelidate/core'
import { useSelect } from '@/utils/mixins/useSelect'

export default {
  components: { ChangeRespobsible, InputPusher, Loader, MainContractorCard },
  props: {
    contact: { type: Object },
    callback: { type: Function },
    v: { type: Object },
    readonly: { type: Boolean },
  },
  setup() {
    return { v$: useVuelidate(), ...useSelect() }
  },
  validations() {
    return {
      contact: {},
    }
  },
  data: () => ({
    C: C, // contact
    email,
    phone,
  }),
  computed: {
    ...mapState({
      selectItems: state => state.selectItems,
    }),
    ...mapGetters({
      activeUsers: 'activeUsers',
    }),
    filteredSegmentTypes() {
      if (this.contactData.fop) {
        return this.directoryItems(
          'segmentTypes',
          this.contactData.segment
        ).filter(item => item.id === 1 || item.id === 2)
      } else {
        return this.directoryItems(
          'segmentTypes',
          this.contactData.segment
        ).filter(item => item.id === 5)
      }
    },
    contactData() {
      return this.contact
    },
    mainPhone() {
      if (!this.contactData.phones.length) return
      return (
        this.contactData.phones.find(item => item.typeId === phone.type.MAIN)
          ?.phone || null
      )
    },
    mainEmail() {
      if (!this.contactData.emails.length) return
      return (
        this.contactData.emails.find(item => item.typeId === email.type.WORK)
          ?.email || null
      )
    },
    $v() {
      return this.v
    },
  },
  methods: {
    getContactInitials,
    getResponsibleShortName,
    contactFilter,
  },
  watch: {
    'contactData.isPatronymic': function (val) {
      if (val === false) {
        this.contactData.patronymic = null
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.left-block {
  background: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  padding: 10px;
}
.res-post {
  display: block;
  width: 270px;
  font-size: 13px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.res-block {
  margin-bottom: 15px;
  &:hover .res-change {
    display: block !important;
  }
  .res-change {
    display: none;
    cursor: pointer;
    color: #09487a;
    font-weight: 500;
    font-size: 13px;
  }
}
.subtitle {
  color: #838282;
  font-weight: 500;
  font-size: 13px;
  display: block;
  margin-bottom: 5px;
}
</style>
