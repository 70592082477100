<template>
  <div>
    <v-data-table
      v-if="bankAccounts"
      :headers="tHeadBankAccounts"
      :items="bankAccounts"
      hide-default-footer
      :hide-default-header="!bankAccounts.length"
      class="pb-2 pt-1 custom-table head-line"
      dense>
      <!-- eslint-disable-next-line -->
      <template #no-data>
        <no-data></no-data>
      </template>
      <template #item.mfo="{ item }">
        <span style="font-size: 13px !important">{{ item.mfo }}</span>
      </template>
      <template #item.bankName="{ item }">
        <span style="font-size: 13px !important">{{ item.bankName }}</span>
      </template>
      <template #item.accountNumber="{ item }">
        <span style="font-size: 13px !important">{{ item.accountNumber }}</span>
      </template>
      <template #item.currency="{ item }">
        <span style="font-size: 13px !important">{{
          item.currency?.name || '---'
        }}</span>
      </template>
      <!-- eslint-disable-next-line -->
      <template #item.currentAccount="{ item }">
        <span style="font-size: 13px !important">
          {{
            item.isBasic
              ? item.type && item.type.name
                ? item.type.name + ' (Основний)'
                : '---'
              : item.type && item.type.name
              ? item.type.name
              : '---'
          }}
        </span>
      </template>
      <!-- eslint-disable-next-line -->
      <template #item.actions="{ item }">
        <TableCrud
          data-readonly="hide"
          :actionsArray="[
            {
              action: () => deleteBankAccount(item),
              text: 'Видалити',
              icon: 'mdi-close',
              disabled: readonly,
            },
            {
              action: () => editBankAccount(item),
              text: 'Редагувати',
              icon: 'EditPencil',
              customIcon: true,
              disabled: readonly,
            },
          ]">
        </TableCrud>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import noData from '@/components/no-table-data.vue'
import TableCrud from '@/components/table-crud.vue'
import { urlDeleteBankAccount } from '@/pages/request'

export default {
  components: { noData, TableCrud },
  props: {
    readonly: { type: Boolean, default: false },
    itemsName: { type: String },
    bankAccounts: { type: Array },
  },
  data: () => ({
    tHeadBankAccounts: [
      { title: 'МФО', key: 'mfo', sortable: false },
      { title: 'Банк', key: 'bankName', sortable: false },
      { title: '№ рахунку', key: 'accountNumber', sortable: false },
      { title: 'Валюта', key: 'currency', sortable: false },
      { title: 'Тип р/р', key: 'currentAccount', sortable: false },
      { title: '', key: 'actions', sortable: false, align: 'end' },
    ],
  }),
  methods: {
    deleteBankAccount(item) {
      return this.$axios
        .delete(urlDeleteBankAccount(item.id))
        .then(res => {
          this.$setSnackbar({ text: 'Рахунок видалено' })
          this.$emit('itemDeleted')
          return res
        })
        .catch(this.$err)
    },
    editBankAccount(item) {
      item.accounts = this.bankAccounts.map(account => account.typeId)
      this.$emit('openDialog', ['editBankAccount', item])
    },
  },
}
</script>

<style scoped>
.head-line {
  border-top: 0px !important;
}
.custom-table head-line {
  border-top: 0px !important;
}
.custom-table .head-line {
  border-top: 0px !important;
}
</style>
