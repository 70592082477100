<template>
  <div>
    <v-row>
      <v-col cols="12" md="12" sm="12" class="pb-0 pt-0">
        <span class="label">Назва (Автосалон)</span>
        <v-text-field
          v-model="dealer.name"
          :error-messages="dealerNameErr"
          placeholder="Введіть текст"
          hide-details
          :loading="duplicatesLoading"
          :readonly="deleteDealer"
          @blur="
            () => {
              v$.dealer.name && v$.dealer.name.$touch()
              checkDuplicates()
            }
          ">
        </v-text-field>
      </v-col>
      <v-col cols="12" md="12" sm="12" class="pb-0 pt-0">
        <span class="label mt-2">Тип</span>
        <v-autocomplete
          v-model="dealer.typeId"
          :items="selectItems.dealerTypes"
          :error-messages="dealerTypeErr"
          item-title="name"
          item-value="id"
          hide-details
          placeholder="Оберiть зi списку"
          :readonly="deleteDealer"
          @blur="v$.dealer.typeId && v$.dealer.typeId.$touch()"
          @change="
            () => {
              dealer.categoryId = null
              v$.$reset()
            }
          ">
          <template #append>
            <span v-if="deleteDealer"></span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        v-if="dealer.typeId === D.type.DEALER"
        cols="12"
        md="12"
        sm="12"
        class="pb-0 pt-0">
        <span class="label">Категорія</span>
        <v-select
          v-model="dealer.categoryId"
          :items="selectItems.dealerCategories"
          :error-messages="dealerCategoryIdErr"
          item-title="name"
          item-value="id"
          placeholder="Оберiть зi списку"
          hide-details
          :readonly="deleteDealer"
          @blur="v$.dealer.categoryId && v$.dealer.categoryId.$touch()">
          <template #append>
            <span v-if="deleteDealer"></span>
          </template>
        </v-select>
      </v-col>
    </v-row>
    <v-fade-transition hide-on-leave>
      <div>
        <div class="mt-3 d-flex align-center">
          <span style="color: #09487a">Зв'язки</span>
          <v-tooltip bottom text="Додати контрагента">
            <template #activator="{ props }">
              <div v-bind="props">
                <v-btn icon size="small" @click="openContractorList">
                  <v-icon>{{
                    contractorDialogList ? 'mdi-close' : 'mdi-plus'
                  }}</v-icon>
                </v-btn>
              </div>
            </template>
            <span>Додати контрагента</span>
          </v-tooltip>
        </div>
        <div v-if="contractorDialogList">
          <div v-if="![D.category.COMMISSION_AREA].includes(dealer.categoryId)">
            <span class="label mt-2">Контрагент</span>
            <div class="d-flex align-center">
              <v-autocomplete
                v-model="dealer.contractorId"
                :error-messages="dealerContractorIdErr"
                :items="selectItems.providers"
                item-value="id"
                hide-details
                item-title="shortName"
                placeholder="Оберiть зi списку"
                :filter="contractorFilter"
                :readonly="deleteDealer"
                @blur="
                  v$.dealer.contractorId && v$.dealer.contractorId.$touch()
                ">
                <template #append>
                  <span v-if="deleteDealer"></span>
                </template>
                <template #prepend>
                  <v-tooltip bottom text="Створити контрагента">
                    <template #activator="{ props }">
                      <v-btn
                        small
                        icon
                        v-bind="props"
                        @click="createContractor">
                        <v-icon style="color: rgb(252, 114, 71)"
                          >mdi-domain-plus</v-icon
                        >
                      </v-btn>
                    </template>
                    <span>Створити контрагента</span>
                  </v-tooltip>
                </template>
              </v-autocomplete>
              <v-btn
                v-if="dealer.contractorId"
                style="margin-top: 10px"
                size="small"
                icon
                @click="openContractorList">
                <v-icon small>{{ 'mdi-close' }}</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </v-fade-transition>
    <h3 v-if="deleteDealer">Дилера буде видалено!</h3>
  </div>
</template>
<script>
import { required } from '@vuelidate/validators'
import { mapState } from 'vuex'
import {
  urlPostCreateDealer,
  urlDeleteDealer,
  urlDealerDuplicates,
} from '@/pages/request'

import {
  setErrHandler,
  contractorFilter,
  getContactInitials,
  contactDetailsWithType,
} from '@/utils/helperFunc'
import { dealer as D } from '@/type-ids.js'
import { setSnackbar } from 'best-modules/plugins'
import { useVuelidate } from '@vuelidate/core'
import { useSelect } from '@/utils/mixins/useSelect'
export default {
  props: { dialog: { type: Object } },
  setup() {
    return { v$: useVuelidate(), ...useSelect() }
  },
  validations() {
    const categoryId =
      this.dealer.typeId === this.D.type.DEALER ? { required } : {}
    const contractorId =
      this.dealer.typeId === this.D.type.DEALER &&
      this.dealer.categoryId === this.D.category.OFFICIAL
        ? { required }
        : {}
    const dealer = this.addDealer
      ? {
          dealer: {
            name: { required },
            typeId: { required },
            categoryId: categoryId,
            contractorId: contractorId,
          },
        }
      : {}

    return dealer
  },
  data: () => ({
    dealer: {
      name: null,
      categoryId: null,
      typeId: null,
      contractorId: null,
    },
    contractorDialogList: false,
    duplicatesLoading: false,
    D: D, // dealer types
  }),
  computed: {
    ...mapState({ selectItems: state => state.selectItems }),
    addDealer() {
      return this.dialog.action === 'addDealer'
    },
    deleteDealer() {
      return this.dialog.action === 'deleteDealer'
    },
    validation() {
      return {
        v$: this.v$.dealer,
        name: 'dealer',
      } /* add keys after back implementation */
    },
    contractorId() {
      return this.$store.state?.dialog?.params?.contractorId
    },
    dealerNameErr() {
      return setErrHandler(this.v$?.dealer?.name)
    },
    dealerTypeErr() {
      return setErrHandler(this.v$?.dealer?.typeId)
    },
    dealerCategoryIdErr() {
      return setErrHandler(this.v$?.dealer?.categoryId)
    },
    dealerContractorIdErr() {
      return setErrHandler(this.v$?.dealer?.contractorId)
    },
  },
  methods: {
    contractorFilter,
    urlDealerDuplicates,
    getContactInitials,
    contactDetailsWithType,
    createContractor() {
      this.$store.commit('setDialog', {
        title: 'Контрагент',
        dialogItem: null,
        action: 'newContractor',
        params: {
          cb: () => false,
        },
      })
    },
    openContractorList() {
      this.contractorDialogList = !this.contractorDialogList
    },
    checkDuplicates() {
      this.duplicatesLoading = true
      if (this.dealer.name !== null && this.dealer.name !== '') {
        return this.$axios
          .get(this.urlDealerDuplicates(this.dealer.name))
          .then(res => {
            if (res.data.count > 0) {
              delete res.data.count
              this.getDuplicateNotifyHTML(Object.values(res.data))
            }
          })
          .finally(() => (this.duplicatesLoading = false))
      } else return null
    },
    getDuplicateNotifyHTML(arr) {
      if (!arr) return
      const msgName = dealer =>
        `Відповідальний: ${getContactInitials(dealer.responsible)}`

      const text = [
        {
          tag: 'div',
          text: 'Знайдені збіги по:',
          class: 'mb-3',
        },
        ...arr.map(item => ({
          tag: 'ol',
          children: [
            {
              tag: 'li',
              text: `Назва: ${item.name}`,
            },
            {
              tag: 'li',
              text: msgName(item),
            },
            {
              tag: 'li',
              text: `Телефон: ${this.contactDetailsWithType(
                item.phones,
                'phone'
              )}`,
            },
          ],
          class: 'mb-3',
        })),
      ]

      return setSnackbar({ text: text, color: 'warning' })
    },
    submit() {
      switch (true) {
        case this.addDealer:
          return this.createDiller()
        case this.deleteDealer:
          return this.removeDealer()
        default:
          return this.$setSnackbar({ text: 'No handler', color: 'warning' })
      }
    },
    createDiller() {
      return this.$axios.post(urlPostCreateDealer(), this.dealer)
    },
    removeDealer() {
      return this.$axios
        .delete(urlDeleteDealer(this.dealer?.id))
        .then(res => {
          if (res?.data?.message) {
            return this.$setSnackbar({
              text: res?.data?.message,
              color: 'warning',
            })
          }
          this.$emit('updateList')
          return this.$setSnackbar({ text: 'Дилера видалено' })
        })
        .catch(error => {
          if (error.response && error.response.status === 423) {
            this.$setSnackbar({
              text: error.response.data.message,
              color: 'warning',
            })
          }
        })
    },
  },
  created() {
    this.$store.dispatch('addDealerTypes')
    this.$store.dispatch('addDealerCategories')
    this.$store.dispatch('addProviders')
  },
  mounted() {
    Object.assign(this.dealer, this.dialog.dialogItem)
  },
}
</script>
