<template>
  <div>
    <v-fade-transition hide-on-leave>
      <section-loader v-show="!user.id || !pageLoad"> </section-loader>
    </v-fade-transition>
    <v-fade-transition hide-on-leave>
      <v-row v-show="user.id && pageLoad" class="profile-block">
        <v-col cols="12" md="3" sm="12">
          <div
            class="d-flex justify-center align-center"
            style="position: relative">
            <v-avatar
              style="border: 2px solid #fc7247"
              size="140px"
              color="#fc7247"
              class="pb-0 mt-2">
              <v-icon v-if="!user.photo" color="white">
                {{ 'mdi-account-circle' }}
              </v-icon>
              <v-img v-if="user.photo" :src="user.photo" :alt="user.photo" />
            </v-avatar>
            <div
              v-if="user.telegramChatId"
              style="position: absolute; top: 0; right: 0">
              <v-tooltip
                bottom
                :text="`Телеграм аккаунт ${user.telegramChatId}`">
                <template #activator="{ props }">
                  <v-btn icon size="small" v-bind="props">
                    <a
                      target="_blank"
                      :href="'tg://user?id=' + user.telegramChatId">
                      <Telegram />
                    </a>
                  </v-btn>
                </template>
                <span>Телеграм аккаунт (ID:{{ user.telegramChatId }})</span>
              </v-tooltip>
            </div>
          </div>
          <div class="mt-2">
            <span
              style="font-size: 14px"
              class="d-block text-center font-weight-bold"
              >{{ getContactInitials(user, { isResponsible: true }) }}</span
            >
            <div
              style="font-size: 12px"
              class="mt-1 d-flex align-center justify-center">
              <span class="mr-1"
                >В системі з {{ toFormatDate(user.created_at, true) }}</span
              >
              <v-tooltip v-if="user.isActive" right text="Активний">
                <template #activator="{ props }">
                  <div v-bind="props">
                    <CheckIcon />
                  </div>
                </template>
                <span>Активний</span>
              </v-tooltip>
            </div>
          </div>
          <profile-section>
            <template #body>
              <span
                v-if="!user.telegramChatId"
                class="pt-3 d-block"
                style="font-size: 12px; color: grey">
                Підключіть Telegram щоб приймати сповіщення від Бота, при зміні
                даних в системі, завжди залишайтеся в курсі подій.
              </span>
              <div class="d-flex align-center">
                <v-btn
                  class="mt-2 no-transform"
                  size="small"
                  color="#09487a"
                  variant="flat"
                  style="width: 100%"
                  :disabled="loading"
                  @click="
                    changeTelegramBot(
                      !!user.telegramChatId ? 'disconnect' : 'connect'
                    )
                  ">
                  <v-icon class="mr-1" color="#fff" style="font-size: 20px">{{
                    'mdi-chat-remove-outline'
                  }}</v-icon>
                  {{
                    !!user.telegramChatId
                      ? 'Відʼєднати телеграм'
                      : 'Додати телеграм'
                  }}
                </v-btn>
              </div>
            </template>
          </profile-section>
          <!-- notification section -->
          <profile-section>
            <template #title> Сповіщення </template>
            <template #body>
              <v-row class="mt-2">
                <v-col class="pb-0 pt-0" cols="12" md="12" sm="12">
                  <v-checkbox
                    v-model="user.isNotificationTelegram"
                    :disabled="loading"
                    hide-details
                    color="red"
                    @change="updateNotification('telegram')"
                    ><template #label>
                      <span style="font-size: 12px">Telegram</span>
                    </template></v-checkbox
                  >
                </v-col>
                <v-col class="pb-0 pt-0" cols="12" md="12" sm="12">
                  <v-checkbox
                    v-model="user.isNotificationEmail"
                    hide-details
                    color="red"
                    :disabled="loading"
                    @change="updateNotification('email')"
                    ><template #label>
                      <span style="font-size: 12px">Email</span>
                    </template></v-checkbox
                  >
                </v-col>
              </v-row>
            </template>
          </profile-section>
        </v-col>
        <v-col cols="12" md="9" sm="12">
          <profile-section>
            <template #title>Контактна інформація</template>
            <template #body>
              <v-row>
                <v-col class="pb-0" cols="12" md="4" sm="12">
                  <span class="label">Телефон</span>
                  <v-text-field
                    v-model="userData.phone"
                    placeholder="Телефон"
                    hide-details
                    dense
                    readonly>
                  </v-text-field>
                </v-col>
                <v-col class="pb-0" cols="12" md="4" sm="12">
                  <span class="label">Email</span>
                  <v-text-field
                    v-model="userData.email"
                    placeholder="Email"
                    hide-details
                    dense
                    readonly>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pb-0" cols="12" md="4" sm="12">
                  <span class="label">Стать</span>
                  <v-select
                    v-model="userData.genderId"
                    item-title="name"
                    item-value="id"
                    readonly
                    :items="directoryItems('genderList', userData.gender)"
                    placeholder="Оберіть зі списку"
                    hide-details
                    dense
                    @focus="asyncAction('addGenders')">
                  </v-select>
                </v-col>
                <v-col class="pb-0" cols="12" md="4" sm="12">
                  <span class="label">Дата народження</span>
                  <DatePicker
                    v-model="userData.birthDate"
                    single
                    readonly
                    placeholder="Введіть дату" />
                </v-col>
                <v-col class="pb-0" cols="12" md="4" sm="12">
                  <span class="label">Посада</span>
                  <v-text-field
                    v-model="userData.post"
                    placeholder="Посада"
                    hide-details
                    dense
                    readonly>
                  </v-text-field>
                </v-col>

                <v-col class="pb-0" cols="12" md="4" sm="12">
                  <span class="label">IНН</span>
                  <v-text-field
                    id="employees-inn"
                    v-model="userData.inn"
                    readonly
                    placeholder="Введіть номер"
                    dense
                    hide-details
                    @input="maxNumLength('employees-inn', 10)">
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="4" sm="12" class="pb-0">
                  <span class="label">Департамент</span>
                  <v-select
                    v-model="userData.departmentId"
                    :items="directoryItems('departments', userData.department)"
                    item-title="name"
                    item-value="id"
                    placeholder="Оберіть зі списку"
                    hide-details
                    dense
                    readonly
                    @focus="asyncAction('addDepartments')">
                  </v-select>
                </v-col>
                <v-col cols="12" md="4" sm="12" class="pb-0">
                  <span class="label">Роль користувача</span>
                  <v-select
                    v-model="user.roleId"
                    :items="directoryItems('allUserRoles', userData.role)"
                    item-title="nameUa"
                    item-value="id"
                    dense
                    hide-details
                    placeholder="Оберіть зі списку"
                    readonly
                    @focus="asyncAction('addAllUserRoles')">
                  </v-select>
                </v-col>
                <v-col cols="12" md="4" sm="12">
                  <span class="label">profileDWH</span>
                  <v-text-field
                    v-model="user.profileDWH"
                    placeholder="Введіть текст"
                    hide-details
                    readonly
                    dense>
                  </v-text-field>
                </v-col>
              </v-row>
              <!-- password section -->
              <profile-section class="mb-5">
                <template #title>
                  <v-btn
                    class="no-transform mt-5"
                    style="background: #ebebeb"
                    @click="changePassword = !changePassword">
                    <v-icon style="font-size: 20px" class="mr-1">{{
                      'mdi-lock-outline'
                    }}</v-icon>
                    <span>{{
                      !changePassword ? 'Змінити пароль' : 'Скасувати'
                    }}</span>
                  </v-btn>
                </template>
                <template #body>
                  <v-scroll-x-transition>
                    <div v-if="changePassword">
                      <v-row class="pt-2">
                        <v-col class="pb-0" cols="12" sm="12" md="4">
                          <span class="label">Старий пароль</span>
                          <v-text-field
                            v-model="password.oldPassword"
                            :error-messages="passwordOldPasswordErr"
                            type="password"
                            placeholder="Введіть старий пароль"
                            dense
                            @blur="v$.password.oldPassword.$touch()">
                          </v-text-field>
                        </v-col>
                        <v-col class="pb-0" cols="12" sm="12" md="4">
                          <span class="label">Новий пароль</span>
                          <v-text-field
                            v-model="password.newPassword"
                            :error-messages="passwordNewPasswordErr"
                            type="password"
                            placeholder="Введіть новий пароль"
                            dense
                            @blur="v$.password.newPassword.$touch()">
                          </v-text-field>
                        </v-col>
                        <v-col class="pb-0" cols="12" sm="12" md="4">
                          <span class="label">Підтвердіть новий пароль</span>
                          <v-text-field
                            v-model="repeatPassword"
                            :error-messages="repeatPasswordErr"
                            type="password"
                            placeholder="Введіть новий пароль"
                            dense
                            @blur="v$.repeatPassword.$touch()">
                          </v-text-field>
                        </v-col>
                        <v-col v-show="!v$.$invalid" cols="12" sm="12" md="4">
                          <v-btn
                            size="small"
                            style="background: #fc7247"
                            class="text-white no-transform"
                            :loading="passwordLoading"
                            @click="submit()">
                            Змінити
                          </v-btn>
                        </v-col>
                      </v-row>
                    </div>
                  </v-scroll-x-transition>
                </template>
              </profile-section>
            </template>
          </profile-section>
        </v-col>
      </v-row>
    </v-fade-transition>
  </div>
</template>
<script>
import profileSection from '../components/profile-section.vue'
import sectionLoader from '@/components/section-loader.vue'
import { mapState } from 'vuex'
import { required, sameAs, minLength } from '@vuelidate/validators'
import {
  urlUpdateUserPassword,
  urlPostUpdateUser,
  urlUserDisconnectTelegram,
} from '@/pages/request'
import DatePicker from '@/components/DatePicker.vue'
import { maxNumLength } from '@/utils/masks'
import { getContactInitials, toFormatDate } from '@/utils/helperFunc'
import CheckIcon from '@/assets/svg/checkIcon.vue'
import Telegram from '@/assets/svg/telegram.vue'
import { useVuelidate } from '@vuelidate/core'
import { useSelect } from '@/utils/mixins/useSelect'
import { setSnackbar } from 'best-modules/plugins'
export default {
  components: {
    Telegram,
    CheckIcon,
    DatePicker,
    profileSection,
    sectionLoader,
  },
  setup() {
    return { v$: useVuelidate(), ...useSelect() }
  },
  validations() {
    const password = this.changePassword
      ? {
          password: {
            newPassword: {
              required,
              minLength: minLength(8),
              containNum: value => /[0-9]/.test(value),
              containSpecial: value => /[#?!@$%^&*-]/.test(value),
            },
            oldPassword: { required, minLength: minLength(8) },
          },
          repeatPassword: {
            required,
            sameAs: sameAs(() => this.password.newPassword),
          },
        }
      : {}

    return {
      ...password,
    }
  },
  data: () => ({
    loading: false,
    userData: {
      leadAccess: null,
      birthDate: null,
      created_at: null,
      departmentId: null,
      email: null,
      email_verified_at: null,
      id: null,
      inn: null,
      isActive: null,
      isNotificationTelegram: null,
      isNotificationEmail: null,
      name: null,
      patronymic: null,
      phone: null,
      photo: null,
      post: null,
      roleId: null,
      surname: null,
      genderId: null,
      profileDWH: null,
    },
    pageLoad: false,
    pageLoadTimeOut: 280,
    changePassword: false,
    password: {
      oldPassword: null,
      newPassword: null,
    },
    repeatPassword: null,
    passwordLoading: false,
    commonErr: 'Обов`язкове поле',
  }),
  computed: {
    ...mapState({
      user: state => state.user,
      selectItems: state => state.selectItems,
    }),
    passwordNewPasswordErr() {
      const errors = []
      if (!this.v$.password.newPassword.$error) return errors

      if (this.v$.password.newPassword.required.$invalid) {
        errors.push(this.commonErr)
      }
      if (this.v$.password.newPassword.minLength.$invalid) {
        errors.push('Мінімальна довжина 8 знаків')
      }
      if (this.v$.password.newPassword.containNum.$invalid) {
        errors.push('Мінімум 1 цифра')
      }
      if (this.v$.password.newPassword.containSpecial.$invalid) {
        errors.push('Мінімум 1 спец. символ')
      }

      return errors
    },
    passwordOldPasswordErr() {
      const errors = []
      if (!this.v$.password.oldPassword.$error) return errors

      if (this.v$.password.oldPassword.required.$invalid) {
        errors.push(this.commonErr)
      }
      if (this.v$.password.oldPassword.minLength.$invalid) {
        errors.push('Мінімальна довжина 8 знаків')
      }

      return errors
    },
    repeatPasswordErr() {
      const errors = []
      if (!this.v$.repeatPassword.$error) return errors
      if (this.v$.repeatPassword.required.$invalid) {
        errors.push(this.commonErr)
      }
      if (this.v$.repeatPassword.sameAs.$invalid) {
        errors.push('Паролі не співпадають')
      }
      return errors
    },
  },
  methods: {
    toFormatDate,
    getContactInitials,
    maxNumLength,
    urlUpdateUserPassword,
    urlUserDisconnectTelegram,
    urlPostUpdateUser,
    changeTelegramBot(action) {
      if (action === 'disconnect') {
        return this.disconnectTelegram()
      } else {
        return window.open('https://t.me/BestLeasingNotificationBot', '_blank')
      }
    },
    disconnectTelegram() {
      return this.$axios
        .get(this.urlUserDisconnectTelegram(this.user.id))
        .then(res => {
          if (res.data.message) {
            setSnackbar({ text: res.data.message })
          }
          this.$store.commit('setUser', res.data)
        })
    },
    async updateNotification(notification) {
      if (
        notification === 'telegram' &&
        !this.user.telegramChatId &&
        this.user.isNotificationTelegram
      ) {
        this.user.isNotificationTelegram = false
        return setSnackbar({
          text: 'Підключіть Telegram щоб приймати сповіщення від Бота,',
          color: 'warning',
        })
      }
      this.loading = true
      this.user.password = null
      const { data } = await this.$axios.post(
        urlPostUpdateUser(this.user.id),
        this.user
      )
      this.$store.commit('setUser', data)
      this.loading = false
    },
    assignUser() {
      setTimeout(() => {
        this.pageLoad = true
      }, this.pageLoadTimeOut)
      !this.userData.id &&
        this.user.id &&
        Object.assign(this.userData, this.user)
    },
    submit() {
      this.passwordLoading = !this.passwordLoading
      return this.$axios
        .post(this.urlUpdateUserPassword(), this.password)
        .then(res => {
          this.passwordLoading = !this.passwordLoading
          'error' in res.data
            ? this.$setSnackbar({ text: 'Невірний пароль', color: 'error' })
            : this.$setSnackbar({ text: 'Пароль успішно змінено' })
          return res
        })
        .catch(err => this.$err(err, () => (this.passwordLoading = false)))
    },
  },
  watch: {
    'user.id': function () {
      this.assignUser()
    },
  },
  mounted() {
    this.assignUser()
  },
}
</script>

<style scoped>
.v-input--selection-controls {
  margin-top: 5px !important;
}
.profile-block {
  background: #fff;
  border-radius: 11px;
  padding: 10px;
  transition: 0.3s all;
  box-shadow: 0 0 15px -4px rgba(0, 0, 0, 0.11);
}
</style>
