<template>
  <VueDatePicker
    :id="id"
    v-model="value"
    :disabled="disabled"
    :readonly="readonly"
    time-picker>
    <template #input-icon>
      <v-icon
        class="pl-3"
        size="small"
        :color="
          $attrs['error-messages'] && $attrs['error-messages'].length
            ? 'error'
            : '#adadad'
        "
        style="cursor: pointer">
        mdi-calendar-month
      </v-icon>
    </template>
  </VueDatePicker>
</template>

<script lang="ts">
import VueDatePicker from '@vuepic/vue-datepicker'
import { generateId } from '@/utils/helperFunc'
import { computed, PropType, WritableComputedRef } from 'vue'

export default {
  name: 'TimePicker',
  components: { VueDatePicker },
  emits: ['update:modelValue', 'focus', 'blur'],
  props: {
    modelValue: { type: [Object, String, Array] },
    readonly: { type: Boolean as PropType<boolean> },
    disabled: { type: Boolean as PropType<boolean> },
  },
  setup(props, { emit }) {
    const id = generateId()

    const value: WritableComputedRef<[Date | null, Date | null] | Date | null> =
      computed({
        get() {
          console.log(props.modelValue)
          if (!props.modelValue) {
            return null
          }
          const [hours, minutes] = props.modelValue.split(':')
          const dateStr = {
            hours: +hours,
            minutes: +minutes,
          }
          return dateStr
        },
        set(val) {
          if (!val) {
            emit('update:modelValue', null)
            return null
          }
          const timeString = `${val.hours
            .toString()
            .padStart(2, '0')}:${val.minutes.toString().padStart(2, '0')}`
          emit('update:modelValue', timeString)
        },
      })

    return { id, value }
  },
}
</script>

<style scoped></style>
