<template>
  <div class="datepicker">
    <div :class="{ datepicker__range: range }" class="datepicker__input">
      <v-hover>
        <template #default="{ isHovering, props: hoverProps }">
          <v-text-field
            v-model="inputValue"
            v-mask:[dateMask(timePicker,range)]
            :loading="loading"
            :label="label"
            :placeholder="placeholder"
            :readonly="range || localReadonly || disabled || readonly"
            v-bind="{ ...$attrs, ...hoverProps }"
            @blur="$emit('blur')"
            @focus="$emit('focus')"
            @click.stop="range && (isOpen = !isOpen)">
            <template #prepend-inner>
              <v-menu
                v-model="isOpen"
                :disabled="disabled || localReadonly"
                offset="15">
                <template #activator="{ props: menuProps }">
                  <div v-bind="menuProps">
                    <v-icon
                      :color="
                        Array.isArray($attrs['error-messages']) &&
                        $attrs['error-messages'].length
                          ? 'error'
                          : '#adadad'
                      "
                      style="cursor: pointer">
                      mdi-calendar-month
                    </v-icon>
                  </div>
                </template>

                <VueDatePicker
                  :id="id"
                  v-model="value"
                  :enable-time-picker="timePicker"
                  :min-date="minDate"
                  :month-change-on-scroll="false"
                  :multi-calendars="range"
                  :range="range"
                  inline
                  locale="ru-RU"
                  minutes-increment="10"
                  select-text="Обрати"
                  time-picker-inline
                  v-bind="$attrs"
                  @blur="$emit('blur')"
                  @focus="$emit('focus')"
                  @update:model-value="isOpen = false">
                  <template #action-row="{ selectDate, internalModelValue }">
                    <div
                      class="w-100 d-flex justify-space-between align-center">
                      <span>{{
                        keepArray(internalModelValue)
                          .map(getInputStr)
                          .join(' - ') || ''
                      }}</span>
                      <v-btn
                        density="compact"
                        variant="plain"
                        @click="selectDate()">
                        Обрати
                      </v-btn>
                    </div>
                  </template>
                  <template v-if="range" #left-sidebar="props">
                    <div class="date-range">
                      <span
                        v-for="(value, key) in dateRanges"
                        :key="key"
                        class="date-range-item"
                        @click="selectRangeDate(value)"
                        >{{ key }}</span
                      >
                    </div>
                  </template>
                </VueDatePicker>
              </v-menu>
            </template>
            <template v-if="closeIcon && !localReadonly" #append-inner>
              <v-fade-transition>
                <v-btn
                  v-show="isHovering"
                  color="#6750A4"
                  density="compact"
                  icon
                  v-bind="hoverProps"
                  variant="text"
                  @click.stop="
                    () => {
                      value = null
                      isOpen = false
                    }
                  ">
                  <v-icon color="grey" size="x-small">mdi-close</v-icon>
                </v-btn>
              </v-fade-transition>
            </template>
          </v-text-field>
        </template>
      </v-hover>
    </div>
  </div>
</template>

<script lang="ts">
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import {
  computed,
  ComputedRef,
  inject,
  PropType,
  Ref,
  ref,
  watch,
  WritableComputedRef,
} from 'vue'
import { generateId, keepArray } from '@/utils/helperFunc'
import { dateMask } from '@/utils/masks'
import { CustomDate, Format, ISODate, LocaleDate } from '@/utils/date'
import { getKey } from 'ol/tilecoord'

export default {
  name: 'DataPicker',
  components: { VueDatePicker },
  emits: [
    'update:modelValue',
    'update:startDate',
    'update:endDate',
    'focus',
    'blur',
  ],
  props: {
    modelValue: { type: [Object, String, Array] },
    modelValueFormat: {
      type: String as PropType<Format>,
      default: 'iso',
    },
    single: { type: Boolean, default: false },
    startDate: { type: [String, Object] },
    endDate: { type: [String, Object] },
    showTime: { type: Boolean as PropType<boolean> },
    timePicker: { type: Boolean as PropType<boolean> },
    range: { type: Boolean as PropType<boolean> },
    placeholder: { type: String as PropType<string>, default: 'Оберіть дату' },
    textInput: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    readonly: { type: Boolean as PropType<boolean> },
    disabled: { type: Boolean as PropType<boolean> },
    closeIcon: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    label: {
      type: String as PropType<string>,
    },
    minDate: {
      type: [Object, String] as PropType<Date | string>,
    },
    loading: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    keys: {
      type: Object,
      default: () => ({ startDate: 'startDate', endDate: 'endDate' }),
    },
  },
  methods: { getKey, dateMask, keepArray },
  setup(props, { emit }) {
    const readonly: Ref<boolean> | ComputedRef<boolean> = inject(
      'readonly',
      ref(false)
    )
    const localReadonly = computed(() => props.readonly || readonly.value)
    const id = generateId()
    const isOpen = ref(false)
    const getInputStr = (date: Date | LocaleDate | ISODate): string => {
      if (!date) return ''
      const parsedDate = new Date(date)
      const day = String(parsedDate.getDate()).padStart(2, '0')
      const month = String(parsedDate.getMonth() + 1).padStart(2, '0')
      const year = parsedDate.getFullYear()

      let dateString = `${day}.${month}.${year}`

      if (props.showTime) {
        const hours = String(parsedDate.getHours()).padStart(2, '0')
        const minutes = String(parsedDate.getMinutes()).padStart(2, '0')
        dateString += ` ${hours}:${minutes}`
      }

      return dateString
    }
    const getEmittedStr = (date): LocaleDate | ISODate => {
      return new CustomDate(date).toString({
        format: props.modelValueFormat,
        time: props.timePicker,
      })
    }

    const isRangeValue = value => {
      return (
        typeof value === 'object' &&
        !!value &&
        props.keys.startDate in value &&
        props.keys.endDate in value
      )
    }

    const inputValue: Ref<string> = ref(null)

    const dateRanges = computed(() => {
      if (props.single) return false
      const today = new Date()
      const yesterday = new Date(today)
      yesterday.setDate(today.getDate() - 1)

      const last7Days = new Date(today)
      last7Days.setDate(today.getDate() - 7)

      const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1)
      const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0)

      const startOfLastMonth = new Date(
        today.getFullYear(),
        today.getMonth() - 1,
        1
      )
      const endOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0)

      const quarter = Math.floor(today.getMonth() / 3) + 1
      const startOfQuarter = new Date(today.getFullYear(), 3 * quarter - 3, 1)
      const endOfQuarter = new Date(today.getFullYear(), 3 * quarter, 0)

      const startOfLastQuarter = new Date(
        today.getFullYear(),
        3 * (quarter - 2) - 3,
        1
      )
      const endOfLastQuarter = new Date(
        today.getFullYear(),
        3 * (quarter - 1),
        0
      )

      const startOfYear = new Date(today.getFullYear(), 0, 1)
      const endOfYear = new Date(today.getFullYear(), 12, 0)

      const startOfLastYear = new Date(today.getFullYear() - 1, 0, 1)
      const endOfLastYear = new Date(today.getFullYear() - 1, 12, 0)

      return {
        Сьогоднi: [today, today],
        Вчора: [yesterday, yesterday],
        'Останнi 7 днiв': [last7Days, today],
        'Поточний мiсяць': [startOfMonth, endOfMonth],
        'Минулий мiсяць': [startOfLastMonth, endOfLastMonth],
        'Поточний квартал': [startOfQuarter, endOfQuarter],
        'Минулий квартал': [startOfLastQuarter, endOfLastQuarter],
        'Поточний рiк': [startOfYear, endOfYear],
        'Минулий рiк': [startOfLastYear, endOfLastYear],
      }
    })

    const selectRangeDate = val => {
      value.value = val
      isOpen.value = false
    }

    // set inputValue
    watch(
      computed(() => props.modelValue),
      () => {
        if (props.range) {
          const parseRange = arr => {
            return arr.filter(Boolean).map(getInputStr).join(' - ')
          }
          if (isRangeValue(props.modelValue)) {
            inputValue.value = parseRange([
              props.modelValue[props.keys.startDate],
              props.modelValue[props.keys.endDate],
            ])
          } else {
            inputValue.value = parseRange([props.startDate, props.endDate])
          }
        } else {
          inputValue.value = getInputStr(
            props.modelValue as Date | LocaleDate | ISODate
          )
        }
      },
      { immediate: true, deep: true }
    )
    watch(inputValue, (val: LocaleDate) => {
      if (!val) {
        value.value = null
        return
      }
      if (props.timePicker ? val.length === 17 : val.length === 10) {
        const minDate = new Date(props.minDate)
        const inputDate = new CustomDate(val).date.toDate()
        value.value = inputDate && minDate > inputDate ? minDate : inputDate
      }
    })

    const value: WritableComputedRef<[Date | null, Date | null] | Date | null> =
      computed({
        get() {
          const getDate = val =>
            val ? new CustomDate(val).date.toDate() : null

          if (props.range) {
            if (isRangeValue(props.modelValue)) {
              return [
                props.modelValue[props.keys.startDate],
                props.modelValue[props.keys.endDate],
              ]
                .filter(Boolean)
                .map(getDate) as [Date, Date]
            } else {
              const startDate = getDate(props.startDate)
              const endDate = getDate(props.endDate)
              return startDate && endDate ? [startDate, endDate] : null
            }
          } else {
            return getDate(props.modelValue)
          }
        },
        set(val: [Date | null, Date | null] | Date | null): void {
          if (!val) {
            emit('update:modelValue', null)
            emit('update:startDate', null)
            emit('update:endDate', null)
            inputValue.value = null

            return
          }
          if (Array.isArray(val)) {
            inputValue.value = val.map(getInputStr).join(' - ')

            const [startDate, endDate] = val
            emit('update:startDate', getEmittedStr(startDate))
            emit('update:endDate', getEmittedStr(endDate))

            const obj = {}
            obj[props.keys.startDate] = getEmittedStr(startDate)
            obj[props.keys.endDate] = getEmittedStr(endDate)
            emit('update:modelValue', obj)
          } else {
            inputValue.value = getInputStr(val)
            emit('update:modelValue', getEmittedStr(val))
          }
        },
      })

    return {
      localReadonly,
      id,
      value,
      inputValue,
      isOpen,
      CustomDate,
      getInputStr,
      dateRanges,
      selectRangeDate,
    }
  },
}
</script>

<style lang="scss">
.datepicker {
  position: relative;
  font-size: 13px !important;

  &__range input {
    cursor: pointer;
  }
}
.date-range {
  display: flex;
  flex-direction: column;
  padding-bottom: 3px;
  width: 140px;
  .date-range-item {
    cursor: pointer;
    font-size: 13px;
    padding: 7px 10px;
    transition: 0.3s;
    color: #212121;
    width: 100%;
    &:hover {
      background: #80808029;
    }
  }
}
</style>
