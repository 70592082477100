<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
    <path d="M17.5 17.9375H3.5C2.5375 17.9375 1.75 17.15 1.75 16.1875V7.04375C1.75 6.475 2.0125 5.95 2.49375 5.6L10.5 0L18.5063 5.6C18.9875 5.90625 19.25 6.475 19.25 7.04375V16.1875C19.25 17.15 18.4625 17.9375 17.5 17.9375Z" fill="#C0C1C7"/>
    <path d="M6.125 0.4375H14.875V14H6.125V0.4375Z" fill="#F4F6F9"/>
    <path d="M5.6875 0V14.4375H15.3125V0H5.6875ZM14.4375 13.5625H6.5625V0.875H14.4375V13.5625Z" fill="#08487A"/>
    <path d="M14.875 1.3125C14.875 2.05625 14.7437 2.625 14 2.625C13.2562 2.625 12.6875 2.05625 12.6875 1.3125C12.6875 0.56875 13.2562 0.4375 14 0.4375C14.7437 0.4375 14.875 0.56875 14.875 1.3125ZM7 0.4375C7.74375 0.4375 8.3125 0.56875 8.3125 1.3125C8.3125 2.05625 7.74375 2.625 7 2.625C6.25625 2.625 6.125 2.05625 6.125 1.3125C6.125 0.56875 6.25625 0.4375 7 0.4375Z" fill="#08487A"/>
    <path d="M10.5 4.375C10.9832 4.375 11.375 3.98325 11.375 3.5C11.375 3.01675 10.9832 2.625 10.5 2.625C10.0168 2.625 9.625 3.01675 9.625 3.5C9.625 3.98325 10.0168 4.375 10.5 4.375Z" fill="#08487A"/>
    <path d="M10.5 11.375C11.9497 11.375 13.125 10.1997 13.125 8.75C13.125 7.30025 11.9497 6.125 10.5 6.125C9.05025 6.125 7.875 7.30025 7.875 8.75C7.875 10.1997 9.05025 11.375 10.5 11.375Z" fill="#08487A"/>
    <path d="M17.5 17.9375H3.5C2.5375 17.9375 1.75 17.15 1.75 16.1875V7.4375L10.5 13.125L19.25 7.4375V16.1875C19.25 17.15 18.4625 17.9375 17.5 17.9375Z" fill="#08487A"/>
  </svg>
</template>

<script>
export default {
  setup() {
    return {}
  }
}
</script>

<style lang="scss" scoped></style>
