<template>
  <div>
    <v-autocomplete
      v-model="frontManager.frontManagerId"
      :error-messages="frontManagerIdErr"
      :items="frontManagers"
      :item-title="
        item => item.surname + ' ' + item.name + ' ' + item.patronymic
      "
      item-value="id"
      label="Фронт менеджер"
      :readonly="supervisorDeleteFront"
      :disabled="supervisorDeleteFront"
      @blur="v$.frontManager.frontManagerId.$touch()">
      <template #append>
        <span v-if="supervisorDeleteFront"></span>
      </template>
    </v-autocomplete>
    <h4 v-if="supervisorDeleteFront" class="pt-2">
      Фронт менеджера буде вiдкрiплено вiд супервайзера!
    </h4>
  </div>
</template>
<script>
import { required } from '@vuelidate/validators'
import { setErrHandler } from '@/utils/helperFunc'

import { mapState } from 'vuex'
import {
  urlAddSupervisorTeamFrontManager,
  urlDeleteSupervisorTeamFrontManager,
} from '@/pages/request'
import { FRONT_MANAGER } from '@/roles'
import { useVuelidate } from '@vuelidate/core'
export default {
  props: { dialog: { type: Object } },
  setup() {
    return { v$: useVuelidate() }
  },
  validations() {
    const require = this.supervisorAddFront
    const frontManagerId = require ? { required } : {}

    return {
      frontManager: {
        frontManagerId: frontManagerId,
      },
    }
  },
  data: () => ({
    frontManager: {
      frontManagerId: null,
    },
  }),
  computed: {
    ...mapState({
      selectItems: state => state.selectItems,
    }),
    supervisorAddFront() {
      return this.dialog.action === 'supervisorAddFront'
    },
    supervisorDeleteFront() {
      return this.dialog.action === 'supervisorDeleteFront'
    },
    validation() {
      return { v$: this.v$.frontManager, name: 'supervisorFrontManager' }
    },
    frontManagers() {
      return this.selectItems.allUsers.filter(
        user =>
          user.roleId === FRONT_MANAGER &&
          !this.existedFrontManagers.includes(user.id)
      )
    },
    existedFrontManagers() {
      return this.dialog.params.existedFrontManagers || []
    },
    frontManagerIdErr() {
      return setErrHandler(this.v$?.frontManager?.frontManagerId)
    },
  },
  methods: {
    submit() {
      const id = this.dialog.params.supervisorTeamId
      const add = () =>
        this.$axios.post(
          urlAddSupervisorTeamFrontManager(id),
          this.frontManager
        )
      const del = () =>
        this.$axios.delete(
          urlDeleteSupervisorTeamFrontManager(
            id,
            this.frontManager.frontManagerId
          )
        )

      switch (true) {
        case this.supervisorAddFront:
          return add(this.frontManager)
        case this.supervisorDeleteFront:
          return del(this.frontManager.id)
      }
    },
  },
  mounted() {
    this.$store.dispatch('addAllUsers')
    Object.assign(this.frontManager, this.dialog.dialogItem)
  },
}
</script>
