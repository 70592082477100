<template>
  <div>
    <SectionLoader v-if="isLoading" />
    <div v-else>
      <div v-if="addEvent || editEvent">
        <v-row>
          <v-col cols="12" class="pt-0 mb-0">
            <span class="label">Заголовок</span>
            <v-text-field
              v-model="event.title"
              :error-messages="nameErr"
              placeholder="Введіть текст"
              class="block-label small-height mt-0"
              hide-details
              dense
              @keyup.enter="submit()"
              @blur="v$.event.title.$touch()">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" class="pb-0 pt-0">
            <span class="label">Тип</span>
            <v-select
              v-model="event.typeId"
              :error-messages="categoryErr"
              class="small-label"
              :items="activityCategories"
              placeholder="Оберіть зі списку"
              :readonly="dialog.params.isCreateMeeting"
              item-title="name"
              item-value="id"
              hide-details
              dense
              @keyup.enter="submit()"
              @blur="v$.event.typeId.$touch()">
            </v-select>
          </v-col>
          <v-col cols="12" md="3" class="pt-0 pb-0">
            <span class="label">Статус</span>
            <v-select
              v-if="event.id"
              v-model="event.statusId"
              class="small-label"
              :items="activityStatuses"
              :error-messages="statusErr"
              item-title="name"
              item-value="id"
              placeholder="Оберіть зі списку"
              hide-details
              dense
              readonly
              @keyup.enter="submit()"
              @blur="v$.event.statusId.$touch()">
            </v-select>
            <v-text-field
              v-if="!event.id"
              value="Новий"
              placeholder="Введіть текст"
              dense
              readonly
              hide-details
              disabled>
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6" class="pb-0 pt-0">
            <span class="label">Учасники</span>
            <v-autocomplete
              v-model="event.invitations"
              class="small-label"
              placeholder="Оберіть зі списку"
              :items="users"
              item-title="initials"
              item-value="id"
              multiple
              deletable-chips
              small-chips
              dense
              hide-details
              @keyup.enter="submit()">
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" class="pb-0 pt-0">
            <span class="label">Дата проведення</span>
            <DatePicker
              v-model="dateStart"
              style="z-index: 10000"
              opens="center"
              placeholder="Оберіть дату"
              :minDate="minDate"
              single />
          </v-col>
          <v-col cols="12" md="3" class="pb-0 pt-0">
            <span class="label">Час початку</span>
            <TimePicker
              v-model="timeStart"
              style="z-index: 10000"
              :disabled="pageLoad"
              placeholder="Оберіть час" />
          </v-col>
          <v-col cols="12" md="3" class="pb-0 pt-0">
            <span class="label">Час завершення</span>
            <TimePicker
              v-model="timeEnd"
              style="z-index: 10000"
              :disabled="pageLoad"
              placeholder="Оберіть час" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div class="d-flex align-center">
              <v-icon class="mr-1">{{ 'mdi-map-marker' }}</v-icon>
              <span class="label">Місце проведення</span>
            </div>
            <div v-if="editEvent && event.address" class="ml-7">
              <span class="link">{{ event.address }}</span>
            </div>
            <v-text-field
              v-else
              v-model="event.address"
              class="block-label pt-0"
              placeholder="Введіть текст"
              hide-details
              dense
              @keyup.enter="submit()"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6" class="pt-4 pb-1">
            <div>
              <link-share-icon
                class="ml-1"
                :width="19"
                :height="19"
                style="vertical-align: sub" />
              &nbsp;
              <span>Зв`язок</span>
              <v-menu offset-y>
                <template #activator="{ props }">
                  <v-btn
                    v-show="activityLinks.length"
                    icon
                    size="small"
                    v-bind="props">
                    <v-icon>{{ 'mdi-plus' }}</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-text class="pt-0 pb-0 pl-0 pr-0">
                    <v-list-item
                      v-for="(item, key) in activityLinks"
                      :key="key"
                      @keyup.enter="submit()"
                      @click="pushToSelectedEntities(item.id)">
                      <v-icon>{{ item.icon }}</v-icon>
                      <v-list-item-title>
                        {{ item.text }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-card-text>
                </v-card>
              </v-menu>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pt-0 pb-0">
            <span class="label">{{ selectEntityLabel }}</span>
            <v-autocomplete
              v-if="
                selectedEntities.includes(2) && !selectedEntities.includes(3)
              "
              v-model="event.contactId"
              :error-messages="managerErr"
              :items="contractorContacts"
              class="small-label"
              item-title="fullName"
              item-value="id"
              hide-details
              :filter="contactFilter"
              placeholder="Оберіть зі списку"
              dense
              @change="
                () => {
                  event.dealerPointOfSaleId = null
                  v$.$reset()
                }
              "
              @keyup.enter="submit()"
              @blur="v$.event.contactId.$touch()">
              <template
                v-if="event.dealerPointOfSaleId || selectedEntities.includes(2)"
                #append>
                <v-btn
                  icon
                  size="small"
                  @click="
                    () => {
                      event.contactId = null
                      deleteFromSelected(2)
                      v$.$reset()
                    }
                  ">
                  <v-icon size="small">{{ 'mdi-close' }}</v-icon>
                </v-btn>
              </template>
            </v-autocomplete>
            <v-autocomplete
              v-if="contactDealerOutlet && !selectedEntities.includes(3)"
              :model-value="contactDealerOutlet"
              :items="selectItems.dealerOutletsList"
              class="small-label"
              item-title="name"
              hide-details
              item-value="id"
              placeholder="Оберіть зі списку"
              dense
              readonly
              disabled>
              <template #append>
                <span></span>
              </template>
            </v-autocomplete>
            <v-autocomplete
              v-if="
                selectedEntities.includes(3) && !selectedEntities.includes(2)
              "
              v-model="event.dealerPointOfSaleId"
              :items="selectItems.dealerOutletsList"
              :error-messages="dealerPointOfSaleIdErr"
              class="small-label"
              item-title="name"
              item-value="id"
              placeholder="Оберіть зі списку"
              dense
              @change="getDealerOutletsBy($event)"
              @keyup.enter="submit()"
              @blur="v$.event.dealerPointOfSaleId.$touch()">
              <template
                v-if="event.dealerPointOfSaleId || selectedEntities.includes(3)"
                #append>
                <v-btn
                  icon
                  size="small"
                  @click="
                    () => {
                      event.dealerPointOfSaleId = null
                      deleteFromSelected(3)
                      contractorContacts.push(...selectItems.contacts)
                      event.contactId = null
                      v$.$reset()
                    }
                  ">
                  <v-icon size="small">{{ 'mdi-close' }}</v-icon>
                </v-btn>
              </template>
            </v-autocomplete>
            <v-autocomplete
              v-if="event.dealerPointOfSaleId && dealerOutletContractor"
              v-model="dealerOutletContractor"
              :items="selectItems.contractors"
              class="small-label mb-2"
              item-title="shortName"
              item-value="id"
              placeholder="Оберіть зі списку"
              dense
              hide-details
              readonly>
              <template #prepend>
                <InputPusher
                  :routeObject="{
                    name: 'contractors-form',
                    params: { id: dealerOutletContractor },
                  }"
                  text="Перейти до контрагента"
                  @click="$store.commit('closeDialog')" />
              </template>
            </v-autocomplete>
            <v-autocomplete
              v-if="event.dealerPointOfSaleId"
              v-model="event.contactId"
              :items="dealerContacts"
              :error-messages="managerErr"
              class="small-label"
              item-title="contact.fullName"
              item-value="contactId"
              hide-details
              placeholder="Оберіть зі списку"
              dense
              @keyup.enter="submit()"
              @blur="v$.event.contactId.$touch()">
            </v-autocomplete>
            <v-autocomplete
              v-if="selectedEntities.includes(1)"
              v-model="event.contractorId"
              :items="selectItems.contractors"
              class="small-label"
              item-title="shortName"
              item-value="id"
              hide-details
              :filter="contractorFilter"
              placeholder="Оберіть зі списку"
              dense
              @change="
                () => {
                  clearContact()
                  getContractorContacts($event)
                  event.dealerPointOfSaleId = null
                  event.contactId = null
                  dealerContacts.splice(0)
                  v$.$reset()
                }
              "
              @keyup.enter="submit()">
              <template
                v-if="event.contractorId || selectedEntities.includes(1)"
                #append>
                <v-btn
                  icon
                  size="small"
                  @click="
                    () => {
                      event.contractorId = null
                      event.contactId = null
                      contractorContacts.splice(0)
                      contractorContacts.push(...selectItems.contacts)
                      deleteFromSelected(1)
                      v$.$reset()
                    }
                  ">
                  <v-icon size="small">{{ 'mdi-close' }}</v-icon>
                </v-btn>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12" sm="12">
            <span class="label">Опис завдання</span>
            <v-textarea
              v-model="event.description"
              :error-messages="descriptionErr"
              class="block-label"
              rows="3"
              hide-details
              dense
              @keyup.enter="submit()"
              @blur="v$.event.description.$touch()">
            </v-textarea>
          </v-col>
          <v-col cols="12" md="12" sm="12">
            <FilesUploader
              v-model:documents="event.files"
              class="mt-4"
              label="Додати файл(и)" />
          </v-col>
        </v-row>
      </div>
      <div v-if="deleteEvent">
        <span style="font-size: 1rem">
          Активнiсть -
          <i style="text-decoration: underline">
            {{ `${dialog.dialogItem.title}` }}
          </i>
          буде видалено
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import DatePicker from '@/components/DatePicker.vue'
import linkShareIcon from '@/assets/svg/link-share.vue'
import FilesUploader from '@/components/FilesUploader.vue'
import TimePicker from '@/components/TimePicker.vue'
import InputPusher from '@/components/InputPusher.vue'
import { humanDate } from '@/utils/formatFunc'
import { required } from '@vuelidate/validators'
import { mapState } from 'vuex'
import {
  backDate,
  contractorFilter,
  contactFilter,
  setErrHandler,
} from '@/utils/helperFunc'

import {
  urlGetActivityCategories,
  urlGetActivityStatuses,
  urlActivityCreate,
  urlGetActivities,
  urlDeleteActivity,
  urlUpdateEvent,
  urlGetAllUsers,
  urlGetContractorContacts,
  urlGetDealerEmployees,
  urlGetDealerPointByContact,
  urlGetContractorByContact,
} from '@/pages/request.js'
import { useVuelidate } from '@vuelidate/core'
import SectionLoader from '@/components/section-loader.vue'
export default {
  components: {
    SectionLoader,
    linkShareIcon,
    FilesUploader,
    TimePicker,
    DatePicker,
    InputPusher,
  },
  setup() {
    return { v$: useVuelidate() }
  },
  props: {
    state: { type: Boolean },
    action: { type: String },
    dialog: { type: Object },
  },
  validations() {
    return { event: this.validationRules }
  },
  data: () => ({
    event: {
      address: null,
      typeId: null,
      dealerPointOfSaleId: null,
      contactId: null,
      startDate: null,
      endDate: null,
      invitations: [],
      description: null,
      contractorId: null,
      statusId: 1,
      resultId: null,
      documents: [],
      files: [],
    },
    isLoading: false,
    string: '',
    filter: false,
    id: null,
    minTime: '8:30',
    maxTime: '22:00',
    dateStart: null,
    dateEnd: null,
    timeStart: null,
    timeEnd: null,
    timeChanged: false,
    selectedEntities: [],
    dialogItem: null,
    pageLoad: false,
    activityCategories: [],
    activityStatuses: [],
    users: [],
    contractorContacts: [],
    dealerContacts: [],
    contactDealerOutlet: null,
    links: [
      { id: 1, text: 'Контрагент', icon: 'mdi-text-account' },
      { id: 2, text: 'Контакт', icon: 'mdi-account' },
      { id: 3, text: 'Торгова точка', icon: 'mdi-account-box-outline' },
    ],
  }),
  computed: {
    ...mapState({
      selectItems: state => state.selectItems,
    }),
    dealerOutletContractor() {
      if (!this.event.dealerPointOfSaleId) return null
      const dealerPoint = this.selectItems.dealerOutletsList.find(
        outlet => outlet.id === this.event?.dealerPointOfSaleId
      )
      return dealerPoint?.contractorId
    },
    activityLinks() {
      const contact = this.selectedEntities.includes(2)
      const dealer = this.selectedEntities.includes(3)
      const contractor = this.selectedEntities.includes(1)

      const filter = arr => this.links.filter(item => !arr.includes(item.id))

      switch (true) {
        case contact && contractor:
          return filter([1, 2, 3])
        case contractor:
          return filter([1, 3])
        case contact:
          return filter([2, 3])
        case dealer:
          return filter([1, 2, 3])
        default:
          return filter(this.selectedEntities)
      }
    },
    validationRules() {
      const dealerPointOfSaleId = this.selectedEntities.includes(3)
        ? { required }
        : {}
      const activity =
        this.addEvent || this.editEvent
          ? {
              title: { required },
              typeId: { required },
              startDate: { required },
              endDate: { required },
              contactId: { required },
              statusId: { required },
              description: { required },
              dealerPointOfSaleId: dealerPointOfSaleId,
            }
          : {}

      return activity
    },
    validation() {
      return { v$: this.v$.event, name: 'activity' }
    },
    nameErr() {
      return setErrHandler(this.v$.event.title)
    },
    categoryErr() {
      return setErrHandler(this.v$.event.typeId)
    },
    startErr() {
      return setErrHandler(this.v$.event.startDate)
    },
    endErr() {
      return setErrHandler(this.v$.event.endDate)
    },
    managerErr() {
      return setErrHandler(this.v$.event.contactId)
    },
    statusErr() {
      return setErrHandler(this.v$.event.statusId)
    },
    descriptionErr() {
      return setErrHandler(this.v$.event.description)
    },
    dealerPointOfSaleIdErr() {
      return setErrHandler(this.v$.event.dealerPointOfSaleId)
    },
    /* dates */
    // strings
    minDate() {
      const date = new Date()
      return new Date(date.setDate(date.getDate() - 1))
        .toISOString()
        .substring(0, 10)
    },
    currentDate() {
      return new Date().toISOString().substr(0, 10)
    },
    currentTime() {
      return new Date().toString().substring(16, 21)
    },
    /* timestamps */
    getStartDateTS() {
      if (!this.event.startDate) return
      if (this.event.startDate.includes('-')) {
        return this.setDate(this.event.startDate)
      }
      return this.event.startDate.substring(0, 10)
    },
    getEndDateTS() {
      if (!this.event.endDate) return
      if (this.event.endDate.includes('-')) {
        return this.setDate(this.event.endDate)
      }
      return this.event.endDate.substring(0, 10)
    },
    getStartTimeTS() {
      if (!this.event.startDate) return
      return this.event.startDate.substring(11, 16)
    },
    getEndTimeTS() {
      if (!this.event.endDate) return
      return this.event.endDate.substring(11, 16)
    },
    // dialog states
    addEvent() {
      return this.action === 'addEvent'
    },
    editEvent() {
      return this.action === 'editEvent'
    },
    deleteEvent() {
      return this.action === 'deleteEvent'
    },
    sameDate() {
      if (!this.dateStart || !this.dateEnd) return false
      return (
        new Date(this.dateStart.split('.').reverse().join('-')).getTime() ===
        new Date(this.dateEnd.split('.').reverse().join('-')).getTime()
      )
    },
    selectEntityLabel() {
      const hasContact = this.selectedEntities.includes(2)
      const hasDealerOutlet = this.selectedEntities.includes(3)
      const hasDealerPointOfSaleId = this.event.dealerPointOfSaleId
      const hasDealerOutletContractor = this.dealerOutletContractor
      const hasContactDealerOutlet = this.contactDealerOutlet

      if (hasContact && !hasDealerOutlet) {
        return 'Контакт'
      }

      if (hasContactDealerOutlet && !hasDealerOutlet) {
        return 'Торговая точка'
      }

      if (hasDealerOutlet && !hasContact) {
        return 'Торговая точка'
      }

      if (hasDealerPointOfSaleId && hasDealerOutletContractor) {
        return 'Контрагент'
      }

      if (hasDealerPointOfSaleId) {
        return 'Контактная особа'
      }

      if (this.selectedEntities.includes(1)) {
        return 'Контрагент'
      }

      return ''
    },
  },
  methods: {
    contactFilter,
    contractorFilter,
    humanDate,
    backDate,
    urlGetActivityCategories,
    urlGetActivityStatuses,
    urlGetContractorContacts,
    urlActivityCreate,
    urlGetActivities,
    urlDeleteActivity,
    urlUpdateEvent,
    urlGetAllUsers,
    urlGetDealerPointByContact,
    urlGetContractorByContact,
    getContactDealer(contactId) {
      return this.$axios
        .get(this.urlGetDealerPointByContact(contactId))
        .then(res => (this.contactDealerOutlet = res.data.id))
    },
    setDate(date) {
      if (date && date.length >= 10 && date.includes('-')) {
        const [y, m, d] = date.substring(0, 10).split('-')
        return `${d}.${m}.${y}` + date.substring(10, 25)
      }
      return date
    },
    getArrItems() {
      this.pageLoad = true
      const getURLs = [
        {
          url: this.urlGetActivityCategories(),
          arr: this.activityCategories,
          cb: arr => arr,
        },
        {
          url: this.urlGetActivityStatuses(),
          arr: this.activityStatuses,
          cb: arr => arr,
        },
        {
          url: this.urlGetAllUsers(),
          arr: this.users,
          cb: arr => arr.map(this.modifyUsers),
        },
      ]

      const data = getURLs.map(v => {
        return this.$axios
          .get(v.url)
          .then(res => v.arr.push(...v.cb(res.data)))
          .catch(this.$err)
      })

      return Promise.all(data).then(() => {
        this.pageLoad = false
      })
    },
    deleteFromSelected(num) {
      const keys = []
      this.selectedEntities.forEach((n, key) => {
        num === n && keys.push(key)
      })
      setTimeout(() => {
        keys.forEach(key => this.selectedEntities.splice(key, 1))
      }, 0)
    },
    pushToSelectedEntities(id) {
      !this.selectedEntities.includes(id) && this.selectedEntities.push(id)
    },
    getDealerOutletsBy(contactId) {
      if (!contactId) return
      return this.$axios
        .get(urlGetDealerEmployees(contactId))
        .then(res => {
          this.dealerContacts.splice(0)
          this.dealerContacts.push(...res.data)
          return res
        })
        .catch(this.$err)
    },
    modifyUsers(user) {
      user.initials = `${user.surname} ${user.name} ${user.patronymic}`
      return user
    },
    triggerEditEvent() {
      return this.$axios
        .post(this.urlUpdateEvent(this.event.id), this.event)
        .then(res => {
          if (res?.data?.message) throw new Error(res?.data?.message)
          this.$setSnackbar({ text: 'Активність оновлено' })
          this.$store.commit('refreshPage')
          return res
        })
        .catch(this.$err)
    },
    activityCreate() {
      return this.$axios
        .post(this.urlActivityCreate(), this.event)
        .then(res => {
          if (res?.data?.message) throw new Error(res?.data?.message)
          this.$setSnackbar({
            text: `Заплановано активність на ${this.event.startDate}`,
          })
          this.$store.commit('refreshPage')
          return res
        })
        .catch(this.$err)
    },
    getResponsible(user) {
      return user.surname + ' ' + user.name
    },
    triggerDeleteEvent() {
      return this.$axios
        .delete(this.urlDeleteActivity(this.event.id))
        .then(() => {
          this.$setSnackbar({ text: 'активність видалено' })
          this.$store.commit('refreshPage')
        })
        .catch(this.$err)
    },
    getContactInfo() {
      if (!this.event.contactId) return
      this.selectedEntities.splice(0)
      this.pushToSelectedEntities(2)
      this.getContractorInfo()
    },
    getDealerInfo(id) {
      this.selectedEntities.push(3)
      return this.getDealerOutletsBy(id)
    },
    getContractorInfo() {
      if (!this.event?.contact?.contractor) {
        this.contractorContacts.push(...this.selectItems.contacts)
        return
      }

      this.getContractorContacts(
        this.event.contractorId || this.event.contact.contractorId
      )
      this.event.contractorId = this.event?.contact?.contractor?.id
    },
    async getContractor(contactId) {
      const { data } = await this.$axios.get(
        this.urlGetContractorByContact(contactId)
      )

      this.getContractorContacts((await data[0]?.id) || null)
    },
    getContractorContacts(contractorId) {
      if (contractorId) {
        return this.$axios
          .get(this.urlGetContractorContacts(contractorId))
          .then(res => {
            this.pushToSelectedEntities(1)
            this.pushToSelectedEntities(2)
            this.event.contractorId = contractorId
            this.contractorContacts.splice(0)
            this.contractorContacts.push(...res.data.map(obj => obj.contact))
            return res
          })
          .catch(this.$err)
      }
    },
    prepareEvent(direction) {
      if (direction === 'toClient') {
        this.dateStart = new Date(this.currentDate)
          .toLocaleString('ru-RU')
          .substring(0, 10)
        this.dateEnd = new Date(this.currentDate)
          .toLocaleString('ru-RU')
          .substring(0, 10)
        this.timeStart = this.currentTime
        this.timeEnd = this.currentTime
        if (this.action === 'addEvent') {
          this.contractorContacts.splice(0)
          this.contractorContacts.push(...this.selectItems.contacts)
          this.event.startDate =
            this.currentDate + ' ' + new Date().toString().substring(16, 21)
          this.event.endDate =
            this.currentDate + ' ' + new Date().toString().substring(16, 21)
          this.event.invitations.splice(0)
          this.event.statusId = 1
        } else if (this.action === 'editEvent') {
          Object.assign(this.event, this.dialog.dialogItem)
          this.timeStart = this.getStartTimeTS
          this.timeEnd = this.getEndTimeTS || this.getStartTimeTS
          this.dateStart = this.getStartDateTS
          this.dateEnd = this.getEndDateTS

          if (this.event.dealerPointOfSaleId) {
            return this.getDealerInfo(this.event.dealerPointOfSaleId)
          } else {
            return this.getContactInfo()
          }
        } else if (this.action === 'deleteEvent') {
          Object.assign(this.event, this.dialog.dialogItem)
        }
      }
      if (direction === 'toServer') {
        this.event.dealerPointOfSaleId =
          this.event.dealerPointOfSaleId || this.contactDealerOutlet
      }
    },
    clearContact() {
      this.event.contactId = null
      this.event.contractor = null
      this.getContractorInfo()
    },
    submit() {
      this.prepareEvent('toServer')
      switch (true) {
        case this.addEvent:
          return this.activityCreate()
        case this.editEvent:
          return this.triggerEditEvent()
        case this.deleteEvent:
          return this.triggerDeleteEvent()
        default:
          throw new Error('No handler')
      }
    },
  },
  watch: {
    dateStart(val) {
      if (!val) return
      this.dateEnd = val
      this.event.startDate = val + ' ' + this.timeStart
      this.event.endDate = val + ' ' + this.timeEnd
    },
    'event.contactId': function (contactId) {
      if (contactId) {
        this.getContactDealer(contactId)
        this.getContractor(contactId)
      }
    },
  },
  mounted() {
    if (!this.dialog?.params?.isCreateMeeting) {
      this.isLoading = true
      setTimeout(() => {
        this.isLoading = false
      }, 5000)
    }
  },
  async created() {
    this.$store.dispatch('addContractors')
    this.$store.dispatch('addDealerOutletList')
    this.$store.dispatch('addContacts')
    await this.getArrItems()
    this.prepareEvent('toClient')
    if (this.dialog?.params?.isCreateMeeting) {
      this.dateStart = new Date()
      this.event.typeId = 2
    }
  },
}
</script>
