<template>
  <v-card :elevation="0">
    <div v-show="routeProjectVerifications">
      <v-fade-transition hide-on-leave>
        <div>
          <Filters :filters="filters" class="ml-2" />
          <SectionLoader
            v-show="asyncLoading('getSecurityServiceAnalyzes')"></SectionLoader>
          <div v-show="!asyncLoading('getSecurityServiceAnalyzes')">
            <v-data-table
              :headers="securityListHeaders"
              :items="securityServiceAnalyzes"
              class="calc-table application-table --cust-cell-pd"
              mobileBreakpoint="750"
              dense
              hide-default-footer
              :items-per-page="-1"
              :hide-default-header="!securityServiceAnalyzes.length"
              @dblclick:row="navigateToAnalysis">
              <!-- eslint-disable-next-line -->
              <template #item.lessee="{ item }">
                <div class="d-flex align-center">
                  <Benefit v-if="item.bestBenefit" class="mr-1" />
                  <ALink
                    :text="item.lesseeName"
                    :route="{
                      name: [1].includes(item.lessee.typeId)
                        ? 'individuals-form'
                        : 'contractors-form',
                      params: {
                        id: item.lessee.id,
                      },
                    }">
                  </ALink>
                </div>
              </template>
              <!-- eslint-disable-next-line -->
              <template #item.status="{ item }">
                <span v-if="item.status && item.status.id === 1"
                  >Не розглянуто</span
                >
                <span v-if="item.status && item.status.id === 2"
                  >На розгляді</span
                >
                <span v-if="item.status && item.status.id === 3"
                  >Доопрацювання</span
                >
                <span v-if="item.status && item.status.id === 4"
                  >Завершено</span
                >
                <span v-if="!item.status">---</span>
              </template>
              <!-- eslint-disable-next-line -->
              <template #item.created_at="{ item }">
                {{ humanDate(formatDate(item.created_at, true)) }}
              </template>
              <!-- eslint-disable-next-line -->
              <template #item.clientResult="{ item }">
                {{ item.result ? item.result.name : '---' }}
              </template>
              <!-- eslint-disable-next-line -->
              <template #item.actions="{ item }">
                <TableCrud
                  style="margin-right: -10px !important"
                  :actionsArray="[
                    {
                      action: () =>
                        $router.push({
                          name: 'ss-project-verification',
                          params: { id: item.id },
                        }),
                      text: 'Вiдкрити',
                      icon: 'mdi-folder-open-outline',
                    },
                  ]">
                </TableCrud>
              </template>
            </v-data-table>
            <Pagination
              :getItems="getSecurityServiceAnalyzes"
              :trigger="paginationTrigger" />
          </div>
        </div>
      </v-fade-transition>
    </div>
    <router-view v-if="!routeProjectVerifications"> </router-view>
  </v-card>
</template>
<script>
import SectionLoader from '@/components/section-loader.vue'

import ALink from '@/components/Link.vue'
import Pagination from '@/components/Pagination.vue'
import { urlGetSecurityServiceAnalyzes } from '@/pages/request'
import { formatDate, humanDate } from '@/utils/formatFunc'
import { getContactInitials } from '@/utils/helperFunc'
import Filters from '@/components/Filters.vue'
import Benefit from '@/assets/svg/benefit.vue'
import { useSelect } from '@/utils/mixins/useSelect'
import { useFilter } from '@/utils/mixins/useFilter'
import { reactive } from 'vue'
import TableCrud from '@/components/table-crud.vue'
import { securityListHeaders } from '@/utils/tableHeaders'
export default {
  components: {
    TableCrud,
    Benefit,
    SectionLoader,
    ALink,
    Pagination,
    Filters,
  },
  setup() {
    const filters = reactive({
      statusId: {
        value: null,
        component: 'v-autocomplete',
        attrs: { label: 'Статус', items: 'securityStatuses' },
      },
      startDate: {
        value: null,
        component: 'DatePicker',
        attrs: { label: 'Період', range: true },
      },
      frontManagerId: {
        value: null,
        component: 'v-autocomplete',
        attrs: {
          label: 'Відповідальний фронт',
          itemTitle: u => getContactInitials(u, { isResponsible: true }),
          items: 'frontManagers',
        },
      },
      search: { value: null },
    })
    return {
      ...useSelect(),
      ...useFilter(filters),
      filters,
    }
  },
  data() {
    return {
      securityServiceAnalyzes: [],
      securityListHeaders,
    }
  },
  computed: {
    routeProjectVerifications() {
      return this.$route.name === 'security-service-verification-list'
    },
  },
  methods: {
    formatDate,
    humanDate,
    urlGetSecurityServiceAnalyzes,
    getSecurityServiceAnalyzes(page = 1) {
      return this.asyncAction('getSecurityServiceAnalyzes', null, () => {
        return this.$axios
          .get(this.urlGetSecurityServiceAnalyzes(page, this.filtersQueryObj))
          .then(res => {
            this.securityServiceAnalyzes.splice(0)
            this.securityServiceAnalyzes.push(...res.data.data)
            return res
          })
          .catch(this.$err)
      })
    },
    navigateToAnalysis(e, row) {
      return this.$router.push({
        name: 'ss-project-verification',
        params: { id: row.item.id },
      })
    },
  },
}
</script>
