<template>
  <v-col :cols="12" :md="3" class="pr-1 pl-0 common-data">
    <div class="mb-3 left-block">
      <span
        class="mb-3 d-block"
        style="color: #08487a; font-weight: 700; font-size: 16px"
        >Загальнi дані</span
      >
      <div class="pt-0">
        <span class="area-label d-block mb-2">ПIБ</span>
        <div class="d-flex align-center">
          <LinkIcon class="mr-1" />
          <ALink
            style="font-size: 14px !important"
            :route="{
              name: 'individuals-form',
              params: { id: agentData.contact.id },
            }"
            :text="agentData.contact.fullName">
          </ALink>
        </div>

        <div>
          <div class="res-block mt-3">
            <div
              class="d-flex align-center"
              style="justify-content: space-between">
              <span class="area-label">Відповідальний</span>
            </div>
            <div class="d-flex align-center">
              <div>
                <span
                  class="res-title"
                  style="color: #09487a; font-size: 14px !important"
                  >{{
                    agentData?.contact?.responsible.fullName || 'Відсутній'
                  }}</span
                >
                <v-tooltip
                  bottom
                  color="grey darken-3"
                  :text="agentData.contact.responsible.post">
                  <template #activator="{ props }">
                    <span
                      class="res-post"
                      style="
                        display: block;
                        width: 270px;
                        font-size: 13px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        color: rgb(131, 130, 130);
                      "
                      v-bind="props"
                      >{{ agentData.contact.responsible.post }}</span
                    >
                  </template>
                  <span style="font-size: 12px">{{
                    agentData.contact.responsible.post
                  }}</span>
                </v-tooltip>
              </div>
            </div>
          </div>
        </div>

        <v-checkbox
          v-model="agentData.isActive"
          dense
          color="red"
          @change="e => e && (agentData.notActiveDate = null)">
          <template #label>
            <span style="font-size: 12px"> Активний </span>
          </template>
        </v-checkbox>
      </div>
    </div>
  </v-col>
</template>
<script>
import ALink from '@/components/Link.vue'
import { getContactInitials } from '@/utils/helperFunc'
import { mapState, mapGetters } from 'vuex'
import LinkIcon from '@/assets/svg/link-icon.vue'
export default {
  components: { LinkIcon, ALink },
  props: {
    agent: { type: Object },
    readOnly: { type: Boolean },
  },
  data: () => {
    return {
      isActive: true,
      notActiveDate: null,
    }
  },
  computed: {
    ...mapState({ selectItems: state => state.selectItems }),
    ...mapGetters({
      activeUsers: 'activeUsers',
    }),
    agentData() {
      return this.agent
    },
  },
  methods: {
    getContactInitials,
  },
  created() {
    this.$store.dispatch('addAllUsers')
  },
}
</script>

<style scoped>
.left-block {
  background: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  padding: 10px;
}
</style>
