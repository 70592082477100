const regex = {
  ru_only: /[а-яА-ЯєіїЄІЇ]/, // only cyrillic letters
  en_only: /[a-zA-Z]/, // only english letters
  ru_not: /[^а-яёА-ЯЁєіїЄІЇ]/, // any symbols expect cyrillic
  number_only: /[0-9]/,
}

function phoneMask(phone, id, params = {}) {
  if (!phone) return
  const { mask = '+38 (0##) ### - ## - ##' } = params
  const el = document.getElementById(id)
  const event = new Event('input', { bubbles: true })
  const sign = '#'
  const numLengthRe = /[^#\d+]/g
  const notDigit = /[^\d]/g
  const firstIndex = mask.indexOf(sign)
  const countryCode = mask.slice(0, firstIndex)
  const numLength = mask.slice(firstIndex).replace(numLengthRe, '').length

  let phoneValue = phone.target ? phone.target.value : phone

  const number = phoneValue.replace(countryCode, '').replace(numLengthRe, '')
  const cCpresent = phoneValue.indexOf(countryCode)
  const splitMask = mask.split('')
  const indexes = []
  splitMask.forEach((val, i) => {
    val === sign ? indexes.push(i) : false
  })
  const fillMask = val => {
    val.split('').forEach((val, i) => {
      if (mask === '+38 (0##) ### - ## - ##' && phoneValue[6] == 0) {
        phoneValue = phoneValue.substring(0, 6) + phoneValue.substring()
      } else {
        indexes[i] ? (splitMask[indexes[i]] = val) : false
      }
    })
  }
  if (number.length >= numLength && cCpresent !== -1 && this.pasteEvent) {
    fillMask(
      phoneValue
        .replace(countryCode, '')
        .replace(numLengthRe, '')
        .slice(number.length - numLength)
    )
  } else if (number.length >= numLength && cCpresent === -1) {
    fillMask(
      phoneValue.replace(numLengthRe, '').slice(number.length - numLength)
    )
  } else if (phoneValue.length > 1) {
    fillMask(phoneValue.slice(firstIndex).replace(notDigit, ''))
  } else if (phoneValue.length <= 1) {
    fillMask(phoneValue.replace(notDigit, ''))
  }

  const joinMask = splitMask.join('').replace(/[^\d]+$/, '')

  el?.value?.length >= 23 && el?.setSelectionRange(23, 23)

  if (el && el.value && el.value !== joinMask) {
    el.value = joinMask
    el.setSelectionRange(splitMask.indexOf(sign), splitMask.indexOf(sign))
    el.dispatchEvent(event)
    return joinMask.substring(0, 23)
  } else {
    return joinMask.substring(0, 23)
  }
}

function emailMask(email, id) {
  if (!email) return
  const el = document.getElementById(id)
  const reg = /^[а-яёії]+$/i
  const event = new Event('input', { bubbles: true })
  const valid = email.target.value
    .split('')
    .map(el => (reg.test(el) ? '' : el))
    .join('')
  if (email.target.value !== valid) {
    el.value = valid
    el.dispatchEvent(event)
  }
}

// restrict length of numeric values
function maxNumLength(id, strictLength) {
  const el = document.getElementById(`${id}`)
  const val = el.value
  let clearVal

  if (el.value) {
    clearVal = val.replace(/[^\d]/g, '')
    clearVal.length >= strictLength // 10
      ? (clearVal = clearVal.substring(0, strictLength))
      : false
  } else return

  if (clearVal !== val) {
    const input = new Event('input', { bubbles: true })
    el.value = clearVal
    el.dispatchEvent(input)
  } else return
}

function trimExceededLength(elId, maxLength, callback) {
  const el = document.getElementById(elId)
  if (!el) return

  const event = new Event('input', { bubbles: true })
  if (el.value && typeof callback === 'function') {
    if (el.value !== callback(el.value)) {
      el.value = callback(el.value)
      el.dispatchEvent(event)
    }
  }
  if (el.value && el.value.length > maxLength) {
    el.value = el.value.substr(0, maxLength)
    el.dispatchEvent(event)
  }
}

function passportNumMask(value, id) {
  if (!value) return
  const el = document.getElementById(id)
  const reg = /^[-]|\d+$/
  const event = new Event('input', { bubbles: true })
  let valid = value
    .split('')
    .map(el => (reg.test(el) ? el : ''))
    .join('')
  if (valid.length === 9 && valid[8] !== '-') {
    valid = `${valid.substring(0, 8)}-${valid[8]}`
  }
  if (valid.length > 14) {
    valid = valid.substring(0, 14)
  }
  if (value !== valid) {
    el.value = valid
    el.dispatchEvent(event)
  }
}

const innMask = {
  mask: '############',
}

const ibanMask = {
  mask: 'UА###########################',
}

const addressIndexMask = {
  mask: '#####',
}

const dateMask = (time, range) => {
  const dateMask = `d#.M#.y###${time ? ', h#:m#' : ''}`
  return {
    mask: range ? [dateMask, dateMask].join(' - ') : dateMask,
    tokens: {
      d: { pattern: /[0-3]/ },
      M: { pattern: /[0-1]/ },
      y: { pattern: /[1-2]/ },
      h: { pattern: /[0-2]/ },
      m: { pattern: /[0-5]/ },
    },
  }
}

export {
  phoneMask,
  maxNumLength,
  trimExceededLength,
  emailMask,
  passportNumMask,
  dateMask,
  innMask,
  ibanMask,
  addressIndexMask,
}
