<template>
  <div
    class="d-flex"
    :class="{ 'gap-4': direction === 'row', 'gap-1': direction === 'column' }"
    :style="{ flexDirection: direction }">
    <div v-for="(field, index) in fields" :key="field.label">
      <slot
        v-if="$slots[`${field.slot || index}_field`]"
        :name="`${field.slot || index}_field`"></slot>
      <div v-else>
        <slot
          v-if="$slots[`${field.slot || index}_label`]"
          :name="`${field.slot || index}_label`"></slot>
        <span v-else :style="styles.label">{{ field.label }}</span>
        <div class="inline-flex align-center">
          <slot
            v-if="$slots[`${field.slot || index}_value`]"
            :name="`${field.slot || index}_value`"
            :style="styles.value"></slot>
          <span
            v-else
            :class="{ disabled: !field.value, ...field.class }"
            :style="{ ...styles.value, ...field.style }"
            @click="() => typeof field.click === 'function' && field.click()">
            {{ field.value || 'Не вказано' }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    column: { type: Number, default: 1 },
    fields: { type: Array, required: true },
    direction: { type: String, default: 'column' },
    type: { type: Number, default: 1 },
  },
  computed: {
    styles() {
      const s = {
        1: {
          label: { fontWeight: '600', marginBottom: '8px' },
        },
        2: {
          label: {
            color: '#838282',
            fontSize: '12px',
            display: 'block',
            fontWeight: '500',
          },
          value: {
            display: 'block',
            maxWidth: '370px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          },
        },
      }

      return s[this.type]
    },
  },
}
</script>

<style lang="scss" scoped></style>
