<template>
  <div style="margin-left: 15px">
    <span class="font-weight-bold mb-3 d-block mt-3">
      Договір фінансового лізингу:
    </span>
    <div v-for="c in contracts" :key="c.id" class="agr-block">
      <v-icon color="#09487a">mdi-file-document</v-icon>
      <ALink
        :route="{
          name: 'dfl-contract',
          params: { id: c.id },
        }"
        :text="c.number" />
    </div>
    <div v-if="!contracts?.length" class="text-disabled text-center mt-5">
      ДФЛ відсутні
    </div>
  </div>
</template>

<script>
import ALink from '@/components/Link.vue'

export default {
  name: 'ServiceСonnections',
  components: { ALink },
  props: {
    contracts: { type: Array },
  },
}
</script>

<style scoped>
.agr-block {
  width: 200px;
  height: 30px;
  border-bottom: 1px solid #09487a45;
  margin-bottom: 10px;
}
.agr-block:hover v-icon {
  color: red;
}
</style>
