<template>
  <v-row>
    <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
      <span class="label">Тип</span>
      <v-autocomplete
        v-model="manager.managerId"
        placeholder="Оберіть зі списку"
        :items="directoryItems('allUsers', manager.manager)"
        :item-title="u => getContactInitials(u, { isResponsible: true })"
        item-value="id"
        hide-details
        :loading="asyncLoading('addAllUsers')"
        :error-messages="setErrHandler(v$.manager.typeId)"
        @focus="asyncAction('addAllUsers')"
        @blur="v$.manager.managerId.$touch()"></v-autocomplete>
    </v-col>

    <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
      <span class="label">Ціль</span>
      <v-text-field
        v-model="manager.value"
        placeholder="Введіть значення"
        :error-messages="setErrHandler(v$.manager.value)"
        hide-details
        @input="
          inputNumber($event, {
            float: dialog.dialogItem.planValueTypeId === 1 ? 0 : 2,
          })
        "
        @blur="
          $event => {
            inputNumber($event, {
              float: dialog.dialogItem.planValueTypeId === 1 ? 0 : 2,
              saveFloat: true,
            })
            v$.manager.value.$touch()
          }
        "></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import { setErrHandler, getContactInitials } from '@/utils/helperFunc'
import { required } from '@vuelidate/validators'
import { inputNumber, keepDigit } from '@/utils/formatFunc'
import {
  urlCreatePlanManagersValue,
  urlUpdatePlanManagersValue,
} from '@/pages/request'
import { cloneDeep } from 'lodash'
import { mapGetters } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import { useSelect } from '@/utils/mixins/useSelect'

export default {
  name: 'PlanManager',
  setup() {
    return { v$: useVuelidate(), ...useSelect() }
  },
  props: {
    dialog: {
      type: Object,
    },
  },
  validations() {
    return {
      manager: {
        managerId: { required },
        value: { required },
      },
    }
  },
  data: () => {
    return {
      manager: {
        planValueId: null,
        managerId: null,
        value: null,
      },
    }
  },
  computed: {
    validation() {
      return { v$: this.v$.manager, name: 'plan' }
    },
    ...mapGetters({
      activeUsers: 'activeUsers',
    }),
  },
  methods: {
    setErrHandler,
    getContactInitials,
    inputNumber,
    submit() {
      const url = (() => {
        switch (this.dialog.action) {
          case 'createPlanManagersValue':
            return urlCreatePlanManagersValue
          case 'updatePlanManagersValue':
            return urlUpdatePlanManagersValue
          default:
            throw new Error('invalid action')
        }
      })()
      const request = cloneDeep(this.manager)
      request.value = parseFloat(keepDigit(request.value))
      return this.$axios.post(url(this.manager.id), request)
    },
  },
  created() {
    if (this.dialog.action === 'createPlanManagersValue') {
      this.asyncAction('addAllUsers')
    }
    Object.assign(this.manager, this.dialog.dialogItem.manager)
  },
}
</script>
