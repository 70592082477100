<template>
  <div>
    <DashboardNavigation @year-counter="yearCounter" />
    <v-row>
      <v-col cols="12" md="12" sm="12">
        <div class="dashboard-block">
          <div id="chart">
            <apexchart
              type="line"
              height="350"
              :options="chartProjectOptions"
              :series="projectSeries"></apexchart>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DashboardNavigation from '@/pages/dashboard/components/DashboardNavigation.vue'
export default {
  components: { DashboardNavigation },
  emits: ['yearCounter'],
  props: {
    graphs: { type: Object },
  },
  data: () => ({
    chartProjectOptions: {
      noData: {
        text: 'Загрузка...',
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val
          },
        },
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return value
          },
        },
      },
      title: {
        text: 'Проекти',
        align: 'left',
        fontSize: 16,
        fontWeight: 500,
      },
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'],
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: [
          'Січ',
          'Лют',
          'Бер',
          'Квіт',
          'Трав',
          'Черв',
          'Лип',
          'Серп',
          'Вер',
          'Жов',
          'Лист',
          'Груд',
        ],
      },
    },
  }),
  computed: {
    projectSeries() {
      const monthsCount = 12
      const zeroPaddedArray = Array.from({ length: monthsCount }).fill(0)

      const projects = Object.values(this.graphs.projectsPerMonth)
        .map(month => month.quantity)
        .concat(zeroPaddedArray)
        .slice(0, monthsCount)

      return [
        {
          name: 'Проекти',
          data: projects,
        },
      ]
    },
  },
  methods: {
    yearCounter(e) {
      this.$emit('yearCounter', e)
    },
  },
}
</script>

<style scoped></style>
