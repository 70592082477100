/**
 * Accounting advantages (Static)
 *
 */

const operativeAccountingAdvantages = () => {
  return [
    [
      {
        text: [
          {
            text: 'Пакет',
            margin: [0, 4, 0, 0],
          },
          {
            text: ' "Стандарт" ',
            margin: [0, 4, 0, 0],
            bold: true,
            color: '#d7514f',
          },
          {
            text: 'включає',
            margin: [0, 4, 0, 0],
          },
        ],
        margin: [0, 20, 0, 6],
        fontSize: 15,
        bold: true,
      },
    ],
    {
      columns: [
        {
          fit: [11, 11],
          width: '3.9%',
          margin: [0, 0, 0, 7],
          image: 'checkIcon',
        },
        {
          fontSize: 11,
          width: '*',
          text: 'Технiчний асистанс BEST 24/7',
          margin: [0, 2, 0, 0],
        },
      ],
    },
    {
      columns: [
        {
          fit: [11, 11],
          width: '3.9%',
          margin: [0, 0, 0, 7],
          image: 'checkIcon',
        },
        {
          fontSize: 11,
          width: '*',
          text: 'Планове технiчне обслуговування (ТО) та його адмiнiстрування',
          margin: [0, 2, 0, 0],
        },
      ],
    },
    [
      {
        text: [
          {
            text: 'Пакет',
            margin: [0, 4, 0, 0],
          },
          {
            text: ' "Бiзнес" ',
            margin: [0, 4, 0, 0],
            bold: true,
            color: '#d7514f',
          },
          {
            text: 'включає',
            margin: [0, 4, 0, 0],
          },
        ],
        margin: [0, 40, 0, 6],
        fontSize: 15,
        bold: true,
      },
    ],
    {
      columns: [
        {
          fit: [11, 11],
          width: '3.9%',
          margin: [0, 0, 0, 7],
          image: 'checkIcon',
        },
        {
          fontSize: 11,
          width: '*',
          text: 'Наповнення пакету "Cтандарт"',
          margin: [0, 2, 0, 0],
        },
      ],
    },
    {
      columns: [
        {
          fit: [11, 11],
          width: '3.9%',
          margin: [0, 0, 0, 7],
          image: 'checkIcon',
        },
        {
          fontSize: 11,
          width: '*',
          text: 'Додаткове ТО',
          margin: [0, 2, 0, 0],
        },
      ],
    },
    {
      columns: [
        {
          fit: [11, 11],
          width: '3.9%',
          margin: [0, 0, 0, 7],
          image: 'checkIcon',
        },
        {
          fontSize: 11,
          width: '*',
          lineHeight: 1.5,
          text: 'Замiна деталей природнього зносу (гальмiвнi колодки/гальмiвнi диски/ходова пiдвiска/щитки склоочисникiв/АКБ/рульова)',
          margin: [0, 2, 0, 0],
        },
      ],
    },
    {
      columns: [
        {
          fit: [11, 11],
          width: '3.9%',
          margin: [0, 0, 0, 7],
          image: 'checkIcon',
        },
        {
          fontSize: 11,
          width: '*',
          lineHeight: 1.5,
          text: 'Долив технiчних рiдин до норми: моторна олива/охолоджувальна рiдина/гальмiвна рiдина (окрiм склоомивача)',
          margin: [0, 2, 0, 0],
        },
      ],
    },
    [
      {
        text: [
          {
            text: 'Пакет',
            margin: [0, 4, 0, 0],
          },
          {
            text: ' "Премiум" ',
            margin: [0, 4, 0, 0],
            bold: true,
            color: '#d7514f',
          },
          {
            text: 'включає',
            margin: [0, 4, 0, 0],
          },
        ],
        margin: [0, 40, 0, 6],
        fontSize: 15,
        bold: true,
      },
    ],
    {
      columns: [
        {
          fit: [11, 11],
          width: '3.9%',
          margin: [0, 0, 0, 7],
          image: 'checkIcon',
        },
        {
          fontSize: 11,
          width: '*',
          text: 'Наповнення пакету "Бiзнес"',
          margin: [0, 2, 0, 0],
        },
      ],
    },
    {
      columns: [
        {
          fit: [11, 11],
          width: '3.9%',
          margin: [0, 0, 0, 7],
          image: 'checkIcon',
        },
        {
          fontSize: 11,
          width: '*',
          lineHeight: 1.5,
          text: 'Агрегатнi ремонти внаслiдок природнього зносу: ДВЗ/КПШ/роздавальна коробка/редуктори',
          margin: [0, 2, 0, 0],
        },
      ],
    },
  ]
}

export { operativeAccountingAdvantages }
