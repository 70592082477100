<template>
  <div>
    <Contractor
      v-if="isContractor"
      :contractor="scoringFin"
      :client="client"
      :scoringData="scoring"
      :desiredFactor="desiredFactor" />
    <Fop v-if="fop" :fop="scoringPerson" :scoringData="scoring" />
    <Person
      v-if="isPerson"
      :person="scoringPerson"
      :client="client"
      :scoringData="scoring" />
    <v-row style="background: white; padding-left: 13px; padding-right: 13px">
      <v-col :cols="12" :sm="3" class="pt-1 pb-1"> </v-col>
      <v-col :cols="12" :sm="9" class="pt-1 pb-1 text-start">
        <div class="card-label">📜 Коментар при створеннi рiшення:</div>
        <span class="card-label">
          {{ scoring?.comment || '---' }}
        </span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Person from '@/pages/projects-verification/scoring/client/person.vue'
import Fop from '@/pages/projects-verification/scoring/client/fop.vue'
import Contractor from '@/pages/projects-verification/scoring/client/contractor.vue'
import { useSelect } from '@/utils/mixins/useSelect'

export default {
  name: 'Client',
  components: { Contractor, Fop, Person },
  setup() {
    return {
      ...useSelect(),
    }
  },
  props: {
    scoring: { type: Object },
  },
  computed: {
    isPerson() {
      return this.scoring.leasingClientTypeId === 1
    },
    fop() {
      return this.scoring.leasingClientTypeId === 3
    },
    isContractor() {
      return this.scoring.leasingClientTypeId === 2
    },
    scoringFin() {
      if (this.client.contractorData?.registry) {
        return this.client.contractorData?.registry || {}
      } else {
        return this.client || {}
      }
    },
    client() {
      return this.scoring.leasingClient || {}
    },
    desiredFactor() {
      return this.client.contractorData?.factors.find(
        f => f.factorGroup === 'tax'
      )
    },
    scoringPerson() {
      return this.client?.contactData?.registry || {}
    },
  },
}
</script>

<style scoped></style>
