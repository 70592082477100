<template>
  <v-row>
    <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
      <FilesUploader
        v-model:documents="questionnaire.questionnaire"
        label="Опитувальник BEST BENEFITS"
        required
        single
        accepted-ext=".xlsx, .xls"
        @update:documents="isParse = false" />
    </v-col>

    <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
      <FilesUploader
        v-model:documents="questionnaire.questionnaireSigned"
        label="Опитувальник (підписаний)"
        single
        accepted-ext=".pdf" />
    </v-col>

    <v-col
      v-if="questionnaire.questionnaire && !isParse"
      cols="12"
      md="12"
      sm="12"
      class="pt-0 pb-0">
      <v-btn
        style="background: #fc7247 !important; color: white !important"
        class="btn-parse mt-3"
        small
        @click="parse">
        Розпарсити
      </v-btn>
    </v-col>

    <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
      <BenefitsParseInfo
        v-if="parseInfo"
        :info="parseInfo"
        @agreed="handleParseAgreed"
        @reject="handleParseReject" />
    </v-col>
  </v-row>
</template>

<script>
import FilesUploader from '@/components/FilesUploader.vue'
import { required } from '@vuelidate/validators'
import {
  urlCreateBenefitsQuestionnaire,
  urlUpdateBenefitsQuestionnaire,
  urlParseBenefitsQuestionnaire,
} from '@/pages/request'
import BenefitsParseInfo from '@/components/BenefitsParseInfo.vue'
import { cloneDeep } from 'lodash'
import { useVuelidate } from '@vuelidate/core'

export default {
  name: 'BenefitsQuestionnaire',
  components: { BenefitsParseInfo, FilesUploader },
  setup() {
    return { v$: useVuelidate() }
  },
  props: {
    dialog: {
      type: Object,
    },
  },
  validations() {
    return {
      questionnaire: {
        questionnaire: { required },
      },
      isParse: { required, isParsed: Boolean },
    }
  },
  data: () => {
    return {
      questionnaire: {
        sliderId: null,
        questionnaire: null,
        questionnaireSigned: null,
        isMain: true,
      },
      sliderId: null,
      parseInfo: null,
      isParse: true,
    }
  },
  computed: {
    createBenefitsQuestionnaire() {
      return this.dialog.action === 'createBenefitsQuestionnaire'
    },
    updateBenefitsQuestionnaire() {
      return this.dialog.action === 'updateBenefitsQuestionnaire'
    },
    validation() {
      return { v$: this.v$, name: 'benefitsQuestionnaire' }
    },
  },
  methods: {
    parse() {
      const req = {
        filePath: this.questionnaire.questionnaire[0].url,
        clientTypeId: this.dialog.dialogItem.clientTypeId,
      }
      return this.$axios
        .post(urlParseBenefitsQuestionnaire(), req)
        .then(res => {
          if (
            res?.data?.message ||
            res.data.status === 423 ||
            res.data.status === 424
          ) {
            return this.$setSnackbar({
              text: res.data.message,
              color: 'warning',
            })
          }
          this.parseInfo = res.data
        })
        .catch(error => {
          if (
            (error.response && error.response.status === 423) ||
            (error.response && error.response.status === 424)
          ) {
            this.$setSnackbar({
              text: error.response.data.message,
              color: 'warning',
            })
          }
        })
    },
    handleParseReject() {
      this.questionnaire.questionnaire = null
      this.isParse = false
      this.parseInfo = null
    },
    handleParseAgreed() {
      this.isParse = true
    },
    submit() {
      const req = cloneDeep(this.questionnaire)
      if (this.createBenefitsQuestionnaire) {
        req.sliderId = this.sliderId
      }
      const url = this.createBenefitsQuestionnaire
        ? urlCreateBenefitsQuestionnaire()
        : urlUpdateBenefitsQuestionnaire(this.questionnaire.id)

      return this.$axios
        .post(url, {
          ...req,
          questionnaireJson: this.parseInfo,
        })
        .catch(this.$err)
    },
  },
  created() {
    this.sliderId = this.dialog.dialogItem.sliderId
    const questionnaire = this.dialog.dialogItem.questionnaire || {}
    Object.assign(this.questionnaire, questionnaire)
  },
}
</script>
