<template>
  <v-card flat>
    <v-fade-transition v-if="listRoute" hide-on-leave>
      <div>
        <div class="d-flex justify-space-between align-center mb-4">
          <Filters :filters="filters" class="ml-2" />
          <v-btn
            class="mr-3"
            color="#fc7247"
            variant="flat"
            disabled
            @click="createDirectory">
            <v-icon style="margin-right: 5px; color: #fff !important">
              {{ 'mdi-plus' }} </v-icon
            >Додати Довідник</v-btn
          >
        </div>
        <v-fade-transition hide-on-leave>
          <div>
            <SectionLoader v-if="loading" />
            <div>
              <v-data-table
                v-show="!loading"
                :headers="directoriesHeader"
                :items="directories"
                class="calc-table --cust-cell-pd"
                hide-default-footer
                :items-per-page="-1"
                :hide-default-header="!directories.length"
                @dblclick:row="navigateToLog">
                <!-- eslint-disable-next-line -->
                <template #item.tableName="{ item }">
                  {{ item.tableName || '---' }}
                </template>
                <!-- eslint-disable-next-line -->
                <template #item.name="{ item }">
                  {{ item.name || '---' }}
                </template>
                <!-- eslint-disable-next-line -->
                <template #item.field="{ item }">
                  {{ item.field || '---' }}
                </template>
                <!-- eslint-disable-next-line -->
                <template #item.action="{ item }">
                  <TableCrud
                    :actionsArray="[
                      {
                        action: () => pushToDirectory(item),
                        text: 'Вiдкрити',
                        icon: 'mdi-folder-open-outline',
                      },
                      {
                        action: () => deleteDirectory(item),
                        text: 'Видалити',
                        icon: 'DeleteIcon',
                        customIcon: true,
                      },
                    ]">
                  </TableCrud>
                </template>
              </v-data-table>
              <Pagination :getItems="getLogs" :trigger="paginationTrigger" />
            </div>
          </div>
        </v-fade-transition>
      </div>
    </v-fade-transition>
    <router-view v-else></router-view>
    <SectionLoader v-if="loading"></SectionLoader>
  </v-card>
</template>
<script>
import SectionLoader from '@/components/section-loader.vue'
import { toFormatDate, getContactInitials } from '@/utils/helperFunc'
import Pagination from '@/components/Pagination.vue'
import {
  urlDeleteAdminDirectory,
  urlGetAdminDirectories,
} from '@/pages/request'
import Filters from '@/components/Filters.vue'
import { useFilter } from '@/utils/mixins/useFilter'
import { reactive } from 'vue'
import TableCrud from '@/components/table-crud.vue'
export default {
  components: { TableCrud, Filters, SectionLoader, Pagination },
  setup() {
    const filters = reactive({
      tableName: {
        value: null,
        component: 'v-text-field',
        attrs: {
          label: 'Назва таблицi',
        },
      },
    })
    return {
      ...useFilter(filters),
      filters,
    }
  },
  data() {
    return {
      directoriesHeader: [
        {
          title: 'Назва таблицi',
          key: 'tableName',
          sortable: false,
          align: 'start',
        },
        { title: 'Поле', key: 'field', sortable: false, align: 'start' },
        { title: 'Назва', key: 'name', sortable: false, align: 'start' },
        { title: 'Дії', key: 'action', sortable: false, align: 'end' },
      ],
      directories: [],
      loading: false,
    }
  },
  computed: {
    listRoute() {
      return this.$route.name === 'administration-directories'
    },
  },
  methods: {
    getContactInitials,
    toFormatDate,
    pushToDirectory(item) {
      this.$router.push({ name: 'directory-single', params: { id: item.id } })
    },
    getLogs(page = 1) {
      this.loading = true

      return this.$axios
        .get(urlGetAdminDirectories(page, this.filtersQueryObj))
        .then(v => {
          this.directories = v.data.data
          this.loading = false
          return v
        })
        .catch(err => this.$err(err, () => (this.loading = false)))
    },
    navigateToLog(e, row) {
      this.pushToDirectory(row.item)
    },
    createDirectory() {
      this.$store.commit('setDialog', {
        title: 'Створити Довідник',
        dialogItem: null,
        action: 'create-admin-directory',
      })
    },
    deleteDirectory(item) {
      return this.$axios.delete(urlDeleteAdminDirectory(item.id)).then(() => {
        this.$setSnackbar({ text: 'Довідник видалено' })
        this.getLogs()
      })
    },
  },
}
</script>
