<template>
  <div>
    <div class="d-flex justify-space-between mb-3">
      <div class="d-flex gap-3">
        <div>
          <span class="text--disabled">Тип плану:</span>
          {{ planValue.type.name }}
        </div>
        <div>
          <span class="text--disabled">Всього:</span>
          {{
            planValue.value
              ? getBeautyNum(planValue.value, {
                  float: planValue.typeId === 1 ? 0 : 2,
                })
              : 0
          }}
        </div>
      </div>
      <div class="pr-3">
        <TableCrud
          icon="mdi-cog"
          :actions-array="
            [
              {
                text: 'Редагувати тип плану',
                icon: 'EditPencil',
                customIcon: true,
                action: updatePlanValueType,
              },
              {
                text: 'Видалити план',
                icon: 'DeleteIcon',
                customIcon: true,
                action: deletePlanValue,
              },
              {
                text: 'Додати план менеджеру',
                icon: 'mdi-plus',
                action: createPlanManagersValue,
              },
            ].filter((a, idx) => {
              return isAdmin || idx === 2
            })
          " />
      </div>
    </div>
    <v-data-table
      hide-default-footer
      :items="planValue.managers_values || []"
      :items-per-page="-1"
      :headers="managersHeaders">
      <template #item.manager="{ item }">
        <span>
          {{ getContactInitials(item.manager, { isResponsible: true }) }}
        </span>
      </template>
      <template #item.value="{ item }">
        <b>{{
          getBeautyNum(item.value, { float: planValue.typeId === 1 ? 0 : 2 })
        }}</b>
      </template>
      <template #item.actions="{ item }">
        <TableCrud
          :actions-array="[
            {
              text: 'Редагувати',
              icon: 'EditPencil',
              customIcon: true,
              action: () => updatePlanManagersValue(item),
            },
            {
              text: 'Видалити',
              icon: 'DeleteIcon',
              customIcon: true,
              action: () => deletePlanManagersValue(item.id),
            },
          ]"></TableCrud>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import TableCrud from '@/components/table-crud.vue'
import { getContactInitials, getBeautyNum } from '@/utils/helperFunc'
import { urlDeletePlanManagersValue, urlDeletePlanValue } from '@/pages/request'
import { mapState } from 'vuex'

export default {
  emits: ['set-plan'],
  name: 'PlanValue',
  components: { TableCrud },
  props: {
    planValue: {
      type: Object,
    },
    plan: {
      type: Object,
    },
  },
  data() {
    return {
      managersHeaders: [
        { title: 'ПІБ', key: 'manager' },
        { title: 'План', key: 'value' },
        {
          title: 'Дії',
          key: 'actions',
          align: 'end',
        },
      ],
    }
  },
  computed: {
    ...mapState({
      isAdmin: state => state.user.roleId,
    }),
  },
  methods: {
    getContactInitials,
    getBeautyNum,
    updatePlanValueType() {
      this.$store.commit('setDialog', {
        action: 'updatePlanValueType',
        title: 'Редагувати тип плану',
        dialogItem: {
          planValue: this.planValue,
          plan: this.plan,
        },
        params: {
          cb: res => {
            this.$emit('set-plan', res.data)
          },
        },
      })
    },
    createPlanManagersValue() {
      this.$store.commit('setDialog', {
        action: 'createPlanManagersValue',
        title: 'Додати план',
        dialogItem: {
          manager: { planValueId: this.planValue.id },
          planValueTypeId: this.planValue.typeId,
        },
        params: {
          cb: res => {
            this.$emit('set-plan', res.data)
          },
        },
      })
    },
    updatePlanManagersValue(manager) {
      this.$store.commit('setDialog', {
        action: 'updatePlanManagersValue',
        title: 'Редагувати план',
        dialogItem: {
          manager,
          planValueTypeId: this.planValue.typeId,
        },
        params: {
          cb: res => {
            this.$emit('set-plan', res.data)
          },
        },
      })
    },
    deletePlanManagersValue(planManagerValueId) {
      return this.$axios
        .delete(urlDeletePlanManagersValue(planManagerValueId))
        .then(res => {
          this.$emit('set-plan', res.data)
        })
    },
    deletePlanValue() {
      return this.$axios
        .delete(urlDeletePlanValue(this.planValue.id))
        .then(res => {
          this.$emit('set-plan', res.data)
        })
    },
  },
}
</script>
