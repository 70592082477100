<template>
  <v-dialog
    v-model="innerState"
    :max-width="syncWithOpenData ? 1100 : 380"
    :value="dialog"
    persistent>
    <div class="dialog-card" style="background: white">
      <div class="pr-3 pl-3">
        <div
          style="
            height: 60px;
            display: flex;
            justify-content: space-between;
            align-items: center;
          ">
          <div
            style="
              color: rgb(8, 72, 122);
              font-weight: 700 !important;
              font-size: 16px;
            ">
            <span v-show="editFrontManager">
              {{ 'змiнити Front - менеджера' }}
            </span>
            <span v-show="editMiddleManager">
              {{ 'змiнити Middle - менеджера' }}
            </span>
            <span v-show="syncWithOpenData">
              <v-icon size="28">
                {{ 'mdi-alert-circle-outline' }}
              </v-icon>
              {{ 'ЕДР' }}
            </span>
            <span v-show="addBankAccount || editBankAccount">
              {{ 'Банкiвський рахунок' }}
            </span>
            <span v-show="addCareer || editCareer">
              {{ "Кар'єра" }}
            </span>
            <span v-show="addEducation || editEducation"> Освiта </span>
            <span
              v-show="
                addContractor ||
                editContractor ||
                createMainContractor ||
                deleteMainContractor ||
                addMainContractor
              ">
              {{ 'Контрагент' }}
            </span>
          </div>
          <v-btn size="small" icon @click="innerState = !innerState">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="26"
              viewBox="0 0 26 26"
              fill="none">
              <path
                d="M25 13C25 6.375 19.625 1 13 1C6.375 1 1 6.375 1 13C1 19.625 6.375 25 13 25C19.625 25 25 19.625 25 13Z"
                stroke="#C0C1C7"
                stroke-width="2"
                stroke-miterlimit="10"></path>
              <path
                d="M17 17L9 9M9 17L17 9"
                stroke="#C0C1C7"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"></path>
            </svg>
          </v-btn>
        </div>
      </div>
      <v-card-text class="pb-2 pt-0">
        <div v-if="addBankAccount || editBankAccount">
          <span class="area-label">№ рахунку (IBAN)</span>
          <v-text-field
            id="cont-b-a"
            v-model="bankAccount.accountNumber"
            v-mask:[ibanMask]
            :error-messages="accountNumberErr"
            placeholder="Введіть номер"
            dense
            hide-details
            @keyup.enter="submit()"
            @blur="v$.bankAccount.accountNumber.$touch()">
          </v-text-field>
          <span class="area-label">МФО</span>
          <v-text-field
            id="contact-account-mfo"
            v-model="bankAccount.mfo"
            :error-messages="interbranchNumErr"
            placeholder="МФО"
            dense
            hide-details
            readonly
            @keyup.enter="submit()"
            @blur="v$.bankAccount.mfo.$touch()">
            <template #append>
              <span></span>
            </template>
          </v-text-field>
          <span class="area-label">Банк</span>
          <v-text-field
            id="contact-account-name"
            v-model="bankAccount.bankName"
            :error-messages="bankNameErr"
            placeholder="Банк"
            dense
            hide-details
            readonly
            @keyup.enter="submit()"
            @blur="v$.bankAccount.bankName.$touch()">
          </v-text-field>
          <span class="area-label">Тип рахунку</span>
          <v-select
            v-model="bankAccount.type.id"
            :error-messages="bankAccountTypeIdErr"
            :items="directoryItems('bankAccountTypes', bankAccount.type)"
            item-title="name"
            item-value="id"
            placeholder="Тип рахунку"
            :loading="asyncLoading('addBankAccountTypes')"
            dense
            hide-details
            @keyup.enter="submit()"
            @change="bankAccount.isBasic = false"
            @blur="v$.bankAccount.type.$touch()"
            @focus="asyncAction('addBankAccountTypes')">
            <template #append-item
              ><Loader v-if="asyncLoading('addBankAccountTypes')"
            /></template>
          </v-select>
          <v-checkbox
            v-if="currentAccount"
            v-model="bankAccount.isBasic"
            class="mt-0 mb-3"
            color="rgb(210, 74, 67)"
            hide-details
            dense
            @keyup.enter="submit()">
            <template #label>
              <span style="font-size: 0.8rem"> Основний рахунок </span>
            </template>
          </v-checkbox>
          <span class="area-label">Валюта</span>
          <v-select
            v-model="bankAccount.currency.id"
            :error-messages="currencyErr"
            placeholder="Оберiть зi списку"
            :items="directoryItems('currencies', bankAccount.currency)"
            item-title="name"
            item-value="id"
            dense
            hide-details
            @keyup.enter="submit()"
            @blur="v$.bankAccount.currency.$touch()">
          </v-select>
        </div>

        <v-row v-if="addCareer || editCareer">
          <v-col cols="12" md="12" sm="12" class="pb-0 pt-0">
            <span class="label d-block mb-1">Контрагент</span>
            <v-autocomplete
              v-model="career.contractorId"
              :error-messages="contractorIdErr"
              :items="directoryItems('contractors', career.contractor)"
              :filter="contractorFilter"
              item-title="shortName"
              item-value="id"
              placeholder="Оберiть зi списку"
              :loading="asyncLoading('addContractors')"
              dense
              hide-details
              @blur="v$.career.contractorId.$touch()"
              @focus="asyncAction('addContractors')">
              <template #append-item
                ><Loader v-if="asyncLoading('addContractors')"
              /></template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="12" sm="12" class="pb-0 pt-0">
            <span class="label d-block mb-1">Посада</span>
            <v-autocomplete
              v-model="career.postId"
              :error-messages="postIdErr"
              :items="directoryItems('careerPosts', career.post)"
              item-title="name"
              item-value="id"
              placeholder="Оберiть зi списку"
              :loading="asyncLoading('addCareerPosts')"
              dense
              hide-details
              @blur="v$.career.postId.$touch()"
              @focus="asyncAction('addCareerPosts')"
              @change="
                () => {
                  $emit('position', $event)
                  career.fullPostName = selectItems.careerPosts.find(
                    post => post.id === $event
                  ).name
                }
              ">
              <template #append-item
                ><Loader v-if="asyncLoading('addCareerPosts')"
              /></template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="12" sm="12" class="pb-0 pt-0">
            <span class="label d-block mb-1">Повна назва посади</span>
            <v-text-field
              v-model="career.fullPostName"
              :error-messages="fullPostNameErr"
              placeholder="Повна назва посади"
              dense
              hide-details
              @blur="v$.career.fullPostName.$touch()"
              @input="$emit('fullPostName', $event)">
            </v-text-field>
          </v-col>
          <v-col cols="12" md="12" sm="12" class="pb-0 pt-0">
            <span class="label d-block mb-1">Департамент</span>
            <v-autocomplete
              v-model="career.departmentId"
              :error-messages="departmentIdErr"
              :items="directoryItems('careerDepartments', career.department)"
              item-title="name"
              item-value="id"
              placeholder="Оберiть зi списку"
              :loading="asyncLoading('addCareerDepartments')"
              dense
              hide-details
              @blur="v$.career.departmentId.$touch()"
              @change="$emit('department', $event)"
              @focus="asyncAction('addCareerDepartments')">
              <template #append-item
                ><Loader v-if="asyncLoading('addCareerDepartments')"
              /></template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="12" sm="12" class="pb-0 pt-0">
            <span class="label d-block mb-1">Роль</span>
            <v-autocomplete
              v-model="career.roleId"
              :error-messages="roleIdErr"
              :items="directoryItems('careerRoles', career.role)"
              item-title="name"
              item-value="id"
              placeholder="Оберiть зi списку"
              :loading="asyncLoading('addCareerRoles')"
              dense
              hide-details
              @blur="v$.career.roleId.$touch()"
              @change="$emit('role', $event)"
              @focus="asyncAction('addCareerRoles')">
              <template #append-item
                ><Loader v-if="asyncLoading('addCareerRoles')"
              /></template>
            </v-autocomplete>
          </v-col>
        </v-row>

        <div v-if="syncWithOpenData" class="pb-4" style="font-size: 0.88rem">
          <edr-contact-mapper :contact="ODContact"> </edr-contact-mapper>
        </div>
        <div v-if="deleteMainContractor">
          <p>
            <strong> Контрагентa буде вiдкрiплено вiд контакту </strong>
          </p>
        </div>
      </v-card-text>
      <v-card-actions class="justify-space-around pb-2 mt-5">
        <v-btn
          style="
            height: 33px;
            color: rgb(8, 72, 122);
            width: 45%;
            font-weight: 700;
            background: white;
            border: 1px solid rgb(8, 72, 122);
            border-radius: 4px;
          "
          :height="33"
          small
          @click="innerState = !innerState">
          ВIДХИЛИТИ
        </v-btn>
        <v-btn
          style="
            height: 33px;
            color: white;
            font-weight: 700;
            width: 45%;
            background: rgb(8, 72, 122);
            border-radius: 4px;
          "
          :height="33"
          small
          @click="submit()">
          ЗБЕРЕГТИ
        </v-btn>
      </v-card-actions>
    </div>
  </v-dialog>
</template>
<script>
import edrContactMapper from './edr-contact-mapper.vue'
import ObjectMapper from 'object-mapper'
import { mapState } from 'vuex'
import { required, minLength } from '@vuelidate/validators'
import { ibanMask, maxNumLength } from '@/utils/masks'
import { getMfoFromIban } from '@/utils/helperFunc'
import {
  urlCreateBankAccount,
  urlUpdateBankAccount,
  urlCreateContactAddress,
  urlUpdateContactAddress,
  urlContactCreateCareer,
  urlContactUpdateCareer,
  urlContactUpdate,
  urlEDRCompanyByEdrpou,
  urlCreateContactContractor,
  urlDeleteContactContractor,
} from '@/pages/request.js'
import {
  checkAccount,
  trimExceededLength,
  setErrHandler,
  v$Notify,
  contractorFilter,
} from '@/utils/helperFunc.js'
import Loader from '@/components/Loader.vue'
import { useVuelidate } from '@vuelidate/core'
import { useSelect } from '@/utils/mixins/useSelect'

export default {
  components: { edrContactMapper, Loader },
  emits: [
    'disableBankAccountStatuses',
    'tableChange',
    'openDataSync',
    'deleteContractor',
    'contactUpdate',
    'update:dialog',
    'department',
    'position',
    'fullPostName',
    'role',
  ],
  setup() {
    return { v$: useVuelidate(), ...useSelect(), ibanMask }
  },
  props: {
    contact: { type: Object },
    dialog: { type: Boolean, default: false },
    dialogItem: { type: Object },
    dialogState: { type: String },
    dialogParams: { type: Object, default: () => {} },
    ODContact: { type: Object },
  },
  validations() {
    const mainContractor =
      this.createMainContractor || this.addMainContractor
        ? {
            postId: { required },
            fullPostName: { required },
            departmentId: { required },
            roleId: { required },
          }
        : {}

    const contactContractor =
      this.addContractor ||
      this.editContractor ||
      this.createMainContractor ||
      this.addMainContractor
        ? {
            contractor: {
              edrpou: (() => {
                if (this.addMainContractor) {
                  return true
                } else
                  return {
                    required,
                    minLength: minLength(8),
                  }
              })(),
              name: (() => {
                if (this.addMainContractor) {
                  return true
                } else return { required }
              })(),
              ...mainContractor,
            },
          }
        : {}

    const bankFields =
      this.addBankAccount || this.editBankAccount
        ? {
            bankAccount: {
              bankName: { required },
              mfo: { required },
              accountNumber: { required, minLength: minLength(29) },
              type: {
                id: { required },
              },
              currency: {
                id: { required },
              },
            },
          }
        : {}

    const career =
      this.addCareer || this.editCareer
        ? {
            career: {
              contractorId: { required },
              fullPostName: { required },
              postId: { required },
              departmentId: { required },
              roleId: { required },
            },
          }
        : {}

    return {
      ...bankFields,
      ...career,
      ...contactContractor,
    }
  },
  data: () => ({
    bankAccount: {
      typeId: null,
      accountNumber: null,
      bankName: null,
      mfo: null,
      currency: {
        id: null,
      },
      isBasic: 0,
      contactId: null,
      type: {
        id: null,
      },
    },
    renderComponent: true,
    career: {
      contractorId: null,
      fullPostName: null,
      postId: null,
      departmentId: null,
      roleId: null,
      contactId: null,
      isSignatureRight: false,
    },
    contractor: {
      edrpou: null,
      name: null,
      postId: null,
      fullPostName: null,
      departmentId: null,
      roleId: null,
      contractorId: null,
    },
    commonErr: 'Обов`язкове поле',
    innerState: false,
    syncWithDataBot: false,
    edrpouLoading: false,
    educationLevels: [
      { name: 'Початкова загальна освіта', id: 1 },
      { name: 'Базова загальна середня освіта', id: 2 },
      { name: 'Повна загальна середня освіта', id: 3 },
      { name: 'Професійно-технічна освіта', id: 4 },
      { name: 'Базова вища освіта', id: 5 },
      { name: 'Повна вища освіта', id: 6 },
    ],
  }),
  computed: {
    ...mapState({
      selectItems: state => state.selectItems,
    }),
    currentAccount() {
      return this.bankAccount?.type?.id === 1
    },
    imutableCurrAccount() {
      return this.bankAccount?.type?.id === 1
    },
    availableAccountTypes() {
      return this.checkAccount(this)
    },
    // Validations
    bankNameErr() {
      return setErrHandler(this.v$.bankAccount.bankName)
    },
    interbranchNumErr() {
      return setErrHandler(this.v$.bankAccount.mfo)
    },
    bankAccountTypeIdErr() {
      return setErrHandler(this.v$.bankAccount.type)
    },
    currencyErr() {
      return setErrHandler(this.v$.bankAccount.currency)
    },
    contractorIdErr() {
      return setErrHandler(this.v$.career.contractorId)
    },
    fullPostNameErr() {
      return setErrHandler(this.v$.career.fullPostName)
    },
    postIdErr() {
      return setErrHandler(this.v$.career.postId)
    },
    departmentIdErr() {
      return setErrHandler(this.v$.career.departmentId)
    },
    roleIdErr() {
      return setErrHandler(this.v$.career.roleId)
    },
    contractorCompanyNameErr() {
      return setErrHandler(this.v$.contractor.shortName)
    },
    contractorPostIdErr() {
      return setErrHandler(this.v$.contractor.postId)
    },
    contractorFullPostNameErr() {
      return setErrHandler(this.v$.contractor.fullPostName)
    },
    contractorDepartmentIdErr() {
      return setErrHandler(this.v$.contractor.departmentId)
    },
    contractorRoleIdErr() {
      return setErrHandler(this.v$.contractor.roleId)
    },
    edrpouErr() {
      const errors = []
      if (!this.v$.contractor.edrpou.$error) return []

      if (this.v$.contractor.edrpou.required.$invalid) {
        errors.push(this.commonErr)
      }
      if (this.v$.contractor.edrpou.minLength.$invalid) {
        errors.push('8 чисел')
      }

      return errors
    },
    accountNumberErr() {
      const errors = []
      if (!this.v$.bankAccount.accountNumber.$error) return errors

      if (this.v$.bankAccount.accountNumber.minLength.$invalid) {
        errors.push('Невiрний номер IBAN')
      }
      if (this.v$.bankAccount.accountNumber.required.$invalid) {
        errors.push(this.commonErr)
      }

      return errors
    },
    // Dialog states
    addBankAccount() {
      return this.dialogState === 'addBankAccount'
    },
    editBankAccount() {
      return this.dialogState === 'editBankAccount'
    },
    deleteBankAccount() {
      return this.dialogState === 'deleteBankAccount'
    },
    editFrontManager() {
      return this.dialogState === 'editFrontManager'
    },
    editMiddleManager() {
      return this.dialogState === 'editMiddleManager'
    },
    deleteMainContractor() {
      return this.dialogState === 'deleteMainContractor'
    },
    addMainContractor() {
      return this.dialogState === 'addMainContractor'
    },
    // Career
    addCareer() {
      return this.dialogState === 'addCareer'
    },
    editCareer() {
      return this.dialogState === 'editCareer'
    },
    addContractor() {
      return this.dialogState === 'addContractor'
    },
    editContractor() {
      return this.dialogState === 'editContractor'
    },
    syncWithOpenData() {
      return this.dialogState === 'syncWithOpenData'
    },
    createMainContractor() {
      return this.dialogState === 'createMainContractor'
    },
    addEducation() {
      return this.dialogState === 'addEducation'
    },
    editEducation() {
      return this.dialogState === 'editEducation'
    },
    contactId() {
      return parseInt(this.$route.params.id)
    },
  },
  methods: {
    contractorFilter,
    checkAccount,
    ObjectMapper,
    urlCreateContactAddress,
    urlUpdateContactAddress,
    urlContactCreateCareer,
    urlContactUpdateCareer,
    urlContactUpdate,
    maxNumLength,
    urlEDRCompanyByEdrpou,
    urlCreateContactContractor,
    urlDeleteContactContractor,
    trimExceededLength,
    getMfoFromIban,
    async getBank(event) {
      this.trimExceededLength('cont-b-a', 29)
      const { mfo = null, name = null } = await this.getMfoFromIban(event)
      this.bankAccount.bankName = name
      this.bankAccount.mfo = mfo
    },
    async submit() {
      if (this.v$.$invalid) {
        this.v$.$anyError
        this.v$.$touch()
        v$Notify(
          {
            ...(this.v$.career || {}),
            ...(this.v$.bankAccount || {}),
            ...(this.v$.contractor || {}),
          },
          'contactDialog'
        )
        return
      }

      if (this.editFrontManager) this.innerState = !this.innerState
      else if (this.editMiddleManager) this.innerState = !this.innerState
      else if (this.addBankAccount) {
        this.innerState = false
        this.bankAccount.contactId = this.contactId
        await this.createBankAccount()
        this.$emit('tableChange')
      } else if (this.editBankAccount) {
        this.innerState = false
        this.bankAccount.contactId = this.contactId
        await this.updateBankAccount()
        this.$emit('tableChange')
      } else if (this.addCareer) {
        this.innerState = false
        this.career.contactId = this.contactId
        await this.createCareer()
        this.$emit('tableChange')
      } else if (this.editCareer) {
        this.innerState = false
        this.career.contactId = this.contactId
        await this.updateCareer()
        this.$emit('tableChange')
      } else if (this.syncWithOpenData) {
        this.innerState = false
        this.$emit('openDataSync')
      } else if (this.deleteMainContractor) {
        this.innerState = false
        this.deleteContactContractor('Контрагента вiдкрiплено')
      } else if (this.addMainContractor) {
        this.innerState = false
        this.createContractor(this.contractorId)
      } else if (this.createMainContractor) {
        this.innerState = false
        this.createContractor()
      } else if (this.addEducation) {
        this.innerState = false
        this.createEducation()
      } else if (this.editEducation) {
        this.innerState = false
        this.updateEducation()
      }
    },
    disableBankAccountStatuses() {
      this.bankAccount.isBasic
        ? this.$emit('disableBankAccountStatuses', this.bankAccount.accountId)
        : ''
    },
    createBankAccount() {
      const req = {
        ...this.bankAccount,
        typeId: this.bankAccount.type.id,
        currencyId: this.bankAccount.currency.id,
        entityId: this.contactId,
        entityTypeId: 1,
      }
      return this.$axios
        .post(urlCreateBankAccount(), req)
        .then(res => {
          this.$setSnackbar({ text: 'Рахунок додано' })
          return res
        })
        .catch(this.$err)
    },
    updateBankAccount() {
      const req = {
        ...this.bankAccount,
        typeId: this.bankAccount.type.id,
        currencyId: this.bankAccount.currency.id,
        entityId: this.contactId,
        entityTypeId: 1,
      }
      return this.$axios
        .post(urlUpdateBankAccount(this.bankAccount.id), req)
        .then(res => {
          this.$setSnackbar({ text: 'Рахунок оновлено' })
          return res
        })
        .catch(this.$err)
    },
    clearObjectFields(object) {
      for (const key in object) {
        if (typeof object[key] === 'boolean') {
          object[key] = false
          continue
        }
        if (typeof object[key] === 'object') {
          this.clearObjectFields(object[key])
          continue
        }
        object[key] = null
      }
    },
    createCareer() {
      return this.$axios
        .post(this.urlContactCreateCareer(), this.career)
        .then(res => {
          this.$setSnackbar({ text: "Кар'єру додано" })
          return res
        })
        .catch(this.$err)
    },
    updateCareer() {
      return this.$axios
        .post(this.urlContactUpdateCareer(this.career.id), this.career)
        .then(res => {
          this.$setSnackbar({ text: "Кар'єру оновлено" })
          return res
        })
        .catch(this.$err)
    },
    clearDialogFields() {
      this.clearObjectFields(this.bankAccount)
      this.bankAccount.isBasic = false
      this.clearObjectFields(this.career)
    },
    openDataSync(e) {
      e && this.getCompatyByEdrpou(this.contractor.edrpou)
    },
    getCompatyByEdrpou(edrpou) {
      return new Promise(resolve => {
        if (!this.edrpouLoading && this.contractor.edrpou?.length === 8) {
          this.edrpouLoading = true
          this.$axios
            .get(this.urlEDRCompanyByEdrpou(edrpou))
            .then(res => {
              this.edrpouLoading = false
              this.syncWithDataBot = true
              this.contractor.shortName = res.data.data.registry.shortName
              resolve()
            })
            // eslint-disable-next-line
            .catch(_ => {
              this.edrpouLoading = false
              setTimeout(() => {
                this.syncWithDataBot = false
              }, 0)
              this.$setSnackbar({
                text: `Не вдалося отримати данi компанії - ${edrpou}`,
              })
              resolve()
            })
        } else {
          setTimeout(() => {
            this.syncWithDataBot = false
          }, 0)
          return resolve()
        }
      })
    },
    deleteContactContractor(message) {
      this.$emit('deleteContractor')
      return this.$axios
        .get(this.urlDeleteContactContractor(this.contact.contactId))
        .then(() => {
          this.$setSnackbar({ text: message })
          this.$emit('contactUpdate')
        })
        .catch(this.$err)
    },
    createContractor(contractorId) {
      contractorId && (this.contractor.contractorId = contractorId)
      return this.$axios
        .post(this.urlCreateContactContractor(this.contactId), this.contractor)
        .then(() => this.$emit('contactUpdate'))
        .catch(err => {
          this.$setSnackbar({
            text: err.response.data?.errors?.edrpou[0],
            color: 'error',
          })
        })
    },
    createEducation() {
      this.$setSnackbar({ text: 'no create handler', color: 'warning' })
    },
    updateEducation() {
      this.$setSnackbar({ text: 'no update handler', color: 'warning' })
    },
  },
  watch: {
    'bankAccount.accountNumber': function (accountNumber) {
      this.getBank(accountNumber)
    },
    innerState(val) {
      if (val) {
        if (this.addBankAccount) {
          this.asyncAction(['addBankAccountTypes'])
        }
        if (this.addCareer) {
          this.asyncAction([
            'addContractors',
            'addCareerPosts',
            'addCareerDepartments',
            'addCareerRoles',
          ])
        }
        if (this.editBankAccount || this.addBankAccount) {
          Object.assign(this.bankAccount, Object.clone(this.dialogItem))
        }
        if (this.editCareer)
          Object.assign(this.career, Object.clone(this.dialogItem))
      } else {
        this.clearDialogFields()
      }
      this.$emit('update:dialog', val)
    },
    dialog(val) {
      this.innerState = val
      this.v$.$reset()
    },
    'career.isSignatureRight': function (val) {
      if (!val) this.career.basedOnTheSignature = null
    },
  },
}
</script>
