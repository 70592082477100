<template>
  <v-card-text class="pt-0">
    <SectionLoader v-if="pageLoad" />
    <div v-if="!pageLoad && faqOtherListRoute">
      <v-row>
        <v-col :cols="12" :sm="4" class="pt-0 pb-0">
          <v-text-field
            v-show="others.length"
            v-model="search"
            class="pt-0 mt-0"
            hide-details
            placeholder="Пошук"
            dense>
          </v-text-field>
        </v-col>
        <v-col
          :cols="12"
          :sm="8"
          class="pt-0 pb-0 d-flex align-center justify-end">
          <v-btn
            v-show="others.length"
            class="text-white"
            text
            small
            :ripple="false"
            style="background: rgb(252, 114, 71); border-radius: 4px"
            @click="openDialog">
            Додати
          </v-btn>
        </v-col>
      </v-row>
      <v-data-table
        :headers="tHead"
        :items="others"
        class="calc-table --cust-cell-pd mt-3"
        mobileBreakpoint="750"
        dense
        :hide-default-footer="!others.length"
        :hide-default-header="!others.length"
        :search="search">
        <!-- eslint-disable-next-line -->
        <template #no-data>
          <EmptySection
            :icon="EmptyFile"
            title="Роздiл тимчасово порожнiй"
            :iconWidth="38"
            :iconHeight="38"
            @click="openDialog" />
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.actions="{ item }">
          <TableCrud
            :actionsArray="[
              {
                action: () => openDeleteDialog(item),
                text: 'Видалити',
                icon: 'DeleteIcon',
                customIcon: true,
              },
            ]">
          </TableCrud>
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.name="{ item }">
          <ALink
            :text="item.name"
            :route="{ name: 'watch-faq-other', params: { id: item.id } }">
          </ALink>
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.created_at="{ item }">
          {{ formatDate(item.created_at) }}
        </template>
      </v-data-table>
    </div>
    <RouterView v-if="!faqOtherListRoute" />
  </v-card-text>
</template>
<script>
import ALink from '@/components/Link.vue'

import EmptySection from '@/components/empty-section.vue'
import EmptyFile from '@/assets/svg/empty-file.vue'
import SectionLoader from '@/components/section-loader.vue'
import { formatDate } from '@/utils/formatFunc'
import TableCrud from '@/components/table-crud.vue'
export default {
  components: {
    TableCrud,
    ALink,
    EmptySection,
    // eslint-disable-next-line
    EmptyFile,
    SectionLoader,
  },
  data: () => ({
    search: null,
    pageLoad: false,
    others: [],
    tHead: [
      {
        title: 'Назва',
        key: 'name',
        align: 'start',
        sortable: false,
        width: '70%',
      },
      { title: 'Дата', key: 'created_at', align: 'center', sortable: true },
      { title: 'Дії', key: 'actions', align: 'end', sortable: false },
    ],
    EmptyFile,
  }),
  computed: {
    faqOtherListRoute() {
      return this.$route.name === 'admin-faq-others'
    },
  },
  methods: {
    formatDate,
    openDialog() {
      return this.$setSnackbar({ text: 'В розробцi!' })
    },
    openDeleteDialog(item) {
      return item
    },
  },
}
</script>
