<template>
  <div>
    <v-row v-if="editContractorDirectior || addContractorContactDirectior">
      <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
        <span class="label d-block mb-1">Контакт</span>
        <v-autocomplete
          v-model="director.directorContact"
          :error-messages="directorContactErr"
          :items="directoryItems('contacts', dialog.dialogItem.contact)"
          item-title="fullName"
          item-value="id"
          placeholder="Оберіть зі списку"
          :disabled="editContractorDirectior"
          @focus="asyncAction('addContacts')"
          @blur="v$.director.directorContact.$touch()">
        </v-autocomplete>
      </v-col>
      <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
        <span class="label d-block mb-1">Посада</span>
        <v-select
          v-model="director.directorPostId"
          :items="
            directoryItems(
              'contractorDirectorPosts',
              dialog.dialogItem.directorPost
            )
          "
          :error-messages="directorPostIdErr"
          item-title="name"
          item-value="id"
          placeholder="Оберіть зі списку"
          :disabled="deleteContractorDirector"
          :readonly="deleteContractorDirector"
          @focus="asyncAction('addContractorDirectorPosts')"
          @blur="v$.director.directorPostId.$touch()">
        </v-select>
      </v-col>
    </v-row>

    <v-row v-if="addContractorDirector">
      <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
        <span class="label d-block mb-1">Прізвище</span>
        <v-text-field
          v-model="director.surname"
          placeholder="Введіть текст"
          :error-messages="surnameErr"
          :disabled="deleteContractorDirector"
          :readonly="deleteContractorDirector"
          @blur="
            () => {
              v$.director.surname.$touch()
              checkNameSurname()
            }
          ">
        </v-text-field>
      </v-col>
      <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
        <span class="label d-block mb-1">Ім`я</span>
        <v-text-field
          v-model="director.name"
          placeholder="Введіть текст"
          :error-messages="nameErr"
          :disabled="deleteContractorDirector"
          :readonly="deleteContractorDirector"
          @blur="
            () => {
              v$.director.name.$touch()
              checkNameSurname()
            }
          ">
        </v-text-field>
      </v-col>
      <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
        <span class="label d-block mb-1">По батькові</span>
        <v-text-field
          v-model="director.patronymic"
          placeholder="Введіть текст"
          :error-messages="patronymicErr"
          :disabled="deleteContractorDirector"
          :readonly="deleteContractorDirector"
          @blur="v$.director.patronymic.$touch()">
        </v-text-field>
      </v-col>
      <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
        <span class="label d-block mb-1">Посада</span>
        <v-select
          v-model="director.directorPostId"
          :items="
            directoryItems(
              'contractorDirectorPosts',
              dialog.dialogItem.directorPost
            )
          "
          :error-messages="directorPostIdErr"
          item-title="name"
          item-value="id"
          placeholder="Оберіть зі списку"
          :disabled="deleteContractorDirector"
          :readonly="deleteContractorDirector"
          @focus="asyncAction('addContractorDirectorPosts')"
          @blur="v$.director.directorPostId.$touch()">
        </v-select>
      </v-col>
    </v-row>

    <v-row v-if="deleteContractorDirector">
      <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
        <span class="label d-block mb-1">Прізвище</span>
        <v-text-field
          :model-value="dialog.dialogItem.contact.surname"
          placeholder="Введіть текст"
          disabled>
        </v-text-field>
      </v-col>
      <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
        <span class="label d-block mb-1">Ім`я</span>
        <v-text-field
          :model-value="dialog.dialogItem.contact.name"
          placeholder="Введіть текст"
          disabled>
        </v-text-field>
      </v-col>
      <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
        <span class="label d-block mb-1">По батькові</span>
        <v-text-field
          :model-value="dialog.dialogItem.contact.patronymic"
          placeholder="Введіть текст"
          disabled>
        </v-text-field>
      </v-col>
      <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
        <span class="label d-block mb-1">Посада</span>
        <v-select
          :model-value="dialog.dialogItem.directorPostId"
          :items="
            directoryItems(
              'contractorDirectorPosts',
              dialog.dialogItem.directorPost
            )
          "
          item-title="name"
          item-value="id"
          placeholder="Оберіть зі списку"
          disabled
          @focus="asyncAction('addContractorDirectorPosts')">
        </v-select>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { required } from '@vuelidate/validators'
import { mapState } from 'vuex'
import {
  urlCreateContractorDirectorContact,
  urlCreateContractorDirector,
  urlDeleteContractorDirector,
} from '@/pages/request'

import {
  setErrHandler,
  contactFilter,
  checkDuplicates,
} from '@/utils/helperFunc'
import { maxNumLength } from '@/utils/masks'
import { useVuelidate } from '@vuelidate/core'
import { useSelect } from '@/utils/mixins/useSelect'
export default {
  setup() {
    return { v$: useVuelidate(), ...useSelect() }
  },
  props: { dialog: { type: Object } },
  validations() {
    if (this.addContractorDirector) {
      return {
        director: {
          surname: { required },
          name: { required },
          patronymic: { required },
          directorPostId: { required },
        },
      }
    } else if (
      this.addContractorContactDirectior ||
      this.editContractorDirectior
    ) {
      return {
        director: {
          directorContact: { required },
          directorPostId: { required },
        },
      }
    } else if (this.deleteContractorDirector) {
      return { director: {} }
    }
  },
  data: () => ({
    directorContact: null,
    directorPostId: null,
    duplicatesLoading: false,
    director: {
      surname: null,
      name: null,
      patronymic: null,
      directorContact: null,
      directorPostId: null,
    },
  }),
  computed: {
    ...mapState({ selectItems: state => state.selectItems }),
    addContractorContactDirectior() {
      return this.dialog.action === 'addContractorContactDirectior'
    },
    addContractorDirector() {
      return this.dialog.action === 'addContractorDirector'
    },
    editContractorDirectior() {
      return this.dialog.action === 'editContractorDirectior'
    },
    validation() {
      return { v$: this.v$.director, name: 'contractorDirector' }
    },
    deleteContractorDirector() {
      return this.dialog.action === 'deleteContractorDirector'
    },
    contractorId() {
      return this.$store.state?.dialog?.params?.contractorId
    },
    directorContactErr() {
      return this.setErrHandler(this.v$.director.directorContact)
    },
    directorPostIdErr() {
      return this.setErrHandler(this.v$.director.directorPostId)
    },
    maxDate() {
      return new Date(new Date().setFullYear(new Date().getFullYear() - 15))
        .toISOString()
        .split('T')[0]
    },
    surnameErr() {
      return this.setErrHandler(this.v$.director.surname)
    },
    nameErr() {
      return this.setErrHandler(this.v$.director.name)
    },
    patronymicErr() {
      return this.setErrHandler(this.v$.director.patronymic)
    },
  },
  methods: {
    contactFilter,

    urlCreateContractorDirector,
    urlCreateContractorDirectorContact,
    urlDeleteContractorDirector,
    setErrHandler,
    maxNumLength,
    checkDuplicates,
    checkNameSurname() {
      if (this.director.surname && this.director.name) {
        this.duplicatesLoading = true
        checkDuplicates(`${this.director.surname} ${this.director.name}`).then(
          () => (this.duplicatesLoading = false)
        )
      }
    },
    submit() {
      if (this.addContractorDirector) {
        return this.createDirector()
      } else if (
        this.addContractorContactDirectior ||
        this.editContractorDirectior
      ) {
        return this.createContactDirector()
      } else if (this.deleteContractorDirector) {
        return this.deleteDirector()
      }
    },
    createDirector() {
      return this.$axios.post(
        this.urlCreateContractorDirector(this.contractorId),
        this.director
      )
    },
    async createContactDirector() {
      const director = {
        contactId: this.director.directorContact,
        directorPostId: this.director.directorPostId,
      }
      return this.$axios.post(
        this.urlCreateContractorDirectorContact(this.contractorId),
        director
      )
    },
    deleteDirector() {
      return this.$axios.delete(
        this.urlDeleteContractorDirector(this.director.id)
      )
    },
  },
  mounted() {
    Object.assign(this.director, this.dialog.dialogItem)
  },
}
</script>
