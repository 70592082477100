<template>
  <div style="min-height: 200px">
    <v-card-text>
      <v-data-table
        :headers="tHead"
        :items="repurchase"
        class="calc-table --cust-cell-pd"
        mobileBreakpoint="750"
        dense
        hide-default-footer
        :hide-default-header="!repurchase.length"
        :items-per-page="1000">
        <!-- eslint-disable-next-line -->
        <template #item.cost-buying-property="{ item }">
          {{ formutSum(item['cost-buying-property']) }}
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.title="{ item }">
          {{ humanDate(formatDate(item.title, true)) }}
        </template>
        <!-- eslint-disable-next-line -->
      </v-data-table>
    </v-card-text>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { formatDate, humanDate, formutSum } from '@/utils/formatFunc'
import { getCurrencySign } from '@/utils/helperFunc'
export default {
  props: { contract: { type: Object } },
  computed: {
    ...mapState({
      repurchase: state =>
        state.contracts.dkpContract?.calculation?.exportData?.repurchase || [],
      currency: state =>
        state.contracts.dkpContract?.calculation?.requestData?.leasingCurrency?.toLowerCase(),
    }),
    currencyTxt() {
      const currencySign = getCurrencySign((this.currency || '').toUpperCase())
      return this.currency === 'uah' ? 'ГРН' : currencySign
    },
    tHead() {
      return [
        { title: '№', key: 'n', align: 'start', sortable: false },
        {
          title: 'Місяць в якому здійснюється оплата',
          key: 'title',
          align: 'center',
          sortable: false,
        },
        {
          title: `Вартість викупу Майна, т.ч. ПДВ 20%, ${this.currencyTxt}`,
          key: 'cost-buying-property',
          align: 'center',
          sortable: false,
        },
        // { text: 'Дії', value: 'actions', align: 'end', sortable: false },
      ]
    },
  },
  methods: { formatDate, humanDate, formutSum },
}
</script>
