<template>
  <v-card flat>
    <v-fade-transition v-if="listRoute" hide-on-leave>
      <div>
        <Filters :filters="filters" class="ml-2" />
        <v-fade-transition hide-on-leave>
          <div>
            <SectionLoader v-if="loading" />
            <div>
              <v-data-table
                v-show="!loading"
                :headers="loggHeader"
                :items="logs"
                :custom-sort="$sortByDate"
                class="calc-table --cust-cell-pd"
                hide-default-footer
                :items-per-page="-1"
                :hide-default-header="!logs.length"
                @dblclick:row="navigateToLog">
                <!-- eslint-disable-next-line -->
                <template #item.phone="{ item }">
                  {{ item.phone }}
                </template>
                <!-- eslint-disable-next-line -->
                <template #item.user="{ item }">
                  {{ item.user || '---' }}
                </template>
                <!-- eslint-disable-next-line -->
                <template #item.created_at="{ item }">
                  {{ toFormatDate(item.created_at) }}
                </template>
                <!-- eslint-disable-next-line -->
                <template #item.action="{ item }">
                  <TableCrud
                    :actionsArray="[
                      {
                        action: () => pushToLog(item),
                        text: 'Вiдкрити',
                        icon: 'mdi-folder-open-outline',
                      },
                    ]">
                  </TableCrud>
                </template>
              </v-data-table>
              <Pagination :getItems="getLogs" :trigger="paginationTrigger" />
            </div>
          </div>
        </v-fade-transition>
      </div>
    </v-fade-transition>
    <router-view v-else></router-view>
    <SectionLoader v-if="loading"></SectionLoader>
  </v-card>
</template>
<script>
import SectionLoader from '@/components/section-loader.vue'
import { toFormatDate, getContactInitials } from '@/utils/helperFunc'
import Pagination from '@/components/Pagination.vue'
import { urlGetAdminLogs } from '@/pages/request'
import { mapState, mapGetters } from 'vuex'
import Filters from '@/components/Filters.vue'
import { useFilter } from '@/utils/mixins/useFilter'
import { reactive } from 'vue'
import TableCrud from '@/components/table-crud.vue'
export default {
  components: { TableCrud, Filters, SectionLoader, Pagination },
  setup() {
    const filters = reactive({
      startDate: {
        value: null,
        component: 'DatePicker',
        attrs: { label: 'Період', range: true },
      },
      typeId: {
        value: null,
        component: 'v-autocomplete',
        attrs: { label: 'Тип', items: 'logsType' },
      },
      tableName: {
        value: null,
        component: 'v-autocomplete',
        attrs: {
          label: 'Назва таблицi',
          items: 'logsTableName',
          itemTitle: 'table',
          itemValue: 'table',
        },
      },
      loggableId: {
        value: null,
        component: 'v-text-field',
        attrs: { label: 'LogId' },
      },
      userId: {
        value: null,
        component: 'v-autocomplete',
        attrs: {
          label: 'Відповідальний',
          itemTitle: u => getContactInitials(u, { isResponsible: true }),
          items: 'activeUsers',
        },
        focus: { action: 'addAllUsers' },
      },
      search: { value: null },
    })
    return {
      ...useFilter(filters),
      filters,
    }
  },
  data() {
    return {
      loggHeader: [
        { title: 'Дата', key: 'created_at', sortable: false, align: 'start' },
        { title: 'Тип', key: 'type.name', sortable: false, align: 'start' },
        {
          title: 'Назва таблицi',
          key: 'tableName',
          sortable: false,
          align: 'start',
        },
        {
          title: 'Відповідальний',
          key: 'user',
          sortable: false,
          align: 'start',
        },
        { title: 'Дії', key: 'action', sortable: false, align: 'end' },
      ],
      logs: [],
      loading: false,
      logRules: [v => (v && v >= 1) || 'LogId повинен бути не менше 1'],
    }
  },
  computed: {
    ...mapState({
      allUsers: state => state.selectItems.allUsers,
      logsTableName: state => state.selectItems.logsTableName,
      logsType: state => state.selectItems.logsType,
    }),
    ...mapGetters({
      activeUsers: 'activeUsers',
    }),
    listRoute() {
      return this.$route.name === 'logging'
    },
    tableNames() {
      return this.logsTableName.map(t => t.table)
    },
  },
  methods: {
    getContactInitials,
    toFormatDate,
    pushToLog(item) {
      this.$router.push({ name: 'logging-details', params: { id: item.id } })
    },
    getLogs(page = 1) {
      this.loading = true
      const modifyLogs = logs => {
        return logs.data?.map(log => {
          log.userId = log.userId || '---'
          log.typeId = log.typeId || '---'
          log.user = getContactInitials(log.user, { isResponsible: true })
          return log
        })
      }

      return this.$axios
        .get(urlGetAdminLogs(page, this.filtersQueryObj))
        .then(v => {
          this.logs.splice(0)
          this.logs.push(...modifyLogs(v.data))
          this.loading = false
          return v
        })
        .catch(err => this.$err(err, () => (this.loading = false)))
    },
    navigateToLog(e, row) {
      this.pushToLog(row.item)
    },
  },
}
</script>
