<template>
  <div>
    <v-row>
      <v-col cols="12" md="12" sm="12">
        <span class="label">Назва</span>
        <v-text-field
          v-model="supervisor.name"
          placeholder="Введіть текст"
          :error-messages="nameErr"
          :readonly="deleteSupervisor"
          hide-details
          :disabled="deleteSupervisor"
          @blur="v$.supervisor.name.$touch()">
        </v-text-field>
      </v-col>
      <v-col cols="12" md="12" sm="12">
        <span class="label">Супервайзер</span>
        <v-autocomplete
          v-model="supervisor.supervisorId"
          :error-messages="supervisorIdErr"
          :items="supervisors"
          :item-title="
            item => getContactInitials(item, { isResponsible: true })
          "
          item-value="id"
          hide-details
          placeholder="Оберіть зі списку"
          :readonly="deleteSupervisor"
          :disabled="deleteSupervisor"
          @blur="v$.supervisor.supervisorId.$touch()">
          <template #append>
            <span v-if="deleteSupervisor"></span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" md="12" sm="12">
        <span class="label">Мідл менеджер</span>
        <v-autocomplete
          v-model="supervisor.middleManagerId"
          :error-messages="middleManagerIdErr"
          :items="middleManagers"
          :item-title="
            item => getContactInitials(item, { isResponsible: true })
          "
          item-value="id"
          hide-details
          placeholder="Оберіть зі списку"
          :readonly="deleteSupervisor"
          :disabled="deleteSupervisor"
          @blur="v$.supervisor.middleManagerId.$touch()">
          <template #append>
            <span v-if="deleteSupervisor"></span>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <h4 v-if="deleteSupervisor" class="pt-2">Супервайзера буде видалено!</h4>
  </div>
</template>
<script>
import { required } from '@vuelidate/validators'
import { getContactInitials, setErrHandler } from '@/utils/helperFunc'

import { mapState } from 'vuex'
import {
  urlCreateSupervisorTeam,
  ulrDeleteSupervisorTeam,
  urlUpdateSupervisorTeam,
} from '@/pages/request'
import { SUPERVISOR, MIDLE_MANAGER, MIDLE_HEAD } from '@/roles'
import { useVuelidate } from '@vuelidate/core'
export default {
  props: { dialog: { type: Object } },
  setup() {
    return { v$: useVuelidate() }
  },
  validations() {
    const require = this.updateSupervisor || this.createSupervisor
    const supervisorId = require ? { required } : {}
    const middleManagerId = require ? { required } : {}
    const name = require ? { required } : {}

    return {
      supervisor: {
        name: name,
        supervisorId: supervisorId,
        middleManagerId: middleManagerId,
      },
    }
  },
  data: () => ({
    supervisor: {
      name: null,
      supervisorId: null,
      middleManagerId: null,
    },
  }),
  computed: {
    ...mapState({
      selectItems: state => state.selectItems,
    }),
    createSupervisor() {
      return this.dialog.action === 'createSupervisor'
    },
    deleteSupervisor() {
      return this.dialog.action === 'deleteSupervisor'
    },
    updateSupervisor() {
      return this.dialog.action === 'updateSupervisor'
    },
    validation() {
      return { v$: this.v$.supervisor, name: 'supervisor' }
    },
    middleManagers() {
      return this.selectItems.allUsers.filter(
        user =>
          [MIDLE_MANAGER, MIDLE_HEAD].includes(user.roleId) && user.isActive
      )
    },
    supervisors() {
      return this.selectItems.allUsers.filter(
        user =>
          user.roleId === SUPERVISOR &&
          !this.existedSupervisers.includes(user.id) &&
          user.isActive
      )
    },
    existedSupervisers() {
      return this.dialog.params.existedSupervisers || []
    },
    supervisorIdErr() {
      return setErrHandler(this.v$?.supervisor?.supervisorId)
    },
    middleManagerIdErr() {
      return setErrHandler(this.v$?.supervisor?.middleManagerId)
    },
    nameErr() {
      return setErrHandler(this.v$?.supervisor?.name)
    },
  },
  methods: {
    getContactInitials,
    submit() {
      const create = () =>
        this.$axios.post(urlCreateSupervisorTeam(), this.supervisor)
      const del = () =>
        this.$axios.delete(ulrDeleteSupervisorTeam(this.supervisor.id))
      const update = () =>
        this.$axios.post(
          urlUpdateSupervisorTeam(this.supervisor.id),
          this.supervisor
        )

      switch (true) {
        case this.createSupervisor:
          return create()
        case this.deleteSupervisor:
          return del()
        case this.updateSupervisor:
          return update()
      }
    },
  },
  mounted() {
    this.$store.dispatch('addAllUsers')
    Object.assign(this.supervisor, this.dialog.dialogItem)
  },
}
</script>
