<template>
  <div>
    <v-tabs
      v-if="
        ['additional-agreements-dfl', 'additional-agreements-dkp'].includes(
          $route.name
        )
      ">
      <v-tab replace :to="{ name: 'additional-agreements-dfl' }"> ДФЛ </v-tab>
      <v-tab replace :to="{ name: 'additional-agreements-dkp' }"> ДКП </v-tab>
    </v-tabs>

    <router-view :key="duType"></router-view>
  </div>
</template>

<script lang="ts">
import { computed, provide } from 'vue'
import { duTypeKey } from './injectionKeys'
import { useRoute } from 'vue-router'

export default {
  name: 'ExtraAgreementsIndex',
  setup() {
    const route = useRoute()

    const duType = computed(() => {
      if (route.matched.some(r => r.name === 'additional-agreements-dfl')) {
        return 'dfl'
      }
      if (route.matched.some(r => r.name === 'additional-agreements-dkp')) {
        return 'dkp'
      }

      throw new Error('[computed] duType - invalid route')
    })

    provide(duTypeKey, duType)

    return { duType }
  },
}
</script>

<style scoped></style>
