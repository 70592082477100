<template>
  <div>
    <v-row>
      <v-col :cols="12" :md="6">
        <LeasingParticipant
          v-model:signatoryId="contract.signatoryId"
          v-model:basisId="contract.dkpBuyerBasisId"
          label="Покупець"
          :typeId="2"
          :participant="contract.buyer"
          :v$="{
            contactId: $v.contract.signatoryId,
            basisId: $v.contract.dkpBuyerBasisId,
          }"
          :readonly="readonly"
          single />
      </v-col>
      <v-col :cols="12" :md="6">
        <LeasingParticipant
          v-model:signatories="contract.providerSignatories"
          label="Постачальник"
          :typeId="contract.providerTypeId"
          :participant="contract.provider"
          :readonly="readonly"
          :v$="$v.contract.providerSignatories" />
      </v-col>
    </v-row>
    <Agreements style="margin-left: 15px" :agreements="contract.agreements" />
  </div>
</template>
<script>
import Agreements from '@/components/Agreements.vue'
import LeasingParticipant from '../../components/leasingParticipant/LeasingParticipant.vue'
import { mapState } from 'vuex'
export default {
  components: {
    LeasingParticipant,
    Agreements,
  },
  props: {
    readonly: { type: Boolean },
  },
  computed: {
    ...mapState({
      contract: state => state.contracts.dkpContract,
      calculation: state => state.contracts.dkpContract.calculation,
      $v: state => state.contracts.$vDkp,
    }),
  },
}
</script>
