<template>
  <div>
    <ContactCard
      v-if="subtenant.subtenantTypeId !== 2"
      :contact="subtenant.contact"
      :isNew="rdAnalyze.status?.id === 1 && roleAccess"
      finDocs />
    <ContractorCard
      v-if="subtenant.subtenantTypeId === 2"
      :isNew="rdAnalyze.status?.id === 1 && roleAccess"
      :contractor="subtenant.contractor"
      finDocs />
  </div>
</template>

<script>
import ContractorCard from '@/components/ContractorCard.vue'
import ContactCard from '@/components/ContactCard.vue'
import { mapState } from 'vuex'

export default {
  components: {
    ContractorCard,
    ContactCard,
  },
  props: {
    subtenants: { type: Array },
    rdAnalyze: { type: Object },
  },
  computed: {
    ...mapState({
      user: state => state.user,
    }),
    roleAccess() {
      return [2, 7, 8].includes(this.user?.role?.id)
    },
    subtenant() {
      return this.subtenants[this.$route.params.idx - 1]
    },
  },
}
</script>

<style scoped></style>
