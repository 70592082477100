<template>
  <div>
    <v-data-table
      v-if="tableBody"
      :headers="tHead"
      :items="tableBody"
      :hide-default-header="!tableBody"
      class="custom-table head-line"
      mobileBreakpoint="750"
      dense>
      <!-- eslint-disable-next-line -->
      <template #item.type="{ item }">
        <div class="d-flex align-center">
          <svg
            v-if="item.type.id === 1"
            class="mr-2"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none">
            <g clip-path="url(#clip0_2163_37500)">
              <path
                d="M15.5 0.5H11.5V1.5H13.793L8.646 6.646L9.354 7.354L14.5 2.207V4.5H15.5V0.5Z"
                fill="#62C14A" />
              <path
                d="M3 0.5C2.33696 0.5 1.70107 0.763392 1.23223 1.23223C0.763392 1.70107 0.5 2.33696 0.5 3L0.5 5C0.5 10.799 5.201 15.5 11 15.5H13C13.663 15.5 14.2989 15.2366 14.7678 14.7678C15.2366 14.2989 15.5 13.663 15.5 13V11.618C15.5 11.3393 15.4223 11.0661 15.2757 10.829C15.1291 10.592 14.9194 10.4005 14.67 10.276L12.255 9.068C12.0627 8.97188 11.852 8.9182 11.6372 8.91061C11.4224 8.90302 11.2084 8.94169 11.0099 9.024C10.8113 9.10632 10.6327 9.23035 10.4863 9.3877C10.3398 9.54505 10.2289 9.73204 10.161 9.936L9.863 10.829C9.80816 10.9929 9.69543 11.1311 9.54594 11.2178C9.39646 11.3045 9.22048 11.3338 9.051 11.3C7.97742 11.0853 6.9914 10.5576 6.21713 9.78352C5.44287 9.00943 4.91499 8.02353 4.7 6.95C4.66624 6.78052 4.69545 6.60454 4.78216 6.45506C4.86887 6.30557 5.00712 6.19284 5.171 6.138L6.28 5.768C6.63839 5.64832 6.93842 5.3978 7.1201 5.0665C7.30179 4.7352 7.35175 4.34754 7.26 3.981L6.674 1.637C6.59307 1.31252 6.40603 1.02439 6.14259 0.818382C5.87915 0.612377 5.55442 0.500315 5.22 0.5H3Z"
                fill="#08487A" />
            </g>
            <defs>
              <clipPath id="clip0_2163_37500">
                <rect
                  width="15"
                  height="15"
                  fill="white"
                  transform="translate(0.5 0.5)" />
              </clipPath>
            </defs>
          </svg>
          <svg
            v-if="item.type.id === 2"
            class="mr-2"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none">
            <g clip-path="url(#clip0_2163_37391)">
              <path
                d="M3 0.5C2.33696 0.5 1.70107 0.763392 1.23223 1.23223C0.763392 1.70107 0.5 2.33696 0.5 3L0.5 5C0.5 10.799 5.201 15.5 11 15.5H13C13.663 15.5 14.2989 15.2366 14.7678 14.7678C15.2366 14.2989 15.5 13.663 15.5 13V11.618C15.5 11.3393 15.4223 11.0661 15.2757 10.829C15.1291 10.592 14.9194 10.4005 14.67 10.276L12.255 9.068C12.0627 8.97188 11.852 8.9182 11.6372 8.91061C11.4224 8.90302 11.2084 8.94169 11.0099 9.024C10.8113 9.10632 10.6327 9.23035 10.4863 9.3877C10.3398 9.54505 10.2289 9.73204 10.161 9.936L9.863 10.829C9.80816 10.9929 9.69543 11.1311 9.54594 11.2178C9.39646 11.3045 9.22048 11.3338 9.051 11.3C7.97742 11.0853 6.9914 10.5576 6.21713 9.78352C5.44287 9.00943 4.91499 8.02353 4.7 6.95C4.66624 6.78052 4.69545 6.60454 4.78216 6.45506C4.86887 6.30557 5.00712 6.19284 5.171 6.138L6.28 5.768C6.63839 5.64832 6.93842 5.3978 7.1201 5.0665C7.30179 4.7352 7.35175 4.34754 7.26 3.981L6.674 1.637C6.59307 1.31252 6.40603 1.02439 6.14259 0.818382C5.87915 0.612377 5.55442 0.500315 5.22 0.5H3Z"
                fill="#08487A" />
              <path
                d="M14.646 0.645996L9.5 5.793V3.5H8.5V7.5H12.5V6.5H10.207L15.354 1.354L14.646 0.645996Z"
                fill="#62C14A" />
            </g>
            <defs>
              <clipPath id="clip0_2163_37391">
                <rect
                  width="15"
                  height="15"
                  fill="white"
                  transform="translate(0.5 0.5)" />
              </clipPath>
            </defs>
          </svg>
          <span style="color: #08487a">{{ item.type?.name || '---' }}</span>
        </div>
      </template>
      <!-- eslint-disable-next-line -->
      <template #item.status="{ item }">
        <div class="d-flex align-center justify-start">
          <v-tooltip bottom :text="item.status?.name || '---'">
            <template #activator="{ props }">
              <v-btn size="small" icon v-bind="props">
                <svg
                  style="margin-right: 10px"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="16"
                  viewBox="0 0 15 16"
                  fill="none">
                  <path
                    d="M2.5 0.5C1.83696 0.5 1.20107 0.763392 0.732233 1.23223C0.263392 1.70107 0 2.33696 0 3L0 5C0 10.799 4.701 15.5 10.5 15.5H12.5C13.163 15.5 13.7989 15.2366 14.2678 14.7678C14.7366 14.2989 15 13.663 15 13V11.618C15 11.3393 14.9223 11.0661 14.7757 10.829C14.6291 10.592 14.4194 10.4005 14.17 10.276L11.755 9.068C11.5627 8.97188 11.352 8.9182 11.1372 8.91061C10.9224 8.90302 10.7084 8.94169 10.5099 9.024C10.3113 9.10632 10.1327 9.23035 9.98626 9.3877C9.83981 9.54505 9.72888 9.73204 9.661 9.936L9.363 10.829C9.30816 10.9929 9.19543 11.1311 9.04594 11.2178C8.89646 11.3045 8.72048 11.3338 8.551 11.3C7.47742 11.0853 6.4914 10.5576 5.71713 9.78352C4.94287 9.00943 4.41499 8.02353 4.2 6.95C4.16624 6.78052 4.19545 6.60454 4.28216 6.45506C4.36887 6.30557 4.50712 6.19284 4.671 6.138L5.78 5.768C6.13839 5.64832 6.43842 5.3978 6.6201 5.0665C6.80179 4.7352 6.85175 4.34754 6.76 3.981L6.174 1.637C6.09307 1.31252 5.90603 1.02439 5.64259 0.818382C5.37915 0.612377 5.05442 0.500315 4.72 0.5H2.5Z"
                    fill="#08487A" />
                </svg>
              </v-btn>
            </template>
            <span>{{ item.status?.name || '---' }}</span>
          </v-tooltip>
          <span
            v-if="item.status && [1, 3, 4].includes(item.status.id)"
            style="color: #007f00"
            >Успiшний дзвiнок</span
          >
          <span
            v-if="item.status && [2, 5, 6, 7, 8].includes(item.status.id)"
            style="color: #d30000"
            text>
            Неуспішний дзвінок
            <v-tooltip activator="parent" location="start">{{
              item.status?.name || '---'
            }}</v-tooltip>
          </span>
        </div>
      </template>
      <!-- eslint-disable-next-line -->
      <template #item.user="{ item }">
        {{
          item.user
            ? getContactInitials(item.user.fullName, { byName: true })
            : '---'
        }}
      </template>
      <!-- eslint-disable-next-line -->
      <template #item.startTime="{ item }">
        {{ toFormatDate(item.startTime) }}
      </template>
    </v-data-table>
  </div>
</template>
<script>
import {
  getContactInitials,
  getResponsibleShortName,
  toFormatDate,
} from '@/utils/helperFunc'
export default {
  props: {
    tableHead: { type: Array },
    tableBody: { type: Array, default: () => [] },
  },
  data: () => ({
    loading: false,
    tHead: [
      { title: 'Статус', key: 'status', align: 'start', sortable: false },
      { title: 'Тип дзвiнка', key: 'type', align: 'start', sortable: false },
      { title: 'Дата', key: 'startTime', align: 'start', sortable: false },
      {
        title: 'Тривалість, сек',
        key: 'duration',
        align: 'center',
        sortable: false,
      },
      {
        title: 'Відповідальний',
        key: 'user',
        align: 'start',
        sortable: false,
      },
    ],
  }),
  computed: {
    filteredItems() {
      return this.tableBody.filter()
    },
  },
  methods: {
    getContactInitials,
    getResponsibleShortName,
    toFormatDate,
  },
}
</script>

<style scoped></style>
