import { getGraphName } from '../get-description'
import { style } from './styles'

function buildTableBody(data, columns, obj) {
  const body = []

  const benefit = obj.requestData.leasingProgram === 34

  if (benefit) {
    body.push([
      {
        text: '№',
        alignment: 'center',
        color: 'white',
        fillColor: style.colors.mainRed,
        bold: true,
        margin: [0, 6, 0, 0],
      },
      {
        text: 'Термiн оплати',
        alignment: 'center',
        color: 'white',
        fillColor: style.colors.mainRed,
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: `Оплата за обʼєкт, ${data?.selectedCurrency?.shortTxt}`,
        alignment: 'center',
        color: 'white',
        fillColor: style.colors.mainRed,
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: `Винагорода лізингодавця, ${data?.selectedCurrency?.shortTxt}`,
        alignment: 'center',
        color: 'white',
        fillColor: style.colors.mainRed,
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: `Компенсація винагороди лізингодавця, ${data?.selectedCurrency?.shortTxt}`,
        alignment: 'center',
        color: 'white',
        fillColor: style.colors.mainRed,
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: `Сума платежу,${data?.selectedCurrency?.shortTxt}`,
        alignment: 'center',
        color: 'white',
        fillColor: style.colors.mainRed,
        bold: true,
        margin: [0, 5, 0, 0],
      }, // #ca5b5b
      {
        text: `Сума платежу з компенсацією,${data?.selectedCurrency?.shortTxt}`,
        alignment: 'center',
        color: 'white',
        fillColor: style.colors.mainRed,
        bold: true,
        margin: [0, 5, 0, 0],
      }, // #ca5b5b
    ])

    data.graph.push({
      n: 'Всього',
      date: ' ',
      'payment-principal': data?.['total-payment-principal'],
      interest: data?.['total-interest'],
      payment: data?.['total-payment'],
      amountRewardUSAID: data?.['total-amount-rewardUSAID'], // компенсація винагороди
      promotionPaymentUSAID: data?.['total-promotion-paymentUSAID'], // Сума платежу з компенсацією
    })
  } else if (obj.programFSN) {
    body.push([
      {
        text: '№',
        alignment: 'center',
        color: 'white',
        fillColor: style.colors.mainRed,
        bold: true,
        margin: [0, 6, 0, 0],
      },
      {
        text: 'Термiн оплати',
        alignment: 'center',
        color: 'white',
        fillColor: style.colors.mainRed,
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: `Відшкодування (компенсація) частини вартості Об'єкта лізингу, в т.ч. ПДВ 20%, ${data?.selectedCurrency?.shortTxt}`,
        alignment: 'center',
        color: 'white',
        fillColor: style.colors.mainRed,
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: `Винагорода за Програмою (5-7-9% річних), без ПДВ, ${data?.selectedCurrency?.shortTxt}`,
        alignment: 'center',
        color: 'white',
        fillColor: style.colors.mainRed,
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: `Комісія за додаткові послуги, без ПДВ, ${data?.selectedCurrency?.shortTxt}`,
        alignment: 'center',
        color: 'white',
        fillColor: style.colors.mainRed,
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: `Розмір Лізингового платежу, який підлягає сплаті Лізингоодержувачем за Програмою  в т. ч. ПДВ, ${data?.selectedCurrency?.shortTxt}`,
        alignment: 'center',
        color: 'white',
        fillColor: style.colors.mainRed,
        bold: true,
        margin: [0, 5, 0, 0],
      },
    ])

    data.graph.push({
      n: 'Всього',
      date: ' ',
      'payment-principal': data?.['total-payment-principal'],
      interest: data?.['total-interest'],
      'commission-additional-services':
        data?.['total-commission-additional-services'],
      payment: data?.['total-payment'],
    })
  } else {
    body.push([
      {
        text: '№',
        alignment: 'center',
        color: 'white',
        fillColor: style.colors.mainRed,
        bold: true,
        margin: [0, 6, 0, 0],
      },
      {
        text: 'Термiн оплати',
        alignment: 'center',
        color: 'white',
        fillColor: style.colors.mainRed,
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: `Оплата за авто, ${data?.selectedCurrency?.shortTxt}`,
        alignment: 'center',
        color: 'white',
        fillColor: style.colors.mainRed,
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: `Винагорода лізингодавця, ${data?.selectedCurrency?.shortTxt}`,
        alignment: 'center',
        color: 'white',
        fillColor: style.colors.mainRed,
        bold: true,
        margin: [0, 5, 0, 0],
      },
      // 'Cервiсний платiж, грн',
      {
        text: `Сума платежу,${data?.selectedCurrency?.shortTxt}`,
        alignment: 'center',
        color: 'white',
        fillColor: style.colors.mainRed,
        bold: true,
        margin: [0, 5, 0, 0],
      }, // #ca5b5b
    ])

    data.graph.push({
      n: 'Всього',
      date: ' ',
      'payment-principal': data?.['total-payment-principal'],
      interest: data?.['total-interest'],
      payment: data?.['total-payment'],
    })
  }

  data.graph
    .filter(row => row.n !== 'Всього')
    .forEach(row => {
      const dataRow = []

      columns.forEach((column, index) => {
        dataRow.push({
          text: (row[column] || '').toString(),
          alignment: index <= 1 ? 'center' : 'right',
          bold: false,
        })
      })

      body.push(dataRow)
    })

  const totalRow = data.graph.find(row => row.n === 'Всього')
  if (totalRow) {
    const totalDataRow = []

    columns.forEach((column, index) => {
      totalDataRow.push({
        text: (totalRow[column] || '').toString(),
        alignment: index <= 1 ? 'center' : 'right',
        bold: true,
      })
    })

    body.push(totalDataRow)
  }

  return body
}

function table(data, columns, obj) {
  const benefitWidth = ['7%', '10%', '13%', '15%', '20%', '15%', '20%']
  const standartWidth = obj.programFSN
    ? ['8%', '18.4%', '18.4%', '18.4%', '18.4%', '18.4%']
    : ['8%', '23%', '23%', '23%', /* '15%' */ '23%']
  return {
    layout: {
      hLineColor: () => '#e9e8e8',
      vLineColor: () => '#e9e8e8',
    },
    fontSize: 8,
    table: {
      widths:
        obj.requestData.leasingProgram === 34 ? benefitWidth : standartWidth,
      headerRows: 1,
      body: buildTableBody(data, columns, obj),
    },
  }
}

const pageBreak = (obj, key, index) => {
  const data = obj.resultData
  const keys = Object.keys(data)
  return (index !== keys.length && data[key].graph.length <= 85) || index === 0
    ? 'after'
    : ''
}

const dd = obj => {
  const graphs = Object.keys(obj.resultData)
  if (graphs.includes('oper-leasing')) return []

  const getAnnuityValue = (engName, data) => {
    if (data[engName]) {
      return data[engName]['total-amount-compensationUSAID'] || ''
    }
    return ''
  }

  return graphs.map((key, index) => {
    if (obj.requestData.leasingProgram === 34) {
      return [
        {
          text: ' ',
          pageBreak: pageBreak(obj, key, index),
        },
        {
          text: `Графік платежів - ${getGraphName(key)}`,
          style: 'header',
          fontSize: 10,
          margin: [0, 0, 0, 5],
        },
        table(
          obj.resultData[key],
          [
            'n',
            'date',
            'payment-principal',
            'interest',
            'amountRewardUSAID',
            'payment',
            'promotionPaymentUSAID',
          ],
          obj
        ),
        {
          margin: [0, 25, 0, 15],
          width: '50%',
          alignment: 'right',
          stack: [
            {
              margin: [0, 20, 0, 15],
              alignment: 'right',
              layout: {
                hLineWidth: () => 0,
                vLineWidth: () => 0,
              },
              table: {
                widths: ['35%', '15%', '35%', '20%'],
                body: [
                  [
                    {},
                    {},
                    {
                      text: 'Компенсація винагороди (Економія по Договору)*',
                      fillColor: style.colors.mainRed,
                      color: 'white',
                      fontSize: 13,
                      alignment: 'center',
                      bold: true,
                      margin: [0, 3, 0, 3],
                    },
                    {
                      text: getAnnuityValue(key, obj.resultData),
                      fillColor: style.colors.mainRed,
                      color: 'white',
                      fontSize: 13,
                      alignment: 'center',
                      bold: true,
                      margin: [15, 3, 0, 3],
                    },
                  ],
                ],
                borderColor: 'white',
              },
            },
          ],
        },
        {
          text: 'Акція дійсна для малих та середніх підприємств:',
          bold: true,
          lineHeight: 1.2,
          fontSize: 11,
        },
        {
          lineHeight: 1.2,
          fontSize: 11,
          text: '- які постраждали від війни (переміщені підприємства, підприємства на раніше окупованій території);',
        },
        {
          fontSize: 11,
          lineHeight: 1.2,
          text: '- які є критично важливими для надання послуг в період кризи або для відбудови, включаючи сільське господарство, енергетику, відбудову і соціальні послуги;',
        },
        {
          lineHeight: 1.2,
          fontSize: 11,
          text: '- власниками яких є жінки та/або підприємства, які мають позитивний гендерний вплив (наприклад, робочі місця, створені спеціально для жінок, які обслуговують підприємства, якими володіють жінки, або надають необхідні жінкам послуги);',
        },
        {
          lineHeight: 1.2,
          fontSize: 11,
          text: '- які мають інший вплив на відновлення: як запропонований проєкт сприятиме ММСП збільшити продажі, вийти на нові ринки, створювати робочі місця, підвищувати ефективність виробництва і загалом підтримуватиме відновлення економіки України.',
        },
      ]
    } else
      return [
        {
          text: ' ',
          pageBreak: pageBreak(obj, key, index),
        },
        {
          text: `Графік платежів - ${getGraphName(key)}`,
          style: 'header',
          fontSize: 10,
          margin: [0, 0, 0, 5],
        },
        obj.programFSN
          ? table(
              obj.resultData[key],
              [
                'n',
                'date',
                'payment-principal',
                'interest',
                'commission-additional-services',
                'payment',
              ],
              obj
            )
          : table(
              obj.resultData[key],
              [
                'n',
                'date',
                'payment-principal',
                'interest',
                /* 'serv_paym' */ 'payment',
              ],
              obj
            ),
      ]
  })
}

export { dd }
