<template>
  <div>
    <v-row>
      <v-col :cols="12" :sm="6">
        <LeasingParticipant
          v-model:signatoryId="agreement.signatoryId"
          v-model:basisId="agreement.buyerBasisId"
          label="Покупець"
          :typeId="2"
          :participant="agreement.buyer"
          :v$="{
            contactId: $v.agreement.signatoryId,
            basisId: $v.agreement.buyerBasisId,
          }"
          :readonly="readonly"
          single
          details />
      </v-col>
      <v-col :cols="12" :sm="6">
        <LeasingParticipant
          v-model:signatories="agreement.providerSignatories"
          label="Постачальник"
          :typeId="agreement.providerTypeId"
          :participant="agreement.provider"
          :v$="$v.agreement.providerSignatories"
          :readonly="readonly"
          details />
      </v-col>
    </v-row>
    <Agreements style="margin-left: 15px" :agreements="agreement.agreements" />
  </div>
</template>
<script>
import LeasingParticipant from '@/pages/contracts/components/leasingParticipant/LeasingParticipant.vue'
import { mapState } from 'vuex'
import Agreements from '@/components/Agreements.vue'

export default {
  components: { LeasingParticipant, Agreements },
  props: {
    readonly: { type: Boolean },
  },
  computed: {
    ...mapState({
      agreement: state => state.contracts.extraAgreement,
      $v: state => state.contracts.$extraAgreement,
    }),
  },
}
</script>
