<template>
  <div>
    <div v-if="agent.payments">
      <v-data-table
        :headers="tHeadPayments"
        show-expand
        :items="getSafe(() => agent.payments)"
        hide-default-footer
        class="pb-2 pt-1 custom-table head-line"
        dense>
        <template #item="{ item }">
          <tr>
            <td>
              <span></span>
            </td>
            <td>
              <span style="font-size: 12px !important">
                {{ item.status?.name || 'Новий' }}</span
              >
            </td>
            <td>
              <ALink
                style="font-size: 12px !important"
                :text="getEntityName(item.contract.client)"
                :route="getEntityRoute(item)" />
            </td>
            <td>
              <ALink
                style="font-size: 12px !important"
                :text="item.contract.number"
                :route="{
                  name: 'dfl-contract',
                  params: { id: item.contract.id },
                }" />
            </td>
            <td>
              <span style="font-size: 12px !important">{{
                item.contract.leasingObjectName
              }}</span>
            </td>
            <td>
              <span style="font-size: 12px !important">{{
                getBeautyNum(item.amountOl) + ' грн'
              }}</span>
            </td>
            <td>
              <span style="font-size: 12px !important">{{
                getBeautyNum(item.amountAB) + ' грн'
              }}</span>
            </td>
            <td>
              <TableCrud
                style="margin-right: -10px !important"
                :actionsArray="[
                  {
                    action: () => openActCard(item),
                    text: 'Переглянути',
                    icon: 'mdi-cloud-print-outline',
                  },
                ]">
              </TableCrud>
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { getBeautyNum, getEntityName, getSafe } from '@/utils/helperFunc'
import ALink from '@/components/Link.vue'
import TableCrud from '@/components/table-crud.vue'

export default {
  name: 'ActPayments',
  components: { TableCrud, ALink },
  props: {
    agent: { type: Object },
  },
  data: () => ({
    expandedItems: [],
    documents: null,
    tHeadPayments: [
      { title: 'Статус', key: 'status', sortable: false },
      { title: 'Клієнт', key: 'lessee', sortable: false },
      {
        title: '№ договору',
        key: 'contract',
        sortable: false,
        align: 'start',
      },
      {
        title: "Об'єкт лізингу",
        key: 'leasingObjectName',
        sortable: false,
        align: 'start',
      },
      { title: 'Сума ОЛ', key: 'amountOl', sortable: false },
      { title: 'Сума АВ', key: 'amountAB', sortable: false },
      { title: 'Дії', key: 'actions', align: 'end', sortable: false },
    ],
  }),
  methods: {
    getSafe,
    getEntityName,
    getBeautyNum,
    getEntityRoute(item) {
      const typeContractor = item.entityTypeId === 2
      const name = typeContractor ? 'contractors-form' : 'individuals-form'
      const id = item.contract.client.id
      return { name, params: { id } }
    },
    openActCard(item) {
      return this.$store.commit('setDialog', {
        title: 'Акт на виплату',
        dialogItem: {
          act: item,
          paymentThroughs: this.agent.paymentThroughs,
        },
        params: {
          hideCancel: true,
          dialogWidth: 800,
          dialogHeight: 650,
          btnWidth: '22%',
          flexEnd: true,
          submitText: 'Зробити виплату',
        },
        action: 'paymentActCard',
      })
    },
  },
}
</script>

<style scoped></style>
