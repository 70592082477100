<template>
  <div>
    <div>
      <OpenableSection title="Юридичні документи">
        <template #append-title>
          <AllDocsBtn :id="contractor.id" contractor legal />
        </template>
        <div class="d-flex align-center">
          <v-checkbox
            v-model="contractorData.isModelCharter"
            style="margin-top: 0 !important; margin-bottom: 10px"
            :disabled="readonly"
            hide-details
            dense
            color="red">
            <template #label>
              <div class="d-flex align-center">
                <span style="font-size: 0.8rem">
                  Наявнiсть модельного статуту
                </span>
                <v-btn
                  icon
                  size="small"
                  density="comfortable"
                  @click.stop="dialog = true">
                  <v-icon size="17">
                    {{ 'mdi-information' }}
                  </v-icon>
                </v-btn>
              </div>
            </template>
          </v-checkbox>
          <v-dialog v-model="dialog" persistent width="540">
            <v-card>
              <v-card-actions style="background: #F2F5FA; !important">
                <v-spacer></v-spacer>
                <v-btn icon @click="dialog = false">
                  <CloseIcon />
                </v-btn>
              </v-card-actions>
              <v-card-text style="padding: 0 !important">
                <div class="doc-modal-statut">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none">
                      <rect width="22" height="22" rx="11" fill="white" />
                      <path d="M11 4.2251V13.0251V4.2251Z" fill="#08487A" />
                      <path
                        d="M11 4.2251V13.0251"
                        stroke="#08487A"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                      <path
                        d="M11.875 16.3999C11.875 16.8831 11.4832 17.2749 11 17.2749C10.5168 17.2749 10.125 16.8831 10.125 16.3999C10.125 15.9167 10.5168 15.5249 11 15.5249C11.4832 15.5249 11.875 15.9167 11.875 16.3999Z"
                        fill="#08487A"
                        stroke="#08487A" />
                    </svg>
                  </div>
                  <span class="d-block ml-2" style="line-height: 150%">
                    Зверніть увагу! В Україні запроваджено модельний статут для
                    електронного обліку.<br />
                    Для отримання результату надання адміністративної послуги
                    необхідно на сайті
                    <a
                      style="color: #08487a"
                      href="https://usr.minjust.gov.ua/content/get-documents"
                      target="_blanc"
                      >https://usr.minjust.gov.ua/content/get-documents</a
                    >
                    у форму пошуку внести "Код доступу". "Код доступу" надається
                    державними реєстраторами, співробітниками ЦНАП та
                    функціональністю порталу одразу після реєстрації заяви на
                    державну реєстрацію або формування запиту про надання
                    відомостей.
                  </span>
                </div>
              </v-card-text>
            </v-card>
          </v-dialog>
        </div>
        <FilesUploader
          v-model:documents="contractorData.statute"
          :readonly="readonly"
          label="Статут (остання редакція)"
          :replace="false"
          :list="false"
          :disableDeleteAction="!userAdmin" />
        <FilesUploader
          v-model:documents="contractorData.codeAccessStatute"
          :readonly="readonly"
          :replace="false"
          label="Опис документів із зазначенням коду доступу до Статуту"
          :list="false"
          :disableDeleteAction="!userAdmin" />
        <FilesUploader
          v-model:documents="contractorData.extract"
          :readonly="readonly"
          :replace="false"
          label="Виписка / витяг з ЄДРЮО, ФОПтаГФ"
          :list="false"
          :disableDeleteAction="!userAdmin" />
        <FilesUploader
          v-model:documents="contractorData.protocolFounders"
          :readonly="readonly"
          :replace="false"
          label="Протокол засновників про обрання Керівника"
          :list="false"
          :disableDeleteAction="!userAdmin" />
        <FilesUploader
          v-model:documents="contractorData.orderAppointment"
          :readonly="readonly"
          :replace="false"
          label="Наказ про призначення на посаду Керівника"
          :list="false"
          :disableDeleteAction="!userAdmin" />
        <FilesUploader
          v-model:documents="contractorData.otherLegalDocuments"
          :readonly="readonly"
          :replace="false"
          label="Інші юридичні документи"
          :list="false"
          :disableDeleteAction="!userAdmin" />
      </OpenableSection>
    </div>
    <div>
      <OpenableSection title="Фiнансовi документи">
        <template #append-title>
          <AllDocsBtn :id="contractor.id" contractor fin />
        </template>
        <FilesUploader
          v-model:documents="contractorData.balanceF1AndF2"
          :readonly="readonly"
          :replace="false"
          label="Баланс - Ф1 і Ф2"
          :list="false"
          :disableDeleteAction="!userAdmin" />
        <FilesUploader
          v-model:documents="contractorData.declaration"
          :readonly="readonly"
          :replace="false"
          label="Декларація за розрахунком податку на прибуток"
          :list="false"
          :disableDeleteAction="!userAdmin" />
        <FilesUploader
          v-model:documents="contractorData.generalTurnoverBalanceSheet"
          :readonly="readonly"
          :replace="false"
          label="Загальна оборотно-сальдова відомість"
          :list="false"
          :disableDeleteAction="!userAdmin" />
        <FilesUploader
          v-model:documents="contractorData.turnoverBalanceSheet"
          :readonly="readonly"
          :replace="false"
          label="Оборотно-сальдові відомості по рахунку (36,37 і 63, 68 рах.)"
          :list="false"
          :disableDeleteAction="!userAdmin" />
        <FilesUploader
          v-model:documents="contractorData.copiesOfContracts"
          :readonly="readonly"
          :replace="false"
          :info="`Копії договорів, за якими передбачається отримання доходу в ${new Date().getFullYear()} р. з основними контрагентами (постачальниками і покупцями)`"
          label="Копії договорів"
          :list="false"
          :disableDeleteAction="!userAdmin" />
        <FilesUploader
          v-model:documents="contractorData.certificatesOfAccountTurnover"
          :readonly="readonly"
          :replace="false"
          label="Довідки про обороти по рахунках з обслуговуючих банків"
          :list="false"
          :disableDeleteAction="!userAdmin" />
        <FilesUploader
          v-model:documents="contractorData.certificatesOfObligations"
          :readonly="readonly"
          :replace="false"
          label="Довідки про наявність / відсутність зобов'язань"
          :list="false"
          :disableDeleteAction="!userAdmin" />
        <FilesUploader
          v-model:documents="contractorData.otherFinancialDocuments"
          :readonly="readonly"
          :replace="false"
          label="Інші фінансові документи"
          :list="false"
          :disableDeleteAction="!userAdmin" />
      </OpenableSection>
    </div>
  </div>
</template>

<script>
import CloseIcon from '@/assets/svg/close-icon.vue'
import OpenableSection from '@/components/OpenableSection.vue'
import FilesUploader from '@/components/FilesUploader.vue'
import AllDocsBtn from '@/components/AllDocsBtn.vue'
import { mapState } from 'vuex'

export default {
  components: { FilesUploader, AllDocsBtn, OpenableSection, CloseIcon },
  props: {
    contractor: { type: Object, required: true },
    readonly: { type: Boolean, default: false },
  },
  data() {
    return {
      dialog: false,
      isModelCharter: this.contractor.isModelCharter,
    }
  },
  computed: {
    ...mapState({
      /* ... */
    }),
    userAdmin() {
      return this.$store?.state?.user?.roleId === 1
    },
    contractorData() {
      return this.contractor
    },
  },
  watch: {
    'contractorData.isModelCharter': function (newValue) {
      if (newValue) {
        this.dialog = true
        this.isModelCharter = true
      } else {
        this.dialog = false
        this.isModelCharter = false
      }
    },
  },
}
</script>

<style scoped>
.doc-modal-statut {
  background: #f2f5fa;
  border: 1px solid #c0c1c7;
  box-shadow: 0 2px 8px rgb(0 0 0 / 4%);
  border-radius: 4px;
  padding: 10px;
  display: flex;
  font-weight: 400;
  font-size: 14px;
  width: 540px;
}
</style>
