<template>
  <div>
    <v-dialog v-if="!collapse" v-model="dialog" max-width="400" persistent>
      <div v-if="dialogData" class="dialog">
        <div class="header">
          <v-btn variant="text" size="small" @click="collapse = true">
            <v-icon>mdi-minus</v-icon>
          </v-btn>
        </div>
        <div class="d-flex justify-center text-center position-relative">
          <div>
            <span class="opacity-60 d-block"
              >{{ dialogData?.detail_call?.type_call?.name }} дзвінок від</span
            >
            <span class="font-weight-bold d-block">{{
              dialogData.detail_call?.phone
            }}</span>
          </div>
        </div>
        <div class="d-flex flex-column justify-center mt-4 text-center">
          <div class="d-flex justify-center mb-3">
            <v-avatar style="height: 70px; width: 70px">
              <v-img alt="avatar" :src="imgSource"></v-img>
            </v-avatar>
          </div>
          <div class="d-flex justify-space-between align-center">
            <h3>{{ connections.length ? 'Звʼязки' : 'ㅤ' }}</h3>
            <div
              v-if="
                dialogData.callHistories && dialogData.callHistories.length
              ">
              <v-tooltip text="Історія дзвінків">
                <template #activator="{ props }">
                  <v-btn
                    variant="text"
                    icon
                    v-bind="props"
                    size="small"
                    @click="openDialogHistoryLeadCalls">
                    <v-icon>mdi-history</v-icon>
                  </v-btn>
                </template>
              </v-tooltip>
            </div>
          </div>
          <div v-if="connections.length" class="connect">
            <div v-for="(item, idx) in connections" :key="idx" class="item">
              <v-tooltip :text="item.name">
                <template #activator="{ props }">
                  <span class="name" v-bind="props">{{ item?.name }}</span>
                </template>
              </v-tooltip>
              <span class="d-block text-start" style="width: 100px">{{
                item.type?.name
              }}</span>
              <span
                class="link d-block"
                @click="$router.replace(getEntityRoute(item.id, item.type.id))"
                >Перейти</span
              >
            </div>
          </div>
        </div>
        <div class="d-flex justify-space-between mt-7">
          <v-btn
            color="green"
            variant="tonal"
            class="shake"
            @click="createLeadByCall">
            <v-icon class="mr-1">mdi-plus</v-icon>
            <span>Створити лід</span>
          </v-btn>
          <v-btn color="red" variant="flat" @click="closeDialog">
            <v-icon class="mr-1">mdi-close</v-icon>
            <span>Закрити</span>
          </v-btn>
        </div>
      </div>
    </v-dialog>

    <CollapseNotification
      v-if="collapse"
      :dialogData="dialogData"
      @open-dialog="openFullDialog"
      @close="closeDialog" />
  </div>
</template>

<script lang="ts">
import { mapState } from 'vuex'
import { getContactInitials, getEntityRoute } from '@/utils/helperFunc'
import router from '@/router/router'
import CollapseNotification from '@/components/callsNotifications/CollapseNotification.vue'

export default {
  components: { CollapseNotification },
  data() {
    return {
      dialog: false,
      collapse: false,
      dialogData: {},
      imgSource:
        'https://www.shutterstock.com/image-vector/smiling-young-woman-headphones-microphone-600nw-2195161163.jpg',
    }
  },
  computed: {
    ...mapState({
      user: state => state.user,
      callNotifications: state => state.callNotifications,
    }),
    connections() {
      const application = this.dialogData?.detail_call?.application || null
      const lead = this.dialogData?.detail_call?.lead || null
      const contact = this.dialogData?.detail_call?.contact || null
      const contractor = this.dialogData?.detail_call?.contractor || null

      const result = []

      if (application) {
        result.push({
          type: {
            name: 'Заявка',
            id: 5,
          },
          name: application.applicationName,
          id: application.id,
          obj: application,
        })
      }
      if (lead) {
        result.push({
          type: {
            name: 'Лід',
            id: 4,
          },
          name: lead.name,
          id: lead.id,
          obj: lead,
        })
      }
      if (contact) {
        result.push({
          type: {
            name: 'Контакт',
            id: 1,
          },
          name: contact.fullName,
          id: contact.id,
          obj: contact,
        })
      }
      if (contractor) {
        result.push({
          type: {
            name: 'Контрагент',
            id: 2,
          },
          name: contractor.shortName || contractor.fullName,
          id: contractor.id,
          obj: contractor,
        })
      }

      return result
    },
  },
  methods: {
    getEntityRoute,
    getContactInitials,
    createLeadByCall() {
      this.$store.commit('setDialog', {
        title: 'Створити лід',
        dialogItem: { id: this.dialogData.id },
        params: {
          cb: res => {
            router.push({
              name: 'edit-lead',
              params: { id: res.data.leadId },
            })
            this.closeDialog()
          },
        },
        action: 'createLeadByCall',
      })
    },
    openDialogHistoryLeadCalls() {
      this.$store.commit('setDialog', {
        title: 'Історія дзвінків',
        dialogItem: {
          callHistories: this.dialogData.callHistories || [],
        },
        params: {
          hideActions: true,
          dialogWidth: 900,
        },
        action: 'historyLeadCalls',
      })
    },
    clearNotification() {
      this.$store.commit('changeCurrentCallActive', false)
      this.$store.commit('setCurrentCallItem', {})
    },
    closeDialog() {
      this.dialog = false
      this.collapse = false
      this.clearNotification()
    },
    openFullDialog() {
      this.collapse = false
      this.dialog = true
    },
  },
  watch: {
    '$route.name': function (val) {
      if (
        [
          'lead-general-info',
          'application-common-info',
          'contact-common-info',
          'contractor-requisites',
        ].includes(val) &&
        Object.keys(this.dialogData).length
      ) {
        this.collapse = true
      }
    },
    'callNotifications.isCurrentCallActive': function (val) {
      if (val) {
        this.dialog = true
        this.dialogData = this.callNotifications.currentCallItem || {}
        this.collapse = false
      } else {
        this.dialog = false
        this.collapse = false
      }
    },
  },
  async created() {
    await this.$store.dispatch('listenCallNotification')
  },
}
</script>

<style scoped lang="scss">
.dialog {
  padding: 19px 19px;
  background: white;
  font-size: 14px;
  border-radius: 7px;
  cursor: default;
  width: 500px;

  .header {
    background: #fff;
    justify-content: end;
    display: flex;
  }
}

@keyframes shake {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(3deg);
  }
  50% {
    transform: rotate(0deg);
  }
  50.1% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-3deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.shake {
  animation: shake 0.4s infinite;
}
</style>
