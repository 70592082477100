<template>
  <div>
    <v-row>
      <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
        <span class="label">Контакт</span>
        <v-autocomplete
          v-model="collegiumParticipant.contactId"
          :items="selectItems.contacts"
          :error-messages="contactIdErr"
          item-value="id"
          item-title="fullName"
          placeholder="Оберіть зі списку"
          :filter="contactFilter"
          :disabled="editCollegiumDirector"
          :readonly="deleteCollegiumParticipant"
          @blur="v$.collegiumParticipant.contactId.$touch()">
        </v-autocomplete>
      </v-col>
      <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
        <span class="label">Посада</span>
        <v-select
          v-model="collegiumParticipant.directorPostId"
          :items="
            directoryItems(
              'contractorDirectorPosts',
              collegiumParticipant.directorPost
            )
          "
          :error-messages="directorPostIdErr"
          item-title="name"
          item-value="id"
          placeholder="Оберіть зі списку"
          :readonly="deleteCollegiumParticipant"
          @focus="asyncAction('addContractorDirectorPosts')"
          @blur="v$.collegiumParticipant.directorPostId.$touch()">
        </v-select>
      </v-col>
    </v-row>
    <h3 v-if="deleteCollegiumParticipant">Директора буде видалено!</h3>
  </div>
</template>
<script>
import { required } from '@vuelidate/validators'
import { mapState } from 'vuex'
import {
  urlCreateContractorCollegiumDirector,
  urlDeleteContractorCollegiumDirector,
} from '@/pages/request'

import { setErrHandler, contactFilter } from '@/utils/helperFunc'
import { useVuelidate } from '@vuelidate/core'
import { useSelect } from '@/utils/mixins/useSelect'
export default {
  setup() {
    return { v$: useVuelidate(), ...useSelect() }
  },
  props: { dialog: { type: Object } },
  validations() {
    return {
      collegiumParticipant: {
        contactId: { required },
        directorPostId: { required },
      },
    }
  },
  data: () => ({ collegiumParticipant: {} }),
  computed: {
    ...mapState({ selectItems: state => state.selectItems }),
    addContractorCollegiumParticipant() {
      return this.dialog.action === 'addContractorCollegiumParticipant'
    },
    validation() {
      return {
        v$: this.v$.collegiumParticipant,
        name: 'contractorCollegiumParticipant',
      }
    },
    deleteCollegiumParticipant() {
      return this.dialog.action === 'deleteCollegiumParticipant'
    },
    contractorId() {
      return this.$store.state?.dialog?.params?.contractorId
    },
    contactIdErr() {
      return this.setErrHandler(this.v$.collegiumParticipant.contactId)
    },
    directorPostIdErr() {
      return this.setErrHandler(this.v$.collegiumParticipant.directorPostId)
    },
    editCollegiumDirector() {
      return this.dialog.action === 'editCollegiumDirector'
    },
  },
  methods: {
    contactFilter,

    urlCreateContractorCollegiumDirector,
    urlDeleteContractorCollegiumDirector,
    setErrHandler,
    submit() {
      if (
        this.addContractorCollegiumParticipant ||
        this.editCollegiumDirector
      ) {
        return this.createDirector()
      } else if (this.deleteCollegiumParticipant) {
        return this.deleteDirector()
      }
    },
    createDirector() {
      if (this.editCollegiumDirector) {
        delete this.collegiumParticipant.directorPost
      }
      return this.$axios.post(
        this.urlCreateContractorCollegiumDirector(this.contractorId),
        this.collegiumParticipant
      )
    },
    deleteDirector() {
      return this.$axios.delete(
        this.urlDeleteContractorCollegiumDirector(this.collegiumParticipant.id)
      )
    },
  },
  created() {
    this.$store.dispatch('addContacts')
  },
  mounted() {
    Object.assign(this.collegiumParticipant, this.dialog.dialogItem)
  },
}
</script>
