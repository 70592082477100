<template>
  <div class="mb-3">
    <div class="res-block" @click="toggleChangeButton">
      <div class="d-flex align-center" style="justify-content: space-between">
        <span class="subtitle">Відповідальний</span>
        <span
          v-if="showChangeButton || !changeResponsible"
          class="res-change"
          @click.stop="toggleResponsible">
          Змінити</span
        >
        <span v-else class="res-change" @click="rejectResponsible"
          >Відміна</span
        >
      </div>
      <div v-if="!changeResponsible" class="d-flex align-center">
        <div>
          <span
            v-if="!changeResponsible"
            class="res-title"
            style="color: #09487a; font-size: 15px"
            >{{
              getContactInitials(responsible, { isResponsible: true })
            }}</span
          >
          <span v-else style="font-size: 13px" class="res-title"
            >Відсутній</span
          >
          <v-tooltip
            v-if="responsible"
            bottom
            color="grey darken-3"
            :text="responsible?.post">
            <template #activator="{ props }">
              <span class="res-post" style="color: #000" v-bind="props">{{
                responsible?.post
              }}</span>
            </template>
            <span v-if="responsible">{{ responsible?.post }}</span>
            <span v-else style="font-size: 13px" class="res-title"
              >Відсутній</span
            >
          </v-tooltip>
        </div>
      </div>
    </div>
    <div v-if="changeResponsible">
      <v-autocomplete
        v-model="localResponsibleId"
        class="mt-0"
        :items="directoryItems(activeUsers, responsible)"
        :item-title="getResponsibleShortName"
        item-value="id"
        :disabled="!!readOnly"
        dense
        @focus="asyncAction('addAllUsers')">
        <template #no-data><Loader /></template>
        <template
          v-if="responsible && asyncLoading('addAllUsers')"
          #append-item>
          <Loader />
        </template>
      </v-autocomplete>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/Loader.vue'
import { getContactInitials, getResponsibleShortName } from '@/utils/helperFunc'
import { mapGetters } from 'vuex'
import { useSelect } from '@/utils/mixins/useSelect'

export default {
  components: { Loader },
  emits: ['update:responsibleId'],
  props: {
    responsible: { type: Object },
    responsibleId: { type: Number },
    readOnly: { type: Boolean, default: false },
  },
  setup() {
    return {
      ...useSelect(),
    }
  },
  data: () => ({
    changeResponsible: false,
    defaultResponsible: null,
    showChangeButton: false,
  }),
  computed: {
    ...mapGetters({
      activeUsers: 'activeUsers',
    }),
    localResponsibleId: {
      get() {
        return this.responsibleId
      },
      set(v) {
        this.$emit('update:responsibleId', v)
      },
    },
  },
  methods: {
    getContactInitials,
    getResponsibleShortName,
    rejectResponsible() {
      this.changeResponsible = false
      this.localResponsibleId = this.responsible.id
    },
    toggleResponsible() {
      if (!this.readOnly) {
        this.changeResponsible = !this.changeResponsible
      }
    },
    toggleChangeButton() {
      if (window.innerWidth <= 768) {
        this.showChangeButton = !this.showChangeButton
      }
    },
  },
}
</script>

<style scoped>
.res-post {
  display: block;
  width: 215px;
  font-size: 13px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.res-block {
  &:hover .res-change {
    display: block !important;
  }
  .res-change {
    display: none;
    cursor: pointer;
    color: #09487a;
    font-weight: 500;
    font-size: 13px;
  }
}

.subtitle {
  color: #838282;
  font-weight: 500;
  font-size: 13px;
  display: block;
  margin-bottom: 5px;
}
</style>
