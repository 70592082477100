import { createStore } from 'vuex'
import { dialog } from './modules/dialog.js'
import { common } from './modules/common.js'
import { selectItems } from './modules/select-items.js'
import { breadScrumb } from './modules/bread-scrumb.js'
import { user } from './modules/user.js'
import { application } from './modules/application.js'
import { contact } from './modules/contact.js'
import { contractor } from './modules/contractor.js'
import { projects } from './modules/projects.js'
import { contracts } from './modules/contracts.js'
import { leads } from './modules/leads'
import { leasingObjects } from './modules/leasing-objects'
import { pagination } from './modules/pagination'
import { blDkps } from './modules/blDkps'
import { notifications } from './modules/notifications'
import { callNotifications } from './modules/callNotifications'
import { auth } from './modules/auth'

export default createStore({
  modules: {
    auth: auth,
    user: user,
    common: common,
    dialog: dialog,
    selectItems: selectItems,
    breadScrumb: breadScrumb,
    application: application,
    contact: contact,
    contractor: contractor,
    projects: projects,
    contracts: contracts,
    leads: leads,
    leasingObjects: leasingObjects,
    pagination: pagination,
    blDkps: blDkps,
    notifications: notifications,
    callNotifications: callNotifications,
  },
})
