<template>
  <div>
    <SectionLoader v-if="loading" />
    <div v-else>
      <div class="d-flex align-center justify-space-between">
        <v-tabs
          v-model="currentTab"
          class="mb-5"
          :height="$vuetify.breakpoint?.xs ? null : 38"
          color="white"
          dark
          slider-color="#fc7247"
          dense>
          <v-tab
            replace
            :to="{
              name: 'marketing-general',
              params: { id: $route.params.id },
            }">
            Основна інформація
          </v-tab>
          <v-tab
            replace
            :to="{
              name: 'marketing-expenses',
              params: { id: $route.params.id },
            }">
            Витрати
          </v-tab>
        </v-tabs>
        <div data-readonly="hide" class="pr-3">
          <div v-if="!marketing.isActive">
            <v-btn
              :loading="loading"
              style="
                background: rgb(252 114 71);
                border-radius: 4px;
                color: #fff;
              "
              @click="activateCompany()">
              <span>Активувати</span>
            </v-btn>
          </div>
          <div v-if="marketing.isActive">
            <v-btn
              :loading="loading"
              style="
                background: rgb(8, 72, 122);
                border-radius: 4px;
                color: #fff;
              "
              @click="deactivateCompany()">
              <span>Деактивувати</span>
            </v-btn>
          </div>
        </div>
      </div>

      <RouterView
        v-model:marketing="marketing"
        style="min-height: 390px"
        :cb="() => $store.commit('refreshPage')">
      </RouterView>

      <ActionButtons
        v-if="!marketing.isActive"
        class="mt-10"
        :confirm="updateMarketing"
        :cancel="() => $store.commit('refreshPage')"
        :confirmLoading="loading"
        :confirmDisable="cacheState === cache || loading"
        :cancelDisable="cacheState === cache">
      </ActionButtons>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { setCache } from '@/utils/helperFunc'
import ActionButtons from '@/components/action-buttons.vue'
import SectionLoader from '@/components/section-loader.vue'
import {
  urlUpdateMarketingCompany,
  urlGetSingleMarketingCompany,
  urlDeactivateMarketingCompany,
} from '@/pages/request'
import { useAdditionalDocs } from '@/utils/mixins/useAdditionalDocs'
export default {
  components: { ActionButtons, SectionLoader },
  setup() {
    return {
      ...useAdditionalDocs(),
    }
  },
  data: () => ({
    currentTab: 0,
    loading: false,
    pageLoad: false,
    marketing: {},
    cache: null,
    generalObject: 'marketing',
    additionalFields: {
      utmTerm: { label: 'Ключове слово' },
      utmContent: { label: 'Ідентифікатор рекламної кампанії (utm_content)' },
    },
  }),
  computed: {
    ...mapState({
      compaignType: state => state.selectItems.compaignType,
    }),
    marketingId() {
      return parseInt(this.$route.params.id)
    },
    cacheState() {
      return setCache([this.marketing])
    },
    isChanged() {
      return JSON.stringify(this.marketing)
    },
  },
  methods: {
    urlUpdateMarketingCompany,
    updateMarketing() {
      this.loading = true
      return this.$axios
        .post(
          this.urlUpdateMarketingCompany(this.$route.params.id),
          this.marketing
        )
        .then(() => {
          this.$store.commit('refreshPage')
          this.$setSnackbar({ text: 'Рекламну компанiю оновлено' })
        })
        .finally(() => (this.loading = false))
        .catch(this.$err)
    },
    getSingleMarketing() {
      this.loading = true
      return this.$axios
        .get(urlGetSingleMarketingCompany(this.$route.params.id))
        .then(res => {
          this.marketing = res.data
          this.cache = setCache([this.marketing])
          return res
        })
        .finally(() => (this.loading = false))
        .catch(this.$err)
    },
    activateCompany() {
      const nowDate = new Date()
      const customDate =
        nowDate.getFullYear() +
        '-' +
        (nowDate.getMonth() + 1).toString().padStart(2, '0') +
        '-' +
        nowDate.getDate().toString().padStart(2, '0')
      const q = { isActive: true, date: customDate }
      this.loading = true
      this.$axios
        .post(urlDeactivateMarketingCompany(this.$route.params.id), q)
        .then(() => {
          this.$store.commit('refreshPage'), (this.loading = false)
        })
    },
    deactivateCompany() {
      const nowDate = new Date()
      const customDate =
        nowDate.getFullYear() +
        '-' +
        (nowDate.getMonth() + 1).toString().padStart(2, '0') +
        '-' +
        nowDate.getDate().toString().padStart(2, '0')
      const q = { isActive: false, date: customDate }
      this.loading = true
      this.$axios
        .post(urlDeactivateMarketingCompany(this.$route.params.id), q)
        .then(() => {
          this.$store.commit('refreshPage'), (this.loading = false)
        })
    },
    setBreadScrumb() {
      this.$store.commit('setBreadScrumbPart', [
        null,
        this.marketing.campaignName,
        null,
      ])
    },
  },
  watch: {
    loading(val) {
      if (!val)
        setTimeout(() => (this.cache = setCache([this.marketing])), 1000)
    },
  },
  async created() {
    await this.getSingleMarketing()
    this.setAdditionalDocsState([this.additionalFields])
    this.$store.dispatch('addCompaignType')
  },
}
</script>

<style scoped lang="scss">
a.v-tab-item--selected.v-tab {
  background: #f2f5fa;
  border: 1px solid #1c1d21;
  box-shadow: 0 2px 8px rgb(0 0 0 / 4%);
  border-radius: 4px;
}
a.v-tab {
  margin-right: 25px;
  border: 1px solid #eaeaea;
  background: #eaeaea;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
}
.list-item {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.subtitle {
  color: #838282;
  font-weight: 500;
  font-size: 13px;
  display: block;
}
.card-block-title {
  display: block;
  color: #08487a;
  font-weight: 600;
  font-size: 16px;
  margin-left: 9px;
}
.title-label {
  font-weight: 600;
  font-size: 15px;
  color: #1c1d21;
  margin-top: 5px;
  font-weight: 400 !important;
}
.data-card {
  display: flex;
  flex-direction: column;
}
.container.container--fluid {
  padding-left: 10px !important;
  padding-right: 10px !important;
  margin: 0 !important;
}

.text-desc {
  font-weight: 400;
  font-size: 16px;
  color: #1c1d21;
  display: block;
}
</style>
