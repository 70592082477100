<template>
  <div>
    <v-row class="align-center mt-3">
      <v-col cols="12" md="7" sm="12">
        <div class="mb-5 d-flex align-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="19"
            viewBox="0 0 20 19"
            fill="none">
            <path
              d="M5 18.875C5 18.875 3.75 18.875 3.75 17.625C3.75 16.375 5 12.625 10 12.625C15 12.625 16.25 16.375 16.25 17.625C16.25 18.875 15 18.875 15 18.875H5ZM10 11.4375C10.8288 11.4375 11.6237 11.1083 12.2097 10.5222C12.7958 9.93616 13.125 9.1413 13.125 8.3125C13.125 7.4837 12.7958 6.68884 12.2097 6.10279C11.6237 5.51674 10.8288 5.1875 10 5.1875C9.1712 5.1875 8.37634 5.51674 7.79029 6.10279C7.20424 6.68884 6.875 7.4837 6.875 8.3125C6.875 9.1413 7.20424 9.93616 7.79029 10.5222C8.37634 11.1083 9.1712 11.4375 10 11.4375Z"
              fill="#08487A" />
            <path
              d="M2.5 0.125C1.83696 0.125 1.20107 0.388392 0.732233 0.857233C0.263392 1.32607 0 1.96196 0 2.625V14.5C0 14.9973 0.197544 15.4742 0.549175 15.8258C0.900805 16.1775 1.37772 16.375 1.875 16.375H2.69125C2.95261 15.4564 3.40688 14.6041 4.02375 13.875H1.25V2.625C1.25 2.29348 1.3817 1.97554 1.61612 1.74112C1.85054 1.5067 2.16848 1.375 2.5 1.375H17.5C17.8315 1.375 18.1495 1.5067 18.3839 1.74112C18.6183 1.97554 18.75 2.29348 18.75 2.625V13.875H15.9762C16.6688 14.6925 17.0888 15.5913 17.3088 16.375H18.125C18.6223 16.375 19.0992 16.1775 19.4508 15.8258C19.8025 15.4742 20 14.9973 20 14.5V2.625C20 1.96196 19.7366 1.32607 19.2678 0.857233C18.7989 0.388392 18.163 0.125 17.5 0.125H2.5Z"
              fill="#08487A" />
          </svg>
          <span class="card-block-title">Статутний капітал та засновники</span>
        </div>
      </v-col>
      <v-col cols="12" md="5" sm="12">
        <div
          class="d-flex capital"
          :style="{ height: $vuetify.breakpoint?.xs ? 'auto' : '70px' }">
          <div
            style="
              width: 100%;
              padding: 10px;
              display: flex;
              justify-content: space-between;
              align-items: center;
            ">
            <v-row class="align-center">
              <v-col cols="12" md="12" sm="12">
                <div class="d-flex flex-column">
                  <span class="area-label">Розмір статутного капіталу</span>
                  <div v-html="getAuthorizedCapital()"></div>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
    </v-row>
    <list-section
      style="margin-top: 0 !important"
      :title="'Засновники'"
      :addActionDisabled="readonly"
      :addActionTitle="'Додати засновника'"
      :addAction="
        () => {
          maxFounderPercent === 0
            ? setSnackbar({ text: 'Структура сформована повнicтю' })
            : openDialog([
                'addBeneficiar',
                {},
                { maxPercent: maxFounderPercent },
              ])
        }
      ">
      <template #body>
        <v-data-table
          v-if="contractorData.founders"
          :headers="tHeadOwners"
          :items="contractorData.founders"
          hide-default-footer
          :hide-default-header="!contractorData.founders"
          class="pb-2 custom-table head-line"
          dense>
          <!-- eslint-disable-next-line -->
          <template #no-data>
            <no-data></no-data>
          </template>
          <template #item.address="{ item }">
            <v-menu left offset-x>
              <template #activator="{ props }">
                <div v-bind="props">
                  <v-btn icon size="small">
                    <v-icon color="#09487a">{{
                      'mdi-map-marker-multiple-outline'
                    }}</v-icon>
                  </v-btn>
                </div>
              </template>
              <div class="pa-5" style="background: #fff">
                <div class="d-flex align-center">
                  <span class="card-sublable mr-1">Країна резиденства:</span>
                  <span class="card-label">{{
                    item.residentCountry?.name || '---'
                  }}</span>
                </div>
                <div class="d-flex align-center">
                  <span class="card-sublable mr-1">Країна реєстрації:</span>
                  <span class="card-label">{{
                    item.registrationCountry?.name || '---'
                  }}</span>
                </div>
                <div class="d-flex align-center mt-2">
                  <span class="card-sublable mr-1">Адреса:</span>
                  <span class="card-label">{{ item.location || '---' }}</span>
                </div>
              </div>
            </v-menu>
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.name="{ item }">
            <span style="font-size: 13px">{{ item.name || '---' }}</span>
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.code="{ item }">
            <span style="font-size: 13px">{{ item.code || '---' }}</span>
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.founderRoleId="{ item }">
            <span style="font-size: 13px">{{ item.founder_role.name }}</span>
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.amount="{ item }">
            <span style="font-size: 13px"
              >{{ getBeautyNum(item.amount, { float: 2 }) }} грн</span
            >
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.amountPercent="{ item }">
            <span style="font-size: 13px">
              {{ getBeautyNum(item.amountPercent, { float: 6, round: true }) }}
              %</span
            >
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.actions="{ item }">
            <div style="margin-right: -10px">
              <TableCrud
                data-readonly="hide"
                :actionsArray="[
                  {
                    action: () =>
                      openDialog([
                        'deleteBeneficiar',
                        item,
                        { maxPercent: maxFounderPercent + item.amountPercent },
                      ]),
                    text: 'Видалити',
                    icon: 'DeleteIcon',
                    customIcon: true,
                    disabled: readonly,
                  },
                  {
                    action: () =>
                      openDialog([
                        'editBeneficiar',
                        item,
                        { maxPercent: maxFounderPercent + item.amountPercent },
                      ]),
                    text: 'Редагувати',
                    icon: 'EditPencil',
                    customIcon: true,
                    disabled: readonly,
                  },
                ]">
              </TableCrud>
            </div>
          </template>
        </v-data-table>
      </template>
    </list-section>
    <div>
      <div>
        <v-scroll-x-transition hide-on-leave>
          <div>
            <list-section
              style="margin-top: 0 !important"
              title="Повязаностi компанiй"
              :elevation="0"
              :addActionTitle="'Додати групу компаній'"
              :addAction="() => openDialog(['addCompanyGroup'])"
              :addActionDisabled="readonly || !contractorData.isCompanyGroup">
              <template #body>
                <v-checkbox
                  v-model="contractorData.isCompanyGroup"
                  :readonly="readonly"
                  color="red"
                  hide-details
                  small
                  dense>
                  <template #label>
                    <span style="font-size: 0.7rem">
                      Приналежнiсть до групи компаній
                    </span>
                  </template>
                </v-checkbox>
                <v-data-table
                  v-if="contractorData.companyGroups"
                  v-show="contractorData.isCompanyGroup"
                  :headers="tHeadCompanyGroup"
                  :items="contractorData.companyGroups || []"
                  class="custom-table"
                  hide-default-footer>
                  <!-- eslint-disable-next-line -->
                  <template #item.company.shortName="{ item }">
                    <ALink
                      style="font-size: 11px !important"
                      :text="item.company.shortName"
                      :route="{
                        name: 'contractors-form',
                        params: { id: item.company.id },
                      }" />
                  </template>
                  <!-- eslint-disable-next-line -->
                  <template #item.actions="{ item }">
                    <TableCrud
                      :actionsArray="[
                        {
                          action: () => removeCompanyGroup(item),
                          text: 'Видалити',
                          icon: 'DeleteIcon',
                          customIcon: true,
                          disabled: readonly,
                        },
                        {
                          action: () => openDialog(['editCompanyGroup', item]),
                          text: 'Редагувати',
                          icon: 'EditPencil',
                          customIcon: true,
                          disabled: readonly,
                        },
                      ]">
                    </TableCrud>
                  </template>
                </v-data-table>
              </template>
            </list-section>
          </div>
        </v-scroll-x-transition>
      </div>
    </div>
    <div class="mb-5 mt-5 d-flex align-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none">
        <path
          d="M9.99998 9.08325C11.2156 9.08325 12.3813 9.56614 13.2409 10.4257C14.1004 11.2852 14.5833 12.451 14.5833 13.6666V19.1666H5.41665V13.6666C5.41665 12.451 5.89953 11.2852 6.75907 10.4257C7.61862 9.56614 8.7844 9.08325 9.99998 9.08325ZM3.84731 11.8388C3.70143 12.3308 3.61526 12.8386 3.59065 13.3513L3.58331 13.6666V19.1666H0.833313V15.0416C0.833133 14.251 1.12484 13.4882 1.65248 12.8995C2.18012 12.3108 2.90654 11.9376 3.6924 11.8516L3.84731 11.8388ZM16.1526 11.8388C16.9684 11.8885 17.7344 12.2475 18.2945 12.8427C18.8546 13.4379 19.1665 14.2243 19.1666 15.0416V19.1666H16.4166V13.6666C16.4166 13.0313 16.325 12.4181 16.1526 11.8388ZM4.04165 6.33325C4.64943 6.33325 5.23233 6.57469 5.6621 7.00447C6.09187 7.43424 6.33331 8.01713 6.33331 8.62492C6.33331 9.23271 6.09187 9.8156 5.6621 10.2454C5.23233 10.6751 4.64943 10.9166 4.04165 10.9166C3.43386 10.9166 2.85096 10.6751 2.42119 10.2454C1.99142 9.8156 1.74998 9.23271 1.74998 8.62492C1.74998 8.01713 1.99142 7.43424 2.42119 7.00447C2.85096 6.57469 3.43386 6.33325 4.04165 6.33325ZM15.9583 6.33325C16.5661 6.33325 17.149 6.57469 17.5788 7.00447C18.0085 7.43424 18.25 8.01713 18.25 8.62492C18.25 9.23271 18.0085 9.8156 17.5788 10.2454C17.149 10.6751 16.5661 10.9166 15.9583 10.9166C15.3505 10.9166 14.7676 10.6751 14.3379 10.2454C13.9081 9.8156 13.6666 9.23271 13.6666 8.62492C13.6666 8.01713 13.9081 7.43424 14.3379 7.00447C14.7676 6.57469 15.3505 6.33325 15.9583 6.33325ZM9.99998 0.833252C10.9724 0.833252 11.9051 1.21956 12.5927 1.90719C13.2803 2.59483 13.6666 3.52746 13.6666 4.49992C13.6666 5.47238 13.2803 6.40501 12.5927 7.09264C11.9051 7.78028 10.9724 8.16658 9.99998 8.16658C9.02752 8.16658 8.09489 7.78028 7.40725 7.09264C6.71962 6.40501 6.33331 5.47238 6.33331 4.49992C6.33331 3.52746 6.71962 2.59483 7.40725 1.90719C8.09489 1.21956 9.02752 0.833252 9.99998 0.833252Z"
          fill="#08487A" />
      </svg>
      <span class="card-block-title">Працівники</span>
    </div>
    <div>
      <div>
        <v-row>
          <v-col :cols="6" :md="3" class="pt-0 pb-0">
            <span class="area-label">Виконавчий орган</span>
            <v-select
              v-model="contractorData.executiveAuthorityId"
              :readonly="readonly"
              :items="
                directoryItems(
                  'executiveAuthorities',
                  contractorData.executiveauthority
                )
              "
              item-title="name"
              placeholder="Оберiть значення"
              item-value="id"
              @focus="asyncAction('addExecutiveAuthorities')">
            </v-select>
          </v-col>
          <v-col :cols="6" :md="3" class="pt-0 pb-0">
            <span class="area-label">Кількість співробітників, чол</span>
            <v-text-field
              id="employees-length-input"
              v-model="contractorData.staffQuantity"
              :readonly="readonly"
              placeholder="Введіть значення"
              @input="maxNumLength('employees-length-input', 10)">
            </v-text-field>
          </v-col>
        </v-row>
        <list-section
          v-if="
            contractorData.executiveAuthorityId === C.authorityType.SINGLE_OWNER
          "
          title="Керiвник"
          style="background: transparent; margin-top: -20px !important"
          :addActionDisabled="readonly"
          addActionTitle="Створити керiвника"
          :addAction="
            () =>
              !contractorData.director
                ? setDialog(
                    'Керiвник',
                    {},
                    {
                      contractorId: $route.params.id,
                      cb: () => $emit('tableChange'),
                    },
                    'addContractorDirector'
                  )
                : $setSnackbar({ text: 'Керiвник вже існує' })
          "
          addActionTwoTitle="Додати керiвника"
          :addActionTwo="
            () =>
              !contractorData.director
                ? setDialog(
                    'Керiвник',
                    {},
                    {
                      contractorId: $route.params.id,
                      cb: () => $emit('tableChange'),
                    },
                    'addContractorContactDirectior'
                  )
                : $setSnackbar({ text: 'Керiвник вже існує' })
          "
          :elevation="0"
          :mt="0">
          <template #body>
            <ExecutiveAuthorities
              :contractor="contractorData"
              :tableHeader="[
                {
                  title: 'ПIБ',
                  key: 'fullName',
                  sortable: false,
                  align: 'start',
                },
                {
                  title: 'Посада',
                  key: 'post',
                  sortable: false,
                  align: 'start',
                },
                {
                  title: 'Дії',
                  key: 'actions',
                  sortable: false,
                  align: 'end',
                },
              ]"
              :actions="
                item => [
                  {
                    action: () => deleteDirector(item),
                    text: 'Видалити',
                    icon: 'DeleteIcon',
                    customIcon: true,
                    disabled: readonly,
                  },
                  {
                    action: () => editDirector(item),
                    text: 'Редагувати',
                    icon: 'EditPencil',
                    customIcon: true,
                    disabled: readonly,
                  },
                ]
              ">
            </ExecutiveAuthorities>
          </template>
        </list-section>
        <list-section
          v-if="
            contractorData.executiveAuthorityId === C.authorityType.COLLEGIUM
          "
          class="mt-0 pt-0"
          title="Колегiальна дирекцiя"
          style="background: transparent; margin-top: -20px !important"
          :addActionDisabled="readonly"
          addActionTitle="Додати директора"
          :addAction="
            () =>
              setDialog(
                'Директор',
                {},
                {
                  contractorId: $route.params.id,
                  cb: () => $emit('tableChange'),
                },
                'addContractorCollegiumParticipant'
              )
          "
          :elevation="0"
          :mt="0">
          <template #body>
            <ExecutiveAuthorities
              :contractor="contractorData"
              :tableHeader="[
                {
                  title: 'ПIБ',
                  key: 'fullName',
                  sortable: false,
                  align: 'start',
                },
                {
                  title: 'Посада',
                  key: 'post',
                  sortable: false,
                  align: 'start',
                },
                {
                  title: 'Дії',
                  key: 'actions',
                  sortable: false,
                  align: 'end',
                },
              ]"
              :actions="
                item => [
                  {
                    action: () => deleteCollegiumParticipant(item),
                    text: 'Видалити',
                    icon: 'DeleteIcon',
                    customIcon: true,
                    disabled: readonly,
                  },
                  {
                    action: () => editCollegiumDirector(item),
                    text: 'Редагувати',
                    icon: 'EditPencil',
                    customIcon: true,
                    disabled: readonly,
                  },
                ]
              ">
            </ExecutiveAuthorities>
          </template>
        </list-section>
      </div>
    </div>
    <div>
      <div>
        <v-scroll-x-transition hide-on-leave>
          <div>
            <list-section
              title="Кінцеві бенефіціарні власники"
              :addActionDisabled="readonly || contractorData.isKbv"
              addActionTitle="Додати бенефiцiарного власника"
              :addAction="
                () =>
                  maxBeneficiarPercent === 0
                    ? $setSnackbar({
                        text: 'Структура сформована повнiстю',
                      })
                    : $store.commit('setDialog', {
                        title: 'Бенефiцiарний власник',
                        dialogItem: {},
                        params: {
                          contractorId: contractorId,
                          maxPercent: maxBeneficiarPercent,
                          dialogWidth: 450,
                          cb: () => {
                            $setSnackbar({
                              text: 'Додано бенефiцiарного власника',
                            })
                            $emit('tableChange')
                          },
                        },
                        action: 'addContractorKbv',
                      })
              "
              addActionTwoTitle="Створити Бенефiцiарного власника"
              :addActionTwo="
                () =>
                  maxBeneficiarPercent === 0
                    ? $setSnackbar({
                        text: 'Структура сформована повнiстю',
                      })
                    : $store.commit('setDialog', {
                        title: 'Бенефiцiарний власник',
                        dialogItem: {},
                        params: {
                          contractorId: contractorId,
                          maxPercent: maxBeneficiarPercent,
                          dialogWidth: 450,
                          cb: () => {
                            $setSnackbar({
                              text: 'Створено бенефiцiарного власника',
                            })
                            $emit('tableChange')
                          },
                        },
                        action: 'createContractorKbv',
                      })
              ">
              <template #body>
                <div>
                  <v-checkbox
                    v-model="contractorData.isKbv"
                    :readonly="readonly"
                    color="red"
                    hide-details
                    small
                    dense
                    @change="
                      () =>
                        contractorData.beneficiaryOwners &&
                        contractorData.beneficiaryOwners.splice(0)
                    ">
                    <template #label>
                      <span style="font-size: 0.7rem">
                        Відсутні фізичні особи-власники
                      </span>
                    </template>
                  </v-checkbox>
                  <v-data-table
                    v-if="contractorData.beneficiaryOwners"
                    v-show="!contractorData.isKbv"
                    :headers="tHeadBeneficiars"
                    :items="contractorData.beneficiaryOwners"
                    hide-default-footer
                    :hide-default-header="!contractorData.beneficiaryOwners"
                    class="pb-2 pt-1 custom-table head-line"
                    dense>
                    <!-- eslint-disable-next-line -->
                    <template #no-data>
                      <no-data></no-data>
                    </template>
                    <!-- eslint-disable-next-line -->
                    <template #item.contact.inn="{ item }">
                      <span style="font-size: 13px">{{
                        item.contact.inn || '---'
                      }}</span>
                    </template>
                    <!-- eslint-disable-next-line -->
                    <template #item.contact.nationality="{ item }">
                      <span style="font-size: 13px">{{
                        item.contact.nationality &&
                        item.contact.nationality.name
                          ? item.contact.nationality?.name
                          : '---'
                      }}</span>
                    </template>
                    <!-- eslint-disable-next-line -->
                    <template #item.contact="{ item }">
                      <a
                        style="font-size: 13px"
                        @click="
                          $router.push({
                            name: 'individuals-form',
                            params: { id: item.contact.id },
                          })
                        "
                        >{{ item?.contact?.fullName }}</a
                      >
                    </template>
                    <!-- eslint-disable-next-line -->
                    <template #item.actions="{ item }">
                      <TableCrud
                        data-readonly="hide"
                        :actionsArray="[
                          {
                            action: () =>
                              $store.commit('setDialog', {
                                title: 'Бенефiцiарний власник',
                                dialogItem: {
                                  fullName: $string(
                                    () => item?.contact?.fullName
                                  ),
                                  surname: $string(
                                    () => item?.contact?.surname
                                  ),
                                  name: $string(() => item.contact.name),
                                  patronymic: $string(
                                    () => item.contact.patronymic
                                  ),
                                  inn: $string(() => item.contact.inn),
                                  birthday: $string(
                                    () => item.contact.birthday
                                  ),
                                  nationality: item.contact.nationality,
                                  influence: item.influence,
                                  percent: item.percent,
                                  signKbv: item.signKbv,
                                  id: $number(() => item.id),
                                },
                                params: {
                                  contractorId: contractorId,
                                  maxPercent: maxBeneficiarPercent,
                                  dialogWidth: 600,
                                  cb: () => {
                                    $setSnackbar(
                                      'Бенефiцiарного власника видалено'
                                    )
                                    $emit('tableChange')
                                  },
                                },
                                action: 'deleteContractorKbv',
                              }),
                            text: 'Видалити',
                            icon: 'DeleteIcon',
                            customIcon: true,
                            disabled: readonly,
                          },
                          {
                            action: () =>
                              $store.commit('setDialog', {
                                title: 'Бенефiцiарний власник',
                                dialogItem: item,
                                params: {
                                  contractorId: contractorId,
                                  maxPercent:
                                    maxBeneficiarPercent + +item.percent,
                                  dialogWidth: 600,
                                  cb: () => {
                                    $setSnackbar(
                                      'Бенефiцiарного власника оновлено'
                                    )
                                    $emit('tableChange')
                                  },
                                },
                                action: 'editContractorKbv',
                              }),
                            text: 'Редагувати',
                            icon: 'EditPencil',
                            customIcon: true,
                            disabled: readonly,
                          },
                        ]">
                      </TableCrud>
                    </template>
                  </v-data-table>
                </div>
              </template>
            </list-section>
          </div>
        </v-scroll-x-transition>
      </div>
    </div>
    <list-section
      style="margin-top: -20px"
      :title="'Cпiвробiтники'"
      :addActionDisabled="readonly"
      :addActionTitle="'Створити спiвробiтника'"
      :addActionTwoTitle="'Додати спiвробiтника'"
      :addActionTwo="
        () =>
          $store.commit('setDialog', {
            title: 'Контакт',
            dialogItem: {
              contractorId: parseInt($route.params.id),
              fullPostName: null,
              postId: null,
              contactId: null,
              departmentId: null,
              roleId: null,
              isSignatureRight: false,
            },
            params: {
              hideConnections: true,
              hideContractor: true,
              cb: () => false,
            },
            action: 'addContactToCareer',
          })
      "
      :addAction="
        () =>
          $store.commit('setDialog', {
            title: 'Контакт',
            dialogItem: {
              surname: null,
              patronymic: null,
              mainPhone: null,
              mainEmail: null,
              fullPostName: null,
              postId: null,
              contractorId: parseInt($route.params.id),
              isSignatureRight: false,
            },
            params: {
              hideConnections: true,
              hideContractor: true,
              cb: () => false,
            },
            action: 'createContactToCareer',
          })
      ">
      <template #body>
        <v-data-table
          v-if="contractorData.employees"
          :headers="tHead"
          :items="contractorData.employees"
          :hide-default-footer="!contractorData.employees"
          :hide-default-header="!contractorData.employees"
          class="pb-2 pt-1 custom-table head-line"
          dense>
          <!-- eslint-disable-next-line -->
          <template #item.isSignatureRight="{ item }">
            <div style="position: relative">
              <div class="d-flex align-center" style="justify-content: center">
                <v-checkbox
                  v-model="item.isSignatureRight"
                  style="
                    transform: scale(0.7);
                    margin-top: 0 !important;
                    padding-top: 0 !important;
                  "
                  readonly
                  hide-details
                  dense>
                </v-checkbox>
                <v-btn
                  v-if="item.isSignatureRight"
                  style="margin-top: -5px"
                  icon
                  size="small"
                  class="nameBlock__wrapper">
                  <svg
                    class="ml-1"
                    style="margin-top: 5px"
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 18 18"
                    fill="none">
                    <path
                      d="M2 0H16C16.5304 0 17.0391 0.210714 17.4142 0.585786C17.7893 0.960859 18 1.46957 18 2V16C18 16.53 17.79 17.04 17.41 17.41C17.04 17.79 16.53 18 16 18H2C1.47 18 0.96 17.79 0.59 17.41C0.21 17.04 0 16.53 0 16V2C0 0.89 0.89 0 2 0ZM9.3 4.29C9.11 4.5 9 4.74 9 5C9 5.27 9.11 5.5 9.3 5.71C9.5 5.9 9.74 6 10 6C10.27 6 10.5 5.9 10.71 5.71C10.9 5.5 11 5.27 11 5C11 4.74 10.9 4.5 10.71 4.29C10.5 4.11 10.27 4 10 4C9.74 4 9.5 4.11 9.3 4.29ZM6.8 8.97C6.7 9.06 6.7 9.07 6.77 9.17L6.82 9.25L6.85 9.31C6.92 9.44 6.93 9.44 7.04 9.36C7.17 9.27 7.39 9.13 7.76 8.91C8.68 8.32 8.5 9 8.09 10.5C7.87 11.33 7.59 12.39 7.38 13.62C7.14 15.37 8.71 14.47 9.12 14.2C9.5 13.96 10.44 13.3 10.66 13.15L10.7 13.13C10.82 13.04 10.77 13 10.68 12.86L10.62 12.78C10.54 12.67 10.46 12.75 10.46 12.75L10.3 12.86C9.85 13.16 9.23 13.59 9.13 13.25C9.04 13 9.41 11.64 9.79 10.25C9.96 9.64 10.13 9 10.26 8.47L10.28 8.41C10.35 7.97 10.5 7.12 9.77 7.18C8.97 7.25 6.8 8.97 6.8 8.97Z"
                      fill="#FC7247" />
                  </svg>
                </v-btn>

                <div class="nameBlockInfo">
                  <div class="nameBlockInfo__wrapper">
                    <div>
                      <span>Підписант на підставі:</span>
                      <div v-if="item.signatoryBases">
                        <div v-for="p in item.signatoryBases" :key="p.id">
                          <span
                            v-if="[2, 9].includes(p?.basisDocument?.id)"
                            class="font-weight-bold">
                            {{ p?.basisDocument?.name }} ({{
                              p?.documentComment
                            }})
                          </span>
                          <span v-else class="font-weight-bold"
                            >{{ p?.basisDocument?.name }}
                          </span>
                        </div>
                      </div>
                      <div v-else>Дані не вказано</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.post="{ item }">
            <div class="d-flex align-center">
              <span style="font-size: 13px">{{
                item.post ? item.post.name : '---'
              }}</span>
              <v-menu right offset-x>
                <template #activator="{ props }">
                  <v-btn
                    v-if="item.fullPostName"
                    style="margin-top: -5px"
                    icon
                    size="x-small"
                    v-bind="props">
                    <svg
                      class="ml-1"
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 18 18"
                      fill="none">
                      <path
                        d="M2 0H16C16.5304 0 17.0391 0.210714 17.4142 0.585786C17.7893 0.960859 18 1.46957 18 2V16C18 16.53 17.79 17.04 17.41 17.41C17.04 17.79 16.53 18 16 18H2C1.47 18 0.96 17.79 0.59 17.41C0.21 17.04 0 16.53 0 16V2C0 0.89 0.89 0 2 0ZM9.3 4.29C9.11 4.5 9 4.74 9 5C9 5.27 9.11 5.5 9.3 5.71C9.5 5.9 9.74 6 10 6C10.27 6 10.5 5.9 10.71 5.71C10.9 5.5 11 5.27 11 5C11 4.74 10.9 4.5 10.71 4.29C10.5 4.11 10.27 4 10 4C9.74 4 9.5 4.11 9.3 4.29ZM6.8 8.97C6.7 9.06 6.7 9.07 6.77 9.17L6.82 9.25L6.85 9.31C6.92 9.44 6.93 9.44 7.04 9.36C7.17 9.27 7.39 9.13 7.76 8.91C8.68 8.32 8.5 9 8.09 10.5C7.87 11.33 7.59 12.39 7.38 13.62C7.14 15.37 8.71 14.47 9.12 14.2C9.5 13.96 10.44 13.3 10.66 13.15L10.7 13.13C10.82 13.04 10.77 13 10.68 12.86L10.62 12.78C10.54 12.67 10.46 12.75 10.46 12.75L10.3 12.86C9.85 13.16 9.23 13.59 9.13 13.25C9.04 13 9.41 11.64 9.79 10.25C9.96 9.64 10.13 9 10.26 8.47L10.28 8.41C10.35 7.97 10.5 7.12 9.77 7.18C8.97 7.25 6.8 8.97 6.8 8.97Z"
                        fill="#FC7247" />
                    </svg>
                  </v-btn>
                </template>
                <div class="pa-3" style="background: #fff">
                  <span style="font-size: 13px">{{
                    item.fullPostName || '---'
                  }}</span>
                </div>
              </v-menu>
            </div>
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.role="{ item }">
            <span style="font-size: 13px">{{
              item.role ? item.role.name : '---'
            }}</span>
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.finishDate="{ item }">
            <span style="font-size: 13px">{{ item.finishDate || '---' }}</span>
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.startDate="{ item }">
            <span style="font-size: 13px">{{ item.startDate || '---' }}</span>
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.department="{ item }">
            <span style="font-size: 13px">{{
              (item.department && item.department.name) || '---'
            }}</span>
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.contact="{ item }">
            <a
              style="font-size: 13px !important"
              @click="pushToContact(item)"
              >{{ item?.contact?.fullName }}</a
            >
          </template>
          <!-- eslint-disable-next-line -->
          <template #no-data>
            <no-data></no-data>
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.actions="{ item }">
            <div style="margin-right: -10px">
              <TableCrud
                data-readonly="hide"
                :actionsArray="[
                  {
                    action: () => deleteCareer(item),
                    text: 'Видалити',
                    icon: 'DeleteIcon',
                    customIcon: true,
                    disabled: readonly,
                  },
                  {
                    action: () =>
                      editCareer(
                        { ...item, contractor },
                        { cb: () => $emit('tableChange') }
                      ),
                    text: 'Редагувати',
                    icon: 'EditPencil',
                    customIcon: true,
                    disabled: readonly,
                  },
                ]">
              </TableCrud>
            </div>
          </template>
        </v-data-table>
      </template>
    </list-section>
  </div>
</template>
<script>
import listSection from '@/components/list-section.vue'
import noData from '@/components/no-table-data.vue'
import TableCrud from '@/components/table-crud.vue'
import ExecutiveAuthorities from '@/components/ExecutiveAuthorityTable.vue'
import ALink from '@/components/Link.vue'
import { mapState } from 'vuex'
import {
  formutSum,
  keepDigit,
  changeInputVal,
  maxNum,
} from '@/utils/formatFunc'
import {
  getSafe,
  contactFilter,
  editCareer,
  toFormatDate,
  setErrHandler,
  getBeautyNum,
  getAuthorizedCapital,
} from '@/utils/helperFunc'
import {
  urlDeleteBeneficiar,
  urlDeleteContactCareer,
  urlSetBeneficiarOwner,
  urlDeleteCompanyGroup,
} from '@/pages/request'
import { maxNumLength } from '@/utils/masks'
import { contractor as C } from '@/type-ids'
import { useSelect } from '@/utils/mixins/useSelect'
import { setSnackbar } from 'best-modules/plugins/index'
export default {
  components: {
    ExecutiveAuthorities,
    listSection,
    noData,
    TableCrud,
    ALink,
  },
  emits: ['updateContractor', 'openDialog', 'tableChange'],
  setup() {
    return {
      ...useSelect(),
    }
  },
  props: {
    readonly: { type: Boolean },
    contractorId: { type: Number },
    contractorList: { type: Array },
    contractor: { type: Object },
    v: { type: Object },
  },
  data: () => ({
    edrpou: null,
    tempCompanyGroupName: null,
    contact: {
      inn: null,
      name: null,
      surname: null,
      patronymic: null,
    },
    contractors: [],
    C: C, // contractor ids
    owners: [],
    tHeadBeneficiars: [
      { title: 'ПIБ', key: 'contact', sortable: false },
      { title: 'Код', key: 'contact.inn', sortable: false },
      { title: 'Громадянство', key: 'contact.nationality', sortable: false },
      {
        title: 'Доля в статутному капіталі (%)',
        key: 'percent',
        sortable: false,
        align: 'center',
      },
      { title: 'Дiї', key: 'actions', sortable: false, align: 'end' },
    ],
    tHeadCompanyGroup: [
      { title: 'Назва', key: 'company.shortName', sortable: false },
      {
        title: 'ЄДРПОУ/Реєстраційний номер',
        key: 'company.edrpou',
        sortable: false,
      },
      { title: 'Країна реєстрації', key: 'nationality.name', sortable: false },
      { title: 'Повязаності', key: 'connection.name', sortable: false },
      { title: 'Дії', key: 'actions', sortable: false, align: 'end' },
    ],
    tHeadOwners: [
      { title: 'Назва', key: 'name', sortable: false },
      { title: 'IНН/ЄДРПОУ', key: 'code', sortable: false, align: 'start' },
      {
        title: 'Частка статутного капiталу - грн',
        key: 'amount',
        align: 'center',
        sortable: false,
      },
      {
        title: 'Частка статутного капiталу - %',
        key: 'amountPercent',
        align: 'center',
        sortable: false,
      },
      { title: 'Адреса', key: 'address', sortable: false, align: 'center' },
      { title: 'Дiї', key: 'actions', sortable: false, align: 'end' },
    ],
    tHeadExecutive: [
      { title: 'ПIБ', key: 'initials', sortable: false },
      { title: 'Посада', key: 'position', sortable: false },
      {
        title: 'Наказ про призначення на посаду',
        key: 'orderOfAppointment',
        sortable: false,
      },
      {
        title: 'Протокол ЗЗУ по призначення керівника',
        key: 'protocol',
        sortable: false,
      },
      {
        title: 'Наявність обмежень повноважень керівника',
        key: 'restrictions',
        sortable: false,
      },
    ],
    tHead: [
      { title: 'ПIБ', key: 'contact', sortable: false },
      { title: 'Посада', key: 'post', sortable: false },
      { title: 'Департамент', key: 'department', sortable: false },
      { title: 'Роль', key: 'role', sortable: false },
      {
        title: 'Підписант',
        key: 'isSignatureRight',
        sortable: false,
        align: 'center',
      },
      { title: 'Дiї', key: 'actions', sortable: false, align: 'end' },
    ],
    tBodyExecutive: [],
    tHeadRepresentative: [
      { title: 'ПIБ', key: 'initials', sortable: false },
      { title: 'Посада', key: 'position', sortable: false },
      {
        title: 'Документ, що надає повноваження (Тип, № та дата)',
        key: 'orderOfAppointment',
        sortable: false,
      },
    ],
    tBodyRepresentative: [],
    tHeadEmploye: [
      { title: 'ПІБ', key: 'initials', sortable: false },
      { title: 'Посада', key: 'position', sortable: false },
      { title: 'Моб.телефон', key: 'phone', sortable: false },
      { title: 'Email', key: 'email', sortable: false },
    ],
  }),
  computed: {
    ...mapState({ selectItems: state => state.selectItems }),
    contractorData() {
      return this.contractor
    },
    maxFounderPercent() {
      if (!this.contractorData.founders) return 100
      return (
        100 -
        this.contractorData.founders
          .map(v => parseFloat(v.amountPercent))
          .reduce((acc, curr) => acc + curr)
      )
    },
    maxBeneficiarPercent() {
      if (!this.contractorData.beneficiaryOwners) return 100
      return (
        100 -
        this.contractorData.beneficiaryOwners
          .map(v => parseFloat(v.percent))
          .reduce((acc, curr) => acc + curr)
      )
    },
    routeId() {
      return this.$route.params.id
    },
  },
  methods: {
    setSnackbar,
    getAuthorizedCapital,
    contactFilter,
    formutSum,
    keepDigit,
    changeInputVal,
    maxNum,
    urlDeleteBeneficiar,
    maxNumLength,
    urlDeleteContactCareer,
    urlSetBeneficiarOwner,
    editCareer,
    toFormatDate,
    setErrHandler,
    getSafe,
    getBeautyNum,
    openDialog(args) {
      console.log('args', args)
      this.$emit('openDialog', args)
    },
    async removeCompanyGroup(companyGroup) {
      await this.$axios.delete(urlDeleteCompanyGroup(companyGroup.id))
      this.$emit('updateContractor')
    },
    setDialog(title = null, item = {}, params = {}, action = null) {
      this.$store.commit('setDialog', {
        title: title,
        dialogItem: item,
        params: params,
        action: action,
      })
    },
    deleteCareer(item) {
      return this.$axios
        .delete(this.urlDeleteContactCareer(item.id))
        .then(() => {
          this.$setSnackbar({ text: "Кар'єру видалено" })
          this.$emit('tableChange')
        })
        .catch(this.$err)
    },
    editCollegiumDirector(item) {
      this.$store.commit('setDialog', {
        title: 'Директор',
        dialogItem: {
          contactId: item.contactId,
          directorPostId: item.directorPostId,
          directorPost: item.post,
        },
        params: {
          contractorId: this.$route.params.id,
          cb: () => {
            this.$setSnackbar({ text: 'Директора вiдредаговано' })
            this.$emit('tableChange')
          },
        },
        action: 'editCollegiumDirector',
      })
    },
    editDirector(item) {
      this.$store.commit('setDialog', {
        title: 'Керiвник',
        dialogItem: {
          directorContact: item.contact.id,
          directorPostId: item.post.id,
          directorPost: item.post,
          contact: item.contact,
        },
        params: {
          contractorId: this.$route.params.id,
          cb: () => {
            this.$setSnackbar({ text: 'Керiвника вiдредаговано' })
            this.$emit('tableChange')
          },
        },
        action: 'editContractorDirectior',
      })
    },
    deleteDirector(item) {
      this.$store.commit('setDialog', {
        title: 'Керiвник',
        dialogItem: item,
        params: {
          cb: () => {
            this.$setSnackbar({ text: 'Керiвника видалено' })
            this.$emit('tableChange')
          },
        },
        action: 'deleteContractorDirector',
      })
    },
    deleteCollegiumParticipant(item) {
      this.$store.commit('setDialog', {
        title: 'Директор',
        dialogItem: item,
        params: {
          cb: () => {
            this.$setSnackbar({ text: 'Директора видалено' })
            this.$emit('tableChange')
          },
        },
        action: 'deleteCollegiumParticipant',
      })
    },
    pushToContact(employee) {
      this.$router.push({
        name: 'individuals-form',
        params: { id: employee.contact.id },
      })
    },
    deleteBeneficiar(id) {
      return this.$axios
        .delete(this.urlDeleteBeneficiar(id))
        .then(res => {
          this.$setSnackbar({ text: 'Бенефiцiара успiшно видалено' })
          this.$emit('tableChange')
          return res
        })
        .catch(this.$err)
    },
  },
  watch: {
    routeId(val) {
      val && this.$emit('updateContractor')
    },
  },
}
</script>
<style scoped lang="scss">
.card-block-title {
  display: block;
  color: #08487a;
  font-weight: 600;
  font-size: 16px;
  margin-left: 9px;
}
.capital {
  background: #efefef;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
}
.v-input--selection-controls {
  margin-top: 0 !important;
}
.nameBlock__wrapper:hover + .nameBlockInfo {
  display: block;
}
.card-sublable {
  color: #838282;
  font-weight: 500;
  font-size: 13px;
}
.card-label {
  color: #000 !important;
}
.nameBlock {
  position: relative;
  &__wrapper {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
  }
  &Info {
    display: none;
    position: absolute;
    z-index: 10000000;
    width: 300px;
    left: -300px;
    top: -50px;
    background: white;
    -webkit-box-shadow: 0 0 32px -6px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0 0 32px -6px rgba(0, 0, 0, 0.75);
    box-shadow: 0 0 13px -6px rgba(0, 0, 0, 0.75);
    border-radius: 7px;
    &__wrapper {
      padding: 10px;
    }
  }
}
</style>
