import {
  urlGetLeadStatuses,
  urlGetContactTypes,
  urlContractorsType,
  urlUserByRole,
  urlGetNationalList,
  urlGetInfluenceList,
  urlGetSignKbvList,
  urlGetGenderList,
  urlContractorActivityTypes,
  urlGetCarrers,
  urlGetDepartments,
  urlGetKlpSolutions,
  urlGetCareerRoles,
  urlGetMFO,
  urlCurrencies,
  urlContractorAddressTypes,
  urlContactAddressTypes,
  urlContactsGetAll,
  urlContactsGetAllDetailed,
  urlGetAllContractors,
  urlGetContractorsList,
  urlCountries,
  urlContractorIndastries,
  urlContractorCategories,
  urlGetEducationTypes,
  urlGetBankAccountTypes,
  urlGetProviders,
  urlGetDirectoriesTableNames,
  urlAnalysisTypes,
  urlGetDkpTypes,
  urlGetDkpOrderCalculations,
  urlNbuCurrencyCourses,
  urlGetFounderRoles,
  urlGetExtraAgreementTypes,
  urlGetApplicationSourceTypes,
  urlApplicationSourcesBySourceLinkId,
  urlGetUsersByRoleRiskManager,
  urlGetRiskAnalyzeStatuses,
  urlGetFinancingMonitoringStatuses,
  urlGetActivityStatuses,
  urlGetLeasPrograms,
  urlGetFrontManagers,
  urlGetMiddleManagers,
  urlGetLegalUsers,
  urlGetProjectStatuses,
  urlGetApplicationStatuses,
  urlGetSecurityStatuses,
  urlGetLegalStatuses,
  urlGetClientTypes,
  urlGetClientNeeds,
  urlGetContactStatuses,
  urlGetDFLStatuses,
  urlGetServiceContractStatuses,
  urlGetDKPStatuses,
  urlGetGCStatuses,
  urlGetExecutiveAuthorities,
  urlGetContractorDirectorPosts,
  urlGetProjects,
  urlGetDflContracts,
  urlGetSuretyAgreementTypes,
  urlGetPledgeTypes,
  urlGetCommunicationMethods,
  urlGetAllUsers,
  urlGetProjectGuarantors,
  urlGetMortgagorList,
  urlGetKlpStatuses,
  urlGetKlpTypes,
  urlGetExtraAgreementStatuses,
  urlDepartments,
  urlRoles,
  urlGetPlanSalesTypes,
  urlGetContractorDealerTypes,
  urlGetLoyaltyPrograms,
  urlGetDealerPointOfSaleMainDirections,
  urlGetProviderList,
  urlGetDealerTypes,
  urlGetLeasingPrograms,
  urlGetDealerList,
  urlGetTicketTheemes,
  urlGetTicketStatuses,
  urlGetClientStatuses,
  urlGetDealerCategories,
  urlGetAgentList,
  urlGetAllAgents,
  urlGetRejectStatuses,
  urlGetDealerOutlets,
  urlGetPhoneTypes,
  urlGetEmailTypes,
  urlGetContactsByApplication,
  urlGetOwnershipsList,
  urlFamilyStatuses,
  urlGetBasisDocuments,
  urlGetConnectionsList,
  urlGetFinancialRiskLevels,
  urlProjectRefusalStatuses,
  urlGetCallTypes,
  urlGetCallStatus,
  urlGetAdminLogsTableName,
  urlGetCallTelephony,
  urlGetAdminLogsType,
  urlGetAdvertisingCampaignTypes,
  urlGetCarData,
  urlServiceApplicationStatuses,
  urlServiceApplicationSourceList,
  urlServiceApplicationTypes,
  urlGetPassportTypes,
  urlGetGpsBeaconTypes,
  urlGetClientTypesFSN,
  urlGetTruckMass,
  urlGetCustomGraphicTypes,
  urlGetLiftingCapacityTypes,
  urlGetGpsTracker,
  urlGetGraphTypes,
  urlGetPlaceOfRegistration,
  urlGetInsuranceFranchise,
  urlGetPromotionsTypes,
  urlGetInsuranceProgram,
  urlGetBookKeepingStatus,
  urlGetAddressApartmentTypes,
  urlGetSegments,
  urlGetServicePackages,
  urlGetCountryOfOrigins,
  urlGetReplacementCarClasses,
  urlGetGuaranteeStatuses,
  urlGetBusinessRegions,
  urlGetPlanTypes,
  urlGetPlanObjectTypes,
  urlGetPlanCategories,
  urlGetPlanLeasingPrograms,
  urlGetPlanSourceTypes,
  urlGetPlanActivityTypes,
  urlGetPlanValueTypes,
  urlGetMonths,
  urlGetLeasingObjectStatuses,
  urlGetStates,
  urlGetFuels,
  urlGetAverageMarks,
  urlGetAverageModels,
  urlGetCalculationTargets,
  urlGetCalculationWorkplace,
} from '@/pages/request'
import { filterUsers } from '@/utils/helperFunc'
import { getItems } from '../helper'
import axios from 'axios'
import Promise from 'lodash/_Promise'

const selectItems = {
  getters: {
    activeUsers: state => {
      return filterUsers(state.allUsers)
    },
    leadAccessUsers: state => {
      return filterUsers(state.allUsers).filter(u => u.leadAccess)
    },
  },
  state: () => ({
    calcTargets: [],
    calcWorkplace: [],
    planTypes: [],
    states: [],
    fuels: [],
    planObjectTypes: [],
    planCategories: [],
    planLeasingPrograms: [],
    planSourceTypes: [],
    planActivityTypes: [],
    planValueTypes: [],
    apartmentTypes: [],
    contactTypes: [],
    gpsBeacons: [],
    contractorTypes: [],
    userRoles: {},
    nationalityList: [],
    segmentTypes: [],
    influenceList: [],
    signKbvList: [],
    connectionsList: [],
    genderList: [],
    activityTypes: [],
    careerPosts: [],
    careerDepartments: [],
    careerRoles: [],
    mfoList: [],
    passportTypes: [],
    currencies: [],
    contractorAddressTypes: [],
    contactAddressTypes: [],
    contacts: [],
    detailedContacts: [],
    agentContacts: [],
    contractors: [],
    contractorsList: [],
    countries: [],
    contractorIndastries: [],
    contractorCategories: [],
    leasingObjectTypes: [],
    educationTypes: [],
    bankAccountTypes: [],
    countryOfOrigins: [],
    providers: [],
    allProviders: [],
    analysisTypes: [],
    dkpTypes: [],
    dkpOrderCalculations: [],
    nbuCurrencyCourses: [],
    founderRoles: [],
    agreementTypes: [],
    applicationSourceTypes: [],
    applicationSource: [],
    applicationSourceLinks: [],
    riskManagers: [],
    riskStatuses: [],
    financingMonitoringStatuses: [],
    financialRiskLevels: [],
    activityStatuses: [],
    frontManagers: [],
    legalUsers: [],
    middleManagers: [],
    projectStatuses: [],
    applicationStatuses: [],
    securityStatuses: [],
    legalStatuses: [],
    clientTypes: [],
    clientNeeds: [],
    contactStatuses: [],
    dflStatuses: [],
    serviceContractStatuses: [],
    dkpStatuses: [],
    gcStatuses: [],
    guaranteeStatuses: [],
    projects: [],
    bookKeepingStatus: [],
    dfls: [],
    suretyAgreementTypes: [],
    businessRegions: [],
    pledgeTypes: [],
    applicationComunicationMethods: [],
    applicationContacts: [],
    allUsers: [],
    leasingPrograms: [],
    executiveAuthorities: [],
    contractorDirectorPosts: [],
    projectGuarantors: [],
    mortgagorsList: [],
    klpStatuses: [],
    klpTypes: [],
    extraAgreementStatuses: [],
    departments: [],
    allUserRoles: [],
    salesPlanTypes: [],
    contractorDealerTypes: [],
    loyaltyPrograms: [],
    DealerPointDirections: [],
    dealerTypes: [],
    otherLk: [],
    directoryTableNames: [],
    dealerOutletsList: [],
    ticketTheemes: [],
    ticketStatuses: [],
    clientStatuses: [],
    leasingObjectStatuses: [],
    dealerCategories: [],
    agents: [],
    agentsList: [],
    applicationRejectStatuses: [],
    dealerOutlets: [],
    phoneTypes: [],
    emailTypes: [],
    ownershipsList: [],
    familyStatuses: [],
    projectRefusalStatuses: [],
    callTypes: [],
    callStatus: [],
    callTelephony: [],
    logsTableName: [],
    logsType: [],
    compaignType: [],
    carModels: [],
    carMarks: [],
    averageCarMarks: [],
    averageCarModels: [],
    klpSolutions: [],
    carModifications: [],
    serviceApplicationStatuses: [],
    serviceApplicationSourceList: [],
    serviceApplicationTypes: [],
    leadStatuses: [],
    insurancePrograms: [],
    promotions: [],
    replacementCarClasses: [],
    insuranceFranchise: [],
    newTruckId: [1, 2, 7, 8, 9, 11, 15, 17, 21],
    newAgriculturalId: [1, 21],
    newSpecialId: [1, 21],
    placeOfRegItems: [],
    placeOfRegistration: [],
    servicePackages: [],
    itemYears: Array.from(new Array(12), (v, index) => index).map(
      (v, i) => new Date().getFullYear() - i
    ),
    itemYearsExpanded: Array.from(new Array(15), (v, index) => index).map(
      (v, i) => new Date().getFullYear() - i
    ),
    yesOrNo: [
      { text: 'Так', value: 1 },
      { text: 'Нi', value: 2 },
    ],
    chartTypes: [],
    gpsTrackerSelect: [],
    months: [],
    klpReworkTypes: [
      { name: 'Розблокування проекта', id: 1 },
      { name: 'Розблокування калькуляції', id: 2 },
    ],
    ukrMonths: [
      'Січень',
      'Лютий',
      'Березень',
      'Квітень',
      'Травень',
      'Червень',
      'Липень',
      'Серпень',
      'Вересень',
      'Жовтень',
      'Листопад',
      'Грудень',
    ],
    clientTypesFSN: [],
    truckMass: [],
    customGraphicTypes: [],
    basisDocuments: [],
    liftingCapacityTypes: [],
    projectValidationKeys: {
      lesseeName: 'Повна назва компанії',
      codeEdrpou: 'ЄДРПОУ',
      stateRegistrationDate: 'Дата реєстрації',
      stateRegistrationNumber: 'Номер реєстрації',
      basicQued: 'Основний квед',
      listQueds: 'Кведи',
      phones: 'Телефон',
      emails: 'Пошта',
      urAddress: 'Адреса реєстрації',
      factAddress: 'Фактична адреса',
      currentAccounts: 'Банківський рахунок',
      director: 'Диерктор',
      executiveAuthority: 'Виконавчий орган',
      founders: 'Засновники',
      name: 'Імʼя',
      surname: 'Прізвище',
      patronymic: 'По батькові',
      nationality: 'Громадянство',
      resident: 'Резидент',
      marriedTo: 'Чоловік/дружина',
      birthday: 'Дата народження',
      placeOfBirth: 'Місце народження',
      identity_document: 'Паспорт',
      inn: 'ІНН',
    },
    passportLabels: {
      identityDocFirst: ['Серія', 'Номер запису', 'Запис №', 'Назва'],
      identityDocSecond: ['Номер', 'ID паспорт', 'Посвідка №', 'Номер'],
      issueDate: ['Дата видачі', 'Дата видачі', 'Дата видачі', 'Дата видачі'],
      sellBy: [null, 'Дійсний, до', 'Дійсний, до', 'Дійсний, до'],
      issuedBy: [
        'Виданий',
        'Орган що видав',
        'Орган що видав',
        'Орган що видав',
      ],
      scan: [true, true, false, false],
    },
    meetStatuses: [
      { name: 'Не завершено', id: false },
      { name: 'Завершено', id: true },
    ],
  }),
  mutations: {
    addPlanTypes(state, types) {
      state.planTypes = types
    },
    addPlanObjectTypes(state, objectTypes) {
      state.planObjectTypes = objectTypes
    },
    addPlanCategories(state, categories) {
      state.planCategories = categories
    },
    addPlanLeasingPrograms(state, leasingPprograms) {
      state.planLeasingPrograms = leasingPprograms
    },
    addPlanSourceTypes(state, sourceTypes) {
      state.planSourceTypes = sourceTypes
    },
    addPlanActivityTypes(state, activityTypes) {
      state.planActivityTypes = activityTypes
    },
    addPlanValueTypes(state, valueTypes) {
      state.planValueTypes = valueTypes
    },
    setLeadStatuses(state, statuses) {
      state.leadStatuses = statuses
    },
    addProjectRefusalStatuses(state, statuses) {
      state.projectRefusalStatuses.splice(0)
      state.projectRefusalStatuses.push(...statuses)
    },
    addCompaignType(state, name) {
      state.compaignType.splice(0)
      state.compaignType.push(...name)
    },
    addLogsType(state, name) {
      state.logsType.splice(0)
      state.logsType.push(...name)
    },
    addStates(state, name) {
      state.states.splice(0)
      state.states.push(...name)
    },
    addFuels(state, name) {
      state.fuels.splice(0)
      state.fuels.push(...name)
    },
    addDirectoryTableNames(state, arr) {
      if (arr) {
        state.directoryTableNames.splice(0)
        state.directoryTableNames.push(...arr.tables)
      }
    },
    addLogsTableName(state, name) {
      state.logsTableName.splice(0)
      state.logsTableName.push(...name)
    },
    addCallStatus(state, status) {
      state.callStatus.splice(0)
      state.callStatus.push(...status)
    },
    addCallTelephony(state, telephony) {
      state.callTelephony.splice(0)
      state.callTelephony.push(...telephony)
    },
    addCallTypes(state, types) {
      state.callTypes.splice(0)
      state.callTypes.push(...types)
    },
    addApartmentTypes(state, types) {
      state.apartmentTypes.splice(0)
      state.apartmentTypes.push(...types)
    },
    addBasisDocuments(state, docs) {
      state.basisDocuments.splice(0)
      state.basisDocuments.push(...docs)
    },
    addFamilyStatuses(state, statuses) {
      state.familyStatuses.splice(0)
      state.familyStatuses.push(...statuses)
    },
    addOwnerships(state, list) {
      state.ownershipsList.splice(0)
      state.ownershipsList.push(...list)
    },
    addPhoneTypes(state, arr) {
      state.phoneTypes.splice(0)
      state.phoneTypes.push(...arr)
    },
    addDealerOutlets(state, arr) {
      state.dealerOutlets.splice(0)
      state.dealerOutlets.push(...arr)
    },
    addApplicationRejectStatuses(state, arr) {
      state.applicationRejectStatuses.splice(0)
      state.applicationRejectStatuses.push(...arr)
    },
    addDealerCategories(state, arr) {
      state.dealerCategories.splice(0)
      state.dealerCategories.push(...arr)
    },
    addClientStatuses(state, arr) {
      state.clientStatuses.splice(0)
      state.clientStatuses.push(...arr)
    },
    addTicketTheemes(state, arr) {
      state.ticketTheemes.splice(0)
      state.ticketTheemes.push(...arr)
    },
    addAllUserRoles(state, arr) {
      state.allUserRoles.splice(0)
      state.allUserRoles.push(...arr)
    },
    addKlpSolutions(state, arr) {
      if (arr) {
        state.klpSolutions.splice(0)
        state.klpSolutions.push(...arr)
      }
    },
    addDepartments(state, arr) {
      state.departments.splice(0)
      state.departments.push(...arr)
    },
    addKlpStatuses(state, arr) {
      state.klpStatuses.splice(0)
      state.klpStatuses.push(...arr)
    },
    addKlpTypes(state, arr) {
      state.klpTypes.splice(0)
      state.klpTypes.push(...arr)
    },
    addDfls(state, arr) {
      state.dfls.splice(0)
      state.dfls.push(...arr)
    },
    addAllUsers(state, arr) {
      state.allUsers.splice(0)
      state.allUsers.push(...arr)
    },
    addGpsBeacons(state, arr) {
      state.gpsBeacons.splice(0)
      state.gpsBeacons.push(...arr)
    },
    addInsurancePrograms(state, arr) {
      state.insurancePrograms.splice(0)
      state.insurancePrograms.push(...arr)
    },
    addPromotions(state, arr) {
      state.promotions.splice(0)
      state.promotions.push(...arr)
    },
    addInsuranceFranchise(state, arr) {
      state.insuranceFranchise.splice(0)
      state.insuranceFranchise.push(...arr)
    },
    addPlaceOfRegistration(state, arr) {
      state.placeOfRegistration.splice(0)
      state.placeOfRegistration.push(...arr)
    },
    addChartTypes(state, arr) {
      state.chartTypes.splice(0)
      state.chartTypes.push(...arr)
    },
    addGpsTracker(state, arr) {
      state.gpsTrackerSelect.splice(0)
      state.gpsTrackerSelect.push(...arr)
    },
    addClientTypesFSN(state, arr) {
      state.clientTypesFSN.splice(0)
      state.clientTypesFSN.push(...arr)
    },
    addTruckMassTypes(state, arr) {
      state.truckMass.splice(0)
      state.truckMass.push(...arr)
    },
    addLiftingCapacityTypes(state, arr) {
      state.liftingCapacityTypes.splice(0)
      state.liftingCapacityTypes.push(...arr)
    },
    addCustomGraphicTypes(state, arr) {
      state.customGraphicTypes.splice(0)
      state.customGraphicTypes.push(...arr)
    },
    addProjects(state, arr) {
      state.projects.splice(0)
      state.projects.push(...arr)
    },
    addLeasingPrograms(state, arr) {
      state.leasingPrograms.splice(0)
      state.leasingPrograms.push(...arr)
    },
    addActivityStatuses(state, arr) {
      state.activityStatuses.push(...arr)
    },
    addFinancingMonitoringStatuses(state, arr) {
      state.financingMonitoringStatuses.push(...arr)
    },
    addFinancialRiskLevels(state, list) {
      state.financialRiskLevels.splice(0)
      state.financialRiskLevels.push(...list)
    },
    addContactTypes(state, contactTypes) {
      state.contactTypes.push(...contactTypes)
    },
    addContractorTypes(state, contractorTypes) {
      state.contractorTypes.push(...contractorTypes)
    },
    addUserRoles(state, object) {
      const { userRoles, role } = object
      state.userRoles[role].push(...userRoles)
    },
    addNationalities(state, nationalityList) {
      state.nationalityList.push(...nationalityList)
    },
    addSegmentTypes(state, segmentTypes) {
      state.segmentTypes.push(...segmentTypes)
    },
    addInfluenceList(state, list) {
      state.influenceList.push(...list)
    },
    addSignKbvList(state, list) {
      state.signKbvList.push(...list)
    },
    addConnections(state, connections) {
      state.connectionsList.push(...connections)
    },
    addGenders(state, genderList) {
      state.genderList.push(...genderList)
    },
    addActivities(state, activities) {
      state.activityTypes.push(...activities)
    },
    addCareerPosts(state, careerPosts) {
      state.careerPosts.push(...careerPosts)
    },
    addCareerDepartments(state, careerDepartments) {
      state.careerDepartments.push(...careerDepartments)
    },
    addCareerRoles(state, careerRoles) {
      state.careerRoles.push(...careerRoles)
    },
    addMfo(state, mfoList) {
      state.mfoList.splice(0)
      state.mfoList.push(...mfoList)
    },
    addPassportTypes(state, passportTypes) {
      state.passportTypes.splice(0)
      state.passportTypes.push(...passportTypes)
    },
    addCurrencies(state, currencies) {
      state.currencies.splice(0)
      state.currencies.push(...currencies)
    },
    addContractorAddressTypes(state, contractorAddressTypes) {
      state.contractorAddressTypes.push(...contractorAddressTypes)
    },
    addContactAddressTypes(state, contactAddressTypes) {
      state.contactAddressTypes.push(...contactAddressTypes)
    },
    addContacts(state, contacts) {
      state.contacts.splice(0)
      state.contacts.push(...contacts)
    },
    addDetailedContacts(state, contacts) {
      state.detailedContacts.splice(0)
      state.detailedContacts.push(...contacts)
    },
    addAgentContacts(state, contacts = []) {
      state.agentContacts.splice(0)
      const agents = contacts.filter(c => c?.type?.id === 2)
      state.agentContacts.push(...agents)
    },
    addContractors(state, contractors) {
      state.contractors.splice(0)
      state.contractors.push(...contractors)
    },
    addContractorsList(state, contractors) {
      state.contractorsList.splice(0)
      state.contractorsList.push(...contractors)
    },
    addCountries(state, countries) {
      state.countries.push(...countries)
    },
    addContractorIndastries(state, contractorIndastries) {
      state.contractorIndastries.push(...contractorIndastries)
    },
    addContractorCategories(state, contractorCategories) {
      state.contractorCategories.push(...contractorCategories)
    },
    addLeasingObjectTypes(state, leasingObjectTypes) {
      window.leasingObjectTypes = leasingObjectTypes
      state.leasingObjectTypes.push(...leasingObjectTypes)
    },
    addEducationTypes(state, educationTypes) {
      state.educationTypes.push(...educationTypes)
    },
    addBankAccountTypes(state, bankAccountTypes) {
      state.bankAccountTypes.push(...bankAccountTypes)
    },
    addCountryOfOrigins(state, countryOfOrigins) {
      state.countryOfOrigins.push(...countryOfOrigins)
    },
    addProviders(state, providers) {
      state.providers.push(...providers)
    },
    addAnalysisTypes(state, analysisTypes) {
      state.analysisTypes.splice(0)
      state.analysisTypes.push(...analysisTypes)
    },
    addDkpTypes(state, types) {
      state.dkpTypes.push(...types)
    },
    addDkpOrderCalculations(state, arr) {
      state.dkpOrderCalculations.push(...arr)
    },
    addCalcTargets(state, arr) {
      state.calcTargets.push(...arr)
    },
    addCalcWorkplace(state, arr) {
      state.calcWorkplace.push(...arr)
    },
    addNbuCurrencyCourses(state, arr) {
      arr = arr.map(v => ({ ...v, cc: v.currency?.name }))
      state.nbuCurrencyCourses.push(...arr)
    },
    addFounderRoles(state, arr) {
      state.founderRoles.splice(0)
      state.founderRoles.push(...arr)
    },
    addAgreementTypes(state, arr) {
      state.agreementTypes.push(...arr)
    },
    addApplicationSource(state, arr) {
      state.applicationSource.splice(0)
      state.applicationSource.push(...arr)
    },
    addApplicationSourceTypes(state, arr) {
      state.applicationSourceTypes.splice(0)
      state.applicationSourceTypes.push(...arr)
    },
    addApplicationSourceLinks(state, arr) {
      state.applicationSourceLinks.splice(0)
      state.applicationSourceLinks.push(...arr)
    },
    addRiskManagers(state, arr) {
      state.riskManagers.push(...arr)
    },
    addRiskStatuses(state, arr) {
      state.riskStatuses.push(...arr)
    },
    addFrontManagers(state, arr) {
      state.frontManagers.splice(0)
      state.frontManagers.push(...arr)
    },
    addLegalUsers(state, arr) {
      state.legalUsers.splice(0)
      state.legalUsers.push(...arr)
    },
    addMiddleManagers(state, arr) {
      state.middleManagers.splice(0)
      state.middleManagers.push(...arr)
    },
    addProjectStatuses(state, arr) {
      state.projectStatuses.push(...arr)
    },
    addApplicationStatuses(state, arr) {
      if (arr) {
        state.applicationStatuses.splice(0)
        state.applicationStatuses.push(...arr)
      }
    },
    addSecurityStatuses(state, arr) {
      if (arr) {
        state.securityStatuses.splice(0)
        state.securityStatuses.push(...arr)
      }
    },
    addLegalStatuses(state, arr) {
      if (arr) {
        state.legalStatuses.splice(0)
        state.legalStatuses.push(...arr)
      }
    },
    addLeasingObjectStatuses(state, arr) {
      if (arr) {
        state.leasingObjectStatuses.splice(0)
        state.leasingObjectStatuses.push(...arr)
      }
    },
    addBookKeepingStatus(state, arr) {
      state.bookKeepingStatus.splice(0)
      state.bookKeepingStatus.push(...arr)
    },
    addClientTypes(state, arr) {
      state.clientTypes.push(...arr)
    },
    addClientNeeds(state, arr) {
      state.clientNeeds.push(...arr)
    },
    addContactStatuses(state, arr) {
      state.contactStatuses.push(...arr)
    },
    addDFLStatuses(state, arr) {
      state.dflStatuses.splice(0)
      state.dflStatuses.push(...arr)
    },
    addServiceContractStatuses(state, arr) {
      state.serviceContractStatuses.splice(0)
      state.serviceContractStatuses.push(...arr)
    },
    addDKPStatuses(state, arr) {
      state.dkpStatuses.splice(0)
      state.dkpStatuses.push(...arr)
    },
    addGCStatuses(state, arr) {
      state.gcStatuses.splice(0)
      state.gcStatuses.push(...arr)
    },
    addGuaranteeStatuses(state, arr) {
      state.guaranteeStatuses.splice(0)
      state.guaranteeStatuses.push(...arr)
    },
    addExecutiveAuthorities(state, arr) {
      state.executiveAuthorities.splice(0)
      state.executiveAuthorities.push(...arr)
    },
    addContractorDirectorPosts(state, arr) {
      state.contractorDirectorPosts.splice(0)
      state.contractorDirectorPosts.push(...arr)
    },
    addSuretyAgreementTypes(state, arr) {
      state.suretyAgreementTypes.splice(0)
      state.suretyAgreementTypes.push(...arr)
    },
    addBusinessRegions(state, arr) {
      state.businessRegions.splice(0)
      state.businessRegions.push(...arr)
    },
    addPledgeTypes(state, arr) {
      state.pledgeTypes.splice(0)
      state.pledgeTypes.push(...arr)
    },
    addApplicationComunicationMethods(state, arr) {
      state.applicationComunicationMethods.splice(0)
      state.applicationComunicationMethods.push(...arr)
    },
    addProjectGuarantors(state, arr) {
      state.projectGuarantors.splice(0)
      state.projectGuarantors.push(...arr)
    },
    addMortgagorsList(state, arr) {
      state.mortgagorsList.splice(0)
      state.mortgagorsList.push(...arr)
    },
    addExtraAgreementStatuses(state, arr) {
      state.extraAgreementStatuses.splice(0)
      state.extraAgreementStatuses.push(...arr)
    },
    addSalesPlanTypes(state, arr) {
      state.salesPlanTypes.splice(0)
      state.salesPlanTypes.push(...arr)
    },
    addContractorDealerTypes(state, arr) {
      state.contractorDealerTypes.splice(0)
      state.contractorDealerTypes.push(...(arr || []))
    },
    addLoyaltyPrograms(state, arr) {
      state.loyaltyPrograms.splice(0)
      state.loyaltyPrograms.push(...(Array.isArray(arr) ? arr : []))
    },
    addDealerPointOfSaleMainDirections(state, arr) {
      state.DealerPointDirections.splice(0)
      state.DealerPointDirections.push(...(Array.isArray(arr) ? arr : []))
    },
    addAllProviders(state, arr) {
      state.allProviders.splice(0)
      state.allProviders.push(...arr)
    },
    addDealerTypes(state, arr) {
      state.dealerTypes.splice(0)
      state.dealerTypes.push(...arr)
    },
    addOtherLk(state, arr) {
      state.otherLk.splice(0)
      state.otherLk.push(...arr)
    },
    addDealerOutletList(state, arr) {
      state.dealerOutletsList.splice(0)
      state.dealerOutletsList.push(...arr)
    },
    addTicketStatuses(state, arr) {
      state.ticketStatuses.splice(0)
      state.ticketStatuses.push(...arr)
    },
    addAgents(state, arr) {
      state.agents.splice(0)
      state.agents.push(...arr)
    },
    addAgentsList(state, arr) {
      state.agentsList.splice(0)
      state.agentsList.push(...arr)
    },
    addEmailTypes(state, arr) {
      state.emailTypes.splice(0)
      state.emailTypes.push(...arr)
    },
    addApplicationContacts(state, arr) {
      state.applicationContacts.splice(0)
      state.applicationContacts.push(...arr)
    },
    clearApplicationContacts(state) {
      state.applicationContacts.splice(0)
    },
    addCarModels(state, items) {
      state.carModels.splice(0)
      state.carModels.push(...items)
    },
    addCarMarks(state, items) {
      state.carMarks.splice(0)
      state.carMarks.push(...items)
    },
    addAverageCarMarks(state, items) {
      state.averageCarMarks.splice(0)
      state.averageCarMarks.push(...items)
    },
    addAverageCarModels(state, items) {
      state.averageCarModels.splice(0)
      state.averageCarModels.push(...items)
    },
    clearCarModels(state) {
      state.carModels.splice(0)
    },
    clearCarMarks(state) {
      state.carMarks.splice(0)
    },
    clearAverageCarMarks(state) {
      state.averageCarMarks.splice(0)
    },
    clearAverageCarModels(state) {
      state.averageCarModels.splice(0)
    },
    addCarModifications(state) {
      state.carModifications.splice(0)
    },
    addServiceApplicationStatuses(state, items) {
      state.serviceApplicationStatuses.splice(0)
      state.serviceApplicationStatuses.push(...items)
    },
    addServiceApplicationSourceList(state, items) {
      state.serviceApplicationSourceList.splice(0)
      state.serviceApplicationSourceList.push(...items)
    },
    addServiceApplicationTypes(state, items) {
      state.serviceApplicationTypes.splice(0)
      state.serviceApplicationTypes.push(...items)
    },
    addServicePackages(state, items) {
      state.servicePackages.splice(0)
      state.servicePackages.push(...items)
    },
    addReplacementCarClasses(state, items) {
      state.replacementCarClasses.splice(0)
      state.replacementCarClasses.push(...items)
    },
    addMonths(state, items) {
      state.months.splice(0)
      state.months.push(...items)
    },
  },
  actions: {
    async addPlanTypes({ state, commit }) {
      if (state.planTypes.length === 0) {
        const items = await getItems(urlGetPlanTypes())
        commit('addPlanTypes', items)
      }
    },
    async addPlanObjectTypes({ state, commit }, planTypeId) {
      if (state.planObjectTypes.length === 0) {
        const items = await getItems(urlGetPlanObjectTypes(planTypeId))
        commit('addPlanObjectTypes', items)
      }
    },
    async addPlanCategories({ state, commit }) {
      if (state.planCategories.length === 0) {
        const items = await getItems(urlGetPlanCategories())
        commit('addPlanCategories', items)
      }
    },
    async addPlanLeasingPrograms({ state, commit }) {
      if (state.planLeasingPrograms.length === 0) {
        const items = await getItems(urlGetPlanLeasingPrograms())
        commit('addPlanLeasingPrograms', items)
      }
    },
    async addPlanSourceTypes({ state, commit }) {
      if (state.planSourceTypes.length === 0) {
        const items = await getItems(urlGetPlanSourceTypes())
        commit('addPlanSourceTypes', items)
      }
    },
    async addPlanActivityTypes({ state, commit }) {
      if (state.planActivityTypes.length === 0) {
        const items = await getItems(urlGetPlanActivityTypes())
        commit('addPlanActivityTypes', items)
      }
    },
    async addPlanValueTypes({ state, commit }) {
      if (state.planValueTypes.length === 0) {
        const items = await getItems(urlGetPlanValueTypes())
        commit('addPlanValueTypes', items)
      }
    },
    async getLeadStatuses({ state, commit }) {
      if (state.leadStatuses.length === 0) {
        const data = await getItems(urlGetLeadStatuses())
        commit('setLeadStatuses', data)
      }
      return Promise.resolve()
    },
    /* contact/contractor */
    async addBasisDocuments({ commit, state }) {
      if (state.basisDocuments.length === 0) {
        const items = await getItems(urlGetBasisDocuments())
        return commit('addBasisDocuments', items)
      }
    },
    async addCallTypes({ commit, state }) {
      if (state.callTypes.length === 0) {
        const items = await getItems(urlGetCallTypes())
        return commit('addCallTypes', items)
      }
    },
    async addApartmentTypes({ commit, state }) {
      if (state.apartmentTypes.length === 0) {
        const items = await getItems(urlGetAddressApartmentTypes())
        return commit('addApartmentTypes', items)
      }
    },
    async addCallTelephony({ commit, state }) {
      if (state.callTelephony.length === 0) {
        const items = await getItems(urlGetCallTelephony())
        return commit('addCallTelephony', items)
      }
    },
    async addLogsType({ commit, state }) {
      if (state.logsType.length === 0) {
        const items = await getItems(urlGetAdminLogsType())
        return commit('addLogsType', items)
      }
    },
    async addKlpSolutions({ commit, state }) {
      if (state.klpSolutions.length === 0) {
        const items = await getItems(urlGetKlpSolutions())
        return commit('addKlpSolutions', items)
      }
    },
    async addCompaignType({ commit, state }) {
      if (state.compaignType.length === 0) {
        const items = await getItems(urlGetAdvertisingCampaignTypes())
        return commit('addCompaignType', items)
      }
    },
    async addLogsTableName({ commit, state }) {
      if (state.logsTableName.length === 0) {
        const items = await getItems(urlGetAdminLogsTableName())
        return commit('addLogsTableName', items)
      }
    },
    async addDirectoryTableNames({ commit, state }) {
      if (state.directoryTableNames.length === 0) {
        const items = await getItems(urlGetDirectoriesTableNames())
        return commit('addDirectoryTableNames', items)
      }
    },
    async addCallStatus({ commit, state }) {
      if (state.callStatus.length === 0) {
        const items = await getItems(urlGetCallStatus())
        return commit('addCallStatus', items)
      }
    },
    async addFamilyStatuses({ commit, state }) {
      if (state.familyStatuses.length === 0) {
        const items = await getItems(urlFamilyStatuses())
        return commit('addFamilyStatuses', items)
      }
    },
    async addOwnerships({ commit, state }) {
      if (state.ownershipsList.length === 0) {
        const items = await getItems(urlGetOwnershipsList())
        return commit('addOwnerships', items)
      }
    },
    async addContactTypes({ commit, state }) {
      if (state.contactTypes.length) return
      const items = await getItems(urlGetContactTypes())
      return commit('addContactTypes', items)
    },
    async addContractorTypes({ commit, state }) {
      if (state.contractorTypes.length) return
      const items = await getItems(urlContractorsType())
      return commit('addContractorTypes', items)
    },
    async addSegmentTypes({ commit, state }) {
      if (state.segmentTypes.length) return
      const items = await getItems(urlGetSegments())
      return commit('addSegmentTypes', items)
    },
    async addStates({ commit, state }) {
      if (state.states.length) return
      const items = await getItems(urlGetStates())
      return commit('addStates', items)
    },
    async addFuels({ commit, state }) {
      if (state.fuels.length) return
      const items = await getItems(urlGetFuels())
      return commit('addFuels', items)
    },
    async addUserRoles({ commit, state }, payload) {
      const { key, roles } =
        typeof payload === 'string'
          ? { key: payload, roles: [payload] }
          : payload
      if (state.userRoles[key]) return
      state.userRoles[key] = []
      const items = await getItems(urlUserByRole(roles.join(',')))
      return commit('addUserRoles', { userRoles: items, role: key })
    },
    async addNationalities({ commit, state }) {
      if (state.nationalityList.length) return
      const items = await getItems(urlGetNationalList())
      const formattedItems = items.map(n => {
        return { id: n.id, name: n.name.capitalize({ hard: true }) }
      })
      commit('addNationalities', formattedItems)
    },
    async addInfluenceList({ commit, state }) {
      if (state.influenceList.length) return
      const items = await getItems(urlGetInfluenceList())
      return commit('addInfluenceList', items)
    },
    async addSignKbvList({ commit, state }) {
      if (state.signKbvList.length) return
      const items = await getItems(urlGetSignKbvList())
      return commit('addSignKbvList', items)
    },
    async addConnections({ commit, state }) {
      if (state.connectionsList.length) return
      const items = await getItems(urlGetConnectionsList())
      return commit('addConnections', items)
    },
    async addGenders({ commit, state }) {
      if (state.genderList.length) return
      const items = await getItems(urlGetGenderList())
      return commit('addGenders', items)
    },
    async addActivities({ commit, state }) {
      if (state.activityTypes.length) return
      const items = await getItems(urlContractorActivityTypes())
      return commit('addActivities', items)
    },
    async addCareerPosts({ commit, state }) {
      if (state.careerPosts.length) return
      const items = await getItems(urlGetCarrers())
      return commit('addCareerPosts', items)
    },
    async addCareerDepartments({ commit, state }) {
      if (state.careerDepartments.length) return
      const items = await getItems(urlGetDepartments())
      return commit('addCareerDepartments', items)
    },
    async addCareerRoles({ commit, state }) {
      if (state.careerRoles.length) return
      const items = await getItems(urlGetCareerRoles())
      return commit('addCareerRoles', items)
    },
    async addMfo({ commit, state }, mfo = null) {
      if (!mfo || state.mfoList.findIndex(el => el.mfo === mfo) >= 0) return
      const items = await getItems(urlGetMFO(mfo), {
        headers: { 'X-API-SECRET': import.meta.env.VITE_ERM_API_SECRET },
      })
      return commit('addMfo', items)
    },
    async addBookKeepingStatus({ commit, state }) {
      if (state.bookKeepingStatus.length) return
      const items = await getItems(urlGetBookKeepingStatus())
      return commit('addBookKeepingStatus', items)
    },
    async addCurrencies({ commit, state }) {
      if (state.currencies.length) return
      const items = await getItems(urlCurrencies())
      return commit('addCurrencies', items)
    },
    async addContractorAddressTypes({ commit, state }) {
      if (state.contractorAddressTypes.length) return
      const items = await getItems(urlContractorAddressTypes())
      return commit('addContractorAddressTypes', items)
    },
    async addContactAddressTypes({ commit, state }) {
      if (state.contactAddressTypes.length) return
      const items = await getItems(urlContactAddressTypes())
      return commit('addContactAddressTypes', items)
    },
    async addContacts({ commit }, byAccess = false) {
      const items = await getItems(urlContactsGetAll(byAccess))
      commit('addAgentContacts', items)
      return commit('addContacts', items)
    },
    async addDetailedContacts({ commit }) {
      const items = await getItems(urlContactsGetAllDetailed())
      return commit('addDetailedContacts', items)
    },
    async addPassportTypes({ commit }) {
      const items = await getItems(urlGetPassportTypes())
      return commit('addPassportTypes', items)
    },
    async addContractors({ commit }, params = {}) {
      const items = await getItems(urlGetAllContractors(params))
      commit('addContractors', items)
      return Promise.resolve(items)
    },
    async addContractorsList({ commit }) {
      const items = await getItems(urlGetContractorsList())
      return commit('addContractorsList', items)
    },
    async addCountries({ commit, state }) {
      if (state.countries.length) return
      const items = await getItems(urlCountries())
      return commit('addCountries', items)
    },
    async addContractorIndastries({ commit, state }) {
      if (state.contractorIndastries.length) return
      const items = await getItems(urlContractorIndastries())
      return commit('addContractorIndastries', items)
    },
    async addContractorCategories({ commit, state }) {
      if (state.contractorCategories.length) return
      const items = await getItems(urlContractorCategories())
      return commit('addContractorCategories', items)
    },
    async addLeasingObjectTypes({ commit, state }) {
      if (state.leasingObjectTypes.length) return
      const items = await getItems(urlGetCarData(), {
        headers: { 'X-API-SECRET': import.meta.env.VITE_ERM_API_SECRET },
      })
      return commit('addLeasingObjectTypes', items)
    },
    async addEducationTypes({ commit, state }) {
      if (state.educationTypes.length) return
      const items = await getItems(urlGetEducationTypes())
      return commit('addEducationTypes', items)
    },
    async addBankAccountTypes({ commit, state }) {
      if (state.bankAccountTypes.length) return
      const items = await getItems(urlGetBankAccountTypes())
      return commit('addBankAccountTypes', items)
    },
    async addCountryOfOrigins({ commit, state }) {
      if (state.countryOfOrigins.length) return
      const items = await getItems(urlGetCountryOfOrigins())
      return commit('addCountryOfOrigins', items)
    },
    async addProviders({ commit }) {
      const items = await getItems(urlGetProviders())
      return commit('addProviders', items)
    },
    /* project */
    async addGpsBeacons({ commit, state }) {
      if (state.gpsBeacons.length) return
      const items = await getItems(urlGetGpsBeaconTypes())
      return commit('addGpsBeacons', items)
    },
    async addInsurancePrograms({ commit, state }) {
      if (state.insurancePrograms.length) return
      const items = await getItems(urlGetInsuranceProgram())
      return commit('addInsurancePrograms', items)
    },
    async addPromotions({ commit, state }) {
      if (state.promotions.length) return
      const items = await getItems(urlGetPromotionsTypes())
      return commit('addPromotions', items)
    },
    async addInsuranceFranchise({ commit, state }) {
      if (state.insuranceFranchise.length) return
      const items = await getItems(urlGetInsuranceFranchise())
      return commit('addInsuranceFranchise', items)
    },
    async addPlaceOfRegistration({ commit, state }) {
      if (state.placeOfRegistration.length) return
      const items = await getItems(urlGetPlaceOfRegistration())
      return commit('addPlaceOfRegistration', items)
    },
    async addChartTypes({ commit, state }) {
      if (state.chartTypes.length) return
      const items = await getItems(urlGetGraphTypes())
      return commit('addChartTypes', items)
    },
    async addGpsTracker({ commit, state }) {
      if (state.gpsTrackerSelect.length) return
      const items = await getItems(urlGetGpsTracker())
      return commit('addGpsTracker', items)
    },
    async addClientTypesFSN({ commit, state }) {
      if (state.clientTypesFSN.length) return
      const items = await getItems(urlGetClientTypesFSN())
      return commit('addClientTypesFSN', items)
    },
    async addTruckMassTypes({ commit, state }) {
      if (state.truckMass.length) return
      const items = await getItems(urlGetTruckMass())
      return commit('addTruckMassTypes', items)
    },
    async addLiftingCapacityTypes({ commit, state }) {
      if (state.liftingCapacityTypes.length) return
      const items = await getItems(urlGetLiftingCapacityTypes())
      return commit('addLiftingCapacityTypes', items)
    },
    async addCustomGraphicTypes({ commit, state }) {
      if (state.customGraphicTypes.length) return
      const items = await getItems(urlGetCustomGraphicTypes())
      return commit('addCustomGraphicTypes', items)
    },
    async addProjectRefusalStatuses({ commit, state }) {
      if (state.projectRefusalStatuses.length) return
      const items = await getItems(urlProjectRefusalStatuses())
      return commit('addProjectRefusalStatuses', items)
    },
    async addAnalysisTypes({ commit, state }) {
      if (state.analysisTypes.length) return
      const items = await getItems(urlAnalysisTypes())
      return commit('addAnalysisTypes', items)
    },
    async addDkpTypes({ commit, state }) {
      if (state.dkpTypes.length) return
      const items = await getItems(urlGetDkpTypes())
      return commit('addDkpTypes', items)
    },
    async addDkpOrderCalculations({ commit, state }) {
      if (state.dkpOrderCalculations.length) return
      const items = await getItems(urlGetDkpOrderCalculations())
      return commit('addDkpOrderCalculations', items)
    },
    async addNbuCurrencyCourses({ commit, state }) {
      if (state.nbuCurrencyCourses.length) return
      const items = await getItems(urlNbuCurrencyCourses())
      return commit('addNbuCurrencyCourses', items)
    },
    async addCalcTargets({ commit, state }) {
      if (state.calcTargets.length) return
      const items = await getItems(urlGetCalculationTargets())
      return commit('addCalcTargets', items)
    },
    async addCalcWorkplace({ commit, state }) {
      if (state.calcWorkplace.length) return
      const items = await getItems(urlGetCalculationWorkplace())
      return commit('addCalcWorkplace', items)
    },
    async addFounderRoles({ commit, state }) {
      if (state.founderRoles.length) return
      const items = await getItems(urlGetFounderRoles())
      return commit('addFounderRoles', items)
    },
    async addAgreementTypes({ commit, state }) {
      if (state.agreementTypes.length) return
      const items = await getItems(urlGetExtraAgreementTypes())
      return commit('addAgreementTypes', items)
    },
    async addApplicationSourceTypes({ commit, state }, query = {}) {
      query = query || {}

      const { sourceId = null, sourceTypeId = null } = query

      if (!(sourceId || sourceTypeId) && state.applicationSourceTypes.length) {
        return
      }

      const items = await getItems(
        urlGetApplicationSourceTypes({ sourceId, sourceTypeId })
      )

      switch (true) {
        case !!sourceId:
          return commit('addApplicationSourceLinks', items)
        case !!sourceTypeId:
          return commit('addApplicationSource', items)
        default:
          return commit('addApplicationSourceTypes', items)
      }
    },
    async addApplicationSourceLinks({ commit }, sourceId = null, sourceTypeId) {
      // if (state.applicationSourceTypes.length) return
      const items = await getItems(
        urlApplicationSourcesBySourceLinkId(sourceId, sourceTypeId)
      )
      return commit('addApplicationSourceLinks', items)
    },
    async addRiskManagers({ commit, state }) {
      if (state.riskManagers.length) return
      const items = await getItems(urlGetUsersByRoleRiskManager())
      return commit('addRiskManagers', items)
    },
    async addRiskStatuses({ commit, state }) {
      if (state.riskStatuses.length) return
      const items = await getItems(urlGetRiskAnalyzeStatuses())
      return commit('addRiskStatuses', items)
    },
    async addFinancingMonitoringStatuses({ commit, state }) {
      if (state.financingMonitoringStatuses.length) return
      const items = await getItems(urlGetFinancingMonitoringStatuses())
      return commit('addFinancingMonitoringStatuses', items)
    },
    async addFinancialRiskLevels({ commit, state }) {
      if (state.financialRiskLevels.length) return
      const items = await getItems(urlGetFinancialRiskLevels())
      return commit('addFinancialRiskLevels', items)
    },
    async addActivityStatuses({ commit, state }) {
      if (state.activityStatuses.length) return
      const items = await getItems(urlGetActivityStatuses())
      return commit('addActivityStatuses', items)
    },
    async addLeasingPrograms({ commit, state }) {
      if (state.leasingPrograms.length) return
      const items = await getItems(urlGetLeasPrograms())
      return commit('addLeasingPrograms', items)
    },
    async addFrontManagers({ commit, state }) {
      if (state.frontManagers.length) return
      const items = await getItems(urlGetFrontManagers())
      return commit('addFrontManagers', items)
    },
    async addLegalUsers({ commit, state }) {
      if (state.legalUsers.length) return
      const items = await getItems(urlGetLegalUsers())
      return commit('addLegalUsers', items)
    },
    async addMiddleManagers({ commit, state }) {
      if (state.frontManagers.length) return
      const items = await getItems(urlGetMiddleManagers())
      return commit('addMiddleManagers', items)
    },
    async addProjectStatuses({ commit, state }) {
      if (state.projectStatuses.length) return
      const items = await getItems(urlGetProjectStatuses())
      return commit('addProjectStatuses', items)
    },
    async addApplicationStatuses({ commit, state }) {
      if (state.applicationStatuses.length) return
      const items = await getItems(urlGetApplicationStatuses())
      return commit('addApplicationStatuses', items)
    },
    async addSecurityStatuses({ commit, state }) {
      if (state.securityStatuses.length) return
      const items = await getItems(urlGetSecurityStatuses())
      return commit('addSecurityStatuses', items)
    },
    async addLegalStatuses({ commit, state }) {
      if (state.legalStatuses.length) return
      const items = await getItems(urlGetLegalStatuses())
      return commit('addLegalStatuses', items)
    },
    async addClientTypes({ commit, state }) {
      if (state.clientTypes.length) return
      const items = await getItems(urlGetClientTypes())
      return commit('addClientTypes', items)
    },
    async addClientNeeds({ commit, state }) {
      if (state.clientNeeds.length) return
      const items = await getItems(urlGetClientNeeds())
      return commit('addClientNeeds', items)
    },
    async addContactStatuses({ commit, state }) {
      if (state.contactStatuses.length) return
      const items = await getItems(urlGetContactStatuses())
      return commit('addContactStatuses', items)
    },
    async addDFLStatuses({ commit, state }) {
      if (state.dflStatuses.length) return
      const items = await getItems(urlGetDFLStatuses())
      return commit('addDFLStatuses', items)
    },
    async addServiceContractStatuses({ commit, state }) {
      if (state.serviceContractStatuses.length) return
      const items = await getItems(urlGetServiceContractStatuses())
      return commit('addServiceContractStatuses', items)
    },
    async addDKPStatuses({ commit, state }) {
      if (state.dkpStatuses.length) return
      const items = await getItems(urlGetDKPStatuses())
      return commit('addDKPStatuses', items)
    },
    async addGCStatuses({ commit, state }) {
      if (state.gcStatuses.length) return
      const items = await getItems(urlGetGCStatuses())
      return commit('addGCStatuses', items)
    },
    async addGuaranteeStatuses({ commit, state }) {
      if (state.guaranteeStatuses.length) return
      const items = await getItems(urlGetGuaranteeStatuses())
      return commit('addGuaranteeStatuses', items)
    },
    async addExecutiveAuthorities({ commit, state }) {
      if (state.executiveAuthorities.length) return
      const items = await getItems(urlGetExecutiveAuthorities())
      return commit('addExecutiveAuthorities', items)
    },
    async addContractorDirectorPosts({ commit, state }) {
      if (state.contractorDirectorPosts.length) return
      const items = await getItems(urlGetContractorDirectorPosts())
      return commit('addContractorDirectorPosts', items)
    },
    async addProjects({ commit }) {
      const { data } = await getItems(urlGetProjects())
      return commit('addProjects', data)
    },
    async addDfls({ commit }) {
      const items = await getItems(urlGetDflContracts())
      return commit('addDfls', items)
    },
    async addSuretyAgreementTypes({ commit, state }) {
      if (state.suretyAgreementTypes.length) return
      const items = await getItems(urlGetSuretyAgreementTypes())
      return commit('addSuretyAgreementTypes', items)
    },
    async addBusinessRegions({ commit, state }) {
      if (state.businessRegions.length) return
      const items = await getItems(urlGetBusinessRegions())
      return commit('addBusinessRegions', items)
    },
    async addPledgeTypes({ commit, state }) {
      if (state.pledgeTypes.length) return
      const items = await getItems(urlGetPledgeTypes())
      return commit('addPledgeTypes', items)
    },
    async addApplicationComunicationMethods({ commit, state }) {
      if (state.applicationComunicationMethods.length) return
      const items = await getItems(urlGetCommunicationMethods())
      return commit('addApplicationComunicationMethods', items)
    },
    async addAllUsers({ commit, state }) {
      if (state.allUsers.length) return
      const items = await getItems(urlGetAllUsers())
      return commit('addAllUsers', items)
    },
    async addLeasingObjectStatuses({ commit, state }) {
      if (state.leasingObjectStatuses.length) return
      const items = await getItems(urlGetLeasingObjectStatuses())
      return commit('addLeasingObjectStatuses', items)
    },
    async addProjectGuarantors({ commit }) {
      const items = await getItems(urlGetProjectGuarantors())
      return commit('addProjectGuarantors', items)
    },
    async addMortgagorsList({ commit, state }) {
      if (state.mortgagorsList.length) return
      const items = await getItems(urlGetMortgagorList())
      return commit('addMortgagorsList', items)
    },
    async addKlpStatuses({ commit, state }) {
      if (state.klpStatuses.length) return
      const items = await getItems(urlGetKlpStatuses())
      return commit('addKlpStatuses', items)
    },
    async addKlpTypes({ commit, state }) {
      if (state.klpTypes.length) return
      const items = await getItems(urlGetKlpTypes())
      return commit('addKlpTypes', items)
    },
    async addExtraAgreementStatuses({ commit, state }) {
      if (state.extraAgreementStatuses.length) return
      const items = await getItems(urlGetExtraAgreementStatuses())
      return commit('addExtraAgreementStatuses', items)
    },
    async addDepartments({ commit, state }) {
      if (state.departments.length) return
      const items = await getItems(urlDepartments())
      return commit('addDepartments', items)
    },
    async addAllUserRoles({ commit, state }) {
      if (state.allUserRoles.length) return
      const items = await getItems(urlRoles())
      return commit('addAllUserRoles', items)
    },
    async addSalesPlanTypes({ commit, state }) {
      if (state.salesPlanTypes.length) return
      const items = await getItems(urlGetPlanSalesTypes())
      return commit('addSalesPlanTypes', items)
    },
    // !Deprecated use "addDealerTypes" action
    async addContractorDealerTypes({ commit, state }) {
      if (state.contractorDealerTypes.length) return
      const items = await getItems(urlGetContractorDealerTypes())
      return commit('addContractorDealerTypes', items)
    },
    async addLoyaltyPrograms({ commit, state }) {
      if (state.loyaltyPrograms.length) return
      const items = await getItems(urlGetLoyaltyPrograms())
      return commit('addLoyaltyPrograms', items)
    },
    async addDealerPointOfSaleMainDirections({ commit, state }) {
      if (state.DealerPointDirections.length) return
      const items = await getItems(urlGetDealerPointOfSaleMainDirections())
      return commit('addDealerPointOfSaleMainDirections', items)
    },
    async addAllProviders({ commit }) {
      const items = await getItems(urlGetProviderList())
      return commit('addAllProviders', items)
    },
    async addDealerTypes({ commit, state }) {
      if (state.dealerTypes.length) return
      const items = await getItems(urlGetDealerTypes())
      return commit('addDealerTypes', items)
    },
    async addOtherLk({ commit, state }) {
      if (state.otherLk.length) return
      const items = await getItems(urlGetLeasingPrograms())
      return commit('addOtherLk', items)
    },
    async addDealerOutletList({ commit }, params = {}) {
      const { page = 1, pagination = false, filters = {} } = params
      if (pagination) {
        return axios.get(urlGetDealerList(page, filters)).then(res => {
          commit('addDealerOutletList', res.data.data)

          return res
        })
      } else {
        // const items = await getItems(urlGetDealerList())
        const items = await getItems(urlGetDealerOutlets())
        return commit('addDealerOutletList', items)
      }
    },
    async addDealerOutlets({ commit }) {
      const items = await getItems(urlGetDealerOutlets())
      return commit('addDealerOutlets', items)
    },
    async addTicketTheemes({ commit, state }) {
      if (state.ticketTheemes.length) return
      const items = await getItems(urlGetTicketTheemes())
      return commit('addTicketTheemes', items)
    },
    async addTicketStatuses({ commit, state }) {
      if (state.ticketStatuses.length) return
      const items = await getItems(urlGetTicketStatuses())
      return commit('addTicketStatuses', items)
    },
    async addClientStatuses({ commit, state }) {
      if (state.clientStatuses.length) return
      const items = await getItems(urlGetClientStatuses())
      return commit('addClientStatuses', items)
    },
    async addDealerCategories({ commit, state }) {
      if (state.dealerCategories.length) return
      const items = await getItems(urlGetDealerCategories())
      return commit('addDealerCategories', items)
    },
    async addAgents({ state, commit }, params) {
      if (params && params.noRepeat && state.agentsList.length) return

      const res = await getItems(urlGetAgentList(params))
      commit('addAgents', res.data)
      return res
    },
    async addAgentsList({ commit }) {
      const items = await getItems(urlGetAllAgents())
      return commit('addAgentsList', items)
    },
    async addApplicationRejectStatuses({ commit, state }) {
      if (state.applicationRejectStatuses.length) return
      const items = await getItems(urlGetRejectStatuses())
      return commit('addApplicationRejectStatuses', items)
    },
    async addPhoneTypes({ commit, state }) {
      if (state.phoneTypes.length) return
      const items = await getItems(urlGetPhoneTypes())
      return commit('addPhoneTypes', items)
    },
    async addEmailTypes({ commit, state }) {
      if (state.emailTypes.length) return
      const items = await getItems(urlGetEmailTypes())
      return commit('addEmailTypes', items)
    },
    async addApplicationContacts({ commit }, applicationId) {
      const items = await getItems(urlGetContactsByApplication(applicationId))
      return commit('addApplicationContacts', items || [])
    },
    async addCarMarks({ commit }, leasingObjectTypeId) {
      if (!leasingObjectTypeId) return
      const items = await getItems(
        urlGetCarData({ leasingObjectType: leasingObjectTypeId }),
        {
          headers: { 'X-API-SECRET': import.meta.env.VITE_ERM_API_SECRET },
        }
      )
      return commit('addCarMarks', items || [])
    },
    async addAverageCarMarks({ commit }, categoryId = 1) {
      if (!categoryId) return
      const items = await getItems(urlGetAverageMarks(categoryId))
      return commit('addAverageCarMarks', items || [])
    },
    async addAverageCarModels({ commit }, payload = {}) {
      const { categoryId: categoryId, markId: markId } = payload
      if (!categoryId || !markId) return
      const items = await getItems(urlGetAverageModels(categoryId, markId))
      return commit('addAverageCarModels', items || [])
    },
    async addCarModels({ commit }, payload = {}) {
      const {
        leasingObjectTypeId: leasingObjectType,
        markId: leasedAssertMark,
      } = payload
      if (!leasingObjectType || !leasedAssertMark) return
      const items = await getItems(
        urlGetCarData({ leasingObjectType, leasedAssertMark }),
        {
          headers: { 'X-API-SECRET': import.meta.env.VITE_ERM_API_SECRET },
        }
      )
      return commit('addCarModels', items || [])
    },
    async addCarModifications() {
      /* temporary empty */
    },
    async addServiceApplicationStatuses({ state, commit }) {
      if (state.serviceApplicationStatuses.length) return
      const items = await getItems(urlServiceApplicationStatuses())
      return commit('addServiceApplicationStatuses', items)
    },
    async addServiceApplicationSourceList({ state, commit }) {
      if (state.serviceApplicationSourceList.length) return
      const items = await getItems(urlServiceApplicationSourceList())
      return commit('addServiceApplicationSourceList', items)
    },
    async addServiceApplicationTypes({ state, commit }) {
      if (state.serviceApplicationTypes.length) return
      const items = await getItems(urlServiceApplicationTypes())
      return commit('addServiceApplicationTypes', items)
    },
    async addServicePackages({ state, commit }) {
      if (state.servicePackages.length) return
      const items = await getItems(urlGetServicePackages())
      return commit('addServicePackages', items)
    },
    async addReplacementCarClasses({ state, commit }) {
      if (state.replacementCarClasses.length) return
      const items = await getItems(urlGetReplacementCarClasses())
      return commit('addReplacementCarClasses', items)
    },
    async addMonths({ state, commit }) {
      if (state.months.length) return
      const items = await getItems(urlGetMonths())
      return commit('addMonths', items)
    },
  },
}

export { selectItems }
