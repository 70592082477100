<template>
  <div>
    <NoData v-if="contact.projects && !contact.projects.length" />
    <ProjectCard
      v-for="project in filteredProjects"
      :key="project.id"
      :project="project"
      :entityTypeId="1" />
  </div>
</template>
<script>
import ProjectCard from '@/components/ProjectCard.vue'
import NoData from '@/components/no-table-data.vue'
import { mapState } from 'vuex'
export default {
  components: {
    ProjectCard,
    NoData,
  },
  props: { contact: { type: Object } },
  data: () => ({
    tableHeader: [
      { title: 'Статус', key: 'status.name', align: 'start', sortable: false },
      {
        title: '№ проекту',
        key: 'projectNumber',
        align: 'center',
        sortable: false,
      },
      { title: 'Дата', key: 'projectDate', align: 'center', sortable: false },
      { title: 'Програма', key: 'program', align: 'center', sortable: false },
      {
        title: 'Кількість ОЛ',
        key: 'LOAmount',
        align: 'center',
        sortable: false,
      },
    ],
  }),
  computed: {
    ...mapState({ selectItems: state => state.selectItems }),
    id() {
      return this.$route?.params?.id
    },
    contactProjects() {
      return this.contact.projects
    },
    filteredProjects() {
      if (!this.filterDate) {
        return this.contact.projects
      }
      return this.contact.projects.filter(project => {
        const createdAt = new Date(project.created_at)
        return createdAt >= this.filterDate
      })
    },
  },
}
</script>
