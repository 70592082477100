const applicationListHeaders = [
  {
    title: 'Статус',
    key: 'statusId',
    align: 'start',
    sortable: false,
    width: '10%',
  },
  {
    title: 'Назва',
    key: 'applicationName',
    align: 'start',
    sortable: false,
    width: '20%',
  },
  {
    title: 'Контактна особа',
    key: 'name',
    align: 'start',
    sortable: false,
    width: '20%',
  },
  {
    title: 'Джерело',
    key: 'source_type.name',
    align: 'start',
    sortable: false,
    width: '10%',
  },
  {
    title: 'Дата створення',
    key: 'created_at',
    align: 'start',
    sortable: false,
    width: '10%',
  },
  {
    title: 'Відповідальний',
    key: 'responsible',
    align: 'start',
    sortable: false,
    width: '15%',
  },
  {
    title: '',
    key: 'actions',
    align: 'center',
    sortable: false,
    width: '1%',
  },
]

const applicationServiceHeaders = [
  { title: 'ID заявки', key: 'id', sortable: false },
  { title: 'Статус', key: 'status.name', sortable: false },
  {
    title: 'Назва заявки',
    key: 'serviceApplicationName',
    sortable: false,
  },
  { title: 'Джерело', key: 'source.name', sortable: false },
  { title: 'Відповідальний', key: 'responsible', sortable: false },
  { title: 'Дата створення', key: 'created_at', sortable: false },
]

const leadListHeaders = [
  {
    title: 'Статус',
    key: 'status',
    align: 'start',
    sortable: false,
    width: '10%',
  },
  { title: 'Назва', key: 'leadName', sortable: false, width: '20%' },
  {
    title: 'Контактна особа',
    key: 'name',
    align: 'start',
    sortable: false,
    width: '15%',
  },
  {
    title: 'Коментар',
    key: 'comment',
    align: 'center',
    sortable: false,
    width: '10%',
  },
  {
    title: 'Джерело',
    key: 'source',
    align: 'left',
    sortable: false,
    width: '20%',
  },
  {
    title: 'Дата створення',
    key: 'created_at',
    align: 'center',
    sortable: false,
    width: '10%',
  },
  {
    title: 'Відповідальний',
    key: 'responsible',
    align: 'center',
    sortable: false,
    width: '15%',
  },
  { title: '', key: 'actions', width: '1%' },
]

const calculationListHeaders = [
  {
    title: '№ Розрахунку',
    key: 'requestId',
    align: 'start',
    sortable: false,
  },
  { title: 'Дата', key: 'date', align: 'center', sortable: true },
  {
    title: 'Назва заявки',
    key: 'applicationName',
    align: 'center',
    sortable: false,
  },
  {
    title: 'Марка',
    key: 'leasedAssertMark.name',
    align: 'center',
    sortable: false,
  },
  {
    title: 'Модель',
    key: 'leasedAssertModel.name',
    align: 'center',
    sortable: false,
  },
  {
    title: 'Цiна',
    key: 'leasingAmount',
    align: 'center',
    sortable: false,
  },
  { title: 'Аванс', key: 'advance', align: 'center', sortable: false },
  {
    title: 'Строк',
    key: 'leasingTerm',
    align: 'center',
    sortable: false,
  },
  {
    title: 'Валюта',
    key: 'currency',
    align: 'center',
    sortable: false,
  },
  { title: 'Дії', key: 'actions', align: 'center', sortable: false },
]

const adminLeadCallHeaders = [
  { title: 'Тип', key: 'type_call', sortable: false, align: 'start' },
  {
    title: 'Дата створення',
    key: 'date',
    sortable: false,
    align: 'start',
  },
  { title: 'Телефон', key: 'phone', sortable: false, align: 'start' },
  {
    title: 'Відповідальна особа',
    key: 'user',
    sortable: false,
    align: 'start',
  },
  { title: 'Дії', key: 'action', sortable: false, align: 'end' },
]

const contactHeaders = [
  {
    title: 'Архівний код',
    key: 'archiveCode',
    align: 'start',
    sortable: true,
  },
  { title: 'Статус', key: 'status', align: 'start', sortable: false },
  { title: 'ПІБ', key: 'fullName', align: 'start', sortable: true },
  { title: 'ІПН', key: 'inn', align: 'start', sortable: true },
  { title: 'Тип', key: 'type', align: 'start', sortable: false },
  {
    title: 'Відповідальний',
    key: 'responsible',
    align: 'start',
    sortable: false,
  },
  { title: 'Дії', key: 'actions', align: 'end', sortable: false },
]

const contractorHeaders = [
  {
    title: 'Архівний код',
    key: 'archiveCode',
    align: 'start',
    sortable: false,
  },
  { title: 'Статус', key: 'status', align: 'start', sortable: false },
  {
    title: 'Назва компанії',
    key: 'shortName',
    align: 'start',
    sortable: false,
  },
  { title: 'ЕДРПОУ', key: 'edrpou', align: 'center', sortable: false },
  { title: 'Тип', key: 'type', align: 'start', sortable: false },
  {
    title: 'Відповідальний',
    key: 'responsible',
    align: 'start',
    sortable: false,
  },
  { title: 'Дії', key: 'actions', align: 'end', sortable: false },
]

const dealerHeaders = [
  {
    title: 'Статус',
    key: 'isDeactivated',
    align: 'start',
    sortable: false,
  },
  { title: 'Назва', key: 'name', align: 'start', sortable: false },
  { title: 'Тип', key: 'type', align: 'start', sortable: false },
  {
    title: 'Контрагент',
    key: 'contractor',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Відповідальний',
    key: 'responsibleId',
    align: 'start',
    sortable: false,
  },
  { title: 'Дії', key: 'actions', align: 'end', sortable: false },
]

const agentHeaders = [
  { title: 'ПIБ', key: 'fullName', align: 'start', sortable: false },
  { title: 'Статус', key: 'isActive', align: 'start', sortable: false },
  {
    title: 'Торгова точка',
    key: 'dealerPoint',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Відповідальний',
    key: 'responsible',
    align: 'start',
    sortable: false,
  },
  { title: 'Дії', key: 'actions', align: 'end', sortable: false },
]

const projectHeaders = [
  { title: 'Статус', key: 'status', align: 'start', sortable: false },
  {
    title: '№ проекту',
    key: 'projectNumber',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Лізингоотримувач',
    key: 'lessee',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Тип аналізу',
    key: 'analysisType',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Відповідальний Фронт',
    key: 'frontManagerFio',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Відповідальний Мідл',
    key: 'middleManagerFio',
    align: 'start',
    sortable: false,
  },
  { title: 'Дата', key: 'projectDate', sortable: false, align: 'start' },
  { title: 'Дії', key: 'actions', align: 'center', sortable: false },
]

const legalCaseHeaders = [
  {
    title: 'Клієнт',
    key: 'clientName',
    align: 'start',
    sortable: false,
    width: '500px',
  },
  {
    title: '№ ДФЛ',
    key: 'contract',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Відповідальний фронт',
    key: 'responsibleFrontFullName',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Відповідальний юрист',
    key: 'responsibleJuristFullName',
    align: 'start',
    sortable: false,
  },
  { title: 'Сума', key: 'delayAmount', align: 'start', sortable: false },
  { title: 'Днів', key: 'delayTerm', align: 'start', sortable: false },
]

const historyLeadCallHeaders = [
  {
    title: 'Дата дзвінка',
    key: 'startTime',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Тип',
    key: 'type_call.name',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Статус',
    key: 'status_call.name',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Номер',
    key: 'phone',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Відповідальний',
    key: 'user',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Тривалість, сек',
    key: 'duration',
    align: 'start',
    sortable: false,
  },
]

const paymentListHeaders = [
  { title: 'Статус', key: 'status', sortable: false },
  { title: 'Клієнт', key: 'lessee', sortable: false },
  {
    title: '№ договору',
    key: 'contractLessee',
    sortable: false,
    align: 'start',
  },
  {
    title: "Об'єкт лізингу",
    key: 'leasingObjectName',
    sortable: false,
    align: 'start',
  },
  { title: 'Сума ОЛ', key: 'amountOl', sortable: false },
  { title: 'Сума АВ', key: 'amountAB', sortable: false },
  { title: 'Дії', key: 'actions', align: 'end', sortable: false },
]

const averagePriceListHeaders = [
  {
    title: 'Категорія',
    key: 'category.name',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Марка',
    key: 'mark.name',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Модель',
    key: 'model.name',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Роки',
    key: 'years',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Дата створення',
    key: 'date',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Відповідальний',
    key: 'responsible',
    align: 'start',
    sortable: false,
  },
  { title: 'Дії', key: 'actions', width: '1%' },
]

const archiveListHeaders = [
  { title: 'ID', key: 'id', sortable: false },
  { title: 'ДФЛ', key: 'contract', sortable: false },
  { title: 'Клієнт', key: 'lessee', sortable: false },
  {
    title: 'Номер архівної справи ',
    key: 'archiveCode',
    sortable: false,
  },
]

/* activities */
const activitiesMeetingsListHeaders = [
  { title: 'Заголовок', key: 'title', sortable: false },
  {
    title: 'Категорiя',
    key: 'type.name',
    sortable: false,
    align: 'center',
  },
  {
    title: 'Менеджер',
    key: 'responsible',
    align: 'center',
    sortable: false,
  },
  {
    title: 'Контрагент',
    key: 'dealerPointContractor',
    align: 'center',
    sortable: false,
  },
  {
    title: 'ТТ',
    key: 'dealer_point_of_sales',
    align: 'center',
    sortable: false,
  },
  {
    title: 'Контакт',
    key: 'contact',
    align: 'center',
    sortable: false,
  },
  {
    title: 'Дата старту',
    key: 'startDate',
    align: 'center',
    sortable: false,
  },
  {
    title: 'Статус',
    key: 'customStatus',
    sortable: false,
    align: 'center',
  },
  {
    title: 'Дiї',
    key: 'actions',
    sortable: false,
    align: 'center',
  },
]

const activitiesRemindersListHeaders = [
  {
    title: 'Тема',
    key: 'taskMessage',
    sortable: false,
    align: 'start',
    width: '230px',
  },
  {
    title: 'Статус',
    key: 'isDone',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Назва',
    key: 'from',
    sortable: false,
    align: 'start',
  },
  {
    title: 'Відповідальний',
    key: 'user',
    sortable: false,
    align: 'start',
  },
  {
    title: 'Заплановано на:',
    key: 'date',
    align: 'start',
    sortable: false,
    width: '150px',
  },
  {
    title: 'Дiї',
    key: 'actions',
    sortable: false,
    align: 'center',
  },
]

/* contracts */
const dflListHeaders = [
  {
    title: 'Статус',
    key: 'status.name',
    align: 'start',
    sortable: false,
  },
  {
    title: '№ договору',
    key: 'number',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Дата',
    key: 'agreementDate',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Лізингоодержувач',
    key: 'lesseeShortName',
    align: 'start',
    sortable: false,
  },
  {
    title: "Об'єкт",
    key: 'leasingObjectName',
    align: 'start',
    sortable: false,
  },
  { title: 'Дії', key: 'actions', align: 'end', sortable: false },
]

const dkpListHeaders = [
  {
    title: 'Статус',
    key: 'status.name',
    align: 'start',
    sortable: false,
  },
  {
    title: '№ договору',
    key: 'number',
    align: 'center',
    sortable: false,
  },
  { title: 'Дата', key: 'date', align: 'center', sortable: false },
  {
    title: 'Постачальник',
    key: 'provider',
    align: 'center',
    sortable: false,
    width: '440px',
  },
  {
    title: 'Тип ПЛ',
    key: 'objectType',
    align: 'center',
    sortable: false,
  },
  { title: 'Валюта', key: 'currency', align: 'center', sortable: false },
  { title: 'Вартість', key: 'amount', align: 'center', sortable: false },
  { title: 'Дії', key: 'actions', align: 'end', sortable: false },
]

const dolListHeaders = [
  {
    title: 'Статус',
    key: 'status.name',
    align: 'start',
    sortable: false,
  },
  {
    title: '№ договору',
    key: 'number',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Дата',
    key: 'agreementDate',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Лізингоодержувач',
    key: 'lesseeShortName',
    align: 'start',
    sortable: false,
  },
  {
    title: "Об'єкт",
    key: 'leasingObjectName',
    align: 'start',
    sortable: false,
  },
  { title: 'Дії', key: 'actions', align: 'end', sortable: false },
]

const dflExtraAgreementListHeaders = [
  {
    title: 'Статус',
    key: 'status.name',
    align: 'start',
    sortable: false,
  },
  { title: 'Номер ДУ', key: 'number', align: 'start', sortable: false },
  { title: 'Дата ДУ', key: 'date', align: 'start', sortable: false },
  {
    title: 'Лізингоотримувач',
    key: 'lessee',
    align: 'start',
    sortable: false,
  },
  { title: 'ДФЛ', key: 'contract', align: 'start', sortable: false },
  { title: 'Дії', key: 'actions', align: 'end', sortable: false },
]
const dkpExtraAgreementListHeaders = [
  {
    title: 'Статус',
    key: 'status.name',
    align: 'start',
    sortable: false,
  },
  { title: 'Номер ДУ', key: 'number', align: 'start', sortable: false },
  { title: 'Дата ДУ', key: 'date', align: 'start', sortable: false },
  {
    title: 'Постачальник',
    key: 'provider',
    align: 'start',
    sortable: false,
  },
  { title: 'ДКП', key: 'dkp', align: 'start', sortable: false },
  { title: 'Дії', key: 'actions', align: 'end', sortable: false },
]

const dflConnectionsHeaders = [
  {
    title: 'Тип',
    key: 'type',
  },
  {
    title: 'Номер',
    key: 'number',
  },
  {
    title: 'Статус',
    key: 'status.name',
  },
  {
    title: 'Дата',
    key: 'date',
  },
]
const dkpConnectionsHeaders = [
  {
    title: 'Тип',
    key: 'type',
  },
  {
    title: 'Номер',
    key: 'number',
  },
  {
    title: 'Статус',
    key: 'status.name',
  },
  {
    title: 'Дата',
    key: 'date',
  },
]

const mortgagorListHeaders = [
  {
    title: 'Статус',
    key: 'status.name',
    align: 'start',
    sortable: false,
  },
  { title: 'Дата', key: 'date', align: 'start', sortable: false },
  { title: 'Номер', key: 'number', align: 'start', sortable: false },
  {
    title: 'Тип застави',
    key: 'type.name',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Заставодавець',
    key: 'mortgagor',
    align: 'start',
    sortable: false,
  },
  { title: 'Дії', key: 'actions', align: 'end', sortable: false },
]

const serviceListHeaders = [
  {
    title: 'Статус',
    key: 'status.name',
    align: 'start',
    sortable: false,
  },
  {
    title: '№ договору',
    key: 'number',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Дата',
    key: 'agreementDate',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Лізингоодержувач',
    key: 'lessee',
    align: 'start',
    sortable: false,
  },
  {
    title: "Об'єкт",
    key: 'leasingObject',
    align: 'start',
    sortable: false,
  },
  { title: 'Дії', key: 'actions', align: 'end', sortable: false },
]

const suretyAgreementListHeaders = [
  {
    title: 'Статус',
    key: 'status.name',
    align: 'start',
    sortable: false,
  },
  { title: 'Дата', key: 'date', align: 'start', sortable: false },
  { title: 'Номер', key: 'number', align: 'start', sortable: false },
  {
    title: 'Тип поруки',
    key: 'type.name',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Поручитель',
    key: 'guarantor',
    align: 'start',
    sortable: false,
  },
  { title: 'Боржник', key: 'debtor', align: 'start', sortable: false },
  { title: 'Дії', key: 'actions', align: 'end', sortable: false },
]

const eDocsListHeaders = [
  {
    title: 'Коротка назва',
    key: 'lesseeName',
    align: 'start',
    sortable: false,
  },
  { title: 'ЄДРПОУ', key: 'lesseeCode', align: 'start', sortable: false },
  {
    title: '№ ДФЛ',
    key: 'contractNumber',
    align: 'start',
    sortable: false,
  },
  { title: 'Дiї', key: 'actions', align: 'center', sortable: false },
]

const leasingObjectListHeaders = [
  {
    title: 'Статус',
    key: 'ОбъектЛизинга_Статус',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Реєстраційний номер',
    key: 'ОбъектЛизинга_Name',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Лізингоотримувач',
    key: 'Контрагент_Name',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Договір лізингу',
    key: 'Договор_Name',
    align: 'start',
    sortable: false,
  },
  { title: '', key: 'actions' },
]

const marketingListHeaders = [
  {
    title: 'Назва кампанії',
    key: 'campaignName',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Кампанія(utmCampaign)',
    key: 'utmCampaign.name',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Джерело(utmSource)',
    key: 'utmSource.name',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Канал(utmMedium)',
    key: 'utmMedium.name',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Ідентифікатор(utmContent)',
    key: 'utmContent.name',
    align: 'start',
    sortable: false,
  },
  { title: 'Статус', key: 'isActive', align: 'start', sortable: false },
  { title: 'Дiї', key: 'actions', align: 'center', sortable: false },
]

const planListHeaders = [
  { title: 'Назва', key: 'name' },
  { title: 'Тип', key: 'type.name' },
]

/* project-verification */

const financialListHeaders = [
  { title: 'Статус', key: 'status', align: 'start', sortable: false },
  {
    title: 'Лізингоотримувач',
    key: 'lessee',
    align: 'start',
    sortable: false,
  },
  { title: 'ЄДРПОУ/ІПН', key: 'code', align: 'start', sortable: false },
  {
    title: 'Фронт',
    key: 'frontManager',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Підтверджено',
    key: 'isConfirmation',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Рівень ризику',
    key: 'financialRiskLevel',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Тип аналiзу',
    key: 'analysisType',
    align: 'center',
    sortable: false,
    width: '80px',
  },
  { title: 'Дії', key: 'actions', align: 'center', sortable: false },
]

const leasingCommitteeListHeaders = [
  { title: 'Статус', key: 'status', align: 'start', sortable: false },
  { title: 'Дата', key: 'date', align: 'start', sortable: false },
  { title: 'Клієнт', key: 'lessee', align: 'center', sortable: false },
  {
    title: 'Тип аналізу',
    key: 'analysisType.name',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Рiшення',
    key: 'result',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Ризик менеджер',
    key: 'riskManager',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Фронт менеджер',
    key: 'frontManager',
    align: 'start',
    sortable: false,
  },
  { title: 'Дії', key: 'actions', align: 'end', sortable: false },
]

const legalDepartListHeaders = [
  { title: 'Статус', key: 'status', align: 'start', sortable: false },
  { title: 'Дата', key: 'created_at', align: 'start', sortable: false },
  { title: 'Клієнт', key: 'lessee', align: 'start', sortable: false },
  {
    title: 'Тип аналізу',
    key: 'analysisType.name',
    align: 'start',
    sortable: false,
  },
  { title: 'Дії', key: 'actions', align: 'end', sortable: false },
]

const riskDepartmentListHeaders = [
  {
    title: 'Статус',
    key: 'status.name',
    align: 'start',
    sortable: false,
  },
  { title: 'Дата', key: 'created_at', align: 'start', sortable: false },
  { title: 'Клієнт', key: 'lessee', align: 'start', sortable: false },
  {
    title: 'Тип аналізу',
    key: 'analysisType.name',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Результат',
    key: 'result',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Фронт',
    key: 'frontManagerFio',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Мідл',
    key: 'middleManagerFio',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Ризик',
    key: 'riskManagerFio',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Перевiрки',
    key: 'serviceChecks',
    align: 'center',
    sortable: false,
  },
  {
    title: "Об'єкт",
    key: 'objectLeasings',
    align: 'center',
    sortable: false,
  },
  { title: 'Дії', key: 'actions', align: 'end', sortable: false },
]

const scoringListHeaders = [
  { title: 'Дата', key: 'created_at', align: 'start', sortable: false },
  {
    title: 'Клієнт',
    key: 'lessee.name',
    align: 'start',
    sortable: false,
    width: '320px',
  },
  {
    title: 'Фронт менеджер',
    key: 'frontManagerResponsible',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Відповідальний',
    key: 'responsible',
    align: 'start',
    sortable: false,
  },
  { title: 'Рішення', key: 'isAgree', align: 'start', sortable: false },
  { title: 'Дії', key: 'actions', align: 'end', sortable: false },
]

const securityListHeaders = [
  { title: 'Статус', key: 'status', align: 'start', sortable: false },
  { title: 'Дата', key: 'created_at', align: 'start', sortable: false },
  { title: 'Клієнт', key: 'lessee', align: 'start', sortable: false },
  {
    title: 'Тип аналізу',
    key: 'analysisType.name',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Рішення (по клиенту)',
    key: 'clientResult',
    align: 'start',
    sortable: false,
  },
  { title: 'Дії', key: 'actions', align: 'end', sortable: false },
]

const supportListHeaders = [
  // { text: 'Тiкет', value: 'theme.name', align: 'start', sortable: false },
  { title: 'id', key: 'id', align: 'center', sortable: false },
  {
    title: 'Статус',
    key: 'status.name',
    align: 'start',
    sortable: false,
  },
  { title: 'Назва', key: 'title', align: 'start', sortable: false },
  { title: 'Дата', key: 'created_at', sortable: false, align: 'center' },
  { title: 'Дії', key: 'actions', align: 'end', sortable: false },
]

const leasingObjectLogHeaders = [
  {
    title: 'Дата',
    key: 'dateTo',
    sortable: false,
    align: 'start',
    width: '120px',
  },
  { title: 'Поле', key: 'field', sortable: true, align: 'start' },
  {
    title: 'Тип події',
    key: 'changes',
    sortable: false,
    align: 'start',
  },
  {
    title: 'Відповідальний',
    key: 'userName',
    sortable: false,
    align: 'start',
  },
]

const scoringChangesHeaders = [
  { title: 'Тип', key: 'record', sortable: false, width: '100px' },
  { title: 'Поле', key: 'field', sortable: false, width: '100px' },
  {
    title: 'Минуле значення',
    key: 'oldValue',
    sortable: false,
    width: '150px',
  },
  {
    title: 'Нове значення',
    key: 'newValue',
    sortable: false,
    width: '150px',
  },
  { title: 'Текст', key: 'text', sortable: false, width: '100px' },
]

const contactApplicationLinkHeaders = [
  { title: 'Назва заявки', key: 'applicationName', sortable: false },
  { title: 'Статус', key: 'status', sortable: false },
  { title: 'Дата створення', key: 'created_at', sortable: false },
  {
    title: 'Потреба',
    key: 'clientNeed.name',
    sortable: false,
    align: 'center',
  },
  {
    title: 'Джерело заявки',
    key: 'sourceType.name',
    align: 'center',
    sortable: false,
  },
  {
    title: 'Відповідальний',
    key: 'responsible',
    align: 'center',
    sortable: false,
  },
]

const fopHeaders = [
  { title: 'Платник ПДВ', value: 'isVatPayer' },
  { title: 'ІПН', value: 'innPayerVAT' },
  { title: 'Дата реєстрації', value: 'date' },
  { title: 'Номер реєстрації', value: 'number' },
  { title: 'Основний квед', value: 'mainQued' },
  { title: 'Інші кведи', value: 'queds' },
  { title: 'Дії', value: 'actions', align: 'end' },
]

const edrChangeTableHeaders = [
  { title: 'Група', key: 'factorGroup', sortable: false },
  { title: 'Подія', key: 'text', sortable: false },
  {
    title: 'Iндикатор',
    key: 'indicator',
    sortable: false,
    align: 'center',
  },
  {
    title: 'JSON',
    key: 'json',
    sortable: false,
    align: 'center',
  },
]

// project verification [Analyze] headers --start
const analyzeSecurityHeaders = [
  { title: 'Назва', key: 'name', sortable: false, width: '330px' },
  { title: 'Статус', key: 'status', sortable: false, width: '130px' },
  {
    title: 'Дата перевірки',
    key: 'finishDate',
    sortable: false,
    width: '130px',
  },
  {
    title: 'Відповідальний',
    key: 'responsible',
    sortable: false,
    width: '150px',
  },
  {
    title: 'Результат перевірки',
    key: 'result',
    align: 'end',
    sortable: false,
    width: '25%',
  },
]

const analyzeRiskHeaders = [
  { title: 'Назва', key: 'name', sortable: false, width: '330px' },
  { title: 'Статус', key: 'status', sortable: false, width: '130px' },
  {
    title: 'Дата перевірки',
    key: 'finishDate',
    align: 'start',
    sortable: false,
    width: '115px',
  },
  {
    title: 'Відповідальний',
    key: 'responsible',
    sortable: false,
    width: '150px',
  },
  {
    title: 'Рішення',
    key: 'conclusion',
    align: 'end',
    sortable: false,
    width: '25%',
  },
]

const analyzeLegalHeaders = [
  { title: 'Назва', key: 'name', sortable: false, width: '330px' },
  { title: 'Статус', key: 'status', sortable: false, width: '130px' },
  {
    title: 'Дата перевірки',
    key: 'finishDate',
    sortable: false,
    width: '115px',
  },
  {
    title: 'Відповідальний',
    key: 'responsible',
    sortable: false,
    width: '150px',
  },
  {
    title: 'Висновок',
    key: 'conclusion',
    sortable: false,
    align: 'end',
    width: '25%',
  },
]

const analyzeFinHeaders = [
  { title: 'Назва', key: 'name', sortable: false, width: '330px' },
  { title: 'Статус', key: 'status', sortable: false, width: '130px' },
  {
    title: 'Дата перевірки',
    key: 'finishDate',
    align: 'start',
    sortable: false,
    width: '115px',
  },
  {
    title: 'Відповідальний',
    key: 'responsible',
    sortable: false,
    width: '150px',
  },
  {
    title: 'Рівень ризику',
    key: 'conclusion',
    sortable: false,
    align: 'end',
    width: '25%',
  },
]

const analyzeKlpHeaders = [
  { title: 'Номер', key: 'number', sortable: false, width: '330px' },
  { title: 'Статус', key: 'status', sortable: false, width: '130px' },
  {
    title: 'Дата перевірки',
    key: 'finishDate',
    align: 'start',
    sortable: false,
    width: '115px',
  },
  {
    title: 'Відповідальний',
    key: 'responsible',
    sortable: false,
    width: '150px',
  },
  {
    title: 'Рішення',
    key: 'conclusion',
    align: 'end',
    sortable: false,
    width: '25%',
  },
]
// project verification [Analyze] headers --end
const adminSupervisorHeaders = [
  { title: 'Назва', key: 'name', align: 'start', sortable: true },
  {
    title: 'Супервайзер',
    key: 'supervisor',
    sortable: false,
    align: 'center',
  },
  {
    title: 'Мiдл',
    key: 'middle_manager',
    sortable: false,
    align: 'center',
  },
  { title: 'Дії', key: 'actions', align: 'end', sortable: false },
]

const applicationCalculationHeaders = [
  {
    title: '№ розрахунку',
    key: 'requestId',
    align: 'center',
    sortable: false,
  },
  { title: 'Дата', key: 'created_at', align: 'center', sortable: false },
  {
    title: 'Марка',
    key: 'requestData.leasedAssertMark.name',
    align: 'center',
    sortable: false,
  },
  {
    title: 'Модель',
    key: 'requestData.leasedAssertModel.name',
    align: 'center',
    sortable: false,
  },
  {
    title: 'Цiна',
    key: 'requestData.leasingAmount',
    align: 'center',
    sortable: false,
  },
  {
    title: 'Аванс',
    key: 'requestData.advance',
    align: 'center',
    sortable: false,
  },
  {
    title: 'Строк, міс',
    key: 'requestData.leasingTerm',
    align: 'center',
    sortable: false,
  },
  {
    title: 'Валюта',
    key: 'requestData.currency',
    align: 'center',
    sortable: false,
  },
  { title: 'Дії', key: 'actions', align: 'end', sortable: false },
]

export {
  applicationListHeaders,
  applicationServiceHeaders,
  leadListHeaders,
  calculationListHeaders,
  adminLeadCallHeaders,
  contactHeaders,
  contractorHeaders,
  dealerHeaders,
  agentHeaders,
  projectHeaders,
  legalCaseHeaders,
  historyLeadCallHeaders,
  paymentListHeaders,
  averagePriceListHeaders,
  archiveListHeaders,
  activitiesMeetingsListHeaders,
  activitiesRemindersListHeaders,
  dflListHeaders,
  dkpListHeaders,
  dolListHeaders,
  dflExtraAgreementListHeaders,
  dkpExtraAgreementListHeaders,
  dflConnectionsHeaders,
  dkpConnectionsHeaders,
  mortgagorListHeaders,
  serviceListHeaders,
  suretyAgreementListHeaders,
  eDocsListHeaders,
  leasingObjectListHeaders,
  marketingListHeaders,
  planListHeaders,
  financialListHeaders,
  leasingCommitteeListHeaders,
  legalDepartListHeaders,
  riskDepartmentListHeaders,
  scoringListHeaders,
  securityListHeaders,
  supportListHeaders,
  leasingObjectLogHeaders,
  scoringChangesHeaders,
  contactApplicationLinkHeaders,
  fopHeaders,
  edrChangeTableHeaders,
  analyzeSecurityHeaders,
  analyzeRiskHeaders,
  analyzeLegalHeaders,
  analyzeFinHeaders,
  analyzeKlpHeaders,
  adminSupervisorHeaders,
  applicationCalculationHeaders,
}
