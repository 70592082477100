<template>
  <div>
    <v-fade-transition hide-on-leave>
      <div v-if="additionalAgreementsRoute">
        <Filters :filters="filters" class="ml-2" />
        <SectionLoader v-if="loading" />
        <div v-show="!loading" class="pt-5">
          <v-row>
            <v-col :cols="12">
              <v-data-table
                :headers="headers"
                :items="extraAgreements"
                class="calc-table --cust-cell-pd"
                mobileBreakpoint="750"
                hide-default-footer
                :items-per-page="-1"
                :hide-default-header="!extraAgreements.length">
                <!-- eslint-disable-next-line -->
                <template #item.lessee="{ item }">
                  <ALink
                    :text="item.lessee.name"
                    :route="getEntityRoute(item.lessee.id, item.lessee.typeId)">
                  </ALink>
                </template>
                <!-- eslint-disable-next-line -->
                <template #item.provider="{ item }">
                  <ALink
                    :text="item.provider.name"
                    :route="
                      getEntityRoute(item.provider.id, item.provider.typeId)
                    ">
                  </ALink>
                </template>
                <!-- eslint-disable-next-line -->
                <template #item.contract="{ item }">
                  <ALink
                    v-if="item.contract"
                    :text="item.contract.number"
                    :route="{
                      name: 'dfl-contract',
                      params: { id: item.contract.id },
                    }">
                  </ALink>
                  <div v-else class="text-disabled">Не вказано</div>
                </template>
                <!-- eslint-disable-next-line -->
                <template #item.dkp="{ item }">
                  <ALink
                    v-if="item.dkp"
                    :text="item.dkp.number"
                    :route="{
                      name: 'dkp-contract',
                      params: { id: item.dkp.id },
                    }">
                  </ALink>
                  <div v-else class="text-disabled">Не вказано</div>
                </template>
                <!-- eslint-disable-next-line -->
                <template #item.date="{ item }">
                  <span>{{ toFormatDate(item.date) }}</span>
                </template>
                <!-- eslint-disable-next-line -->
                <template #item.number="{ item }">
                  <ALink
                    :text="item.number"
                    :route="{
                      name: `du-${duType}-agreement`,
                      params: { id: item.id },
                    }">
                  </ALink>
                </template>
                <!-- eslint-disable-next-line -->
                <template #item.actions="{ item }">
                  <TableCrud
                    :actionsArray="[
                      {
                        action: () =>
                          $router.push({
                            name: `du-${duType}-agreement`,
                            params: { id: item.id },
                          }),
                        text: 'Вiдкрити',
                        icon: 'mdi-folder-open-outline',
                      },
                    ]">
                  </TableCrud>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <Pagination
            :getItems="getExtraEgreement"
            :trigger="paginationTrigger" />
        </div>
      </div>
    </v-fade-transition>
    <router-view v-if="!additionalAgreementsRoute"></router-view>
  </div>
</template>
<script>
import TableCrud from '@/components/table-crud.vue'
import SectionLoader from '@/components/section-loader.vue'
import ALink from '@/components/Link.vue'
import Filters from '@/components/Filters.vue'
import Pagination from '@/components/Pagination.vue'
import {
  urlGetExtraAgreementDfl,
  urlGetExtraAgreementDkp,
} from '@/pages/request.js'
import { getEntityRoute, toFormatDate } from '@/utils/helperFunc'
import { useFilter } from '@/utils/mixins/useFilter'
import { reactive, computed, inject } from 'vue'
import {
  dflExtraAgreementListHeaders,
  dkpExtraAgreementListHeaders,
} from '@/utils/tableHeaders'
import { useRoute } from 'vue-router'
import { duTypeKey } from './injectionKeys.ts'

export default {
  components: {
    TableCrud,
    SectionLoader,
    ALink,
    Filters,
    Pagination,
  },
  setup() {
    const route = useRoute()

    const duType = inject(duTypeKey)

    const additionalAgreementsRoute = computed(() => {
      return route.name === `additional-agreements-${duType.value}`
    })

    const url = computed(() => {
      switch (duType.value) {
        case 'dfl':
          return urlGetExtraAgreementDfl
        case 'dkp':
          return urlGetExtraAgreementDkp
        default:
          throw new Error('[ExtraAgreements.vue] computed url - invalid duType')
      }
    })

    const filters = reactive({
      statusId: {
        value: null,
        component: 'v-autocomplete',
        attrs: {
          items: duType.value === 'dfl' ? 'dflStatuses' : 'dkpStatuses',
          label: 'Статус',
        },
        focus: {
          action: duType.value === 'dfl' ? 'addDFLStatuses' : 'addDKPStatuses',
        },
      },
      startDate: {
        value: null,
        component: 'DatePicker',
        attrs: { label: 'Період', range: true },
      },
      search: { value: null },
    })
    return {
      ...useFilter(filters),
      filters,
      additionalAgreementsRoute,
      duType,
      url,
    }
  },
  data() {
    return {
      extraAgreements: [],
      loading: false,
    }
  },
  computed: {
    headers() {
      switch (this.duType) {
        case 'dfl':
          return dflExtraAgreementListHeaders
        case 'dkp':
          return dkpExtraAgreementListHeaders
        default:
          throw new Error(
            '[ExtraAgreements.vue] computed headers - invalid duType'
          )
      }
    },
  },
  methods: {
    toFormatDate,
    getEntityRoute,
    getExtraEgreement(page = 1) {
      this.loading = true
      return this.$axios
        .get(this.url(page, this.filtersQueryObj))
        .then(res => {
          this.extraAgreements = res.data.data
          return res
        })
        .catch(this.$err)
        .finally(() => (this.loading = false))
    },
  },
}
</script>
