<template>
  <div>
    <div v-if="routeDocsList">
      <div class="crm-section-title title-absolute">E-Docs:</div>
      <Filters :filters="filters" class="ml-2" />
      <div v-show="!asyncLoading('getDocsList')" :cols="12" class="pr-0">
        <v-data-table
          :headers="eDocsListHeaders"
          :items="tBody"
          class="calc-table"
          mobileBreakpoint="1200"
          :hide-default-header="!tBody.length"
          hide-default-footer
          :items-per-page="-1"
          @dblclick:row="navigateToDoc">
          <!-- eslint-disable-next-line -->
          <template #item.lesseeName="{ item }">
            <ALink
              :text="item.lesseeName"
              :route="getEntityRoute(item.lesseeId, item.lesseeTypeId)">
            </ALink>
          </template>
          <template #item.contractNumber="{ item }">
            <ALink
              :text="item.contractNumber"
              :route="{
                name: 'dfl-contract',
                params: { id: item.contractId },
              }">
            </ALink>
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.actions="{ item }">
            <TableCrud
              :actionsArray="[
                {
                  action: () =>
                    $router.push({
                      name: 'e-docs-single',
                      params: { id: item.id },
                    }),
                  text: 'Переглянути',
                  icon: 'EyeIcon',
                  customIcon: true,
                },
              ]">
            </TableCrud>
          </template>
        </v-data-table>
        <Pagination :getItems="getDocsList" :trigger="paginationTrigger" />
      </div>
      <SectionLoader
        v-if="asyncLoading('getDocsList') && routeDocsList"></SectionLoader>
    </div>

    <RouterView v-if="!routeDocsList"></RouterView>
  </div>
</template>
<script>
import SectionLoader from '@/components/section-loader.vue'
import ALink from '@/components/Link.vue'
import Pagination from '@/components/Pagination.vue'
import { urlGetEdocsList } from '@/pages/request'
import { getEntityRoute, toFormatDate } from '@/utils/helperFunc'
import { mapState } from 'vuex'
import {
  backDate,
  tableDateSort,
  getContactInitials,
  getResponsibleShortName,
} from '@/utils/helperFunc'
import Filters from '@/components/Filters.vue'
import TableCrud from '@/components/table-crud.vue'
import { useSelect } from '@/utils/mixins/useSelect'
import { useFilter } from '@/utils/mixins/useFilter'
import { reactive } from 'vue'
import { eDocsListHeaders } from '@/utils/tableHeaders'
export default {
  components: {
    TableCrud,
    Filters,
    SectionLoader,
    ALink,
    Pagination,
  },
  setup() {
    const filters = reactive({
      search: { value: null },
    })
    return {
      ...useSelect(),
      ...useFilter(filters),
      filters,
    }
  },
  data() {
    return {
      tBody: [],
      eDocsListHeaders,
    }
  },
  computed: {
    ...mapState({ selectItems: state => state.selectItems }),
    routeDocsList() {
      return this.$route.name === 'e-docs'
    },
  },
  methods: {
    getEntityRoute,
    backDate,
    tableDateSort,
    getContactInitials,
    getResponsibleShortName,
    toFormatDate,
    getDocsList(page = 1) {
      return this.asyncAction('getDocsList', null, () => {
        return this.$axios
          .get(urlGetEdocsList(page, this.filtersQueryObj))
          .then(res => {
            if (res?.data?.message) {
              return this.$setSnackbar({
                text: res.data.message,
                color: 'warning',
              })
            }
            this.tBody.splice(0)
            this.tBody.push(...res.data.data)
            return res
          })
          .catch(this.$err)
      })
    },
    navigateToDoc(e, row) {
      this.$router.push({ name: 'e-docs-single', params: { id: row.item.id } })
    },
  },
}
</script>
<style lang="scss">
.v-list-item__content {
  padding-left: 15px !important;
}
.container.container--fluid {
  padding-left: 10px !important;
  padding-right: 10px !important;
  margin: 0 auto !important;
}
</style>
