<template>
  <v-row>
    <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
      <span class="label">Прізвище</span>
      <v-text-field
        v-model="contactAgent.surname"
        :error-messages="surnameErr"
        placeholder="Введіть текст"
        dense
        hide-details
        @blur="v$.contactAgent.surname.$touch()">
      </v-text-field>
    </v-col>
    <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
      <span class="label">Iм`я</span>
      <v-text-field
        v-model="contactAgent.name"
        :error-messages="nameErr"
        placeholder="Введіть текст"
        dense
        hide-details
        @blur="v$.contactAgent.name.$touch()">
      </v-text-field>
    </v-col>
    <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
      <span class="label">По батькові</span>
      <v-text-field
        v-model="contactAgent.patronymic"
        :error-messages="patronymicErr"
        placeholder="Введіть текст"
        dense
        hide-details
        @blur="v$.contactAgent.patronymic.$touch()">
      </v-text-field>
    </v-col>
    <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
      <span class="label">Посада</span>
      <v-select
        v-model="contactAgent.postId"
        :items="selectItems.careerPosts"
        :error-messages="postIdErr"
        item-title="name"
        item-value="id"
        hide-details
        placeholder="Оберіть зі списку"
        @blur="v$.contactAgent.postId.$touch()">
      </v-select>
    </v-col>
    <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
      <span class="label">Телефон</span>
      <v-text-field
        id="d-o-a-c"
        v-model="contactAgent.mainPhone"
        :error-messages="mainPhoneErr"
        dense
        hide-details
        placeholder="Введіть текст"
        @input="phoneMask($event, 'd-o-a-c')"
        @blur="v$.contactAgent.mainPhone.$touch()">
      </v-text-field>
    </v-col>
    <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
      <span class="label">Почта</span>
      <v-text-field
        v-model="contactAgent.mainEmail"
        :error-messages="mainEmailErr"
        placeholder="Введіть текст"
        dense
        hide-details
        @blur="v$.contactAgent.mainEmail.$touch()">
      </v-text-field>
    </v-col>
  </v-row>
</template>
<script>
import { required, email, minLength } from '@vuelidate/validators'
import { mapState } from 'vuex'
import { urlDealerOutletCreateAgent } from '@/pages/request'
import { setErrHandler } from '@/utils/helperFunc'
import { phoneMask } from '@/utils/masks'
import { useVuelidate } from '@vuelidate/core'
export default {
  props: { dialog: { type: Object } },
  setup() {
    return { v$: useVuelidate() }
  },
  validations() {
    return {
      contactAgent: {
        surname: { required },
        name: { required },
        patronymic: { required },
        postId: { required },
        mainPhone: {
          minLength: () => minLength(23),
          required,
        },
        mainEmail: { email, required },
      },
    }
  },
  data: () => ({
    contactAgent: {
      lastName: null,
      name: null,
      patronymic: null,
      postId: null,
      mainPhone: null,
      mainEmail: null,
    },
  }),
  computed: {
    ...mapState({ selectItems: state => state.selectItems }),
    validation() {
      return { v$: this.v$.contactAgent, name: 'contactAgent' }
    },
    createContactAgent() {
      return this.dialog.action === 'createContactAgent'
    },
    nameErr() {
      return setErrHandler(this.v$.contactAgent.name)
    },
    patronymicErr() {
      return setErrHandler(this.v$.contactAgent.patronymic)
    },
    surnameErr() {
      return setErrHandler(this.v$.contactAgent.surname)
    },
    postIdErr() {
      return setErrHandler(this.v$.contactAgent.postId)
    },
    mainEmailErr() {
      return setErrHandler(this.v$.contactAgent.mainEmail)
    },
    mainPhoneErr() {
      return setErrHandler(this.v$.contactAgent.mainPhone)
    },
  },
  methods: {
    phoneMask,
    setErrHandler,
    submit() {
      switch (true) {
        case this.createContactAgent:
          return this.postContactAgent()
        default:
          return Promise.reject('No handler')
      }
    },
    postContactAgent() {
      return this.$axios.post(
        urlDealerOutletCreateAgent(this.dialog.params.dealerOutletId),
        this.contactAgent
      )
    },
  },
  created() {
    this.$store.dispatch('addCareerPosts')
  },
  mounted() {
    Object.assign(this.contactAgent, this.dialog.dialogItem)
  },
}
</script>
