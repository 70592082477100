<template>
  <div>
    <FilesUploader
      v-model:documents="signedActOfProvisionOfServices.document"
      label="Документ"
      dense
      required
      :error-messages="documentErr"
      @blur="v$.signedActOfProvisionOfServices.document.$touch()" />
  </div>
</template>

<script>
import FilesUploader from '@/components/FilesUploader.vue'
import { urlSendAgentPaymentAct } from '@/pages/request'
import { setErrHandler } from '@/utils/helperFunc'
import { minLength, required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'

export default {
  name: 'AgentPaymentAct',
  components: { FilesUploader },
  setup() {
    return { v$: useVuelidate() }
  },
  props: {
    id: { type: Number },
  },
  validations() {
    return {
      signedActOfProvisionOfServices: {
        document: { required, minLength: minLength(1) },
      },
    }
  },
  data: () => ({
    signedActOfProvisionOfServices: {
      document: null,
    },
  }),
  computed: {
    validation() {
      return {
        v$: this.v$.signedActOfProvisionOfServices,
        name: 'signedActOfProvisionOfServices',
        payload: {
          sectionPayload: this.signedActOfProvisionOfServices.document,
        },
      }
    },
    documentErr() {
      return setErrHandler(this.v$.signedActOfProvisionOfServices.document)
    },
  },
  methods: {
    urlSendAgentPaymentAct,
    submit() {
      const req = {
        signedActOfProvisionOfServices:
          this.signedActOfProvisionOfServices.document,
      }
      return this.$axios
        .post(urlSendAgentPaymentAct(this.id), req)
        .then(res => {
          if (res.data.message)
            return this.$setSnackbar({
              text: res.data.message,
              color: 'warning',
            })
          this.$setSnackbar({ text: 'Акти на виплату доданi' })
          return res.data
        })
    },
  },
}
</script>

<style scoped></style>
