<template>
  <div>
    <div class="document-list">
      <div class="pa-1">
        <span v-if="!doc" style="display: flex; align-items: stretch">
          <v-icon class="doc-desc-part" :size="iconSize" color="#cd5c5c">
            {{ icon }}
          </v-icon>
          <span class="doc-desc-part" style="font-size: 0.78rem">
            &nbsp;&nbsp;{{ label }}
            &nbsp;
            <span v-show="required" class="required-star"> * </span>
          </span>
          <span class="doc-desc-part">
            <v-btn
              v-if="!doc"
              icon
              size="x-small"
              class="rounded-circle"
              :disabled="disabled"
              @click="$refs[id].click()">
              <v-icon :size="18" color="grey darken-2" small>
                {{ 'mdi-paperclip' }}
              </v-icon>
            </v-btn>
          </span>
        </span>
        <span v-if="doc">
          <v-icon :size="22" color="#cd5c5c">
            {{ 'mdi-file-document-outline' }}
          </v-icon>
          <span style="font-size: 0.78rem">{{ doc.name }}</span>
          <span style="color: black">
            {{ `(${(doc.size / 1000).toFixed(2)} - kb)` }}
          </span>
          &nbsp;
          <v-btn
            color="#d24a43"
            class="document-delete-icon"
            icon
            size="x-small"
            :disabled="disabled"
            @click="deleteDoc()">
            <v-icon :size="16">
              {{ 'mdi-close' }}
            </v-icon>
          </v-btn>
          <v-btn
            v-if="fileLoading"
            class="ml-2"
            :loading="true"
            icon
            :width="14"
            :height="14">
          </v-btn>
        </span>
      </div>
    </div>
    <div>
      <input
        :id="id"
        :ref="id"
        class="hidden-input"
        type="file"
        :accept="acceptedExt"
        @change="emitDocument" />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    disabled: { type: Boolean },
    label: { type: String },
    document: { type: File },
    required: { type: Boolean },
    acceptedExt: { type: String, defautl: '.docx' },
    maxSize: { type: Number, default: 5242880 },
    uploadPath: {
      type: String,
      default: '/json/document/upload',
    },
    icon: {
      type: String,
      default: 'mdi-file-document-outline',
    },
    iconSize: {
      type: Number,
      default: 16,
    },
  },
  data: () => ({
    clonedDocument: null,
    fileLoading: false,
    id: null,
    doc: null,
  }),
  methods: {
    emitDocument() {
      this.doc = this.$refs[this.id].files[0]
      this.$emit('update:document', this.doc)
    },
    deleteDoc() {
      const inputEvent = new Event('input', { bubbles: true })
      const el = document.getElementById(this.id)
      el.value = null
      el.dispatchEvent(inputEvent)
      this.doc = null
      this.clonedDocument = null
      this.$emit('update:document', this.doc)
    },
  },
  watch: {
    document: {
      deep: true,
      handler(val) {
        this.$emit('update:document', val)
      },
    },
  },
  created() {
    this.$set(this, 'clonedDocument', this.document)
  },
  mounted() {
    this.id = this._uid
    if (!Array.isArray(this.document)) {
      this.$emit('update:document', null)
    }
    this.doc = this.document
  },
}
</script>
<style lang="scss" scoped>
.document-list div {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  transition: color 0.3s ease;
}
.document-list div:hover {
  color: #4d4c4c;
}
.document-line-wrapper {
  padding: 0 10px;
  font-size: 0.85rem;
  .document-delete-icon {
    opacity: 0;
    transition: opacity 0.2s ease-in;
  }
  &:hover {
    .document-delete-icon {
      opacity: 1;
    }
  }
}
.hidden-input {
  visibility: hidden;
  position: absolute;
  left: 0;
  top: 0;
}
.required-star {
  color: red;
  font-size: 1.05rem;
}
.doc-desc-part {
  flex: 0 1 auto;
  align-self: start;
  justify-content: start;
}
</style>
