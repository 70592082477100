<template>
  <v-container style="padding-top: 0">
    <v-row v-if="leadData" class="ma-0">
      <v-col :cols="12" :md="3" class="pa-0">
        <v-fade-transition hide-on-leave>
          <v-row>
            <v-col :cols="12" :md="11" style="padding-top: 8px !important">
              <v-row>
                <v-col class="white-block" :cols="12" :sm="12">
                  <v-row class="pl-3 mt-2">
                    <div class="text-field">
                      <span class="subtitle">Назва</span>
                      <span class="text-desc">{{
                        leadData.leadName || 'Не вказано'
                      }}</span>
                    </div>
                  </v-row>
                  <v-row
                    v-if="
                      leadData &&
                      leadData.source_type &&
                      leadData.source_type.id === 2
                    ">
                    <v-col :cols="12" :md="12" class="pb-0">
                      <div class="text-field">
                        <span class="subtitle">Джерело</span>
                        <span
                          class="text-desc"
                          style="display: flex !important; align-items: center">
                          <WebSiteIcon />
                          <span class="ml-1">{{ sourceType }}</span>
                        </span>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col :cols="12" :md="12" class="pb-0">
                      <span class="subtitle">Тип клієнта</span>
                      <v-select
                        v-model="leadData.clientTypeId"
                        placeholder="Оберіть тип клієнта"
                        :error-messages="clientTypeIdErr"
                        :readonly="readonly"
                        :items="
                          directoryItems('clientTypes', leadData.client_type)
                        "
                        hide-details
                        item-title="name"
                        item-value="id"
                        @focus="asyncAction('addClientTypes')"
                        @blur="v$.leadData.clientTypeId.$touch()">
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col :cols="12" :md="12" class="pb-0">
                      <span class="subtitle">Потреба клієнта</span>
                      <v-select
                        v-model="leadData.clientNeedId"
                        placeholder="Оберіть потреба клієнта"
                        :items="
                          directoryItems('clientNeeds', leadData.client_need)
                        "
                        hide-details
                        :error-messages="clientNeedIdErr"
                        :readonly="readonly"
                        item-title="name"
                        item-value="id"
                        @focus="asyncAction('addClientNeeds')"
                        @blur="v$.leadData.clientNeedId.$touch()">
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col :cols="12" :md="12" class="pb-0">
                      <ChangeRespobsible
                        v-model:responsibleId="leadData.responsibleId"
                        :read-only="leadData.readOnly"
                        :responsible="leadData.responsible" />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col :cols="12" :md="12" class="pt-0">
                      <div class="text-field">
                        <span class="subtitle">Дата створення</span>
                        <span class="text-desc">{{
                          toFormatDate(leadData.created_at, false, true) ||
                          'Не вказано'
                        }}</span>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-fade-transition>
      </v-col>

      <v-col :cols="12" :md="9" style="padding: 0">
        <div
          style="
            background: #efefef;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.07);
            border-radius: 4px;
            margin-bottom: -15px;
          ">
          <div>
            <StatusBar
              class="mt-2"
              :currentStatus="leadData.status.id"
              :statuses="statusBarInfo"
              :subStatuses="{
                done:
                  leadData.status.id < 5
                    ? statuses.filter(s => [5, 6, 7, 8, 9].includes(s.id))
                    : [
                        {
                          ...statuses.find(s => s.id === 4),
                          select: 'done',
                          arrow: true,
                        },
                      ],
              }"
              :submit="toggleStatus"
              spread-items
              size="small">
            </StatusBar>
          </div>
          <div class="social-wrapper" style="padding: 0 0 15px 15px">
            <div class="social" style="display: flex; align-items: center">
              <v-tooltip bottom text="Відправити повiдомлення на Whatsapp">
                <template #activator="{ props }">
                  <v-btn
                    size="x-small"
                    icon
                    style="margin-right: 12px"
                    v-bind="props"
                    @click="() => $setSnackbar({ text: 'to be continued..' })">
                    <TellIcon />
                  </v-btn>
                </template>
                <span>Відправити повiдомлення на Whatsapp</span>
              </v-tooltip>
              <v-tooltip bottom text="Відправити лист на пошту">
                <template #activator="{ props }">
                  <v-btn
                    size="x-small"
                    icon
                    style="margin-right: 35px"
                    :loading="loading"
                    v-bind="props"
                    @click="openCreateSmsDialog(2)">
                    <EmailIcon />
                  </v-btn>
                </template>
                <span>Відправити лист на пошту</span>
              </v-tooltip>
              <v-tooltip bottom text="Відправити повiдомлення на Viber">
                <template #activator="{ props }">
                  <v-btn
                    size="x-small"
                    icon
                    style="margin-right: 12px"
                    :loading="loading"
                    v-bind="props"
                    @click="openCreateSmsDialog(3)">
                    <ViberIcon />
                  </v-btn>
                </template>
                <span>Відправити повiдомлення на Viber</span>
              </v-tooltip>
              <v-tooltip bottom text="Відправити повiдомлення на телефон">
                <template #activator="{ props }">
                  <v-btn
                    size="x-small"
                    icon
                    style="margin-right: 35px"
                    :loading="loading"
                    v-bind="props"
                    @click="openCreateSmsDialog(1)">
                    <MessageIcon />
                  </v-btn>
                </template>
                <span>Відправити повiдомлення на телефон</span>
              </v-tooltip>
              <v-tooltip bottom text="Створити нагадування">
                <template #activator="{ props }">
                  <v-btn
                    size="x-small"
                    icon
                    v-bind="props"
                    @click="openCreateNotificationDialog()">
                    <NotifIcon />
                  </v-btn>
                </template>
                <span>Створити нагадування</span>
              </v-tooltip>
              <v-tooltip
                v-if="leadData.application"
                bottom
                text="Перейти в заявку">
                <template #activator="{ props }">
                  <v-btn
                    class="ml-4"
                    size="small"
                    icon
                    v-bind="props"
                    @click="navigateToApplication">
                    <v-icon color="#636363">{{
                      'mdi-link-box-variant-outline'
                    }}</v-icon>
                  </v-btn>
                </template>
                <span>Перейти в заявку</span>
              </v-tooltip>
            </div>
          </div>
          <div class="notif-wrapper">
            <div
              v-for="item in firstNotifications"
              :key="item.id"
              class="notif-block">
              <div class="notif-block__header">
                <span class="notif-title">{{ item.taskMessage }}</span>
                <svg
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6.587 19.912C6.979 20.304 7.45 20.5 8 20.5C8.54934 20.5007 9.02 20.305 9.412 19.913C9.804 19.521 10 19.05 10 18.5H6C5.99934 19.0493 6.195 19.52 6.587 19.912Z"
                    fill="#FC7247" />
                  <path
                    d="M0.287004 17.212C0.479004 17.404 0.71667 17.5 1 17.5H15C15.2827 17.5007 15.52 17.405 15.712 17.213C15.904 17.021 16 16.7833 16 16.5C16.0007 16.2173 15.905 15.98 15.713 15.788C15.521 15.596 15.2833 15.5 15 15.5H14V13.6C12.7173 13.1007 11.6883 12.3133 10.913 11.238C10.1377 10.1627 9.75 8.91667 9.75 7.5C9.75067 6.73334 9.87167 6.00834 10.113 5.325C10.3543 4.64167 10.7167 4.00834 11.2 3.425C10.9673 3.259 10.705 3.11334 10.413 2.988C10.121 2.86267 9.81667 2.76667 9.5 2.7V2C9.50067 1.584 9.355 1.23 9.063 0.938002C8.771 0.646002 8.41667 0.500002 8 0.500002C7.584 0.499336 7.23 0.645002 6.938 0.937002C6.646 1.229 6.5 1.58334 6.5 2V2.7C5.2 3.03267 4.125 3.72834 3.275 4.787C2.425 5.84567 2 7.08334 2 8.5V15.5H1C0.717337 15.4993 0.480004 15.595 0.288004 15.787C0.0960036 15.979 3.4602e-06 16.2167 3.4602e-06 16.5C-0.000663206 16.7827 0.0950036 17.02 0.287004 17.212Z"
                    fill="#FC7247" />
                  <path
                    d="M20 7.5C20 9.70914 18.2091 11.5 16 11.5C13.7909 11.5 12 9.70914 12 7.5C12 5.29086 13.7909 3.5 16 3.5C18.2091 3.5 20 5.29086 20 7.5Z"
                    fill="#62C14A" />
                </svg>
              </div>
              <div class="notif-block__footer">
                <span class="notif-date">{{ toFormatDate(item.date) }}</span>
                <span class="notif-user">{{
                  getContactInitials(item.user)
                }}</span>
                <v-btn
                  class="notif-btn"
                  size="x-small"
                  :loading="loading"
                  @click="completeTask(item)">
                  Завершити
                </v-btn>
              </div>
            </div>
          </div>
        </div>

        <v-tabs
          v-model="currentTab"
          class="mb-5 mt-8"
          slider-color="#FC7247"
          show-arrows
          :height="38">
          <v-tab
            replace
            :to="{
              name: 'lead-general-info',
              params: { id: $route.params.id },
            }">
            Основна iнформацiя
          </v-tab>
          <v-tab
            replace
            :to="{
              name: 'lead-communication',
              params: { id: $route.params.id },
            }">
            Активностi
          </v-tab>
          <v-tab
            v-if="lead.duplicates.length"
            replace
            :to="{ name: 'lead-history', params: { id: $route.params.id } }">
            Iсторiя
          </v-tab>
        </v-tabs>

        <RouterView
          v-if="leadData"
          v-model:lead="leadData"
          style="min-height: 390px"
          :cb="() => $store.commit('refreshPage')"
          :v="v$"
          :readonly="readonly"
          @update-lead="getLead().then(() => (loading = false))">
        </RouterView>

        <v-row v-if="!readonly">
          <ActionButtons
            :confirm="updateLead"
            :cancel="resetLeadData"
            :confirmLoading="loading"
            :readonly="readonly"
            :confirmDisable="!isChanged"
            :cancelDisable="!isChanged || loading">
          </ActionButtons>
        </v-row>
      </v-col>
    </v-row>
    <SectionLoader v-if="loading"></SectionLoader>
  </v-container>
</template>

<script>
import ViberIcon from '@/assets/svg/social/viber-icon.vue'
import MessageIcon from '@/assets/svg/social/message-icon.vue'
import EmailIcon from '@/assets/svg//social/email-icon.vue'
import NotifIcon from '@/assets/svg//social/notif-icon.vue'
import TellIcon from '@/assets/svg/social/tell-icon.vue'
import WebSiteIcon from '@/assets/svg/webSite-icon.vue'
import StatusBar from '@/components/StatusBar.vue'
import SectionLoader from '@/components/section-loader.vue'
import ActionButtons from '@/components/action-buttons.vue'
import { mapState, mapGetters } from 'vuex'
import { v$Notify } from '@/utils/helperFunc'
import { urlTaskPlanTaskUpdate } from '@/pages/request'
import { required, minLength, email } from '@vuelidate/validators'
import {
  toFormatDate,
  setErrHandler,
  getResponsibleShortName,
  getContactInitials,
} from '@/utils/helperFunc'
import ChangeRespobsible from '@/components/ChangeRespobsible.vue'
import { useVuelidate } from '@vuelidate/core'
import { useSelect } from '@/utils/mixins/useSelect'

export default {
  components: {
    ChangeRespobsible,
    StatusBar,
    SectionLoader,
    ActionButtons,
    TellIcon,
    WebSiteIcon,
    NotifIcon,
    EmailIcon,
    MessageIcon,
    ViberIcon,
  },
  setup() {
    return { v$: useVuelidate(), ...useSelect() }
  },
  validations() {
    const leadData = {
      name: { required },
      phone: this.leadData?.phone ? { required, minLength: minLength(23) } : {},
      email: { email },
      clientTypeId: { required },
      clientNeedId: { required },
      responsibleId: { required },
    }
    return {
      leadData,
    }
  },
  data: () => {
    return {
      currentTab: 0,
      loading: false,
      leadData: null,
    }
  },
  computed: {
    ...mapState({
      lead: state => state.leads.curLead,
      statuses: state => state.selectItems.leadStatuses,
      user: state => state.user,
      selectItems: state => state.selectItems,
      clientTypes: state => state.selectItems.clientTypes,
      clientNeeds: state => state.selectItems.clientNeeds,
    }),
    ...mapGetters({
      activeUsers: 'activeUsers',
    }),
    readonly() {
      if (this.leadData.responsibleId) {
        return !(
          this.leadData.statusId === 2 &&
          (this.lead.responsibleId === this.user.id || this.user.roleId === 1)
        )
      } else return false
    },
    sourceType() {
      const utmMedium = this.leadData.utmMedium || ''
      const sourceName = utmMedium === 'cpc' ? 'Реклама' : 'Органічний трафік'
      const source = this.leadData.source?.name || '---'

      return `${source} (${sourceName})`
    },
    statusBarInfo() {
      return this.statuses.length
        ? [
            this.statuses[0],
            this.statuses[1],
            this.statuses[2],
            this.doneStatus,
          ]
        : []
    },
    doneStatus() {
      if ([5, 6, 7, 8, 9].includes(this.leadData.status.id)) {
        return { ...this.leadData.status, select: 'done', arrow: true }
      } else {
        return {
          ...this.statuses.find(s => s.id === 4),
          select: 'done',
          arrow: true,
        }
      }
    },
    isChanged() {
      return JSON.stringify(this.leadData) !== JSON.stringify(this.lead)
    },
    nameErr() {
      return setErrHandler(this.v$.leadData.name)
    },
    clientTypeIdErr() {
      return setErrHandler(this.v$.leadData.clientTypeId)
    },
    clientNeedIdErr() {
      return setErrHandler(this.v$.leadData.clientNeedId)
    },
    firstNotifications() {
      return Object.clone(this.leadData?.task_schedulers || [])
        .filter(t => !t.isDone)
        .sort((a, b) => new Date(a.date) - new Date(b.date))
        .slice(0, 3)
    },
  },
  methods: {
    router() {
      return router
    },
    setErrHandler,
    toFormatDate,
    getResponsibleShortName,
    getContactInitials,
    completeTask(item) {
      item.isDone = true
      this.loading = true
      return this.$axios.post(urlTaskPlanTaskUpdate(item.id), item).then(() => {
        this.$setSnackbar({ text: 'Нагадування завершено' })
        this.lead.task_schedulers = this.lead.task_schedulers.map(t =>
          t.id === item.id ? { ...t, isDone: true } : t
        )
        this.loading = false
      })
    },
    navigateToApplication() {
      this.$router.push({
        name: 'edit-application',
        params: { applicationId: this.leadData?.application?.id },
      })
    },
    openCreateNotificationDialog() {
      this.$store.commit('setDialog', {
        title: 'Деталі по нагадуванню',
        dialogItem: {
          entityId: this.leadData.id,
          entityTypeId: 4,
        },
        params: {
          dialogHeight: 700,
          dialogWidth: 600,
          cb: () =>
            this.getLead(this.leadData.id).then(() => (this.loading = false)),
        },
        action: 'task-plan-dialog',
      })
    },
    openCreateSmsDialog(type) {
      if (type === 2 && !this.leadData.email)
        return this.$setSnackbar({
          text: '[Email] - повинен бути заповнений',
          color: 'warning',
        })
      if ([1, 3].includes(type) && this.leadData.phone === null) {
        return this.$setSnackbar({
          text: '[Телефон] - повинен бути заповнений',
          color: 'warning',
        })
      }
      if (!this.leadData.responsible) {
        return this.$setSnackbar({
          text: 'Вiдповiдальна особа у лiда не знайдена',
          color: 'warning',
        })
      }
      this.loading = true
      this.$store.commit('setDialog', {
        title: 'Вiдправка повiдомлення',
        dialogItem: {
          entityId: this.leadData.id,
          emails: this.leadData.email,
          phones: this.leadData.phone,
          resEmail: this.leadData.responsible.email,
          photo: this.leadData.responsible.photo,
          name: this.leadData.responsible.name,
        },
        params: {
          dialogWidth: 500,
          submitText: 'Відправити',
          rejectText: 'Скасувати',
          type: type,
          entityTypeId: 4,
          cb: () =>
            this.getLead(this.leadData.id).then(() => (this.loading = false)),
        },
        action: 'send-sms-dialog',
      })
      this.loading = false
    },
    async toggleStatus(id) {
      this.v$.leadData.$touch()
      if (this.leadData.statusId === 4) return
      if (this.leadData.statusId === 1 && id > 2)
        return this.$setSnackbar({
          text: 'Потрібно взяти лід в роботу',
          color: 'error',
        })
      if (id === 4 && this.leadData.responsibleId === null) {
        return this.$setSnackbar({
          text: 'Відсутня відповідальна особа за лідом',
          color: 'error',
        })
      }

      this.loading = true
      if (id === 4) {
        this.v$.$touch()
        if (this.v$.$invalid) return
        await this.createApplication()
        this.loading = false
      }
      try {
        await this.$store.dispatch('updateLeadStatus', {
          id: this.leadData.id,
          statusId: id,
        })
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          this.$setSnackbar({
            text: error.response.data.message,
            color: 'warning',
          })
        }
      } finally {
        this.loading = false
      }
      this.loading = false
    },
    async createApplication() {
      const { data } = await this.$store.dispatch(
        'createApplicationFromLead',
        this.leadData.id
      )
      if (data.status === 200) {
        this.$setSnackbar({ text: 'Заявку створено' })
        this.$router.push({ name: 'leads' })
      } else if (data.status === 400) {
        this.$setSnackbar({ text: 'Заявка вже існує' })
      }
    },
    async updateLead() {
      this.v$.leadData.$touch()
      if (!this.v$.$invalid) {
        this.loading = true
        delete this.leadData?.responsible
        const res = await this.$store.dispatch('updateLead', this.leadData)
        if (res.message) {
          this.loading = false
          this.resetLeadData()
          return this.$setSnackbar({ text: res.message, color: 'warning' })
        }
        this.leadData = JSON.parse(JSON.stringify(this.lead))
        this.$setSnackbar({ text: 'Лід оновлено' })
        this.loading = false
      } else v$Notify(this.v$.leadData, 'lead')
    },
    resetLeadData() {
      this.leadData = JSON.parse(JSON.stringify(this.lead))
    },
    async getLead() {
      this.loading = true
      await this.$store.dispatch('getSingleLead', this.$route.params.id)
      this.leadData = JSON.parse(JSON.stringify(this.lead))
      this.loading = true
    },
  },
  watch: {
    '$route.params.id': async function () {
      await this.getLead()
      this.$store.commit('setBreadScrumbPart', [null, this.leadData.leadName])

      this.loading = false
    },
    leadData: {
      handler(data) {
        Object.entries(data).forEach(field => {
          if (field[1] === '') data[field[0]] = null
        })
      },
      deep: true,
    },
    lead: {
      handler(data) {
        this.leadData = JSON.parse(JSON.stringify(data))
      },
      deep: true,
    },
  },
  async mounted() {
    await this.getLead()
    await this.$store.dispatch('getLeadStatuses')
    this.$store.commit('setBreadScrumbPart', [null, this.leadData.leadName])
    this.loading = false
  },
}
</script>

<style lang="scss" scoped>
.res-post {
  display: block;
  width: 215px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 13px;
}
.res-block {
  margin-bottom: 15px;
  &:hover .res-change {
    display: block !important;
  }
  .res-change {
    display: none;
    cursor: pointer;
    color: #09487a;
    font-weight: 500;
    font-size: 13px;
  }
}

.social-icon svg {
  height: 24px;
  width: 24px;
}
.v-tab {
  padding: 0 !important;
  margin-right: 25px;
}
.notif-wrapper {
  gap: 5px;
  padding: 0 15px 10px 15px;
  display: flex;
  flex-wrap: wrap;
  .notif-block {
    background: #ffffff;
    box-shadow: 0 2px 8px rgb(0 0 0 / 4%);
    border-radius: 4px;
    width: 265px;
    height: 100px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 10px;
    transition: all 0.3s;
    &:hover {
      background-color: #e2e2e2;
    }
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .notif-title {
        font-size: 14px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 215px;
      }
    }
    &__footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .notif-date {
        font-size: 12px;
      }
      .notif-user {
        font-size: 12px;
      }
      .notif-btn {
        background: #fc7247 !important;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04) !important;
        border-radius: 4px !important;
        color: #fff !important;
      }
    }
  }
}
.border-right {
  border-right: 1px solid #ccc;
}
.field-link:hover {
  cursor: pointer !important;
}
a.v-tab {
  font-weight: 700;
  font-size: 14px;
  color: #838282 !important;
}
a.v-tab-item--selected.v-tab {
  font-weight: 700 !important;
  font-size: 14px;
  color: #08487a !important;
}
.subtitle {
  color: #838282;
  font-weight: 500;
  font-size: 12px;
  display: block;
  margin-bottom: 5px;
}

.text-desc {
  font-weight: 400;
  font-size: 13px;
  color: #1c1d21;
  display: block;
}

.crm-layout .crm .crm-content .v-data-table-header {
  background: transparent !important;
  border-bottom: none !important;
}
</style>
