function editorConfig(selector, templates, replaceValues = {}) {
  return {
    selector: `#${selector}`,
    height: 330,
    menubar: false,
    toolbar_mode: 'wrap',
    plugins: [
      'advlist autolink lists link image charmap print preview image anchor pagebreak table',
      'searchreplace visualblocks code fullscreen',
      `insertdatetime media table paste code help wordcount ${
        templates?.length ? 'template' : ''
      }`,
    ],
    toolbar: `undo redo | formatselect | bold italic backcolor | fontsizeselect | lineheight | fontselect |
      alignleft aligncenter alignright alignjustify |
      bullist numlist outdent indent | removeformat | help ${
        templates?.length ? 'template' : ''
      } | image | pagebreak |
      table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol`,
    template_replace_values: { ...replaceValues },
    templates: templates?.length ? [...templates] : [],
  }
}

const setHtml = (editorData, selector, callback, templates, replaceValues) => {
  setTimeout(() => {
    setTimeout(() => {
      window.tinymce.remove()
      window.tinymce.init(editorConfig(selector, templates, replaceValues))
      window.tinymce.get(selector).setContent(editorData || '')
      typeof callback === 'function' && callback()
    }, 0)
  }, 0)
}
// @ts-ignore
const getHtml = selector => {
  if (window.tinymce && window.tinymce.get(selector)) {
    return window.tinymce.get(selector).getContent()
  }
}

// set editor styles
const setEditorStyles = (selector, styles) => {
  if (window.tinymce && window.tinymce.get(selector)) {
    window.tinymce.get(selector).getBody().style = styles
  }
}

// editor readOnly
const toggleEditor = (selector, enable) => {
  if (window.tinymce && window.tinymce.get(selector)) {
    if (enable) {
      window.tinymce.get(selector).setMode('design')
    } else {
      window.tinymce.get(selector).setMode('readonly')
    }
  }
}

// выделение содержимого текста
const getSelectedText = selector => {
  if (window.tinymce && window.tinymce.get(selector)) {
    return window.tinymce.get(selector).selection.getContent({ format: 'text' })
  }
}

// add custom editor buttons
const addCustomToolbarButton = (selector, buttonName, buttonText, onClickCallback) => {
  if (window.tinymce && window.tinymce.get(selector)) {
    window.tinymce.get(selector).ui.registry.addButton(buttonName, {
      text: buttonText,
      onAction: onClickCallback,
    })
  }
}

export {
  getHtml,
  setHtml,
  editorConfig,
  addCustomToolbarButton,
  getSelectedText,
  toggleEditor,
}
