<template>
  <div>
    <div class="d-flex justify-start">
      <v-btn
        v-if="marketing"
        data-readonly="hide"
        style="background: rgb(8, 72, 122); border-radius: 4px; color: #fff"
        :disabled="!marketing.isActive"
        @click="addExpenses()">
        Додати витрату
      </v-btn>
    </div>
    <div v-show="marketing.advertisingCosts">
      <v-data-table
        :headers="tHead"
        :items="marketing.advertisingCosts || []"
        class="calc-table"
        mobileBreakpoint="1200"
        hide-default-footer>
        <!-- eslint-disable-next-line -->
        <template #item.amount="{ item }">
          <span>{{ getBeautyNum(item.amount, { float: 2 }) }} ₴</span>
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.actions="{ item }">
          <TableCrud
            :actionsArray="[
              {
                action: () => editExpenses(item),
                text: 'Редагувати',
                icon: 'EditPencil',
                customIcon: true,
              },
              {
                action: () => deleteExpenses(item.id),
                text: 'Видалити',
                icon: 'DeleteIcon',
                customIcon: true,
              },
            ]">
          </TableCrud>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { urlDeleteExpensesMarketingCompany } from '@/pages/request'
import TableCrud from '@/components/table-crud.vue'
import { getBeautyNum } from '@/utils/helperFunc'
export default {
  components: { TableCrud },
  props: {
    marketing: { type: Object },
  },
  data: () => ({
    tHead: [
      { title: 'Місяць', key: 'shortDate', align: 'start', sortable: false },
      { title: 'Витрати', key: 'amount', align: 'start', sortable: false },
      { title: 'Дiї', key: 'actions', align: 'end', sortable: false },
    ],
    advertisingCosts: [],
  }),
  methods: {
    getBeautyNum,
    addExpenses() {
      this.$store.commit('setDialog', {
        title: '💷 Додати витрату',
        dialogItem: {},
        params: {
          companyId: this.marketing.id,
          rejectText: 'Відмінити',
          submitText: 'Додати',
          cb: res => {
            if (res) {
              this.advertisingCosts.push(res.data)
            }
          },
        },
        action: 'add-marketing-expenses',
      })
    },
    editExpenses(item) {
      this.$store.commit('setDialog', {
        title: '💷 Редагування виплати',
        dialogItem: item,
        params: {
          companyId: this.marketing.id,
          rejectText: 'Скасувати',
          submitText: 'Підтвердити',
        },
        action: 'edit-marketing-expenses',
      })
    },
    deleteExpenses(id) {
      return this.$axios
        .delete(urlDeleteExpensesMarketingCompany(id))
        .then(() => {
          this.$setSnackbar({ text: 'Виплата видалена' })
          this.$store.commit('refreshPage')
        })
    },
  },
  created() {
    this.advertisingCosts = this.marketing.advertisingCosts || []
  },
}
</script>
