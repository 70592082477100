<template>
  <div class="new-contractor-wrapper">
    <v-row>
      <v-col
        v-if="addContractorToApplication"
        cols="12"
        md="12"
        sm="12"
        class="pb-0">
        <span class="label">Контрагент</span>
        <v-autocomplete
          v-model="contractor"
          placeholder="Оберіть зі списку"
          :items="directoryItems('contractors', contractor)"
          :filter="contractorFilter"
          item-value="id"
          item-title="shortName"
          return-object
          hide-details
          @focus="asyncAction('addContractors')">
        </v-autocomplete>
      </v-col>
      <v-col
        v-if="
          !addMainContractor &&
          !deleteMainContractor &&
          !deleteContractorFromApplication &&
          !assignAgentContractor
        "
        cols="12"
        md="12"
        sm="12"
        class="pt-0">
        <span class="label">ЄДРПОУ</span>
        <v-text-field
          id="cp-edrpou"
          v-model="contractor.edrpou"
          placeholder="Введіть єдрпоу"
          :error-messages="edrpouErr"
          dense
          hide-details
          :readonly="addContractorToApplication || deleteAgentContractor"
          @keyup.enter="submit()"
          @input="maxNumLength('cp-edrpou', 8)"
          @blur="v$.contractor.edrpou.$touch()">
          <template #append>
            <v-btn
              v-show="edrpouLoading"
              :loading="edrpouLoading"
              icon
              size="x-small">
            </v-btn>
          </template>
        </v-text-field>
      </v-col>
      <v-col
        v-if="
          addMainContractor ||
          deleteMainContractor ||
          deleteContractorFromApplication ||
          deleteAgentContractor ||
          assignAgentContractor
        "
        cols="12"
        md="12"
        class="pt-0"
        sm="12">
        <span class="label">Контрагент</span>
        <v-autocomplete
          v-model="contractor.contractorId"
          :items="
            directoryItems('contractors', addMainContractor ? null : contractor)
          "
          :error-messages="contractorIdErr"
          item-value="id"
          item-title="shortName"
          hide-details
          :menu-props="{ maxWidth: 300 }"
          placeholder="Оберіть зі списку"
          :filter="contractorFilter"
          :disabled="
            deleteMainContractor ||
            deleteContractorFromApplication ||
            deleteAgentContractor
          "
          :readonly="
            deleteMainContractor ||
            deleteContractorFromApplication ||
            deleteAgentContractor
          "
          @focus="asyncAction('addContractors')"
          @blur="v$.contractor.contractorId.$touch()">
          <template #no-data><Loader /></template>
        </v-autocomplete>
      </v-col>
      <v-col
        v-if="
          !addContractorToApplication &&
          !addMainContractor &&
          !deleteMainContractor &&
          !deleteContractorFromApplication &&
          !assignAgentContractor
        "
        cols="12"
        md="12"
        class="pt-0"
        sm="12">
        <span class="label">Назва компанії</span>
        <v-text-field
          v-model="contractor.shortName"
          placeholder="Введіть текст"
          :error-messages="nameErr"
          dense
          hide-details
          :readonly="addContractorToApplication || deleteAgentContractor"
          @keyup.enter="submit()"
          @blur="v$.contractor.shortName.$touch()">
        </v-text-field>
      </v-col>
    </v-row>
    <v-row
      v-if="createMainContractor || addMainContractor || assignAgentContractor">
      <v-col cols="12" md="12" sm="12" class="pt-0">
        <span class="label">Посада</span>
        <v-select
          v-model="contractor.postId"
          :items="selectItems.careerPosts"
          :error-messages="contractorPostIdErr"
          placeholder="Оберіть зі списку"
          item-title="name"
          item-value="id"
          dense
          hide-details
          @keyup.enter="submit()"
          @blur="v$.contractor.postId.$touch()"
          @focus="$store.dispatch('addCareerPosts')"
          @change="
            contractor.fullPostName = selectItems.careerPosts.find(
              post => post.id === $event
            ).name
          ">
        </v-select>
      </v-col>
      <v-col cols="12" md="12" sm="12" class="pt-0">
        <span class="label">Повна назва посади</span>
        <v-text-field
          v-model="contractor.fullPostName"
          :error-messages="contractorFullPostNameErr"
          placeholder="Введіть повну назву посади"
          dense
          hide-details
          @keyup.enter="submit()"
          @blur="v$.contractor.fullPostName.$touch()">
        </v-text-field>
      </v-col>
      <v-col cols="12" md="12" sm="12" class="pt-0">
        <span class="label">Департамент</span>
        <v-select
          v-model="contractor.departmentId"
          :items="selectItems.careerDepartments"
          :error-messages="contractorDepartmentIdErr"
          placeholder="Оберіть зі списку"
          item-title="name"
          item-value="id"
          dense
          hide-details
          @keyup.enter="submit()"
          @blur="v$.contractor.departmentId.$touch()"
          @focus="$store.dispatch('addCareerDepartments')">
        </v-select>
      </v-col>
      <v-col cols="12" md="12" sm="12" class="pt-0">
        <span class="label">Роль</span>
        <v-select
          v-model="contractor.roleId"
          :items="directoryItems('careerRoles', contractor.role)"
          :error-messages="contractorRoleIdErr"
          placeholder="Оберіть зі списку"
          item-title="name"
          item-value="id"
          dense
          hide-details
          @focus="asyncAction('addCareerRoles')"
          @keyup.enter="submit()"
          @blur="v$.contractor.roleId.$touch()">
        </v-select>
      </v-col>
    </v-row>
    <div
      v-if="
        deleteContractor ||
        deleteContractorFromApplication ||
        deleteMainContractor ||
        deleteAgentContractor
      ">
      <span style="font-size: 1.1rem; color: black">
        {{ `буде видалено` }}
      </span>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { required, minLength } from '@vuelidate/validators'
import { maxNumLength } from '@/utils/masks'
import {
  urlCreateContractor,
  urlUpdateApplication,
  urlCreateContactContractor,
  urlDeleteContactContractor,
} from '@/pages/request'
import ObjectMapper from 'object-mapper'
import { contractorMap } from '@/pages/objects-map'
import { setErrHandler, contractorFilter } from '@/utils/helperFunc'
import { useVuelidate } from '@vuelidate/core'
import { useSelect } from '@/utils/mixins/useSelect'
import Loader from '@/components/Loader.vue'

export default {
  components: { Loader },
  setup() {
    return { v$: useVuelidate(), ...useSelect() }
  },
  props: { dialog: { type: Object } },
  validations() {
    const mainContractor =
      this.createMainContractor ||
      this.addMainContractor ||
      this.assignAgentContractor
        ? {
            postId: { required },
            fullPostName: { required },
            departmentId: { required },
            roleId: { required },
          }
        : {}
    const contractorId =
      this.addMainContractor ||
      this.addContractorToApplication ||
      this.assignAgentContractor
        ? { contractorId: { required } }
        : {}
    const edrpou =
      !this.addMainContractor && !this.assignAgentContractor
        ? { edrpou: { required, minLength: minLength(8) } }
        : {}
    const shortName =
      !this.addMainContractor && !this.assignAgentContractor
        ? { shortName: { required } }
        : {}

    return {
      contractor: {
        ...edrpou,
        ...shortName,
        ...mainContractor,
        ...contractorId,
      },
    }
  },
  data: () => ({
    contractor: {
      contractorId: null,
      edrpou: null,
      type: null,
      shortName: null,
      roleId: null,
      fullPostName: null,
      postId: null,
      addresses: [],
      companyFullName: null,
      queds: [],
    },
    edrpouLoading: false,
    synWidhDataBot: false,
  }),
  computed: {
    ...mapState({
      selectItems: state => state.selectItems,
    }),
    validation() {
      return { v$: this.v$.contractor, name: 'contractorCrud' }
    },
    nameErr() {
      return setErrHandler(this.v$.contractor?.shortName)
    },
    contractorRoleIdErr() {
      return setErrHandler(this.v$.contractor?.roleId)
    },
    contractorFullPostNameErr() {
      return setErrHandler(this.v$.contractor?.fullPostName)
    },
    contractorDepartmentIdErr() {
      return setErrHandler(this.v$.contractor?.departmentId)
    },
    contractorPostIdErr() {
      return setErrHandler(this.v$.contractor?.postId)
    },
    contractorIdErr() {
      return setErrHandler(this.v$.contractor?.contractorId)
    },
    edrpouErr() {
      const errors = []
      if (!this.v$.contractor.edrpou.$error) return []
      !this.v$.contractor.edrpou.required &&
        errors.push('Обов`язково для заповнення')
      !this.v$.contractor.edrpou.minLength && errors.push('8 чисел')
      return errors
    },
    mapedContractor() {
      return contractorMap
    },
    newContractor() {
      return this.dialog.action === 'newContractor'
    },
    deleteContractor() {
      return this.dialog.action === 'deleteContractor'
    },
    addMainContractor() {
      return this.dialog.action === 'addMainContractor'
    },
    addContractorToApplication() {
      return this.dialog.action === 'addContractorToApplication'
    },
    createContractorToApplication() {
      return this.dialog.action === 'createContractorToApplication'
    },
    deleteContractorFromApplication() {
      return this.dialog.action === 'deleteContractorFromApplication'
    },
    createMainContractor() {
      return this.dialog.action === 'createMainContractor'
    },
    deleteMainContractor() {
      return this.dialog.action === 'deleteMainContractor'
    },
    deleteAgentContractor() {
      return this.dialog.action === 'deleteAgentContractor'
    },
    assignAgentContractor() {
      return this.dialog.action === 'assignAgentContractor'
    },
  },
  methods: {
    maxNumLength,
    contractorFilter,
    urlCreateContractor,
    ObjectMapper,
    urlUpdateApplication,
    urlCreateContactContractor,
    urlDeleteContactContractor,
    submit() {
      switch (true) {
        case this.newContractor:
          return this.createContractor()
        case this.deleteContractor:
          return this.removeContractor()
        case this.addMainContractor:
          return this.createMContractor()
        case this.createContractorToApplication:
          return this.createApplicationContractor()
        case this.addContractorToApplication:
          return this.bindApplicationContractor()
        case this.deleteContractorFromApplication:
          return this.deleteApplicationContractor()
        case this.createMainContractor:
          return this.createMContractor()
        case this.deleteMainContractor:
          return this.deleteMContractor()
        case this.assignAgentContractor:
          return this.assignContractorToAgent()
        default:
          return Promise.reject('No handler')
      }
    },
    setContractorId(contractor) {
      contractor.contractorId = contractor?.id

      console.log(' contractor', contractor)
    },
    bindApplicationContractor() {
      this.dialog.params.saveContractorId(this.contractor)
      return this.$store
        .dispatch('addApplicationContacts', this.contractor.id)
        .then(() => this.$store.commit('closeDialog'))
    },
    deleteApplicationContractor() {
      this.dialog.params.saveContractorId()
      return Promise.resolve()
    },
    createContractor() {
      return this.postContractor()
    },
    removeContractor() {
      return Promise.resolve(
        this.$setSnackbar({ text: 'No handler', color: 'warning' })
      )
    },
    postContractor() {
      return this.$axios
        .post(this.urlCreateContractor(), this.contractor)
        .then(res => {
          if (res?.data?.message) {
            return Promise.reject(res.data.message)
          }
          this.$setSnackbar({ text: 'Створено контрагента' })
          return res.data
        })
        .catch(err => {
          if (err?.response?.data?.errors?.edrpou[0]) {
            return this.$setSnackbar({
              text: err.response.data.errors.edrpou[0],
              color: 'error',
            })
          }
          this.$err(err)
        })
    },
    createApplicationContractor() {
      return this.$axios
        .post(urlCreateContractor(), this.contractor)
        .then(res => {
          this.$emit('contractorAdded', res.data.id)
          this.$setSnackbar({ text: 'Додано контрагента' })
          return res
        })
        .catch(err =>
          this.$err(err, () => {
            this.$setSnackbar({
              text: err.response.data?.errors?.edrpou[0],
              color: 'error',
            })
          })
        )
    },
    assignContractorToAgent() {
      return Promise.reject('No handler')
    },
    createMContractor() {
      return this.$axios
        .post(
          this.urlCreateContactContractor(this.$route.params.id),
          this.contractor
        )
        .then(() =>
          this.$setSnackbar({ text: 'Додано нового контрагента до контакту' })
        )
        .catch(err =>
          this.$err(err, () => {
            this.$setSnackbar({
              text: err.response.data?.errors?.edrpou[0],
              color: 'error',
            })
          })
        )
    },
    deleteMContractor() {
      return this.$axios
        .get(this.urlDeleteContactContractor(this.$route.params.id))
        .then(() => this.$setSnackbar({ text: 'Контрагента вiдкрiплено' }))
        .catch(this.$err)
    },
  },
  watch: {
    contractor: function (val) {
      this.setContractorId(val)
    },
  },
  created() {
    Object.assign(this.contractor, this.dialog?.dialogItem || {})
  },
}
</script>
