<template>
  <div>
    <div v-if="$route.name === 'admin-lead-calls'">
      <Filters :filters="filters" class="ml-2" />
      <SectionLoader v-if="loading" />
      <div>
        <v-data-table
          v-show="!loading"
          :headers="adminLeadCallHeaders"
          :items="adminLeadCalls"
          :custom-sort="$sortByDate"
          class="calc-table --cust-cell-pd"
          hide-default-footer
          :hide-default-header="!adminLeadCalls.length"
          @dblclick:row="navigateToAdminLeadCall">
          <!-- eslint-disable-next-line -->
          <template #item.phone="{ item }">
            {{ item.detail_call?.phone }}
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.user="{ item }">
            {{ getContactInitials(item.detail_call?.user) }}
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.type_call="{ item }">
            {{ item.detail_call?.type_call?.name }}
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.date="{ item }">
            {{ toFormatDate(item.created_at) }}
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.action="{ item }">
            <TableCrud
              :actionsArray="[
                {
                  action: () =>
                    $router.push({
                      name: 'admin-lead-call-single',
                      params: { id: item.id },
                    }),
                  text: 'Переглянути',
                  icon: 'EyeIcon',
                  customIcon: true,
                },
              ]">
            </TableCrud>
          </template>
        </v-data-table>
        <Pagination
          class="mt-3"
          :getItems="getAdminLeadCalls"
          :trigger="paginationTrigger" />
      </div>
    </div>

    <div v-else>
      <RouterView></RouterView>
    </div>
  </div>
</template>

<script lang="ts">
import Filters from '@/components/Filters.vue'
import SectionLoader from '@/components/section-loader.vue'
import TableCrud from '@/components/table-crud.vue'
import { reactive, ref } from 'vue'
import { getContactInitials, toFormatDate } from '@/utils/helperFunc'
import { adminLeadCallHeaders } from '@/utils/tableHeaders'
import { urlGetAdminLeadCalls } from '@/pages/request'
import Pagination from '@/components/Pagination.vue'
import { useFilter } from '@/utils/mixins/useFilter'
import router from '@/router/router'

export default {
  components: { Pagination, TableCrud, SectionLoader, Filters },
  data() {
    return {
      adminLeadCallHeaders,
    }
  },
  setup() {
    const adminLeadCalls = ref([])
    const loading = ref(false)
    const filters = reactive({
      startTime: {
        value: null,
        component: 'DatePicker',
        attrs: {
          label: 'Період',
          range: true,
          keys: { startDate: 'startTime', endDate: 'endTime' },
        },
      },
      userId: {
        value: null,
        component: 'v-autocomplete',
        attrs: {
          label: 'Відповідальний',
          itemTitle: u => getContactInitials(u, { isResponsible: true }),
          items: 'allUsers',
        },
      },
    })

    const navigateToAdminLeadCall = (e, row) => {
      router.push({
        name: 'admin-lead-call-single',
        params: { id: row.item.id },
      })
    }

    return {
      loading,
      ...useFilter(filters),
      filters,
      adminLeadCalls,
      navigateToAdminLeadCall,
    }
  },
  methods: {
    getContactInitials,
    toFormatDate,
    getAdminLeadCalls(page = 1) {
      this.loading = true
      return this.$axios
        .get(urlGetAdminLeadCalls(page, this.filtersQueryObj))
        .then(res => {
          this.loading = false
          this.adminLeadCalls = res.data.data
        })
    },
  },
}
</script>

<style scoped></style>
