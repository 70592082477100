import { urlApplicationById } from '@/pages/request.js'
import { getItems } from '../helper.js'

const application = {
  state: () => ({
    calculations: [],
    clientId: null,
    clientTypeId: null,
    client_need: null,
    comment: null,
    communication_method: {},
    companyName: null,
    contact: null,
    contactId: null,
    contractor: null,
    contractorId: null,
    created_at: null,
    edrpou: null,
    emails: null,
    id: null,
    inn: null,
    clientNeedId: null,
    communicationMethodId: null,
    applicationName: null,
    name: null,
    offer_sends: null,
    surname: null,
    phones: [],
    responsible: {},
    responsibleId: null,
    patronymic: null,
    source: null,
    sourceId: null,
    sourceLinkAgentId: null,
    sourceLinkId: null,
    sourceTypeId: null,
    source_link: null,
    source_link_agent: {},
    source_type: {},
    statusId: null,
    updated_at: null,
    isMissingData: null,
  }),
  mutations: {
    setApplication(state, application) {
      return Object.assign(state, application)
    },
    setSourceLinkAgentId(state, id) {
      state.sourceLinkAgentId = id
    },
  },
  actions: {
    /* eslint-disable no-unused-vars */
    async getApplicationById({ commit }, id) {
      const res = await getItems(urlApplicationById(id))
      return res
    },

    /* eslint-enable no-unused-vars */
  },
}

export { application }
