
const contractor = {
  state: () => ({
    addresses: []
  }),
  mutations: {
    addContractorAddresses(state, addresses) {
      state.addresses.splice(0)
      state.addresses.push(...(addresses || []))
    },
    contractorAddAddress(state, address) {
      state.addresses.push(address)
    },
    contractorDeleteAddress(state, id) {
      state.addresses = state.addresses.filter(a => a.id !== id)
    },
    contractorEditAddress(state, address) {
      state.addresses = state.addresses.map(a => a.id === address.id ? address : a)
    }
  }, 
  /* eslint-enable no-unused-vars */
  actions: {
    //...
  },
  getters: {
    //...
  }
}

export { contractor }