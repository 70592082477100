<template>
  <div>
    <SectionLoader v-if="loading"></SectionLoader>
    <v-fade-transition hide-on-leave>
      <div v-if="!loading" style="background: #fff">
        <div>
          <div style="position: relative">
            <v-card-text class="pb-1">
              <v-row>
                <v-col cols="12" md="10" sm="12">
                  <Fields :fields="fields" :type="2" direction="row">
                    <template #number_field>
                      <EditableField
                        v-model="agreement.number"
                        label="№ Договору"
                        style="margin-top: -5px"
                        readonly
                        @blur="v$.agreement.number.$touch()" />
                    </template>
                  </Fields>
                </v-col>
                <v-col cols="12" md="2" sm="12">
                  <div class="align-center d-flex gap-3 justify-end">
                    <v-checkbox
                      v-if="agreement.statusId !== 5"
                      :model-value="agreement.isCancelContract"
                      color="#fc7247"
                      hide-details
                      dense
                      :readonly="
                        (!admin && readonly) || agreement.isCancelContract
                      "
                      @update:model-value="setCancel">
                      <template #append>
                        <div class="label ml-6">
                          {{
                            agreement.isCancelContract
                              ? 'Анульовано'
                              : 'Анулювати'
                          }}
                        </div>
                      </template>
                    </v-checkbox>
                    <TableCrud
                      style="z-index: 40000000"
                      class="ml-2"
                      :actionsArray="[
                        {
                          action: getGuaranteePrintedForm,
                          text: 'Договiр поруки',
                          icon: 'mdi-file',
                        },
                      ]"
                      customIcon="Printer">
                    </TableCrud>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </div>
          <div>
            <StatusBar
              class="mb-2"
              :currentStatus="agreement.statusId"
              :submit="setGCStatus"
              :statuses="filteredStatuses"
              spreadItems
              size="small">
            </StatusBar>
          </div>
        </div>
        <div class="mt-3" style="background: #fff">
          <v-tabs
            v-model="currentTab"
            class="dkpTabs"
            show-arrows
            color="white"
            dark
            dense
            slider-color="#fc7247"
            :height="38">
            <v-tab
              replace
              :to="{
                name: 'surety-parties-details',
                params: { id: $route.params.id },
              }">
              Реквізити сторін
            </v-tab>
            <v-tab
              replace
              :to="{
                name: 'surety-subject',
                params: { id: $route.params.id },
              }">
              Предмет поруки
            </v-tab>
            <v-tab
              replace
              :to="{
                name: 'surety-documents',
                params: { id: $route.params.id },
              }">
              Документи
            </v-tab>
            <v-tab
              replace
              :to="{
                name: 'surety-history',
                params: { id: $route.params.id },
              }">
              Історія
            </v-tab>
          </v-tabs>
          <div style="position: relative">
            <router-view :agreement="agreement" :readonly="readonly" :v="v$">
            </router-view>
            <ActionButtons
              :confirm="submit"
              :cancel="getSuretyAgreement"
              :confirmLoading="submitLoading"
              :cancelDisable="submitLoading || agreementState === cache"
              :confirmDisable="agreementState === cache">
            </ActionButtons>
          </div>
        </div>
      </div>
    </v-fade-transition>
  </div>
</template>
<script>
import StatusBar from '@/components/StatusBar.vue'
import SectionLoader from '@/components/section-loader.vue'
import ActionButtons from '@/components/action-buttons.vue'
import generateSuretyAgreement from './pdf-forms/surety-agreement'
import {
  LODescription as getLoDescription,
  setCache,
  setErrHandler,
  getEntityName,
  v$Notify,
  toFormatDate,
  backDate,
} from '@/utils/helperFunc'
import {
  urlUpdateSuretyAgreement,
  urlGetSuretyAgreement,
  urlGetGuaranteeContractPrintForm,
  urlSetCancelContractGuarantee,
  urlSetGCStatus,
  urlGetEntityPrintedForm,
} from '@/pages/request'
import { mapState } from 'vuex'
import { required, minLength } from '@vuelidate/validators'

import EditableField from '@/components/EditableField.vue'
import Fields from '@/components/Fields.vue'
import { openDocument } from '@/utils/helperFunc'
import { useVuelidate } from '@vuelidate/core'
import TableCrud from '@/components/table-crud.vue'
import { confirm } from 'best-modules/plugins'

export default {
  components: {
    TableCrud,
    Fields,
    EditableField,
    SectionLoader,
    ActionButtons,
    StatusBar,
  },
  setup() {
    return { v$: useVuelidate() }
  },
  validations() {
    return {
      agreement: {
        number: { required },
        date: { required, minLength: minLength(10) },
        typeId: { required },
        creditorId: { required },
        creditorSignatoryId: { required },
        creditorBasisId: { required },
        guarantorId: { required },
        guarantorSignatories: {
          required,
          minLength: minLength(1),
          $each: {
            contactId: { required },
            basisId: { required },
          },
        },
      },
    }
  },
  data: () => ({
    agreement: {},
    submitLoading: false,
    agreedCalculations: [],
    currentTab: 0,
    loading: false,
    cache: null,
    creditorEmployees: [],
    guarantorEmployees: [],
    printedForm: {},
  }),
  computed: {
    ...mapState({
      user: state => state.user,
      selectItems: state => state.selectItems,
    }),
    fields() {
      return [
        {
          label: 'Дата договору',
          value: toFormatDate(this.agreement.date, true),
        },
        { label: '№ Договору', slot: 'number' },
      ]
    },
    agreementId() {
      return this.$route?.params?.id
    },
    debtor() {
      if (this.agreement.debtor) {
        const debtor =
          this.agreement?.debtor?.contact || this.agreement?.debtor?.contractor
        return debtor?.shortName || debtor?.fullName
      }
      return '---'
    },
    admin() {
      return this.user.roleId === 1
    },
    agreementState() {
      return this.setCache([this.agreement])
    },
    typeIdErr() {
      return this.setErrHandler(this.v$?.agreement?.typeId)
    },
    route() {
      return this.$route.name
    },
    readonly() {
      return this.agreement.readOnly
    },
    filteredStatuses() {
      const filteredStatuses = this.selectItems.guaranteeStatuses.filter(item =>
        this.agreement.isCancelContract ? true : item.id !== 6
      )
      return filteredStatuses.map(s => {
        let showCheckIcon
        if (this.agreement.statusId === 6) {
          showCheckIcon = s.id === 6
        }

        return { ...s, showCheckIcon }
      })
    },
  },
  methods: {
    setCache,
    getEntityName,
    setErrHandler,

    getLoDescription,
    urlUpdateSuretyAgreement,
    urlGetSuretyAgreement,
    urlGetEntityPrintedForm,
    urlSetCancelContractGuarantee,
    urlSetGCStatus,
    backDate,
    generateSuretyAgreement,
    async setGCStatus(statusId) {
      this.loading = true

      const currentStatus = this.agreement.statusId

      if (currentStatus === 5) {
        return this.$setSnackbar({
          text: 'Договiр з статусу пiдписаного не може бути змiнено',
          color: 'warning',
        })
      }
      if (
        [4, 5].includes(statusId) &&
        !this.agreement.agreements?.some(a => !a.isAgreed)
      ) {
        return this.$setSnackbar({
          text: 'Договiр повинен бути завiзований всiма учасниками',
          color: 'warning',
        })
      }
      if (statusId < currentStatus || statusId > currentStatus + 1) {
        return this.$setSnackbar({ text: 'Дія неможлива', color: 'error' })
      }

      if ([2, 3].includes(statusId)) {
        try {
          const statusNames = {
            2: 'Підписано Поручителем',
            3: 'Візування',
          }
          await confirm({
            text: `Ви впевнені що хочете перевести договір в статус "${statusNames[statusId]}"?`,
          })
        } catch (e) {
          return
        }
      }

      return this.$axios
        .post(this.urlSetGCStatus(this.agreement.id), { statusId: statusId })
        .then(res => {
          if (res.data.message) {
            this.$setSnackbar({ text: res.data.message, color: 'warning' })
          } else this.$setSnackbar({ text: 'Статус успiшно змiнено' })
          this.getSuretyAgreement()
          return res
        })
        .catch(err => this.$err(err, () => (this.loading = false)))
    },

    getGuaranteePrintedForm() {
      return this.$axios
        .get(urlGetGuaranteeContractPrintForm(this.agreement.id))
        .then(res => {
          if (res.data.message) {
            return this.$setSnackbar({
              text: res.data.message,
              color: 'warning',
            })
          }
          openDocument({ url: res.data.url, text: 'Договір поруки' })
        })
        .catch(this.$err)
    },
    setBreadScrumb() {
      const name =
        this.getEntityName(this.agreement.guarantor) +
        ' ' +
        this.agreement.number
      this.$store.commit('setBreadScrumbPart', [null, null, name, null])
    },
    getSuretyAgreement() {
      this.loading = true
      return this.$axios
        .get(this.urlGetSuretyAgreement(this.agreementId || this.agreement.id))
        .then(res => {
          if (res?.data?.message) {
            const err = res?.data?.message
            return this.$setSnackbar({ text: err, color: 'error' })
          }
          if (!res.data.guarantorSignatories)
            res.data.guarantorSignatories = [{ contactId: null, basisId: null }]
          if (!res.data.debtorSignatories)
            res.data.debtorSignatories = [{ contactId: null, basisId: null }]
          Object.assign(this.agreement, res.data)
          this.cache = this.setCache([this.agreement])
          this.loading = false
          this.v$.$reset()
          return res
        })
        .catch(err => this.$err(err, () => (this.loading = false)))
    },
    setCancel() {
      confirm({
        text: 'Ви дійсно бажаєте анулювати договір?',
      }).then(() => {
        return this.$axios
          .post(urlSetCancelContractGuarantee(this.agreement.id), {
            isCancelContract: true,
          })
          .then(res => {
            this.$setSnackbar({ text: 'Оновлено' })
            Object.assign(this.agreement, res.data)
            this.setCache([this.agreement])
            this.setStoreCache()

            return res
          })
      })
    },
    submit() {
      if (this.v$.$invalid) {
        this.v$.$anyError
        this.v$.$touch()
        v$Notify(this.v$.agreement, 'suretyAgreement')
        return Promise.resolve()
      }
      this.submitLoading = true
      const requestObj = Object.clone(this.agreement)
      requestObj.date = backDate(this.agreement.date)

      if (requestObj.guarantorSignatories)
        requestObj.guarantorSignatories = requestObj.guarantorSignatories.map(
          s => ({
            contactId: s.contactId,
            basisId: s.basisId,
          })
        )
      if (requestObj.debtorSignatories)
        requestObj.debtorSignatories = requestObj.debtorSignatories.map(s => ({
          contactId: s.contactId,
          basisId: s.basisId,
        }))

      !requestObj.tripartiteAgreement

      if (!requestObj.tripartiteAgreement) {
        requestObj.debtorSignatories = null
      }

      return this.$axios
        .post(this.urlUpdateSuretyAgreement(this.agreementId), requestObj)
        .then(res => {
          if (res?.data?.message) throw new Error(res.data.message)
          res.data.date = toFormatDate(res.data.date, true)
          Object.assign(this.agreement, res.data)
          this.cache = this.setCache([this.agreement])
          this.submitLoading = false
          this.$setSnackbar({ text: 'Данi оновлено' })
          return res
        })
        .catch(err => this.$err(err, () => (this.submitLoading = false)))
    },
  },
  watch: {
    '$route.name': function () {
      this.setBreadScrumb()
    },
  },
  created() {
    this.$store.dispatch('addGuaranteeStatuses')
    this.$store.dispatch('addSuretyAgreementTypes')
    this.getSuretyAgreement().then(res => {
      this.setBreadScrumb()
      return res
    })
  },
}
</script>
