<template>
  <div>
    <v-row>
      <v-col cols="12" md="2" sm="12" class="pr-0">
        <v-tabs
          v-model="currentTab"
          color="white"
          slider-color="#fc7247"
          background
          direction="vertical"
          dense>
          <v-tab replace :to="{ name: 'admin-faq-documents' }"
            >Документація</v-tab
          >
          <v-tab replace :to="{ name: 'admin-faq-videos' }"
            >Відеоінструкції</v-tab
          >
          <v-tab replace :to="{ name: 'admin-faq-others' }">Інше</v-tab>
        </v-tabs>
      </v-col>
      <v-col cols="12" md="10" sm="12">
        <RouterView></RouterView>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  data: () => ({ currentTab: 0 }),
}
</script>

<style scoped>
.v-tabs-bar.v-item-group > * {
  background: #fff !important;
}
.v-tabs--vertical > .v-tabs-bar .v-tab {
  height: 35px !important;
}
a.v-tab {
  font-size: 12px !important;
}
a.v-tab-item--selected.v-tab {
  font-size: 12px !important;
}
</style>
