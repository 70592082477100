<template>
  <v-card-text>
    <div v-if="supportList">
      <SectionLoader v-if="loading"></SectionLoader>
      <div v-if="!loading">
        <v-row>
          <v-col :cols="12" :md="8" class="pt-0 pb-0">
            <v-btn
              class="ml-1"
              variant="flat"
              color="rgb(210, 74, 67)"
              size="small"
              @click="
                $store.commit('setDialog', {
                  title: 'Тiкет',
                  dialogItem: {},
                  params: {
                    cb: () => {
                      getUserTickets()
                      $setSnackbar({ text: 'Тiкет створено' })
                    },
                  },
                  action: 'createTicket',
                })
              ">
              Додати тiкет
            </v-btn>
          </v-col>
          <v-col :cols="12" :md="4" class="pt-0 pb-0 d-flex">
            <v-text-field
              v-if="tickets.length"
              v-model="string"
              style="display: flex; flex: 1 1 auto"
              class="pt-0 mt-0"
              prepend-inner-icon="mdi-magnify"
              label="Пошук"
              hide-details>
            </v-text-field>
          </v-col>
        </v-row>
        <v-card-text>
          <v-data-table
            :headers="supportListHeaders"
            :items="tickets"
            class="calc-table --cust-cell-pd"
            mobileBreakpoint="750"
            :search="string"
            dense
            :hide-default-footer="!tickets.length"
            :hide-default-header="!tickets.length">
            <!-- eslint-disable-next-line -->
            <template #item.title="{ item }">
              <ALink
                :text="item.title"
                :route="{ name: 'support-ticket', params: { id: item.id } }">
              </ALink>
            </template>
            <!-- eslint-disable-next-line -->
            <template #item.actions="{ item }">
              <TableCrud
                :actionsArray="[
                  {
                    action: () =>
                      $router.push({
                        name: 'support-ticket',
                        params: { id: item.id },
                      }),
                    text: 'Вiдкрити',
                    icon: 'mdi-folder-open-outline',
                  },
                ]">
              </TableCrud>
            </template>
          </v-data-table>
        </v-card-text>
      </div>
    </div>
    <RouterView v-show="!supportList" @data-changed="getUserTickets">
    </RouterView>
  </v-card-text>
</template>
<script>
import SectionLoader from '@/components/section-loader.vue'

import ALink from '@/components/Link.vue'
import { urlGetUserTickets } from '@/pages/request'
import TableCrud from '@/components/table-crud.vue'
import { supportListHeaders } from '@/utils/tableHeaders'
export default {
  components: { TableCrud, SectionLoader, ALink },
  data() {
    return {
      phones: null,
      loading: false,
      tickets: [],
      supportListHeaders,
      string: '',
    }
  },
  computed: {
    supportList() {
      return this.$route.name === 'support'
    },
  },
  methods: {
    getUserTickets() {
      this.loading = true
      return this.$axios
        .get(urlGetUserTickets())
        .then(res => {
          this.tickets.splice(0)
          this.tickets.push(...res.data)
          this.loading = false
        })
        .catch(err => this.$err(err, () => (this.loading = false)))
    },
  },
  created() {
    this.getUserTickets()
  },
}
</script>
