<template>
  <div>
    <div class="d-flex justify-space-between">
      <div class="mb-3 d-flex align-center">
        <svg
          class="mr-1"
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="16"
          viewBox="0 0 14 16"
          fill="none">
          <path
            d="M8 5V0.5H3.5C3.10218 0.5 2.72064 0.658035 2.43934 0.93934C2.15804 1.22064 2 1.60218 2 2V9.125H6.875C7.27282 9.125 7.65436 9.28304 7.93566 9.56434C8.21696 9.84564 8.375 10.2272 8.375 10.625C8.375 11.0228 8.21696 11.4044 7.93566 11.6857C7.65436 11.967 7.27282 12.125 6.875 12.125H2V14C2 14.3978 2.15804 14.7794 2.43934 15.0607C2.72064 15.342 3.10218 15.5 3.5 15.5H12.5C12.8978 15.5 13.2794 15.342 13.5607 15.0607C13.842 14.7794 14 14.3978 14 14V6.5H9.5C9.10218 6.5 8.72064 6.34196 8.43934 6.06066C8.15804 5.77936 8 5.39782 8 5ZM4.625 7.8125C4.625 7.66332 4.68426 7.52024 4.78975 7.41475C4.89524 7.30926 5.03832 7.25 5.1875 7.25H10.8125C10.9617 7.25 11.1048 7.30926 11.2102 7.41475C11.3157 7.52024 11.375 7.66332 11.375 7.8125C11.375 7.96168 11.3157 8.10476 11.2102 8.21025C11.1048 8.31574 10.9617 8.375 10.8125 8.375H5.1875C5.03832 8.375 4.89524 8.31574 4.78975 8.21025C4.68426 8.10476 4.625 7.96168 4.625 7.8125ZM4.625 13.4375C4.625 13.2883 4.68426 13.1452 4.78975 13.0398C4.89524 12.9343 5.03832 12.875 5.1875 12.875H10.8125C10.9617 12.875 11.1048 12.9343 11.2102 13.0398C11.3157 13.1452 11.375 13.2883 11.375 13.4375C11.375 13.5867 11.3157 13.7298 11.2102 13.8352C11.1048 13.9407 10.9617 14 10.8125 14H5.1875C5.03832 14 4.89524 13.9407 4.78975 13.8352C4.68426 13.7298 4.625 13.5867 4.625 13.4375ZM9.125 5V0.875L13.625 5.375H9.5C9.40054 5.375 9.30516 5.33549 9.23483 5.26517C9.16451 5.19484 9.125 5.09946 9.125 5ZM1.0625 10.0625C0.913316 10.0625 0.770242 10.1218 0.664752 10.2273C0.559263 10.3327 0.5 10.4758 0.5 10.625C0.5 10.7742 0.559263 10.9173 0.664752 11.0227C0.770242 11.1282 0.913316 11.1875 1.0625 11.1875H6.6875C6.83668 11.1875 6.97976 11.1282 7.08525 11.0227C7.19074 10.9173 7.25 10.7742 7.25 10.625C7.25 10.4758 7.19074 10.3327 7.08525 10.2273C6.97976 10.1218 6.83668 10.0625 6.6875 10.0625H1.0625Z"
            fill="#08487A" />
        </svg>
        <span class="card-block-title">Схема реалізації</span>
      </div>
    </div>
    <div class="data-card">
      <span class="area-label">Вiдхилення</span>
      <p class="title-label">
        {{ slider.deviationsProjectMessage || 'Відсутні' }}
      </p>
    </div>
    <div v-if="slider.overlappedDeviationsMessage" class="data-card">
      <span class="area-label">Чим відхилення перекриті</span>
      <p class="title-label">{{ slider.overlappedDeviationsMessage }}</p>
    </div>
    <span>
      <FilesUploader
        v-model:documents="project.projectScheme"
        style="padding-left: 0"
        :readonly="readonly"
        label="Схема реалiзацii"
        :disableDeleteAction="
          getSafe(() => $store.state.user.roleId !== 1, false)
        ">
      </FilesUploader>
    </span>
    <div class="mb-3 mt-3 d-flex align-center">
      <PlanTimeIcon class="mr-1" />
      <span class="card-block-title">Плановi дати</span>
    </div>
    <v-row>
      <v-col :cols="12" :md="4" class="pt-0 pb-0">
        <div class="data-card">
          <span class="area-label">Дата підписання договору</span>
          <p class="title-label">{{ slider.dateSigningContract }}</p>
        </div>
      </v-col>
      <v-col :cols="12" :md="4" class="pt-0 pb-0">
        <div class="data-card">
          <span class="area-label">Дата сплати авансу</span>
          <p class="title-label">{{ slider.advanceDate }}</p>
        </div>
      </v-col>
      <v-col :cols="12" :md="4" class="pt-0 pb-0">
        <div class="data-card">
          <span class="area-label">Дата відвантаження</span>
          <p class="title-label">{{ slider.dateOfLeasing }}</p>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import PlanTimeIcon from '@/assets/svg/planTime-icon.vue'
import FilesUploader from '@/components/FilesUploader.vue'
import { mapState } from 'vuex'
import { getSafe } from '@/utils/helperFunc'
export default {
  components: {
    FilesUploader,
    PlanTimeIcon,
  },
  props: {
    readonly: { type: Boolean },
  },
  methods: { getSafe },
  computed: {
    ...mapState({
      selectItems: state => state.selectItems,
      project: state => state.projects.project,
      slider: state => state.projects.slider,
      $v: state => state.projects.$project,
    }),
  },
}
</script>

<style scoped>
.card-block-title {
  display: block;
  color: #08487a;
  font-weight: 600;
  font-size: 16px;
  margin-left: 9px;
}

.title-label {
  font-weight: 600;
  font-size: 15px;
  color: #1c1d21;
  margin-top: 5px;
}
.data-card {
  display: flex;
  flex-direction: column;
}
.card-block-title {
  display: block;
  color: #08487a;
  font-weight: 600;
  font-size: 16px;
  margin-left: 9px;
}

.title-label {
  font-weight: 400 !important;
}
</style>
