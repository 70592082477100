<template>
  <div id="new-application">
    <div v-if="calculationsRoutes">
      <RouterView :applicationData="application" />
    </div>
    <div v-else>
      <v-dialog v-model="deleteDialog" :max-width="420" persistent>
        <action-card
          title="Розрахунок"
          :closeDialog="() => (deleteDialog = !deleteDialog)"
          :submit="() => deleteCalculation()"
          :reject="() => (deleteDialog = !deleteDialog)">
          <template #body>
            <strong class="ma-3">К буде видалено!</strong>
          </template>
        </action-card>
      </v-dialog>
      <v-fade-transition hide-on-leave>
        <v-row v-if="!pageLoad && !responseMessage" class="new-application">
          <v-col :cols="12" class="pt-0 pb-0">
            <v-row v-if="application.id || createApplicationMode" class="mt-2">
              <v-col
                v-if="!calculationsRoutes"
                :cols="12"
                :sm="3"
                class="pt-0 pb-0 pr-0">
                <application-common-card
                  v-model:application="application"
                  v-model:mainContactId="mainContactId"
                  v-model:mainContractorId="mainContractorId"
                  :v="v$"
                  :createApplicationMode="createApplicationMode"
                  @update-source-types="updateSourceTypes($event)"
                  @update-source-links="updateSourceLinks($event)">
                </application-common-card>
                <main-contractor-card
                  v-if="
                    !application.lessee.contactId &&
                    sourceReTreatment &&
                    !mainContactId
                  "
                  v-model:mainContractorId="mainContractorId"
                  :showAddBtn="false"
                  :addContractorBtn="addContractorToApplication"
                  :deleteContractorBtn="deleteContractorFromApplication"
                  :mainContractor="application.lessee.contractor"
                  :createApplicationMode="createApplicationMode"
                  :elevation="0"
                  :disabled="!!application.readOnly"
                  @open-dialog="() => openDialog(...$event, {}, 'contractor')">
                </main-contractor-card>
                <main-contact-card
                  v-if="
                    !application.lessee.contractorId &&
                    sourceReTreatment &&
                    !mainContractorId
                  "
                  ref="mainContactCard"
                  :chooseDialog="{
                    title: 'Контакт',
                    dialogItem: {
                      contactId: null,
                      fullPostName: null,
                      departmentId: null,
                      mainPhone: null,
                      mainEmail: null,
                    },
                    params: {
                      cb: data => {
                        $refs.mainContactCard.addMainContact(data)
                      },
                    },
                    action: 'addApplicationContact',
                  }"
                  :mainContact="application.lessee.contact || {}"
                  :mainContactId="mainContactId"
                  :showAddBtn="false"
                  :elevation="0"
                  :disabled="!!application.readOnly"
                  @update:set-client-type-id="setClientTypeId"
                  @change="saveContactId($event)"
                  @main-contact-id="assignContactId">
                </main-contact-card>
              </v-col>
              <v-col
                :cols="12"
                :sm="calculationsRoutes ? 12 : 9"
                class="pb-0"
                :class="{ '': !calculationsRoutes }">
                <div class="status-wrapper" style="margin-left: 10px">
                  <div
                    v-if="application.statusId && !calculationsRoutes"
                    class="application-header">
                    <v-row class="align-right">
                      <v-col
                        :cols="12"
                        class="act-btn-wrapper d-flex justify-end">
                        <v-row>
                          <v-col :cols="12" :sm="6">
                            <v-btn
                              v-if="application.statusId === 5"
                              class="ma-3"
                              style="
                                margin-bottom: 0 !important;
                                margin-right: 0 !important;
                                background: #ffffff;
                                border: 1px solid #08487a;
                                left: 0;
                                border-radius: 4px;
                                text-transform: uppercase;
                              "
                              @click="
                                $router.push({
                                  name: 'project',
                                  params: { projectId: application.projectId },
                                })
                              ">
                              <LinkIcon class="mr-2" />
                              Проект
                            </v-btn>
                          </v-col>
                          <v-col :cols="12" :sm="6" class="d-flex justify-end">
                            <v-btn
                              v-if="hasApprovedCalculation"
                              class="text-white ma-3"
                              style="
                                margin-bottom: 0 !important;
                                background: #08487a;
                                border-radius: 4px;
                              "
                              :disabled="
                                !hasApprovedCalculation ||
                                !!application.readOnly ||
                                !!application.$hasChanges
                              "
                              :loading="createSliderLoading"
                              @click="
                                () =>
                                  application.slider
                                    ? handleSlider('open')
                                    : handleSlider('create')
                              ">
                              <PlusIcon class="mr-2" />
                              <span class="ml-1">{{
                                application.slider
                                  ? 'Оновити бігунок'
                                  : 'Створити бігунок'
                              }}</span>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </div>
                  <StatusBar
                    class="mt-1"
                    :currentStatus="application.statusId"
                    :statuses="statuses"
                    :submit="toggleStatus"
                    :color="BtnStatusColor"
                    spread-items
                    size="small">
                  </StatusBar>
                  <div
                    v-if="!createApplicationMode"
                    data-readonly="hide"
                    class="social-wrapper"
                    style="padding: 0 0 15px 15px">
                    <div
                      class="social"
                      style="display: flex; align-items: center">
                      <v-tooltip
                        bottom
                        text="Відправити повiдомлення на Whatsapp">
                        <template #activator="{ props }">
                          <v-btn
                            size="x-small"
                            icon
                            style="margin-right: 12px"
                            v-bind="props"
                            @click="
                              () =>
                                $setSnackbar({
                                  text: 'Функціонал тимчасово недоступний',
                                  color: 'warning',
                                })
                            ">
                            <TellIcon />
                          </v-btn>
                        </template>
                        <span>Відправити повiдомлення на Whatsapp</span>
                      </v-tooltip>
                      <v-tooltip bottom text="Відправити лист на пошту">
                        <template #activator="{ props }">
                          <v-btn
                            size="x-small"
                            icon
                            style="margin-right: 35px"
                            :loading="loading"
                            v-bind="props"
                            @click="openCreateSmsDialog(2)">
                            <EmailIcon />
                          </v-btn>
                        </template>
                        <span>Відправити лист на пошту</span>
                      </v-tooltip>
                      <v-tooltip bottom text="Відправити повiдомлення на Viber">
                        <template #activator="{ props }">
                          <v-btn
                            size="x-small"
                            icon
                            style="margin-right: 12px"
                            :loading="loading"
                            v-bind="props"
                            @click="openCreateSmsDialog(3)">
                            <ViberIcon />
                          </v-btn>
                        </template>
                        <span>Відправити повiдомлення на Viber</span>
                      </v-tooltip>
                      <v-tooltip
                        bottom
                        text="Відправити повiдомлення на телефон">
                        <template #activator="{ props }">
                          <v-btn
                            size="x-small"
                            icon
                            style="margin-right: 35px"
                            :loading="loading"
                            v-bind="props"
                            @click="openCreateSmsDialog(1)">
                            <MessageIcon />
                          </v-btn>
                        </template>
                        <span>Відправити повiдомлення на телефон</span>
                      </v-tooltip>
                      <v-tooltip bottom text="Створити нагадування">
                        <template #activator="{ props }">
                          <v-btn
                            size="x-small"
                            icon
                            v-bind="props"
                            @click="openCreateNotificationDialog()">
                            <NotifIcon />
                          </v-btn>
                        </template>
                        <span>Створити нагадування</span>
                      </v-tooltip>
                    </div>
                  </div>
                  <div v-if="!createApplicationMode" class="notif-wrapper">
                    <div
                      v-for="item in firstNotifications"
                      :key="item.id"
                      class="notif-block">
                      <div class="notif-block__header">
                        <span class="notif-title">{{ item.taskMessage }}</span>
                        <svg
                          width="20"
                          height="21"
                          viewBox="0 0 20 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M6.587 19.912C6.979 20.304 7.45 20.5 8 20.5C8.54934 20.5007 9.02 20.305 9.412 19.913C9.804 19.521 10 19.05 10 18.5H6C5.99934 19.0493 6.195 19.52 6.587 19.912Z"
                            fill="#FC7247" />
                          <path
                            d="M0.287004 17.212C0.479004 17.404 0.71667 17.5 1 17.5H15C15.2827 17.5007 15.52 17.405 15.712 17.213C15.904 17.021 16 16.7833 16 16.5C16.0007 16.2173 15.905 15.98 15.713 15.788C15.521 15.596 15.2833 15.5 15 15.5H14V13.6C12.7173 13.1007 11.6883 12.3133 10.913 11.238C10.1377 10.1627 9.75 8.91667 9.75 7.5C9.75067 6.73334 9.87167 6.00834 10.113 5.325C10.3543 4.64167 10.7167 4.00834 11.2 3.425C10.9673 3.259 10.705 3.11334 10.413 2.988C10.121 2.86267 9.81667 2.76667 9.5 2.7V2C9.50067 1.584 9.355 1.23 9.063 0.938002C8.771 0.646002 8.41667 0.500002 8 0.500002C7.584 0.499336 7.23 0.645002 6.938 0.937002C6.646 1.229 6.5 1.58334 6.5 2V2.7C5.2 3.03267 4.125 3.72834 3.275 4.787C2.425 5.84567 2 7.08334 2 8.5V15.5H1C0.717337 15.4993 0.480004 15.595 0.288004 15.787C0.0960036 15.979 3.4602e-06 16.2167 3.4602e-06 16.5C-0.000663206 16.7827 0.0950036 17.02 0.287004 17.212Z"
                            fill="#FC7247" />
                          <path
                            d="M20 7.5C20 9.70914 18.2091 11.5 16 11.5C13.7909 11.5 12 9.70914 12 7.5C12 5.29086 13.7909 3.5 16 3.5C18.2091 3.5 20 5.29086 20 7.5Z"
                            fill="#62C14A" />
                        </svg>
                      </div>
                      <div class="notif-block__footer">
                        <span class="notif-date">{{
                          toFormatDate(item.date)
                        }}</span>
                        <span class="notif-user">{{
                          getContactInitials(item.user)
                        }}</span>
                        <v-btn
                          class="notif-btn"
                          size="x-small"
                          :loading="loading"
                          @click="completeTask(item)">
                          Завершити
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </div>
                <v-card :elevation="0" class="relative">
                  <send-offer
                    v-if="applicationCalculationRoute"
                    v-model:sendOfferDialog="sendOfferDialog"
                    :clientOffer="{ ...objectToSend, application: application }"
                    :dialogAction="dialogAction"
                    :cb="updateCalculations">
                  </send-offer>
                  <v-tabs
                    v-if="!calculationsRoutes"
                    v-model="currentTab"
                    slider-color="#FC7247"
                    style="padding-left: 10px"
                    class="application-tabs"
                    show-arrows
                    :height="38">
                    <v-tab
                      v-if="!application.id"
                      replace
                      :to="{
                        name: 'create-application-common-info',
                      }">
                      ОСНОВНА ІНФОРМАЦІЯ
                    </v-tab>
                    <v-tab
                      v-if="application.id"
                      replace
                      :to="{
                        name: 'application-common-info',
                        params: { applicationId: $route.params.applicationId },
                      }">
                      ОСНОВНА ІНФОРМАЦІЯ
                    </v-tab>
                    <v-tab
                      v-if="application.id"
                      replace
                      :to="{
                        name: 'application-calculations',
                        params: { applicationId: $route.params.applicationId },
                      }">
                      РОЗРАХУНКИ
                    </v-tab>
                    <!--                    <v-tab-->
                    <!--                      v-if="application.id"-->
                    <!--                      @click="-->
                    <!--                        $router.replace({-->
                    <!--                          name: 'application-market-value-assessment',-->
                    <!--                        })-->
                    <!--                      "-->
                    <!--                      >ОЦІНКА РИНКОВОЇ ВАРТОСТІ</v-tab-->
                    <!--                    >-->
                    <v-tab
                      v-if="application.id"
                      replace
                      :to="{
                        name: 'application-comunication',
                        params: { applicationId: $route.params.applicationId },
                      }">
                      АКТИВНІСТЬ
                    </v-tab>
                  </v-tabs>
                  <v-fade-transition hide-on-leave>
                    <div>
                      <RouterView
                        v-model:objectToSend="objectToSend"
                        v-model:sendOfferDialog="sendOfferDialog"
                        v-model:dialogAction="dialogAction"
                        v-model:deleteDialog="deleteDialog"
                        v-model:dialogItem="dialogItem"
                        style="min-height: 390px"
                        :sendedOffers="application.offer_sends"
                        :updateCalculations="updateCalculations"
                        :tBody="calculationTableBody"
                        :cb="() => $store.commit('refreshPage')"
                        :sourceReTreatment="sourceReTreatment"
                        :createApplicationMode="createApplicationMode"
                        :application="application"
                        :v="v$"
                        :calculationsLoading="calculationsLoading"
                        :mainContactId="mainContactId"
                        :mainContractorId="mainContractorId"
                        :readonly="application.readOnly"
                        @data-changed="
                          getApplication($route.params.applicationId)
                        "
                        @get-calculations="updateCalculations">
                      </RouterView>
                      <div v-if="!createApplicationMode">
                        <div v-if="$route.name === 'application-calculations'">
                          <Pagination
                            v-show="
                              applicationCalculationRoute &&
                              !!calculationTableBody.length
                            "
                            :keep-query="false"
                            :getItems="getApplicationCalculations"
                            :trigger="calcPaginationTrigger" />
                        </div>
                      </div>
                    </div>
                  </v-fade-transition>
                  <ActionButtons
                    v-if="!calculationsRoutes"
                    class="ml-2 mb-10"
                    :confirm="updateApplication"
                    :cancel="() => $store.commit('refreshPage')"
                    :confirmLoading="loading"
                    :cancelDisable="
                      !application.$hasChanges || loading || pageLoad
                    "
                    :confirmDisable="
                      !application.$hasChanges || loading || pageLoad
                    ">
                  </ActionButtons>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-fade-transition>
      <div v-if="!pageLoad && responseMessage">
        <v-card-text>
          <v-card-title>{{ responseMessage }}</v-card-title>
        </v-card-text>
      </div>
      <SectionLoader v-if="pageLoad"></SectionLoader>
    </div>
  </div>
</template>
<!--  -->
<!--  -->
<script>
import TellIcon from '@/assets/svg/social/tell-icon.vue'
import ViberIcon from '@/assets/svg/social/viber-icon.vue'
import MessageIcon from '@/assets/svg/social/message-icon.vue'
import EmailIcon from '@/assets/svg//social/email-icon.vue'
import NotifIcon from '@/assets/svg//social/notif-icon.vue'
import LinkIcon from '@/assets/svg/link-icon.vue'
import PlusIcon from '@/assets/svg/whitePlus-icon.vue'
import Pagination from '@/components/Pagination.vue'
import actionCard from '@/components/action-dialog.vue'
import ActionButtons from '@/components/action-buttons.vue'
import SectionLoader from '@/components/section-loader.vue'
import mainContractorCard from '@/pages/transaction-participants/contacts/main-contractor.vue'
import mainContactCard from '@/pages/transaction-participants/contractors/main-contact-card.vue'
import applicationCommonCard from './components/application-common-card.vue'
import sendOffer from '@/components/send-offer.vue'
import StatusBar from '@/components/StatusBar.vue'
import { mapState } from 'vuex'
import { required, minLength } from '@vuelidate/validators'
import { urlTaskPlanTaskUpdate } from '@/pages/request'
import { highLightErrors as hlErrors, v$Notify } from '@/utils/helperFunc'
import { toFormatDate, getContactInitials } from '@/utils/helperFunc'
import {
  urlCalculationsByApplication,
  urlApplicationById,
  urlApplicationUpdateStatus,
  urlDeleteCalculation,
  urlUpdateApplication,
  urlCreateApplication,
  urlCreateSlider,
  urlGetSliderByApplication,
  urlApplicationRemoveApplicationAndPhones,
  urlUnsignCalculation,
} from '@/pages/request'
import { application as LT, leasingClient as LC } from '@/type-ids'
import { useVuelidate } from '@vuelidate/core'
import { cachedObject } from 'best-modules/utils'
export default {
  components: {
    SectionLoader,
    sendOffer,
    actionCard,
    ActionButtons,
    mainContractorCard,
    mainContactCard,
    applicationCommonCard,
    StatusBar,
    Pagination,
    PlusIcon,
    LinkIcon,
    ViberIcon,
    NotifIcon,
    MessageIcon,
    EmailIcon,
    TellIcon,
  },
  setup() {
    const application = cachedObject({
      sourceLinkAgentId: null,
      phones: [],
      emails: [],
      contractorId: null,
      contactId: null,
      responsibleId: null,
      clientId: null,
      clientTypeId: 2,
      comment: null,
      companyName: null,
      edrpou: null,
      inn: null,
      clientNeedId: null,
      communicationMethodId: null,
      applicationName: null,
      name: null,
      patronymic: null,
      surname: null,
      source: null,
      slider: null,
      sourceId: null,
      sourceLinkId: null,
      sourceTypeId: null,
      contactPersonId: null,
      isMissingData: false,
      isPatronymic: true,
      lessee: {
        contact: null,
        contractor: null,
        contactPersonId: null,
        contractorId: null,
        contactId: null,
      },
    })
    return { v$: useVuelidate(), application }
  },
  validations() {
    const isContractor = this.application.clientTypeId === 2
    const isContact = [1, 3].includes(this.application.clientTypeId)
    const clientNameValidator = {
      name: { required },
      surname: this.createSliderValidationMode ? { required } : {},
      patronymic:
        this.createSliderValidationMode && this.application.isPatronymic
          ? { required }
          : {},
    }

    const sourceId =
      this.createApplicationMode && this.sourceOnline
        ? { sourceId: { required } }
        : {}
    const sourceLinkId =
      this.createApplicationMode &&
      this.application.sourceId &&
      this.selects.applicationSourceLinks.length
        ? { sourceLinkId: { required } }
        : {}
    const sourceLinkAgentId =
      this.createApplicationMode && this.sourceAgent
        ? { sourceLinkAgentId: { required } }
        : {}
    const clientTypeId =
      this.mainContactId ||
      this.mainContractorId ||
      this.application.lessee.contactId ||
      this.application.lessee.contractorId
        ? {}
        : { clientTypeId: { required } }
    const name =
      this.application.lessee.contractorId ||
      this.mainContractorId ||
      this.application.lessee.contactId ||
      this.mainContactId
        ? {}
        : clientNameValidator

    const emailsPhones = this.createSliderValidationMode
      ? { required, minLength: minLength(1) }
      : {}

    const contactId =
      this.sourceReTreatment && !this.mainContactId && !this.mainContractorId
        ? { required }
        : {}
    const contractorId =
      this.sourceReTreatment && !this.mainContractorId && !this.mainContactId
        ? { required }
        : {}
    const companyValidator = {
      edrpou:
        isContractor && this.createSliderValidationMode
          ? { required, minLength: minLength(8) }
          : {},
      name: isContractor && this.createSliderValidationMode ? { required } : {},
    }
    const inn =
      (isContact && this.createSliderValidationMode) || this.application.inn
        ? { required, minLength: minLength(10) }
        : {}

    return {
      application: {
        responsibleId: { required },
        sourceTypeId: this.createApplicationMode ? { required } : {},
        applicationName: { required },
        clientNeedId: { required },
        communicationMethodId: { required },
        inn: inn,
        companyName: companyValidator.name,
        edrpou: companyValidator.edrpou,
        ...sourceId,
        ...sourceLinkId,
        ...sourceLinkAgentId,
        ...clientTypeId,
        ...name,
        ...emailsPhones,
        emails:
          !this.createSliderValidationMode &&
          !this.application.isMissingData &&
          !this.application.phones?.length &&
          !this.application.emails?.length
            ? { required, minLength: minLength(1) }
            : {},
        phones:
          !this.createSliderValidationMode &&
          !this.application.isMissingData &&
          !this.application.phones?.length &&
          !this.application.emails?.length
            ? { required, minLength: minLength(1) }
            : {},
      },
      mainContactId: contactId,
      mainContractorId: contractorId,
    }
  },
  data: () => ({
    createSliderValidationMode: false,
    responseMessage: null,
    calculationsLoading: false,
    calcPaginationTrigger: 1,
    createSliderLoading: false,
    mainContactId: null,
    mainContractorId: null,
    contractor: {},
    contact: {},
    dialogMode: null,
    pageLoad: false,
    sendOfferDialog: false,
    objectToSend: null,
    dialogAction: null,
    deleteDialog: false,
    dialogItem: null,
    dialog: false,
    dialogState: null,
    email: null,
    phone: null,
    currentTab: 0,
    loading: false,
    calculationTableBody: [],
    sourceTypes: [],
    sourceLinks: [],
  }),
  computed: {
    ...mapState({
      user: state => state.user,
      selects: state => state.selectItems,
    }),
    createApplicationMode() {
      return this.$route.name === 'create-application-common-info'
    },
    applicationCalculationRoute() {
      return this.$route.name === 'application-calculations'
    },
    agentChanged() {
      if (!this.application.$cache) return false
      return (
        this.application.$cache.sourceLinkAgentId !==
        this.application.sourceLinkAgentId
      )
    },
    contactWatch() {
      return this.application.lessee.contactId
    },
    BtnStatusColor() {
      return this.application.statusId === 6 ? '#E14545' : null
    },
    firstNotifications() {
      return Object.clone(this.application?.task_schedulers || [])
        .filter(t => !t.isDone)
        .sort((a, b) => new Date(a.date) - new Date(b.date))
        .slice(0, 3)
    },
    statuses() {
      if (this.application.statusId === 6) {
        return this.selects.applicationStatuses
          .map(s =>
            s.id === 6
              ? { id: 6, name: this.application.rejection.type.name }
              : s
          )
          .filter(s => s.id !== 5)
      } else if (this.application.statusId === 5) {
        return this.selects.applicationStatuses.filter(s => s.id !== 6)
      } else {
        return this.selects.applicationStatuses.filter(s => s.id !== 5)
      }
    },
    calculationsRoutes() {
      return [
        'application-create-caluclation',
        'application-new-payment-plan',
        'application-slider',
        'slider-client',
        'slider-guarantors',
        'slider-subtenants',
        'slider-leasing-objects',
        'slider-scoring',
        'slider-main-info',
        'application-edit-calculation',
        'application-payment-plan',
        'edit-calculator-leasing-object',
        'edit-calculator-leasing-terms',
        'edit-calculator-additional-terms',
        'edit-calculator-service',
        'create-calculator-leasing-object',
        'create-calculator-leasing-terms',
        'create-calculator-additional-terms',
        'create-calculator-service',
      ].includes(this.$route.name)
    },
    sliderRoute() {
      return this.$route.name.includes('slider')
    },
    applicationRoute() {
      return !this.sliderRoute && !this.calculationsRoutes
    },
    sourceReTreatment() {
      return this.application.sourceTypeId === LT.sourceType.RE_TREATMENT
    },
    sourceAgent() {
      return this.application.sourceTypeId === LT.sourceType.AGENT
    },
    sourceOnline() {
      return this.application.sourceTypeId === LT.sourceType.ONLINE
    },
    hasApprovedCalculation() {
      if (!this.calculationTableBody.length) return false
      return this.calculationTableBody.filter(v => v.isAgreed).length > 0
    },
    url() {
      return this.applicationId
        ? this.urlUpdateApplication(this.$route.params.applicationId)
        : this.urlCreateApplication()
    },
    successMessage() {
      return this.applicationId
        ? 'Заявку успiшно оновлено'
        : 'Заявку успiшно створено'
    },
    applicationId() {
      return parseInt(this.$route.params.applicationId)
    },
  },
  methods: {
    urlCalculationsByApplication,
    urlApplicationById,
    urlApplicationUpdateStatus,
    urlDeleteCalculation,
    urlApplicationRemoveApplicationAndPhones,
    urlUpdateApplication,
    urlCreateApplication,
    urlCreateSlider,
    urlGetSliderByApplication,
    hlErrors,
    urlUnsignCalculation,
    toFormatDate,
    getContactInitials,
    completeTask(item) {
      item.isDone = true
      this.loading = true
      return this.$axios
        .post(urlTaskPlanTaskUpdate(item.id), item)
        .then(() => {
          this.$setSnackbar({ text: 'Нагадування завершено' })
          this.loading = false
          this.application.task_schedulers =
            this.application.task_schedulers.map(t =>
              t.id === item.id ? { ...t, isDone: true } : t
            )
        })
        .catch(this.$err)
    },
    openCreateNotificationDialog() {
      this.$store.commit('setDialog', {
        title: 'Деталі по нагадуванню',
        dialogItem: {
          entityId: this.application.id,
          entityTypeId: 3,
        },
        params: {
          dialogHeight: 700,
          dialogWidth: 600,
          cb: () =>
            this.getApplication(this.application.id).then(
              () => (this.loading = false)
            ),
          overflow: { y: 'hidden' },
        },
        action: 'task-plan-dialog',
      })
    },
    openCreateSmsDialog(type) {
      if (type === 2 && !this.application.emails.length > 0)
        return this.$setSnackbar({
          text: '[Email] - повинен бути заповнений',
          color: 'warning',
        })
      if ([1, 3].includes(type) && this.application.phones.length === 0) {
        return this.$setSnackbar({
          text: '[Телефон] - повинен бути заповнений',
          color: 'warning',
        })
      }
      this.loading = true
      this.$store.commit('setDialog', {
        title: 'Вiдправка повiдомлення',
        dialogItem: {
          entityId: this.application.id,
          emails: this.application.emails,
          phones: this.application.phones,
          resEmail: this.application.responsible.email,
          photo: this.application.responsible.photo,
          name: this.application.responsible.name,
        },
        params: {
          type: type,
          entityTypeId: 3,
          dialogWidth: 500,
          submitText: 'Відправити',
          rejectText: 'Скасувати',
          cb: () =>
            this.getApplication(this.application.id).then(
              () => (this.loading = false)
            ),
        },
        action: 'send-sms-dialog',
      })
      this.loading = false
    },
    updateCalculations() {
      this.calcPaginationTrigger += 1
    },
    unsignCalculations() {
      this.calculationTableBody
        .filter(c => !!c.isAgreed)
        .forEach(
          async c => await this.$axios.get(this.urlUnsignCalculation(c.id))
        )
    },
    openDialogToApprove() {
      const errors = this.validateFields()
      if (errors > 0) return
      this.$store.commit('setDialog', {
        title: 'Бiгунок',
        dialogItem: {},
        action: 'approveSlider',
        params: {
          calculations: this.calculationTableBody,
          dialogWidth: 600,
        },
      })
    },
    handleSlider(action) {
      this.createSliderValidationMode = true
      if (this.v$.$invalid) {
        this.validateApplication()
        this.createSliderValidationMode = false
        return
      }

      switch (action) {
        case 'create':
          return this.createSlider()
        case 'open':
          return this.openSlider()
      }
    },
    createSlider() {
      if (!this.application.phones?.length) {
        return this.$setSnackbar({
          text: 'Заповніть телефон клієнта',
          color: 'warning',
        })
      }
      this.createSliderLoading = true

      return this.$axios
        .get(this.urlCreateSlider(this.applicationId))
        .then(() => {
          this.openSlider()
          this.application.slider = {}
          this.createSliderLoading = false
        })
    },
    openSlider() {
      this.$router.push({ name: 'application-slider' })
    },
    assignContactId(e) {
      if (e === null) this.application.lessee.contact = null
      this.mainContactId = e
      this.application.lessee.contactId = e
    },
    addContractorToApplication() {
      this.$store.commit('setDialog', {
        title: 'Контрагент',
        dialogItem: null,
        action: 'addContractorToApplication',
        params: { saveContractorId: id => this.saveContractorId(id) },
      })
    },
    setClientTypeId() {
      this.application.clientTypeId = 2
    },
    deleteContractorFromApplication() {
      this.saveContractorId(null)
    },
    validateFields() {
      this.v$.$anyError
      this.v$.$touch()
      v$Notify(
        {
          ...this.v$.application,
          mainContactId: this.v$.mainContactId,
          mainContractorId: this.v$.mainContractorId,
        },
        'application'
      )

      switch (this.application.clientTypeId) {
        case 1:
          return this.validatePerson() /* person */
        case 2:
          return this.validateLegal() /* legal */
        case 3:
          return this.validatePerson() /* fop */
      }
    },
    validatePerson() {
      const errors = []
      const contact = this.application.lessee.contact?.id
      const inn =
        this.application?.inn?.length === 10 ||
        this.application.lessee.contactId ||
        this.mainContactId
      const name =
        this.application.lessee.contactId ||
        this.mainContactId ||
        this.application.name
      if (!inn && !contact)
        errors.push('Вкажіть IНН (або додайте контакт з IПН)')
      if (!name) errors.push('Вкажіть Iм`я')
      if (errors.length) errors.forEach(msg => this.$setSnackbar({ text: msg }))
      return errors.length
    },
    validateLegal() {
      const errors = []
      const contractor =
        this.application.lessee.contractor?.id || this.mainContractorId
      const edrpou =
        this.application?.edrpou?.length === 8 || this.mainContractorId
      const companyName = this.application?.companyName || this.mainContractorId
      const name = this.application?.name || this.mainContractorId
      if ((!companyName || !name || !edrpou) && !contractor) {
        let errFields = ''
        if (!companyName) errFields += ' [назва компанії] '
        if (!name) errFields += ' [Iм`я] '
        if (!edrpou) errFields += ' [ЄДРПОУ] '
        errors.push(
          `Заповнiть наступнi поля: ${errFields}, або додайте контрагента`
        )
      }
      if (errors.length) errors.forEach(msg => this.$setSnackbar({ text: msg }))
      return errors.length
    },
    updateSourceTypes(e) {
      this.sourceTypes.splice(0)
      e && e.length && this.sourceTypes.push(...e)
    },
    updateSourceLinks(e) {
      this.sourceLinks.splice(0)
      e && e.length && this.sourceLinks.push(...e)
    },
    saveContactId(e) {
      e && e.id && this.$store.commit('saveContactId', e.id)
      this.application.lessee.contact = e
      if (!e?.id) {
        this.application.id = e
        console.log('fuck')
      }
    },
    saveContractorId(e) {
      e && e.id && this.$store.commit('saveContractorId', e?.id)
      this.application.lessee.contractor = e
      if (!e?.id) {
        this.application.lessee.contractorId = e
        this.mainContractorId = e
      }
      if (e?.id) {
        this.mainContractorId = e.id
      }
    },
    openDialog(state, item = {}, dialogMode) {
      this.dialogMode = dialogMode
      setTimeout(() => {
        this.dialog = !this.dialog
        this.dialogState = state
        this.dialogItem = item
      }, 0)
    },
    deleteCalculation() {
      return this.$axios
        .delete(this.urlDeleteCalculation(this.dialogItem.id))
        .then(res => {
          this.$setSnackbar({ text: 'Рохрахунок видалено' })
          this.updateCalculations()
          this.deleteDialog = !this.deleteDialog
          return res
        })
        .catch(this.$err)
    },
    changeStatus(body) {
      return this.$axios
        .post(this.urlApplicationUpdateStatus(this.application.id), body)
        .then(res => {
          if (res.data.message) {
            return this.$setSnackbar({
              text: res.data.message,
              color: 'warning',
            })
          } else {
            this.$setSnackbar({ text: 'Статус успiшно змiнено' })
            this.getApplication(this.application.id)
            return res
          }
        })
        .catch(this.$err)
    },
    toggleStatus(id) {
      switch (id) {
        case 1:
          return // not-handled (disabled)
        case 2:
          return // in work (disabled)
        case 3:
          return // sended client offer (disabled)
        case 4:
          return // collecting documents (disabled)
        case 5:
          return // project (disabled)
        case 6:
          return this.openRejectionDialog({ statusId: id }) // rejection
      }
    },
    openRejectionDialog(body) {
      if (this.application.statusId === 5) return
      return this.$store.commit('setDialog', {
        title: 'Вiдмова',
        dialogItem: body,
        params: {
          changeStatus: this.changeStatus,
        },
        action: 'rejectApplication',
        disabled: false,
      })
    },
    getApplicationCalculations(page = 1) {
      if (!this.$route.params.applicationId) return
      this.calculationsLoading = true
      return this.$axios
        .get(
          this.urlCalculationsByApplication(this.$route.params.applicationId, {
            page,
          })
        )
        .then(res => {
          this.calculationTableBody.splice(0)
          const sortedCalculations = (res.data.data || []).sort(
            (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
          )
          this.calculationTableBody.push(...sortedCalculations)

          return res
        })
        .catch(this.$err)
        .finally(() => (this.calculationsLoading = false))
    },
    collectApplicationObject(applicationData) {
      const application = Object.clone(applicationData.$object)
      let phones = []
      let emails = []

      if (application.sourceTypeId !== 3) {
        phones = this.application.phones
          .filter(phone => {
            return !this.application.$cache.phones
              .map(item => item.phone)
              .includes(phone.phone)
          })
          .map(p => p.phone)
        emails = this.application.emails
          .filter(
            email =>
              !this.application.$cache.emails
                .map(item => item.email)
                .includes(email.email)
          )
          .map(p => p.email)
        // application.contactId = null
        // application.contractorId = null
      } else {
        phones = this.application.phones
        emails = this.application.emails
      }
      if (application.lessee && application.lessee.contractorId) {
        application.contractorId = application?.lessee?.contractorId
      }
      if (application.lessee && application.lessee.contactId) {
        application.contactId = application?.lessee?.contactId
      }
      if (application.lessee && application.lessee.contactPersonId) {
        application.contactPersonId = application?.lessee?.contactPersonId
      }

      application.phones.splice(0)
      application.emails.splice(0)
      application.phones.push(...phones)
      application.emails.push(...emails)

      if (this.$store.state.common.contactId && !application.lessee.contactId) {
        application.contactId = this.$store.state.common.contactId
      } else if (
        this.$store.state.common.contractorId &&
        !application.lessee.contractorId
      ) {
        application.contractorId = this.$store.state.common.contractorId
      }

      delete application.lessee
      this.$store.commit('saveContactId', null)
      this.$store.commit('saveContractorId', null)

      return application
    },
    validateApplication() {
      this.v$.$anyError
      this.v$.$touch()
      v$Notify(
        {
          ...this.v$.application,
          mainContactId: this.v$.mainContactId,
          mainContractorId: this.v$.mainContractorId,
        },
        'application'
      )
    },
    normalizeContacts(contacts, type) {
      if (Array.isArray(contacts)) {
        return contacts.map(contact => {
          const key = type === 'phone' ? 'phone' : 'email'

          if (typeof contact === 'object' && contact[key]) {
            return contact[key]
          }
          return contact
        })
      }
      return []
    },
    async updateApplication() {
      return this.v$.$invalid ? this.validateApplication() : this.sendRequest()
    },
    async sendRequest(notify = true, loading = true) {
      if (loading) this.loading = true

      const applicationObject = this.collectApplicationObject(this.application)
      delete applicationObject?.responsible

      if (applicationObject.phones.length) {
        applicationObject.phones = this.normalizeContacts(
          applicationObject.phones,
          'phone'
        )
      }
      if (applicationObject.emails.length) {
        applicationObject.emails = this.normalizeContacts(
          applicationObject.emails,
          'email'
        )
      }

      if (this.application.sourceTypeId === 3 && !this.createApplicationMode) {
        await this.$axios.get(
          this.urlApplicationRemoveApplicationAndPhones(this.application.id)
        )
      }

      return this.$axios
        .post(this.url, applicationObject)
        .then(async res => {
          if (res.data.message)
            return this.$setSnackbar({
              text: res.data.message,
              color: 'warning',
            })
          // if (this.agentChanged) await this.unsignCalculations()
          notify && this.$setSnackbar({ text: this.successMessage })

          if (this.$route.name !== 'edit-application') {
            this.$router.push({
              name: 'edit-application',
              params: { applicationId: res.data.id },
            })
          }

          this.loading = false

          this.v$.$reset()
          this.$store.commit('refreshPage')

          return res
        })
        .catch(err => {
          if (err.response.data.errors) {
            this.$setSnackbar(
              // eslint-disable-next-line
              {
                text: JSON.stringify(err.response.data.errors).replace(
                  /[{}[\]]/g,
                  ''
                ),
                color: 'error',
              }
            )
            this.loading = false
            return null
          } else {
            this.$err(err, () => (this.loading = false))
          }
        })
    },
    getApplication(id, loader = true) {
      if (loader) {
        this.pageLoad = true
        // this.application.id = null
      }

      return this.$axios
        .get(this.urlApplicationById(id))
        .then(res => {
          if (res.data.message) {
            this.responseMessage = res.data.message
          } else {
            this.$store.commit('setApplication', res.data)

            if (res.data.sourceTypeId === 3) {
              this.application.$setIgnore('emails', 'phones')
            }

            this.application.$set({
              ...res.data,
              lessee: { ...this.application.lessee, ...res.data.lessee },
            })
            // res.data.lessee.contactId && (this.mainContactId = res.data.lessee.contactId)
          }

          this.setBreadScrumb()
          this.pageLoad = false

          return res
        })
        .catch(this.$err)
    },
    setBreadScrumb() {
      this.$store.commit('setBreadScrumbPart', [
        null,
        this.application.applicationName,
        null,
      ])
    },
  },
  watch: {
    calculationsRoutes: {
      handler(newVal, oldVal) {
        if (oldVal && !newVal) {
          this.getApplicationCalculations()
        }
      },
    },
    'application.lessee.contactPersonId': {
      async handler(id) {
        if (this.mainContractorId) {
          this.application.emails.splice(0)
          this.application.phones.splice(0)
          if (id) {
            if (!this.selects.applicationContacts.length)
              await this.$store.dispatch(
                'addApplicationContacts',
                this.application.lessee.contractorId
              )
            const c =
              this.selects.applicationContacts.find(el => el.contactId === id)
                ?.contact || null
            if (!c) return
            this.application.phones.push(...c.phones)
            this.application.emails.push(...c.emails)
          }
        }
      },
      immediate: true,
    },
    mainContractorId: function (id) {
      if (!id) {
        this.application.companyName = null
        this.application.edrpou = null
        this.application.lessee.contactPersonId = null
        return
      }
      const contractor =
        this.application.lessee.contractor.id ===
        this.application.lessee.contractorId
          ? this.application.lessee.contractor
          : this.selects.contractors.find(contractor => contractor.id === id)
      this.application.clientTypeId = LC.type.LEGAL || null
      this.application.companyName = contractor?.shortName || null
      this.application.edrpou = contractor?.edrpou || null
      this.application.lessee.contractorId = contractor?.id || null
    },
    mainContactId: function (id) {
      if (!id) {
        this.application.inn = null
        this.application.surname = null
        this.application.name = null
        this.application.patronymic = null
        return
      }
      const contact =
        this.application.lessee.contact.id === this.application.lessee.contactId
          ? this.application.lessee.contact
          : this.selects.detailedContacts.find(contact => contact.id === id)
      if (this.createApplicationMode && this.sourceReTreatment) {
        this.application.clientTypeId = contact?.fop
          ? LC.type.ENTEPRENEUR
          : LC.type.PERSON
      }
      this.application.inn = contact?.inn || null
      // eslint-disable-next-line no-unsafe-optional-chaining
      const [secondName, name, patronymic] = contact?.fullName?.split(' ')
      this.application.surname = secondName || null
      this.application.name = name || null
      this.application.patronymic = patronymic || null
      if (this.sourceReTreatment) {
        if (contact.emails) {
          this.application.emails.splice(0)
          this.application.emails.push(...contact.emails)
        }
        if (contact.phones) {
          this.application.phones.splice(0)
          this.application.phones.push(...contact.phones)
        }
      }
    },
    '$route.name': {
      handler() {
        this.setBreadScrumb()
      },
    },
    applicationRoute: {
      handler(val) {
        if (this.createApplicationMode) {
          this.$store.dispatch('addApplicationSourceTypes')
          this.$store.dispatch('addClientNeeds')
          this.$store.dispatch('addApplicationComunicationMethods')
          this.$store.dispatch('addClientTypes')
          this.application.responsibleId = this.user.id
          this.application.responsible = this.user
        } else if (val) {
          this.getApplication(this.$route.params.applicationId).then(() => {
            if (this.application.lessee.contractorId)
              this.$store.dispatch(
                'addApplicationContacts',
                this.application.lessee.contractorId
              )
            this.mainContactId = this.application.lessee.contactId
            this.mainContractorId = this.application.lessee.contractorId
          })
          this.$store.dispatch('addApplicationStatuses')
        }
      },
      immediate: true,
    },
  },
  created() {
    this.getApplicationCalculations()
  },
}
</script>
<style lang="scss" scoped>
.v-tab {
  padding: 0 !important;
  margin-right: 25px;
}
.v-tabs.application-tabs .v-tab,
.v-tabs.application-tabs .v-tab-item--selected {
  background: transparent !important;
}
.v-tab-item--selected.v-tab {
  color: #08487a !important;
  font-weight: 700 !important;
  font-size: 14px;
}
.v-tab {
  color: #838282 !important;
}

.status-wrapper {
  background: rgb(239, 239, 239);
  box-shadow: rgb(0 0 0 / 7%) 0 2px 8px;
  border-radius: 4px;
  margin-right: 15px;
}

.social-icon svg {
  height: 24px;
  width: 24px;
}

.notif-wrapper {
  gap: 15px;
  padding: 0 15px 10px 15px;
  display: flex;
  flex-wrap: wrap;
  .notif-block {
    background: #ffffff;
    box-shadow: 0 2px 8px rgb(0 0 0 / 4%);
    border-radius: 4px;
    width: 265px;
    height: 100px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 10px;
    transition: all 0.3s;
    &:hover {
      background-color: #e2e2e2;
    }
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .notif-title {
        font-size: 14px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 215px;
      }
    }
    &__footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .notif-date {
        font-size: 12px;
      }
      .notif-user {
        font-size: 12px;
      }
      .notif-btn {
        background: #fc7247 !important;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04) !important;
        border-radius: 4px !important;
        color: #fff !important;
      }
    }
  }
}
</style>
