<template>
  <div class="split-btn">
    <v-btn
      class="split-btn__button"
      :disabled="actions.main.disabled"
      @click="() => actions.main.cb()">
      {{ actions.main.label }}
    </v-btn>
    <v-menu bottom offset-y>
      <template #activator="{ props }">
        <v-btn
          v-if="Array.isArray(actions.list)"
          class="split-btn__icon"
          v-bind="props">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="6"
            viewBox="0 0 8 6"
            fill="none">
            <path
              d="M6.63431 0.26035C6.94673 -0.0867825 7.45326 -0.0867825 7.76568 0.26035C8.0781 0.607483 8.0781 1.1703 7.76568 1.51743L4.56568 5.07298C4.25326 5.42012 3.74673 5.42012 3.43431 5.07298L0.234314 1.51743C-0.0781049 1.17029 -0.0781048 0.607483 0.234314 0.260349C0.546734 -0.0867831 1.05327 -0.086783 1.36569 0.260349L4 3.18737L6.63431 0.26035Z"
              fill="white" />
          </svg>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in actions.list"
          :key="index"
          :value="index"
          @click="() => item.cb()">
          <v-list-item-title>{{ item.label }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    actions: { type: Object, required: true },
  },
}
</script>

<style>
.split-btn {
  display: flex;
  align-items: center;
}
.split-btn__button {
  background: #fc7247 !important;
  border-radius: 4px !important;
  color: #fff !important;
  font-weight: 400 !important;
  width: 75% !important;
  font-size: 14px !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.split-btn__icon {
  background: #fc7247 !important;
  border-radius: 4px !important;
  color: #fff !important;
  font-weight: 400 !important;
  width: 15% !important;
  font-size: 14px !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
</style>
