<template>
  <div>
    <v-row class="d-flex justify-space-between">
      <v-col :cols="12" :md="6" class="pt-0 pb-0">
        <v-row class="mt-3 mb-5">
          <div class="projectRow">
            <svg
              class="mr-2"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none">
              <path
                d="M16 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0ZM6 14H4V7H6V14ZM10 14H8V4H10V14ZM14 14H12V10H14V14Z"
                fill="#08487A" />
            </svg>
            <span class="projectTitle">Тип лізингу</span>
          </div>
        </v-row>
        <v-row class="align-center">
          <v-col :cols="12" :sm="5" class="pt-0 pb-0 flex-end">
            <span class="card-label calc">Тип лізингу</span>
          </v-col>
          <v-col :cols="12" :sm="7" class="pt-0 pb-0">
            <v-select
              v-model="calculationData.leasingType"
              :items="['Фiнансовий', 'Оперативний']"
              placeholder="оберiть тип лізингу"
              hide-details
              dense
              @change="handleGraphType">
            </v-select>
          </v-col>
        </v-row>
        <v-row class="align-center">
          <v-col :cols="12" :sm="5" class="pb-0 flex-end">
            <span
              class="card-label calc"
              :class="{ 'required-value': !!!calculationData.currency }">
              Валюта фінансування
            </span>
          </v-col>
          <v-col :cols="12" :sm="7" class="pb-0">
            <v-select
              v-model="calculationData.currency"
              :error-messages="currencyErr"
              :items="
                selectItems.currencies.filter(c =>
                  [
                    leasingClient.type.LEGAL,
                    leasingClient.type.ENTEPRENEUR,
                  ].includes(application.clientTypeId)
                    ? true
                    : c.id === financingCurrency.UAH
                )
              "
              item-title="name"
              item-value="name"
              placeholder="оберiть валюту фiнансування"
              hide-details
              dense
              @blur="$v.calculation.currency.$touch()">
              <!--              <template #append>-->
              <!--                <span v-if="calculationData.programFSN"></span>-->
              <!--              </template>-->
            </v-select>
          </v-col>
        </v-row>
        <v-row v-if="programFsnAccess" class="mt-2">
          <v-col :cols="6" :sm="5" class="pb-0 align-center">
            <span class="card-label calc"> Програма 5-7-9% </span>
          </v-col>
          <v-col :cols="6" :sm="7" class="pb-0 align-center">
            <v-checkbox
              :model-value="applyProgramFsn"
              class="mt-1 r-prep"
              color="red"
              :false-value="false"
              :true-value="true"
              hide-details
              dense
              :readonly="application.clientTypeId === 1"
              @update:model-value="trackFsn">
            </v-checkbox>
            <v-tooltip
              color="grey darken-3"
              top
              text="Державна програма «Доступний фінансовий лізинг 5-7-9%» покликана
                      посилити інвестиційну активність малого та середнього бізнесу»">
              <template #activator="{ props }">
                <span style="font-size: 14px; color: black">
                  <v-icon size="17" class="pb-3" v-bind="props">
                    {{ 'mdi-information' }}
                  </v-icon>
                </span>
              </template>
            </v-tooltip>
          </v-col>
        </v-row>

        <v-row v-if="benefitsAccess" class="mt-2 align-center">
          <v-col :cols="6" :sm="5" class="pb-0 align-center">
            <div class="d-flex align-center">
              <span class="card-label calc">BEST BENEFITS</span>
              <UsaidIcon class="ml-1" />
            </div>
          </v-col>
          <v-col :cols="6" :sm="7" class="pb-0 align-center">
            <v-checkbox
              :model-value="applyBenefits"
              class="mt-1 r-prep"
              color="red"
              :false-value="false"
              :true-value="true"
              hide-details
              dense
              @update:model-value="
                $event => {
                  $emit('update:applyBenefits', $event)
                  $emit('update:applyProgramFsn', false)
                  $emit('clearFsn')
                }
              ">
            </v-checkbox>
            <v-tooltip
              top
              text="Зниження вартості лізингу на 30% для допомоги переміщеному бізнесу">
              <template #activator="{ props }">
                <span
                  class="benefits-info"
                  style="font-size: 14px; color: black">
                  <v-icon size="17" class="pb-3" v-bind="props">
                    {{ 'mdi-information' }}
                  </v-icon>
                </span>
              </template>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="align-center">
          <v-col :cols="12" :sm="5" class="pb-0 flex-end">
            <span
              class="card-label calc"
              :class="{ 'required-value': !!!calculationData.leasingProgram }">
              Програма лізингу
            </span>
          </v-col>
          <v-col :cols="12" :sm="7" class="pb-0">
            <v-select
              v-model="calculationData.leasingProgram"
              :error-messages="leasingProgramErr"
              :items="
                directoryItems(
                  filteredLP,
                  calculationGeneralObject.leasingProgram
                )
              "
              item-title="name"
              item-value="programId"
              :item-disabled="
                item => [19, 21, 28, 20, 18, 34, 37].includes(item.programId)
              "
              placeholder="оберiть программу лізингу"
              hide-details
              :disabled="leasingProgramState || calculationData.programFSN"
              dense
              @blur="$v.calculation.leasingProgram.$touch()">
              <!--              <template #append>-->
              <!--                <span-->
              <!--                  v-if="-->
              <!--                    leasingProgramState || calculationData.programFSN-->
              <!--                  "></span>-->
              <!--              </template>-->
            </v-select>
          </v-col>
        </v-row>
        <v-row class="align-center">
          <v-col :cols="12" :sm="5" class="pb-0 flex-end">
            <span
              class="card-label calc"
              :class="{ 'required-value': !!!calculationData.advance }">
              Аванс
            </span>
          </v-col>
          <v-col :cols="12" :sm="7" class="pb-0">
            <v-text-field
              id="advance-adv"
              v-model="calculationData.advance"
              :error-messages="advanceErr"
              placeholder="0"
              hide-details
              dense
              @input="
                $event => {
                  advancePercent
                    ? maxNum('advance-adv', 100)
                    : changeInputVal('advance-adv', 'input', [
                        keepDigit({ float: 2 }),
                        formutSum,
                      ])
                  trackAdvance($event)
                }
              "
              @focusout="Input.restoreData('advance-adv')"
              @focusin="Input.hideData('advance-adv')"
              @blur="$v.calculation.advance.$touch()">
              <template #append>
                <v-tooltip
                  bottom
                  :text="
                    advancePercent ? 'вказати у гривнях' : 'вказати у вiдсотках'
                  ">
                  <template #activator="{ props }">
                    <v-btn
                      color="black"
                      class="text-white rounded-lg"
                      style="margin-top: 4px; opacity: 0.75"
                      size="x-small"
                      variant="flat"
                      v-bind="props"
                      @click="
                        () => {
                          advancePercent = !advancePercent
                          calculationData.advance = null
                        }
                      ">
                      {{ advancePercent ? '%' : 'грн' }}
                    </v-btn>
                  </template>
                </v-tooltip>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col :cols="12" :sm="5" class="pb-0 flex-end">
            <span
              class="card-label calc"
              :class="{ 'required-value': !!!calculationData.leasingTerm }">
              Термiн фiнансування
            </span>
          </v-col>
          <v-col :cols="12" :sm="7" class="pb-0">
            <v-text-field
              id="adv-months"
              v-model="leasingTerm"
              :error-messages="leasingTermErr"
              hide-details
              dense
              @input="
                () => {
                  maxNum('adv-months', maxMonthTerm)
                }
              "
              @blur="
                () => {
                  $v.calculation.leasingTerm.$touch()
                  calculationData.leasingTerm = leasingTerm
                  $emit('changeLP')
                }
              "
              @focusout="
                () => {
                  Input.restoreData('adv-months')
                  setLeasingTerm()
                }
              "
              @focusin="Input.hideData('adv-months')">
              <template #append-inner>
                <span class="append-text pt-0"> мiс </span>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="pt-1 align-center">
          <v-col :cols="12" :sm="5" class="pb-0 flex-end">
            <span
              class="card-label calc"
              :class="{ 'required-value': !!!calculationData.leasingRest }">
              Залишкова вартiсть
            </span>
          </v-col>
          <v-col :cols="12" :sm="7" class="pb-0">
            <v-text-field
              id="serv-rest-paym"
              v-model="calculationData.leasingRest"
              :error-messages="leasingRestErr"
              placeholder="Вкажіть значення у вiдстотках"
              dense
              hide-details
              @input="maxNum('serv-rest-paym', 100)"
              @focusout="Input.restoreData('serv-rest-paym')"
              @focusin="Input.hideData('serv-rest-paym')"
              @blur="$v.calculation.leasingRest.$touch()">
              <template #append-inner>
                <span class="append-text pt-0">%</span>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-col>
      <v-col :cols="12" :md="6" class="pt-0 pb-0 pl-3 pl-lg-10">
        <v-row class="mt-3 mb-5">
          <div class="projectRow">
            <svg
              class="mr-2"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="24"
              viewBox="0 0 20 24"
              fill="none">
              <path
                d="M2.74107 13.6154L4.29662 8.94231H15.704L17.2596 13.6154M15.704 18.8077C15.2915 18.8077 14.8958 18.6436 14.6041 18.3515C14.3124 18.0593 14.1485 17.6631 14.1485 17.25C14.1485 16.8369 14.3124 16.4407 14.6041 16.1485C14.8958 15.8564 15.2915 15.6923 15.704 15.6923C16.1166 15.6923 16.5123 15.8564 16.804 16.1485C17.0957 16.4407 17.2596 16.8369 17.2596 17.25C17.2596 17.6631 17.0957 18.0593 16.804 18.3515C16.5123 18.6436 16.1166 18.8077 15.704 18.8077ZM4.29662 18.8077C3.88406 18.8077 3.4884 18.6436 3.19668 18.3515C2.90495 18.0593 2.74107 17.6631 2.74107 17.25C2.74107 16.8369 2.90495 16.4407 3.19668 16.1485C3.4884 15.8564 3.88406 15.6923 4.29662 15.6923C4.70918 15.6923 5.10484 15.8564 5.39657 16.1485C5.68829 16.4407 5.85218 16.8369 5.85218 17.25C5.85218 17.6631 5.68829 18.0593 5.39657 18.3515C5.10484 18.6436 4.70918 18.8077 4.29662 18.8077ZM17.1766 8.42308C16.9692 7.82077 16.3885 7.38462 15.704 7.38462H4.29662C3.61218 7.38462 3.03144 7.82077 2.82403 8.42308L0.666992 14.6538V22.9615C0.666992 23.237 0.776251 23.5011 0.970733 23.6958C1.16522 23.8906 1.42899 24 1.70403 24H2.74107C3.01611 24 3.27988 23.8906 3.47436 23.6958C3.66884 23.5011 3.7781 23.237 3.7781 22.9615V21.9231H16.2226V22.9615C16.2226 23.237 16.3318 23.5011 16.5263 23.6958C16.7208 23.8906 16.9845 24 17.2596 24H18.2966C18.5717 24 18.8354 23.8906 19.0299 23.6958C19.2244 23.5011 19.3337 23.237 19.3337 22.9615V14.6538L17.1766 8.42308Z"
                fill="#08487A" />
              <path
                d="M15.8077 6.46154H18.2966L10.0003 0L1.70403 6.46154H4.19292L10.0003 1.93128L15.8077 6.46154ZM5.85218 1.29949V0H3.36329V3.23077L5.85218 1.29949Z"
                fill="#08487A" />
            </svg>
            <span class="projectTitle">Страхування</span>
          </div>
        </v-row>
        <v-row class="align-center">
          <v-col :cols="12" :sm="5" class="pt-0 pb-0 flex-end">
            <span
              class="card-label calc"
              :class="{
                'required-value': !!!calculationData.insuranceProgram,
              }">
              {{
                commercialProperty || equipment
                  ? 'Програма страхування:'
                  : 'Програма КАСКО'
              }}:
            </span>
          </v-col>
          <v-col :cols="12" :sm="7" class="pt-0 pb-0">
            <v-select
              v-model="calculationData.insuranceProgram"
              :error-messages="insuranceProgramErr"
              :items="
                directoryItems(
                  insurancePrograms,
                  calculationGeneralObject.insuranceProgramType
                )
              "
              item-title="name"
              item-value="value"
              placeholder="оберiть про-му"
              dense
              hide-details
              @change="trackInsurance($event)"
              @blur="$v.calculation.insuranceProgram.$touch()">
              <!--              <template #append>-->
              <!--                <span v-if="calculationData.programFSN"></span>-->
              <!--              </template>-->
            </v-select>
          </v-col>
        </v-row>
        <v-row v-if="!withoutInsurance" class="pt-1 align-center">
          <v-col :cols="12" :sm="5" class="pb-0 flex-end">
            <span
              class="card-label calc"
              :class="{
                'required-value': !!!calculationData.insuranceFranchise,
              }">
              Франшиза
            </span>
          </v-col>
          <v-col :cols="12" :sm="7" class="pb-0">
            <v-select
              v-model="calculationData.insuranceFranchise"
              :error-messages="insuranceFranchiseErr"
              :items="insuranceFranchises"
              item-title="name"
              item-value="value"
              dense
              hide-details
              @blur="$v.calculation.insuranceFranchise.$touch()">
              <!--              <template #append>-->
              <!--                <span v-if="calculationData.programFSN"></span>-->
              <!--              </template>-->
            </v-select>
          </v-col>
        </v-row>
        <v-scroll-x-transition>
          <v-row v-if="hasStock" class="pt-1 align-center">
            <v-col :cols="12" :sm="5" class="pb-0 flex-end">
              <span
                class="card-label calc"
                :class="{ 'required-value': !!!calculationData.stock }">
                Акція
              </span>
            </v-col>
            <v-col :cols="12" :sm="7" class="pb-0">
              <v-select
                v-model="calculationData.stock"
                :error-messages="stockErr"
                :items="
                  directoryItems(promotions, calculationGeneralObject.stockType)
                "
                item-title="name"
                item-value="id"
                :item-disabled="
                  item =>
                    (item.id >= 1 &&
                      item.id <= 6 &&
                      +calculationData.leasingTerm === 12) ||
                    (item.id === 5 && +calculationData.leasingTerm !== 60) ||
                    (item.id === 6 && +calculationData.leasingTerm !== 36)
                "
                dense
                hide-details
                @blur="$v.calculation.stock.$touch()">
                <template #item="{ item, props }">
                  <v-list-item v-bind="props">
                    <div
                      class="d-flex align-center"
                      style="line-height: 15px !important">
                      <v-tooltip
                        color="grey darken-3"
                        :text="
                          item.raw.id === 5
                            ? 'Страхування КАСКО на останні 2 роки в подарунок'
                            : 'КАСКО на 1 рік в подарунок'
                        ">
                        <template #activator="{ props }">
                          <span
                            v-if="item.raw.id === 5 || item.raw.id === 6"
                            v-bind="props"
                            style="font-size: 14px; color: black">
                            <v-icon style="z-index: 7" size="17" v-bind="props">
                              {{ 'mdi-information' }}
                            </v-icon>
                          </span>
                        </template>
                      </v-tooltip>
                    </div>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-scroll-x-transition>
        <div v-show="applyProgramFsn">
          <h3 class="crm-subtitle pt-0 mb-3">Доступнi кредити 5-7-9%</h3>
          <v-row class="align-center">
            <v-col :cols="12" :sm="5" class="pb-0 flex-end">
              <span class="card-label calc">Тип клієнта</span>
            </v-col>
            <v-col :cols="12" :sm="7" class="pb-0">
              <v-select
                v-model="calculationData.customerTypeObject"
                :items="selectItems.clientTypesFSN"
                :error-messages="customerTypeErr"
                item-title="name"
                item-value="id"
                return-object
                placeholder="Оберiть тип клієнта"
                dense
                hide-details
                @focus="asyncAction('addClientTypesFSN')"
                @blur="$v.calculation.customerTypeObject.$touch()">
              </v-select>
            </v-col>
          </v-row>
          <v-row class="align-center">
            <v-col :cols="12" :sm="5" class="pb-0 flex-end">
              <div class="d-flex align-center">
                <span class="card-label calc">UIRM 3м</span>
                <v-tooltip :text="`Актуальний курс: ${localNbuDeposit}`">
                  <template #activator="{ props }">
                    <v-btn v-bind="props" icon variant="text" size="x-small">
                      <v-icon
                        v-if="localNbuDeposit < calculationData.uird"
                        color="red">
                        {{ 'mdi-pan-down' }}
                      </v-icon>
                      <v-icon v-else color="green">
                        {{ 'mdi-pan-up' }}
                      </v-icon>
                    </v-btn>
                  </template>
                </v-tooltip>
              </div>
            </v-col>
            <v-col :cols="12" :sm="7" class="pb-0">
              <v-text-field
                :model-value="calculationData.uird"
                :error-messages="uirdErr"
                placeholder="Вкажіть значення"
                dense
                readonly
                hide-details
                @blur="$v.calculation.uird.$touch()">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="align-center">
            <v-col :cols="12" :sm="5" class="pb-0 flex-end">
              <span class="card-label calc"> Приріст робочих місць </span>
            </v-col>
            <v-col :cols="12" :sm="7" class="pb-0">
              <v-select
                v-model="calculationData.workplaceType"
                :error-messages="workplaceErr"
                placeholder="Вкажіть значення"
                dense
                return-object
                :items="
                  directoryItems('calcWorkplace', calculationData.workplaceType)
                "
                item-title="name"
                item-value="id"
                hide-details
                @focus="asyncAction('addCalcWorkplace')"
                @blur="$v.calculation.workplaceType.$touch()">
              </v-select>
            </v-col>
          </v-row>
          <v-row class="align-center">
            <v-col :cols="12" :sm="5" class="pb-0 flex-end">
              <span class="card-label calc"> Цілі </span>
            </v-col>
            <v-col :cols="12" :sm="7" class="pb-0">
              <v-select
                v-model="calculationData.targetType"
                :error-messages="targetErr"
                :items="
                  directoryItems('calcTargets', calculationData.targetType)
                "
                item-title="name"
                item-value="id"
                placeholder="Вкажіть значення"
                dense
                return-object
                hide-details
                @focus="asyncAction('addCalcTargets')"
                @blur="$v.calculation.targetType.$touch()">
              </v-select>
            </v-col>
          </v-row>
          <v-row class="align-center">
            <v-col :cols="12" :sm="5" class="pb-0 flex-end">
              <span class="card-label calc"> Новостворене підприємство </span>
            </v-col>
            <v-col :cols="12" :sm="7" class="pb-0">
              <v-checkbox
                v-model="calculationData.newCreated"
                :error-messages="newCreatedErr"
                :true-value="true"
                :false-value="false"
                dense
                hide-details
                color="red"
                @blur="$v.calculation.newCreated.$touch()">
              </v-checkbox>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-space-between mt-5">
      <v-col :cols="12" :md="6" class="pt-0 pb-0">
        <v-fade-transition hide-on-leave>
          <div
            v-show="
              !calculation.graphTypes.includes(CLOT.graphType.OPER_LEASING)
            ">
            <v-row class="mt-3 mb-5">
              <div class="projectRow">
                <svg
                  class="mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="19"
                  viewBox="0 0 20 19"
                  fill="none">
                  <path
                    d="M15.45 13.1802L20 5.31019V19.0002H0V1.0002H2V13.5402L7.5 4.0002L14 7.7802L18.24 0.450195L19.97 1.4502L14.74 10.5002L8.23 6.7502L2.31 17.0002H4.57L8.96 9.4402L15.45 13.1802Z"
                    fill="#08487A" />
                </svg>
                <span class="projectTitle">Графiк</span>
              </div>
            </v-row>
            <v-row class="pb-1">
              <v-col
                :cols="12"
                :sm="5"
                class="pb-0 flex-end"
                style="align-items: center !important">
                <span
                  class="card-label calc"
                  :class="{
                    'required-value': !!!calculationData.graphTypes.length,
                  }">
                  Тип графіку
                </span>
              </v-col>
              <v-col :cols="12" :sm="7" class="pb-0">
                <v-select
                  v-model="calculationData.graphTypes"
                  :error-messages="graphTypeErr"
                  :items="
                    selectItems.chartTypes.filter(
                      v => v.value !== 'oper-leasing'
                    )
                  "
                  item-title="name"
                  item-value="value"
                  placeholder="оберiть тип графіку"
                  multiple
                  chips
                  small-chips
                  deletable-chips
                  :list-props="{
                    density: 'compact',
                    slim: true,
                  }"
                  density="compact"
                  hide-details
                  @change="
                    $event => {
                      checkGraphsQuantity($event)
                      trackGraphs($event)
                    }
                  "
                  @blur="$v.calculation.graphTypes.$touch()">
                  <!--                  <template #append>-->
                  <!--                    <span v-if="calculationData.programFSN"></span>-->
                  <!--                  </template>-->
                </v-select>
              </v-col>
            </v-row>
            <div
              v-show="calculation.graphTypes.includes(CLOT.graphType.EVEN)"
              class="mt-3">
              <v-row>
                <v-col :cols="12" :sm="5" class="pb-0 flex-center">
                  <div
                    class="card-label calc"
                    :class="{
                      'required-value':
                        !!!calculationData.gainEvenGraphicMonths ||
                        !!!calculationData.gainEvenGraphicPercent,
                    }">
                    Класичний (посилення)
                  </div>
                </v-col>
                <v-col :cols="12" :sm="7" class="pb-0">
                  <v-row>
                    <v-col :cols="6" class="pt-0 pb-0">
                      <span class="label">Місяців</span>
                      <v-text-field
                        id="gt-gain-even"
                        v-model="calculationData.gainEvenGraphicMonths"
                        :error-messages="gainEvenGraphicMonthsErr"
                        class="pt-0 mt-0"
                        dense
                        placeholder="0"
                        hide-details
                        @input="maxNum('gt-gain-even', 72)"
                        @focusout="Input.restoreData('gt-gain-even')"
                        @focusin="Input.hideData('gt-gain-even')"
                        @blur="$v.calculation.gainEvenGraphicMonths.$touch()">
                      </v-text-field>
                    </v-col>
                    <v-col :cols="6" class="pt-0 pb-0">
                      <span class="label">Відсотків</span>
                      <v-text-field
                        id="gt-gain-even-perc"
                        v-model="calculationData.gainEvenGraphicPercent"
                        :error-messages="gainEvenGraphicPercentErr"
                        class="pt-0 mt-0"
                        placeholder="0"
                        dense
                        hide-details
                        @input="maxNum('gt-gain-even-perc', 100)"
                        @focusout="Input.restoreData('gt-gain-even-perc')"
                        @focusin="Input.hideData('gt-gain-even-perc')"
                        @blur="$v.calculation.gainEvenGraphicPercent.$touch()">
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </div>
            <div
              v-show="
                calculation.graphTypes.includes(CLOT.graphType.IRREGULAR) ||
                calculation.graphTypes.includes(CLOT.graphType.ANNUITY) ||
                calculation.graphTypes.includes(CLOT.graphType.ANNUITY_PLUS)
              "
              class="mt-3">
              <div
                v-show="
                  calculation.graphTypes.includes(CLOT.graphType.ANNUITY_PLUS)
                "
                class="mt-3">
                <v-row class="pt-1 align-center">
                  <v-col :cols="12" :sm="5" class="pb-0 flex-center">
                    <div
                      class="card-label calc"
                      :class="{ 'required-value': !!!calculationData.unpga }">
                      Ануїтет з посиленням
                    </div>
                  </v-col>
                  <v-col :cols="12" :sm="7" class="pt-0 pb-0">
                    <v-row>
                      <v-col :cols="12" :sm="6" class="pb-0">
                        <span class="label">УПНГА вiдсоткiв</span>
                        <v-text-field
                          id="gt-unpga-month"
                          v-model="calculationData.unpga"
                          :error-messages="unpgaMonthErr"
                          class="pt-0 mt-0"
                          placeholder="Введіть %"
                          dense
                          hide-details
                          @input="maxNum('gt-unpga-month', 100)"
                          @focusout="Input.restoreData('gt-unpga-month')"
                          @focusin="Input.hideData('gt-unpga-month')"
                          @blur="$v.calculation.unpga.$touch()">
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>
        </v-fade-transition>
      </v-col>
      <v-col :cols="12" :md="6" class="pt-0 pb-0 pl-3 pl-lg-10">
        <div
          v-show="!calculation.graphTypes.includes(CLOT.graphType.OPER_LEASING)"
          class="mt-12">
          <h3
            v-if="!$vuetify.breakpoint?.xs && !$vuetify.breakpoint?.sm"
            class="crm-subtitle">
            &nbsp;
          </h3>
          <v-row
            v-show="calculation.graphTypes.includes(CLOT.graphType.IRREGULAR)"
            class="pt-1 align-center">
            <v-col :cols="12" :sm="5" class="pt-0 pb-0 flex-end">
              <div
                class="card-label calc"
                :class="{
                  'required-value':
                    !!!calculationData.customGraphicType ||
                    (calculationData.customGraphicType &&
                      ((universalGain &&
                        !calculationData.customUniversalOption) ||
                        (stepGain &&
                          (!calculationData.customStepOptionFirst ||
                            !calculationData.customStepOptionMiddle)))),
                }">
                Iндивiдуальний
              </div>
            </v-col>
            <v-col :cols="12" :sm="7" class="pt-0 pb-0">
              <v-select
                v-model="calculationData.customGraphicType"
                :items="
                  directoryItems(
                    'customGraphicTypes',
                    calculation.customIrregularGraphicType
                  )
                "
                :error-messages="customGraphicTypeErr"
                item-title="name"
                item-value="id"
                placeholder="Оберiть зi списку"
                dense
                hide-details
                @blur="$v.calculation.customGraphicType.$touch()">
                <!--                <template #append>-->
                <!--                  <span v-if="calculationData.programFSN"></span>-->
                <!--                </template>-->
              </v-select>
            </v-col>
          </v-row>
          <v-row
            v-show="
              handTyping &&
              calculation.graphTypes.includes(CLOT.graphType.IRREGULAR)
            "
            class="mt-3">
            <v-col :cols="12" :sm="5" class="pt-0 pb-0 flex-end">
              <div class="card-label calc"></div>
            </v-col>
            <v-col :cols="12" :sm="7" class="pt-0 pb-0">
              <v-tooltip bottom text="Заповнюється в останню чергу!">
                <template #activator="{ props }">
                  <v-btn
                    class="mt-3"
                    variant="flat"
                    color="green"
                    :loading="seasonsGraphLoading"
                    v-bind="props"
                    @click="$emit('getFinancingAmount')">
                    Перейти до сезонного графіку
                  </v-btn>
                </template>
                <span>Заповнюється в останню чергу!</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row
            v-show="
              universalGain &&
              calculation.graphTypes.includes(CLOT.graphType.IRREGULAR)
            "
            class="pt-2">
            <v-col :cols="12" :sm="5" class="pt-0 pb-0 flex-end">
              <div class="card-label calc"></div>
            </v-col>
            <v-col :cols="12" :sm="7" class="pt-0 pb-0">
              <v-text-field
                id="ms-cust-un-opts"
                v-model="calculationData.customUniversalOption"
                :error-messages="customUniversalOptionErr"
                dense
                hide-details
                @input="maxNum('ms-cust-un-opts', 100)"
                @focusout="Input.restoreData('ms-cust-un-opts')"
                @focusin="Input.hideData('ms-cust-un-opts')"
                @blur="$v.calculation.customUniversalOption.$touch()">
                <template #append>
                  <span class="append-text-sm"> Посилюємо на % </span>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row
            v-show="
              stepGain &&
              calculation.graphTypes.includes(CLOT.graphType.IRREGULAR)
            "
            class="mt-3">
            <v-col :cols="12" :sm="5" class="pb-0 flex-end">
              <div class="card-label calc"></div>
            </v-col>
            <v-col :cols="12" :sm="7" class="pb-0">
              <v-row>
                <v-col :cols="12" :sm="4" class="mt-0">
                  <span class="label">1/3</span>
                  <v-text-field
                    id="ms-cust-opts-f"
                    v-model="calculationData.customStepOptionFirst"
                    :error-messages="customStepOptionFirstErr"
                    class="mt-0 pt-0"
                    placeholder="1/3"
                    dense
                    hide-details
                    @input="maxNum('ms-cust-opts-f', 100)"
                    @focusout="Input.restoreData('ms-cust-opts-f')"
                    @focusin="Input.hideData('ms-cust-opts-f')"
                    @blur="$v.calculation.customStepOptionFirst.$touch()">
                  </v-text-field>
                </v-col>
                <v-col :cols="12" :sm="4" class="mt-0">
                  <span class="label">2/3</span>
                  <v-text-field
                    id="ms-cust-opts-m"
                    v-model="calculationData.customStepOptionMiddle"
                    :error-messages="customStepOptionMiddleErr"
                    class="pt-0 mt-0"
                    placeholder="2/3"
                    dense
                    hide-details
                    @input="maxNum('ms-cust-opts-m', 100)"
                    @focusout="Input.restoreData('ms-cust-opts-m')"
                    @focusin="Input.hideData('ms-cust-opts-m')"
                    @blur="$v.calculation.customStepOptionMiddle.$touch()">
                  </v-text-field>
                </v-col>
                <v-col :cols="12" :sm="4" class="mt-0">
                  <span class="label">3/3</span>
                  <v-text-field
                    :model-value="custGraphSum ? 0 : customGraphicThird"
                    class="mt-0 pt-0"
                    placeholder="3/3"
                    dense
                    readonly
                    hide-details>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { setErrHandler, Input } from '@/utils/helperFunc'
import {
  maxNum,
  minNum,
  formutSum,
  keepDigit,
  changeInputVal,
} from '@/utils/formatFunc'
import { mapState } from 'vuex'
import {
  calculation as CLOT,
  leasingClient,
  financingCurrency,
  insuranceProgram,
} from '@/type-ids'
import { AGRICULTURAL, SPECIAL, TRAILER } from '../object-types'
import UsaidIcon from '@/assets/svg/calculation/usaid-icon.vue'
import { useSelect } from '@/utils/mixins/useSelect'
export default {
  emits: [
    'update:applyBenefits',
    'update:applyProgramFsn',
    'update:applyCorp',
    'update:editCommisionLk',
    'update:editIncomeLk',
    'changeLP',
    'getFinancingAmount',
    'getNbuDeposit',
    'shiftGraphTypes',
    'clearFsn',
  ],
  components: { UsaidIcon },
  setup() {
    return {
      ...useSelect(),
    }
  },
  props: {
    readOnly: { type: Boolean },
    calculation: { type: Object },
    truck: { type: Boolean, default: false },
    calculationGeneralObject: { type: Object },
    agricultural: { type: Boolean, default: false },
    car: { type: Boolean, default: false },
    busShort: { type: Boolean, default: false },
    special: { type: Boolean, default: false },
    equipment: { type: Boolean, default: false },
    airTransport: { type: Boolean, default: false },
    railwayTransport: { type: Boolean, default: false },
    waterTransport: { type: Boolean, default: false },
    commercialProperty: { type: Boolean, default: false },
    withoutInsurance: { type: Boolean, default: false },
    moto: { type: Boolean, default: false },
    loading: { type: Boolean },
    calcLoading: { type: Boolean, default: false },
    seasonsGraphLoading: { type: Boolean },
    filteredLP: { type: Array },
    corporativeLP: { type: Object },
    isNew: { type: Boolean, default: false },
    marksLpActive: { type: Boolean },
    truckMoreThenThreeTons: { type: Boolean },
    truckMassUpToThree: { type: Boolean },
    insuranceFranchises: { type: Array },
    busMoreTwenty: { type: Boolean },
    application: { type: Object },
    carFsnProgramMinSum: { type: Boolean },
    agriculturalFsnProgramMinSum: { type: Boolean },
    equipmentFsnProgramSum: { type: Boolean },
    specialFsnProgramSum: { type: Boolean },
    airDronFsnProgramSum: { type: Boolean },
    truckFsnProgramSum: { type: Boolean },
    grnTotalPrice: { type: String },
    setFsnDefaultValues: { type: Function },
    applyCorp: { type: Boolean },
    applyBenefits: { type: Boolean },
    applyProgramFsn: { type: Boolean },
    airDron: { type: Boolean },
    airDronId: { type: Number },
    v: { type: Object },
    isCreateCalculation: { type: Boolean },
    isEditCalculation: { type: Boolean },
    localNbuDeposit: { type: String },
  },
  data: () => ({
    localApplyCorp: false,
    advancePercent: true,
    baseMonth: 84,
    showGraphField: true,
    leasingTerm: null,
    Input: new Input(),
    CLOT, // calculation leasing object type
    leasingClient,
    financingCurrency,
  }),
  computed: {
    ...mapState({ selectItems: state => state.selectItems }),
    disableProgramInput() {
      return this.agricultural || this.special
    },
    calculationData() {
      return this.calculation
    },
    $v() {
      return this.v
    },
    hasStock() {
      const isLeasingTypeValid = this.calculation?.leasingObjectType?.id === 10
      const isNew = this.calculation?.isNew
      const advanceInRange =
        this.calculation?.advance >= 40 && this.calculation?.advance <= 70
      const leasingType = this.calculation?.leasingType === 'Фiнансовий'

      if (isLeasingTypeValid && isNew && advanceInRange && leasingType)
        return true
      return (
        this.calculation.leasingTerm >= 24 ||
        (this.agricultural && this.calculationData.programFSN)
      )
    },
    promotions() {
      const isLeasingTypeValid = this.calculation?.leasingObjectType?.id === 10
      const isNew = this.calculation?.isNew
      const leasingTermIsValid = this.calculation?.leasingTerm === '12'
      const advanceInRange =
        this.calculation?.advance >= 40 && this.calculation?.advance <= 70
      const leasingType = this.calculation?.leasingType === 'Фiнансовий'

      const arr = this.selectItems.promotions

      if (
        isLeasingTypeValid &&
        isNew &&
        leasingTermIsValid &&
        advanceInRange &&
        leasingType
      ) {
        return arr
      } else {
        return arr.filter(item => item.id !== 7)
      }
    },
    carFsnInvalidFields() {
      return (
        !this.isNew ||
        !this.car ||
        !this.carFsnProgramMinSum ||
        !this.finGraphType
      )
    },
    agriculturalFsnInvalidFields() {
      return (
        !this.isNew ||
        !this.agricultural ||
        !this.agriculturalFsnProgramMinSum ||
        !this.finGraphType
      )
    },
    truckFsnInvalid() {
      return (
        !this.isNew ||
        !this.truck ||
        !this.truckMoreThenThreeTons ||
        !this.truckFsnProgramSum ||
        !this.finGraphType
      )
    },
    equipmentFsnInvalid() {
      return (
        !this.isNew ||
        !this.equipment ||
        !this.equipmentFsnProgramSum ||
        !this.finGraphType
      )
    },
    programFsnAccess() {
      const clientTypeId = [3, 2].includes(this.application.clientTypeId)
      const eligibleObjectTypeTypes = [1, 4, 5, 6, 7, 10, 13]
      const isLeasingObjectTypeValid = eligibleObjectTypeTypes.includes(
        this.calculationData.leasingObjectType.id
      )

      const leasingTerm = +this.calculationData.leasingTerm
      const isLeasingTermValid = leasingTerm >= 12 && leasingTerm <= 60

      const isInsuranceProgramCascoValid =
        this.calculationData.insuranceProgram === 1

      const isLeasingTypeValid =
        this.calculationData.leasingType !== 'Оперативний'

      const isNotNewVehicle = !this.calculationData.isNew

      let validLeasingObject = true

      if (isNotNewVehicle) {
        const vehYear = this.calculationData.leasingObjectYear
        const curYear = new Date().getFullYear()
        const leasingTermYears = Math.floor(
          +this.calculationData.leasingTerm / 12
        )
        const yearSum = curYear - vehYear
        const leasingDate = yearSum + leasingTermYears

        validLeasingObject = leasingDate <= 7
      }

      return (
        isLeasingObjectTypeValid &&
        isLeasingTermValid &&
        isInsuranceProgramCascoValid &&
        isLeasingTypeValid &&
        clientTypeId &&
        validLeasingObject
      )
    },
    benefitsAccess() {
      const clientTypeId = [3, 2].includes(this.application.clientTypeId)
      const eligibleObjectTypeTypes = [1, 4, 5, 6, 7, 10, 13]
      const isLeasingObjectTypeValid = eligibleObjectTypeTypes.includes(
        this.calculationData.leasingObjectType.id
      )

      const leasingTerm = +this.calculationData.leasingTerm
      const isLeasingTermValid = leasingTerm >= 12 && leasingTerm <= 60

      const isInsuranceProgramCascoValid =
        this.calculationData.insuranceProgram === 1

      const isLeasingTypeValid =
        this.calculationData.leasingType !== 'Оперативний'

      const isNotNewVehicle = !this.calculationData.isNew

      let validLeasingObject = true

      if (isNotNewVehicle) {
        const vehYear = this.calculationData.leasingObjectYear
        const curYear = new Date().getFullYear()
        const leasingTermYears = Math.floor(
          +this.calculationData.leasingTerm / 12
        )
        const yearSum = curYear - vehYear
        const leasingDate = yearSum + leasingTermYears

        validLeasingObject = leasingDate <= 7
      }

      return (
        isLeasingObjectTypeValid &&
        isLeasingTermValid &&
        isInsuranceProgramCascoValid &&
        isLeasingTypeValid &&
        clientTypeId &&
        validLeasingObject
      )
    },

    specialFsnInvalid() {
      return (
        !this.isNew ||
        !this.special ||
        !this.specialFsnProgramSum ||
        !this.finGraphType
      )
    },
    airDronInvalid() {
      return (
        !this.isNew ||
        !this.airTransport ||
        !this.airDron ||
        !this.finGraphType ||
        !this.airDronFsnProgramSum
      )
    },
    showIn() {
      return this.advancePercent ? 'Ввести в грн' : 'Ввести у %'
    },
    handTyping() {
      return [
        CLOT.customGraphicType.SEASSON,
        CLOT.customGraphicType.SEASSON_FIELD_DAY,
      ].includes(this.calculation.customGraphicType)
    },
    factorialGain() {
      return (
        this.calculation.customGraphicType ===
        CLOT.customGraphicType.ENHANCED_FACTORIAL
      )
    },
    stepGain() {
      return (
        this.calculation.customGraphicType === CLOT.customGraphicType.STEPPED
      )
    },
    factorial() {
      return (
        this.calculation.customGraphicType === CLOT.customGraphicType.FACTORIAL
      )
    },
    universalGain() {
      return (
        this.calculation.customGraphicType ===
        CLOT.customGraphicType.UNIVERSAL_ENHANCED
      )
    },
    customGraphicTypeErr() {
      return setErrHandler(this.$v.calculation.customGraphicType)
    },
    customUniversalOptionErr() {
      return setErrHandler(this.$v.calculation.customUniversalOption)
    },
    customStepOptionFirstErr() {
      return setErrHandler(this.$v.calculation.customStepOptionFirst)
    },
    customStepOptionMiddleErr() {
      return setErrHandler(this.$v.calculation.customStepOptionMiddle)
    },
    workplaceErr() {
      return setErrHandler(this.$v.calculation.workplaceType)
    },
    targetErr() {
      return setErrHandler(this.$v.calculation.targetType)
    },
    newCreatedErr() {
      return setErrHandler(this.$v.calculation.newCreated)
    },
    customerTypeErr() {
      return setErrHandler(this.$v.calculation.customerTypeObject)
    },
    uirdErr() {
      return setErrHandler(this.$v.calculation.uird)
    },
    lPforUkrAuto() {
      if (
        [
          123, 131, 138, 41, 36, 37, 12, 285, 8, 53, 371, 16, 34, 46, 29, 136,
          134, 34, 451,
        ].includes(this.calculation?.leasedAssertMark?.id) &&
        (this.car || this.truck)
      ) {
        return true
      }
      return false
    },
    leasingProgramState() {
      const hydromek =
        (this.calculation?.leasedAssertMark?.name || '').toUpperCase() ===
        'Hidromek'.toUpperCase()
      if (this.isNew && hydromek) return false
      if (this.special && hydromek && !this.calculation.isNew) return false
      if (this.special && hydromek && this.calculation.isNew) return true
      if (this.special && !hydromek && this.calculation.isNew) return false
      if (this.oldWorkCar) return true
      if (this.agricultural && this.calculation.isNew) return false
      if (this.busShort && this.busMoreTwenty && this.calculation.isNew)
        return false
      if (this.truck && this.truckMoreThenThreeTons) return false
      if (this.marksLpActive && this.calculation.isNew) return false
      if (
        [44, 29, 138].includes(this.calculation?.leasedAssertMark?.id) &&
        (this.car || this.truck)
      ) {
        return false
      }
      if (this.calculation.isNew && this.lPforUkrAuto) {
        return false
      }

      return true
    },
    seasonMnthArrErr() {
      return setErrHandler(this.$v.calculation.seasonMnthArr)
    },
    custFirstGraphNum() {
      return parseInt(this.calculation.customStepOptionFirst) || 0
    },
    custSecondGraphNum() {
      return parseInt(this.calculation.customStepOptionMiddle) || 0
    },
    customGraphicThird() {
      return 100 - this.custFirstGraphNum - this.custSecondGraphNum
    },
    custGraphSum() {
      return this.custFirstGraphNum + this.custSecondGraphNum >= 100
    },
    currencyErr() {
      return setErrHandler(this.$v.calculation.currency)
    },
    advanceErr() {
      return setErrHandler(this.$v.calculation.advance)
    },
    leasingProgramErr() {
      return setErrHandler(this.$v.calculation.leasingProgram)
    },
    leasingTermErr() {
      return setErrHandler(this.$v.calculation.leasingTerm)
    },
    leasingRestErr() {
      return setErrHandler(this.$v.calculation.leasingRest)
    },
    graphTypeErr() {
      return setErrHandler(this.$v.calculation.graphTypes)
    },
    gainEvenGraphicMonthsErr() {
      return setErrHandler(this.$v.calculation.gainEvenGraphicMonths)
    },
    gainEvenGraphicPercentErr() {
      return setErrHandler(this.$v.calculation.gainEvenGraphicPercent)
    },
    unpgaMonthErr() {
      return setErrHandler(this.$v.calculation.unpga)
    },
    stockErr() {
      return setErrHandler(this.$v.calculation.stock)
    },
    oldWorkCar() {
      const c = this.calculationData
      return (
        !c.isNew &&
        (c.leasingObjectType.id === AGRICULTURAL ||
          c.truckMass === 2 ||
          c.leasingObjectType.id === SPECIAL ||
          c.leasingObjectType.id === TRAILER)
      )
    },
    itemYearsObj() {
      const maxMonths = this.selectItems.itemYearsExpanded
      let months = this.baseMonth

      return maxMonths.map((v, i) => {
        if (i > 5 && !this.oldWorkCar) {
          months -= 12
        }
        return { year: v, month: months }
      })
    },
    maxMonthTerm() {
      return (
        this.itemYearsObj.find(
          v => v.year === this.calculation.leasingObjectYear
        )?.month || 84
      )
    },
    minMonthTerm() {
      return this.calculationData.leasingType === 'Оперативний' ? 3 : 12
    },
    insuranceProgramErr() {
      return setErrHandler(this.$v.calculation.insuranceProgram)
    },
    insuranceFranchiseErr() {
      return setErrHandler(this.$v.calculation.insuranceFranchise)
    },
    insuranceTermErr() {
      return setErrHandler(this.$v.calculation.insuranceTerm)
    },
    insurancePrograms() {
      const drone = this.airDron
      const truckLessThenTreeTons =
        this.calculationData.truckMass === CLOT.truckMass.LESS_THAN_THREE
      const truckMoreThenThreeTons =
        this.calculationData.truckMass === CLOT.truckMass.MORE_THAN_THREE

      if (
        this.calculation.isNew &&
        (this.car || (this.truck && truckLessThenTreeTons))
      ) {
        return this.selectItems.insurancePrograms.filter(p => {
          return [
            insuranceProgram.STANDARD,
            insuranceProgram.CAUTIOUS,
            insuranceProgram.TAXI,
            insuranceProgram.WITHOUT_INSURANCE,
          ].includes(p.value)
        })
      }
      if (
        !this.calculation.isNew &&
        (this.car || (this.truck && truckLessThenTreeTons))
      ) {
        return this.selectItems.insurancePrograms.filter(p => {
          return [
            insuranceProgram.STANDARD,
            insuranceProgram.CAUTIOUS,
            insuranceProgram.TAXI,
            insuranceProgram.WITHOUT_INSURANCE,
          ].includes(p.value)
        })
      }
      if (this.truck && truckMoreThenThreeTons) {
        const programs = [
          ...JSON.parse(JSON.stringify(this.selectItems.insurancePrograms)),
        ]
        if (programs[1]) {
          programs[1].name = 'Обережний (тільки при повній масі ТЗ до 4 тонн)'
        }
        return programs
      }
      if (this.airTransport && drone) {
        return this.selectItems.insurancePrograms.filter(
          p => p.value === insuranceProgram.STANDARD
        )
      }
      if (this.car || this.truck) {
        return this.selectItems.insurancePrograms
      }
      if (this.commercialProperty) {
        return this.selectItems.insurancePrograms.filter(p =>
          [
            insuranceProgram.STANDARD,
            insuranceProgram.WITHOUT_INSURANCE,
          ].includes(p.value)
        )
      }

      return this.selectItems.insurancePrograms.filter(p =>
        [
          insuranceProgram.STANDARD,
          insuranceProgram.WITHOUT_INSURANCE,
        ].includes(p.value)
      )
    },
    finGraphType() {
      return this.calculationData.leasingType === 'Фiнансовий'
    },
  },
  methods: {
    maxNum,
    minNum,
    formutSum,
    keepDigit,
    changeInputVal,
    setLeasingTerm() {
      if (this.calculationData.leasingTerm > this.maxMonthTerm) {
        this.calculationData.leasingTerm = this.maxMonthTerm.toString()
      }
      if (this.calculationData.leasingTerm < this.minMonthTerm) {
        this.calculationData.leasingTerm = this.minMonthTerm.toString()
      }
    },
    trackGraphs(graphs) {
      switch (true) {
        case !graphs.includes(CLOT.graphType.EVEN):
          return this.clearEven()
        case !graphs.includes(CLOT.graphType.ANNUITY_PLUS):
          return this.clearAnnuityPlus()
        case !graphs.includes(CLOT.graphType.IRREGULAR):
          return this.clearIrregular()
      }
    },
    trackInsurance(id) {
      if (id === insuranceProgram.WITHOUT_INSURANCE) {
        this.calculationData.insuranceCascoManipulate = '0'
        this.calculationData.insuranceFranchise = 1
      }
    },
    trackAdvance(e) {
      if (e && parseFloat(e) < 20) {
        this.calculationData.prenumerando = 1
      } else if (e && parseFloat(e) >= 20) {
        this.calculationData.prenumerando = 2
      }
    },
    clearGraphs() {
      this.clearEven()
      this.clearAnnuityPlus()
      this.clearIrregular()
    },
    clearEven() {
      this.calculationData.gainEvenGraphicMonths = '0'
      this.calculationData.gainEvenGraphicPercent = '0'
    },
    clearAnnuityPlus() {
      this.calculationData.unpga = '12'
    },
    clearIrregular() {
      this.calculationData.customGraphicType = 4
      this.calculationData.customUniversalOption = null
      this.calculationData.customStepOptionFirst = null
      this.calculationData.customStepOptionMiddle = null
    },
    setUnspr(e) {
      if (!e) return
      const value = parseInt(e)
      if (this.calculationData.leasingType === 'Оперативний') {
        this.calculationData.unsprMonth = value - 1
      } else {
        this.calculationData.unsprMonth = value <= 12 ? '8' : '11'
      }
    },
    checkGraphsQuantity() {
      setTimeout(() => {
        if (this.calculation.graphTypes.length > 3) {
          this.$emit('shiftGraphTypes')
        }
      }, 0)
    },
    changeCustInpVal(id, value) {
      const el = document.getElementById(id)
      const event = new Event('input', { bubbles: true })
      el.value = value
      el.dispatchEvent(event)
    },
    handleGraphType(e) {
      if (e === 'Оперативний') {
        this.calculationData.graphTypes.splice(0)
        this.calculationData.graphTypes.push('oper-leasing')
        this.calculationData.programFSN = false
        // this.clearFsn()
      } else {
        this.calculationData.graphTypes.splice(0)
      }

      this.clearGraphs()
    },
    trackFsn(e) {
      if (e) {
        this.calculationData.programFSN = true
        this.$emit('update:applyProgramFsn', e)
        //   this.$emit('update:applyCorp', false)
        //   this.$emit('update:applyBenefits', false)

        // const setCarFsnValues = () => {
        //   // this.calculationData.increaseAmount = '7'
        //
        //   console.log('track fsn')
        //   this.calculationData.workplace = null // TODO FSN
        //   // this.calculationData.uird = null // TODO FSN
        //   this.calculationData.target = null // TODO FSN
        //   this.calculationData.newCreated = false // TODO FSN
        //   this.calculationData.paymentPf = 1
        //   this.calculationData.additionalEquipment = false
        //   this.calculationData.addEquipmentSum = null
        //   this.calculationData.equipmentDiscount = null
        //   this.calculationData.currency = 'UAH'
        this.$emit('update:applyCorp', false)
        this.$emit('update:applyBenefits', false)
        this.$emit('changeLP')
        //   this.calculationData.advance = '30'
        //   this.calculationData.leasingTerm = '48'
        //   this.calculationData.leasingRest = '0'
        //   this.calculationData.graphTypes.splice(0)
        //   this.calculationData.graphTypes.push('irregular')
        //   this.calculationData.customGraphicType = 5
        //   this.calculationData.customUniversalOption = '100'
        //   this.calculationData.insuranceProgram = 2
        //   this.calculationData.insuranceFranchise = 4
        //   this.calculationData.stock = 2
        //   this.calculationData.vehicleOwnerTax = 2
        //   this.calculationData.gpsTracker = 2
        //   this.calculationData.gpsBeacon = 1
        //   this.calculationData.beaconAmount = '1'
        //   this.calculationData.patrolCardsSupport = 2
        //   this.calculationData.patrolCardQuantity = '0'
        //   this.calculationData.preliminaryFinancing = 0
        //   this.calculationData.preFinancingDays = '0'
        //   this.calculationData.preFinancingAmount = '0'
        //   this.calculationData.holidays = 0
        //   this.calculationData.prenumerando = 2
        //   this.calculationData.oneTimeCommission = 0
        //   this.calculationData.unsprMonth = '6'
        //   this.calculationData.rateManipulate = '0'
        //   this.calculationData.commisionManipulate = '0'
        //   this.calculationData.insuranceCascoManipulate = '0'
        //   this.calculationData.hiddenCommisionGr = '0'
        //   this.calculationData.hiddenCommision = '0'
        //   this.calculationData['сomment-income-lk'] = null
        //   this.calculationData.commissionLk = '0'
        //   this.calculationData.commissionLkPr = '0'
        //   this.calculationData.rateReduction = '0'
        this.calculationData['comment-commission-lk'] = null
        this.$emit('update:editCommisionLk', false)
        this.$emit('update:editIncomeLk', false)
        //   this.calculationData.agentCommision = '1'
        //   this.calculationData.gainEvenGraphicPercent = '0'
        //   this.calculationData.gainEvenGraphicMonths = '0'
        //   this.calculationData.trailersSum = 0
        //   this.calculationData.equipSumWithDiscount = 0
        //   this.calculationData.customStepOptionFirst = null
        //   this.calculationData.customStepOptionMiddle = null
        // }
        // const setAgriculturalValues = () => {
        //   // this.calculationData.increaseAmount = '7'
        //   this.calculationData.paymentPf = 2
        //   this.calculationData.additionalEquipment = false
        //   this.calculationData.addEquipmentSum = null
        //   this.calculationData.equipmentDiscount = null
        //   this.calculationData.currency = 'UAH'
        //   this.$emit('update:applyCorp', false)
        //   this.$emit('update:applyBenefits', false)
        //   this.calculationData.advance = '50'
        //   this.calculationData.leasingTerm = '36'
        //   this.calculationData.leasingRest = '0'
        //   this.calculationData.graphTypes.splice(0)
        //   this.calculationData.graphTypes.push('irregular')
        //   this.calculationData.customGraphicType = 5
        //   this.calculationData.customUniversalOption = '100'
        //   this.calculationData.insuranceProgram = 1
        //   this.calculationData.insuranceFranchise = 4
        //   this.calculationData.stock = 2
        //   this.calculationData.vehicleOwnerTax = 2
        //   this.calculationData.gpsTracker = 2
        //   this.calculationData.gpsBeacon = 1
        //   this.calculationData.beaconAmount = '1'
        //   this.calculationData.patrolCardsSupport = 2
        //   this.calculationData.patrolCardQuantity = '0'
        //   this.calculationData.preliminaryFinancing = 0
        //   this.calculationData.preFinancingDays = '0'
        //   this.calculationData.preFinancingAmount = '0'
        //   this.calculationData.assistService = 1
        //   this.calculationData.holidays = 0
        //   this.calculationData.prenumerando = 2
        //   this.calculationData.oneTimeCommission = 0
        //   this.calculationData.unsprMonth = '3'
        //   this.calculationData.rateManipulate = '0'
        //   this.calculationData.commisionManipulate = '0'
        //   this.calculationData.insuranceCascoManipulate = '0'
        //   this.calculationData.hiddenCommisionGr = '0'
        //   this.calculationData.hiddenCommision = '0'
        //   this.calculationData['сomment-income-lk'] = null
        // this.calculationData.commissionLk = '0'
        // this.calculationData.commissionLkPr = '0'
        //   this.calculationData.rateReduction = '0'
        // this.calculationData['comment-commission-lk'] = null
        // this.$emit('update:editCommisionLk', false)
        // this.$emit('update:editIncomeLk', false)
        //   this.calculationData.agentCommision = '1'
        //   this.calculationData.gainEvenGraphicPercent = '0'
        //   this.calculationData.gainEvenGraphicMonths = '0'
        //   this.calculationData.trailersSum = 0
        //   this.calculationData.equipSumWithDiscount = 0
        //   this.calculationData.customStepOptionFirst = null
        //   this.calculationData.customStepOptionMiddle = null
        // }
        //
        // const setTruckFsnValues = () => {
        //   // this.calculationData.increaseAmount = '7'
        //   this.calculationData.paymentPf = 2
        //   this.calculationData.additionalEquipment = false
        //   this.calculationData.addEquipmentSum = null
        //   this.calculationData.equipmentDiscount = null
        //   this.calculationData.currency = 'UAH'
        //   this.$emit('update:applyCorp', false)
        //   this.$emit('update:applyBenefits', false)
        //   this.calculationData.advance = '50'
        //   this.calculationData.leasingTerm = '36'
        //   this.calculationData.leasingRest = '0'
        //   this.calculationData.graphTypes.splice(0)
        //   this.calculationData.graphTypes.push('irregular')
        //   this.calculationData.customGraphicType = 5
        //   this.calculationData.customUniversalOption = '100'
        //   this.calculationData.insuranceProgram = 1
        //   this.calculationData.insuranceFranchise = 4
        //   this.calculationData.stock = 2
        //   this.calculationData.vehicleOwnerTax = 2
        //   this.calculationData.gpsTracker = 2
        //   this.calculationData.gpsBeacon = 1
        //   this.calculationData.beaconAmount = '1'
        //   this.calculationData.patrolCardsSupport = 2
        //   this.calculationData.patrolCardQuantity = '0'
        //   this.calculationData.preliminaryFinancing = 0
        //   this.calculationData.preFinancingDays = '0'
        //   this.calculationData.preFinancingAmount = '0'
        //   this.calculationData.assistService = 1
        //   this.calculationData.holidays = 0
        //   this.calculationData.prenumerando = 2
        //   this.calculationData.oneTimeCommission = 0
        //   this.calculationData.unsprMonth = '6'
        //   this.calculationData.rateManipulate = '0'
        //   this.calculationData.commisionManipulate = '0'
        //   this.calculationData.insuranceCascoManipulate = '0'
        //   this.calculationData.hiddenCommisionGr = '0'
        //   this.calculationData.hiddenCommision = '0'
        //   this.calculationData['сomment-income-lk'] = null
        //   this.calculationData.commissionLk = '0'
        //   this.calculationData.commissionLkPr = '0'
        //   this.calculationData.rateReduction = '0'
        //   this.calculationData['comment-commission-lk'] = null
        //   this.$emit('update:editCommisionLk', false)
        //   this.$emit('update:editIncomeLk', false)
        //   this.calculationData.agentCommision = '1'
        //   this.calculationData.gainEvenGraphicPercent = '0'
        //   this.calculationData.gainEvenGraphicMonths = '0'
        //   this.calculationData.trailersSum = 0
        //   this.calculationData.equipSumWithDiscount = 0
        //   this.calculationData.customStepOptionFirst = null
        //   this.calculationData.customStepOptionMiddle = null
        // }
        //
        // const setEquipmentValues = () => {
        //   // this.calculationData.increaseAmount = '10'
        //   this.calculationData.paymentPf = 2
        //   this.calculationData.additionalEquipment = false
        //   this.calculationData.addEquipmentSum = null
        //   this.calculationData.equipmentDiscount = null
        //   this.calculationData.currency = 'UAH'
        //   this.$emit('update:applyCorp', false)
        //   this.$emit('update:applyBenefits', false)
        //   this.calculationData.leasingProgram = 1
        //   this.calculationData.advance = '50'
        //   this.calculationData.leasingTerm = '24'
        //   this.calculationData.leasingRest = '0'
        //   this.calculationData.graphTypes.splice(0)
        //   this.calculationData.graphTypes.push('irregular')
        //   this.calculationData.customGraphicType = 5
        //   this.calculationData.customUniversalOption = '100'
        //   this.calculationData.insuranceProgram = 1
        //   this.calculationData.insuranceFranchise = 4
        //   this.calculationData.stock = 2
        //   this.calculationData.vehicleOwnerTax = 2
        //   this.calculationData.gpsTracker = 2
        //   this.calculationData.gpsBeacon = 1
        //   this.calculationData.beaconAmount = '1'
        //   this.calculationData.patrolCardsSupport = 2
        //   this.calculationData.patrolCardQuantity = '0'
        //   this.calculationData.preliminaryFinancing = 0
        //   this.calculationData.preFinancingDays = '0'
        //   this.calculationData.preFinancingAmount = '0'
        //   this.calculationData.assistService = 1
        //   this.calculationData.holidays = 0
        //   this.calculationData.prenumerando = 2
        //   this.calculationData.oneTimeCommission = 0
        //   this.calculationData.unsprMonth = '6'
        //   this.calculationData.rateManipulate = '0'
        //   this.calculationData.commisionManipulate = '0'
        //   this.calculationData.insuranceCascoManipulate = '0'
        //   this.calculationData.hiddenCommisionGr = '0'
        //   this.calculationData.hiddenCommision = '0'
        //   this.calculationData['сomment-income-lk'] = null
        //   this.calculationData.commissionLk = '0'
        //   this.calculationData.commissionLkPr = '0'
        //   this.calculationData.rateReduction = '0'
        //   this.calculationData['comment-commission-lk'] = null
        //   this.$emit('update:editCommisionLk', false)
        //   this.$emit('update:editIncomeLk', false)
        //   this.calculationData.agentCommision = '1'
        //   this.calculationData.gainEvenGraphicPercent = '0'
        //   this.calculationData.gainEvenGraphicMonths = '0'
        //   this.calculationData.trailersSum = 0
        //   this.calculationData.equipSumWithDiscount = 0
        //   this.calculationData.customStepOptionFirst = null
        //   this.calculationData.customStepOptionMiddle = null
        // }
        //
        // const setSpecialValues = () => {
        //   // this.calculationData.increaseAmount = '10'
        //   this.calculationData.paymentPf = 2
        //   this.calculationData.additionalEquipment = false
        //   this.calculationData.addEquipmentSum = null
        //   this.calculationData.equipmentDiscount = null
        //   this.calculationData.currency = 'UAH'
        //   this.$emit('update:applyCorp', false)
        //   this.$emit('update:applyBenefits', false)
        //   this.calculationData.leasingProgram = 1
        //   this.calculationData.advance = '50'
        //   this.calculationData.leasingTerm = '24'
        //   this.calculationData.leasingRest = '0'
        //   this.calculationData.graphTypes.splice(0)
        //   this.calculationData.graphTypes.push('irregular')
        //   this.calculationData.customGraphicType = 5
        //   this.calculationData.customUniversalOption = '100'
        //   this.calculationData.insuranceProgram = 1
        //   this.calculationData.insuranceFranchise = 4
        //   this.calculationData.stock = 2
        //   this.calculationData.vehicleOwnerTax = 2
        //   this.calculationData.gpsTracker = 2
        //   this.calculationData.gpsBeacon = 1
        //   this.calculationData.beaconAmount = '1'
        //   this.calculationData.patrolCardsSupport = 2
        //   this.calculationData.patrolCardQuantity = '0'
        //   this.calculationData.preliminaryFinancing = 0
        //   this.calculationData.preFinancingDays = '0'
        //   this.calculationData.preFinancingAmount = '0'
        //   this.calculationData.assistService = 1
        //   this.calculationData.holidays = 0
        //   this.calculationData.prenumerando = 2
        //   this.calculationData.oneTimeCommission = 0
        //   this.calculationData.unsprMonth = '6'
        //   this.calculationData.rateManipulate = '0'
        //   this.calculationData.commisionManipulate = '0'
        //   this.calculationData.insuranceCascoManipulate = '0'
        //   this.calculationData.hiddenCommisionGr = '0'
        //   this.calculationData.hiddenCommision = '0'
        //   this.calculationData['сomment-income-lk'] = null
        //   this.calculationData.commissionLk = '0'
        //   this.calculationData.commissionLkPr = '0'
        //   this.calculationData.rateReduction = '0'
        //   this.calculationData['comment-commission-lk'] = null
        //   this.$emit('update:editCommisionLk', false)
        //   this.$emit('update:editIncomeLk', false)
        //   this.calculationData.agentCommision = '1'
        //   this.calculationData.gainEvenGraphicPercent = '0'
        //   this.calculationData.gainEvenGraphicMonths = '0'
        //   this.calculationData.trailersSum = 0
        //   this.calculationData.equipSumWithDiscount = 0
        //   this.calculationData.customStepOptionFirst = null
        //   this.calculationData.customStepOptionMiddle = null
        // }
        //
        // const setAirDronValues = () => {
        //   this.calculationData.dkpDiscount = '10'
        //   this.calculationData.rateReduction = '10'
        //   // this.calculationData.increaseAmount = '0'
        //   this.calculationData.paymentPf = 2
        //   this.calculationData.additionalEquipment = false
        //   this.calculationData.addEquipmentSum = null
        //   this.calculationData.equipmentDiscount = null
        //   this.calculationData.currency = 'UAH'
        //   this.$emit('update:applyCorp', false)
        //   this.$emit('update:applyBenefits', false)
        //   this.calculationData.advance = '30'
        //   this.calculationData.leasingTerm = '12'
        //   this.calculationData.leasingRest = '0'
        //   this.calculationData.graphTypes.splice(0)
        //   this.calculationData.graphTypes.push('even')
        //   this.calculationData.customGraphicType = 4
        //   this.calculationData.customUniversalOption = null
        //   this.calculationData.insuranceProgram = 1
        //   this.calculationData.insuranceFranchise = 4
        //   this.calculationData.stock = 2
        //   this.calculationData.vehicleOwnerTax = 2
        //   this.calculationData.gpsTracker = 2
        //   this.calculationData.gpsBeacon = 0
        //   this.calculationData.beaconAmount = '0'
        //   this.calculationData.patrolCardsSupport = 2
        //   this.calculationData.patrolCardQuantity = '0'
        //   this.calculationData.preliminaryFinancing = 0
        //   this.calculationData.preFinancingDays = '0'
        //   this.calculationData.preFinancingAmount = '0'
        //   this.calculationData.assistService = 1
        //   this.calculationData.holidays = 0
        //   this.calculationData.prenumerando = 2
        //   this.calculationData.oneTimeCommission = 0
        //   this.calculationData.unsprMonth = '3'
        //   this.calculationData.rateManipulate = '0'
        //   this.calculationData.commisionManipulate = '0'
        //   this.calculationData.insuranceCascoManipulate = '0'
        //   this.calculationData.hiddenCommisionGr = '0'
        //   this.calculationData.hiddenCommision = '0'
        //   this.calculationData['сomment-income-lk'] = null
        //   this.calculationData.commissionLk = '0'
        //   this.calculationData.commissionLkPr = '0'
        //   this.calculationData['comment-commission-lk'] = null
        //   this.$emit('update:editCommisionLk', false)
        //   this.$emit('update:editIncomeLk', false)
        //   this.calculationData.agentCommision = '1'
        //   this.calculationData.gainEvenGraphicPercent = '0'
        //   this.calculationData.gainEvenGraphicMonths = '0'
        //   this.calculationData.trailersSum = 0
        //   this.calculationData.equipSumWithDiscount = 0
        //   this.calculationData.customStepOptionFirst = null
        //   this.calculationData.customStepOptionMiddle = null
        // }

        // const setValues = () => {
        //   switch (true) {
        //     case this.car:
        //       return setCarFsnValues()
        //     case this.agricultural:
        //       return setAgriculturalValues()
        //     case this.truck:
        //       return setTruckFsnValues()
        //     case this.equipment:
        //       return setEquipmentValues()
        //     case this.special:
        //       return setSpecialValues()
        //     case this.airDron:
        //       return setAirDronValues()
        //   }
        // }

        // const setAntenor = () => {
        //   const lo = this.agricultural || this.truck || this.special
        //   const currentLoTotalSum = parseFloat(
        //     this.keepDigit(this.grnTotalPrice || '0')
        //   )
        //   const antenorMinSum = 1200000
        //   if (
        //     currentLoTotalSum >= antenorMinSum &&
        //     this.calculationData.programFSN &&
        //     lo
        //   ) {
        //     this.calculationData.gpsTracker = 1
        //   }
        // }
        //
        // setValues()
        // setAntenor()
      } else {
        this.$emit('update:applyProgramFsn', false)
        this.$emit('changeLP')
        this.calculationData.programFSN = false
      }
    },
  },
  watch: {
    'calculationData.programFSN': function (state) {
      if (!state) {
        this.$emit('clearFsn')
        this.setFsnDefaultValues()
        this.$emit('update:applyBenefits', false)
      } else {
        this.$emit('update:applyProgramFsn', true)
        this.$emit('changeLP')
      }
    },
    'calculationData.leasingTerm': function (term) {
      this.leasingTerm = term
      this.setUnspr(term)
      if (this.calculationData.hasMaintenance) {
        this.calculationData.maintenanceRequest.contractTerm = term
      }
    },
    'calculationData.currency': function (currency) {
      if (this.calculationData.hasMaintenance) {
        this.calculationData.maintenanceRequest.paymentsBind = currency
      }
    },
    maxMonthTerm() {
      this.setLeasingTerm()
    },
    minMonthTerm() {
      this.setLeasingTerm()
    },
    hasStock(val) {
      if (!val) this.calculationData.stock = 1
    },
    custFirstGraphNum(val) {
      if (val + this.custSecondGraphNum > 100) {
        const elVal = 100 - val
        if (elVal < 0) return

        this.changeCustInpVal('ms-cust-opts-m', elVal)
      }
    },
    custSecondGraphNum(val) {
      if (val + this.custFirstGraphNum > 100) {
        const elVal = 100 - val
        if (elVal < 0) return
        this.changeCustInpVal('ms-cust-opts-f', elVal)
      }
    },
    corporativeST(val) {
      this.$emit('update:applyCorp', val)

      this.$emit('update:applyBenefits', !val)
    },
    applyCorp(val) {
      if (val) {
        this.$emit('changeLP', this.corporativeLP.programId)
        this.$emit('update:applyBenefits', !val)
      } else {
        this.$emit('changeLP')
      }
    },
    localApplyCorp(newVal) {
      this.$emit('update:applyCorp', newVal)
    },
    applyBenefits(val, oldValue) {
      this.calculationData.hiddenCommisionGr = '0'
      this.calculationData.hiddenCommision = 0
      this.calculationData.commissionLk = '0'
      this.calculationData.commissionLkPr = 0
      this.calculationData.additionalAb = '0'
      this.calculationData.additionalAbPr = 0

      if (val === true && oldValue === false) {
        this.$emit('update:applyProgramFsn', false)
      }

      this.$emit('changeLP')
      this.$emit('update:applyCorp', false)
    },
    applyProgramFsn() {
      this.calculationData.hiddenCommisionGr = '0'
      this.calculationData.hiddenCommision = 0
      this.calculationData.commissionLk = '0'
      this.calculationData.commissionLkPr = 0
      this.calculationData.additionalAb = '0'
      this.calculationData.additionalAbPr = 0

      this.$emit('changeLP')
      this.$emit('update:applyCorp', false)
    },
    benefitsAccess(v) {
      if (!v) {
        this.$emit('update:applyBenefits', false)
      }
    },
    programFsnAccess(v) {
      if (!v) {
        this.$emit('update:applyProgramFsn', false)
      }
    },
  },
  created() {
    this.leasingTerm = this.calculationData.leasingTerm
    this.localApplyCorp = this.applyCorp
    this.calculationData.programFSN = this.applyProgramFsn
    this.$store.dispatch('addPromotions')
    this.$store.dispatch('addInsurancePrograms')
    this.$store.dispatch('addCustomGraphicTypes')
    this.$store.dispatch('addChartTypes')
  },
}
</script>

<style scoped>
.projectRow {
  display: flex;
  align-items: center;
  padding: 0 12px;
}

.benefits-info {
  z-index: 4 !important;
}

.projectTitle {
  display: block;
  color: #08487a;
  font-weight: 600;
  font-size: 16px;
}
.v-application .v-list .v-list-item {
  height: auto !important;
}
</style>
