<template>
  <div>
    <SectionLoader v-if="loading"></SectionLoader>
    <v-fade-transition hide-on-leave>
      <div v-if="!loading" style="background: #fff">
        <div>
          <div style="position: relative">
            <v-card-text class="pb-1">
              <v-row>
                <v-col cols="12" md="10" sm="12">
                  <Fields :fields="fields" :type="2" direction="row">
                    <template #number_field>
                      <EditableField
                        v-model="agreement.number"
                        label="№ Договору"
                        style="margin-top: -5px"
                        readonly
                        @blur="v$.agreement.number.$touch()" />
                    </template>
                  </Fields>
                </v-col>
                <v-col cols="12" md="2" sm="12">
                  <div class="align-center d-flex gap-3 justify-end">
                    <v-checkbox
                      v-if="agreement.statusId !== 5"
                      :model-value="agreement.isCancelContract"
                      color="#fc7247"
                      hide-details
                      dense
                      :readonly="
                        (!admin && readonly) || agreement.isCancelContract
                      "
                      @update:model-value="setCancel">
                      <template #append>
                        <div class="label ml-6">
                          {{
                            agreement.isCancelContract
                              ? 'Анульовано'
                              : 'Анулювати'
                          }}
                        </div>
                      </template>
                    </v-checkbox>
                    <TableCrud
                      style="z-index: 40000000"
                      class="ml-2"
                      :actionsArray="[
                        {
                          action: openPrintedForm,
                          text: 'Договiр застави',
                          icon: 'mdi-file',
                          loading: true,
                        },
                      ]"
                      customIcon="Printer">
                    </TableCrud>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </div>
          <div>
            <StatusBar
              class="mb-2"
              :currentStatus="agreement.statusId"
              :submit="setMortStatus"
              :statuses="filteredStatuses"
              spreadItems
              small>
            </StatusBar>
          </div>
          <div style="position: relative">
            <div class="d-flex align-center" style="margin-left: 10px">
              <div>
                <span class="label">Тип договору</span>
                <v-select
                  v-model="agreement.pledgeTypeId"
                  :error-messages="typeIdErr"
                  :items="directoryItems('pledgeTypes', agreement.pledgeType)"
                  item-title="name"
                  item-value="id"
                  :readonly="readonly"
                  placeholder="Оберiть тип"
                  @focus="asyncAction('addPledgeTypes')"
                  @blur="v$.agreement.pledgeTypeId.$touch()">
                </v-select>
              </div>
              <div class="pl-3">
                <v-checkbox
                  v-model="agreement.isNotarization"
                  hide-details
                  :readonly="readonly"
                  color="#fc7247">
                  <template #label>
                    <span style="font-size: 12px">Нотаріальне завірення</span>
                  </template>
                </v-checkbox>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-3">
          <v-tabs
            v-model="currentTab"
            background-color="grey darken-2"
            show-arrows
            class="dkpTabs"
            color="white"
            dark
            slider-color="#fc7247"
            dense
            :height="$vuetify.breakpoint?.xs ? null : 38">
            <v-tab
              replace
              :to="{
                name: 'mortgagor-parties-details',
                params: { id: $route.params.id },
              }">
              Реквізити сторін
            </v-tab>
            <v-tab
              replace
              :to="{
                name: 'mortgagor-pledge-property',
                params: { id: $route.params.id },
              }">
              Об'єкти застави майна
            </v-tab>
            <v-tab
              replace
              :to="{
                name: 'mortgagor-documents',
                params: { id: $route.params.id },
              }">
              Документи
            </v-tab>
            <v-tab
              replace
              :to="{
                name: 'mortgagor-history',
                params: { id: $route.params.id },
              }">
              Історія
            </v-tab>
          </v-tabs>
          <div style="position: relative">
            <router-view
              :agreement="agreement"
              :v="v$"
              :readonly="readonly"
              :mortgagorContact="mortgagorContact"
              :mortgagorContractor="mortgagorContractor">
            </router-view>
            <ActionButtons
              :confirm="submit"
              :cancel="getMortgagorAgreement"
              :confirmLoading="submitLoading"
              :cancelDisable="submitLoading || agreementState === cache"
              :confirmDisable="agreementState === cache">
            </ActionButtons>
          </div>
        </div>
      </div>
    </v-fade-transition>
  </div>
</template>
<script>
import SectionLoader from '@/components/section-loader.vue'
import ActionButtons from '@/components/action-buttons.vue'
import {
  LODescription as getLoDescription,
  getBeautyNum,
  setCache,
  setErrHandler,
  getEntityName,
  v$Notify,
  backDate,
  toFormatDate,
  getContactInitials,
  openDocument,
} from '@/utils/helperFunc'
import {
  urlUpdateMortgagorAgreement,
  urlGetMortgagorAgreement,
  urlSetCancelContractPledge,
  urlGetMortgagorPrintedForm,
  urlSetMortgragorStatus,
} from '@/pages/request'
import { mapState } from 'vuex'
import { required, minLength } from '@vuelidate/validators'

import StatusBar from '@/components/StatusBar.vue'
import EditableField from '@/components/EditableField.vue'
import Fields from '@/components/Fields.vue'
import { useVuelidate } from '@vuelidate/core'
import { useSelect } from '@/utils/mixins/useSelect'
import TableCrud from '@/components/table-crud.vue'
import { confirm } from 'best-modules/plugins'
export default {
  components: {
    TableCrud,
    Fields,
    EditableField,
    StatusBar,
    SectionLoader,
    ActionButtons,
  },
  setup() {
    return { v$: useVuelidate(), ...useSelect() }
  },
  validations() {
    return {
      agreement: {
        date: { required, minLength: minLength(10) },
        pledgeTypeId: { required },
        creditorSignatoryId: { required },
        creditorBasisId: { required },
        mortgagorSignatories: {
          required,
          minLength: minLength(1),
          $each: {
            contactId: { required },
            basisId: { required },
          },
        },
      },
    }
  },
  data: () => ({
    agreement: {},
    submitLoading: false,
    currentTab: 0,
    loading: false,
    cache: null,
    printedForm: {},
  }),
  computed: {
    ...mapState({
      user: state => state.user,
      selectItems: state => state.selectItems,
    }),
    fields() {
      return [
        {
          label: 'Дата договору',
          value: toFormatDate(this.agreement.date, true),
        },
        { label: '№ Договору', slot: 'number' },
        { label: 'Боржник', value: this.debtor },
      ]
    },
    agreementId() {
      return this.$route?.params?.id
    },
    debtor() {
      if (this.agreement.debtor) {
        const debtor =
          this.agreement?.debtor?.contact || this.agreement?.debtor?.contractor
        return (
          debtor?.shortName ||
          getContactInitials(debtor?.fullName, { byName: true })
        )
      }
      return '---'
    },
    readonly() {
      return this.agreement.readOnly
    },
    agreementState() {
      return this.setCache([this.agreement])
    },
    admin() {
      return this.user.roleId === 1
    },
    LO() {
      return this.getLoDescription(this.calculation)
    },
    typeIdErr() {
      return this.setErrHandler(this.v$?.agreement?.pledgeTypeId)
    },
    route() {
      return this.$route.name
    },
    mortgagorContact() {
      return [1, 3].includes(this.agreement.mortgagorTypeId)
    },
    mortgagorContractor() {
      return this.agreement.mortgagorTypeId === 2
    },
    filteredStatuses() {
      const filteredStatuses = this.selectItems.gcStatuses.filter(item =>
        this.agreement.isCancelContract ? true : item.id !== 6
      )
      return filteredStatuses.map(s => {
        let showCheckIcon
        if (this.agreement.statusId === 6) {
          showCheckIcon = s.id === 6
        }

        return { ...s, showCheckIcon }
      })
    },
  },
  methods: {
    setCache,
    getEntityName,
    setErrHandler,

    getLoDescription,
    urlSetMortgragorStatus,
    urlSetCancelContractPledge,
    urlUpdateMortgagorAgreement,
    urlGetMortgagorAgreement,
    urlGetMortgagorPrintedForm,
    openPrintedForm() {
      return this.$axios
        .get(urlGetMortgagorPrintedForm(this.agreement.id))
        .then(res => {
          if (res.data.message) {
            return this.$setSnackbar({
              text: res.data.message,
              color: 'warning',
            })
          }
          return openDocument({
            text: 'Договір застави',
            url: res.data.url,
          })
        })
    },
    async setMortStatus(statusId) {
      this.loading = true

      const currentStatus = this.agreement.statusId

      if (currentStatus === 5) {
        return this.$setSnackbar({
          text: 'Договiр з статусу пiдписаного не може бути змiнено',
          color: 'warning',
        })
      }
      if (
        [4, 5].includes(statusId) &&
        !this.agreement.agreements?.some(a => !a.isAgreed)
      ) {
        return this.$setSnackbar({
          text: 'Договiр повинен бути завiзований всiма учасниками',
          color: 'warning',
        })
      }
      if (statusId < currentStatus || statusId > currentStatus + 1) {
        return this.$setSnackbar({ text: 'Дія неможлива', color: 'error' })
      }

      if ([2, 3].includes(statusId)) {
        try {
          const statusNames = {
            2: 'Підписано Заставодавцем',
            3: 'Візування',
          }
          await confirm({
            text: `Ви впевнені що хочете перевести договір в статус "${statusNames[statusId]}"?`,
          })
        } catch (e) {
          return
        }
      }

      return this.$axios
        .post(this.urlSetMortgragorStatus(this.agreement.id), {
          statusId: statusId,
        })
        .then(res => {
          if (res.data.message) {
            this.$setSnackbar({ text: res.data.message, color: 'warning' })
          } else this.$setSnackbar({ text: 'Статус успiшно змiнено' })
          this.getMortgagorAgreement()
          return res
        })
        .catch(err => this.$err(err, () => (this.loading = false)))
    },
    setCancel() {
      confirm({
        text: 'Ви дійсно бажаєте анулювати договір?',
      }).then(() => {
        return this.$axios
          .post(urlSetCancelContractPledge(this.agreement.id), {
            isCancelContract: true,
          })
          .then(res => {
            this.$setSnackbar({ text: 'Оновлено' })
            Object.assign(this.agreement, res.data)
            this.setCache([this.agreement])
            return res
          })
      })
    },
    setBreadScrumb() {
      const name = `${this.getEntityName(this.agreement.mortgagor)} ${
        this.agreement.number
      }`
      this.$store.commit('setBreadScrumbPart', [null, null, name, null])
    },
    getMortgagorAgreement() {
      this.loading = true
      return this.$axios
        .get(this.urlGetMortgagorAgreement(this.agreementId))
        .then(res => {
          this.setAgreement(res.data)
          this.setBreadScrumb()
          return res
        })
        .catch(this.$err)
        .finally(() => (this.loading = false))
    },
    setAgreement(obj) {
      obj.pledgeObjectPrice = obj.pledgeObjectPrice
        ? getBeautyNum(obj.pledgeObjectPrice, { float: 2 })
        : ''
      obj.mortgagorSignatories ??= [{ contactId: null, basisId: null }]
      Object.assign(this.agreement, obj)
      this.cache = this.setCache([this.agreement])
    },
    submit() {
      if (this.v$.$invalid) {
        this.v$.$anyError
        this.v$.$touch()
        v$Notify(this.v$.agreement, 'mortgagorAgreement')
        return Promise.resolve()
      }
      this.submitLoading = true
      const requestObj = Object.clone(this.agreement)
      requestObj.pledgeObjectPrice =
        parseFloat(requestObj.pledgeObjectPrice?.replace(/\s/g, '') || 0) ||
        null
      requestObj.date = backDate(requestObj.date)
      if (requestObj.mortgagorSignatories)
        requestObj.mortgagorSignatories = requestObj.mortgagorSignatories.map(
          s => ({
            contactId: s.contactId,
            basisId: s.basisId,
          })
        )

      return this.$axios
        .post(this.urlUpdateMortgagorAgreement(this.agreementId), requestObj)
        .then(res => {
          if (res?.data?.message) throw new Error(res.data.message)
          this.setAgreement(res.data)
          this.$setSnackbar({ text: 'Данi оновлено' })
          return res
        })
        .catch(this.$err)
        .finally(() => (this.submitLoading = false))
    },
  },
  watch: {
    '$route.name': function () {
      this.setBreadScrumb()
    },
  },
  created() {
    this.getMortgagorAgreement()
    this.$store.dispatch('addGCStatuses')
  },
}
</script>

<style scoped>
.dkpTabs .v-slide-group__wrapper {
  background: #fff !important;
}
</style>
