<template>
  <v-row>
    <v-col v-if="addKlpHead || deleteKlpHead" cols="12" md="12" sm="12">
      <span class="label">Голова КЛП</span>
      <v-autocomplete
        v-model="item.headId"
        :items="selectItems.allUsers"
        :error-messages="klpHeadErr"
        item-value="id"
        :item-title="getKlpName"
        placeholder="Оберіть зі списку"
        hide-details
        :readonly="deleteKlpHead"
        :disabled="deleteKlpHead"
        @blur="v$.item.headId.$touch()">
      </v-autocomplete>
    </v-col>
    <v-col v-if="chooseKlpParticipants" cols="12" md="12" sm="12">
      <span class="label">Члени КЛП</span>
      <v-autocomplete
        v-model="item.members"
        :items="selectItems.allUsers"
        :error-messages="klpParticipantsErr"
        item-value="id"
        :item-title="getKlpName"
        hide-details
        placeholder="Оберіть зі списку"
        multiple
        @blur="v$.item.members.$touch()">
      </v-autocomplete>
    </v-col>
    <v-col v-if="chooseKlpInvited" cols="12" md="12" sm="12">
      <span class="label">Запрошені до КЛП</span>
      <v-autocomplete
        v-model="item.invitations"
        :items="selectItems.allUsers"
        :error-messages="klpInvitedErr"
        item-value="id"
        :item-title="getKlpName"
        hide-details
        placeholder="Оберіть зі списку"
        multiple
        @blur="v$.item.invitations.$touch()">
      </v-autocomplete>
    </v-col>
    <h3 v-if="deleteKlpHead">
      {{ 'Голову КЛП буде видалено!' }}
    </h3>
  </v-row>
</template>

<script>
import { urlKlpInvations } from '@/pages/request'
import { required } from '@vuelidate/validators'
import { mapState } from 'vuex'

import { getContactInitials, setErrHandler } from '@/utils/helperFunc'
import { useVuelidate } from '@vuelidate/core'
import Item from '@/components/car-api/Item.vue'
export default {
  components: { Item },
  setup() {
    return { v$: useVuelidate() }
  },
  props: { dialog: { type: Object } },
  validations() {
    const headId = this.addKlpHead || this.deleteKlpHead ? { required } : true
    const members = this.chooseKlpParticipants ? { required } : true
    const invitations = this.chooseKlpInvited ? { required } : true
    return {
      item: {
        headId: headId,
        members: members,
        invitations: invitations,
      },
    }
  },
  data() {
    return {
      item: {
        headId: '',
        members: [],
        invitations: [],
      },
    }
  },
  computed: {
    ...mapState({ selectItems: state => state.selectItems }),
    validation() {
      return { v$: this.v$.item, name: 'klp' }
    },
    addKlpHead() {
      return this.dialog.action === 'addKlpHead'
    },
    deleteKlpHead() {
      return this.dialog.action === 'deleteKlpHead'
    },
    chooseKlpParticipants() {
      return this.dialog.action === 'chooseKlpParticipants'
    },
    chooseKlpInvited() {
      return this.dialog.action === 'chooseKlpInvited'
    },
    klpHeadErr() {
      return setErrHandler(this.v$.item?.headId)
    },
    klpParticipantsErr() {
      return setErrHandler(this.v$.item?.members)
    },
    klpInvitedErr() {
      return setErrHandler(this.v$.item?.invitations)
    },
  },
  methods: {
    getContactInitials,
    setErrHandler,
    urlKlpInvations,
    getKlpName(item) {
      if (item) {
        return `${getContactInitials(item)} ${item?.post}`
      } else return null
    },
    handleParticipantsSelection() {
      this.item.members.forEach(memberid => {
        this.participantsArray.push({ typeId: 2, invitedId: memberid })
      })
    },

    handleInvitedSelection() {
      this.item.invitations.forEach(invitedId => {
        this.invitedArray.push({ typeId: 3, invitedId: invitedId })
      })
    },

    submit() {
      const dataArray = []

      if (this.addKlpHead || this.deleteKlpHead) {
        dataArray.push({ typeId: 1, invitedId: this.item.headId })
      } else {
        if (this.chooseKlpParticipants && this.item.members.length > 0) {
          this.item.members.forEach(invitedId => {
            dataArray.push({ typeId: 2, invitedId: invitedId })
          })
        }

        if (this.chooseKlpInvited && this.item.invitations.length > 0) {
          this.item.invitations.forEach(invitedId => {
            dataArray.push({ typeId: 3, invitedId: invitedId })
          })
        }
      }

      return this.$axios
        .post(urlKlpInvations(this.dialog.params.project.id), dataArray)
        .then(res => {
          if (res) {
            this.$setSnackbar({ text: 'Дані оновлено' })
          }
        })
    },
  },
  created() {
    this.$store.dispatch('addAllUsers')
  },
}
</script>
