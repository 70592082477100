<template>
  <div class="service-contract">
    <SectionLoader v-if="loading"> </SectionLoader>
    <v-fade-transition hide-on-leave>
      <div
        v-if="!loading"
        class="service-contract-wrapper"
        style="background: #fff">
        <v-card-text class="pt-3 pb-1">
          <v-row>
            <v-col cols="12" md="10" sm="12">
              <Fields
                :fields="fields"
                :type="2"
                :direction="$vuetify.breakpoint?.xs ? 'column' : 'row'">
                <template #leasingObject_label>
                  <div class="d-flex align-center gap-1">
                    <v-icon color="#08487a">{{ 'mdi-car' }}</v-icon>
                    <span class="font-weight-bold">Об'єкт лізингу</span>
                  </div>
                </template>
              </Fields>
            </v-col>
            <v-col cols="12" md="2" sm="12">
              <div class="d-flex align-center justify-end gap-3">
                <v-checkbox
                  v-if="serviceContract.statusId !== 5"
                  :model-value="serviceContract.isCancelContract"
                  color="#fc7247"
                  class="mt-0"
                  hide-details
                  dense
                  :readonly="
                    (!admin && readonly) || serviceContract.isCancelContract
                  "
                  @update:model-value="setCancel">
                  <template #append>
                    <div class="label ml-6">
                      {{
                        serviceContract.isCancelContract
                          ? 'Анульовано'
                          : 'Анулювати'
                      }}
                    </div>
                  </template>
                </v-checkbox>
                <div>
                  <v-tooltip bottom text="Інформація з 1с">
                    <template #activator="{ props }">
                      <v-btn
                        icon
                        class="rounded-circle"
                        density="comfortable"
                        v-bind="props"
                        @click="setDialog">
                        <oneCIcon />
                      </v-btn>
                    </template>
                    <span>Інформація з 1с</span>
                  </v-tooltip>
                  <div v-if="showloadingSub" class="background-loader">
                    <div class="loader">
                      <div class="rect1"></div>
                      <div class="rect2"></div>
                      <div class="rect3"></div>
                    </div>
                  </div>
                  <v-dialog
                    v-if="oneCDialog"
                    v-model="oneCDialog"
                    width="450px">
                    <div style="height: 85vh; overflow: scroll">
                      <div style="padding: 10px; background: #fff">
                        <div class="d-flex justify-end">
                          <v-btn icon size="small" @click="oneCDialog = false">
                            <CloseIcon />
                          </v-btn>
                        </div>
                        <div class="pa-2" style="background: #fff">
                          <ModalInfo
                            :oneCId:="serviceContract.oneCId"
                            :info="oneCInfo" />
                        </div>
                      </div>
                    </div>
                  </v-dialog>
                </div>
                <TableCrud
                  :actionsArray="printedFormActions"
                  custom-icon="Printer">
                </TableCrud>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <StatusBar
          v-if="!loading"
          class="mb-2"
          :currentStatus="serviceContract.statusId"
          :submit="id => sendService(id).then(() => setServiceStatus(id))"
          :statuses="filteredStatuses"
          spreadItems
          hasReject
          small>
        </StatusBar>
        <v-tabs
          v-model="currentTab"
          show-arrows
          color="white"
          dark
          slider-color="#fc7247"
          dense
          class="dkpTabs ml-3"
          :direction="$vuetify.breakpoint?.xs ? 'vertical' : 'horizontal'"
          :height="$vuetify.breakpoint?.xs ? null : 38">
          <v-tab
            replace
            :to="{
              name: 'service-parties-details',
              params: { id: $route.params.id },
            }">
            Реквізити сторін
          </v-tab>
          <v-tab
            replace
            :to="{
              name: 'service-leasing-object',
              params: { id: $route.params.id },
            }">
            Предмет лізингу
          </v-tab>
          <v-tab
            replace
            :to="{
              name: 'service-payment-history',
              params: { id: $route.params.id },
            }">
            Графік платежів
          </v-tab>
          <v-tab
            replace
            :to="{
              name: 'service-agreements',
              params: { id: $route.params.id },
            }">
            Договори
          </v-tab>
          <v-tab
            replace
            :to="{
              name: 'service-connections',
              params: { id: $route.params.id },
            }">
            Зв'язки
          </v-tab>
          <v-tab
            replace
            :to="{ name: 'service-history', params: { id: $route.params.id } }">
            Історія
          </v-tab>
        </v-tabs>
        <RouterView
          :serviceContract="serviceContract"
          :endorsement="endorsement"
          :readonly="readonly"
          :contracts="[contractConnections]">
        </RouterView>
        <v-card-actions
          v-show="!loading && $route.name === 'service-parties-details'">
          <ActionButtons
            class="mt-10"
            :confirm="() => submit()"
            :cancel="getContract"
            :confirmLoading="submitLoading"
            :cancelDisable="submitLoading || contractState === cache"
            :confirmDisable="contractState === cache">
          </ActionButtons>
        </v-card-actions>
      </div>
    </v-fade-transition>
  </div>
</template>
<script>
import ActionButtons from '@/components/action-buttons.vue'
import SectionLoader from '@/components/section-loader.vue'
import TableCrud from '@/components/table-crud.vue'
import StatusBar from '@/components/StatusBar.vue'
import Fields from '@/components/Fields.vue'
import { mapState } from 'vuex'
import { required, minLength } from '@vuelidate/validators'
import {
  urlSetSignatoryServiceContract,
  urlGetAdvancePrintForm,
  urlGetServiceContractPdf,
  urlSetDflStatus,
  urlDFLUploadDocument,
  urlSetCancelContractDfl,
  urlDownLoadDflDocs,
  urlGetOneCDflInfo,
  urlCreateServiceContract,
  urlSetCancelContractService,
  urlSetServiceStatus,
} from '@/pages/request'
import {
  LODescription as getLoDescription,
  setCache,
  getAddress,
  v$Notify,
  backDate,
  toFormatDate,
  openDocument,
} from '@/utils/helperFunc'
import { formutSum } from '@/utils/formatFunc'

import { generateDFLPdf } from './pdf/dfl-form'
import { generateAdditionalPdf } from './pdf/dflAdditionalPage'
import { dataURLtoFile } from '@/components/print-forms'
import oneCIcon from '@/assets/svg/oneCIcon.vue'
import CloseIcon from '@/assets/svg/close-icon.vue'
import ModalInfo from '@/components/OneC/Modal-info.vue'
import { useVuelidate } from '@vuelidate/core'
import { useSelect } from '@/utils/mixins/useSelect'
import { setSnackbar, confirm } from 'best-modules/plugins'

export default {
  components: {
    ModalInfo,
    CloseIcon,
    oneCIcon,
    SectionLoader,
    ActionButtons,
    TableCrud,
    StatusBar,
    Fields,
  },
  setup() {
    return { v$: useVuelidate(), ...useSelect() }
  },
  validations() {
    return {
      serviceContract: {
        number: { required },
        agreementDate: { required, minLength: minLength(10) },
        lessorSignatoryId: { required },
        lessorBasisId: { required },
        lesseeSignatories: {
          required,
          minLength: minLength(1),
          $each: {
            contactId: { required },
            basisId: { required },
          },
        },
      },
    }
  },
  data: () => ({
    submitLoading: false,
    advancePrintData: {},
    DFLPrintData: {},
    currentTab: 0,
    dflPrintedForms: [],
    oneCInfo: null,
    oneCDialog: false,
    loadingSub: false,
    showloadingSub: false,
    loadingRequest: false,
  }),
  computed: {
    ...mapState({
      user: state => state.user,
      serviceContract: state => state.contracts.serviceContract,
      loading: state => state.contracts.serviceContractLoading,
      selectItems: state => state.selectItems,
      calculation: state => state.contracts.serviceContract.calculation,
      cache: state => state.contracts.serviceCache,
      lessee: state => state.contracts.serviceContract.lessee,
      lessor: state => state.contracts.serviceContract.lessor,
    }),
    filteredStatuses() {
      const filteredStatuses = this.selectItems.serviceContractStatuses.filter(
        item => (this.serviceContract.isCancelContract ? true : item.id !== 6)
      )
      return filteredStatuses.map(s => {
        let showCheckIcon
        if (this.serviceContract.statusId === 6) {
          showCheckIcon = s.id === 6
        }

        return { ...s, showCheckIcon }
      })
    },
    oneCInfoText() {
      return this.formatObject(this.oneCInfo)
    },
    readonly() {
      return this.serviceContract?.readOnly
    },
    contractConnections() {
      return this.serviceContract.contract
    },
    dflPdfParams() {
      return [
        this.DFLPrintData,
        {
          lessee: this.lessee,
          calculation: this.calculation,
        },
      ]
    },
    admin() {
      return this.user.roleId === 1
    },
    endorsement() {
      return this.serviceContract.statusId === 3
    },
    contractId() {
      return this.$route?.params?.id
    },
    LO() {
      return this.getLoDescription(this.calculation)
    },
    contractState() {
      return this.setCache([this.serviceContract])
    },
    route() {
      return this.$route.name
    },
    /* validations */
    lesseeName() {
      const contactLesee = this.lessee?.contact?.fullName
      const contractorLesee = this.lessee?.contractor?.shortName
      return (
        (contactLesee || contractorLesee || '---') +
        ' - ' +
        this.serviceContract.number
      )
    },
    selectExtraAgreement() {
      return (
        this.serviceContract?.extraAgreements?.map(obj => ({
          action: () => this.openAgreementSingle(obj.id),
          text: `Додаткова угода ${obj.number}`,
          icon: 'mdi-text-box-check',
        })) || []
      )
    },
    selectServiceContract() {
      return (
        this.serviceContract?.serviceContracts?.map(obj => ({
          action: () => this.openServiceContractSingle(obj.id),
          text: `Сервiсний договiр ${obj.number}`,
          icon: 'mdi-text-box-check',
        })) || []
      )
    },
    printedFormActions() {
      const forms = this.dflPrintedForms.map(pf => {
        return {
          action: () => this.downloadAnyPrintedForm(pf),
          text: pf.name,
          icon: 'mdi-file',
        }
      })
      return [...this.pfForms, ...forms]
    },
    pfForms() {
      return [
        {
          action: () => this.openServiceContractPdf(),
          text: 'Договір сервісу',
          icon: 'mdi-file-pdf',
        },
      ]
    },
    fields() {
      return [
        { label: '№ Договору', value: this.serviceContract.number },
        {
          label: 'Дата договору',
          value: toFormatDate(this.serviceContract.agreementDate, true),
        },
        {
          label: `${this.serviceContract.contract?.type?.name || 'ДФЛ'}`,
          value: `${this.serviceContract.contract?.number}`,
          class: { link: true },
          click: () => {
            this.$router.push({
              name: `${
                this.serviceContract.contract.typeId === 1
                  ? 'dfl-contract'
                  : 'dkp-contract'
              }`,
              params: { id: this.serviceContract.contract.id },
            })
          },
        },
        {
          label: "Об'єкт лізингу",
          value: this.LO.description({ detailed: true }),
        },
      ]
    },
  },
  methods: {
    setCache,
    getAddress,
    getLoDescription,
    urlSetSignatoryServiceContract,
    urlSetCancelContractDfl,
    urlGetAdvancePrintForm,
    urlSetServiceStatus,

    formutSum,
    backDate,
    urlGetServiceContractPdf,
    urlSetDflStatus,
    generateDFLPdf,
    generateAdditionalPdf,
    urlCreateServiceContract,
    formatObject(obj, indent = 0) {
      if (!obj) {
        return ''
      }

      const indentString = '  '.repeat(indent)
      const lines = []

      for (const [key, value] of Object.entries(obj)) {
        if (typeof value === 'object' && value !== null) {
          const nestedObj = this.formatObject(value, indent + 1)
          lines.push(`${indentString}${key}:`)
          lines.push(nestedObj)
        } else {
          lines.push(`${indentString}${key}: ${value}`)
        }
      }

      return lines.join('\n')
    },
    setDialog() {
      if (!this.serviceContract.oneCId) {
        this.$setSnackbar({ text: "Зв'язку з 1с не знайдено", color: 'error' })
        return
      }

      this.showloadingSub = true

      setTimeout(() => {
        this.getOneCInfo(this.serviceContract.oneCId).then(() => {
          this.oneCDialog = true
          this.showloadingSub = false
        })
      }, 700)
    },
    getOneCInfo(id) {
      const req = {
        oneCId: id,
      }

      if (!id) {
        return this.$setSnackbar({
          text: "Зв'язку з 1с не знайдено",
          color: 'error',
        })
      }

      this.loadingSub = true

      return this.$axios.post(urlGetOneCDflInfo(), req).then(res => {
        this.oneCInfo = res.data
        this.loadingSub = false
      })
    },
    downloadAnyPrintedForm(pf) {
      return this.$axios
        .get(urlDownLoadDflDocs(this.$route.params.id, pf.key))
        .then(res => {
          if (res.data.message) {
            return setSnackbar({ text: res.data.message, color: 'error' })
          }
          openDocument({
            url: res.data.url,
            text: pf.name,
          })
        })
    },
    async openServiceContractPdf() {
      if (this.v$.$invalid) {
        return this.v$.$touch()
      }
      await this.getDFLFormData()
      if (this.DFLPrintData.url) {
        const url = this.DFLPrintData.url
        const ext = url.split('.').pop().toLowerCase()
        const text = `${this.serviceContract.number} - ${this.serviceContract.lesseeName}.${ext}`

        return openDocument({ url, text })
      }
      this.generateDFLPdf(...this.dflPdfParams).open()
      this.lessee.leasingClientType === 1 &&
        this.generateAdditionalPdf(this.DFLPrintData).open()
    },
    setCancel() {
      confirm({
        text: 'Ви дійсно бажаєте анулювати договір?',
      }).then(() => {
        this.loadingRequest = true
        return this.$axios
          .post(urlSetCancelContractService(this.serviceContract.id), {
            isCancelContract: true,
          })
          .then(res => {
            this.$setSnackbar({ text: 'Оновлено' })
            Object.assign(this.serviceContract, res.data)
            this.setCache([this.serviceContract])
            this.setStoreCache()
            this.loadingRequest = false
            return res
          })
      })
    },
    async setServiceStatus(statusId) {
      const currentStatus = this.serviceContract.statusId

      if (currentStatus === 5) {
        return this.$setSnackbar({
          text: 'Договiр з статусу пiдписаного не може бути змiнено',
          color: 'warning',
        })
      }
      if (
        [4, 5].includes(statusId) &&
        !this.serviceContract.agreements?.some(a => !a.isAgreed)
      ) {
        return this.$setSnackbar({
          text: 'Договiр повинен бути завiзований всiма учасниками',
          color: 'warning',
        })
      }
      if (statusId < currentStatus || statusId > currentStatus + 1) {
        return this.$setSnackbar({ text: 'Дія неможлива', color: 'error' })
      }

      if ([2, 3].includes(statusId)) {
        try {
          const statusNames = {
            2: 'Підписано клієнтом',
            3: 'Візування',
          }
          await confirm({
            text: `Ви впевнені що хочете перевести договір в статус "${statusNames[statusId]}"?`,
          })
        } catch (e) {
          return
        }
      }

      return this.$axios
        .post(this.urlSetServiceStatus(this.serviceContract.id), {
          statusId: statusId,
        })
        .then(res => {
          if (res.data.message) {
            this.$setSnackbar({ text: res.data.message, color: 'warning' })
          } else {
            this.$setSnackbar({ text: 'Статус успішно змінено' })
          }
          this.getContract()
        })
        .catch(this.$err)
    },
    async sendService(id) {
      if (this.readonly) return

      try {
        if (this.v$.$invalid) {
          const validationError = v$Notify(
            this.v$.serviceContract,
            'serviceContract'
          )
          return Promise.reject(validationError)
        }

        if (id === 3 /* endorsement */) {
          if (!Object.keys(this.DFLPrintData).length) {
            await this.getDFLFormData()
          }
          if (this.DFLPrintData.url) return

          const base64Pdf = await generateDFLPdf(
            ...this.dflPdfParams
          ).getAsBase64()
          const pdfFile = dataURLtoFile(
            'application/pdf',
            base64Pdf,
            `КП ${this.calculation.requestId}`
          )

          const formData = new FormData()
          formData.append('document', pdfFile)

          const response = await this.$axios.post(
            urlDFLUploadDocument(this.contractId),
            formData
          )
          this.$setSnackbar({ text: 'ДФЛ вiдправлено на вiзування' })

          return response
        }

        return Promise.resolve()
      } catch (err) {
        this.$err(err)
        throw err
      }
    },
    submit() {
      if (this.v$.$invalid) {
        this.v$.$anyError
        this.v$.$touch()
        v$Notify(this.v$.serviceContract, 'serviceContract')
        return Promise.resolve()
      }

      const requestData = {
        lessorSignatoryId: this.serviceContract.lessorSignatoryId,
        lessorBasisId: this.serviceContract.lessorBasisId,
        lesseeSignatories: this.serviceContract.lesseeSignatories.map(s => ({
          contactId: s.contactId,
          basisId: s.basisId,
        })),
      }

      this.submitLoading = true
      return this.$axios
        .post(this.urlSetSignatoryServiceContract(this.contractId), requestData)
        .then(res => {
          if (res.data.message) throw new Error(res.data.message)
          this.$setSnackbar({ text: 'Данi збереженi' })
          this.submitLoading = false
          this.setCache([this.serviceContract])
          this.setStoreCache()
        })
        .catch(err => this.$err(err, () => (this.submitLoading = false)))
    },
    openAgreementSingle(id) {
      this.$router.push({ name: 'service-agreement', params: { id: id } })
    },
    openServiceContractSingle(id) {
      this.$router.push({ name: 'service-contract', params: { id: id } })
    },
    setBreadScrumb(res) {
      this.$store.commit('setBreadScrumbPart', [null, null, this.lesseeName])
      return res
    },
    setServiceContractLesseeSignatoryId(res) {
      if ([1, 3].includes(this.serviceContract.lessee.typeId)) {
        const ServiceContractLesseeSignatoryId = res?.data?.lessee?.contact?.id
        this.$store.commit(
          'setServiceContractLesseeSignatoryId',
          ServiceContractLesseeSignatoryId
        )
      }
      return res
    },
    createServiceContract() {
      return this.$axios
        .post(urlCreateServiceContract(), {
          contractId: this.serviceContract.id,
        })
        .then(res => {
          this.$setSnackbar({ text: 'Сервiсний договiр успiшно створено' })
          this.$router.push({
            name: 'service-contract',
            params: { id: res.data.id },
          })

          return res
        })
        .catch(this.$err)
    },
    setStoreCache() {
      this.$store.commit(
        'setServiceContractCache',
        this.setCache([this.serviceContract])
      )
    },
    getAdvanceFormData() {
      if (this.v$.$invalid) {
        return this.v$.$touch()
      }
      return this.$axios
        .get(this.urlGetAdvancePrintForm(this.$route.params.id))
        .then(res => Object.assign(this.advancePrintData, res.data))
        .catch(this.$err)
    },
    getDFLFormData() {
      return this.$axios
        .get(this.urlGetServiceContractPdf(this.$route.params.id))
        .then(res => {
          if (res.data.message)
            return this.$setSnackbar({
              text: res.data.message,
              color: 'warning',
            })
          Object.assign(this.DFLPrintData, res.data)
        })
        .catch(this.$err)
    },
    getContract() {
      if (!this.contractId) return
      if (this.v$.$reset) {
        this.v$.$reset()
      }
      this.$store.dispatch('addBasisDocuments')
      this.$store.dispatch('addServiceContractStatuses')
      this.$store
        .dispatch('getServiceContract', this.contractId)
        .then(this.setServiceContractLesseeSignatoryId)
        .then(this.setStoreCache)
        .then(this.setBreadScrumb)
        .catch(err => {
          this.$setSnackbar({ text: err, color: 'error' })
          this.$store.commit('setServiceContractState', false)
          throw err
        })
    },
  },
  watch: {
    route() {
      this.setBreadScrumb()
    },
    v$: {
      deep: true,
      handler(v) {
        this.$store.commit('setServiceContractValidation', v)
      },
    },
    'serviceContract.otherDocuments': function (v) {
      if (v !== null && v.length === 0)
        this.serviceContract.otherDocuments = null
    },
  },
  created() {
    this.getContract()
  },
}
</script>

<style scoped lang="scss">
.dkpTabs .v-slide-group__wrapper {
  background: #fff !important;
}
.background-loader {
  background: #ffffff8f;
  width: 100%;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100000;
}
.loader {
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(50%, 50%);
  width: 48px;
  height: 13px;
  font-size: 10px;
  > div {
    height: 100%;
    width: 9px;
    display: inline-block;
    background-color: #3498db;
    animation: load 1.2s infinite ease-in-out;
  }
  .rect2 {
    animation-delay: -1s;
  }
  .rect3 {
    animation-delay: -0.8s;
  }
}

@keyframes load {
  0%,
  40%,
  100% {
    transform: scaleX(0.8);
  }
  20% {
    transform: scaleX(1);
    background-color: #0870b2;
  }
}
</style>
