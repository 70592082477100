<template>
  <div>
    <SectionLoader v-if="asyncLoading('getPlan')" />
    <div v-else>
      <v-row>
        <v-col :cols="12" :md="4">
          <span class="label">Назва</span>
          <v-text-field
            v-model="plan.name"
            :readonly="currentUserRoleId !== 1"
            placeholder="введіть текст"
            :error-messages="setErrHandler(v$.plan.name)"
            @blur="v$.plan.name.$touch()"></v-text-field>
        </v-col>
        <v-col :cols="12" :md="4">
          <span class="label">Тип</span>
          <v-select
            v-model="plan.typeId"
            placeholder="оберіть зі списку"
            :items="directoryItems('planTypes', plan.type)"
            item-title="name"
            item-value="id"
            readonly
            :loading="asyncLoading('addPlanTypes')"
            :error-messages="setErrHandler(v$.plan.typeId)"
            @blur="v$.plan.typeId.$touch()"></v-select>
        </v-col>
      </v-row>
      <v-tabs v-model="currentTab" class="mb-3">
        <v-tab v-for="(planValue, idx) in planValues" :key="idx">
          {{ planValue.month.name }}
        </v-tab>
        <v-btn
          v-if="
            [ADMIN, MIDLE_HEAD, RISK_HEAD, SALES_DIRECTOR].includes(
              currentUserRoleId
            )
          "
          size="small"
          class="mt-2"
          icon
          @click="createPlanValueType"
          ><v-icon>mdi-plus</v-icon></v-btn
        >
      </v-tabs>
      <div v-if="currentPlanValue">
        <PlanValue
          :planValue="currentPlanValue"
          :plan="plan"
          @set-plan="setPlan" />
      </div>

      <ActionButtons
        :confirm="updatePlan"
        :cancel="resetPlan"
        :confirm-disable="!hasChanges"
        :cancel-disable="!hasChanges" />
    </div>
  </div>
</template>

<script>
import SectionLoader from '@/components/section-loader.vue'
import PlanValue from './PlanValue.vue'
import { urlGetPlanSingle, urlUpdatePlan } from '@/pages/request'
import { required } from '@vuelidate/validators'
import { setErrHandler } from '@/utils/helperFunc'
import ActionButtons from '@/components/action-buttons.vue'
import { setCache } from '@/utils/helperFunc'
import { cloneDeep } from 'lodash'
import { mapState } from 'vuex'
import { ADMIN, MIDLE_HEAD, RISK_HEAD, SALES_DIRECTOR } from '@/roles'
import { useVuelidate } from '@vuelidate/core'
import { useSelect } from '@/utils/mixins/useSelect'

export default {
  name: 'PlanSingle',
  components: { ActionButtons, SectionLoader, PlanValue },
  setup() {
    return { v$: useVuelidate(), ...useSelect() }
  },
  validations() {
    return {
      plan: {
        name: { required },
        typeId: { required },
      },
    }
  },
  data: () => {
    return {
      ADMIN,
      MIDLE_HEAD,
      RISK_HEAD,
      SALES_DIRECTOR,
      currentTab: 0,
      plan: {},
      cache: null,
    }
  },
  computed: {
    ...mapState({
      currentUserRoleId: state => state.user.roleId,
    }),
    hasChanges() {
      return (
        setCache([this.plan], ['0.plan_values']) !==
        setCache([this.cache], ['0.plan_values'])
      )
    },
    planValues() {
      if (this.plan.plan_values?.length) {
        return Array.from(this.plan.plan_values).sort((a, b) => {
          return a.month.id - b.month.id
        })
      } else {
        return []
      }
    },
    currentPlanValue() {
      if (this.planValues.length) {
        return this.planValues[this.currentTab]
      } else {
        return null
      }
    },
  },
  methods: {
    setErrHandler,
    setPlan(obj) {
      this.plan = obj
      this.cache = cloneDeep(obj)
    },
    getPlan() {
      return this.asyncAction('getPlan', null, () => {
        return this.$axios
          .get(urlGetPlanSingle(this.$route.params.id))
          .then(res => {
            this.setPlan(res.data)
          })
      })
    },
    updatePlan() {
      return this.asyncAction('updatePlan', null, () => {
        return this.$axios
          .post(urlUpdatePlan(this.$route.params.id))
          .then(res => {
            this.setPlan(res.data)
          })
      })
    },
    resetPlan() {
      this.plan = cloneDeep(this.cache)
    },
    createPlanValueType() {
      this.$store.commit('setDialog', {
        action: 'createPlanValueType',
        title: 'Додати план',
        dialogItem: {
          plan: this.plan,
        },
        params: {
          cb: res => {
            this.setPlan(res.data)
          },
        },
      })
    },
  },
  created() {
    this.getPlan()
  },
}
</script>
