<template>
  <v-row>
    <v-col cols="12" md="12" class="pb-0 pt-0">
      <span class="label">Дилерская точка</span>
      <v-text-field
        :model-value="dealerOutlet.name"
        placeholder="Оберіть зі списку"
        hide-details
        disabled>
      </v-text-field>
    </v-col>
    <v-col v-if="addDealerSeller" cols="12" md="12" class="pb-0">
      <span class="label">Постачальник</span>
      <v-autocomplete
        v-model="dealerSeller"
        :items="providers"
        :error-messages="providerIdErr"
        item-title="fullName"
        placeholder="Оберіть зі списку"
        :loading="asyncLoading('addContacts') || asyncLoading('addContractors')"
        :readonly="deleteDealerSeller"
        :disabled="deleteDealerSeller"
        return-object
        hide-details
        @focus="asyncAction(['addContacts', 'addContractors'])"
        @blur="v$.dealerSeller.providerId.$touch()">
        <template #no-data
          ><Loader
            v-if="
              asyncLoading('addContacts') || asyncLoading('addContractors')
            "
        /></template>
        <template #append>
          <span v-if="deleteDealerSeller"></span>
        </template>
      </v-autocomplete>
    </v-col>
    <v-col v-if="deleteDealerSeller" cols="12" md="12">
      <span class="label">Постачальник</span>
      <v-text-field
        :model-value="seller.fullName"
        placeholder="Введіть назву"
        disabled>
      </v-text-field>
    </v-col>
    <h3 v-if="deleteDealerSeller">Продавця буде видалено!</h3>
  </v-row>
</template>
<script>
import { required } from '@vuelidate/validators'
import { mapState } from 'vuex'
import {
  urlCreateDealerOutletSeller,
  urlDeleteDealerOutletSeller,
} from '@/pages/request'

import { setErrHandler, contractorFilter } from '@/utils/helperFunc'
import { useVuelidate } from '@vuelidate/core'
import { useSelect } from '@/utils/mixins/useSelect'
import { computed } from 'vue'
import Loader from '@/components/Loader.vue'
export default {
  components: { Loader },
  props: { dialog: { type: Object } },
  setup(props) {
    const { directoryItems } = useSelect()
    const providers = computed(() => {
      const providers = [
        ...directoryItems('contacts').map(c => ({
          fullName: c.fullName,
          providerId: c.id,
          providerTypeId: 1,
        })),
        ...directoryItems('contractors').map(c => ({
          fullName: c.shortName,
          providerId: c.id,
          providerTypeId: 2,
        })),
      ]
      return providers.filter(provider => {
        const currentProvider = JSON.stringify({
          providerId: provider.providerId,
          providerTypeId: provider.providerTypeId,
        })
        return !props.dialog.params.existedSellers.includes(currentProvider)
      })
    })

    return { v$: useVuelidate(), ...useSelect(), providers }
  },
  validations() {
    return {
      dealerSeller: {
        providerId: this.addDealerSeller ? { required } : {},
      },
    }
  },
  data: () => ({
    dealerSeller: null, // object has { fullName: null, providerTypeId: null, providerId: null}
  }),
  computed: {
    ...mapState({ selectItems: state => state.selectItems }),
    addDealerSeller() {
      return this.dialog.action === 'addDealerSeller'
    },
    deleteDealerSeller() {
      return this.dialog.action === 'deleteDealerSeller'
    },
    validation() {
      return { v$: this.v$.dealerSeller, name: 'dealerSeller' }
    },
    dealerOutlet() {
      return this.$store.state?.dialog?.params?.dealerOutlet
    },
    seller() {
      return this.$store.state?.dialog?.dialogItem?.seller
    },
    providerIdErr() {
      return setErrHandler(this.v$?.dealerSeller?.providerId)
    },
  },
  methods: {
    contractorFilter,
    submit() {
      switch (true) {
        case this.addDealerSeller:
          return this.createDillerSeller()
        case this.deleteDealerSeller:
          return this.removeDealerSeller()
        default:
          return this.$setSnackbar({ text: 'No handler', color: 'warning' })
      }
    },
    createDillerSeller() {
      return this.$axios.post(urlCreateDealerOutletSeller(), {
        ...this.dealerSeller,
        dealerPointId: this.dealerOutlet.id,
      })
    },
    removeDealerSeller() {
      return this.$axios.delete(
        urlDeleteDealerOutletSeller(this.dealerSeller?.id)
      )
    },
  },
  created() {
    Object.assign(this.dealerSeller, this.dialog.dialogItem)
  },
}
</script>
