<template>
  <div>
    <v-fade-transition hide-on-leave>
      <section-loader v-if="pageLoad && !departments.length"></section-loader>
    </v-fade-transition>
    <v-fade-transition hide-on-leave>
      <div v-if="!pageLoad && departments.length">
        <div class="pt-1 pl-0 pr-2 pb-1 d-flex justify-end">
          <v-btn
            class="text-white"
            style="background: rgb(252, 114, 71); border-radius: 4px"
            @click="openDepartmentDialog('addMode')">
            Додати департамент
          </v-btn>
        </div>
        <v-fade-transition hide-on-leave>
          <div class="mt-3">
            <v-data-table
              :headers="dPartmHeader"
              :items="departments"
              :custom-sort="$sortByDate"
              class="calc-table application-table"
              mobileBreakpoint="1200"
              :items-per-page="-1"
              hide-default-footer>
              <!-- eslint-disable-next-line -->
              <template #item.updated_at="{ item }">
                {{ $formatDate(item.updated_at) }}
              </template>
              <!-- eslint-disable-next-line -->
              <template #item.action="{ item }">
                <TableCrud
                  :actionsArray="[
                    {
                      action: () => openDepartmentDialog('editMode', item),
                      text: 'Редагувати',
                      icon: 'EditPencil',
                      customIcon: true,
                    },
                    {
                      action: () => openDepartmentDialog('removeMode', item),
                      text: 'Видалити',
                      icon: 'DeleteIcon',
                      customIcon: true,
                    },
                  ]" />
              </template>
            </v-data-table>
          </div>
        </v-fade-transition>
      </div>
    </v-fade-transition>
  </div>
</template>
<script>
import sectionLoader from '@/components/section-loader.vue'
import { urlDepartments } from '@/pages/request.js'
import { toFormatArrWithDate } from '@/utils/helperFunc'
import TableCrud from '@/components/table-crud.vue'

export default {
  components: { TableCrud, sectionLoader },
  data: () => ({
    department: null,
    departments: [],
    pageLoad: false,
    dPartmHeader: [
      { title: 'Департамент', key: 'name', sortable: false, align: 'start' },
      {
        title: 'Дата ств-ння/он-ння',
        key: 'updated_at',
        align: 'center',
      },
      {
        title: 'Дії',
        key: 'action',
        align: 'end',
      },
    ],
  }),
  methods: {
    urlDepartments,
    toFormatArrWithDate,
    openDepartmentDialog(action, item) {
      this.$store.commit('setDialog', {
        title: 'Департамент',
        dialogItem: {
          department: item ? item.name : null,
          departmentItem: item,
          currentMode: action,
        },
        action: 'departments-dialog',
      })
    },
    getAllDepartments() {
      this.pageLoad = true
      this.departments.splice(0)
      return this.$axios
        .get(this.urlDepartments())
        .then(v => {
          this.pageLoad = false
          this.departments.push(...toFormatArrWithDate(v.data, 'updated_at'))
          return v
        })
        .catch(err => this.$err(err, () => (this.pageLoad = false)))
    },
  },
  created() {
    this.getAllDepartments()
  },
}
</script>
