<template>
  <div style="position: absolute; opacity: 0; z-index: -1">
    <label for="_email">email</label><input name="_email" type="email" />
    <label for="phone">phone</label><input id="phone" name="phone" type="tel" />
    <label for="_number">number</label><input name="_number" type="text" />
    <label for="_password">password</label><input name="_password" type="password" />
    <label for="_login">login</label><input name="_login" type="text" />
    <label for="login">Логін</label><input name="login" type="text" />
  </div>
</template>
