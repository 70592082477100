<template>
  <div>
    <div>
      <div>
        <v-data-table
          v-if="contractorData.dealerPointOfSales"
          :headers="tHeadDealerOutlets"
          :items="contractorData.dealerPointOfSales"
          hide-default-footer
          :hide-default-header="!contractorData.dealerPointOfSales"
          class="pb-2 pt-1 custom-table"
          dense>
          <!-- eslint-disable-next-line -->
          <template #no-data>
            <no-data></no-data>
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.responsible="{ item }">
            <span style="font-size: 13px">{{
              getContactInitials(item.responsible.fullName, { byName: true })
            }}</span>
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.addresses="{ item }">
            <span style="font-size: 13px">{{
              getSafe(() => getAddress(item.addresses[0])) || 'Відсутня'
            }}</span>
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.name="{ item }">
            <ALink
              style="font-size: 13px !important"
              :text="item.name"
              :route="{ name: 'dealer-form', params: { id: item.id } }" />
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.actions="{ item }">
            <TableCrud
              :actionsArray="[
                {
                  action: () =>
                    $store.commit('setDialog', {
                      title: 'Диллер',
                      dialogItem: {
                        contractorId: getSafe(() => item.contractorId),
                        typeId: getSafe(() => item.type.id),
                        name: item.name,
                        id: item.id,
                      },
                      params: {
                        cb: () => {
                          $emit('tableChange')
                          $setSnackbar({ text: 'Дилера видалено' })
                        },
                      },
                      action: 'deleteDealer',
                    }),
                  text: 'Видалити',
                  icon: 'DeleteIcon',
                  customIcon: true,
                  disabled: readonly,
                },
              ]"
              :disabled="readOnlyDealerPoint">
            </TableCrud>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>
<script>
import ALink from '@/components/Link.vue'
import { mapState } from 'vuex'
import {
  getContactInitials,
  getResponsibleShortName,
  getSafe,
} from '@/utils/helperFunc'
import noData from '@/components/no-table-data.vue'
import TableCrud from '@/components/table-crud.vue'
export default {
  components: { TableCrud, noData, ALink },
  props: {
    contractor: { type: Object },
    readonly: { type: Boolean },
  },
  data: () => ({
    tHeadDealerOutlets: [
      { title: 'Назва', key: 'name', sortable: false, align: 'start' },
      {
        title: 'Відповідальний',
        key: 'responsible',
        sortable: false,
        align: 'start',
      },
      {
        title: 'Фактична адреса',
        key: 'addresses',
        align: 'start',
        sortable: false,
      },
      { title: 'Дії', key: 'actions', align: 'end', sortable: false },
    ],
  }),
  computed: {
    ...mapState({
      selectItems: state => state.selectItems,
      user: state => state.user,
    }),
    contractorData() {
      return this.contractor
    },
    readOnlyDealerPoint() {
      return this.contractor?.responsible?.id !== this.user.id
    },
  },
  methods: {
    getSafe,
    getContactInitials,
    getResponsibleShortName,
    getAddress(address) {
      if (!address) return '---'

      const c = address.city
      const h = address.house
      const s = address.street

      return [
        c?.Region === c?.City ? null : c?.Region,
        c?.SettlementType,
        c?.City,
        s?.StreetType,
        s?.Street,
        h?.HouseNum
          ? 'буд. ' + h.HouseNum + (h?.HouseNumAdd ? h?.HouseNumAdd : '')
          : null,
        h?.apartmentNum ? 'кв/оф. ' + h.apartmentNum : null,
      ]
        .filter(v => v)
        .join(', ')
    },
  },
}
</script>
