<template>
  <div>
    <span>Будь ласка, заповніть поля та прикріпіть копії документів</span>
    <div class="mt-3">
      <span class="area-label">Тип паспорту</span>
      <v-select
        v-model="identity_document.typeId"
        item-title="name"
        item-value="id"
        hide-details
        placeholder="Оберіть тип документу"
        :items="directoryItems('passportTypes', defaultType)" />
      <div class="mt-3">
        <v-scroll-x-transition hide-on-leave>
          <div v-if="[1, 3].includes(identity_document.typeId)">
            <v-row class="align-center">
              <v-col :cols="12" :md="4" class="pt-1 pb-0">
                <span class="area-label">Серія</span>
                <v-text-field
                  id="c-ld-book-pass-ser"
                  v-model="identity_document.identityDocFirst"
                  hide-details
                  placeholder="Введіть текст"
                  :error-messages="identityDocFirstErr"
                  @input="
                    trimExceededLength('c-ld-book-pass-ser', 2, arg =>
                      arg.replace(/[^ІіА-Яа-яA-Za-z0-9]/g, '').toUpperCase()
                    )
                  "
                  @blur="v$.identity_document.identityDocFirst.$touch()">
                </v-text-field>
              </v-col>
              <v-col :cols="12" :md="4" class="pt-1 pb-0">
                <span class="area-label">Номер</span>
                <v-text-field
                  id="c-ld-book-pass-num"
                  v-model="identity_document.identityDocSecond"
                  hide-details
                  placeholder="Введіть текст"
                  :error-messages="identityDocSecondErr"
                  @input="maxNumLength('c-ld-book-pass-num', 6)"
                  @blur="v$.identity_document.identityDocSecond.$touch()">
                </v-text-field>
              </v-col>
              <v-col :cols="12" :md="4" class="pb-0">
                <span class="area-label">Дата видачі</span>
                <DatePicker
                  v-model="identity_document.issueDate"
                  :maxDate="new Date().toISOString().split('T')[0]"
                  single
                  hide-details
                  placeholder="Дата" />
              </v-col>
            </v-row>
            <v-row class="align-center">
              <v-col :cols="12" :md="12" class="pt-1 pb-0">
                <span class="area-label">Орган що видав</span>
                <v-text-field
                  v-model="identity_document.issuedBy"
                  hide-details
                  placeholder="Введіть текст"
                  :error-messages="issuedByErr"
                  dense
                  @blur="v$.identity_document.issuedBy.$touch()">
                </v-text-field>
              </v-col>
            </v-row>
          </div>
        </v-scroll-x-transition>
        <v-scroll-x-transition hide-on-leave>
          <div v-if="identity_document.typeId === 2">
            <v-row class="align-center">
              <v-col :cols="12" :md="4" class="pt-1 pb-0">
                <span class="area-label">Запис №</span>
                <v-text-field
                  id="ctr-ld-pass-rec-num"
                  v-model="identity_document.identityDocFirst"
                  hide-details
                  placeholder="Введіть текст"
                  :error-messages="identityDocFirstErr"
                  @input="passportNumMask($event, 'ctr-ld-pass-rec-num')"
                  @blur="v$.identity_document.identityDocFirst.$touch()">
                </v-text-field>
              </v-col>
              <v-col :cols="12" :md="4" class="pt-1 pb-0">
                <span class="area-label">Документ №</span>
                <v-text-field
                  id="ctr-ld-pass-num"
                  v-model="identity_document.identityDocSecond"
                  hide-details
                  placeholder="Введіть текст"
                  :error-messages="identityDocSecondErr"
                  @input="maxNumLength('ctr-ld-pass-num', 9)"
                  @blur="v$.identity_document.identityDocSecond.$touch()">
                </v-text-field>
              </v-col>
              <v-col :cols="12" :md="4" class="pt-1 pb-0">
                <span class="area-label">Орган що видав</span>
                <v-text-field
                  id="id-card-authority"
                  v-model="identity_document.issuedBy"
                  hide-details
                  placeholder="Введіть текст"
                  :error-messages="issuedByErr"
                  @input="maxNumLength('id-card-authority', 4)"
                  @blur="v$.identity_document.issuedBy.$touch()">
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="align-center">
              <v-col :cols="12" :md="4" class="pt-1 pb-0">
                <span class="area-label">Дата видачі</span>
                <DatePicker
                  v-model="identity_document.issueDate"
                  :maxDate="new Date().toISOString().split('T')[0]"
                  single
                  hide-details
                  placeholder="Дата" />
              </v-col>
              <v-col :cols="12" :md="4" class="pt-1 pb-0">
                <span class="area-label">Дійсний, до</span>
                <DatePicker
                  v-model="identity_document.sellBy"
                  single
                  hide-details
                  placeholder="Дата" />
              </v-col>
            </v-row>
          </div>
        </v-scroll-x-transition>
        <v-scroll-x-transition hide-on-leave>
          <div v-if="identity_document.typeId === 9">
            <v-row class="align-center">
              <v-col :cols="12" :md="4" class="pt-1 pb-0">
                <span class="area-label">Запис №</span>
                <v-text-field
                  id="ctr-ld-pass-rec-num"
                  v-model="identity_document.identityDocFirst"
                  hide-details
                  placeholder="Введіть текст"
                  :error-messages="identityDocFirstErr"
                  @input="passportNumMask($event, 'ctr-ld-pass-rec-num')"
                  @blur="v$.identity_document.identityDocFirst.$touch()">
                </v-text-field>
              </v-col>
              <v-col :cols="12" :md="4" class="pt-1 pb-0">
                <span class="area-label">Документ №</span>
                <v-text-field
                  id="ctr-ld-pass-num"
                  v-model="identity_document.identityDocSecond"
                  hide-details
                  placeholder="Введіть текст"
                  :error-messages="identityDocSecondErr"
                  @input="maxNumLength('ctr-ld-pass-num', 9)"
                  @blur="v$.identity_document.identityDocSecond.$touch()">
                </v-text-field>
              </v-col>
              <v-col :cols="12" :md="4" class="pt-1 pb-0">
                <span class="area-label">Орган що видав</span>
                <v-text-field
                  id="id-card-authority"
                  v-model="identity_document.issuedBy"
                  hide-details
                  placeholder="Введіть текст"
                  :error-messages="issuedByErr"
                  @input="maxNumLength('id-card-authority', 4)"
                  @blur="v$.identity_document.issuedBy.$touch()">
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="align-center">
              <v-col :cols="12" :md="4" class="pt-1 pb-0">
                <span class="area-label">Дата видачі</span>
                <DatePicker
                  v-model="identity_document.issueDate"
                  :maxDate="new Date().toISOString().split('T')[0]"
                  single
                  hide-details
                  placeholder="Дата" />
              </v-col>
              <v-col :cols="12" :md="4" class="pt-1 pb-0">
                <span class="area-label">Дійсний, до</span>
                <DatePicker
                  v-model="identity_document.sellBy"
                  single
                  hide-details
                  placeholder="Дата" />
              </v-col>
            </v-row>
          </div>
        </v-scroll-x-transition>

        <v-scroll-x-transition hide-on-leave>
          <div v-if="identity_document.typeId === 4">
            <v-row class="align-center">
              <v-col :cols="12" :md="4" class="pt-1 pb-0">
                <span class="area-label">Запис №</span>
                <v-text-field
                  id="ctr-ld-pass-rec-num"
                  v-model="identity_document.identityDocFirst"
                  hide-details
                  placeholder="Введіть текст"
                  :error-messages="identityDocFirstErr"
                  @input="passportNumMask($event, 'ctr-ld-pass-rec-num')"
                  @blur="v$.identity_document.identityDocFirst.$touch()">
                </v-text-field>
              </v-col>
              <v-col :cols="12" :md="4" class="pt-1 pb-0">
                <span class="area-label">Документ №</span>
                <v-text-field
                  id="ctr-ld-pass-num"
                  v-model="identity_document.identityDocSecond"
                  hide-details
                  placeholder="Введіть текст"
                  :error-messages="identityDocSecondErr"
                  @input="maxNumLength('ctr-ld-pass-num', 9)"
                  @blur="v$.identity_document.identityDocSecond.$touch()">
                </v-text-field>
              </v-col>
              <v-col :cols="12" :md="4" class="pt-1 pb-0">
                <span class="area-label">Орган що видав</span>
                <v-text-field
                  id="id-card-authority"
                  v-model="identity_document.issuedBy"
                  hide-details
                  placeholder="Введіть текст"
                  :error-messages="issuedByErr"
                  @input="maxNumLength('id-card-authority', 4)"
                  @blur="v$.identity_document.issuedBy.$touch()">
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="align-center">
              <v-col :cols="12" :md="4" class="pt-1 pb-0">
                <span class="area-label">Дата видачі</span>
                <DatePicker
                  v-model="identity_document.issueDate"
                  :maxDate="new Date().toISOString().split('T')[0]"
                  single
                  hide-details
                  placeholder="Дата" />
              </v-col>
              <v-col :cols="12" :md="4" class="pt-1 pb-0">
                <span class="area-label">Дійсний, до</span>
                <DatePicker
                  v-model="identity_document.sellBy"
                  single
                  hide-details
                  placeholder="Дата" />
              </v-col>
            </v-row>
          </div>
        </v-scroll-x-transition>

        <v-scroll-x-transition hide-on-leave>
          <div v-if="identity_document.typeId === 8">
            <v-row class="align-center">
              <v-col :cols="12" :md="4" class="pt-1 pb-0">
                <span class="area-label">Запис №</span>
                <v-text-field
                  id="ctr-ld-pass-rec-num"
                  v-model="identity_document.identityDocFirst"
                  hide-details
                  placeholder="Введіть текст"
                  :error-messages="identityDocFirstErr"
                  @input="passportNumMask($event, 'ctr-ld-pass-rec-num')"
                  @blur="v$.identity_document.identityDocFirst.$touch()">
                </v-text-field>
              </v-col>
              <v-col :cols="12" :md="4" class="pt-1 pb-0">
                <span class="area-label">Документ №</span>
                <v-text-field
                  id="ctr-ld-pass-num"
                  v-model="identity_document.identityDocSecond"
                  hide-details
                  placeholder="Введіть текст"
                  :error-messages="identityDocSecondErr"
                  @input="maxNumLength('ctr-ld-pass-num', 9)"
                  @blur="v$.identity_document.identityDocSecond.$touch()">
                </v-text-field>
              </v-col>
              <v-col :cols="12" :md="4" class="pt-1 pb-0">
                <span class="area-label">Орган що видав</span>
                <v-text-field
                  id="id-card-authority"
                  v-model="identity_document.issuedBy"
                  hide-details
                  placeholder="Введіть текст"
                  :error-messages="issuedByErr"
                  @input="maxNumLength('id-card-authority', 4)"
                  @blur="v$.identity_document.issuedBy.$touch()">
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="align-center">
              <v-col :cols="12" :md="4" class="pt-1 pb-0">
                <span class="area-label">Дата видачі</span>
                <DatePicker
                  v-model="identity_document.issueDate"
                  :maxDate="new Date().toISOString().split('T')[0]"
                  single
                  hide-details
                  placeholder="Дата" />
              </v-col>
              <v-col :cols="12" :md="4" class="pt-1 pb-0">
                <span class="area-label">Дійсний, до</span>
                <DatePicker
                  v-model="identity_document.sellBy"
                  single
                  hide-details
                  placeholder="Дата" />
              </v-col>
            </v-row>
          </div>
        </v-scroll-x-transition>

        <v-scroll-x-transition hide-on-leave>
          <div v-if="identity_document.typeId === 7">
            <v-row class="align-center">
              <v-col :cols="12" :md="4" class="pt-1 pb-0">
                <span class="area-label">Запис №</span>
                <v-text-field
                  id="ctr-ld-pass-rec-num"
                  v-model="identity_document.identityDocFirst"
                  hide-details
                  placeholder="Введіть текст"
                  :error-messages="identityDocFirstErr"
                  @input="passportNumMask($event, 'ctr-ld-pass-rec-num')"
                  @blur="v$.identity_document.identityDocFirst.$touch()">
                </v-text-field>
              </v-col>
              <v-col :cols="12" :md="4" class="pt-1 pb-0">
                <span class="area-label">Документ №</span>
                <v-text-field
                  id="ctr-ld-pass-num"
                  v-model="identity_document.identityDocSecond"
                  hide-details
                  placeholder="Введіть текст"
                  :error-messages="identityDocSecondErr"
                  @input="maxNumLength('ctr-ld-pass-num', 6)"
                  @blur="v$.identity_document.identityDocSecond.$touch()">
                </v-text-field>
              </v-col>
              <v-col :cols="12" :md="4" class="pt-1 pb-0">
                <span class="area-label">Орган що видав</span>
                <v-text-field
                  id="id-card-authority"
                  v-model="identity_document.issuedBy"
                  hide-details
                  placeholder="Введіть текст"
                  :error-messages="issuedByErr"
                  @input="maxNumLength('id-card-authority', 4)"
                  @blur="v$.identity_document.issuedBy.$touch()">
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="align-center">
              <v-col :cols="12" :md="4" class="pt-1 pb-0">
                <span class="area-label">Дата видачі</span>
                <DatePicker
                  v-model="identity_document.issueDate"
                  :maxDate="new Date().toISOString().split('T')[0]"
                  single
                  placeholder="Дата" />
              </v-col>
              <v-col :cols="12" :md="4" class="pt-1 pb-0">
                <span class="area-label">Дійсний, до</span>
                <DatePicker
                  v-model="identity_document.sellBy"
                  single
                  hide-details
                  placeholder="Дата" />
              </v-col>
            </v-row>
          </div>
        </v-scroll-x-transition>

        <v-scroll-x-transition hide-on-leave>
          <div v-if="identity_document.typeId === 6">
            <v-row class="align-center">
              <v-col :cols="12" :md="4" class="pt-1 pb-0">
                <span class="area-label">Запис №</span>
                <v-text-field
                  id="ctr-ld-pass-rec-num"
                  v-model="identity_document.identityDocFirst"
                  hide-details
                  placeholder="Введіть текст"
                  :error-messages="identityDocFirstErr"
                  @input="passportNumMask($event, 'ctr-ld-pass-rec-num')"
                  @blur="v$.identity_document.identityDocFirst.$touch()">
                </v-text-field>
              </v-col>
              <v-col :cols="12" :md="4" class="pt-1 pb-0">
                <span class="area-label">Документ №</span>
                <v-text-field
                  id="ctr-ld-pass-num"
                  v-model="identity_document.identityDocSecond"
                  hide-details
                  placeholder="Введіть текст"
                  :error-messages="identityDocSecondErr"
                  @input="maxNumLength('ctr-ld-pass-num', 6)"
                  @blur="v$.identity_document.identityDocSecond.$touch()">
                </v-text-field>
              </v-col>
              <v-col :cols="12" :md="4" class="pt-1 pb-0">
                <span class="area-label">Орган що видав</span>
                <v-text-field
                  id="id-card-authority"
                  v-model="identity_document.issuedBy"
                  hide-details
                  placeholder="Введіть текст"
                  :error-messages="issuedByErr"
                  @input="maxNumLength('id-card-authority', 4)"
                  @blur="v$.identity_document.issuedBy.$touch()">
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="align-center">
              <v-col :cols="12" :md="4" class="pt-1 pb-0">
                <span class="area-label">Дата видачі</span>
                <DatePicker
                  v-model="identity_document.issueDate"
                  :maxDate="new Date().toISOString().split('T')[0]"
                  single
                  placeholder="Дата" />
              </v-col>
              <v-col :cols="12" :md="4" class="pt-1 pb-0">
                <span class="area-label">Дійсний, до</span>
                <DatePicker
                  v-model="identity_document.sellBy"
                  single
                  hide-details
                  placeholder="Дата" />
              </v-col>
            </v-row>
          </div>
        </v-scroll-x-transition>

        <v-scroll-x-transition hide-on-leave>
          <div v-if="identity_document.typeId === 5">
            <v-row class="align-center">
              <v-col :cols="12" :md="4" class="pt-1 pb-0">
                <span class="area-label">Запис №</span>
                <v-text-field
                  id="ctr-ld-pass-rec-num"
                  v-model="identity_document.identityDocFirst"
                  hide-details
                  placeholder="Введіть текст"
                  :error-messages="identityDocFirstErr"
                  @input="passportNumMask($event, 'ctr-ld-pass-rec-num')"
                  @blur="v$.identity_document.identityDocFirst.$touch()">
                </v-text-field>
              </v-col>
              <v-col :cols="12" :md="4" class="pt-1 pb-0">
                <span class="area-label">Документ №</span>
                <v-text-field
                  id="ctr-ld-pass-num"
                  v-model="identity_document.identityDocSecond"
                  hide-details
                  maxlength="8"
                  placeholder="Введіть текст"
                  :error-messages="identityDocSecondErr"
                  @input="
                    trimExceededLength('ctr-ld-pass-num', 8, arg =>
                      arg
                        .split('')
                        .map((s, i) =>
                          i >= 2
                            ? s.replace(/\D/g, '')
                            : s.replace(/[^a-zA-Z]/g, '')
                        )
                        .join('')
                        .toUpperCase()
                    )
                  "
                  @blur="v$.identity_document.identityDocSecond.$touch()">
                </v-text-field>
              </v-col>
              <v-col :cols="12" :md="4" class="pt-1 pb-0">
                <span class="area-label">Орган що видав</span>
                <v-text-field
                  id="id-card-authority"
                  v-model="identity_document.issuedBy"
                  hide-details
                  placeholder="Введіть текст"
                  :error-messages="issuedByErr"
                  @blur="v$.identity_document.issuedBy.$touch()">
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="align-center">
              <v-col :cols="12" :md="4" class="pt-1 pb-0">
                <span class="area-label">Дата видачі</span>
                <DatePicker
                  v-model="identity_document.issueDate"
                  :maxDate="new Date().toISOString().split('T')[0]"
                  single
                  placeholder="Дата" />
              </v-col>
              <v-col :cols="12" :md="4" class="pt-1 pb-0">
                <span class="area-label">Дійсний, до</span>
                <DatePicker
                  v-model="identity_document.sellBy"
                  single
                  hide-details
                  placeholder="Дата" />
              </v-col>
            </v-row>
          </div>
        </v-scroll-x-transition>

        <v-scroll-x-transition hide-on-leave>
          <div v-if="identity_document.typeId === 10">
            <v-row class="align-center">
              <v-col :cols="12" :md="8" class="pt-1 pb-0">
                <span class="area-label">Назва документу</span>
                <v-text-field
                  id="c-ld-book-pass-ser"
                  v-model="identity_document.identityDocFirst"
                  hide-details
                  placeholder="Введіть текст"
                  :error-messages="identityDocFirstErr"
                  @blur="v$.identity_document.identityDocFirst.$touch()">
                </v-text-field>
              </v-col>
              <v-col :cols="12" :md="4" class="pt-1 pb-0">
                <span class="area-label">Документ №</span>
                <v-text-field
                  id="c-ld-book-pass-num"
                  v-model="identity_document.identityDocSecond"
                  hide-details
                  placeholder="Введіть текст">
                </v-text-field>
              </v-col>
              <v-col :cols="12" :md="4" class="pt-1 pb-0">
                <span class="area-label">Дата видачі</span>
                <DatePicker
                  v-model="identity_document.issueDate"
                  :maxDate="new Date().toISOString().split('T')[0]"
                  single
                  placeholder="Дата" />
              </v-col>
              <v-col :cols="12" :md="4" class="pt-1 pb-0">
                <span class="area-label">Дійсний до</span>
                <DatePicker
                  v-model="identity_document.sellBy"
                  single
                  placeholder="Дата" />
              </v-col>
            </v-row>
          </div>
        </v-scroll-x-transition>
      </div>
      <div>
        <FilesUploader
          v-model:documents="identity_document.document"
          class="mt-4"
          label="Скан документу"
          dense
          required
          acceptedExt=".pdf"
          :list="false"
          :error-messages="documentErr"
          @loaded="onLoadDocument"
          @blur="v$.identity_document.document.$touch()">
        </FilesUploader>
        <v-dialog v-model="signedDialog.dialog" width="300">
          <v-card>
            <div class="pa-3">
              <span style="color: #000; font-size: 14px; font-weight: 700"
                >Документ завiрений?</span
              >
              <div
                v-for="(doc, idx) in signedDialog.documents"
                :key="idx"
                class="mt-3">
                <span
                  v-if="doc.text.includes('.rar') || doc.text.includes('.zip')">
                  Архів неможливо завірити
                </span>
                <div v-else>
                  <span
                    style="font-size: 14px"
                    class="link"
                    @click="() => openDocument(doc)"
                    >{{ doc.text }}</span
                  >
                  <v-checkbox
                    #label
                    v-model="signedDialog.documents[idx].isSigned"
                    style="margin-top: 0 !important"
                    dense
                    :false-value="false"
                    :true-value="true"
                    hide-details>
                    Так, завiрена копiя
                  </v-checkbox>
                </div>
                <v-card-actions
                  v-if="
                    !(doc.text.includes('.rar') || doc.text.includes('.zip'))
                  "
                  class="pl-0 mt-3">
                  <v-btn color="primary" variant="flat" @click="handleSigned"
                    >Пiдтвердити</v-btn
                  >
                </v-card-actions>
              </div>
            </div>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import {
  maxNumLength,
  trimExceededLength,
  passportNumMask,
} from '@/utils/masks'
import DatePicker from '@/components/DatePicker.vue'
import { setErrHandler, openDocument } from '@/utils/helperFunc'
import {
  urlUpdateContactPassport,
  urlCreateContactPassport,
} from '@/pages/request'
import { required, minLength } from '@vuelidate/validators'
import { mapState } from 'vuex'
import FilesUploader from '@/components/FilesUploader.vue'
import { useVuelidate } from '@vuelidate/core'
import { useSelect } from '@/utils/mixins/useSelect'

export default {
  components: { FilesUploader, DatePicker },
  setup() {
    return { v$: useVuelidate(), ...useSelect() }
  },
  props: {
    dialog: { type: Object },
    contactData: { type: Object },
  },
  validations() {
    const sellBy = [1, 3].includes(this.identity_document.typeId)
      ? {}
      : { required }
    const issuedBy = this.otherDocument ? {} : { required }
    const identityDocFirst = { required }
    if ([2, 4, 5, 6, 7, 8, 9].includes(this.identity_document.typeId)) {
      identityDocFirst.minLength = minLength(14)
    }
    if ([1, 3].includes(this.identity_document.typeId)) {
      identityDocFirst.minLength = minLength(2)
    }
    const identityDocSecondLength = this.identity_document.typeId === 2 ? 9 : 6

    return {
      identity_document: {
        identityDocFirst: identityDocFirst,
        identityDocSecond: {
          required,
          minLength: minLength(identityDocSecondLength),
        },
        issueDate: { required },
        sellBy: sellBy,
        issuedBy: issuedBy,
        document: { required, minLength: minLength(1) },
      },
    }
  },
  data: () => ({
    identity_document: {
      typeId: null,
      identityDocFirst: null,
      identityDocSecond: null,
      sellBy: null,
      issueDate: null,
      issuedBy: null,
      document: null,
    },
    signedDialog: {
      dialog: false,
      documents: [],
    },
  }),
  computed: {
    validation() {
      return {
        v$: this.v$.identity_document,
        name: 'identity_document',
        payload: { sectionPayload: this.identity_document.typeId },
      }
    },
    ...mapState({
      passportTypes: state => state.passportTypes,
      selectItems: state => state.selectItems,
    }),
    defaultType() {
      return this.identity_document.type?.name
    },
    passportType() {
      return this.identity_document?.typeId
    },
    idCard() {
      return this.identity_document.typeId === 2
    },
    otherDocument() {
      return this.identity_document.typeId === 10
    },
    createPassport() {
      return this.dialog.action === 'create-passport'
    },
    editPassport() {
      return this.dialog.action === 'edit-passport'
    },
    identityDocFirstErr() {
      let msg
      if (this.idCard)
        msg =
          this.identity_document.identityDocFirst?.length === 14
            ? null
            : 'Довжина поля 14 символів'
      else msg = null
      return setErrHandler(this.v$.identity_document.identityDocFirst, msg)
    },
    identityDocSecondErr() {
      return setErrHandler(this.v$.identity_document.identityDocSecond)
    },
    issuedByErr() {
      return setErrHandler(this.v$.identity_document.issuedBy)
    },
    documentErr() {
      return setErrHandler(this.v$.identity_document.document)
    },
  },
  methods: {
    maxNumLength,
    openDocument,
    trimExceededLength,
    passportNumMask,
    handleInput(value) {
      const regex = /^[А-Я]{2}\d{3,8}$/
      const input = value.replace(/\s/g, '').toUpperCase()
      if (regex.test(input)) {
        this.identity_document.identityDocSecond = input
      } else {
        this.identity_document.identityDocSecond = null
      }
    },
    setSignedDialog(value) {
      this.signedDialog.dialog = value
      if (!value) {
        this.signedDialog.documents = []
      }
    },
    onLoadDocument(documents) {
      this.signedDialog.documents = Object.clone(documents)
      this.setSignedDialog(true)
    },
    handleSigned() {
      if (this.signedDialog && Array.isArray(this.signedDialog.documents)) {
        this.identity_document.document =
          this.identity_document.document.filter(doc => {
            return !this.signedDialog.documents
              .map(d => d.url)
              .includes(doc.url)
          })
        this.identity_document.document.push(...this.signedDialog.documents)
        this.setSignedDialog(false)
      } else {
        this.$setSnackbar({
          text: 'signedDialog не є масивом',
          color: 'warning',
        })
      }
    },
    submit() {
      const url = this.createPassport
        ? urlCreateContactPassport()
        : urlUpdateContactPassport(this.identity_document.id)
      const req = Object.clone(this.identity_document)
      delete req.document
      req.document = this.identity_document.document
      req.contactId ??= this.dialog.params.contactId
      return this.$axios
        .post(url, req)
        .then(res => {
          this.$setSnackbar(
            `Документ, що посвідчує особу ${
              this.createPassport ? 'додано' : 'оновлено'
            }`
          )
          // this.$store.commit('refreshPage')
          return res.data
        })
        .catch(this.$err)
    },
    clearPassport() {
      this.identity_document.identityDocFirst = null
      this.identity_document.identityDocSecond = null
      this.identity_document.sellBy = null
      this.identity_document.issueDate = null
      this.identity_document.issuedBy = null
    },
  },
  created() {
    if (this.dialog.dialogItem) {
      this.identity_document = Object.clone(this.dialog.dialogItem)
    }
    this.$watch('identity_document.typeId', this.clearPassport)
  },
}
</script>

<style scoped></style>
