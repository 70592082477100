<template>
  <div>
    <v-row>
      <v-col v-if="toReject" cols="12" md="12" sm="12">
        <span class="label">Тип відмови</span>
        <v-select
          v-model="rejectionTypeId"
          placeholder="Оберіть зі списку"
          :items="projectRefusalStatuses"
          :error-messages="statusIdErr"
          item-title="name"
          item-value="id"
          :readonly="readonly"
          @blur="v$.rejectionTypeId.$touch()">
        </v-select>
      </v-col>
      <v-col cols="12" md="12" sm="12" class="pt-0">
        <span class="label">{{ commentLabel }}</span>
        <v-textarea
          v-model="projectComment"
          placeholder="Введіть текст"
          :error-messages="commentErr"
          solo
          @blur="v$.projectComment.$touch()">
        </v-textarea>
      </v-col>
      <v-col v-if="klpRework" cols="12" md="12" sm="12" class="pt-0">
        <span class="label">Тип</span>
        <v-select
          v-model="klpTypeId"
          placeholder="Оберіть зі списку"
          :items="klpReworkTypes"
          :error-messages="klpTypeIdErr"
          item-title="name"
          hide-details
          item-value="id"
          :readonly="readonly"
          @blur="v$.klpTypeId.$touch()">
        </v-select>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { minLength, required } from '@vuelidate/validators'
import { setErrHandler } from '@/utils/helperFunc'
import { urlSetProjectStatus } from '@/pages/request'
import { useVuelidate } from '@vuelidate/core'

export default {
  props: {
    dialog: { type: Object },
  },
  setup() {
    return { v$: useVuelidate() }
  },
  validations() {
    return {
      rejectionTypeId: this.toReject ? { required } : {},
      projectComment: { required, minLength: minLength(20) },
      klpTypeId: this.klpRework ? { required } : {},
    }
  },
  data: () => ({
    rejectionTypeId: null,
    projectComment: null,
    readonly: false,
    klpTypeId: null,
  }),
  computed: {
    ...mapState({
      klpReworkTypes: state => state.selectItems.klpReworkTypes,
      projectRefusalStatuses: state => state.selectItems.projectRefusalStatuses,
      projectValidationKeys: state => state.selectItems.projectValidationKeys,
    }),
    validation() {
      return { v$: this.v$, name: 'applicationRejection' }
    },
    statusIdErr() {
      return this.setErrHandler(this.v$.rejectionTypeId)
    },
    klpTypeIdErr() {
      return this.setErrHandler(this.v$.klpTypeId)
    },
    commentErr() {
      const errors = []
      if (!this.v$.projectComment.$dirty) return errors
      if (!this.projectComment) {
        errors.push("Поле обов'язкове для заповнення")
      }
      if (this.projectComment && this.projectComment.length < 20) {
        errors.push('Мінімальна кількість символів для коментаря: 20')
      }
      return errors
    },
    toReject() {
      return this.dialog.action === 'rejectProjectResult'
    },
    toMiddle() {
      return this.dialog.action === 'projectToMiddle'
    },
    toFinalization() {
      return this.dialog.action === 'projectToFinalization'
    },
    toRevisionFromVerification() {
      return this.dialog.action === 'sendToRevision'
    },
    toVerification() {
      return this.dialog.action === 'sendToVerification'
    },
    commentLabel() {
      return this.toReject ? 'Причина відмови' : 'Коментар'
    },
    klpRework() {
      return this.dialog.dialogItem.klpRework
    },
  },
  methods: {
    setErrHandler,
    urlSetProjectStatus,
    async submit() {
      const { next } = await this.submitHandler()
      return Promise.resolve(next)
    },
    submitHandler() {
      if (this.toRevisionFromVerification || this.toVerification) {
        return this.sendProjectRevision()
      } else {
        return this.setProjectStatus()
      }
    },
    sendProjectRevision() {
      const revisionObject =
        this.dialog.dialogItem.serviceObj ||
        this.dialog.dialogItem.calculationObj
      const isCalc = !!revisionObject.calculationId
      const request = {
        comment: this.projectComment,
      }
      if (this.klpRework) {
        request.typeId = this.klpTypeId
      }

      switch (true) {
        case isCalc:
          request.calculationId = revisionObject.calculationId
          break
        case revisionObject.name === 'klp':
          break
        case this.toVerification:
          request.projectId = this.dialog.dialogItem.projectId
          break
      }
      return this.$axios.post(revisionObject.url, request).then(() => {
        let msg
        switch (true) {
          case this.toVerification:
            msg = `Проект передано в ${revisionObject.name}`
            break
          case this.toRevisionFromVerification:
            msg = `${
              isCalc ? 'Калькуляцію' : 'Проект'
            } передано на доопрацювання`
            break
        }
        this.$setSnackbar({ text: msg })
        return { next: true }
      })
    },
    setProjectStatus() {
      const request = {
        statusId: this.dialog.dialogItem.statusId,
      }
      if (this.toReject) {
        request.rejectionTypeId = this.rejectionTypeId
        request.rejectionComments = this.projectComment
      } else if (this.toFinalization || this.toMiddle) {
        request.commentStatus = this.projectComment
      }
      return this.$store
        .dispatch('setProjectStatus', {
          projectId: this.dialog.dialogItem.projectId,
          request,
        })
        .then(res => {
          if (res.data.message) {
            const htmlMsg = Object.keys(res.data.message).map(field =>
              `${this.projectValidationKeys[field]}`.join('')
            )

            const msgText = `Помилка валідації: ${htmlMsg}`
            this.$setSnackbar({ text: msgText, color: 'error' })
            return { next: false }
          } else {
            this.$setSnackbar({ text: 'Статус успішно змінено' })
            return { next: true }
          }
        })
    },
  },
  created() {
    this.$store.dispatch('addProjectRefusalStatuses')
    if (this.dialog.dialogItem.statusId) {
      this.rejectionTypeId = this.dialog.dialogItem.rejectionTypeId
    }
  },
}
</script>

<style scoped></style>
