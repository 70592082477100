<template>
  <div class="pt-2 pb-4">
    <list-section
      :title="typeData.title"
      :addAction="() => openDialog('create')"
      :addACtionTitle="`додати ${typeData.name}`"
      :addActionDisabled="readonly">
      <template #body>
        <div>
          <v-data-table
            :headers="headers"
            :items="localItems"
            class="type-cell"
            hide-default-header
            hide-default-footer>
            <!-- eslint-disable-next-line -->
            <template #item.typeId="{ item }">
              <div
                class="d-flex align-center gap-1 mr-4 ml-2"
                style="
                  border-right: 1px solid #c0c1c7;
                  width: 15rem;
                  font-size: 13px !important;
                ">
                <PhoneIcon v-if="typeData.phone" />
                <EmailIcon v-else />
                <span>{{ getSafe(() => item.type.name) }}</span>
              </div>
            </template>
            <!-- eslint-disable-next-line -->
            <template #item.item="{ item }">
              <div class="d-flex align-center gap-3">
                <v-hover>
                  <template #default="{ isHovering, props }">
                    <div
                      v-bind="props"
                      class="d-inline-flex gap-1 align-center"
                      @click="() => copyText(item)">
                      <span class="link" style="font-size: 13px !important">{{
                        item.phone || item.email
                      }}</span>
                      <div style="width: 16px; font-size: 13px !important">
                        <v-fade-transition hide-on-leave>
                          <v-icon
                            v-show="isHovering"
                            style="cursor: pointer"
                            size="small">
                            {{
                              copied.value === item.id
                                ? 'mdi-check'
                                : 'mdi-content-copy'
                            }}
                          </v-icon>
                        </v-fade-transition>
                      </div>
                    </div>
                  </template>
                </v-hover>
                <div v-for="messenger in getMessengers(item)" :key="messenger">
                  <v-tooltip bottom :text="messenger">
                    <template #activator="{ props }">
                      <v-btn
                        size="x-small"
                        icon
                        v-bind="props"
                        @click="openMessengerLink(item.phone, messenger)">
                        <component :is="messenger.toLowerCase()" />
                      </v-btn>
                    </template>
                    <span> {{ messenger }}</span>
                  </v-tooltip>
                </div>

                <div v-if="item.isNotify">
                  <v-tooltip bottom text="Підписаний на розсилки (промо-акції)">
                    <template #activator="{ props }">
                      <v-icon style="color: #09487a" v-bind="props">{{
                        'mdi-bell-alert-outline'
                      }}</v-icon>
                    </template>
                    <span>Підписаний на розсилки (промо-акції)</span>
                  </v-tooltip>
                </div>
              </div>
            </template>
            <!-- eslint-disable-next-line -->
            <template #item.actions="{ item }">
              <TableCrud
                v-if="!readonly"
                data-readonly="hide"
                class="d-flex justify-end"
                style="margin-right: -5px"
                :actionsArray="[
                  {
                    action: () => openDialog('edit', item),
                    text: 'Редагувати',
                    icon: 'EditPencil',
                    customIcon: true,
                    disabled: item.disabled,
                  },
                  {
                    action: () => deleteItem(item.id),
                    text: 'Видалити',
                    icon: 'mdi-close',
                    disabled: item.disabled,
                  },
                ]">
              </TableCrud>
            </template>
          </v-data-table>
        </div>
      </template>
    </list-section>
  </div>
</template>

<script>
import ListSection from '@/components/list-section.vue'
import telegram from '@/assets/svg/telegram.vue'
import viber from '@/assets/svg/viber.vue'
import whatsapp from '@/assets/svg/whatsapp.vue'
import PhoneIcon from '@/assets/svg/phone-icon.vue'
import EmailIcon from '@/assets/svg/email-icon.vue'
import Loader from '@/components/Loader.vue'
import TableCrud from '@/components/table-crud.vue'
import { phoneMask, emailMask } from '@/utils/masks'
import {
  urlPhoneCreate,
  urlPhoneUpdate,
  urlPhoneDelete,
  urlEmailCreate,
  urlEmailUpdate,
  urlEmailDelete,
} from '@/pages/request'
import { useSelect } from '@/utils/mixins/useSelect'
import { getSafe } from '../../utils/helperFunc'

export default {
  components: {
    ListSection,
    telegram,
    viber,
    whatsapp,
    PhoneIcon,
    EmailIcon,
    Loader,
    TableCrud,
  },
  setup() {
    return {
      ...useSelect(),
    }
  },
  props: {
    items: { type: Array, default: () => [] },
    type: {
      type: String,
      required: true,
      validator: val => ['phone', 'email'].includes(val),
    },
    entityTypeId: { type: Number, required: true },
    entityId: { type: Number, required: true },
    readonly: { type: Boolean, default: false },
    checkFunc: { type: Function },
  },
  data() {
    return {
      onEditIdx: null,
      localItems: [],
      link: null,
      headers: [
        { title: 'Тип', key: 'typeId', align: 'left' },
        { title: 'Item', key: 'item', align: 'left' },
        { title: 'Дії', key: 'actions', align: 'right' },
      ],
      copied: { value: false, timeout: null },
    }
  },
  computed: {
    typeData() {
      const typesMap = {
        phone: {
          phone: true,
          urlCreate: urlPhoneCreate,
          urlDelete: urlPhoneDelete,
          urlUpdate: urlPhoneUpdate,
          type: 'phone',
          title: 'Телефони',
          name: 'Телефон',
          icon: 'mdi-cellphone-android',
          mask: phoneMask,
        },
        email: {
          email: true,
          urlCreate: urlEmailCreate,
          urlDelete: urlEmailDelete,
          urlUpdate: urlEmailUpdate,
          type: 'email',
          title: 'Email',
          name: 'Email',
          icon: 'mdi-at',
          mask: emailMask,
        },
      }

      return typesMap[this.type]
    },
  },
  methods: {
    getSafe,
    getItemStr(item) {
      return item[this.typeData.type]
    },
    getMessengers(item) {
      const list = []

      if (item && typeof item === 'object') {
        item.isViber && list.push('Viber')
        item.isTelegram && list.push('Telegram')
        item.isWhatsapp && list.push('Whatsapp')
      }

      return list
    },
    openMessengerLink(phone, type) {
      const curNumber = phone.replace(/\D/g, '')

      const isMobileDevice =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )

      if (type === 'Viber') {
        if (isMobileDevice) {
          this.link = `viber://add?number=${curNumber}`
        } else {
          this.link = `viber://chat?number=${'+' + curNumber}`
        }
      } else if (type === 'Whatsapp') {
        this.link = `https://wa.me/${curNumber}`
      } else if (type === 'Telegram') {
        return
      }

      window.open(this.link, '_blank')
    },
    copyText(item) {
      clearTimeout(this.copied.timeout)
      this.copied.value = null
      navigator.clipboard
        .writeText(item[this.typeData.type])
        .then(() => {
          this.copied.value = item.id
          this.copied.timeout = setTimeout(
            () => (this.copied.value = null),
            3000
          )
        })
        .catch(() => {
          throw new Error('text copied failed')
        })
    },
    openDialog(action, item) {
      return this.$store.commit('setDialog', {
        title: this.typeData.name,
        action: `${action}${this.typeData.type.capitalize()}`,
        dialogItem: item,
        params: {
          entity: {
            id: this.entityId,
            typeId: this.entityTypeId,
          },
          excludeTypes: this.localItems.map(i => i.typeId),
          type: this.typeData.type,
          checkItem: this.checkItem,
          dialogWidth: 380,
          cb: res => {
            if (res && res.data) {
              if (action === 'create') {
                this.localItems = [...this.localItems, res.data]
              } else if (action === 'edit') {
                this.localItems = this.localItems.map(i =>
                  i.id === res.data.id ? res.data : i
                )
              }
            }
          },
        },
      })
    },
    async deleteItem(id) {
      await this.asyncAction('delete', null, () => {
        return this.$axios.delete(this.typeData.urlDelete(id))
      })
      this.localItems = this.localItems.filter(item => item.id !== id)
      this.$setSnackbar({ text: `${this.typeData.name} видалено` })
    },
    async checkItem(item) {
      const hasDuplicates = this.localItems
        .filter(i => i.id !== item.id)
        .map(this.getItemStr)
        .includes(this.getItemStr(item).trim())
      if (hasDuplicates) {
        this.$setSnackbar({ text: `Даний ${this.typeData.name} вже додано` })
        return Promise.resolve(true)
      }
      if (this.checkFunc) {
        const status = await this.checkFunc(this.getItemStr(item))
        return Promise.resolve(status)
      }
      return Promise.resolve(false)
    },
  },
  mounted() {
    this.localItems = Object.clone(this.items || [])
  },
}
</script>

<style lang="scss" scoped>
.contact-list-wrapper {
  display: flex;
  overflow: hidden;
  align-items: center;
  .contact-title {
    font-size: 0.85rem;
  }
  .contact-item {
    tbody {
      td {
        height: 32px !important;
      }
    }

    .action-wrapper {
      cursor: pointer;
      white-space: nowrap;
      display: inline;
    }
  }
}
.empty-msg {
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.38);
  margin-top: 10px;
  padding-left: 10px;
}
</style>
