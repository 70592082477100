<template>
  <div>
    <send-offer
      v-if="Object.keys(calculation).length && showHeader && tabState"
      v-model:sendOfferDialog="sendOfferDialog"
      :clientOffer="cachedCalculation"
      :dialogAction="dialogAction"
      :currency="selectedCurrency"
      :cb="() => getCalculationById()">
    </send-offer>
    <v-dialog v-model="deleteDialog" max-width="420" persistent>
      <action-card
        title="Розрахунок"
        :closeDialog="() => (deleteDialog = !deleteDialog)"
        :submit="() => deleteCalculation()"
        :reject="() => (deleteDialog = !deleteDialog)">
        <template #body>
          <p style="font-size: 1rem">
            <strong> Калькуляцiю буде видалено! </strong>
          </p>
        </template>
      </action-card>
    </v-dialog>
    <v-fade-transition hide-on-leave>
      <div v-if="availableGraphs.length && !pageLoad">
        <div
          class="pt-0 pl-0 pr-0 pb-0 mb-0"
          :style="
            availableGraphs[0].value === 'oper-leasing'
              ? 'min-height: 400'
              : `min-height: ${pageMinHeight + 'px'} `
          ">
          <v-tabs
            v-show="showTabs"
            v-model="tabState"
            :style="{ display: showTabs ? null : 'none' }"
            class="calc-history-tab"
            :direction="$vuetify.breakpoint?.xs ? 'vertical' : 'horizontal'"
            :height="$vuetify.breakpoint?.xs ? null : 32"
            fixed-tabs
            show-arrows
            hide-slider>
            <v-tab
              v-for="(item, key) in availableGraphs"
              :key="key"
              class="calc-tab"
              :ripple="availableGraphs.length > 1"
              :style="{
                textDecoration:
                  availableGraphs.length > 1 ? null : 'none !important',
                cursor: availableGraphs.length > 1 ? 'pointer' : 'default',
              }"
              style="max-width: 100%"
              :value="item.value"
              :href="'#' + item.value">
              {{ item.name }}
            </v-tab>
          </v-tabs>
          <v-scroll-x-transition hide-on-leave>
            <div class="graph-wrapper mt-3">
              <div v-if="showHeader && tabState" style="min-height: 358px">
                <v-row>
                  <v-col :cols="12" :md="7" class="pb-0">
                    <table class="pb-4 calc-object-table">
                      <tbody>
                        <tr>
                          <td>Предмет лізингу</td>
                          <td>
                            {{ requestData.leasedAssertMark.name }}
                            {{ requestData.leasedAssertModel.name }}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Вартiсть предмету лізингу,
                            {{ (currencyTxt || '').toLowerCase() }}
                          </td>
                          <td>
                            {{
                              getBeautyNum(
                                resultData[tabState]['offer-price-netto'],
                                float
                              ) || '---'
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Вартiсть предмету лізингу з реєстрацією,
                            {{ (currencyTxt || '').toLowerCase() }}
                          </td>
                          <td>
                            {{
                              getBeautyNum(
                                resultData[tabState]['offer-price-brutto'],
                                float
                              ) || '---'
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>Авансовий платiж</td>
                          <td v-html="offerAdvance || '---'"></td>
                        </tr>
                        <tr>
                          <td>Термiн лізингу, мiс</td>
                          <td>
                            {{
                              getBeautyNum(
                                resultData[tabState]['offer-term'],
                                float
                              )
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>Валюта фiнансування</td>
                          <td>
                            {{ getBeautyNum(requestData.currency, float) }}
                          </td>
                        </tr>
                        <tr>
                          <td>Разова комiсiя, %</td>
                          <td v-html="oneTimeCommision || '---'"></td>
                        </tr>
                        <tr>
                          <td>
                            Залишкова вартiсть,
                            {{ (currencyTxt || '').toLowerCase() }}
                          </td>
                          <td>
                            {{
                              getBeautyNum(
                                resultData[tabState]['offer-rest'],
                                float
                              ) || '---'
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Середньомiсячний платiж по лізингу,
                            {{ (currencyTxt || '').toLowerCase() }}
                          </td>
                          <td>
                            {{
                              getBeautyNum(
                                resultData[tabState]['offer-month-payment'],
                                float
                              ) || '---'
                            }}
                          </td>
                        </tr>
                        <tr v-show="requestData.hasMaintenance">
                          <td>Середньомiсячний платiж по сервiсу, грн</td>
                          <td>
                            {{
                              getBeautyNum(
                                resultData?.maintenance?.monthPaymentUAH
                              ) || '---'
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>Сума фінансування, грн</td>
                          <td>
                            {{
                              getBeautyNum(
                                resultData[tabState][
                                  'offer-amount-of-financing'
                                ]
                              ) || '---'
                            }}
                          </td>
                        </tr>
                        <tr
                          v-if="
                            requestData?.leasingProgram === 34 &&
                            calculation.requestData.leasingType !==
                              'Оперативний'
                          ">
                          <td>Сума знижки BEST BENEFITS, грн</td>
                          <td>
                            {{
                              getBeautyNum(
                                resultData[tabState][
                                  'total-amount-compensationUSAID'
                                ]
                              ) || '---'
                            }}
                          </td>
                        </tr>
                        <tr v-if="availableGraphs[0].value === 'oper-leasing'">
                          <td>
                            Нотаріальне оформлення,
                            {{ (currencyTxt || '').toLowerCase() }}
                          </td>
                          <td>
                            {{
                              getBeautyNum(
                                resultData[tabState]['offer-notarization'],
                                float
                              ) || '---'
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </v-col>
                  <!-- Action buttons (right side) -->
                  <v-col
                    :cols="12"
                    :md="5"
                    class="pt-5 pr-8 pb-0 d-flex flex-column justify-space-between text-right pr-3">
                    <div v-if="calculation" class="act-btn-wrapper">
                      <v-row>
                        <v-col :cols="12" :sm="12" class="pt-0 pb-0">
                          <v-btn
                            :disabled="!!calculation.application.readOnly"
                            style="background: #fc7247; color: white"
                            density="comfortable"
                            class="approve-btn no-transform angle mb-2"
                            :dark="!calculation.application.readOnly"
                            @click="
                              () =>
                                calculation.isAgreed
                                  ? rejectCalculation()
                                  : approveCalculation()
                            ">
                            <span>{{
                              calculation.isAgreed
                                ? 'Вiдм-ти пог-ння'
                                : 'Погодити'
                            }}</span>
                          </v-btn>
                        </v-col>
                        <v-col :cols="12" :sm="6" class="pt-0 pb-0">
                          <!--
                            (maybe temporary commented)
                            <v-select
                              @change="changeClientOfferCurrency"
                              v-model="selectedCurrency"
                              :items="selectItems.currencies"
                              item-title="name"
                              item-value="name"
                              class="mt-0"
                              label="Валюта"
                              dense>
                            </v-select>
                          -->
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col :cols="12" class="pt-0 pb-0">
                          <!-- <v-tooltip
                            color="grey darken-3"
                            bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                v-if="calculation.isAgreed"
                                @click="downloadImport"
                                class="ma-1 calc-act-btn"
                                v-on="on"
                                :width="35"
                                :height="35"
                                small
                                fab
                                dark>
                                <v-icon
                                  color="white"
                                  size="20">
                                  {{ 'mdi-download' }}
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>Завантажити імпорт</span>
                          </v-tooltip> -->
                          <v-tooltip
                            color="grey darken-3"
                            bottom
                            text="Надiслати рохрахунок">
                            <template #activator="{ props }">
                              <v-btn
                                class="ma-1"
                                size="small"
                                icon
                                density="comfortable"
                                v-bind="props"
                                @click="openOfferDialog('sendOffer')">
                                <SendCalcIcon />
                              </v-btn>
                            </template>
                            <span>Надiслати рохрахунок</span>
                          </v-tooltip>
                          <v-tooltip
                            color="grey darken-3"
                            bottom
                            text="Редагувати">
                            <template #activator="{ props }">
                              <v-btn
                                class="ma-1"
                                size="small"
                                icon
                                density="comfortable"
                                v-bind="props"
                                @click="
                                  $router.push({
                                    name: getRouteName(),
                                    params: { id: $route.params.calculationId },
                                  })
                                ">
                                <EditIcon />
                              </v-btn>
                            </template>
                            <span>Редагувати</span>
                          </v-tooltip>
                          <v-tooltip
                            v-if="!calculation.isSaved"
                            color="grey darken-3"
                            text="Зберегти розрахунок"
                            bottom>
                            <template #activator="{ props }">
                              <v-btn
                                class="ma-1"
                                size="small"
                                density="comfortable"
                                icon
                                v-bind="props"
                                @click="saveCalculation">
                                <SaveIcon />
                              </v-btn>
                            </template>
                            <span>Зберегти розрахунок</span>
                          </v-tooltip>
                          <v-tooltip
                            color="grey darken-3"
                            bottom
                            text="Видалити розрахунок">
                            <template #activator="{ props }">
                              <v-btn
                                class="ma-1"
                                size="small"
                                density="comfortable"
                                icon
                                :disabled="calculation.readOnly"
                                v-bind="props"
                                @click="
                                  !calculation.isAgreed &&
                                  !calculation.isSendOffer
                                    ? opendDeleteDialog()
                                    : $setSnackbar(
                                        'Неможливо видалити калькуляцiю'
                                      )
                                ">
                                <DeleteIcon />
                              </v-btn>
                            </template>
                            <span>Видалити розрахунок</span>
                          </v-tooltip>
                          <v-tooltip
                            color="grey darken-3"
                            bottom
                            text="Завантажити комерційну пропозицію">
                            <template #activator="{ props }">
                              <v-btn
                                class="ma-1"
                                density="comfortable"
                                size="small"
                                :disabled="!admin"
                                icon
                                v-bind="props"
                                @click="openOfferDialog('downloadOffer')">
                                <ResultIcon />
                              </v-btn>
                            </template>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </div>
                    <div>
                      <div class="mb-2 text-right">
                        <div class="bonus-title mb-1">Графік платежів в:</div>
                        <v-tooltip
                          v-for="(currency, key) in currencies"
                          :key="key"
                          bottom
                          :text="currency.desc">
                          <template #activator="{ props }">
                            <v-btn
                              :color="
                                selectedCurrency === currency?.name
                                  ? '#fc7247'
                                  : 'grey-lighten-2'
                              "
                              :dark="selectedCurrency === currency?.name"
                              icon
                              density="comfortable"
                              class="ml-2 rounded-circle"
                              :style="{
                                background:
                                  selectedCurrency === currency?.name
                                    ? '#fc7247'
                                    : 'white',
                              }"
                              v-bind="props"
                              @click="
                                () => {
                                  if (selectedCurrency !== currency?.name) {
                                    selectedCurrency = currency?.name
                                    changeClientOfferCurrency()
                                  }
                                }
                              ">
                              <span
                                class="currency-txt"
                                :style="{
                                  color:
                                    selectedCurrency === currency?.name
                                      ? 'white'
                                      : 'black',
                                }">
                                {{ currency.icon }}
                              </span>
                            </v-btn>
                          </template>
                          <span>{{ currency.desc }}</span>
                        </v-tooltip>
                      </div>
                    </div>
                  </v-col>
                </v-row>
                <br />
              </div>
              <!-- Payment schedule -->
              <div
                v-if="
                  !operLeasing &&
                  resultData[showImport ? 'import' : tabState].graph.length
                ">
                <v-row class="pt-2">
                  <v-col :cols="12" class="pt-0">
                    <v-data-table
                      :class="
                        requestData?.leasingProgram === 34
                          ? 'payment-benefits'
                          : 'payment-table'
                      "
                      color="black"
                      :headers="
                        requestData.programFSN
                          ? FSNPaymentsTHead
                          : paymentSchHead
                      "
                      :items="tBody"
                      :items-per-page="180"
                      hide-default-footer
                      density="compact"
                      dense>
                      <!-- Program FSN templates START -->
                      <!-- eslint-disable-next-line -->
                      <template #item.paymentFSN="{ item }">
                        <span>
                          {{ getBeautyNum(item.paymentFSN, float) || '---' }}
                        </span>
                      </template>
                      <!-- eslint-disable-next-line -->
                      <template #item.baseRewardUirdIndexFSN="{ item }">
                        <span>
                          {{
                            getBeautyNum(item.baseRewardUirdIndexFSN, float) ||
                            '---'
                          }}
                        </span>
                      </template>
                      <!-- eslint-disable-next-line -->
                      <template
                        #item.additionalServicesCommissionFSN="{ item }">
                        <span>
                          {{
                            getBeautyNum(
                              item.additionalServicesCommissionFSN,
                              float
                            ) || '---'
                          }}
                        </span>
                      </template>
                      <!-- eslint-disable-next-line -->
                      <template #item.programCompensationRewardFSN="{ item }">
                        <span>
                          {{
                            getBeautyNum(
                              item.programCompensationRewardFSN,
                              float
                            ) || '---'
                          }}
                        </span>
                      </template>
                      <!-- eslint-disable-next-line -->
                      <template #item.leasingObjectCompensationFSN="{ item }">
                        <span>
                          {{
                            getBeautyNum(
                              item.leasingObjectCompensationFSN,
                              float
                            ) || '---'
                          }}
                        </span>
                      </template>
                      <!-- Program FSN templates END -->

                      <!-- eslint-disable-next-line -->
                      <template #item.date="{ item }">
                        <span>{{ humanDate(item.date) }}</span>
                      </template>
                      <!-- eslint-disable-next-line -->
                      <template #item.data="{ item }">
                        <span>{{ toFormatDate(item.data, true) }}</span>
                      </template>
                      <!-- eslint-disable-next-line -->
                      <template #item.payment-principal="{ item }">
                        <span>
                          {{
                            getBeautyNum(item['payment-principal'], float) ||
                            '---'
                          }}
                        </span>
                      </template>
                      <!-- eslint-disable-next-line -->
                      <template #item.interest="{ item }">
                        <span>
                          {{ getBeautyNum(item.interest, float) || '---' }}
                        </span>
                      </template>
                      <!-- eslint-disable-next-line -->
                      <template #item.payment="{ item }">
                        <span>
                          {{ getBeautyNum(item.payment, float) || '---' }}
                        </span>
                      </template>
                      <!-- eslint-disable-next-line -->
                      <template #item.compensationRewards="{ item }">
                        <span>
                          {{
                            getBeautyNum(item['compensation-rewards'], float) ||
                            '---'
                          }}
                        </span>
                      </template>
                      <!-- eslint-disable-next-line -->
                      <template #item.commissionAdditionalServices="{ item }">
                        <span>
                          {{
                            getBeautyNum(
                              item['commission-additional-services'],
                              float
                            ) || '---'
                          }}
                        </span>
                      </template>
                      <!-- eslint-disable-next-line -->
                      <template #item.servicePayment="{ item }">
                        <span>
                          {{
                            getBeautyNum(
                              calculation.resultData.maintenance.graph[
                                item.n - 1
                              ].servicePayment,
                              float
                            ) || '0'
                          }}
                        </span>
                      </template>
                      <template #item.amountCompensation="{ item }">
                        <span>
                          {{
                            getBeautyNum(item.amountCompensationUSAID, float) ||
                            '0'
                          }}
                        </span>
                      </template>
                      <template #item.promotionPayment="{ item }">
                        <span>
                          {{
                            getBeautyNum(item.promotionPaymentUSAID, float) ||
                            '0'
                          }}
                        </span>
                      </template>
                      <template #item.amountReward="{ item }">
                        <span>
                          {{
                            getBeautyNum(item.amountRewardUSAID, float) || '---'
                          }}
                        </span>
                      </template>
                      <!-- eslint-disable-next-line -->
                      <template #body.append>
                        <!-- Беніфіт/не погоджена-->
                        <tr
                          v-if="
                            requestData?.leasingProgram === 34 &&
                            !calculation.isAgreed
                          "
                          :class="{
                            'appended-row__mobile': $vuetify.breakpoint?.xs,
                          }"
                          class="appended-row">
                          <td style="font-weight: 600">Всього</td>
                          <td></td>
                          <td
                            style="color: black; font-weight: 600"
                            :class="
                              requestData?.leasingProgram === 34
                                ? 'text-center'
                                : ''
                            ">
                            {{
                              getBeautyNum(
                                total['total-payment-principal'],
                                float
                              ) || '---'
                            }}
                          </td>
                          <td
                            v-if="calculation.requestData.hasMaintenance"
                            style="color: black; font-weight: 600"
                            :class="
                              calculation.requestData.hasMaintenance
                                ? 'text-center'
                                : ''
                            ">
                            <span>
                              {{
                                getBeautyNum(
                                  resultData?.maintenance?.totalServicePayment
                                ) || '---'
                              }}
                            </span>
                          </td>
                          <td
                            style="color: black; font-weight: 600"
                            :class="
                              requestData?.leasingProgram === 34
                                ? 'text-center'
                                : ''
                            ">
                            {{
                              getBeautyNum(total['total-interest'], float) ||
                              '---'
                            }}
                          </td>
                          <td
                            :class="
                              requestData?.leasingProgram === 34
                                ? 'text-center'
                                : ''
                            "
                            style="color: black; font-weight: 600">
                            {{
                              getBeautyNum(
                                total['total-amount-rewardUSAID'],
                                float
                              ) || '---'
                            }}
                          </td>
                          <td
                            :class="
                              requestData?.leasingProgram === 34
                                ? 'text-center'
                                : ''
                            "
                            style="color: black; font-weight: 600">
                            {{
                              getBeautyNum(total['total-payment'], float) ||
                              '---'
                            }}
                          </td>
                          <td
                            :class="
                              requestData?.leasingProgram === 34
                                ? 'text-center'
                                : ''
                            "
                            style="color: black; font-weight: 600">
                            {{
                              getBeautyNum(
                                total['total-promotion-paymentUSAID'],
                                float
                              ) || '---'
                            }}
                          </td>
                          <td
                            v-if="requestData.programFSN"
                            style="color: black; font-weight: 600">
                            {{
                              getBeautyNum(total['total-payment'], float) ||
                              '---'
                            }}
                          </td>
                        </tr>
                        <!-- Бенефіт/погоджена-->
                        <tr
                          v-if="
                            requestData?.leasingProgram === 34 &&
                            calculation.isAgreed
                          "
                          :class="{
                            'appended-row__mobile': $vuetify.breakpoint?.xs,
                          }"
                          class="appended-row">
                          <td style="font-weight: 600">Всього</td>
                          <td></td>
                          <td
                            style="color: black; font-weight: 600"
                            :class="
                              requestData?.leasingProgram === 34
                                ? 'text-center'
                                : ''
                            ">
                            {{
                              getBeautyNum(
                                total['total-payment-principal'],
                                float
                              ) || '---'
                            }}
                          </td>
                          <td
                            v-if="calculation.requestData.hasMaintenance"
                            style="color: black; font-weight: 600"
                            :class="
                              calculation.requestData.hasMaintenance
                                ? 'text-center'
                                : ''
                            ">
                            <span>
                              {{
                                getBeautyNum(
                                  resultData?.maintenance?.totalServicePayment
                                ) || '---'
                              }}
                            </span>
                          </td>
                          <td
                            style="color: black; font-weight: 600"
                            :class="
                              requestData?.leasingProgram === 34
                                ? 'text-center'
                                : ''
                            ">
                            {{
                              getBeautyNum(total['total-interest'], float) ||
                              '---'
                            }}
                          </td>
                          <td
                            class="text-center"
                            style="color: black; font-weight: 600">
                            {{
                              getBeautyNum(
                                total['total-amount-compensationUSAID'],
                                float
                              ) || '---'
                            }}
                          </td>
                          <td
                            :class="
                              requestData?.leasingProgram === 34
                                ? 'text-center'
                                : ''
                            "
                            style="color: black; font-weight: 600">
                            {{
                              getBeautyNum(
                                total['total-amount-rewardUSAID'],
                                float
                              ) || '---'
                            }}
                          </td>
                          <td
                            class="text-center"
                            style="color: black; font-weight: 600">
                            {{
                              getBeautyNum(total['total-payment'], float) ||
                              '---'
                            }}
                          </td>
                          <td
                            v-if="!calculation.isAgreed"
                            :class="
                              requestData?.leasingProgram === 34
                                ? 'text-center'
                                : ''
                            "
                            style="color: black; font-weight: 600">
                            {{
                              getBeautyNum(
                                total['total-promotion-paymentUSAID'],
                                float
                              ) || '---'
                            }}
                          </td>
                        </tr>
                        <!-- Звичайний розрахунок-->
                        <tr
                          v-if="
                            requestData?.leasingProgram !== 34 &&
                            !requestData.programFSN
                          "
                          :class="{
                            'appended-row__mobile': $vuetify.breakpoint?.xs,
                          }"
                          class="appended-row">
                          <td style="font-weight: 600">Всього</td>
                          <td></td>
                          <td
                            style="color: black; font-weight: 600"
                            :class="
                              requestData?.leasingProgram === 34
                                ? 'text-center'
                                : ''
                            ">
                            {{
                              getBeautyNum(
                                total['total-payment-principal'],
                                float
                              ) || '---'
                            }}
                          </td>
                          <td
                            v-if="calculation.requestData.hasMaintenance"
                            style="color: black; font-weight: 600"
                            :class="
                              calculation.requestData.hasMaintenance
                                ? 'text-center'
                                : ''
                            ">
                            <span>
                              {{
                                getBeautyNum(
                                  resultData?.maintenance?.totalServicePayment
                                ) || '---'
                              }}
                            </span>
                          </td>
                          <td
                            style="color: black; font-weight: 600"
                            :class="
                              requestData?.leasingProgram === 34
                                ? 'text-center'
                                : ''
                            ">
                            {{
                              getBeautyNum(total['total-interest'], float) ||
                              '---'
                            }}
                          </td>
                          <td
                            :class="
                              requestData?.leasingProgram === 34
                                ? 'text-center'
                                : ''
                            "
                            style="color: black; font-weight: 600">
                            {{
                              getBeautyNum(total['total-payment'], float) ||
                              '---'
                            }}
                          </td>
                        </tr>
                        <!-- program fsn-->
                        <tr
                          v-if="requestData.programFSN"
                          class="appended-row"
                          :class="{
                            'appended-row__mobile': $vuetify.breakpoint?.xs,
                          }">
                          <td style="font-weight: 600">Всього</td>
                          <td></td>
                          <td
                            style="color: black; font-weight: 600"
                            class="text-center">
                            {{
                              getBeautyNum(
                                total['totalLeasingObjectCompensationFSN'],
                                float
                              ) || '---'
                            }}
                          </td>
                          <td
                            class="text-center"
                            style="color: black; font-weight: 600">
                            {{
                              getBeautyNum(
                                total['totalProgramCompensationRewardFSN'],
                                float
                              ) || '---'
                            }}
                          </td>
                          <td
                            v-if="calculation.requestData.hasMaintenance"
                            style="color: black; font-weight: 600"
                            :class="
                              calculation.requestData.hasMaintenance
                                ? 'text-center'
                                : ''
                            ">
                            <span>
                              {{
                                getBeautyNum(
                                  resultData?.maintenance?.totalServicePayment
                                ) || '---'
                              }}
                            </span>
                          </td>
                          <td
                            style="color: black; font-weight: 600"
                            class="text-center">
                            {{
                              getBeautyNum(
                                total['totalAdditionalServicesCommissionFSN'],
                                float
                              ) || '---'
                            }}
                          </td>
                          <td
                            class="text-center"
                            style="color: black; font-weight: 600">
                            {{
                              getBeautyNum(
                                total['totalBaseRewardUirdIndexFSN'],
                                float
                              ) || '---'
                            }}
                          </td>
                          <td
                            class="text-center"
                            style="color: black; font-weight: 600">
                            {{
                              getBeautyNum(total['paymentFSN'], float) || '---'
                            }}
                          </td>
                        </tr>
                        <span></span>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </div>
              <!-- OperLeasing -->
              <div
                v-if="
                  operLeasing &&
                  resultData[showImport ? 'import' : tabState].graph.length
                ">
                <v-row class="pt-2">
                  <v-col :cols="12" class="pt-0">
                    <v-data-table
                      class="payment-table"
                      color="black"
                      :headers="
                        calculation?.requestData?.hasMaintenance
                          ? paymentSchOperLeasingHeadWithMaintenance
                          : paymentSchOperLeasingHead
                      "
                      :items="tBody"
                      :items-per-page="180"
                      hide-default-footer
                      density="compact"
                      dense>
                      <!-- eslint-disable-next-line -->
                      <template #item.date="{ item }">
                        <span>{{ humanDate(item.date) }}</span>
                      </template>
                      <!-- eslint-disable-next-line -->
                      <template #item.payment-principal="{ item }">
                        <span>
                          {{
                            getBeautyNum(item['payment-principal'], float) ||
                            '---'
                          }}
                        </span>
                      </template>
                      <template #item.servicePayment="{ item }">
                        <span>
                          {{
                            getBeautyNum(
                              calculation.resultData.maintenance.graph[
                                item.n - 1
                              ].servicePayment,
                              float
                            ) || '0'
                          }}
                        </span>
                      </template>
                      <!-- eslint-disable-next-line -->
                      <template #body.append>
                        <!-- oper-leasing-->
                        <!-- HasMaintenance-->
                        <tr
                          v-if="operLeasingMaintenance"
                          class="appended-row"
                          :class="{
                            'appended-row__mobile': $vuetify.breakpoint?.xs,
                          }">
                          <td style="font-weight: 600">Всього</td>
                          <td></td>
                          <td></td>
                          <td style="color: black; font-weight: 600">
                            {{
                              getBeautyNum(
                                resultData?.maintenance?.totalServicePayment
                              ) || '0'
                            }}
                          </td>
                          <td style="color: black; font-weight: 600">
                            {{
                              getBeautyNum(
                                total['total-payment-principal'],
                                float
                              ) || '---'
                            }}
                          </td>
                        </tr>
                        <!-- NotMaintenance-->
                        <tr
                          v-if="!operLeasingMaintenance"
                          class="appended-row"
                          :class="{
                            'appended-row__mobile': $vuetify.breakpoint?.xs,
                          }">
                          <td style="font-weight: 600">Всього</td>
                          <td></td>
                          <td></td>
                          <td style="color: black; font-weight: 600">
                            {{
                              getBeautyNum(
                                total['total-payment-principal'],
                                float
                              ) || '---'
                            }}
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-scroll-x-transition>
        </div>
      </div>
    </v-fade-transition>
    <section-loader v-if="pageLoad"></section-loader>
    <router-view></router-view>
  </div>
</template>

<script>
import SendCalcIcon from '@/assets/svg/calculation/sendCalc.vue'
import ResultIcon from '@/assets/svg/calculation/result.vue'
import SaveIcon from '@/assets/svg/calculation/save.vue'
import DeleteIcon from '@/assets/svg/calculation/delete.vue'
import EditIcon from '@/assets/svg/calculation/edit.vue'
import sendOffer from '@/components/send-offer.vue'
import actionCard from '@/components/action-dialog.vue'
import sectionLoader from '@/components/section-loader.vue'
import { formutSum, humanDate } from '@/utils/formatFunc'
import {
  setClientOfferCurrency,
  getCurrencySign,
  getBeautyNum,
  toFormatDate,
  getSafe,
} from '@/utils/helperFunc'
import { maxNumLength } from '@/utils/masks'
import {
  calculationGet,
  urlDeleteCalculation,
  urlGetSaveCalculation,
} from '@/pages/request'
import { mapState } from 'vuex'
export default {
  components: {
    sendOffer,
    actionCard,
    sectionLoader,
    ResultIcon,
    SaveIcon,
    DeleteIcon,
    EditIcon,
    SendCalcIcon,
  },
  props: {
    noFetchRate: { type: Boolean },
    applicationName: { type: String },
    requestDataProp: { type: Object },
    showHeader: { type: Boolean, default: true },
    pageMinHeight: { type: Number, default: 800 },
    showTabs: { type: Boolean, default: true },
    showImport: { type: Boolean, default: false },
    showExport: { type: [Boolean, Function], default: false },
    float: { type: Number, default: 0 },
  },
  data: () => ({
    selectedCurrency: 'UAH',
    calculation: {},
    cachedCalculation: {},
    tabState: 0,
    pageLoad: false,
    dialogAction: null,
    sendOfferDialog: null,
    deleteDialog: false,
  }),
  computed: {
    ...mapState({
      selectItems: state => state.selectItems,
      user: state => state.user,
    }),
    availableGraphs() {
      if (
        !Object.keys(this.calculation).length ||
        !this.selectItems?.chartTypes?.length
      )
        return []

      const graphKeys = Object.keys(this.calculation.resultData)
      const graphs = this.selectItems.chartTypes.filter(g =>
        graphKeys.includes(g.value)
      )
      const operLeasing = graphs.find(g => g.value === 'oper-leasing')
      const availableGraphs = operLeasing ? [operLeasing] : graphs

      return availableGraphs
    },
    operLeasingMaintenance() {
      return this.calculation?.requestData?.hasMaintenance
    },
    admin() {
      return this.user.roleId === 1
    },
    tBody() {
      if (this.computedShowExport) return this.exportData.graph
      else
        return this.resultData[this.showImport ? 'import' : this.tabState].graph
    },
    total() {
      return this.computedShowExport
        ? this.exportData
        : this.resultData[this.tabState]
    },
    computedShowExport() {
      return typeof this.showExport === 'function'
        ? this.showExport(this.calculation)
        : this.showExport
    },
    currencies() {
      const availableCurrencies = [
        { name: 'EUR', icon: '€', desc: 'Євро' },
        { name: 'USD', icon: '$', desc: 'Долар' },
        { name: 'UAH', icon: '₴', desc: 'Гривня' },
        { name: 'GBP', icon: '£', desc: 'Фунт стерлінгів' },
      ]

      const result = availableCurrencies.filter(currency => {
        switch (this.requestData.leasingCurrency) {
          case 'UAH':
            return true
          case 'USD':
            return ['UAH', 'USD'].includes(currency?.name)
          case 'EUR':
            return ['UAH', 'EUR'].includes(currency?.name)
          case 'GBP':
            return ['UAH', 'GBP'].includes(currency?.name)
          default:
            throw new Error('Invalid requestData.leasingCurrency value')
        }
      })

      return result
    },
    operLeasing() {
      return !!this.resultData['oper-leasing']
    },
    resultData() {
      return this.calculation?.resultData
    },
    exportData() {
      return this.calculation.exportData?.export || this.calculation.exportData
    },
    requestData() {
      return this.calculation?.requestData
    },
    customTdStyle() {
      return `
        font-weight: normal;
        font-size: 0.8rem;`
    },
    routeApplicationEditPaymentPlan() {
      return this.$route.name === 'application-payment-plan'
    },
    routeApplicationNewPaymentPlan() {
      return this.$route.name === 'application-new-payment-plan'
    },
    paymentDestinationHeader() {
      if (this.$route.name === 'application-payment-plan') {
        return []
      } else {
        return [
          {
            title: 'Призначення платежу',
            key: 'title',
            align: 'center',
            sortable: false,
          },
        ]
      }
    },
    paymentSchOperLeasingHead() {
      return [
        { title: '№', key: 'n', align: 'center', sortable: false },
        ...this.paymentDestinationHeader,
        {
          title: 'Термін оплати',
          key: this.computedShowExport ? 'data' : 'date',
          align: 'center',
          sortable: false,
        },
        {
          title: `Загальна сума платежу, ${this.currencyTxt}`,
          key: 'payment-principal',
          align: 'center',
          sortable: false,
        },
      ]
    },
    paymentSchOperLeasingHeadWithMaintenance() {
      return [
        { title: '№', key: 'n', align: 'center', sortable: false },
        ...this.paymentDestinationHeader,
        {
          title: 'Термін оплати',
          key: this.computedShowExport ? 'data' : 'date',
          align: 'center',
          sortable: false,
        },
        {
          title: `Сервісний платіж, ${this.currencyTxt}`,
          key: 'servicePayment',
          align: 'center',
          sortable: false,
        },
        {
          title: `Загальна сума платежу, ${this.currencyTxt}`,
          key: 'payment-principal',
          align: 'center',
          sortable: false,
        },
      ]
    },
    paymentSchHead() {
      if (
        this.requestData?.leasingProgram === 34 &&
        this.calculation.isAgreed
      ) {
        const columns = [
          { title: '№', key: 'n', align: 'center', sortable: false },
          {
            title: 'Дата платежу',
            key: this.computedShowExport ? 'data' : 'date',
            align: 'center',
            sortable: false,
          },
          ...this.paymentDestinationHeader,
          {
            title: `Оплата за техніку, ${this.currencyTxt}`,
            key: 'payment-principal',
            align: 'center',
            sortable: false,
          },
          {
            title: `Винагорода (комісія) Лізингодавцю, ${this.currencyTxt}`,
            key: 'interest',
            align: 'center',
            sortable: false,
            width: '170px',
            fontSize: '12px',
          },
          {
            title: `Розмір компенсації винагороди, за програмою USAID, ${this.currencyTxt}`,
            key: 'amountCompensation',
            align: 'center',
            sortable: false,
            width: '170px',
            fontSize: '12px',
          },
          {
            title: `Акційна винагорода лізингодавця, ${this.currencyTxt}`,
            key: 'amountReward',
            align: 'center',
            sortable: false,
            width: '170px',
          },
          {
            title: `Загальна сума платежу, ${this.currencyTxt}`,
            key: 'payment',
            align: 'center',
            sortable: false,
          },
        ]

        if (this.calculation?.requestData?.hasMaintenance) {
          const servicePaymentColumn = {
            title: `Cервiсний платiж, ${this.currencyTxt}`,
            key: 'servicePayment',
            align: 'center',
            sortable: false,
          }

          columns.splice(3, 0, servicePaymentColumn)
        }

        return columns
      }
      if (
        this.requestData?.leasingProgram === 34 &&
        !this.calculation.isAgreed
      ) {
        const columns = [
          { title: '№', key: 'n', align: 'center', sortable: false },
          {
            title: 'Дата платежу',
            key: this.computedShowExport ? 'data' : 'date',
            align: 'center',
            sortable: false,
          },
          ...this.paymentDestinationHeader,
          {
            title: `Оплата за техніку, ${this.currencyTxt}`,
            key: 'payment-principal',
            align: 'center',
            sortable: false,
          },
          {
            title: `Винагорода (комісія) Лізингодавцю, ${this.currencyTxt}`,
            key: 'interest',
            align: 'center',
            sortable: false,
            width: '170px',
            fontSize: '12px',
          },
          {
            title: `Акційна винагорода лізингодавця, ${this.currencyTxt}`,
            key: 'amountReward',
            align: 'center',
            sortable: false,
            width: '170px',
          },
          {
            title: `Загальна сума платежу, ${this.currencyTxt}`,
            key: 'payment',
            align: 'center',
            sortable: false,
          },
          {
            title: `Акційна сума платежу USAID, ${this.currencyTxt}`,
            key: 'promotionPayment',
            align: 'center',
            sortable: false,
            width: '170px',
            fontSize: '12px',
          },
        ]

        if (this.calculation?.requestData?.hasMaintenance) {
          const servicePaymentColumn = {
            title: `Cервiсний платiж, ${this.currencyTxt}`,
            key: 'servicePayment',
            align: 'center',
            sortable: false,
          }

          columns.splice(3, 0, servicePaymentColumn)
        }

        return columns
      } else {
        const columns = [
          { title: '№', key: 'n', align: 'center', sortable: false },
          ...this.paymentDestinationHeader,
          {
            title: 'Термін оплати',
            key: this.computedShowExport ? 'data' : 'date',
            align: 'center',
            sortable: false,
          },
          {
            title: `Відшкодування (Компенсація) вартості Майна, ${this.currencyTxt}`,
            key: 'payment-principal',
            align: 'center',
            sortable: false,
          },
          {
            title: `Винагорода (комісія) Лізингодавцю, ${this.currencyTxt}`,
            key: 'interest',
            align: 'center',
            sortable: false,
          },
          {
            title: `Загальна сума платежу, ${this.currencyTxt}`,
            key: 'payment',
            align: 'center',
            sortable: false,
          },
        ]

        if (this.calculation?.requestData?.hasMaintenance) {
          const servicePaymentColumn = {
            title: `Cервiсний платiж, ${this.currencyTxt}`,
            key: 'servicePayment',
            align: 'center',
            sortable: false,
          }

          columns.splice(3, 0, servicePaymentColumn)
        }

        return columns
      }
    },
    FSNPaymentsTHead() {
      const columns = [
        { title: '№', key: 'n', align: 'center', sortable: false },
        {
          title: 'Термін оплати',
          key: this.computedShowExport ? 'data' : 'date',
          align: 'center',
          sortable: false,
        },
        ...this.paymentDestinationHeader,
        {
          title: `Відшкодування (компенсація) частини вартості Об'єкта лізингу, ПДВ 20%, ${this.currencyTxt}`,
          key: 'leasingObjectCompensationFSN',
          align: 'center',
          sortable: false,
        },
        {
          title: `Компенсаційна винагорода, за Програмою (5,7,9,11% річних), грн., без ПДВ ${this.currencyTxt}`,
          key: 'programCompensationRewardFSN',
          align: 'center',
          sortable: false,
        },
        {
          title: `Комісія за додаткові послуги, без ПДВ, ${this.currencyTxt}`,
          key: 'additionalServicesCommissionFSN',
          align: 'center',
          sortable: false,
        },
        {
          title: `Базова винагорода (комісія) Індекс UIRD (З міс.) + 5,6,7 без ПДВ, ${this.currencyTxt}`,
          key: 'baseRewardUirdIndexFSN',
          align: 'center',
          sortable: false,
        },
        {
          title: `Розмір Лізингового платежу, який підлягає сплаті Лізингоодержувачем за Програмою ПДВ, ${this.currencyTxt}`,
          key: 'paymentFSN',
          align: 'center',
          sortable: false,
        },
      ]

      if (this.calculation?.requestData?.hasMaintenance) {
        const servicePaymentColumn = {
          title: `Cервiсний платiж, ${this.currencyTxt}`,
          key: 'servicePayment',
          align: 'center',
          sortable: false,
        }

        columns.splice(3, 0, servicePaymentColumn)
      }

      return columns
    },
    currencyTxt() {
      const currency = this.selectedCurrency.toLowerCase()
      const currencySign = getCurrencySign((currency || '').toUpperCase())
      return currency === 'uah' ? 'ГРН' : currencySign
    },
    // v-html
    oneTimeCommision() {
      if (!Object.keys(this.resultData).length || !this.tabState) return '---'

      return `${
        (
          (this.resultData[this.tabState]['offer-administrative-payment-per'] ||
            0) * 100
        ).toFixed(2) +
        '%' +
        ` <span style="${this.customTdStyle}">
                  (${
                    this.$getSafe(
                      () =>
                        this.formutSum(
                          this.resultData[this.tabState][
                            'offer-administrative-payment'
                          ].toFixed()
                        ),
                      0
                    ) +
                    ' ' +
                    (this.currencyTxt || '').toLowerCase()
                  })
                </span>
              `
      }`
    },
    offerAdvance() {
      if (!Object.keys(this.resultData).length || !this.tabState) return '---'
      if (
        !this.resultData[this.tabState]['offer-advance-per'] ||
        !this.resultData[this.tabState]['offer-advance-per']
      )
        return

      return `
        ${(
          parseFloat(this.resultData[this.tabState]['offer-advance-per']) *
            100 || 0
        ).toFixed(2)}%
        <span style="${this.customTdStyle}">
          (${
            this.resultData[this.tabState]['offer-advance']
              .toLocaleString('ru-RU')
              .replace(/,/g, ' ') +
            ' ' +
            (this.currencyTxt || '').toLowerCase()
          })
        </span>`
    },
  },
  methods: {
    getSafe,
    formutSum,
    humanDate,
    toFormatDate,
    maxNumLength,
    calculationGet,
    getBeautyNum,
    urlDeleteCalculation,
    // downloadImport() {
    //   let a = document.createElement('a')
    //   a.href = '/' + this.calculation.excelImportFile
    //   a.download = this.calculation.excelImportFile.substring(11)
    //   a.click()
    // },
    changeClientOfferCurrency() {
      const object = Object.keys(this.cachedCalculation).length
        ? this.cachedCalculation
        : this.calculation
      return setClientOfferCurrency(object, {
        currency: this.selectedCurrency,
        keepIntegers: true,
        showImport: this.showImport,
      })
        .then(offer => {
          Object.assign(this.calculation, offer)
        })
        .catch(this.$err)
    },
    saveCalculation() {
      return this.$axios
        .get(urlGetSaveCalculation(this.$route.params.calculationId))
        .then(res => {
          this.$setSnackbar({ text: 'Калькуляцiю збережено' })
          this.getCalculationById()
          return res
        })
        .catch(this.$err)
    },
    opendDialog() {
      this.deleteDialog = true
    },
    getRouteName() {
      switch (true) {
        case this.routeApplicationNewPaymentPlan:
          return 'application-edit-calculation'
        case this.routeApplicationEditPaymentPlan:
          return 'application-edit-calculation'
        default:
          return 'edit-calculation'
      }
    },
    getCalculationById() {
      this.pageLoad = true

      if (!this.requestDataProp) {
        const id = this.$route?.params?.calculationId
        return this.$axios
          .get(this.calculationGet(id))
          .then(res => {
            // this.selectedCurrency = res.data.requestData.leasingCurrency
            return setClientOfferCurrency(res.data, {
              currency: this.selectedCurrency,
              currencyRate:
                this.selectedCurrency !== 'UAH'
                  ? res.data.requestData.leasingCurrencyCourse
                  : null,
              keepIntegers: true,
              showImport: this.showImport,
            }).then(offer => {
              Object.assign(this.calculation, offer)
              this.tabState = this.availableGraphs[0].value
              this.renameFirstPayName(this.calculation.resultData)
              Object.assign(this.cachedCalculation, offer)
              this.requestDataProp && this.setBreadScrumb()
              if (this.$route.name === 'application-payment-plan')
                this.setBreadScrumb()
              this.pageLoad = false
            })
          })
          .catch(err => this.$err(err, () => (this.pageLoad = false)))
      } else {
        // this.selectedCurrency = this.requestDataProp.requestData.leasingCurrency
        return setClientOfferCurrency(this.requestDataProp, {
          currency: this.selectedCurrency,
          currencyRate:
            this.selectedCurrency !== 'UAH'
              ? this.requestDataProp.requestData.leasingCurrencyCourse
              : null,
          keepIntegers: true,
          showImport: this.showImport,
          noFetchRate: this.noFetchRate,
        }).then(offer => {
          Object.assign(this.calculation, offer)
          this.tabState = this.availableGraphs[0].value
          this.renameFirstPayName(this.calculation.resultData)
          Object.assign(this.cachedCalculation, offer)
          this.requestDataProp && this.setBreadScrumb()
          this.pageLoad = false
        })
      }
    },
    setBreadScrumb() {
      const id = this.calculation?.requestId || ''
      const lastRoute = this.$route.matched[this.$route.matched.length - 1]
      if (this.routeApplicationEditPaymentPlan) {
        return this.$store.commit(
          'setBreadScrumbPart',
          [
            null,
            this.calculation?.application?.applicationName,
            lastRoute ? lastRoute.meta.title + ' - ' + id : null,
          ],
          false
        )
      } else if (this.routeApplicationNewPaymentPlan) {
        return this.$store.commit(
          'setBreadScrumbPart',
          [
            null,
            this.calculation?.application?.applicationName,
            null,
            lastRoute ? lastRoute.meta.title + ' - ' + id : null,
          ],
          false
        )
      }
    },
    opendDeleteDialog() {
      this.deleteDialog = true
    },
    deleteCalculation() {
      return this.$axios
        .delete(this.urlDeleteCalculation(this.$route.params.calculationId))
        .then(() => {
          this.$setSnackbar({ text: 'Рохрахунок видалено' })
          this.$router.push({ name: 'user-calculations' })
        })
        .catch(this.$err)
    },
    renameFirstPayName(resultData) {
      this.availableGraphs.forEach(
        v =>
          resultData?.[v.value].graph?.[0] &&
          (resultData[v.value].graph[0].n = 'Аванс')
      )
    },
    handleApprovement() {
      this.$emit('getCalculations')
      this.getCalculationById()
    },
    approveCalculation() {
      if (this.calculation?.gpsTrackerType?.isActual) {
        this.$store.commit('setDialog', {
          title: 'Погодити розрахунок',
          dialogItem: this.calculation,
          action: 'approveCalculation',
          params: {
            cb: this.handleApprovement,
            hideActions: true,
          },
        })
      } else {
        this.$setSnackbar({
          text: 'Замініть Gps-Tracker в калькуляції на актуальний',
          color: 'warning',
        })
      }
    },
    rejectCalculation() {
      this.$store.commit('setDialog', {
        title: 'Вiдмiнити погодження',
        dialogItem: this.calculation,
        action: 'rejectCalculation',
        params: {
          cb: this.handleApprovement,
        },
      })
    },
    openOfferDialog(action) {
      this.sendOfferDialog = !this.sendOfferDialog
      this.dialogAction = action
    },
  },
  watch: {
    '$route.name': function () {
      this.getCalculationById()
      this.setBreadScrumb()
    },
  },
  async created() {
    await this.$store.dispatch('addChartTypes')
    await this.getCalculationById()
    this.setBreadScrumb()
    this.$emit('getCalculations')
  },
}
</script>

<style lang="scss">
a.calc-tab.v-tab.v-tab-item--selected {
  background: #08487a !important;
  color: white !important;
}
a.calc-tab.v-tab {
  background: #fff !important;
  color: #000 !important;
}
.appended-row {
  &__mobile {
    display: flex !important;
    flex-direction: column !important;
  }
}
</style>

<style scoped lang="scss">
.currency-txt {
  color: white;
  font-size: 1.1rem;
}
.v-data-table tbody tr {
  height: 30px;
}
.calc-object-table {
  width: 100%;
  background: #fff;
  font-size: 0.875rem;
  border-collapse: collapse;
  tbody {
    tr {
      td {
        border-bottom: thin solid rgba(0, 0, 0, 0.12);
        padding: 4px 10px;
        &:last-child {
          border-radius: 0 0 4px 0;
        }
      }
    }
  }
}
</style>
