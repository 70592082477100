<template>
  <div>
    <div v-if="copyAddress" class="pb-4">
      <span style="font-size: 1rem">Копiювати в: </span>
      <div v-if="asyncLoading('addAddressTypes')"><Loader /></div>
      <div v-else>
        <div v-for="(address, key) in filteredAddressTypes" :key="key">
          <v-checkbox
            v-model="selectedAddressTypes"
            :value="address.id"
            :label="address.name"
            class="mt-1"
            color="rgb(210, 74, 67)"
            dense
            hide-details>
          </v-checkbox>
        </div>
      </div>
    </div>
    <div v-if="!copyAddress">
      <v-row>
        <v-col cols="12" md="12" sm="12" class="pt-0">
          <span class="label">Тип</span>
          <v-select
            v-model="newAddress.typeId"
            :error-messages="typeIdErr"
            :items="directoryItems(filteredAddressTypes, newAddress.type)"
            item-title="name"
            item-value="id"
            placeholder="Оберіть зі списку"
            dense
            hide-details
            :loading="
              !directoryItems(filteredAddressTypes, newAddress.type).length &&
              asyncLoading('addAddressTypes')
            "
            :readonly="
              editAddress || newAddress.readonlyAddressTypeId || dealerPoint
            "
            :disabled="
              editAddress || newAddress.readonlyAddressTypeId || dealerPoint
            "
            @keyup.enter="submit()"
            @blur="v$.newAddress.typeId.$touch()">
          </v-select>
        </v-col>
      </v-row>

      <v-checkbox
        v-if="newAddress.typeId === 4"
        v-model="newAddress.canBeEmpty"
        dense
        hide-details
        class="mt-0 pt-0">
        <template #label>
          <span style="font-size: 12px">Адреса відсутня</span>
        </template>
      </v-checkbox>

      <div v-show="!newAddress.canBeEmpty">
        <v-row>
          <v-col cols="12" md="12" sm="12" class="pt-0">
            <span class="label">Країна</span>
            <v-autocomplete
              v-model="newAddress.countryId"
              :items="countries"
              item-title="name"
              hide-details
              item-value="id"
              placeholder="Оберіть зі списку"
              dense>
            </v-autocomplete>
          </v-col>
        </v-row>

        <v-scroll-y-transition>
          <v-row v-show="newAddress.countryId && newAddress.countryId === 1">
            <v-col class="pt-0" cols="12" md="12" sm="12">
              <span class="label">Місто/Село</span>
              <v-autocomplete
                id="va-city"
                ref="city"
                v-model="newAddress.city"
                placeholder="Оберіть зі списку"
                no-filter
                :items="cities"
                :item-title="getCityTextKey"
                item-value="Id"
                return-object
                :error-messages="cityErr"
                hide-details
                @blur="v$.newAddress.city.$touch()">
                <template #item="{ item }">
                  <div
                    class="pa-2 cursor-pointer gap-1 d-flex align-center"
                    @click="selectCity(item.raw)">
                    <span class="d-block">{{
                      item.raw.Region !== item.raw.City ? item.raw.Region : ''
                    }}</span>
                    <span class="d-block"
                      >{{ item.raw.City }}, {{ item.raw.Area }}</span
                    >
                    <span class="d-block">
                      {{ item.raw.Suburb ? 'село ' + item.raw.Suburb : '' }}
                    </span>
                  </div>
                </template>
                <template #selection="{ item }">
                  <div>
                    {{
                      item.raw.Suburb
                        ? item.raw.City + '. село ' + item.raw.Suburb
                        : item.raw.City
                    }}
                  </div>
                </template>
                <template #append-inner>
                  <v-tooltip
                    v-if="newAddress.city"
                    location="bottom"
                    :text="`Стара назва: ${newAddress.city.CityOld} Нова назва: ${newAddress.city.City}`">
                    <template #activator="{ props }">
                      <v-btn
                        v-if="
                          newAddress.city.City &&
                          newAddress.city.CityOld &&
                          newAddress.city.City !== newAddress.city.CityOld
                        "
                        size="x-small"
                        icon
                        v-bind="props"
                        @click="rotateCity">
                        <v-icon size="x-small">{{ 'mdi-rotate-left' }}</v-icon>
                      </v-btn>
                    </template>
                  </v-tooltip>
                </template>
              </v-autocomplete>
            </v-col>

            <v-col
              v-if="newAddress.city"
              class="pt-0"
              cols="12"
              md="12"
              sm="12">
              <span class="label">Область</span>
              <v-text-field
                :value="newAddress.city.Region"
                placeholder="Введіть текст"
                dense
                hide-details
                disabled
                readonly>
                <template #append-inner>
                  <v-tooltip
                    v-if="
                      newAddress.city &&
                      newAddress.city.Region &&
                      newAddress.city.RegionOld &&
                      newAddress.city.Region !== newAddress.city.RegionOld
                    "
                    :text="
                      `Стара назва:``${newAddress.city.RegionOld}``Нова назва ``${newAddress.city.Region}`
                    "
                    bottom>
                    <template #activator="{ props }">
                      <v-btn
                        size="x-small"
                        icon
                        v-bind="props"
                        @click="
                          ;[newAddress.city.Region, newAddress.city.RegionOld] =
                            [newAddress.city.RegionOld, newAddress.city.Region]
                        ">
                        <v-icon small>{{ 'mdi-rotate-left' }}</v-icon>
                      </v-btn>
                    </template>
                  </v-tooltip>
                  <v-btn
                    v-if="
                      newAddress.city &&
                      newAddress.city.Region &&
                      newAddress.city.RegionOld &&
                      newAddress.city.RegionOld !== newAddress.city.Region
                    "
                    small
                    @click="
                      ;[newAddress.city.RegionOld, newAddress.city.Region] = [
                        newAddress.city.Region,
                        newAddress.city.RegionOld,
                      ]
                    ">
                    <v-icon small>{{ 'mdi-rotate-left' }}</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
            <v-col
              v-if="newAddress.city && newAddress.city.Area"
              class="pt-0"
              cols="12"
              md="12"
              sm="12">
              <span class="label">Район</span>
              <v-text-field
                :value="newAddress.city.Area"
                placeholder="Введіть текст"
                dense
                hide-details
                disabled
                readonly>
                <template #append-inner>
                  <v-tooltip
                    v-if="
                      newAddress.city &&
                      newAddress.city.Area &&
                      newAddress.city.AreaOld &&
                      newAddress.city.Area !== newAddress.city.AreaOld
                    "
                    :text="`Стара назва: ${newAddress.city.AreaOld} Нова назва: ${newAddress.city.Area}`"
                    bottom>
                    <template #activator="{ props }">
                      <v-btn
                        size="x-small"
                        icon
                        v-bind="props"
                        @click="
                          ;[newAddress.city.Area, newAddress.city.AreaOld] = [
                            newAddress.city.AreaOld,
                            newAddress.city.Area,
                          ]
                        ">
                        <v-icon small>{{ 'mdi-rotate-left' }}</v-icon>
                      </v-btn>
                    </template>
                    <span
                      >{{
                        `Стара назва: "${newAddress.city.AreaOld}". Нова назва "${newAddress.city.Area}"`
                      }}
                    </span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
            <v-col class="pt-0" cols="12" md="12" sm="12">
              <span class="label">Вулиця/Проспект/Провулок</span>
              <v-autocomplete
                id="va-street"
                ref="street"
                v-model="newAddress.street"
                placeholder="Оберіть зі списку"
                :items="streets"
                no-filter
                hide-details
                item-title="Street"
                item-value="StreetId"
                return-object
                dense
                @change="clearHouse">
                <template #item="{ item }">
                  <div
                    class="pa-2 cursor-pointer d-flex align-center gap-1"
                    @click="selectStreet(item.raw)">
                    <span class="d-block">
                      {{ item.raw.StreetType || '' }}
                    </span>
                    <span class="d-block">{{ item.raw.Street }}</span>
                  </div>
                </template>
                <template #selection="{ item }">
                  <div>
                    {{ item.raw.StreetType || '' }} {{ item.raw.Street }}
                  </div>
                </template>
                <template #append-inner>
                  <v-tooltip
                    v-if="
                      newAddress.street &&
                      newAddress.street.Street &&
                      newAddress.street.StreetOld &&
                      newAddress.street.Street !== newAddress.street.StreetOld
                    "
                    :text="`Стара назва: ${newAddress.street.StreetOld} Нова назва: ${newAddress.street.Street}`"
                    bottom>
                    <template #activator="{ props }">
                      <v-btn
                        size="x=small"
                        icon
                        v-bind="props"
                        @click="rotateStreet">
                        <v-icon small>{{ 'mdi-rotate-left' }}</v-icon>
                      </v-btn>
                    </template>
                  </v-tooltip>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col class="pt-0" cols="12" md="12" sm="12">
              <span class="label">Будинок</span>
              <v-autocomplete
                id="va-house"
                ref="house"
                v-model="newAddress.house"
                v-model:search="searchHouse"
                placeholder="Оберіть зі списку"
                :items="houses"
                hide-details
                item-title="HouseNum"
                item-value="HouseId"
                return-object
                no-filter
                dense
                @focus="houseFocus"
                @input="checkEmpty($event)"
                @change="clearIndex($event)">
                <template #item="{ item, props }">
                  <div
                    v-bind="props"
                    class="pa-2 cursor-pointer d-flex align-center gap-1"
                    @click="selectHouse(item.raw)">
                    <span class="d-block">{{ getHouseText(item.raw) }}</span>
                    <span class="d-block">{{
                      item.raw.Index_ ? ', Iндекс ' + item.raw.Index_ : ''
                    }}</span>
                  </div>
                </template>
                <template #selection="{ item }">
                  {{ getHouseText(item.raw) }}
                </template>
              </v-autocomplete>
            </v-col>
            <v-scroll-y-transition hide-on-leave>
              <v-col v-show="hasApartment" cols="12">
                <div>
                  <span class="label">Тип помешкання</span>
                  <v-select
                    v-model="newAddress.apartmentTypeId"
                    :items="
                      directoryItems(
                        'apartmentTypes',
                        newAddress.apartment_type || newAddress.apartmentType
                      )
                    "
                    item-title="name"
                    item-value="id"
                    placeholder="Оберіть зі списку"
                    dense
                    hide-details
                    :loading="
                      !directoryItems(
                        'apartmentTypes',
                        newAddress.apartment_type || newAddress.apartmentType
                      ).length && asyncLoading('addApartmentTypes')
                    "
                    @focus="asyncAction('addApartmentTypes')"
                    @keyup.enter="submit()">
                  </v-select>
                </div>
                <div v-if="newAddress.apartmentTypeId">
                  <span class="label">{{ addressLabel }}</span>
                  <v-text-field
                    v-model="newAddressHouse.apartmentNum"
                    placeholder="Введіть текст"
                    hide-details
                    dense>
                  </v-text-field>
                </div>
              </v-col>
            </v-scroll-y-transition>
            <v-col v-if="hasApartment" cols="12">
              <v-btn variant="plain" color="error" @click="removeApartment">
                <v-icon>mdi-delete</v-icon>
                <span>Видалити помешкання</span>
              </v-btn>
            </v-col>
            <v-col v-if="!hasApartment" cols="12">
              <v-btn variant="plain" color="error" @click="hasApartment = true">
                <v-icon>mdi-plus</v-icon>
                <span>Додати помешкання</span>
              </v-btn>
            </v-col>

            <v-col class="pt-0" cols="12" md="12" sm="12">
              <span class="label">Індекс</span>
              <v-text-field
                id="address-index"
                v-model="newAddressHouse.Index_"
                placeholder="Введіть індекс"
                :error-messages="houseErr"
                dense
                hide-details
                @input="
                  addressIndexMask(+newAddressHouse.Index_, 'address-index')
                "
                @blur="v$.newAddress.house.Index_.$touch()">
              </v-text-field>
            </v-col>
          </v-row>
        </v-scroll-y-transition>
        <v-scroll-y-transition>
          <v-row v-if="newAddress.countryId && newAddress.countryId !== 1">
            <v-col
              v-if="newAddress.city"
              class="pt-0"
              cols="12"
              md="12"
              sm="12">
              <span class="label">Мiсто/Cело</span>
              <v-text-field
                v-model="newAddress.city.City"
                placeholder="Введіть текст"
                dense
                hide-details>
              </v-text-field>
            </v-col>
            <v-col class="pt-0" cols="12" md="12" sm="12">
              <span class="label">Вулиця/Проспект/Провулок</span>
              <v-text-field
                v-model="newAddress.street.Street"
                placeholder="Введіть текст"
                dense
                hide-details>
              </v-text-field>
            </v-col>
            <v-col class="pt-0" cols="12" md="12" sm="12">
              <span class="label">Будинок</span>
              <v-text-field
                v-model="newAddress.house.HouseNum"
                placeholder="Введіть текст"
                dense
                hide-details></v-text-field>
            </v-col>
            <v-col class="pt-0" cols="12" md="12" sm="12">
              <span class="label">Квартира/офic</span>
              <v-text-field
                v-model="newAddress.house.apartmentNum"
                placeholder="Введіть текст"
                dense
                hide-details>
              </v-text-field>
            </v-col>
            <v-col class="pt-0" cols="12" md="12" sm="12">
              <span class="label">Iндекс</span>

              <v-text-field
                v-model="newAddress.house.Index_"
                v-mask="'#######'"
                placeholder="Введіть текст"
                hide-details
                :error-messages="houseErr"
                dense>
              </v-text-field>
            </v-col>
          </v-row>
        </v-scroll-y-transition>
      </div>
    </div>
  </div>
</template>
<script>
import ObjectMapper from 'object-mapper'
import { required } from '@vuelidate/validators'
import {
  urlRegions,
  urlDistricts,
  urlCities,
  urlCountries,
  urlGetCountry,
  urlAddressCreate,
  urlAddressUpdate,
} from '@/pages/request.js'
import { addressIndexMask, maxNumLength } from '@/utils/masks'
import { contactorDateMap } from '@/pages/objects-map.js'
import { getCities, getStreets, getHouses } from '@/utils/apiAddresses'
import { setErrHandler, v$Notify } from '@/utils/helperFunc'
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import { useVuelidate } from '@vuelidate/core'
import { useSelect } from '@/utils/mixins/useSelect'
export default {
  components: { Loader },
  setup() {
    return { v$: useVuelidate(), ...useSelect() }
  },
  props: {
    id: { type: Object },
    dialogItem: { type: Object },
    deleteProp: { type: String },
    state: { type: Boolean },
    action: { type: String },
    addresses: { type: Array },
  },
  validations() {
    const isRequired =
      this.newAddress.countryId === 1 && !this.newAddress.canBeEmpty
        ? { required }
        : {}
    return {
      newAddress: {
        typeId: (() => {
          if (this.copyAddress) {
            return true
          } else return isRequired
        })(),
        city: isRequired,
        house: {
          Index_: isRequired,
        },
      },
    }
  },
  data: () => ({
    newAddress: {
      canBeEmpty: false,
      typeId: null,
      countryId: 1,
      city: null,
      apartmentTypeId: null,
      readonlyAddressTypeId: false,
      street: {
        Street: null,
        StreetId: null,
        StreetType: null,
        house_moniker: null,
        StreetOld: null,
      },
      house: {
        Index_: null,
        HouseNumAdd: null,
        HouseNum: null,
        apartmentNum: null,
      },
    },
    inputListenerTimeout: null,
    searchHouse: null,
    countries: [],
    regions: [],
    districts: [],
    cities: [],
    streets: [],
    houses: [],
    selectedAddressTypes: [],
    hasApartment: false,
  }),
  computed: {
    ...mapState({
      selectItems: state => state.selectItems,
    }),
    validation() {
      return { v$: this.v$.newAddress, name: 'address' }
    },
    cityDistrict() {
      if (!this.newAddress.city) return
      return this.newAddress.city.Area
    },
    cityArea() {
      if (!this.newAddress.city) return
      return this.newAddress.city.Region
    },
    typeIdErr() {
      return setErrHandler(this.v$.newAddress.typeId)
    },
    cityErr() {
      return setErrHandler(this.v$.newAddress.city)
    },
    houseErr() {
      return setErrHandler(this.v$.newAddress.house.Index_)
    },
    contactorDateMap() {
      return contactorDateMap
    },
    addressesId() {
      if (!this.addresses?.length) return []
      return this.addresses.map(address => address.type.id)
    },
    addAddress() {
      return this.action === 'addAddress'
    },
    editAddress() {
      return this.action === 'editAddress'
    },
    copyAddress() {
      return this.action === 'copyAddress'
    },
    dealerPoint() {
      return this.id.entityTypeId === 5
    },
    changeDealerAddress() {
      return this.action === 'changeDealerAddress'
    },
    getAddress() {
      return this.action === 'getAddress'
    },
    addressTypes() {
      switch (this.id.entityTypeId) {
        case 1:
          return this.selectItems.contactAddressTypes
        case 2:
          return this.selectItems.contractorAddressTypes
        case 5:
          return this.selectItems.contractorAddressTypes.filter(
            a => a.id === 2 /* actual address */
          )
        default:
          return []
      }
    },
    addressLabel() {
      switch (this.newAddress.apartmentTypeId) {
        case 1: {
          return 'Квартира №'
        }
        case 2: {
          return 'Офіс №'
        }
        case 3: {
          return 'Приміщення №'
        }
        default: {
          return ''
        }
      }
    },
    filteredAddressTypes() {
      return this.editAddress
        ? this.addressTypes
        : this.addressTypes.filter(
            address =>
              !this.addressesId.includes(address.id) || address.id === 3
          )
    },
    apiAddressToken() {
      return this.$store.state?.common.token
    },
    newAddressHouse() {
      return this.newAddress?.house || { apartmentNum: null, Index_: null }
    },
    indexPresent() {
      if (this.newAddress.countryId === 1) {
        return (this.newAddressHouse?.Index_?.length || 0) >= 7
      } else return (this.newAddressHouse?.Index_?.length || 0) >= 5
    },
    housePresent() {
      return this.newAddressHouse?.HouseNum
    },
  },
  methods: {
    urlCountries,
    urlRegions,
    urlDistricts,
    urlCities,
    getCities,
    getStreets,
    getHouses,
    urlGetCountry,
    maxNumLength,
    ObjectMapper,
    addressIndexMask,
    selectCity(item) {
      this.newAddress.city = item
      this.$nextTick(() => {
        const autocompleteRefs = this.$refs['city']
        if (Array.isArray(autocompleteRefs)) {
          autocompleteRefs.forEach(ref => {
            ref.menu = false
          })
        } else if (autocompleteRefs && autocompleteRefs.menu) {
          autocompleteRefs.menu = false
        }
      })
    },
    selectStreet(item) {
      this.newAddress.street = item
      this.$nextTick(() => {
        const autocompleteRefs = this.$refs['street']
        if (Array.isArray(autocompleteRefs)) {
          autocompleteRefs.forEach(ref => {
            ref.menu = false
          })
        } else if (autocompleteRefs && autocompleteRefs.menu) {
          autocompleteRefs.menu = false
        }
      })
    },
    selectHouse(item) {
      this.newAddress.house = item
      this.$nextTick(() => {
        const autocompleteRefs = this.$refs['house']
        if (Array.isArray(autocompleteRefs)) {
          autocompleteRefs.forEach(ref => {
            ref.menu = false
          })
        } else if (autocompleteRefs && autocompleteRefs.menu) {
          autocompleteRefs.menu = false
        }
      })
    },
    getCountry() {
      return this.$axios.get(urlGetCountry()).then(res => {
        this.countries = res.data
      })
    },
    getCityTextKey: item =>
      item.Suburb ? item.City + '. село ' + item.Suburb : item.City,
    rotateStreet() {
      const newStreet = this.newAddress.street.Street
      const oldStreet = this.newAddress.street.StreetOld
      const streetIds = this.streets.map(street => street.StreetId)
      const index = streetIds.indexOf(this.newAddress.street.StreetId)

      this.newAddress.street.StreetOld = newStreet
      this.newAddress.street.Street = oldStreet

      if (index !== -1) {
        this.streets.splice(index, 1, this.newAddress.street)
      }
    },
    rotateCity() {
      const newCity = this.newAddress.city.City
      const oldCity = this.newAddress.city.CityOld
      const cityIds = this.cities.map(city => city.Id)
      const index = cityIds.indexOf(this.newAddress.city.Id)

      this.newAddress.city.CityOld = newCity
      this.newAddress.city.City = oldCity

      if (index !== -1) {
        this.cities.splice(index, 1, this.newAddress.city)
      }
    },
    checkEmpty(e) {
      if (!e) {
        Object.assign(this.newAddress, {
          house: {
            Index_: null,
            HouseNum: null,
            HouseNumAdd: null,
            apartmentNum: null,
          },
        })
      }
    },
    pushCities(e) {
      if (e && e.length <= 10) {
        this.getCities(this.apiAddressToken, e)
          .then(res => {
            if (Array.isArray(res)) {
              this.cities.splice(0)
              this.cities.push(...res)
            } else {
              this.$setSnackbar({ text: res, color: 'warning' })
            }
          })
          .catch(this.$err)
      }
    },
    pushStreets(e) {
      if (e && e.length) {
        this.getStreets(
          this.apiAddressToken,
          this.newAddress.city.st_moniker,
          e
        )
          .then(res => {
            if (Array.isArray(res)) {
              this.streets.splice(0)
              this.streets.push(...res)
            } else if (!res || res?.Message) {
              this.streets.splice(0)
              // create custom house
              // e &&
              //   this.streets.push({
              //     Street: e,
              //     StreetId: 0,
              //     house_moniker: 255,
              //   })
            }
          })
          .catch(this.$err)
      } else if (!e) {
        Object.assign(this.newAddress, {
          street: {
            Street: null,
            StreetId: null,
            StreetType: null,
            house_moniker: null,
            StreetOld: null,
          },
        })
      }
    },
    pushHouses(e) {
      if (e && e.length) {
        this.getHouses(
          this.apiAddressToken,
          this.newAddress.street.house_moniker,
          e
        )
          .then(res => {
            if (Array.isArray(res)) {
              const h = JSON.parse(JSON.stringify(res))
              this.houses.splice(0)
              // if (!h.map(el => el.houseNum).includes(e)) {
              //   h.unshift({...res[0], HouseNum: e, HouseId: 0})
              // }
              this.houses.push(...h)
            } else if (!res || res?.Message) {
              this.houses.splice(0)
              // create custom house
              // e && this.houses.push({ HouseNum: e, HouseId: 0 })
            }
          })
          .catch(this.$err)
      } else if (!e) {
        Object.assign(this.newAddress, {
          house: {
            Index_: null,
            HouseNum: null,
            HouseNumAdd: null,
            apartmentNum: null,
          },
        })
      }
    },
    clearAddress() {
      this.newAddress.typeId = null
      this.newAddress.apartmentTypeId = null
      this.newAddress.city = null
      this.newAddress.street.Street = null
      this.newAddress.street.StreetId = null
      this.newAddress.street.StreetType = null
      this.newAddress.street.house_moniker = null
      this.newAddress.street.StreetOld = null
      this.newAddress.house.Index_ = null
      this.newAddress.house.apartmentNum = null
      this.newAddress.house.HouseNum = null
      this.newAddress.house.HouseNumAdd = null
    },
    clearSelects() {
      this.regions.splice(0)
      this.districts.splice(0)
      this.cities.splice(0)
      this.streets.splice(0)
      this.houses.splice(0)
    },
    clearStreetHouse() {
      this.newAddress.street.Street = null
      this.newAddress.street.StreetId = null
      this.newAddress.street.StreetType = null
      this.newAddress.street.StreetOld = null
      this.newAddress.street.house_moniker = null
      this.newAddress.house.Index_ = null
      this.newAddress.house.HouseNum = null
      this.newAddress.house.apartmentNum = null
      this.newAddress.house.HouseNumAdd = null
    },
    clearHouse() {
      this.newAddress.house.Index_ = null
      this.newAddress.house.HouseNum = null
      this.newAddress.house.apartmentNum = null
      this.newAddress.house.HouseNumAdd = null
    },
    clearIndex(e) {
      if (!e) this.newAddress.house.Index_ = null
    },
    removeApartment() {
      const clearIfExist = (obj, fieldName) => {
        if (obj[fieldName]) {
          obj[fieldName] = null
        }
      }
      this.hasApartment = false

      clearIfExist(this.newAddress, 'apartmentTypeId')
      clearIfExist(this.newAddress, 'apartment_type')
      clearIfExist(this.newAddress, 'apartmentType')
      clearIfExist(this.newAddressHouse, 'apartmentNum')
    },
    syncData() {
      Object.assign(this.newAddress, {
        ...this.dialogItem,
        house: { ...this.dialogItem.house },
        street: { ...this.dialogItem.street },
        city: { ...this.dialogItem.city },
        entityId: this.id.entityId,
        entityTypeId: this.id.entityTypeId,
      })
      if (!this.addAddress) {
        this.cities.splice(0)
        this.streets.splice(0)
        this.houses.splice(0)
        this.newAddress?.city?.Id && this.cities.push(this.newAddress.city)
        this.newAddress?.street?.StreetId &&
          this.streets.push(this.newAddress.street)
        this.newAddress?.house?.HouseId &&
          this.houses.push(this.newAddress.house)
        this.newAddress.typeId =
          this.dialogItem.typeId || this.dialogItem?.type?.id
        this.newAddress.apartmentTypeId =
          this.dialogItem.apartmentTypeId ||
          this.dialogItem?.apartmentType?.id ||
          this.dialogItem?.apartment_type?.id
      }

      setTimeout(() => {
        this.copyAddress && (this.newAddress.typeId = null)
      }, 0)

      if (this.newAddress.apartmentTypeId) {
        this.hasApartment = true
      }
    },
    handleAddress(url) {
      const req = Object.clone(this.newAddress)

      delete req.apartmentType

      // заявка на реєстрацію відсутня
      if (req.canBeEmpty && this.addAddress) {
        Object.keys(req).forEach(key => {
          if (
            key !== 'canBeEmpty' &&
            key !== 'typeId' &&
            key !== 'entityTypeId' &&
            key !== 'entityId'
          ) {
            req[key] = null
            req.countryId = null
          }
        })
      } else if (req.house.HouseNumAdd) {
        req.house.HouseNum = req.house.HouseNum.replace(
          req.house.HouseNumAdd,
          ''
        ).trim()
      }

      return this.$axios
        .post(url(this.newAddress.id), req)
        .then(res => {
          this.clearAddress()
          return res.data
        })
        .catch(this.$err)
    },
    async copyAddresses() {
      const requests = this.selectedAddressTypes.map(address => {
        this.newAddress.typeId = address

        return this.$axios
          .post(urlAddressCreate(), this.newAddress)
          .then(res => {
            return res.data
          })
          .catch(this.$err)
      })

      return Promise.all(requests).then(res => {
        this.clearAddress()
        return res
      })
    },
    assignId() {
      Object.assign(this.newAddress, this.id)
    },
    highLightErr() {
      this.v$.$anyError
      this.v$.$touch()
      v$Notify(this.v$.newAddress, 'address')
    },
    submit() {
      switch (true) {
        case this.changeDealerAddress:
          return Promise.resolve(this.newAddress)
        case this.getAddress:
          return Promise.resolve(this.newAddress)
        case this.copyAddress:
          return this.copyAddresses()
        case this.addAddress:
          return this.handleAddress(urlAddressCreate)
        case this.editAddress:
          return this.handleAddress(urlAddressUpdate)
      }
    },
    sanityzeElValue(id, cb) {
      clearTimeout(this.inputListenerTimeout)
      const el = document.getElementById(id)

      const value = (el.value || '').replace(/[ёъыэA-Z]/gi, '')
      if (value !== el.value) {
        el.value = value
        el.dispatchEvent(new Event('input'))
      }
      this.inputListenerTimeout = setTimeout(() => {
        Promise.resolve(el.value).then(cb)
      }, 500)
    },
    getHouseText(house) {
      const houseNum =
        house.HouseNum?.replace(house.HouseNumAdd, '').trim() || ''
      return `${houseNum} ${house.HouseNumAdd || ''}`.trim()
    },
    addListeners() {
      const cities = document.querySelector('#va-city')
      const streets = document.querySelector('#va-street')
      // const houses = document.querySelector('#va-house')

      if (cities) {
        cities.addEventListener('input', e =>
          this.sanityzeElValue('va-city', () => this.pushCities(e.target.value))
        )
        cities.addEventListener('paste', e =>
          this.sanityzeElValue('va-city', () => this.pushCities(e.target.value))
        )
      }
      if (streets) {
        streets.addEventListener('input', e =>
          this.sanityzeElValue('va-street', () =>
            this.pushStreets(e.target.value)
          )
        )
        streets.addEventListener('paste', e =>
          this.sanityzeElValue('va-street', () =>
            this.pushStreets(e.target.value)
          )
        )
      }
      // if (houses) {
      //   houses.addEventListener('input', e =>
      //     this.sanityzeElValue('va-house', () => this.pushHouses(e.target.value))
      //   )
      //   houses.addEventListener('paste', e =>
      //     this.sanityzeElValue('va-house', () => this.pushHouses(e.target.value))
      //   )
      // }
    },
    removeListeners() {
      const cities = document.querySelector('#va-city')
      const streets = document.querySelector('#va-street')
      const houses = document.querySelector('#va-house')

      if (cities) {
        cities.removeEventListener('input', e => {
          this.pushCities(e.target.value)
        })
        cities.removeEventListener('paste', e =>
          this.pushCities(e.target.value)
        )
      }
      if (streets) {
        streets.removeEventListener('input', e =>
          this.pushStreets(e.target.value)
        )
        streets.removeEventListener('paste', e =>
          this.pushStreets(e.target.value)
        )
      }
      if (houses) {
        houses.removeEventListener('input', e =>
          this.pushHouses(e.target.value)
        )
        houses.removeEventListener('paste', e =>
          this.pushHouses(e.target.value)
        )
      }
    },
    addAddressTypes() {
      const action =
        this.id.entityTypeId === 1
          ? 'addContactAddressTypes'
          : 'addContractorAddressTypes'
      return this.asyncAction('addAddressTypes', null, () =>
        this.$store.dispatch(action)
      )
    },
    houseFocus() {
      const input = document.getElementById('va-house')

      this.$nextTick(() => {
        input.setSelectionRange(input.selectionStart, input.selectionStart)
      })
    },
  },
  watch: {
    state(val) {
      this.v$.$reset()
      if (val) {
        this.clearAddress()
        this.assignId()
        this.clearSelects()
        if (!this.addAddress) {
          this.syncData()
        }
      }
    },
    // 'newAddress.house.HouseNumAdd': {
    //   handler() {
    //     if (this.newAddress.house) {
    //       this.newAddress.house.HouseNum = this.getHouseText(this.newAddress.house)
    //     }
    //   },
    // },
    searchHouse: {
      handler(val) {
        if (val && val !== this.getHouseText(this.newAddress.house)) {
          this.sanityzeElValue('va-house', this.pushHouses)
        }
      },
    },
    // add house which not exist in Data Base
    houses: {
      handler(v) {
        if (
          this.dialogItem?.house &&
          !this.addAddress &&
          v.findIndex(el => el.houseNum === this.dialogItem.house.houseNum) < 0
        ) {
          this.houses.push(this.dialogItem.house)
        }
      },
      immediate: true,
    },
    // add street which not exist in Data Base
    streets: {
      handler(v) {
        if (
          this.dialogItem?.street &&
          !this.addAddress &&
          v.findIndex(el => el.streetId === this.dialogItem.street.streetId) < 0
        ) {
          this.streets.push(this.dialogItem.street)
        }
      },
      immediate: true,
    },
  },
  created() {
    !this.editAddress && this.addAddressTypes()
  },
  mounted() {
    this.getCountry()
    this.assignId()
    !this.addAddress && this.syncData()
    this.addListeners()
    if (this.dealerPoint)
      this.newAddress.typeId = 2 /* actual address for dealer point */
    this.$watch('newAddress.countryId', value => {
      if (value === 1) {
        Object.assign(this.newAddress, {
          city: null,
          readonlyAddressTypeId: false,
          street: {
            Street: null,
            StreetId: null,
            StreetType: null,
            house_moniker: null,
            StreetOld: null,
          },
          house: {
            Index_: null,
            HouseNumAdd: null,
            HouseNum: null,
            apartmentNum: null,
          },
        })
      } else {
        Object.assign(this.newAddress, {
          city: {
            City: null,
          },
          street: {
            Street: null,
          },
          house: {
            Index_: null,
            HouseNum: null,
            apartmentNum: null,
          },
        })
      }
    })
  },
  beforeUnmount() {
    this.removeListeners()
  },
}
</script>
