<template>
  <AddAddress
    :id="getSafe(() => dialog.params.id, null)"
    ref="address"
    :dialogItem="dialog.dialogItem"
    :action="dialog.action"
    :addresses="getSafe(() => dialog.params.addresses, [])">
  </AddAddress>
</template>
<script>
import AddAddress from '@/components/address.vue'
import { getSafe } from '@/utils/helperFunc'
export default {
  components: { AddAddress },
  props: { dialog: { type: Object } },
  computed: {
    v$() {
      return this.$refs.address.v$
    },
    validation() {
      return {
        v$: this.v$.newAddress,
        name: 'address',
      }
    },
    addAddress() {
      return this.dialog.action === 'addAddress'
    },
    editAddress() {
      return this.dialog.action === 'editAddress'
    },
    copyAddress() {
      return this.dialog.action === 'copyAddress'
    },
  },
  methods: {
    getSafe,
    submit() {
      return new Promise((resolve, reject) => {
        return this.$refs.address
          .submit()
          .then(res => resolve(res))
          .catch(err => reject(err))
      })
    },
  },
}
</script>
