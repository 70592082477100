<template>
  <div style="position: relative">
    <div class="white-block">
      <v-tabs
        show-arrows
        :direction="$vuetify.breakpoint?.xs ? 'vertical' : 'horizontal'"
        :height="$vuetify.breakpoint?.xs ? null : 38"
        background-color="grey darken-2"
        color="white"
        dark
        slider-color="red"
        dense>
        <v-tab
          :to="{
            name: 'fd-client-verification',
            params: { id: $route.params.id, tabState: $route.params.tabState },
          }"
          >Клієнт</v-tab
        >
        <v-tab
          v-for="(_provider, index) in providers"
          :key="index"
          :to="{
            name: 'fd-provider-verification',
            params: {
              idx: index + 1,
              id: $route.params.id,
              tabState: $route.params.tabState,
            },
          }">
          <span> Постачальник {{ getProviderName(providers[index]) }} </span>
        </v-tab>
        <v-tab
          v-for="(item, index) in guarantors"
          :key="index"
          :to="{
            name: 'fd-guarantor-verification',
            params: {
              idx: index + 1,
              id: $route.params.id,
              tabState: $route.params.tabState,
            },
          }">
          <span
            style="
              overflow: hidden;
              display: block;
              white-space: nowrap;
              text-overflow: ellipsis;
            "
            >Поручитель {{ getGuarantorName(guarantors[index]) }}</span
          >
        </v-tab>
        <v-tab
          v-for="(item, index) in subtenants"
          :key="index"
          :to="{
            name: 'fd-subtenant-verification',
            params: {
              idx: index + 1,
              id: $route.params.id,
              tabState: $route.params.tabState,
            },
          }">
          <span
            style="
              overflow: hidden;
              display: block;
              white-space: nowrap;
              text-overflow: ellipsis;
            "
            >Суборедатор {{ getSubtenantName(subtenants[index]) }}</span
          >
        </v-tab>
        <v-tab
          v-if="FDAnalyze.statusId === 4"
          :to="{
            name: 'fd-risk',
            params: { id: $route.params.id, tabState: $route.params.tabState },
          }">
          Підтвердження документів
        </v-tab>
        <v-tab
          :to="{
            name: 'fd-history',
            params: { id: $route.params.id, tabState: $route.params.tabState },
          }"
          >Історія</v-tab
        >
        <v-tab
          :to="{
            name: 'fd-analyze',
            params: { id: $route.params.id, tabState: $route.params.tabState },
          }"
          >Аналiз</v-tab
        >
      </v-tabs>
    </div>
    <SectionLoader v-if="loading" />

    <RouterView
      v-else
      :id="id"
      v-model:FDAnalyze="FDAnalyze"
      :guarantors="guarantors"
      :subtenants="subtenants"
      :loading="loading"
      :providers="providers"
      :submitLoading="submitLoading"
      :acceptDocumentsLoading="acceptDocumentsLoading"
      :project="FDAnalyze.project"
      :financialRiskLevelCache="financialRiskLevelCache"
      :documentsAccepted="documentsAccepted"
      :v="v$"
      @get="getFinancialAnalyzesById()"
      @submit="submit"
      @accept-documents="acceptDocuments" />
  </div>
</template>
<script>
import SectionLoader from '@/components/section-loader.vue'
import { required } from '@vuelidate/validators'
import {
  urlFinancialMonitoringConfirmationLevel,
  urlGetSingleFinancialMonitoringAnalyze,
  urlSetFinancingMonitoringStatus,
} from '@/pages/request'
import {
  getProjectGuarantors,
  getProjectSubtenants,
  getContactInitials,
} from '@/utils/helperFunc'
import { useVuelidate } from '@vuelidate/core'

export default {
  components: {
    SectionLoader,
  },
  setup() {
    return { v$: useVuelidate() }
  },
  validations() {
    return {
      FDAnalyze: {
        financialRiskLevelId: { required },
        clientSanctionDocument: { required },
        providerSanctionDocument: { required },
        financialRiskAssessment: { required },
        financialRiskAssessmentNote: { required },
      },
    }
  },
  data: () => ({
    FDAnalyze: {},
    loading: false,
    submitLoading: false,
    acceptDocumentsLoading: false,
    documentsAccepted: false,
    conclusion: {
      providerConclusion: null,
      financialRiskAssessment: null,
    },
    financialRiskLevelCache: null,
  }),
  computed: {
    id() {
      return this.$route.params.id
    },
    guarantors() {
      if (!Object.keys(this.FDAnalyze).length) return []
      return getProjectGuarantors(this.FDAnalyze.guarantors)
    },
    subtenants() {
      if (!Object.keys(this.FDAnalyze).length) return []
      return getProjectSubtenants(this.FDAnalyze.subtenants)
    },
    client() {
      const entity =
        this.FDAnalyze.client.leasingClientType === 2 ? 'contractor' : 'contact'
      return this.FDAnalyze.client[entity]
    },
    typeContractor() {
      return this.FDAnalyze.client.leasingClientType === 2
    },
    providers() {
      return this.FDAnalyze.providers || []
    },
  },
  methods: {
    getContactInitials,
    urlSetFinancingMonitoringStatus,
    getProviderName(provider) {
      if (provider.providerTypeId === 2) {
        return provider.contractor.shortName
      } else {
        return this.getContactInitials(provider.contact)
      }
    },
    getGuarantorName(guarantor) {
      if (guarantor.guarantorTypeId === 2) {
        return guarantor.contractor.shortName
      } else {
        return this.getContactInitials(guarantor.contact)
      }
    },
    getSubtenantName(subtenant) {
      if (subtenant.subtenantTypeId === 2) {
        return subtenant.contractor.shortName
      } else {
        return this.getContactInitials(subtenant.contact)
      }
    },
    getFinancialAnalyzesById() {
      this.loading = true
      return this.$axios
        .get(urlGetSingleFinancialMonitoringAnalyze(this.id))
        .then(res => {
          if (res.data.message) {
            this.$setSnackbar({ text: res.data.message, color: 'warning' })
            this.$router.push({ name: 'main' })
            return
          }
          Object.assign(this.FDAnalyze, res.data)
          this.financialRiskLevelCache = res.data.financialRiskLevelId
          this.documentsAccepted = res.data.isConfirmation
          if (res.data.financialRiskAssessment && res.data.providerConclusion) {
            this.conclusion.financialRiskAssessment =
              res.data.financialRiskAssessment
            this.conclusion.providerConclusion = res.data.providerConclusion
          }
          this.loading = false
          return res
        })
        .catch(this.$err)
    },
    async submit() {
      this.submitLoading = true

      return this.$axios
        .post(this.urlSetFinancingMonitoringStatus(this.id), {
          financialRiskLevelId: this.FDAnalyze.financialRiskLevelId,
          statusId: 4,
          financialRiskAssessment: this.FDAnalyze.financialRiskAssessment,
        })
        .then(async res => {
          if (res.data.status === 400) {
            return this.$setSnackbar({
              text: res.data.message,
              color: 'warning',
            })
          }
          this.$setSnackbar({ text: 'Статус змiнено' })
          this.submitLoading = false
          await this.getFinancialAnalyzesById()
          return res
        })
        .catch(this.$err)
    },
    async acceptDocuments() {
      this.acceptDocumentsLoading = true

      const {
        isSignedQuestionnaire,
        isSignedPassport,
        isSignedOwnershipStructure,
      } = this.FDAnalyze
      const requestObj = {
        isSignedQuestionnaire,
        isSignedPassport,
        isSignedOwnershipStructure,
      }
      return this.$axios
        .post(urlFinancialMonitoringConfirmationLevel(this.id), requestObj)
        .then(() => {
          this.$setSnackbar({ text: 'Документи підтверджено' })
          this.documentsAccepted = true
        })
        .finally(() => (this.acceptDocumentsLoading = false))
    },
    setBreadScrumb(res) {
      this.$store.commit('setBreadScrumbPart', [
        null,
        null,
        'Верифікація: ' + (this.contact?.name || this.contractor?.shortName),
      ])
      return res
    },
  },
  created() {
    this.getFinancialAnalyzesById()
  },
}
</script>
<style scoped lang="scss">
.v-tab-item--selected.v-tab {
  font-weight: 700 !important;
  color: #08487a !important;
  font-size: 13px;
}
.v-tab {
  color: #838282 !important;
  font-size: 13px;
  letter-spacing: normal;
  text-transform: uppercase;
}
</style>
