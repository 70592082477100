<template>
  <div class="verification-card">
    <div
      class="d-flex align-center justify-space-between"
      style="
        background: #fff;
        padding: 13px;
        margin-bottom: 10px;
        margin-top: -20px;
        padding-top: 25px;
      ">
      <div class="pb-0 d-flex">
        <div class="data-card">
          <span style="font-size: 13px; color: #838282" class="pb-1 d-block"
            >Тип звернення</span
          >
          <span class="subtitle" style="font-size: 14px !important"
            >{{
              sourceTypeId === 3 ? 'Повторне звернення' : 'Первинне звернення'
            }}
          </span>
        </div>
        <div class="data-card">
          <span style="font-size: 13px; color: #838282" class="pb-1 d-block"
            >Дата звернення</span
          >
          <span class="subtitle" style="font-size: 14px !important">{{
            applicationDate
          }}</span>
        </div>
        <div class="data-card">
          <span style="font-size: 13px; color: #838282" class="pb-1 d-block"
            >Тип аналізу</span
          >
          <span class="subtitle" style="font-size: 14px !important">{{
            ssAnalyze.project?.slider?.analysisType?.name
          }}</span>
        </div>
        <div v-if="showBenefit" class="data-card">
          <v-tooltip bottom text="BEST BENEFITS">
            <template #activator="{ props }">
              <Benefit width="44px" height="44px" v-bind="props" />
            </template>
            <span>BEST BENEFITS</span>
          </v-tooltip>
        </div>
      </div>
      <div>
        <div class="data-card">
          <span class="area-label">Статус</span>
          <span class="statusName">{{ ssAnalyze.status?.name || '---' }}</span>
        </div>
      </div>
    </div>

    <ContractorCard
      v-if="typeContractor"
      :contractor="client"
      :isNew="ssAnalyze.status?.id === 1 && roleAccess"
      contactDetails
      showKbvDocs>
      <template #main-info>
        <OpenableList class="pb-1" style="margin-top: -10px; margin-left: -5px">
          <FilesUploader
            readonly
            label="Заявка-анкета"
            :documents="ssAnalyze.project.slider.applicationForm" />
          <FilesUploader
            readonly
            label="Опитувальник"
            :documents="ssAnalyze.project.slider.questionnaire" />
        </OpenableList>
      </template>
    </ContractorCard>

    <ContactCard
      v-if="typeContact"
      :contact="client"
      marriedDocs
      :isNew="ssAnalyze.status?.id === 1 && roleAccess">
      <template #main-info>
        <OpenableList class="pb-1" style="margin-top: -10px; margin-left: -5px">
          <FilesUploader
            readonly
            label="Заявка-анкета"
            :documents="ssAnalyze.project.slider.applicationForm" />
          <FilesUploader
            readonly
            label="Опитувальник"
            :documents="ssAnalyze.project.slider.questionnaire" />
          <FilesUploader
            readonly
            label="Паспорт"
            :documents="client.identityDocument.document" />
          <FilesUploader
            readonly
            label="Ідентифiкацiйний номер"
            :documents="client.identificationNumber" />
          <FilesUploader
            readonly
            label="Свідоцтво про укладення/розірвання шлюбу"
            :documents="client.marriageCertificate" />
        </OpenableList>
      </template>
    </ContactCard>

    <div class="white-block" style="margin-top: 10px">
      <span class="card-label d-block" style="margin-bottom: -20px"
        >Відповідальні:</span
      >
      <div class="section-card">
        <div>
          <v-row>
            <v-col :cols="12" :sm="3" class="pt-0 pb-0"> </v-col>
            <v-col :cols="12" :sm="9" class="pt-0 pb-0 text-start">
              <span class="card-sublable mr-1">Фронт менеджер:</span>
              <span class="card-value">{{ frontManager }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col :cols="12" :sm="3" class="pt-0 pb-0"> </v-col>
            <v-col :cols="12" :sm="9" class="pt-0 pb-0 text-start">
              <span class="card-sublable mr-1">Мiдл менеджер:</span>
              <span class="card-value">{{ middleManager }}</span>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
    <div class="white-block" style="margin-top: 10px">
      <v-row>
        <v-col :cols="12" :md="3">
          <span>Коментар:</span>
        </v-col>
        <v-col :cols="12" :md="9">
          <v-textarea
            v-model="clientConclusion"
            row-height="30"
            rows="4"
            hide-details
            placeholder="Напишіть коментар"
            :disabled="!isInWork"
            :readonly="!isInWork">
          </v-textarea>
        </v-col>
      </v-row>
    </div>

    <ActionButtons
      v-if="client"
      class="mt-5"
      :confirm="() => clientConclusionFunc(1)"
      :cancel="() => clientConclusionFunc(2)"
      confirmBtnText="Позитивне рішення"
      cancelBtnText="Негативне рішення"
      :confirmLoading="false"
      :cancelLoading="false"
      :cancelDisable="!isInWork || ssAnalyze.clientResultId === 2"
      :confirmDisable="!isInWork || ssAnalyze.clientResultId === 1">
    </ActionButtons>
  </div>
</template>
<script>
import FilesUploader from '@/components/FilesUploader.vue'
import ActionButtons from '@/components/action-buttons.vue'
import ContactCard from '@/components/ContactCard.vue'
import ContractorCard from '@/components/ContractorCard.vue'
import { formatDate, humanDate } from '@/utils/formatFunc'
import OpenableList from '@/components/OpenableList.vue'
import Benefit from '@/assets/svg/benefit.vue'
import { calculation } from '@/type-ids'
import { mapState } from 'vuex'
export default {
  emits: ['setConclusion'],
  components: {
    Benefit,
    OpenableList,
    ActionButtons,
    FilesUploader,
    ContactCard,
    ContractorCard,
  },
  props: {
    ssAnalyze: { type: Object },
    isInWork: { type: Boolean },
    conclusionsState: { type: Object },
  },
  data: () => ({
    clientConclusion: null,
  }),
  computed: {
    ...mapState({
      user: state => state.user,
    }),
    roleAccess() {
      return [2, 9].includes(this.user?.role?.id)
    },
    calculation() {
      return calculation
    },
    id() {
      return this.$route.params.id
    },
    client() {
      return this.ssAnalyze?.project?.lessee
    },
    typeContractor() {
      return this.ssAnalyze?.project?.leasingClientType === 2
    },
    typeContact() {
      return [1, 3].includes(this.ssAnalyze?.project?.leasingClientType)
    },
    sourceTypeId() {
      return this.ssAnalyze?.project?.slider?.application?.sourceTypeId
    },
    frontManager() {
      const m = this.ssAnalyze?.project?.frontManagerResponsible?.fullName
      if (!m) return '---'
      return m
    },
    middleManager() {
      const m = this.ssAnalyze?.project?.middleManagerResponsible?.fullName
      if (!m) return '---'
      return m
    },
    applicationDate() {
      const date = this.ssAnalyze?.project?.slider?.application?.created_at
      if (!date) return
      return this.humanDate(this.formatDate(date, true))
    },
    showBenefit() {
      if (this.ssAnalyze && this.ssAnalyze.calculations) {
        return this.ssAnalyze.calculations.some(t => t.leasingProgram === 34)
      }
      return false
    },
  },
  methods: {
    formatDate,
    humanDate,
    clientConclusionFunc(resultId) {
      const request = {
        clientResultId: resultId,
        clientConclusion: this.clientConclusion,
        type: 1,
      }
      this.$emit('setConclusion', { request })
    },
    joinStr(...args) {
      return args.filter(str => str).join(' ')
    },
  },
  watch: {
    conclusionsState: {
      handler(val) {
        this.clientConclusion = val.clientConclusion
      },
      deep: true,
      immediate: true,
    },
  },
}
</script>

<style scoped>
.card-label {
  color: #000 !important;
}
.card-sublable {
  color: #838282;
  font-weight: 500;
  font-size: 13px;
}
.subtitle {
  color: #000;
}
.statusName {
  color: #fc7247;
  font-size: 14px;
}
.data-card {
  display: flex;
  flex-direction: column;
  margin-right: 25px;
}
</style>
