<template>
  <div v-if="participantData" class="mt-3">
    <v-card-title class="d-flex align-center">
      <svg
        class="d-block"
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="20"
        viewBox="0 0 15 17"
        fill="none">
        <path
          d="M7.22271 16.8939L4.81514 14.4956H1.60505C1.16366 14.4956 0.78567 14.3392 0.471081 14.0263C0.157027 13.713 0 13.3364 0 12.8968V1.70481C0 1.26512 0.157027 0.888594 0.471081 0.575219C0.78567 0.262378 1.16366 0.105957 1.60505 0.105957H12.8404C13.2818 0.105957 13.6597 0.262378 13.9743 0.575219C14.2884 0.888594 14.4454 1.26512 14.4454 1.70481V12.8968C14.4454 13.3364 14.2884 13.713 13.9743 14.0263C13.6597 14.3392 13.2818 14.4956 12.8404 14.4956H9.63028L7.22271 16.8939ZM7.22271 8.1002C7.99848 8.1002 8.66056 7.82707 9.20895 7.28079C9.75734 6.73452 10.0315 6.07499 10.0315 5.30222C10.0315 4.52944 9.75734 3.86991 9.20895 3.32364C8.66056 2.77737 7.99848 2.50423 7.22271 2.50423C6.44694 2.50423 5.78485 2.77737 5.23646 3.32364C4.68807 3.86991 4.41388 4.52944 4.41388 5.30222C4.41388 6.07499 4.68807 6.73452 5.23646 7.28079C5.78485 7.82707 6.44694 8.1002 7.22271 8.1002ZM7.22271 14.6555L8.98826 12.8968H12.8404V11.9774C12.1181 11.2713 11.2789 10.7149 10.3229 10.3082C9.36625 9.90211 8.33286 9.69905 7.22271 9.69905C6.11255 9.69905 5.07944 9.90211 4.12336 10.3082C3.16676 10.7149 2.32732 11.2713 1.60505 11.9774V12.8968H5.45716L7.22271 14.6555Z"
          fill="#08487A" />
      </svg>
      <span class="title ml-2">{{ label }}</span>
    </v-card-title>
    <v-card-text class="pt-5">
      <v-row>
        <v-col class="pt-0 pb-0" cols="12" sm="12" md="12">
          <span class="label">{{ type.ru }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12" sm="12">
          <v-tooltip bottom :text="getEntityName(participantData)">
            <template #activator="{ props }">
              <div v-bind="props">
                <ALink
                  style="
                    width: 100%;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                  "
                  class="d-block mb-2"
                  :text="getEntityName(participantData)"
                  :route="getEntityRoute(participantData.id, typeId)" />
              </div>
            </template>
            <span>{{ getEntityName(participantData) }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <div v-if="details">
        <span class="label">{{ type.contractor ? 'ЄДРПОУ' : 'ІПН' }}</span>
        <span class="d-block mb-2">{{
          participantData.inn || participantData.edrpou
        }}</span>
      </div>
      <div>
        <div v-if="single">
          <SignatoryItem
            v-if="!hideSignatory"
            :signatoriesList="signatoriesList"
            :signatory="localSignatories"
            :v$="v$"
            :readonly="readonly"
            @update="localSignatories = $event" />
        </div>
        <div v-else>
          <div
            v-for="(signatory, idx) in localSignatories"
            :key="signatory.contactId">
            <SignatoryItem
              v-if="!hideSignatory"
              :signatoriesList="
                signatoriesList.filter(s => {
                  return !localSignatories
                    .map(l => l.contactId)
                    .filter(l => l !== signatory.contactId)
                    .includes(s.contact.id)
                })
              "
              :signatory="localSignatories[idx]"
              :showDelete="localSignatories.length > 1"
              :readonly="readonly"
              :loading="loading"
              @update="event => updateSignatory(event, idx)"
              @delete="deleteSignatory" />
          </div>
          <v-btn
            v-if="
              localSignatories?.length < signatoriesList.length &&
              participantData.executiveAuthorityId === 2
            "
            :loading="loading"
            @click="addSignatory">
            Додати підписанта
          </v-btn>
        </div>
      </div>
    </v-card-text>
  </div>
</template>

<script>
import SignatoryItem from './SignatoryItem.vue'
import ALink from '@/components/Link.vue'
import { getAddress, getEntityRoute, getEntityName } from '@/utils/helperFunc'

export default {
  components: {
    SignatoryItem,
    ALink,
  },
  props: {
    label: { type: String },
    typeId: { type: Number, required: true },
    participant: { type: Object, required: true },
    signatories: { type: Array },
    signatoryId: { type: Number },
    basisId: { type: Number },
    v$: { type: Object },
    single: { type: Boolean },
    readonly: { type: Boolean },
    hideSignatory: { type: Boolean },
    details: { type: Boolean },
  },
  data() {
    return {
      loading: false,
      localSignatories: null,
    }
  },
  computed: {
    type() {
      const contractor = {
        contractor: true,
        ru: 'Контрагент',
        en: 'contractor',
      }
      const contact = {
        contact: true,
        ru: 'Контакт',
        en: 'contact',
      }
      return this.typeId === 2 ? contractor : contact
    },
    participantData() {
      return this.participant
        ? this.participant[this.type.en] || this.participant
        : null
    },
    signatoriesList() {
      const list = []

      if (this.type.contact) {
        list.push({
          contact: this.participantData,
          contactCareerSignatureBases:
            this.participantData.contactCareerSignatureBases,
        })
        list.push(
          ...(this.participantData.representatives || []).map(r => {
            return {
              contact: r.representative,
              contactCareerSignatureBases: [r.documentType],
            }
          })
        )
      }
      if (this.type.contractor) {
        list.push(...(this.participantData.employees || []))
      }

      return list
    },
  },
  methods: {
    getAddress,
    getEntityRoute,
    getEntityName,
    deleteSignatory(contactId) {
      this.loading = true
      this.localSignatories = this.localSignatories.filter(
        s => s.contactId !== contactId
      )
      this.loading = false
    },
    addSignatory() {
      this.loading = true
      this.localSignatories = [
        ...this.localSignatories,
        { contactId: null, basisId: null },
      ]
      this.loading = false
    },
    updateSignatory(signatory, index) {
      this.localSignatories = this.localSignatories.map((s, idx) =>
        idx === index ? signatory : s
      )
    },
  },
  created() {
    this.localSignatories = this.single
      ? { contactId: this.signatoryId, basisId: this.basisId }
      : Object.clone(this.signatories) || [{ contactId: null, basisId: null }]
    this.$watch(
      'localSignatories',
      val => {
        if (this.single) {
          this.$emit('update:signatoryId', val.contactId)
          this.$emit('update:basisId', val.basisId)
        } else {
          this.$emit('update:signatories', val)
        }
      },
      { deep: true }
    )
  },
}
</script>

<style lang="scss" scoped>
.label {
  display: block;
  color: #838282;
  font-size: 12px;
}
.title {
  display: block;
  color: #08487a;
  font-weight: 700;
}
</style>
