export const uk = {
  badge: 'Бейдж',
  dataIterator: {
    loadingText: 'Загрузка',
  },
  noDataAvailable: 'Немає даних для відображення',
  dataFooter: {
    itemsPerPageAll: 'Всі',
    itemsPerPageText: 'Рядків на сторінці:',
    pageText: '{0}-{1} із {2}',
    firstPage: 'Перша сторінка',
    lastPage: 'Остання сторінка',
    nextPage: 'Наступна сторінка',
    prevPage: 'Попередня сторінка',
  },
  input: {
    clear: 'Очистити',
    otp: 'Код',
  },
  today: 'Сьогодні',
  open: 'Відкрити',
  close: 'Закрити',
  loading: 'Завантаження',
  noDataText: 'Немає даних для відображення',
  pagination: {
    ariaLabel: {
      page: 'Сторінка',
      currentPage: 'Поточна сторінка',
      first: 'Перша сторінка',
      last: 'Остання сторінка',
      next: 'Наступна сторінка',
      previous: 'Попередня сторінка',
      root: 'Корінь',
    },
  },
}
