<template>
  <div>
    <v-row v-if="agreement.pledgeTypeId === 2" class="px-3 py-5">
      <v-col cols="12" md="12" sm="12" class="pt-0">
        <span class="label">Об'єкт</span>
        <v-text-field
          v-model="agreementData.pledgeObject"
          hide-details
          placeholder="Введіть текст">
        </v-text-field>
      </v-col>
      <v-col cols="12" md="12" sm="12" class="pt-0">
        <span class="label">Серійний номер</span>
        <v-text-field
          v-model="agreementData.pledgeObjectSerialNumber"
          hide-details
          placeholder="Введіть номер">
        </v-text-field>
      </v-col>
      <v-col cols="12" md="12" sm="12" class="pt-0">
        <span class="label">Державний номер</span>
        <v-text-field
          v-model="agreementData.pledgeObjectStateNumber"
          hide-details
          placeholder="Введіть номер">
        </v-text-field>
      </v-col>
      <v-col cols="12" md="12" sm="12" class="pt-0">
        <span class="label">Вартість</span>
        <v-text-field
          v-model="agreementData.pledgeObjectPrice"
          hide-details
          placeholder="Введіть вартість"
          @input="inputNumber($event, { float: 2, minValue: 1 })"
          @blur="inputNumber($event, { float: 2, saveFloat: true })">
        </v-text-field>
      </v-col>
    </v-row>
    <v-row v-if="[1, 3].includes(agreement.pledgeTypeId)" class="px-3 py-5">
      <v-col cols="12" md="12" sm="12">
        <div class="card-label d-flex justify-space-between">
          <span class="d-block">Пiдписанi ДФЛ з боржником:</span>
          <v-btn
            v-if="agreement.pledgeTypeId === 1"
            style="background: #09487a; color: #fff"
            variant="text"
            :loading="loading"
            @click="getDflList">
            Оновити
          </v-btn>
        </div>
      </v-col>

      <v-col v-if="agreement.contracts" cols="12" md="12" sm="12">
        <v-data-table
          :headers="tHead"
          :items="agreement.contracts"
          class="calc-table --cust-cell-pd ml-2 mr-2"
          mobileBreakpoint="750"
          dense
          :hide-default-footer="
            !agreement.contracts || !agreement.contracts.length
          "
          :hide-default-header="
            !agreement.contracts || !agreement.contracts.length
          ">
          <!-- eslint-disable-next-line -->
          <template #item.number="{ item }">
            <ALink
              :text="item.number"
              :route="{ name: 'dfl-contract', params: { id: item.id } }">
            </ALink>
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.isActive="{ item }">
            <span v-if="item.isActive === true">Дiючий</span>
            <span v-if="item.isActive === false">Новий</span>
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.date="{ item }">
            {{ humanDate(formatDate(item.date, true)) }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ALink from '@/components/Link.vue'
import { formatDate, humanDate, inputNumber } from '@/utils/formatFunc'
import { urlGetPledgeContract } from '@/pages/request.js'
export default {
  components: { ALink },
  props: { agreement: { type: Object }, v: { type: Object } },
  data: () => ({
    tHead: [
      { title: '№', key: 'id', align: 'start', sortable: false },
      { title: 'Номер', key: 'number', align: 'center', sortable: false },
      {
        title: "Об'єкт Лізингу",
        key: 'leasingObjectName',
        align: 'center',
        sortable: false,
      },
      { title: 'Дата', key: 'date', align: 'center', sortable: false },
      { title: 'Статус', key: 'isActive', align: 'center', sortable: false },
    ],
    contracts: [],
    loading: false,
  }),
  computed: {
    agreementData() {
      return this.agreement
    },
    $v() {
      return this.v
    },
  },
  methods: {
    formatDate,
    humanDate,
    inputNumber,
    urlGetPledgeContract,
    getDflList() {
      this.loading = true
      return this.$axios
        .get(this.urlGetPledgeContract(this.agreementData.id))
        .then(res => {
          this.contracts = res.data
          this.loading = false
          this.$store.commit('refreshPage')
          this.$setSnackbar({ text: 'Дані оновлено' })
        })
    },
  },
}
</script>
