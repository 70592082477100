<template>
  <div>
    <div v-if="verificationList">
      <v-fade-transition hide-on-leave>
        <div>
          <Filters :filters="filters" class="ml-2" />
          <SectionLoader
            v-if="asyncLoading('getLegalDepartmentAnylizes')"></SectionLoader>
          <div v-show="!asyncLoading('getLegalDepartmentAnylizes')">
            <v-data-table
              :headers="legalDepartListHeaders"
              :items="legalDepartmentAnalyzes"
              class="calc-table application-table --cust-cell-pd"
              mobileBreakpoint="750"
              dense
              :items-per-page="-1"
              hide-default-footer
              :hide-default-header="!legalDepartmentAnalyzes.length"
              @dblclick:row="navigateToAnalysis">
              <!-- eslint-disable-next-line -->
              <!-- eslint-disable-next-line -->
              <template #item.status="{ item }">
                <span v-if="item.status && item.status.id === 1"
                  >Не розглянуто</span
                >
                <span v-if="item.status && item.status.id === 2"
                  >На розгляді</span
                >
                <span v-if="item.status && item.status.id === 3"
                  >Доопрацювання</span
                >
                <span v-if="item.status && item.status.id === 4"
                  >Завершено</span
                >
                <span v-if="!item.status">---</span>
              </template>
              <!-- eslint-disable-next-line -->
              <template #item.lessee="{ item }">
                <div class="d-flex align-center">
                  <Benefit v-if="item.bestBenefit" class="mr-1" />
                  <ALink
                    v-if="item.lesseeName"
                    :text="item.lesseeName"
                    :route="getEntityRoute(item)" />
                </div>
              </template>
              <!-- eslint-disable-next-line -->
              <template #item.created_at="{ item }">
                {{ humanDate(formatDate(item.created_at, true)) }}
              </template>
              <!-- eslint-disable-next-line -->
              <template #item.actions="{ item }">
                <TableCrud
                  style="margin-right: -10px !important"
                  :actionsArray="[
                    {
                      action: () =>
                        $router.push({
                          name: 'ld-project-verification',
                          params: { id: item.id },
                        }),
                      text: 'Вiдкрити',
                      icon: 'mdi-folder-open-outline',
                    },
                  ]">
                </TableCrud>
              </template>
            </v-data-table>
            <Pagination
              :getItems="getLegalDepartmentAnylizes"
              :trigger="paginationTrigger" />
          </div>
        </div>
      </v-fade-transition>
    </div>
    <router-view v-if="!verificationList"> </router-view>
  </div>
</template>
<script>
import SectionLoader from '@/components/section-loader.vue'

import ALink from '@/components/Link.vue'
import Pagination from '@/components/Pagination.vue'
import { urlGetLegalAnalyzes } from '@/pages/request'
import { formatDate, humanDate } from '@/utils/formatFunc'
import Filters from '@/components/Filters.vue'
import { getContactInitials } from '@/utils/helperFunc'
import Benefit from '@/assets/svg/benefit.vue'
import { useSelect } from '@/utils/mixins/useSelect'
import { useFilter } from '@/utils/mixins/useFilter'
import { reactive } from 'vue'
import TableCrud from '@/components/table-crud.vue'
import { legalDepartListHeaders } from '@/utils/tableHeaders'
export default {
  components: {
    TableCrud,
    Benefit,
    Pagination,
    SectionLoader,

    ALink,
    Filters,
  },
  setup() {
    const filters = reactive({
      statusId: {
        value: null,
        component: 'v-autocomplete',
        attrs: { label: 'Статус', items: 'legalStatuses' },
      },
      startDate: {
        value: null,
        component: 'DatePicker',
        attrs: { label: 'Період', range: true },
      },
      frontManagerId: {
        value: null,
        component: 'v-autocomplete',
        attrs: {
          label: 'Відповідальний фронт',
          itemTitle: u => getContactInitials(u, { isResponsible: true }),
          items: 'frontManagers',
        },
      },
      search: { value: null },
    })
    return {
      ...useSelect(),
      ...useFilter(filters),
      filters,
    }
  },
  data() {
    return {
      legalDepartmentAnalyzes: [],
      legalDepartListHeaders,
    }
  },
  computed: {
    verificationList() {
      return this.$route.name === 'security-service-legal-department'
    },
  },
  methods: {
    formatDate,
    humanDate,
    urlGetLegalAnalyzes,
    getEntityRoute(analyse) {
      const typeContractor = analyse.lessee.typeId === 2
      const name = typeContractor ? 'contractors-form' : 'individuals-form'
      const id = analyse.lessee.id
      return { name, params: { id } }
    },
    getStatusName(key) {
      switch (key) {
        case 1:
          return 'Юр висновок Готовий'
        case 0:
          return '---'
      }
    },
    getLegalDepartmentAnylizes(page = 1) {
      return this.asyncAction('getLegalDepartmentAnylizes', null, () => {
        return this.$axios
          .get(this.urlGetLegalAnalyzes(page, this.filtersQueryObj))
          .then(res => {
            this.legalDepartmentAnalyzes.splice(0)
            this.legalDepartmentAnalyzes.push(...res.data.data)
            return res
          })
          .catch(this.$err)
      })
    },
    navigateToAnalysis(e, row) {
      return this.$router.push({
        name: 'ld-project-verification',
        params: { id: row.item.id },
      })
    },
  },
}
</script>
