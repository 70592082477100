<template>
  <div>
    <div class="pr-2">
      <send-offer
        v-model:sendOfferDialog="sendOfferDialog"
        :clientOffer="objectToSend"
        :dialogAction="dialogAction"
        :cb="updateList">
      </send-offer>
      <v-fade-transition hide-on-leave>
        <div v-if="routeUserCalculations">
          <div v-show="showCalculations">
            <v-fade-transition hide-on-leave>
              <div class="align-center">
                <div class="crm-section-title title-absolute">Розрахунки:</div>
                <Filters
                  :filters="filters"
                  class="ml-2"
                  dialog
                  @filter="updateList" />
              </div>
            </v-fade-transition>
            <v-fade-transition hide-on-leave>
              <v-row v-show="!pageLoad" class="pt-3">
                <v-col :cols="12" class="pt-0">
                  <v-data-table
                    :headers="calculationListHeaders"
                    :items="calculations"
                    class="calc-table --cust-cell-pd"
                    mobileBreakpoint="750"
                    hide-default-footer
                    :hide-default-header="!calculations.length"
                    :customSort="tableDateSort()"
                    :items-per-page="-1">
                    <!-- eslint-disable-next-line -->
                    <template #item.applicationName="{ item }">
                      <ALink
                        :text="item.applicationName"
                        :route="{
                          name: 'application-edit-calculation',
                          params: {
                            applicationId: item.applicationId,
                            calculationId: item.id,
                          },
                        }">
                      </ALink>
                    </template>
                    <!-- eslint-disable-next-line -->
                    <template #item.requestId="{ item }">
                      <div class="d-flex align-center">
                        <ALink
                          :text="item.requestId"
                          :route="{
                            name: 'application-edit-calculation',
                            params: {
                              calculationId: item.id,
                              applicationId: item.applicationId,
                            },
                          }">
                        </ALink>
                        <v-tooltip
                          bottom
                          :text="
                            item.isAgreed
                              ? 'Погоджена калькуляцiя'
                              : '' || item.isSendOffer
                              ? 'Вiдправлено КП'
                              : ''
                          ">
                          <template #activator="{ props }">
                            <v-icon
                              class="ml-2"
                              :size="20"
                              :color="
                                item.isAgreed
                                  ? '#3cb371'
                                  : item.isSendOffer
                                  ? 'orange'
                                  : ''
                              "
                              v-bind="props">
                              {{
                                item.isAgreed
                                  ? 'mdi-check-circle'
                                  : item.isSendOffer
                                  ? 'mdi-email-check'
                                  : ''
                              }}
                            </v-icon>
                          </template>
                          <span v-if="item.isAgreed"
                            >Погоджена калькуляцiя</span
                          >
                          <span v-if="item.isSendOffer">Вiдправлено КП</span>
                        </v-tooltip>
                      </div>
                    </template>
                    <!-- eslint-disable-next-line -->
                    <template #item.leasingAmount="{ item }">
                      {{
                        formutSum(item.leasingAmount) +
                        ' ' +
                        getCurrencySign(item.leasingCurrency)
                      }}
                    </template>
                    <!-- eslint-disable-next-line -->
                    <template #item.created_at="{ item }">
                      <span>
                        {{ humanDate(formatDate(item.created_at, true)) }}
                      </span>
                    </template>
                    <!-- eslint-disable-next-line -->
                    <template #item.actions="{ item }">
                      <TableCrud
                        data-readonly="hide"
                        :color="
                          item.isAgreed
                            ? 'green darken-2'
                            : item.isSendOffer
                            ? 'orange darken-1'
                            : ''
                        "
                        :actionsArray="[
                          {
                            action: () => showCalculation(item),
                            text: 'Переглянути',
                            icon: 'mdi-eye',
                          },
                          {
                            action: () => editCalculation(item),
                            text: 'Редагувати',
                            icon: 'mdi-pencil',
                            disabled: !!item.readOnly,
                          },
                          {
                            action: () =>
                              openOfferDialog(item, 'downloadOffer'),
                            text: 'Зберегти',
                            icon: 'mdi-download',
                            disabled: user.roleId !== 1,
                          },
                          {
                            action: () => openOfferDialog(item, 'sendOffer'),
                            text: item.isSendOffer
                              ? 'Вiдправлено'
                              : 'Вiдправити',
                            icon: 'mdi-email-send',
                            color: item.isSendOffer ? 'orange darken-1' : '',
                          },
                          {
                            action: () =>
                              item.isAgreed
                                ? rejectCalculation(item)
                                : approveCalculation(item),
                            text: item.isAgreed
                              ? 'Вiдмiнити погодження'
                              : 'Погодити',
                            icon: item.isAgreed
                              ? 'mdi-close-circle-outline'
                              : 'mdi-checkbox-marked-circle-outline',
                            color: item.isAgreed ? 'red lighten-2' : '',
                            disabled: !!item.readOnly,
                          },
                          {
                            action: () => opendDeleteDialog(item),
                            text: 'Видалити',
                            icon: 'mdi-delete',
                            statement: !item.isAgreed && !item.isSendOffer,
                            disabled: !!item.readOnly,
                          },
                        ]">
                      </TableCrud>
                    </template>
                  </v-data-table>
                  <Pagination
                    :getItems="getUserCalculations"
                    :trigger="paginationTrigger" />
                </v-col>
              </v-row>
            </v-fade-transition>
          </div>
        </div>
      </v-fade-transition>
      <section-loader v-if="pageLoad && routeUserCalculations"></section-loader>
    </div>
    <div class="payment-history-section">
      <router-view :class="{ 'pa-0': routePaymentPlan }"></router-view>
    </div>
  </div>
</template>
<script>
import Filters from '@/components/Filters.vue'
import sendOffer from '@/components/send-offer.vue'
import sectionLoader from '@/components/section-loader.vue'
import TableCrud from '@/components/table-crud.vue'
import ALink from '@/components/Link.vue'
import Pagination from '@/components/Pagination.vue'
import { mapState, mapGetters, useStore } from 'vuex'
import {
  getCurrencySign,
  tableDateSort,
  getContactInitials,
} from '@/utils/helperFunc'
import {
  formatDate,
  humanDate,
  formutSum,
  maxNum,
  inputNumber,
} from '@/utils/formatFunc'
import {
  urlDeleteCalculation,
  calculationGetAll,
  urlCopyCalculation,
} from '@/pages/request'
import { reactive, readonly, ref } from 'vue'
import { useSelect } from '@/utils/mixins/useSelect'
import { useFilter } from '@/utils/mixins/useFilter'
import { calculationListHeaders } from '@/utils/tableHeaders'
export default {
  components: {
    Filters,
    sendOffer,
    sectionLoader,
    TableCrud,
    ALink,
    Pagination,
  },
  setup() {
    const store = useStore()
    const filterDialog = ref(true)

    const addCarMarks = loTypeId => {
      filters.markId.value = null
      store.commit('clearCarMarks')
      filters.modelId.value = null
      store.commit('clearCarModels')
      return store.dispatch('addCarMarks', loTypeId)
    }
    const addCarModels = markId => {
      const leasingObjectTypeId = filters.leasingObjectTypeId.value
      filters.modelId.value = null
      store.commit('clearCarModels')
      return store.dispatch('addCarModels', {
        markId,
        leasingObjectTypeId,
      })
    }
    const filters = reactive({
      leasingObjectTypeId: {
        value: null,
        component: 'v-autocomplete',
        attrs: { items: 'leasingObjectTypes', label: 'Тип ПЛ' },
        change: addCarMarks,
      },
      markId: {
        value: null,
        component: 'v-autocomplete',
        attrs: { items: 'carMarks', label: 'Марка' },
        change: addCarModels,
      },
      modelId: {
        value: null,
        component: 'v-autocomplete',
        attrs: { items: 'carModels', label: 'Модель' },
      },
      advance: {
        value: null,
        component: 'v-text-field',
        attrs: { label: 'Аванс' },
        input: (_, e) => inputNumber(e, { rankSpacing: false }),
      },
      leasingTerm: {
        value: null,
        component: 'v-text-field',
        attrs: { label: 'Термiн лізингу' },
        input: (_, e) => inputNumber(e, { rankSpacing: false, maxValue: 84 }),
      },
      responsibleId: {
        value: null,
        component: 'v-autocomplete',
        attrs: {
          items: 'activeUsers',
          label: 'Відповідальний',
          itemTitle: u => getContactInitials(u, { isResponsible: true }),
        },
        focus: { action: 'addAllUsers' },
      },
      dates: { value: null, component: 'DatePicker' },
      search: { value: null },
    })
    return {
      ...useSelect(),
      ...useFilter(filters, filterDialog),
      filters,
      filterDialog,
    }
  },
  data() {
    return {
      dialogAction: null,
      calculations: [],
      sendOfferDialog: false,
      objectToSend: null,
      eSActionTxt: 'Додати розрахунок',
      eSMessage: 'Калькуляцiй не було створено',
      pageLoad: false,
      calculationListHeaders,
    }
  },
  computed: {
    ...mapState({
      userId: state => state.user?.id,
      user: state => state.user,
      selectItems: state => state.selectItems,
    }),
    ...mapGetters({
      activeUsers: 'activeUsers',
    }),
    showCalculations() {
      return this.routeUserCalculations
    },
    routeUserCalculations() {
      return this.$route.name === 'user-calculations'
    },
    routePaymentPlan() {
      return this.$route.name === 'payment-history'
    },
  },
  methods: {
    readonly,
    formatDate,
    humanDate,
    formutSum,
    tableDateSort,
    calculationGetAll,
    urlDeleteCalculation,
    urlCopyCalculation,
    getCurrencySign,
    maxNum,
    inputNumber,
    createCalculationCopy(item) {
      if (!item.isAgreed) {
        this.$axios.get(urlCopyCalculation(item.id)).then(res => {
          this.updateList()
          this.$setSnackbar({ text: 'Калькуляцiя успiшно скопiйована' })
          return res
        })
      }
    },
    closeFilter() {
      for (const f in this.filters) {
        if (this.filters[f] && f !== 'search') this.filters[f] = null
      }
    },
    opendDeleteDialog(calculation) {
      this.$store.commit('setDialog', {
        title: 'Видалити розрахунок',
        dialogItem: {
          calculation: calculation,
        },
        action: 'deleteCalculation',
        params: {
          cb: () => this.updateList(),
        },
      })
    },
    setSubHeader() {
      setTimeout(() => {
        this.$store.commit('setSubheader', {
          state: true,
          name: 'calculations',
          data: {},
        })
      }, 0)
    },
    modifyCalculations(arr) {
      return arr.map(calculation => {
        calculation.date = humanDate(formatDate(calculation.created_at, true))
        return calculation
      })
    },
    getUserCalculations(page = 1) {
      this.pageLoad = true

      return this.$axios
        .get(this.calculationGetAll(this.userId, page, this.filtersQueryObj))
        .then(res => {
          this.calculations.splice(0)
          const sortedCalculations = (res.data.data || []).sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at)
          )
          this.calculations.push(...this.modifyCalculations(sortedCalculations))

          return res
        })
        .catch(this.$err)
        .finally(() => (this.pageLoad = false))
    },
    showCalculation(item) {
      this.$router.push({
        name: 'application-payment-plan',
        params: { calculationId: item.id, applicationId: item.applicationId },
      })
    },
    editCalculation(item) {
      this.$router.push({
        name: 'application-edit-calculation',
        params: { calculationId: item.id, applicationId: item.applicationId },
      })
    },
    openOfferDialog(item, action) {
      this.dialogAction = action
      this.objectToSend = item
      this.sendOfferDialog = true
    },
    approveCalculation(calculation) {
      this.$store.commit('setDialog', {
        title: 'Погодити розрахунок',
        dialogItem: calculation,
        action: 'approveCalculation',
        params: {
          cb: () => this.updateList(),
          hideActions: true,
        },
      })
    },
    rejectCalculation(calculation) {
      this.$store.commit('setDialog', {
        title: 'Вiдмiнити погодження',
        dialogItem: calculation,
        action: 'rejectCalculation',
        params: {
          cb: () => this.updateList(),
        },
      })
    },
  },
  watch: {
    activeUsers: {
      handler(val) {
        this.filters.responsibleId.items = val
      },
      deep: true,
    },
  },
  mounted() {
    this.setSubHeader()
  },
}
</script>

<style scoped>
.title-absolute {
  position: absolute;
  top: -50px;
  left: 5px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #08487a;
}
.crm-layout .crm .crm-content .crm-section-title {
  padding: 0 4px 0 4px;
}
.container {
  padding: 0;
}
</style>
