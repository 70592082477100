<template>
  <div>
    <span class="label">Назва кампанії</span>
    <v-text-field
      id="new-application-inn"
      v-model="company.campaignName"
      class="label-top"
      :error-messages="campaignNameErr"
      placeholder="Введіть назву"
      dense>
    </v-text-field>
    <v-row>
      <v-col :cols="12" :md="12">
        <v-row class="align-end">
          <v-col :cols="12" :md="6" class="pt-0 pb-0">
            <span class="label">Джерело (utm_source)</span>
            <v-select
              v-model="company.utmSource.typeId"
              :items="compaignType"
              item-title="name"
              item-value="id"
              :error-messages="utmSourceTypeIdErr"
              class="label-top"
              placeholder="Значення"
              dense>
            </v-select>
          </v-col>
          <v-col :cols="12" :md="6" class="pt-0 pb-0">
            <v-text-field
              v-model="company.utmSource.name"
              :error-messages="utmSourceErr"
              class="label-top"
              placeholder="Введіть значення"
              dense>
            </v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="12" :md="12">
        <v-row class="align-end">
          <v-col :cols="12" :md="6" class="pt-0 pb-0">
            <span class="label">Канал (utm_medium)</span>
            <v-select
              v-model="company.utmMedium.typeId"
              :items="compaignType"
              :item-title="item => item.name"
              :item-value="item => item.id"
              :error-messages="utmMediumTypeIdErr"
              class="label-top"
              placeholder="Значення"
              dense>
            </v-select>
          </v-col>
          <v-col :cols="12" :md="6" class="pt-0 pb-0">
            <v-text-field
              v-model="company.utmMedium.name"
              :error-messages="utmMediumErr"
              class="label-top"
              placeholder="Введіть значення"
              dense>
            </v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="12" :md="12">
        <v-row class="align-end">
          <v-col :cols="12" :md="6" class="pt-0 pb-0">
            <span class="label">Кампанія (utm_campaign)</span>
            <v-select
              v-model="company.utmCampaign.typeId"
              :items="compaignType"
              :item-title="item => item.name"
              :item-value="item => item.id"
              :error-messages="utmCampaignTypeIdErr"
              class="label-top"
              placeholder="Значення"
              dense>
            </v-select>
          </v-col>
          <v-col :cols="12" :md="6" class="pt-0 pb-0">
            <v-text-field
              v-model="company.utmCampaign.name"
              :error-messages="utmCampaignErr"
              class="label-top"
              placeholder="Введіть значення"
              dense>
            </v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="12" :md="12">
        <v-row class="align-end">
          <v-col :cols="12" :md="12" class="pt-0 pb-0">
            <span class="label">Продукт (product)</span>
            <v-text-field
              id="new-application-inn"
              v-model="company.product"
              :error-messages="productErr"
              class="label-top"
              placeholder="Введіть значення"
              dense>
            </v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="12" :md="12">
        <v-row class="align-end">
          <v-col :cols="12" :md="12" class="pt-0 pb-0">
            <span class="label">Рекламне повідомлення (slogan)</span>
            <v-text-field
              id="new-application-inn"
              v-model="company.slogan"
              :error-messages="sloganErr"
              class="label-top"
              placeholder="Введіть значення"
              dense>
            </v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-if="showDoc(additionalFields, 'utmContent')"
        :cols="12"
        :md="12"
        class="pt-0 pb-0">
        <v-row class="align-end">
          <v-col :cols="12" :md="6" class="pt-0 pb-0">
            <div class="d-flex align-center">
              <span class="label">Ідент. (utm_content)</span>
              <v-btn
                icon
                size="x-small"
                @click="hideDoc(additionalFields, 'utmContent')">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none">
                  <path
                    d="M12 12L1 1M1 12L12 1"
                    stroke="#FC7247"
                    stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              </v-btn>
            </div>
            <v-select
              v-model="company.utmContent.typeId"
              :items="compaignType"
              :item-title="item => item.name"
              :item-value="item => item.id"
              class="label-top"
              placeholder="Значення"
              dense>
            </v-select>
          </v-col>
          <v-col :cols="12" :md="6" class="pt-0 pb-0">
            <v-text-field
              v-model="company.utmContent.name"
              class="label-top"
              placeholder="Введіть значення"
              dense>
            </v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="showDoc(additionalFields, 'utmTerm')" class="align-end">
      <v-col :cols="12" :md="6" class="pt-0 pb-0">
        <div class="d-flex align-center">
          <span class="label">Ключове слово (utm_term)</span>
          <v-btn
            icon
            size="x-small"
            @click="hideDoc(additionalFields, 'utmTerm')">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none">
              <path
                d="M12 12L1 1M1 12L12 1"
                stroke="#FC7247"
                stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </v-btn>
        </div>
        <v-select
          v-model="company.utmTerm.typeId"
          :items="compaignType"
          :item-title="item => item.name"
          :item-value="item => item.id"
          class="label-top"
          placeholder="Значення"
          dense>
        </v-select>
      </v-col>
      <v-col :cols="12" :md="6" class="pt-0 pb-0">
        <v-text-field
          v-model="company.utmTerm.name"
          class="label-top"
          placeholder="Введіть значення"
          dense>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="showDoc(additionalFields, 'brand')" :cols="12" :md="12">
        <v-row class="align-end">
          <v-col :cols="12" :md="6" class="pt-0 pb-0">
            <span class="label"
              >Бренд (brand)
              <v-btn
                icon
                size="x-small"
                @click="hideDoc(additionalFields, 'brand')">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none">
                  <path
                    d="M12 12L1 1M1 12L12 1"
                    stroke="#FC7247"
                    stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              </v-btn>
            </span>
            <v-text-field
              v-model="company.brand"
              class="label-top"
              placeholder="Введіть значення"
              dense>
            </v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="12" :md="12" class="mt-0 pt-0">
        <AdditionalDocs
          :key="updateKey"
          v-model:documents="additionalFields"
          class="mt-0 pt-0"
          title="Додати поле"
          label="Обрати додаткове поле" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AdditionalDocs from '@/components/AdditionalDocs.vue'
import { mapState } from 'vuex'
import { urlCreateMarketingCompany } from '@/pages/request'
import { minLength, required } from '@vuelidate/validators'
import { setErrHandler } from '@/utils/helperFunc'
import { useVuelidate } from '@vuelidate/core'
import { useAdditionalDocs } from '@/utils/mixins/useAdditionalDocs'
export default {
  components: { AdditionalDocs },
  setup() {
    return { v$: useVuelidate(), ...useAdditionalDocs() }
  },
  validations() {
    return {
      company: {
        campaignName: { required, minLength: minLength(3) },
        utmSource: {
          name: { required, minLength: minLength(3) },
          typeId: { required },
        },
        utmMedium: {
          name: { required, minLength: minLength(3) },
          typeId: { required },
        },
        utmCampaign: {
          name: { required, minLength: minLength(3) },
          typeId: { required },
        },
        product: { required, minLength: minLength(3) },
        slogan: { required, minLength: minLength(3) },
      },
    }
  },
  data: () => ({
    company: {
      campaignName: null,
      utmSource: { name: null, typeId: null },
      utmMedium: { name: null, typeId: null },
      utmCampaign: { name: null, typeId: null },
      utmContent: { name: null, typeId: null },
      utmTerm: { name: null, typeId: null },
      product: null,
      slogan: null,
    },
    generalObject: 'company',
    additionalFields: {
      utmTerm: { label: 'Ключове слово' },
      utmContent: { label: 'Ідентифікатор рекламної кампанії (utm_content)' },
      brand: { label: 'Продукт (product)' },
    },
  }),
  computed: {
    validation() {
      return { v$: this.v$.company, name: 'marketingCompany' }
    },
    ...mapState({
      compaignType: state => state.selectItems.compaignType,
    }),
    campaignNameErr() {
      return setErrHandler(this.v$.company?.campaignName)
    },
    utmSourceErr() {
      return setErrHandler(this.v$.company?.utmSource.name)
    },
    utmSourceTypeIdErr() {
      return setErrHandler(this.v$.company?.utmSource.typeId)
    },
    utmMediumErr() {
      return setErrHandler(this.v$.company?.utmMedium.name)
    },
    utmMediumTypeIdErr() {
      return setErrHandler(this.v$.company?.utmMedium.typeId)
    },
    utmCampaignErr() {
      return setErrHandler(this.v$.company?.utmCampaign)
    },
    productErr() {
      return setErrHandler(this.v$.company?.product)
    },
    sloganErr() {
      return setErrHandler(this.v$.company?.slogan)
    },
    utmCampaignTypeIdErr() {
      return setErrHandler(this.v$.company?.utmCampaign.typeId)
    },
    utmContentErr() {
      return setErrHandler(this.v$.company?.utmContent.name)
    },
    brandErr() {
      return setErrHandler(this.v$.company?.brand)
    },
    utmContentTypeIdErr() {
      return setErrHandler(this.v$.company?.utmContent.typeId)
    },
    utmTermErr() {
      return setErrHandler(this.v$.company?.utmTerm.name)
    },
    utmTermTypeIdErr() {
      return setErrHandler(this.v$.company?.utmTerm.typeId)
    },
  },
  methods: {
    submit() {
      return this.$axios.post(urlCreateMarketingCompany(), this.company)
    },
  },
  async created() {
    this.$store.dispatch('addCompaignType')
    this.company.utmTerm.typeId && (this.additionalFields.utmTerm.show = true)
    this.company.utmContent.typeId &&
      (this.additionalFields.utmContent.show = true)
    this.company.brand && (this.additionalFields.brand.show = true)
  },
}
</script>
