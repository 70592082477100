<template>
  <div>
    <div v-for="q in localQuestionnaires" :key="q.id">
      <div class="d-flex align-center justify-space-between mb-1">
        <span class="label"
          >Рік: {{ new Date(q.created_at).getFullYear() }}</span
        >
        <v-btn icon size="small" @click="questionnaireDialog('update', q)">
          <v-icon size="19">mdi-pencil</v-icon>
        </v-btn>
      </div>
      <FilesUploader
        :documents="q.questionnaire"
        single
        readonly
        label="Опитувальник BEST BENEFIT"
        acceptedExt=".xlsx, .xls" />
      <FilesUploader
        :documents="q.questionnaireSigned"
        single
        readonly
        label="Опитувальник (Підписаний) BEST BENEFIT"
        acceptedExt=".pdf" />
    </div>
    <v-btn
      variant="text"
      color="#FC7247"
      @click="questionnaireDialog('create')">
      Додати опитувальник
      <v-icon right>mdi-plus</v-icon>
    </v-btn>
  </div>
</template>

<script>
import FilesUploader from '@/components/FilesUploader.vue'
import { cloneDeep } from 'lodash'
export default {
  name: 'BenefitsQuestionnaire',
  components: { FilesUploader },
  props: {
    questionnaires: {
      type: Array,
    },
    sliderId: {
      type: Number,
    },
    clientTypeId: {
      type: Number,
    },
  },
  data: () => {
    return {
      localQuestionnaires: [],
    }
  },
  methods: {
    questionnaireDialog(action, questionnaire) {
      this.$store.commit('setDialog', {
        title: 'Опитувальник BEST BENEFITS',
        action: `${action}BenefitsQuestionnaire`,
        dialogItem: {
          sliderId: this.sliderId,
          questionnaire: questionnaire || {
            sliderId: this.sliderId,
            isMain: false,
          },
          clientTypeId: this.clientTypeId,
        },
        params: {
          dialogWidth: 700,
          cb: res => {
            if (action === 'create') {
              this.localQuestionnaires.push(res.data)
            } else {
              const updatedQuestionnaire = this.localQuestionnaires.find(
                q => q.id === questionnaire.id
              )
              Object.assign(updatedQuestionnaire, res.data)
            }
          },
        },
      })
    },
  },
  watch: {
    questionnaires: {
      handler(v) {
        this.localQuestionnaires = cloneDeep(v || [])
      },
      deep: true,
      immediate: true,
    },
  },
}
</script>
