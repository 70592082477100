import { getFilterQueryString, generateRandomNumber } from '@/utils/helperFunc'

const urlRecoveryPassword = () => '/auth/password/reset/sendSms'
const urlResetPassword = () => '/auth/password/reset/resetPassword'
const urlLoginFirst = () => '/auth/firstLogin'
const urlLoginSecond = () => '/auth/secondLogin'
const urlLogout = () => '/auth/logout'
const baseUrl = location.origin
const urlBestApi = (path, query) =>
  `https://api.bestleasing.com.ua${path}?apiKey=${
    import.meta.env.VITE_ERM_API_KEY
  }${query}`
import { mapValues } from 'lodash'

const urlGetNbuDeposit = (query = '') => {
  return urlBestApi('/api/json/nbu/deposit', query)
}

const urlGetCarData = (query = {}) => {
  const queryKeys = {
    category: 'leasingObjectType',
    mark: 'leasedAssertMark',
    model: 'leasedAssertModel',
    year: 'leasingObjectYear',
    generation: 'generation',
    bodyType: 'body',
    modification: 'modification',
  }

  return urlBestApi(
    '/api/getObjectType',
    getFilterQueryString(mapValues(queryKeys, v => query[v]))
  )
}

const urlCreateAveragePrice = () => '/json/averagePrice/create'
const urlUpdateAveragePrice = id => `/json/averagePrice/update/${id}`
const urlGetAverageMarks = categoryId =>
  `/json/averagePrice/list/marks?category=${categoryId}`
const urlGetAverageModels = (categoryId, markId) =>
  `/json/averagePrice/list/models?category=${categoryId}&mark=${markId}`
const urlGetSingleAverage = id => `/json/averagePrice/single/${id}`
const urlGetAverageList = (page, filters = {}) =>
  `/json/averagePrice?page=${page}${getFilterQueryString(filters)}`
const urlGetStates = () => '/json/averagePrice/list/states'
const urlGetFuels = () => '/json/averagePrice/list/fuels'

const urlJiraCreateTask = () =>
  `/api/bugTracker?apiKey=${import.meta.env.VITE_ERM_API_KEY}`

/** Notifications */
const urlGetNotifications = () => '/json/notification'
const urlGetCallNotifications = () => '/json/lead/call/getMessed '
const urlReadNotification = notificationId =>
  `/json/notification/read/${notificationId}`

/** Dashboard */
// Get dashboard graphs data
const urlGetDashboardGraphs = year => `/json/dashboard?year=${year}`

// POST check email existence
const urlCheckEmailExistence = () => '/json/checkExists/email'
// POST check phone existence
const urlCheckPhoneExistence = () => '/json/checkExists/phone'
// GET phone types
const urlGetPhoneTypes = () => '/json/getPhoneTypes'
// GET email types
const urlGetEmailTypes = () => '/json/getEmailTypes'

// GlobalSearch
const urlGlobalSearch = search => `/json/generalSearch?search=${search}`

/** Users */
// GET user roles
const urlUserRoles = () => '/json/user/getRole'
// GET user
const urlGetUser = () => '/json/getUserData'
// GET all users
const urlGetAllUsers = () => '/json/getUsersList'
// GET front-manager bonus
const urlGetFrontManagerBonus = (userId, year) =>
  `/json/frontManager/getBonus/${userId}?year=${year}`
// GET client types
const urlGetClientTypes = () => '/json/getClientTypes'

/* legal case */
const urlGetLegalCaseList = (page, filters = {}) =>
  `/json/legalCase/delayList?page=${page}${getFilterQueryString(filters)}`

const urlGetLegalCaseScheduledList = (page, filters = {}) =>
  `/json/legalCase/scheduledPayments?page=${page}${getFilterQueryString(
    filters
  )}`
/* legal case */

/* registrationApplication */
const urlGetRegistrationApplication = id =>
  `/json/contract/registrationApplication/single/${id}`
const urlRegistrationApplicationUpdate = id =>
  `/json/contract/registrationApplication/update/${id}`
const urlRegistrationApplicationPrintedForm = id =>
  `/json/contract/registrationApplication/printedForm/${id}`
/* registrationApplication */

/* Calculations */
const urlGetInsuranceProgram = () => '/json/getInsuranceProgramTypes'
const urlGetPromotionsTypes = () => '/json/getStockTypes'
const urlGetInsuranceFranchise = () => '/json/getInsuranceFranchiseTypes'
const urlGetPlaceOfRegistration = () => '/json/getPlaceOfRegistrationTypes'
const urlGetGraphTypes = () => '/json/getGraphTypes'
const urlGetGpsTracker = () => '/json/getGpsTrackerTypes'
const urlGetClientTypesFSN = () => '/json/getClientFSNTypes'
const urlGetTruckMass = () => '/json/getTruckMassTypes'
const urlGetLiftingCapacityTypes = () => '/json/getLiftingCapacityTypes'
const urlGetGpsBeaconTypes = () => '/json/getGpsBeaconTypes'
const urlGetCustomGraphicTypes = () => '/json/getCustomGraphicTypes'
/* COPY */
const urlCopyCalculation = calculationId =>
  `/json/calculations/copyCalculation/${calculationId}`
// GET calculation by id
const calculationGet = id => `/json/calculations/single/${id}`
// POST create/edit
const calculationCreate = () => '/json/calculations/create'
// GET all calculation by userId
const urlDuChangeCalculation = calcId =>
  `/json/calculations/updateExtraAgreements/${calcId}`
const calculationGetAll = (userId, page = 1, filters = {}) => {
  return `/json/calculations/getByUser/${userId}?page=${page}${getFilterQueryString(
    filters
  )}`
}
const projectCalculationStatusReject = calcId =>
  `/json/calculations/setRejectStatus/${calcId}`
// GET delete calculation
const urlDeleteCalculation = id => `/json/calculations/delete/${id}`
// POST access calculation
const urlConfirmCalculation = calculationId =>
  `/json/calculations/accept/${calculationId}`
// GET unsign calculation
const urlUnsignCalculation = calculationId =>
  `/json/calculations/reject/${calculationId}`
// GET leasing programs
const urlGetLeasPrograms = () => '/json/getLeasingPrograms'
// GET service packages
const urlGetServicePackages = () => '/json/getServicePackages'
// POST tiresCatalogList
const urlGetTiresCatalogList = () => '/json/tiresCatalog/getTiresByName'
const urlGetFinancingAmount = () => '/json/calculations/getFinancingSum'
// GET save calculation
const urlGetSaveCalculation = calculationId =>
  `/json/calculations/save/${calculationId}`
// GET replacement car classes
const urlGetReplacementCarClasses = () => '/json/getReplacementCarClasses'

/* Planning */
// GET front manager sales plan
const urlGetFrontSalesPlan = () => '/json/planSales'
// GET get front manager plan sales types
const urlGetPlanSalesTypes = () => '/json/getPlanSalesTypes'
// POST create front manager sales plan
const urlPostCreateSalesPlan = () => '/json/planSales/create'
// POST update front manager sale plan
const urlUpdateFrontSalesPlan = id => `/json/planSales/update/${id}`
// DELETE front manager sale plan
const urlDeleteFrontSalePlan = id => `/json/planSales/delete/${id}`
// GET front manager activities plan
const urlGetFrontActivitiesPlan = () => '/json/planActyvity'
// POST create front manager activity plan
const ulrPostCreateActivityPlan = () => '/json/planActyvity/create'
// POST update front manager activity plan
const ulrPostUpdateFrontActivityPlan = id => `/json/planActyvity/update/${id}`
// DELETE front manager activity plan
const urlDeleteFrontActivityPlan = id => `/json/planActyvity/delete/${id}`

/* Applications */
// GET application sources
const urlGetApplicationSourceTypes = ({ sourceId, sourceTypeId }) => {
  const query = { source: sourceTypeId, sourceLink: sourceId }
  return `/json/getSourcesTypes${getFilterQueryString(query, {
    firstSymbol: '?',
  })}`
}
// GET application sources by sourceId
const urlGetApplicationSourcesById = sourceId =>
  `/json/getSourcesTypes?source=${sourceId}`
// GET application sources by sourceId/linkId
const urlApplicationSourcesBySourceLinkId = (sourceTypeId, sourceId) =>
  `/json/getSourcesTypes?source=${sourceTypeId}&sourceLink=${sourceId}`
// GET all calculations by applicationId
const urlCalculationsByApplication = (id, { page = 1 }) => {
  return `/json/calculations/getByApplication/${id}?page=${page}`
}
// POST Applicacation/lead SMS
const urlSendSms = () => '/json/getSend'
// GET client needs
const urlGetClientNeeds = () => '/json/applications/clientNeeds'
// GET comunication methods
const urlGetCommunicationMethods = () =>
  '/json/applications/communicationMethods'
// GET application by id
const urlApplicationById = id => `/json/applications/single/${id}`
// POST update application status id by applicationId
const urlApplicationUpdateStatus = applicationId =>
  ` /json/applications/setStatus/${applicationId}`
// POST create application
const urlCreateApplication = () => '/json/applications/create'
// GET search duplicates by inn/edrpou
const urlSearchDuplicatesByCode = code =>
  `/json/applications/getSearchEdrpou?search=${code}`
// POST update application
const urlUpdateApplication = id => `/json/applications/update/${id}`
// GET providers
const urlGetProviders = () => '/json/contractor/providers'
// GET provider list
const urlGetProviderList = () => '/json/project/getProviderList'
// GET application rejection statuses
const urlGetRejectStatuses = () => '/json/getApplicationRejections'
// POST application add comment
const urlApplicationAddComment = () => '/json/applicationComment/create'
// POST application update comment
const urlApplicationUpdateComment = commentId =>
  `/json/applicationComment/update/${commentId}`
// DELETE application comment
const urlDeleteApplicationComment = commentId =>
  `/json/applicationComment/delete/${commentId}`
// GET comments by application id
const ulrGetCommentsByApplication = applicationId =>
  `/json/applicationComment/getByApplication/${applicationId}`
// GET contacts by application id
const urlGetContactsByApplication = applicationId =>
  `/json/contractor/getEmployees/${applicationId}`
// POST create contact from application
const urlCreateContactFromApplication = applicationId =>
  `/json/applications/createContactPerson/${applicationId}`

// GET project slider
const urlGetProjectPf = guarantorId =>
  `/json/project/getGuarantorFamilyStatusPrintedForm/${guarantorId}`
const urlGetProjectSlider = calculationId =>
  `/json/calculations/getSlider/${calculationId}`
// DELETE delete guarantor from slider
const urlDeleteGuarantor = guarantorId =>
  `/json/slider/guarantor/delete/${guarantorId}`
// POST add guarantor to slider
const urlAddGuarantor = () => '/json/slider/guarantor/create'
// POST update slider guarantor
const urlUpdateGuarantor = guarantorId =>
  `/json/slider/guarantor/update/${guarantorId}`
// DELETE delete subtenant from slider
const urlDeleteSubtenant = guarantorId =>
  `/json/slider/subtenant/delete/${guarantorId}`
// POST add subtenant to slider
const urlAddSubtenant = () => '/json/slider/subtenant/create'
// POST update slider subtenant
const urlUpdateSubtenant = guarantorId =>
  `/json/slider/subtenant/update/${guarantorId}`
// GET employees
const urlGetEmployees = calculationId =>
  `/json/calculations/getProviderEmployees/${calculationId}`
// POST add template
const urlSetCancelContractDkp = id => `/json/dkp/setCancelContract/${id}`
const urlSetCancelContractDfl = id => `/json/contract/setCancelContract/${id}`
const urlSetCancelContractService = id =>
  `/json/serviceContract/setCancelContract/${id}`
const urlSetDocumentServiceContract = id =>
  `/json/serviceContract/setDocument/${id}`
const urlGetServiceContractPdf = id =>
  `/json/serviceContract/getPrintedForm/${id}`
const urlSetCancelContractExtra = id =>
  `/json/extraAgreement/setCancelContract/${id}`
const urlSetCancelExtraAgreementDkp = id =>
  `/json/extraAgreementDkp/setCancelContract/${id}`
const urlSetCancelContractGuarantee = id =>
  `/json/guaranteeContract/setCancelContract/${id}`
const urlSetCancelContractPledge = id =>
  `/json/pledgeContract/setCancelContract/${id}`
const urlAddTemplate = () => '/json/dkp/addFormTemplate'
// GET application statuses
const urlGetApplicationStatuses = () => '/json/getApplicationStatuses'
// GET securityStatus
const urlGetSecurityStatuses = () => '/json/getServiceStatuses'
// GET legal status
const urlGetLegalStatuses = () => '/json/getServiceStatuses'
// GET applications
const urlGetApplicationList = (page = null, filters = {}) => {
  return `/json/applications/get?page=${page}${getFilterQueryString(filters)}`
}
// FinanceMonitoring Confirmation
const urlFinancialMonitoringConfirmationLevel = id =>
  `/json/financialMonitoring/confirmationLevel/${id}`
// GET application delete phone
const urlApplicationDeletePhone = (applicationId, contactId) =>
  `/json/applications/${applicationId}/phone/delete/${contactId}`
// GET application delete email
const urlApplicationDeleteEmail = (applicationId, contactId) =>
  `/json/applications/${applicationId}/email/delete/${contactId}`
// GET delete emails and phones from application
const urlApplicationRemoveApplicationAndPhones = applicationId =>
  `/json/applications/removePhonesAndEmailsByApplicationId/${applicationId}`
// POST check application email exist
const urlApplicationCheckEmail = () =>
  '/json/applications/checkApplicationEmailExists'
// POST check application phone exist
const urlApplicationCheckPhone = () =>
  '/json/applications/checkApplicationPhoneExists'

// Marketing
const urlGetMarketingList = (page = null, filters = {}) =>
  `/json/advertisingCampaign?page=${page}${getFilterQueryString(filters)}`

const urlCreateMarketingCompany = () => '/json/advertisingCampaign/create'
const urlUpdateMarketingCompany = id => `/json/advertisingCampaign/update/${id}`
const urlDeleteMarketingCompany = id => `/json/advertisingCampaign/delete/${id}`
const urlGetSingleMarketingCompany = id =>
  `/json/advertisingCampaign/single/${id}`
const urlGetAdvertisingCampaignTypes = () => '/json/getAdvertisingCampaignTypes'
const urlDeactivateMarketingCompany = id =>
  `/json/advertisingCampaign/activation/${id}`

const urlCreateExpensesMarketingCompany = () =>
  '/json/advertisingCampaign/cost/create'
const urlUpdateExpensesMarketingCompany = id =>
  `/json/advertisingCampaign/cost/update/${id}`
const urlDeleteExpensesMarketingCompany = id =>
  `/json/advertisingCampaign/cost/delete/${id}`

/* Client offers */
// POST client offer through email
const sendClientOffer = () => '/json/getOffer'
const sendOfferToAgent = () => '/json/getOfferAgent'

/* Open data bot */
// GET requests
const apiKey = 'SCfRN47nmECw'
const urlEDRFOPByInn = inn =>
  `https://opendatabot.com/api/v3/fop/${inn}?apiKey=${apiKey}`
const urlEDRCompanyByEdrpou = edrpou =>
  `https://opendatabot.com/api/v3/full-company/${edrpou}?apiKey=${apiKey}`
const urlEDRCompany = contractorId =>
  `/json/openDataBot/getOpenDataByContractor/${contractorId}`

const urlContactFopCreate = () => '/json/contact/fop/create'
const urlContactFopUpdate = fopId => `/json/contact/fop/update/${fopId}`
const urlContactFopDelete = fopId => `/json/contact/fop/delete/${fopId}`

const urlResendEdrCompanyInfo = contractorId =>
  `/json/openDataBot/contractor/${contractorId}`
/* Your score */
const urlGetCompanyInfo = edrpou =>
  `/json/applications/getRegisterCompanyInfo/${edrpou}`

/* Roles/Departments */
// GET roles
const urlRoles = () => '/json/admin/roles/getAll'
// GET all departments
const urlDepartments = () => '/json/department/getAll'
// GET user by role
const urlUserByRole = role => `/json/getUsersByRole?role=${role}`

/* NBU */
// GET NBU currency courses
const urlNbuCurrencyCourses = () => '/json/getRate'
// GET currencies
const urlCurrencies = () => '/json/getCurrencies'
// GET url mfo
const urlGetMFO = mfo => {
  const query = mfo ? `&mfo=${mfo}` : ''
  return urlBestApi('/api/json/nbu/bankInfo', query)
}

/* Slider */
const urlCreateSlider = applicationId => `/json/slider/create/${applicationId}`
const urlGetSliderByApplication = applicationId =>
  `/json/applications/getSlider/${applicationId}`
const urlCreateBenefitsQuestionnaire = () =>
  '/json/benefitsQuestionnaire/create'
const urlUpdateBenefitsQuestionnaire = questionnaireId =>
  `/json/benefitsQuestionnaire/update/${questionnaireId}`
const urlParseBenefitsQuestionnaire = () => '/json/benefitsQuestionnaire/parse'
const urlBenefitsQuestionnaireTemplate = sliderId =>
  `/json/benefitsQuestionnaire/getTemplateBySlider/${sliderId}`

const urlBenefitsEnvironmentalQuestionnaire = () =>
  '/json/slider/getEnvironmentalQuestionnaireTemplate'

// POST prepared object to project
const urlPostObjectToProject = id => `/json/slider/update/${id}`
// POST send slider to middle
const urlSendSliderToMiddle = sliderId =>
  `/json/slider/sendToMiddle/${sliderId}`
// POST update project from slider
const urlUpdateProjectFromSlider = sliderId =>
  `/json/slider/sendToMiddleUpdate/${sliderId}`

// GET REGIONS [slider]
const urlGetBusinessRegions = () => '/json/getBusinessRegions'

// GET get calculation for project
const urlGetProjectCalculation = calcId =>
  `/json/project/getSingleCalculation/${calcId}`
/* Bank */
// GET bank acoount types
const urlGetBankAccountTypes = () => '/json/getCurrentAccountTypes'

// get country
const urlGetCountryOfOrigins = () => '/json/getCountries'

// get targets
const urlGetCalculationTargets = () => '/json/getTargets'

// get workplace
const urlGetCalculationWorkplace = () => '/json/getWorkplaces'
/** Projects */
// GET projects
const urlGetProjects = () => '/json/project'
// GET project by id
const urlGetSingleProject = projectId => `/json/project/single/${projectId}`
// POST update project
const urlUpdateProject = projectId => `/json/project/update/${projectId}`
// GET analysis types
const urlAnalysisTypes = () => '/json/getAnalysisTypes'
// GET dkp contracts
const urlGetDkpContracts = (page, filters) => {
  return `/json/dkp?page=${page}${getFilterQueryString(filters)}`
}
// GET dfl contracts
const urlGetDflContracts = (page, filters, typeId, exportParam) => {
  return `/json/contract?page=${page}&typeId=${typeId}${getFilterQueryString(
    filters
  )}&export=${exportParam}`
}
// GET advance DFL print form
const urlGetAdvancePrintForm = dflId =>
  `/json/contract/advancePrintedForm/${dflId}`
// GET DFL print form
const urlGetDFLPrintForm = dflId => `/json/contract/printedForm/${dflId}`
// GET DKP print data
const urlGetDKPPrintData = dkpId => `/json/dkp/printedForm/${dkpId}`
// GET single dkp contract
const urlGetSingleDkpContract = dkpId => `/json/dkp/single/${dkpId}`
// GET single dfl contract
const urlGetSingleDflContract = dflId => `/json/contract/single/${dflId}`
// GET dkp types
const urlGetDkpTypes = () => '/json/getDkpTypes'
// GET dkp order calculations
const urlGetDkpOrderCalculations = () => '/json/getDkpOrderCalculations'
// POST update dkp contract
const urlUpdateDkpContract = dkpId => `/json/dkp/update/${dkpId}`
// POST update dfl contract
const urlUpdateDflContract = dflId => `/json/contract/update/${dflId}`

const urlUpdateServiceContract = id => `/json/serviceContract/update/${id}`
const urlSetSignatoryServiceContract = id =>
  `/json/serviceContract/setSignatory/${id}`

// POST add dkpClientBasisDocument
const urlAddDkpClientBasisDocument = () => '/json/listAdd/dkpBasisDocument'
// GET extra agreement
const urlGetExtraAgreementDfl = (page, filters) => {
  return `/json/extraAgreement?page=${page}${getFilterQueryString(filters)}`
}
const urlGetExtraAgreementDkp = (page, filters) => {
  return `/json/extraAgreementDkp?page=${page}${getFilterQueryString(filters)}`
}

const urlExtraAgreementPrintedFormAdvance = id =>
  `/json/extraAgreement/printedFormAdvance/${id}`
// GET single extra agreement
const urlGetExtraAgreementDflById = id => `/json/extraAgreement/single/${id}`
// GET single extra agreement
const urlGetExtraAgreementDkpById = id => `/json/extraAgreementDkp/single/${id}`
// POST dfl extra agreement create
const urlCreateExtraAgreementDfl = () => '/json/extraAgreement/create'
// POST dkp extra agreement create
const urlCreateExtraAgreementDkp = () => '/json/extraAgreementDkp/create'

// GET extra agreement types
const urlGetExtraAgreementTypes = () => '/json/extraAgreement/getTypes'
// GET agreed calculations
const urlGetAgreedCalculations = agreementId =>
  `/json/extraAgreement/getAgreedCalculations/${agreementId}`
// POST update extra agreement
const urlUpdateExtraAgreementDfl = agreementId =>
  `/json/extraAgreement/update/${agreementId}`
const urlUpdateExtraAgreementDkp = agreementId =>
  `/json/extraAgreementDkp/update/${agreementId}`
const urlCheckServices = projectId =>
  `/json/project/checkRevisionServices/${projectId}`
// GET security service analyzes
const urlGetSecurityServiceAnalyzes = (page = 1, filters = {}) =>
  `/json/securityServiceAnalyzes?page=${page}${getFilterQueryString(filters)}`
// POST create financial monitoring
const urlCreateFinancial = () => '/json/financialMonitoring/create'
// POST create legal conclusion
const urlCreateLegalConclusion = () => '/json/legalConclusion/create'
// GET  legal conclusion
const urlGetLegalAnalyzes = (page = 1, filters = {}) =>
  `/json/legalConclusion?page=${page}${getFilterQueryString(filters)}`
// POST create security service analyzes
const urlCreateSecurityService = () => '/json/securityServiceAnalyzes/create'
// GET security service analyzes by id
const urlGetSequrityServiceAnalyzesById = analyzeId =>
  `/json/securityServiceAnalyzes/single/${analyzeId}`
// GET security service take to work
const urlSecurityServiceTakeToWork = analyzeId =>
  `/json/securityServiceAnalyzes/takeToWork/${analyzeId}`
// GET sercuriry service printed form
const urlSecurityServicePrintedForm = analyzeId =>
  `/json/securityServiceAnalyzes/getPrintedForm/${analyzeId}`
// GET risk analyze
const urlCreateRiskAnalyze = () => '/json/riskAnalyses/create'
// GET GC single
const urlGetSingleGCContract = guaranteeContactId =>
  `/json/guaranteeContract/single/${guaranteeContactId}`
// GET PledgeContract
const urlGetPledgeContract = id =>
  `/json/pledgeContract/uploadContractsWithOneC/${id}`
const urlGetRiskAnalyzes = params => {
  const { query, page = 1 } = params

  const queryStr = Object.entries(query)
    .filter(q => q[1])
    .map(q => `&${q[0]}=${q[1]}`)
    .join('')

  return `/json/riskAnalyses?page=${page}${queryStr}`
}
// GET single risk analyze
const urlGetRiskAnalyzeById = analyzeId =>
  `/json/riskAnalyses/single/${analyzeId}`
// GET take to work legal conclusion
const urlTakeToWorkLegalConclusion = projectId =>
  `/json/legalConclusion/takeToWork/${projectId}`
// GET take to work risk analyze
const urlTakeToWorkRiskAnalyze = analyzeId =>
  `/json/riskAnalyses/takeToWork/${analyzeId}`
const urlRiskTradeParams = projectId =>
  `/json/riskAnalyses/getPrintParam/${projectId}`
// POST risk analyze set documents
const urlRiskAnalyzeSetDocuments = projectId =>
  `/json/riskAnalyses/setDocuments/${projectId}`
// GET risk analyze statuses
const urlGetRiskAnalyzeStatuses = () => '/json/getServiceStatuses'
// GET leasing committee analyzes
const urlGetLeasingCommitteeAnalyzes = (params = {}) => {
  const { page, filters = {} } = params
  return `/json/klp?page=${page}${getFilterQueryString(filters)}`
}
// GET leasing committee project
const urlGetLeasingCommitteeProject = projectId =>
  `/json/klp/single/${projectId}`
// POST leasing committee decision
const urlPostLeasingCommitteeDecision = projectId =>
  `/json/klp/setDocuments/${projectId}`
// GET klp protocol path for download
const urlGetKlpProtocolPath = calcId => `/json/klp/printProtocol/${calcId}`
const urlKlpReworkCalculation = klpId => `/json/klp/reworkCalculation/${klpId}`
const urlKlpReworkProject = klpId => `/json/klp/setRevision/${klpId}`

const urlKlpInvations = id => `/json/klp/invitation/${id}`
const urlGetKlpSolutions = () => '/json/getKlpSolutions'

const urlSetKlpSolution = id => `/json/klp/solution/${id}`
const urlKlpInvationsDelete = id => `/json/klp/deleteInvitation/${id}`

const urlKlpSetResult = klpId => `/json/klp/setResult/${klpId}`
const urlKlpTakeToWork = klpId => `/json/klp/takeToWork/${klpId}`
// POST set risk analyze responsible
const urlRiskAnalyzeSetResponsible = analyzeId =>
  `/json/riskAnalyses/setResponsible/${analyzeId}`
// GET users by role - "RiskManager"
const urlGetUsersByRoleRiskManager = () =>
  '/json/getUsersByRole?role=RiskManager,RiskHead'
// GET managers by role - 'FrontManager'

const urlGetFrontManagers = () => '/json/getUsersByRole?role=FrontManager'

// get menegers by role = 'legal'
const urlGetLegalUsers = () => '/json/getUsersByRole?role=LegalManager'
// GET managers by role - 'FrontManager'
const urlGetMiddleManagers = () => '/json/getUsersByRole?role=MidlManager'
// GET legal department analyze by id
const urlGetLegalDepartmentAnalyzeById = analyzeId =>
  `/json/legalConclusion/single/${analyzeId}`
// POST legal conclusion
const urlUpdateLegalConclusion = analyzeId =>
  `/json/legalConclusion/setResult/${analyzeId}`
// GET financial monitiring analyzes
// create printed form
const urlCreateLegalDepartmentPrintedForm = analyzeId =>
  `/json/legalConclusion/getPrintedForm/${analyzeId}`
const urlGetFinancialMonitoringAnalyzes = (params = {}) => {
  const { page = 1, filters = {} } = params
  return `/json/financialMonitoring?page=${page}${getFilterQueryString(
    filters
  )}`
}
// GET single financial monitoring
const urlGetSingleFinancialMonitoringAnalyze = analyzeId =>
  `/json/financialMonitoring/single/${analyzeId}`
// GET financial monitoring take to work
const urlTakeToWorkFinancialMonitoring = analyzeId =>
  `/json/financialMonitoring/takeToWork/${analyzeId}`
// GET financing monitoring statuses
const urlGetFinancingMonitoringStatuses = () => '/json/getServiceStatuses'
// client and provider financial documents
const urlUploadFinancingMonitoringSanctionDocument = id =>
  `/json/financialMonitoring/uploadSanctionDocuments/${id}`
// GET financialRiskLevels
const urlGetFinancialRiskLevels = () => '/json/getFinancialRiskLevel'
// GET DKP form variables
const urlGetDKPFormVariables = () => '/json/dkp/getFormVariables'
// GET project statuses
const urlGetProjectStatuses = () => '/json/project/getStatuses'
// GET filtered projects
const urlGetFilteredProjects = (page = 1, filters = {}, exportParam) => {
  return `/json/project?page=${page}${getFilterQueryString(
    filters
  )}&export=${exportParam}`
}

// GET personal data printed form
const urlGetRequirementNoRegistrMvs = calculationId =>
  `/json/project/getRequirementNoRegistrMvs/${calculationId}`
const urlGetSellCarLetter = calculationId =>
  `/json/project/getSellCarLetter/${calculationId}`
const urlGetGuaranteeLetter = calculationId =>
  `/json/project/getGuaranteeLetter/${calculationId}`
const urlGetInfoForDflDoc = calculationId =>
  `/json/project/getInfoForContractDoc/${calculationId}`
const urlGetPersonalDataGuarantor = guarantorId =>
  `/json/project/getPersonalDataGuarantorPrintedForm/${guarantorId}`

const urlGetGuarantorGeneralMeetingsPf = guarantorId =>
  `/json/project/getGuarantorsGeneralMeetingsPrintedForm/${guarantorId}`
const urlGetGuarantorFoundingDocuments = guarantorId =>
  `/json/project/getGuarantorFoundingDocumentsPrintedForm/${guarantorId}`
const urlGetGuarantorDecisionParticipantPf = guarantorId =>
  `/json/project/getGuarantorDecisionParticipantPrintedForm/${guarantorId}`
const urlGetLesseeSpouseDflAgreement = calculationId =>
  `/json/project/getFamilyStatusPrintedForm/${calculationId}`
const urlGetPersonalDataPrintedForm = projectId =>
  `/json/project/getPersonalDataPrintedForm/${projectId}`
// GET decision printed form
const urlGetDecisionPrintedForm = projectId =>
  `/json/project/getDecisionParticipantPrintedForm/${projectId}`
const urlGetSpousePersonalDataPrintedForm = projectId =>
  `/json/project/getSpousePersonalDataPrintedForm/${projectId}`
const urlGetLimitOfFinancingPrintedForm = projectId =>
  `/json/project/getLimitOfFinancingPrintedForm/${projectId}`
// GET meetings printed form
const urlGetMeetingsPrintedForm = projectId =>
  `/json/project/getGeneralMeetingsPrintedForm/${projectId}`
// GET founding documents printed form
const urlGetFoundingDocumentsPrintedForm = projectId =>
  `/json/project/getFoundingDocumentsPrintedForm/${projectId}`
// GET all project documents
const urlAllProjectDocuments = projectId =>
  `/json/project/getArchiveProjectDocumens/${projectId}`
// GET all client contact legal documents
const urlAllClientContactLegalDocuments = projectId =>
  `/json/project/getArchiveContactUrDocumens/${projectId}`
// GET all client contact fin documents
const urlAllClientContactFinDocuments = projectId =>
  `/json/project/getArchiveContactFinDocumens/${projectId}`
// GET all client contractor legal documents
const urlAllClientContractorLegalDocuments = projectId =>
  `/json/project/getArchiveContractorUrDocumens/${projectId}`
// GET all client contractor fin documents
const urlAllClientContractorFinDocuments = projectId =>
  `/json/project/getArchiveContractorFinDocumens/${projectId}`

// E-Docs
const urlGetEdocsList = (page, filters) => {
  return `/json/EDocS?page=${page}${getFilterQueryString(filters)}`
}

const urlEDocs = id => `/json/EDocS/single/${id}`

// get bookKeeping single payment
const urlGetSingleAgentPaymentAct = id => `/json/agents/payment/single/${id}`

// get bookKeeping payments acts
const urlGetAgentPaymentsActs = (page, filters) => {
  return `/json/agents/payment?page=${page}${getFilterQueryString(filters)}`
}

// get printedForm agentPaymentAct
const urlGetPrintedFormAgentPaymentAct = id =>
  `/json/agents/payment/printedFormAct/${id}`

// post agentPaymentAct
const urlSendAgentPaymentAct = id => `/json/agents/payment/setDocumentAct/${id}`

// post agentPaymentStatusAct
const urlSetStatusPaymentAct = id => `/json/agents/payment/setStatus/${id}`
// get entityPrintedForm

const urlGetEntityPrintedForm = id =>
  `/json/guaranteeContract/familyStatusPrintedForm/${id}`

// get dfl docs
const urlGetDFLDocs = dflId => `/json/contract/getContractDocs/${dflId}`

const urlGetOneCDflInfo = () => '/json/contract/getContractDataByOneCId'

const urlGetOneCLeasingObjectByOneCId = () =>
  '/json/contract/getOneCLeasingObjectByOneCId'

// contact dfls
const urlGetContactDfls = id => `/json/contract/getContractListByContact/${id}`

// contractor dfls
const urlGetContractorDfls = id =>
  `/json/contract/getContractListByContractor/${id}`

// download dfl docs
const urlDownLoadDflDocs = (id, doc) =>
  `/json/contract/getContractPrintForms/${id}?key[]=${doc}`
const urlDownloadDraftDfl = id => `/json/calculations/draftPrintedForm/${id}`
// GET DFL statuses
const urlGetDFLStatuses = () => '/json/getContractStatuses'
// GET DKP statuses
const urlGetDKPStatuses = () => '/json/getDkpStatuses'
// GET GuaranteeContractStatuses
const urlGetGuaranteeStatuses = () => '/json/getGuaranteeContractStatuses'
const urlGetGCStatuses = () => '/json/getPledgeContractStatuses'
// GET Terminate DKP
const urlTerminateDkp = dkpId => {
  return `/json/dkp/terminate/${dkpId}`
}
// GET Terminate DFL
const urlTerminateDfl = dflId => {
  return `/json/contract/terminate/${dflId}`
}

// GET DKP templates
const urlGetDKPTemplates = () => '/json/dkp/getLoadedTemplates'
// GET extra agreement printed form
const urlGetExtraAgreementPrintForm = duId =>
  `/json/extraAgreement/printedForm/${duId}`
// GET extra agreement printed form automatic
const urlGetExtraAgreementPrintFromAutomatic = duId =>
  `/json/extraAgreement/printedFormAutomation/${duId}`
// POST project set status
const urlSetProjectStatus = projectId => `/json/project/setStatus/${projectId}`
// GET check projects fields
const urlSetReturnFromRevision = klpId =>
  `/json/klp/setReturnFromRevision/${klpId}`
const checkProjectsFields = projectId =>
  `/json/project/checkCompletedData/${projectId}`
// POST dkp set status
const urlSetDkpStatus = dkpId => `/json/dkp/setStatus/${dkpId}`
// POST dfl set status
const urlSetDflStatus = dflId => `/json/contract/setStatus/${dflId}`
const urlSetServiceStatus = id => `/json/serviceContract/setStatus/${id}`
// POST mortgragor set status
const urlSetMortgragorStatus = id => `/json/pledgeContract/setStatus/${id}`
// POST dfl set status
const urlSetGCStatus = guaranteeContactId =>
  `/json/guaranteeContract/setStatus/${guaranteeContactId}`
// POST add contractor mortgagor
const urlCreateContractorMortgagor = () =>
  '/json/project/addContractorMortgagor'
// POST add contact mortgagor
const urlCreateContactMortgagor = () => '/json/project/addContactMortgagor'
// DELETE contact mortgagor
const urlDeleteContactMortgagor = pledgeId =>
  `/json/project/deleteContactMortgagor/${pledgeId}`
// DELETE contractor mortgagor
const urlDeleteContractorMortgagor = pledgeId =>
  `/json/project/deleteContractorMortgagor/${pledgeId}`
// GET surety agreements
const urlGetSuretyAgreements = (page, filters) => {
  return `/json/guaranteeContract?page=${page}${getFilterQueryString(filters)}`
}
// POST update surety agreement
const urlUpdateSuretyAgreement = agreementId =>
  `/json/guaranteeContract/update/${agreementId}`
// GET mortgagor agreements
const urlGetMortgagorAgreements = (page, filters) => {
  return `/json/pledgeContract?page=${page}${getFilterQueryString(filters)}`
}
// GET mortgagor agreement
const urlGetMortgagorAgreement = agreementId =>
  `/json/pledgeContract/single/${agreementId}`
// POST update mortgagor agreement
const urlUpdateMortgagorAgreement = agreementId =>
  `/json/pledgeContract/update/${agreementId}`
// GET surety agreement
const urlGetSuretyAgreement = agreementId =>
  `/json/guaranteeContract/single/${agreementId}`
// POST guarantee create
const urlGuaranteeCreate = () => '/json/guaranteeContract/create'
// GET surety agreement types
const urlGetSuretyAgreementTypes = () => '/json/guaranteeContract/getTypes'
// POST create pledge contact
const ulrCreatePledgeContract = () => '/json/pledgeContract/create'
// GET pledge types
const urlGetPledgeTypes = () => '/json/pledgeContract/getTypes'
// POST set extra agreement calculation
const urlSetExtraAgreementCalculation = agreementId =>
  `/json/extraAgreement/setCalculation/${agreementId}`
// GET guarantee contract printed form
const urlGetGuaranteeContractPrintForm = guaranteeId =>
  `/json/guaranteeContract/printedForm/${guaranteeId}`
// GET morgagor printed form
const urlGetMortgagorPrintedForm = agreementId =>
  `/json/pledgeContract/printedForm/${agreementId}`
// POST set middle manager
const urlSetMiddleManager = projectId =>
  `/json/project/setMiddleManager/${projectId}`
// GET project guarantors
const urlGetProjectGuarantors = () => '/json/project/getGuarantorsList'
// GET project mortgagor list
const urlGetMortgagorList = () => '/json/project/getMortgagorsList'
// GET send legal conclusion analyze for revision
const urlLegalConclusionRevision = analyzeId =>
  `/json/legalConclusion/revision/${analyzeId}`
// GET send security service analyze for revision
// GET send risk analyze for revision
const urlRiskRevision = analyzeId => `/json/riskAnalyses/revision/${analyzeId}`
// GET send fin monitoring for revision
const urlFinMonitoringRevision = analyzeId =>
  `/json/financialMonitoring/revision/${analyzeId}`
// POST set status for security service
const urlSetSecurityServiceStatus = analizeId =>
  `/json/securityServiceAnalyzes/setRevision/${analizeId}`
// POST risk analyze set status
const urlRiskAnalyzeSetStatus = analyzeId =>
  `/json/riskAnalyses/setResult/${analyzeId}`
const urlRiskAnalyzeSetResult = analyzeId =>
  `/json/riskAnalyses/setResult/${analyzeId}`

const urlRiskAnalyzeSetRevision = analyzeId =>
  `/json/riskAnalyses/setRevision/${analyzeId}`
// POST set status for legal department
const urlSetLegalDepartmentStatus = analizeId =>
  `/json/legalConclusion/setRevision/${analizeId}`
// POST set financing monitoring status
const urlSetFinancingMonitoringStatus = analyzeId =>
  `/json/financialMonitoring/setRevision/${analyzeId}`

const urlSetFinancingMonitoringResult = analyzeId =>
  `/json/financialMonitoring/setResult/${analyzeId}`
// GET KLP statuses
const urlGetKlpStatuses = () => '/json/getServiceStatuses'
// GET KLP types
const urlGetKlpTypes = () => '/json/getKlpTypes'
// GET extra agreement statuses
const urlGetExtraAgreementStatuses = () => '/json/extraAgreement/getStatuses'
// GET set extra agreement status
const urlSetExtraAgreementStatus = agreementId =>
  `/json/extraAgreement/setStatus/${agreementId}`
// GET set extra agreement status
const urlSetExtraAgreementDkpStatus = agreementId =>
  `/json/extraAgreementDkp/setStatus/${agreementId}`
// POST DFL upload document
const urlDFLUploadDocument = DFLID => `/json/contract/uploadDocument/${DFLID}`
// POST add comment to project
const urlProjectAddComment = () => '/json/project/comment/create'
// POST edit project comment
const urlProjectEditComment = id => `/json/project/comment/update/${id}`
// DELETE delete comment from project
const urlProjectDeleteComment = id => `/json/project/comment/delete/${id}`
// GET project refusal statuses
const urlProjectRefusalStatuses = () => '/json/getProjectRejections'

// Service contracts
const urlGetServiceContracts = (page, filters) => {
  return `/json/serviceContract?page=${page}${getFilterQueryString(filters)}`
}
const urlGetServiceContractsSingle = id => `/json/serviceContract/single/${id}`
const urlCreateServiceContract = () => '/json/serviceContract/create'
const urlGetServiceContractStatuses = () => '/json/getServiceContractStatus'

/* Contractors (counterparty) */
// POST contructor create object
const urlCreateContractor = () => '/json/contractor/create'
// GET contractor types
const urlContractorsType = () => '/json/contractor/type'
// GET contractor indastries list
const urlContractorIndastries = () => '/json/contractor/industry'
// GET contractor categories list
const urlContractorCategories = () => '/json/contractor/category'
// GET contractor by id
const urlGetContractor = id => `/json/contractor/single/${id}`
// GET contractor by contact
const urlGetContractorByContact = contactId =>
  `/json/contractor/getContractorByContact/${contactId}`
// POST create contractor address
const urlCreateContractorAddress = () => '/json/contractor/address/create'
// POST update contractor address
const urlUpdateContractorAddress = addressId =>
  `/json/contractor/address/update/${addressId}`
// DELETE contractor address
const urlDeleteContractorAddress = addressId =>
  `/json/contractor/address/delete/${addressId}`
// GET contructor address types
const urlContractorAddressTypes = () =>
  '/json/contractor/getContractorAddressTypes'
// POST create contractor bank account
const urlCreateBankAccount = () => '/json/currentAccount/create'
// DELETE contractor bank account
const urlDeleteBankAccount = accountId =>
  `/json/currentAccount/delete/${accountId}`
// POST update contactor bank acount
const urlUpdateBankAccount = accountId =>
  ` /json/currentAccount/update/${accountId}`
// GET contractor activity types
const urlContractorActivityTypes = () => '/json/queds/getAll'
const urlContractorByEdrpou = edrpou =>
  `/json/slider/getClientByEdrpou/${edrpou}`
// GET all contractors {name, id}
const urlGetAllContractors = (params = {}) => {
  const { byAccess = false, filters = {} } = params || {}
  return `/json/contractor/getList?byAccess=${byAccess}${getFilterQueryString(
    filters
  )}`
}
// GET all contractors detailed
const urlGetContractorsList = () => '/json/getContractorsList'
// POST contractor update
const urlContractorUpdate = contractorId =>
  `/json/contractor/update/${contractorId}`
// GET contractor dealer types
// !Deprecated use "urlGetDealerTypes" instead
const urlGetContractorDealerTypes = () => '/json/dealerPointOfSale/getTypes'
// GET partner programs of other leasing companies
const urlGetLeasingPrograms = () => '/json/getOtherLeasingCompanies'
// GET loyalty programs
const urlGetLoyaltyPrograms = () => '/json/contractor/dealerLoyalties'
// GET getDealerPointOfSaleMainDirections
const urlGetDealerPointOfSaleMainDirections = () =>
  '/json/getDealerPointOfSaleMainDirections'
// POST create contractor with career
const urlCreateContactContractor = contactId =>
  `/json/contact/addContractor/${contactId}`

const urlDealerPointChangeResponsible = dealerPointId =>
  `/json/dealerPointOfSale/changeResponsible/${dealerPointId}`
// GET delete contractor contact
const urlDeleteContractorContact = contractorId =>
  `/json/contractor/deleteContact/${contractorId}`
// POST contractor add/create main contact
const urlContractorAddContact = contractorId =>
  `/json/contractor/addContact/${contractorId}`
// GET founder roles
const urlGetFounderRoles = () => '/json/contractor/founders/getRoles'
// POST founder create
const urlCreateFounder = () => '/json/contractor/founders/create'
// POST update founder
const urlUpdateFounder = founderId =>
  `/json/contractor/founders/update/${founderId}`
// POST delete founder
const urlDeleteFounder = founderId =>
  `/json/contractor/founders/delete/${founderId}`
// GET filtered contractors

const urlGetFilteredContractors = (page = 1, filters = {}) => {
  return `/json/contractor?page=${page}${getFilterQueryString(filters)}`
}
// GET executive authorities
const urlGetExecutiveAuthorities = () => '/json/getExecutiveAuthorities'
// GET contractor director posts
const urlGetContractorDirectorPosts = () => '/json/getContractorDirectorPosts'
// POST create contractor director
const urlCreateContractorDirector = contractorId =>
  `/json/contractor/createDirector/${contractorId}`
// POST create contractor director by contact
const urlCreateContractorDirectorContact = contractorId =>
  `/json/contractor/setDirector/${contractorId}`
// POST create contractor collegium director
const urlCreateContractorCollegiumDirector = contractorId =>
  `/json/contractor/setCollegium/${contractorId}`
// DELETE contractor collegium director
const urlDeleteContractorCollegiumDirector = participantId =>
  `/json/contractor/deleteCollegium/${participantId}`
// DELETE contractor director
const urlDeleteContractorDirector = directorId =>
  `/json/contractor/deleteDirector/${directorId}`
// Contractor company group
const urlCreateCompanyGroup = () => '/json/contractor/companyGroup/create'
const urlUpdateCompanyGroup = companyGroupId =>
  `/json/contractor/companyGroup/update/${companyGroupId}`
const urlDeleteCompanyGroup = companyGroupId =>
  `/json/contractor/companyGroup/delete/${companyGroupId}`
// GET delete contractor phone
const urlDeleteContractorPhone = (contractorId, phoneId) =>
  `/json/contractor/${contractorId}/phone/delete/${phoneId}`
// GET delete contractor email
const urlDeleteContractorEmail = (contractorId, emailId) =>
  `/json/contractor/${contractorId}/email/delete/${emailId}`
// GET ownerships list
const urlGetOwnershipsList = () => '/json/getFormOwnershipCompanies'

/* Archive */
const urlGetArchiveList = (page = null, filters = {}) =>
  `/json/archiveFile?page=${page}${getFilterQueryString(filters)}`
const urlGetArchiveSingle = id => `/json/archiveFile/single/${id}`
const urlUpdateArchive = id => `/json/archiveFile/update/${id}`
const urlLoadArchiveDocuments = id => `/json/archiveFile/updateDocuments/${id}`

/* Administration */
const urlGetMonths = () => '/json/getMonth'
const urlGetPlanList = () => '/json/plan'
const urlGetPlanSingle = id => `/json/plan/single/${id}`
const urlCreatePlan = () => '/json/plan/create'
const urlDeletePlan = id => `/json/plan/delete/${id}`
const urlUpdatePlan = id => `/json/plan/update/${id}`
const urlCreatePlanValue = () => '/json/plan/value/create'
const urlUpdatePlanValue = id => `/json/plan/value/update/${id}`
const urlDeletePlanValue = id => `/json/plan/value/delete/${id}`
const urlCreatePlanManagersValue = () => '/json/plan/managersValue/create'
const urlUpdatePlanManagersValue = id => `/json/plan/managersValue/update/${id}`
const urlDeletePlanManagersValue = id => `/json/plan/managersValue/delete/${id}`
const urlGetPlanTypes = () => '/json/getPlanTypes'
const urlGetPlanObjectTypes = planTypeId =>
  `/json/getPlanObjectTypes?planTypeId=${planTypeId}`
const urlGetPlanCategories = () => '/json/getLeasingObjectCategories'
const urlGetPlanLeasingPrograms = () => '/json/getLeasingPrograms'
const urlGetPlanSourceTypes = () => '/json/getSourcesTypes'
const urlGetPlanActivityTypes = () => '/json/activity/getTypes'
const urlGetPlanValueTypes = () => '/json/getPlanValueTypes'
const urlGetCalls = (page = 1, filters = {}) =>
  `/json/getCalls?page=${page}${getFilterQueryString(filters)}`
const urlGetCallTypes = () => '/json/getTypeCalls'
const urlGetCallStatus = () => '/json/getStatusCalls'
const urlGetCallTelephony = () => '/json/getTelephonies'
const urlGetAdminLeadCalls = (page = 1, filters = {}) =>
  `/json/lead/call?page=${page}${getFilterQueryString(filters)}`
const urlGetAdminLeadCallSingle = id => `/json/lead/call/single/${id}`
const urlCancelAdminLeadCall = id => `/json/lead/call/cancelCreateLead/${id}`
const urlCreateAdminLeadCall = id => `/json/lead/call/createLead/${id}`
// GET LOGS

const urlGetContactHistory = id => `/json/history/contact/${id}`
const urlGetContractorHistory = id => `/json/history/contractor/${id}`
const urlGetCalculationHistory = id => `/json/history/calculation/${id}`
const urlGetSliderHistory = id => `/json/history/slider/${id}`
const urlGetDkpHistory = id => `/json/history/dkp/${id}`
const urlGetContractHistory = id => `/json/history/contract/${id}`
const urlGetExtraAgreementHistory = id => `/json/history/extraAgreement/${id}`
const urlGetExtraAgreementDkpHistory = id =>
  `/json/history/extraAgreement/${id}`
const urlGetGuaranteeContractHistory = id =>
  `/json/history/guaranteeContract/${id}`
const urlGetPledgeContractHistory = id => `/json/history/pledgeContract/${id}`

const urlGetCalculationLogs = id => `/json/logAction/singleCalculation/${id}`
const urlGetContractorLogs = id => `/json/history/contractor/${id}`
const urlGetContactLogs = id => `/json/logAction/singleContact/${id}`
const urlGetAdminLogs = (page = 1, filters = {}) =>
  `/json/logAction/getAll?page=${page}${getFilterQueryString(filters)}`
// GET LOGS TABLE NAME
const urlGetAdminLogsTableName = () => '/json/getTables'
// GET LOGS TYPE
const urlGetAdminLogsType = () => '/json/getLogActionTypes'
const urlAdminUserChangePassword = id => `/json/admin/users/setPassword/${id}`
// GET SINGLE LOGS
const urlGetAdminSingleLogs = logActionId =>
  `/json/logAction/single/${logActionId}`
const urlPostCreateUser = () => '/json/admin/users/create/'
const urlPostUpdateUser = id => `/json/admin/users/update/${id}`
const urlUserDisconnectTelegram = id =>
  `/json/admin/users/disconnectTelegram/${id}`
const urlGetAdminAllUsers = (page = 1, filters = {}) =>
  `/json/admin/users/getAll?page=${page}${getFilterQueryString(filters)}`
const urlGetAdminSingleUser = id => `/json/admin/users/single/${id}`
const urlDeleteAdminUserDelete = id => `/json/admin/users/delete/${id}`
const urlGetAdminDeactivateUser = id => `/json/admin/users/deactivate/${id}`
const urlGetAdminActivateUser = id => `/json/admin/users/activate/${id}`
// POST
const urlCreateSupervisorTeam = () => ' /json/admin/supervisorTeams/create'
// POST
const urlUpdateSupervisorTeam = id => `/json/admin/supervisorTeams/update/${id}`
// Delete
const ulrDeleteSupervisorTeam = id => `/json/admin/supervisorTeams/delete/${id}`
// POST
const urlAddSupervisorTeamFrontManager = supervisorId =>
  `/json/admin/supervisorTeams/addFrontManager/${supervisorId}`
// Delete
const urlDeleteSupervisorTeamFrontManager = (supervisorId, frontManagerId) =>
  ` /json/admin/supervisorTeams/deleteFrontManager/${supervisorId}/${frontManagerId}`
// GET
const urlGetAllSupervisorTeams = () => '/json/admin/supervisorTeams/getAll'
// GET
const urlGetSupervisorTeam = id => `/json/admin/supervisorTeams/single/${id}`
// GET client statuses
const urlGetClientStatuses = () => '/json/getClientStatuses'
// POST upload dkp files
const urlUploadBlDkp = () => '/json/admin/blDkps/upload'
// GET get all best leasing dkps
const urlgetBlDkps = () => '/json/admin/blDkps/'
// DELETE delete dkp file
const urlDeleteBlDkp = id => `/json/admin/blDkps/delete/${id}`
// POST create table for dkp in data base
const urlCreateBlDkpTable = () => '/json/admin/blDkps/add'

/** Admin directories */
// GET
const urlGetAdminDirectories = (page = 1, filters = {}) =>
  `/json/history/directory?page=${page}${getFilterQueryString(filters)}`

// GET tableNames
const urlGetDirectoriesTableNames = () => '/json/history/directory/getTables'

const urlGetDirectoriesField = tableName =>
  `/json/history/directory/getColumns?table=${tableName}`

// create directory
const urlCreateAdminDirectory = () => '/json/history/directory/create'

// update card directory
const urlUpdateAdminSingleDirectory = id =>
  `/json/history/directory/update/${id}`

// delete directory
const urlDeleteAdminDirectory = id => `/json/history/directory/delete/${id}`

// single card directory
const urlGetAdminSingleDirectory = id => `/json/history/directory/single/${id}`

/** Activities */
// GET
const urlGetActivityCategories = () => '/json/activity/getTypes'
// GET activity statuses
const urlGetActivityStatuses = () => '/json/activity/getStatuses'
// POST activity create
const urlActivityCreate = () => '/json/activity/create'
// GET activities
const urlGetActivities = (params = {}) => {
  const { page = 1, filters = {} } = params
  return `/json/activity?page=${page}${getFilterQueryString(filters)}`
}

const urlGetSegments = () => '/json/getSegments'
// DELETE event
const urlDeleteActivity = id => `/json/activity/delete/${id}`
// POST update event
const urlCompleteEvent = id => `/json/activity/complete/${id}`
// POST update event
const urlUpdateEvent = id => `/json/activity/update/${id}`
// GET all user invitations
const urlGetAllUserInvitations = () => '/json/activity/invitations'
// GET reject invitation
const urlRejectInivation = id => `/json/activity/invitations/reject/${id}`
// GET accept invitation
const urlAcceptInvitation = id => `/json/activity/invitations/accept/${id}`
// GET activity statuses
const urlGetFinishActivityStatuses = () => '/json/activity/getResults'
// GET contractor contacts
const urlGetContractorContacts = contractorId =>
  `/json/contractor/getEmployees/${contractorId}`
// GET delete contractorContract
const urlDeleteContactContractor = contactId =>
  `/json/contact/deleteContractor/${contactId}`
// GET filter activities
const urlFilterActivities = (responsibleId, statusId, dateStart, dateEnd) => {
  return `/json/activity?filter${statusId ? '&statusId=' + statusId : ''}${
    dateStart ? '&startDate=' + dateStart : ''
  }${dateEnd ? '&endDate=' + dateEnd : ''}${
    responsibleId ? '&responsibleId=' + responsibleId : ''
  }`
}
// GET single activity
const urlGetContactActivities = contactId =>
  `/json/activity/getActivitiesByContact/${contactId}`

/** Dealers */
// GET dealer list
const urlGetDealerList = (page = 1, filters = {}) => {
  return `/json/dealerPointOfSale?page=${page}${getFilterQueryString(filters)}`
}

const urlGetDealerRegions = () => {
  return '/json/dealerPointOfSale/getRegions'
}
// Activate/deactivate dealer point
const urlDealerPointInactivation = id => {
  return `/json/dealerPointOfSale/deactivation/${id}`
}
// GET dealer point by contact/contractor id
const urlGetDealerPointByContact = entityId =>
  `/json/dealerPointOfSale/getDealerPoints/${entityId}`
// POST create dealer
const urlPostCreateDealer = () => '/json/dealerPointOfSale/create'
// GET get dealer point duplicates
const urlDealerDuplicates = dealerPointName =>
  `/json/dealerPointOfSale/getSearchByName?search=${dealerPointName}`
// GET dealer types
const urlGetDealerTypes = () => '/json/dealerPointOfSale/getTypes'
// GET dealer categories
const urlGetDealerCategories = () => '/json/dealerPointOfSale/getCategories'
// POST update dealer
const urlDealerUpdate = dealerId => `/json/dealerPointOfSale/update/${dealerId}`
// GET dealer
const urlGetDealer = dealerId => `/json/dealerPointOfSale/single/${dealerId}`
// DELETE dealer
const urlDeleteDealer = dealerId => `/json/dealerPointOfSale/delete/${dealerId}`
// POST set dealer agent
const urlSetDealerAgent = dealerId =>
  `/json/dealerPointOfSale/employee/add/${dealerId}`
// DELETE dealer agent
const urlDeleteDealerAgent = dealerId =>
  `/json/dealerPointOfSale/contactPerson/delete/${dealerId}`
// POST update delaer agent
const urlUpdateDealerAgent = dealerPointEmployeesId =>
  `/json/dealerPointOfSale/employee/update/${dealerPointEmployeesId}`
// POST create dealer seller
const urlCreateDealerOutletSeller = () =>
  '/json/dealerPointOfSale/seller/create'
// DELETE dealer outlet seller
const urlDeleteDealerOutletSeller = sellerId =>
  `/json/dealerPointOfSale/seller/delete/${sellerId}`
// GET dealer outlet providers
const urlGetDealerOutletProviders = dealerPointId =>
  `/json/dealerPointOfSale/getProviders/${dealerPointId}`
// GET dealer outlet contacts
const urlGetDealerContacts = dealerId =>
  `/json/dealerPointOfSale/getContactPersons/${dealerId}`
// GET dealer employees
const urlGetDealerEmployees = dealerId =>
  `/json/dealerPointOfSale/getEmployees/${dealerId}`
// GET dealer outlets
const urlGetDealerOutlets = () => '/json/getDealerPointOfSales'
// DELETE dealer outlet phone
const urlDeleteDealerOutletPhone = phoneId =>
  `/json/dealerPointOfSale/deletePhone/${phoneId}`
// DELETE dealer outlet email
const urlDeleteDealerOutletEmail = emailId =>
  `/json/dealerPointOfSale/deleteEmail/${emailId}`

/** Contacts */
// POST create new contact
const urlCreateNewContact = () => '/json/contact/create'
// GET check contacts surname duplicates
const urlGetContactsDuplicatesBySurname = (surname = '') =>
  `/json/contact/getBySearchLastName?search=${surname}`
// GET check exist phone number
const urtCheckExistPhone = phone =>
  `/json/contact/getBySearchPhone?search=${phone}`
// GET check exist email
const urtCheckExistEmail = email =>
  `/json/contact/getBySearchEmail?search=${email}`
// GET get contact address types
const urlContactAddressTypes = () => '/json/contact/getContactAddressTypes'
// GET contact by id
const urlGetContact = id => `/json/contact/single/${id}`
// POST create new address for contact
const urlCreateContactAddress = () => '/json/contact/address/create'
// POST update contact address
const urlUpdateContactAddress = addressId =>
  `/json/contact/address/update/${addressId}`
// DELETE contact bank account
const urlDeleteContactBankAccount = accountId =>
  `/json/contact/currentAccount/delete/${accountId}`
// POST create contact bank account
const urlCreateContactBankAccount = () => '/json/contact/currentAccount/create'
// POST update bank account
const urlContactUpdateBankAccount = accountId =>
  `/json/contact/currentAccount/update/${accountId}`
// GET nationality list
const urlGetNationalList = () => '/json/getNationality'
// GET influence list
const urlGetInfluenceList = () => '/json/getTypeInfluence'
// GET sign kbv list
const urlGetSignKbvList = () => '/json/getSignCBV'
// GET connections list
const urlGetConnectionsList = () => '/json/getConnections'
// GET gender list
const urlGetGenderList = () => '/json/getGender'
// POST contact update
const urlContactUpdate = contactId => `/json/contact/update/${contactId}`
// DELETE contractor address

// GET PASSPORT TYPE
const urlGetPassportTypes = () => '/json/getIdentityDocumentType'
// UPDATE CONTACT PASSPORT
const urlUpdateContactPassport = contactId =>
  `/json/contact/identityDocument/update/${contactId}`
// DELETE CONTACT PASSPORT
const urlDeleteContactPassport = contactId =>
  `/json/contact/identityDocument/delete/${contactId}`
// POST CONTACT PASSPORT
const urlCreateContactPassport = () => '/json/contact/identityDocument/create'
const urlDeleteContactAddress = addressId =>
  `/json/contact/address/delete/${addressId}`
// POST create career
const urlContactCreateCareer = () => '/json/contact/career/create'
const urlCreateContactCareerSignatureBasis = () =>
  '/json/contact/career/signatureBasis/create'
const urlUpdateContactCareerSignatureBasis = id =>
  `/json/contact/career/signatureBasis/update/${id}`

const urlDeleteContactCareerSignatureBasis = id =>
  `/json/contact/career/signatureBasis/delete/${id}`
// POST update contact career
const urlContactUpdateCareer = careerId =>
  `/json/contact/career/update/${careerId}`
// GET career list
const urlGetCarrers = () => '/json/contact/careerPost'
// GET career department list
const urlGetDepartments = () => '/json/contact/careerDepartment'
// GET career role list
const urlGetCareerRoles = () => '/json/contact/careerRole'
// DELETE contact career
const urlDeleteContactCareer = careerId =>
  `/json/contact/career/delete/${careerId}`
// GET all contacts {name, id}
const urlContactsGetAll = (byAccess = false) =>
  `/json/contact/getList?byAccess=${byAccess}`
// GET all contacts detailed
const urlContactsGetAllDetailed = () => '/json/getContactsList'
const urlGetContactTypes = () => '/json/contact/type'
// GET education types
const urlGetEducationTypes = () => '/json/getContactEducationTypes'
// POST create education
const urlCreateEducation = () => '/json/contact/education/create'
// POST education update
/** @param {Number|String} educationId */
const urlUpdateEducation = educationId =>
  `/json/contact/education/update/${educationId}`
// DELETE education
/** @param {Number|String} educationId */
const urlDeleteEducation = educationId =>
  `/json/contact/education/delete/${educationId}`
// GET contact statuses
const urlGetContactStatuses = () => '/json/getClientStatuses'
// GET filtered contacts
const urlGetContacts = (page = 1, filters = {}) => {
  return `/json/contact?page=${page}${getFilterQueryString(filters)}`
}
// POST create agent
const urlDealerOutletCreateAgent = dealerOutletId =>
  `/json/dealerPointOfSale/createAgent/${dealerOutletId}`
// POST add contact person to dealer outlet
const urlDealerOutletAddContactPerson = dealerOutletId =>
  `/json/dealerPointOfSale/employee/create/${dealerOutletId}`
// DELETE dealer outlet employee
const urlDeleteDealerOutletEmployee = employeeId =>
  `/json/dealerPointOfSale/employee/delete/${employeeId}`
// GET convert employee to agent
const urlConvertEmployeeToAgent = employeeId =>
  `/json/dealerPointOfSale/employee/convertToAgent/${employeeId}`
const urlRepresentativeCreate = () => '/json/contact/representative/create'
const urlRepresentativeUpdate = contactId =>
  `/json/contact/representative/update/${contactId}`
const urlRepresentativeDelete = representativeId =>
  `/json/contact/representative/delete/${representativeId}`
// GET delete contact email
const urlDeleteContactEmail = (contactId, emailId) =>
  `/json/contact/${contactId}/email/delete/${emailId}`
// GET delete contact phone
const urlDeleteContactPhone = (contactId, phoneId) =>
  `/json/contact/${contactId}/phone/delete/${phoneId}`
// GET family statuses
const urlFamilyStatuses = () => '/json/getFamilyStatuses'
// GET signatory documents
const urlGetBasisDocuments = () => '/json/getBasisDocuments'

/* SelectPrintedForm DFL/DOL */
const urlBankDetailsPrintedForm = (id, currentAccountId) =>
  `/json/contract/bankDetailsPrintedForm/${id}?currentAccountId=${currentAccountId}`

/* Agents */
// GET agents list
const urlGetAgentList = (params = {}) => {
  const { page = 1, filters = {} } = params
  return `/json/agents?page=${page}${getFilterQueryString(filters)}`
}
const urlGetAllAgents = () => '/json/agents/getList'

// GET agent by id
const urlGetAgent = agentId => ` /json/agents/single/${agentId}`
// POST create agent
const urlCreateAgent = () => '/json/agents/create'
// DELETE agent
const urlDeleteAgent = agentId => `/json/agents/delete/${agentId}`
// POST update agent
const urlUpdateAgent = agentId => `/json/agents/update/${agentId}`
// POST update agency contract
const urlUpdateAgencyContract = agentId => `/json/agents/contract/${agentId}`
/* Comunication history */
// GET contact calling history
const urlGetContactCallingHistory = contactId =>
  `/json/getCalls/contact/${contactId}`
// GET contractor calling history
const urlGetContractorCallingHistory = contractorId =>
  `/json/getCalls/contact/${contractorId}`
// GET application calling
const urlGetApplicationCallingHistory = applicationId =>
  `/json/getCalls/contact/${applicationId}`

const urlGetBookKeepingStatus = () => '/json/getAgentPaymentStatuses'

/** Location items */
// GET countries list
const urlCountries = () => '/json/getCities' // is not mistake (agreed)
// GET regions list
const urlRegions = countryId => `/json/getCities?country=${countryId}`
// GET districts list
const urlDistricts = (countryId, regionId) =>
  `/json/getCities?country=${countryId}&region=${regionId}`
// GET cities list
const urlCities = (countryId, rigionId, districtId) =>
  `/json/getCities?country=${countryId}&region=${rigionId}&district=${districtId}`

/** Verification */
// POST security service reject client
const urlSecurityServiceResult = id =>
  `/json/securityServiceAnalyzes/setResult/${id}`

/** Beneficiars */
// POST beneficiar object
const urlCreateBeneficiar = () => '/json/contractor/beneficiar/create'
// DELETE beneficiar by id
const urlDeleteBeneficiar = id => `/json/contractor/beneficiar/delete/${id}`
// POST updated beneficiar object
const urlUpdateBeneficiar = id => `/json/contractor/beneficiar/update/${id}`
// POST set beneficiar owners
const urlSetBeneficiarOwner = contractorId =>
  `/json/contractor/setBeneficiarOwner/${contractorId}`
// POST create beneficiar owner
const urlCreateBeneficiarOwner = contractorId =>
  `/json/contractor/createBeneficiarOwner/${contractorId}`
// POST update beneficiar owner
const urlUpdateBeneficiarOwner = kbvId =>
  `/json/contractor/updateBeneficiarOwner/${kbvId}`
// DELETE unset beneficiar owner
const urlUnsetBeneficiarOwner = beneficiarId =>
  `/json/contractor/unsetBeneficiarOwner/${beneficiarId}`

/** User profile */
// POST create user "client offer" template
const urlCreateClinetOfferTemplate = userId =>
  `/json/user/${userId}/sign/create`
// POST update user "client offer" template
const urlUpdateClientOfferTemplate = (userId, templateId) =>
  `/json/user/${userId}/sign/update/${templateId}`
// DELETE user "client offer" template
const urlDeleteClientOfferTemplate = (userId, templateId) =>
  `/json/user/${userId}/sign/delete/${templateId}`
// GET user "client offer" template
const urlGetClientOfferTemplate = (userId, templateId) =>
  `/json/user/${userId}/sign/single/${templateId}`
// GET all user "client offer" templates
const urlGetClientOfferTemplates = userId => `/json/user/${userId}/sign/getAll`
// POST update user password
const urlUpdateUserPassword = () => '/json/user/updatePassword'

/* support */
// GET user tickets
const urlGetUserTickets = () => '/json/support/getByUser'
// POST create ticket
const urlPostCreateTicket = () => '/json/support/create'
// GET ticket theemes
const urlGetTicketTheemes = () => '/json/getSupportTheme'
// GET admin tickets
const urlGetAdminTickets = () => '/json/support/getByAdmin'
// GET ticket statuses
const urlGetTicketStatuses = () => '/json/getSupportStatuses'
// POST ticket set status
const urlPostAdminSetTicketStatus = ticketId =>
  `/json/support/setStatus/${ticketId}`
// GET ticket history
const urlGetTicketHistory = ticketId =>
  `/json/support/getCommentsByTicket/${ticketId}`
// POST add user comment
const urlTicketAddUserComment = () => '/json/support/addCommentByUser'
// POST add admin comment
const urlTicketAddAdminComment = () => '/json/support/addCommentByAdmin'

/** Other */
const urlPostUploadImage = () => '/json/image/upload'
const urlPostDeleteImage = () => '/json/image/delete'
const urlUploadFiles = () => '/json/document/uploadExtraFiles'
const urlCommentCreate = () => '/json/comment/create'
const urlCommentUpdate = id => `/json/comment/update/${id}`
const urlCommentDelete = id => `/json/comment/delete/${id}`
const urlCommentGetByApplication = applicationId =>
  `/json/comment/getByApplication/${applicationId}`

/* FAQ */
// POST faq video create
const urlFaqVideoCreate = () => '/json/faq/video/create'
// GET all videos
const urlFaqGetAllVideos = () => '/json/faq/video/getAll'
// GET video by id
const urlFaqGetVideoById = id => `/json/faq/video/getById/${id}`
// DELETE video by id
const urlFaqDeleteVideoById = id => `/json/faq/video/delete/${id}`
// GET all documents
const urlFaqGetAllDocuments = () => '/json/faq/document/getAll'
// DELETE document by id
const urlFaqDeleteDocumentById = id => `/json/faq/document/delete/${id}`
// POST add document
const urlFaqAddDocument = () => '/json/faq/document/create'
// POST update document
const urlFaqUpdateDocument = id => `/json/faq/document/update/${id}`

/** Leads */
// GET leads
const urlGetLeads = (page = 1, filters = {}) => {
  return `/json/lead?page=${page}${getFilterQueryString(filters)}`
}
// GET lead by id
const urlFullPlanTask = (page = 1, filters = {}) =>
  `/json/taskScheduler?page=${page}${getFilterQueryString(filters)}`
// const urlFullPlanTask = () => `/json/taskScheduler`
const urlTaskPlanTask = () => '/json/taskScheduler/create'
const urlTaskPlanTaskDelete = taskSchedulerId =>
  `/json/taskScheduler/delete/${taskSchedulerId}`
const urlTaskPlanTaskUpdate = taskSchedulerId =>
  `/json/taskScheduler/update/${taskSchedulerId}`
const urlGetLeadSendSms = () => '/json/lead/getSend'
const urlSendTelegramMsg = leadId =>
  `/json/lead/sendMessageServiceTelegram/${leadId}`

const urlGetLeadById = id => `/json/lead/getById/${id}`
// GET lead statuses
const urlGetLeadStatuses = () => '/json/getLeadStatuses'
// POST update lead
const urlUpdateLead = id => `/json/lead/update/${id}`
// POST update lead status
const urlUpdateLeadStatus = id => `/json/lead/setStatus/${id}`
// GET create application from lead
const urlCreateApplicationFromLead = id => `/json/lead/createApplication/${id}`
// POST create comment for lead
const urlCreateLeadComment = () => '/json/lead/createComment'
// POST edit lead comment
const urlEditLeadComment = id => `/json/lead/updateComent/${id}`
// DELETE delete lead comment
const urlDeleteLeadComment = id => `/json/lead/deleteComment/${id}`

/** Leasing Objects */
// GET leasing objects list by front_1c_id
const urlGetLeasingObjectsList = (oneCId, filters) =>
  `/json/oneC/objectLeasing/listByFront/${oneCId}${getFilterQueryString(
    filters
  )}`
const urlGetLeasingObjectSingle = oneCId =>
  `/json/oneC/objectLeasing/single/${oneCId}`

// activateThrough
const urlActivateThrough = id => `/json/agents/paymentThrough/active/${id}`
// deactivateThrough
const urlDeactivateThrough = id =>
  `/json/agents/paymentThrough/deactivate/${id}`
// delete Through
const urlDeleteThrough = id => `/json/agents/paymentThrough/delete/${id}`
// create ThroughContact
const urlCreateThrough = () => '/json/agents/paymentThrough/create'

// isAgentContract
const urlSetIsAgentContract = agentId =>
  `/json/agents/isActiveContract/${agentId}`

// isPaymentThrough
const urlSetPaymentThrough = agentId =>
  `/json/agents/isPaymentThrough/${agentId}`
// selectPayment
const urlAddSelectPayment = id => `/json/agents/payment/update/${id}`

//** GetCounty */
const urlGetCountry = () => '/json/getCountries'

/** Scoring */
const urlScoringCreate = () => '/json/scoringService/create'
const urlScoringSenResult = scoringId =>
  `/json/scoringService/setResult/${scoringId}`
const urlScoringSingle = id => `/json/scoringService/single/${id}`
const urlScoringFinList = (page = 1, filters = {}) =>
  `/json/scoringService/getAllFinancing?page=${page}${getFilterQueryString(
    filters
  )}`
const urlScoringSecurityList = (page = 1, filters = {}) =>
  `/json/scoringService/getAllSecurity?page=${page}${getFilterQueryString(
    filters
  )}`
const urlScoringRiskList = (page = 1, filters = {}) =>
  `/json/scoringService/getAllRisk?page=${page}${getFilterQueryString(filters)}`
const urlScoringGuaranteePrintedForm = calcId =>
  `/json/scoringService/getPFLetterOfGuarantee/${calcId}`
const urlScoringAdvancePrintedForm = calcId =>
  `/json/scoringService/getPFAdvance/${calcId}`

/** Service */
const urlGetServiceApplicationList = (page = 1, filters = {}) => {
  return `/json/serviceApplication?page=${page}${getFilterQueryString(filters)}`
}
const urlGetServiceApplicationSingle = id =>
  `/json/serviceApplication/getById/${id}`
const urlGetServiceApplicationOL = id =>
  `/json/serviceApplication/getOneCDataByServiceApplicationId/${id}`
const urlCreateServiceApplication = () => '/json/serviceApplication/create'
const urlUpdateServiceApplication = id =>
  `/json/serviceApplication/update/${id}`
const urlServiceApplicationSetStatus = id =>
  `/json/serviceApplication/setStatus/${id}`
const urlServiceApplicationStatuses = () =>
  '/json/serviceApplication/getListServiceApplicationStatus'
const urlServiceApplicationSourceList = () =>
  '/json/serviceApplication/getServiceApplicationSource'
const urlServiceApplicationTypes = () =>
  '/json/serviceApplication/getServiceApplicationType'

/** All Legal/Fin documents */
// GET contact - add legal documents
const urlGetContactLegalDocuments = contactId =>
  `/json/contact/getArchiveUrDocumens/${contactId}`
// GET contact - add financial documents
const urlGetContactFinDocuments = contactId =>
  `/json/contact/getArchiveFinDocumens/${contactId}`
// GET contractor - add legal documents
const urlGetContractorLegalDocuments = contractorId =>
  `/json/contractor/getArchiveUrDocumens/${contractorId}`
// GET contractor - add financial documents
const urlGetContractorFinDocuments = contractorId =>
  `/json/contractor/getArchiveFinDocumens/${contractorId}`
// POST create phone
const urlPhoneCreate = () => '/json/phone/create'
// POST update phone
const urlPhoneUpdate = id => `/json/phone/update/${id}`
// DELETE delete phone
const urlPhoneDelete = id => `/json/phone/delete/${id}`
// POST create email
const urlEmailCreate = () => '/json/email/create'
// POST update email
const urlEmailUpdate = id => `/json/email/update/${id}`
// DELETE delete email
const urlEmailDelete = id => `/json/email/delete/${id}`
// POST create address
const urlAddressCreate = () => '/json/address/create'
// POST update address
const urlAddressUpdate = id => `/json/address/update/${id}`
// DELETE delete address
const urlAddressDelete = id => `/json/address/delete/${id}`

const urlOpenWithMicrosoft = path => {
  return `https://view.officeapps.live.com/op/view.aspx?src=${baseUrl}${path}?cache=${generateRandomNumber()}`
}
const urlGetAddressApartmentTypes = () => '/json/getApartmentTypes'

// GET LeasingObjects Statuses
const urlGetLeasingObjectStatuses = () => '/json/getLeasingObjectStatuses'

export {
  urlGetNbuDeposit,
  urlGetFuels,
  urlGetStates,
  urlGetAverageList,
  urlCreateAveragePrice,
  urlGetSingleAverage,
  urlUpdateAveragePrice,
  urlGetAverageMarks,
  urlGetAverageModels,
  // Auth (login/recovery password and more)
  urlRecoveryPassword,
  urlResetPassword,
  urlLoginFirst,
  urlLoginSecond,
  urlLogout,
  // Notifications
  urlGetNotifications,
  urlReadNotification,
  urlJiraCreateTask,
  urlGetCarData,
  urlGetSegments,
  urlGetAddressApartmentTypes,
  urlGetGuaranteeStatuses,
  urlGetCallNotifications,
  // agent Through
  urlAddSelectPayment,
  urlSetPaymentThrough,
  urlSetIsAgentContract,
  urlActivateThrough,
  urlDeactivateThrough,
  urlDeleteThrough,
  urlCreateThrough,
  // country
  urlGetCountry,
  // country
  urlGetPrintedFormAgentPaymentAct,
  urlGetSingleAgentPaymentAct,
  urlGetAgentPaymentsActs,
  urlSetStatusPaymentAct,
  urlSendAgentPaymentAct,
  urlGetInsuranceProgram,
  urlGetPromotionsTypes,
  urlGetInsuranceFranchise,
  urlGetPlaceOfRegistration,
  urlGetGraphTypes,
  urlGetGpsTracker,
  urlGetClientTypesFSN,
  urlGetTruckMass,
  urlGetLiftingCapacityTypes,
  urlGetGpsBeaconTypes,
  urlGetCustomGraphicTypes,
  // Service
  urlGetServiceApplicationList,
  urlGetServiceApplicationSingle,
  urlGetServiceApplicationOL,
  urlCreateServiceApplication,
  urlUpdateServiceApplication,
  urlServiceApplicationSetStatus,
  urlServiceApplicationStatuses,
  urlServiceApplicationSourceList,
  urlServiceApplicationTypes,

  // All Legal/Fin documents
  urlGetContactLegalDocuments,
  urlGetContactFinDocuments,
  urlGetContractorLegalDocuments,
  urlGetContractorFinDocuments,
  urlCreateContactPassport,
  urlDeleteContactPassport,
  urlUpdateContactPassport,
  urlGetPassportTypes,

  // Dashboard
  urlGetDashboardGraphs,
  urlCheckEmailExistence,
  urlCheckPhoneExistence,
  urlGetPhoneTypes,
  urlGetEmailTypes,

  // Search
  urlGlobalSearch,

  // User
  urlUserRoles,
  urlGetUser,
  urlGetAllUsers,
  urlUpdateUserPassword,
  urlGetFrontManagerBonus,
  urlGetClientTypes,

  // Support
  urlGetUserTickets,
  urlPostCreateTicket,
  urlGetTicketTheemes,
  urlGetAdminTickets,
  urlGetTicketStatuses,
  urlPostAdminSetTicketStatus,
  urlGetTicketHistory,
  urlTicketAddUserComment,
  urlTicketAddAdminComment,

  // legal case
  urlGetLegalCaseList,
  urlGetLegalCaseScheduledList,
  urlGetLegalUsers,
  urlKlpSetResult,
  urlKlpInvations,
  urlKlpInvationsDelete,
  urlGetKlpSolutions,
  urlSetKlpSolution,

  // Calculations
  calculationGet,
  projectCalculationStatusReject,
  calculationCreate,
  urlDuChangeCalculation,
  calculationGetAll,
  urlDeleteCalculation,
  urlConfirmCalculation,
  urlUnsignCalculation,
  urlGetLeasPrograms,
  urlGetServicePackages,
  urlGetTiresCatalogList,
  urlGetFinancingAmount,
  urlGetSaveCalculation,
  urlGetReplacementCarClasses,
  urlCopyCalculation,
  urlSetMortgragorStatus,
  urlGetCalculationTargets,
  urlGetCalculationWorkplace,

  // Planning
  urlGetFrontSalesPlan,
  urlGetFrontActivitiesPlan,
  urlGetPlanSalesTypes,
  urlPostCreateSalesPlan,
  ulrPostCreateActivityPlan,
  urlUpdateFrontSalesPlan,
  urlDeleteFrontSalePlan,
  ulrPostUpdateFrontActivityPlan,
  urlDeleteFrontActivityPlan,
  urlGetBookKeepingStatus,

  // E-Docs
  urlGetEdocsList,
  urlEDocs,

  // Projects
  urlGetPersonalDataGuarantor,
  urlGetGuarantorGeneralMeetingsPf,
  urlGetGuarantorFoundingDocuments,
  urlGetGuarantorDecisionParticipantPf,
  urlGetLesseeSpouseDflAgreement,
  urlGetInfoForDflDoc,
  urlGetGuaranteeLetter,
  urlGetSellCarLetter,
  urlGetRequirementNoRegistrMvs,
  urlGetProjectPf,
  urlGetProjects,
  urlGetSingleProject,
  urlUpdateProject,
  urlAnalysisTypes,
  urlGetDkpContracts,
  urlGetEntityPrintedForm,
  urlGetDflContracts,
  urlGetSingleDkpContract,
  urlGetAdvancePrintForm,
  urlGetDFLPrintForm,
  urlGetDKPPrintData,
  urlGetSingleDflContract,
  urlGetDkpTypes,
  urlGetDkpOrderCalculations,
  urlUpdateDkpContract,
  urlSetFinancingMonitoringResult,
  urlUpdateDflContract,
  urlAddDkpClientBasisDocument,
  urlGetExtraAgreementDfl,
  urlGetExtraAgreementDkp,
  urlExtraAgreementPrintedFormAdvance,
  urlGetExtraAgreementDflById,
  urlGetExtraAgreementDkpById,
  urlCreateExtraAgreementDfl,
  urlCreateExtraAgreementDkp,
  urlGetExtraAgreementTypes,
  urlGetAgreedCalculations,
  urlUpdateExtraAgreementDfl,
  urlUpdateExtraAgreementDkp,
  urlCheckServices,
  urlGetSecurityServiceAnalyzes,
  urlCreateLegalConclusion,
  urlCreateFinancial,
  urlCreateRiskAnalyze,
  urlGetSingleGCContract,
  urlGetRiskAnalyzes,
  urlGetRiskAnalyzeById,
  urlGetLegalAnalyzes,
  urlCreateSecurityService,
  urlGetSequrityServiceAnalyzesById,
  urlGetLegalDepartmentAnalyzeById,
  urlRiskAnalyzeSetStatus,
  urlRiskAnalyzeSetResult,
  urlRiskAnalyzeSetDocuments,
  urlGetRiskAnalyzeStatuses,
  urlGetLeasingCommitteeAnalyzes,
  urlGetLeasingCommitteeProject,
  urlPostLeasingCommitteeDecision,
  urlGetSpousePersonalDataPrintedForm,
  urlGetLimitOfFinancingPrintedForm,
  urlGetKlpProtocolPath,
  urlKlpReworkCalculation,
  urlKlpReworkProject,
  urlKlpTakeToWork,
  urlRiskAnalyzeSetResponsible,
  urlSecurityServiceTakeToWork,
  urlSecurityServicePrintedForm,
  urlTakeToWorkLegalConclusion,
  urlUpdateLegalConclusion,
  urlCreateLegalDepartmentPrintedForm,
  urlTakeToWorkRiskAnalyze,
  urlRiskTradeParams,
  urlGetUsersByRoleRiskManager,
  urlGetFrontManagers,
  urlGetMiddleManagers,
  urlGetFinancialMonitoringAnalyzes,
  urlGetSingleFinancialMonitoringAnalyze,
  urlTakeToWorkFinancialMonitoring,
  urlGetFinancingMonitoringStatuses,
  urlUploadFinancingMonitoringSanctionDocument,
  urlGetFinancialRiskLevels,
  urlSetFinancingMonitoringStatus,
  urlGetDKPFormVariables,
  urlGetProjectStatuses,
  urlGetFilteredProjects,
  urlGetPersonalDataPrintedForm,
  urlGetDecisionPrintedForm,
  urlGetMeetingsPrintedForm,
  urlGetFoundingDocumentsPrintedForm,
  urlRiskAnalyzeSetRevision,
  urlAllProjectDocuments,
  urlAllClientContactLegalDocuments,
  urlAllClientContactFinDocuments,
  urlAllClientContractorLegalDocuments,
  urlAllClientContractorFinDocuments,
  urlGetDFLStatuses,
  urlGetDKPStatuses,
  urlTerminateDkp,
  urlTerminateDfl,
  urlGetGCStatuses,
  urlGetDKPTemplates,
  urlGetExtraAgreementPrintForm,
  urlGetExtraAgreementPrintFromAutomatic,
  urlSetProjectStatus,
  checkProjectsFields,
  urlSetDkpStatus,
  urlSetDflStatus,
  urlSetServiceStatus,
  urlSetGCStatus,
  urlCreateContractorMortgagor,
  urlCreateContactMortgagor,
  urlDeleteContactMortgagor,
  urlDeleteContractorMortgagor,
  urlGetSuretyAgreements,
  urlUpdateSuretyAgreement,
  urlGetSuretyAgreement,
  urlGetMortgagorAgreements,
  urlGetMortgagorAgreement,
  urlUpdateMortgagorAgreement,
  urlGuaranteeCreate,
  urlGetSuretyAgreementTypes,
  ulrCreatePledgeContract,
  urlGetPledgeTypes,
  urlSetExtraAgreementCalculation,
  urlGetGuaranteeContractPrintForm,
  urlGetMortgagorPrintedForm,
  urlSetMiddleManager,
  urlGetProjectGuarantors,
  urlGetMortgagorList,
  urlLegalConclusionRevision,
  urlRiskRevision,
  urlFinMonitoringRevision,
  urlSetSecurityServiceStatus,
  urlSetLegalDepartmentStatus,
  urlGetKlpStatuses,
  urlGetKlpTypes,
  urlGetExtraAgreementStatuses,
  urlSetExtraAgreementStatus,
  urlSetExtraAgreementDkpStatus,
  urlDFLUploadDocument,
  urlProjectAddComment,
  urlProjectEditComment,
  urlProjectDeleteComment,
  urlProjectRefusalStatuses,
  urlGetPledgeContract,
  urlGetDFLDocs,
  urlDownLoadDflDocs,
  urlDownloadDraftDfl,
  urlSetReturnFromRevision,
  urlFinancialMonitoringConfirmationLevel,
  // logs
  urlGetContactHistory,
  urlGetContractorHistory,
  urlGetCalculationHistory,
  urlGetSliderHistory,
  urlGetDkpHistory,
  urlGetContractHistory,
  urlGetExtraAgreementHistory,
  urlGetExtraAgreementDkpHistory,
  urlGetGuaranteeContractHistory,
  urlGetPledgeContractHistory,

  // Service Contracts
  urlGetServiceContracts,
  urlGetServiceContractsSingle,
  urlCreateServiceContract,
  urlGetServiceContractStatuses,
  urlUpdateServiceContract,
  urlSetDocumentServiceContract,
  urlSetSignatoryServiceContract,
  urlGetServiceContractPdf,

  // Verification
  urlSecurityServiceResult,

  // Applications
  urlCalculationsByApplication,
  urlApplicationById,
  urlApplicationUpdateStatus,
  urlSendSms,
  urlGetClientNeeds,
  urlGetCommunicationMethods,
  urlCreateApplication,
  urlSearchDuplicatesByCode,
  urlUpdateApplication,
  urlGetApplicationSourceTypes,
  urlGetApplicationSourcesById,
  urlApplicationSourcesBySourceLinkId,
  urlGetProviders,
  urlPostObjectToProject,
  urlGetBusinessRegions,
  urlSendSliderToMiddle,
  urlUpdateProjectFromSlider,
  urlGetProjectCalculation,
  urlGetProviderList,
  urlGetRejectStatuses,
  urlApplicationAddComment,
  urlApplicationUpdateComment,
  urlDeleteApplicationComment,
  ulrGetCommentsByApplication,
  urlGetProjectSlider,
  urlDeleteGuarantor,
  urlAddGuarantor,
  urlUpdateGuarantor,
  urlDeleteSubtenant,
  urlAddSubtenant,
  urlUpdateSubtenant,
  urlGetEmployees,
  urlSetCancelContractDkp,
  urlSetCancelContractDfl,
  urlSetCancelContractService,
  urlSetCancelContractGuarantee,
  urlSetCancelContractPledge,
  urlSetCancelContractExtra,
  urlSetCancelExtraAgreementDkp,
  urlAddTemplate,
  urlGetApplicationStatuses,
  urlGetApplicationList,
  urlApplicationDeletePhone,
  urlApplicationDeleteEmail,
  urlApplicationRemoveApplicationAndPhones,
  urlApplicationCheckEmail,
  urlApplicationCheckPhone,
  urlGetContactsByApplication,
  urlCreateContactFromApplication,
  urlGetSecurityStatuses,
  urlGetLegalStatuses,

  // Your score
  urlGetCompanyInfo,

  // Archive
  urlGetArchiveList,
  urlGetArchiveSingle,
  urlUpdateArchive,
  urlLoadArchiveDocuments,

  // Administration
  urlGetMonths,
  urlGetPlanList,
  urlGetPlanSingle,
  urlCreatePlan,
  urlDeletePlan,
  urlUpdatePlan,
  urlCreatePlanValue,
  urlUpdatePlanValue,
  urlDeletePlanValue,
  urlCreatePlanManagersValue,
  urlUpdatePlanManagersValue,
  urlDeletePlanManagersValue,
  //
  urlGetPlanTypes,
  urlGetPlanObjectTypes,
  urlGetPlanCategories,
  urlGetPlanLeasingPrograms,
  urlGetPlanSourceTypes,
  urlGetPlanActivityTypes,
  urlGetPlanValueTypes,
  //
  urlGetAdminDirectories,
  urlGetDirectoriesTableNames,
  urlGetDirectoriesField,
  urlCreateAdminDirectory,
  urlGetAdminSingleDirectory,
  urlUpdateAdminSingleDirectory,
  urlDeleteAdminDirectory,
  urlGetAdminSingleLogs,
  urlGetAdminLogsType,
  urlGetAdminLogsTableName,
  urlGetAdminLogs,
  urlAdminUserChangePassword,
  urlGetCalls,
  urlPostCreateUser,
  urlPostUpdateUser,
  urlUserDisconnectTelegram,
  urlGetAdminAllUsers,
  urlGetAdminSingleUser,
  urlDeleteAdminUserDelete,
  urlGetAdminDeactivateUser,
  urlGetAdminActivateUser,
  urlGetClientStatuses,
  urlCreateSupervisorTeam,
  urlUpdateSupervisorTeam,
  ulrDeleteSupervisorTeam,
  urlAddSupervisorTeamFrontManager,
  urlDeleteSupervisorTeamFrontManager,
  urlGetAllSupervisorTeams,
  urlGetSupervisorTeam,
  urlUploadBlDkp,
  urlgetBlDkps,
  urlDeleteBlDkp,
  urlCreateBlDkpTable,
  urlGetCallTypes,
  urlGetCallStatus,
  urlGetCallTelephony,
  urlGetMarketingList,
  urlCreateMarketingCompany,
  urlUpdateMarketingCompany,
  urlDeleteMarketingCompany,
  urlGetSingleMarketingCompany,
  urlGetAdvertisingCampaignTypes,
  urlDeactivateMarketingCompany,
  urlCreateExpensesMarketingCompany,
  urlUpdateExpensesMarketingCompany,
  urlDeleteExpensesMarketingCompany,
  urlGetAdminLeadCalls,
  urlGetAdminLeadCallSingle,
  urlCancelAdminLeadCall,
  urlCreateAdminLeadCall,

  // Sliders
  urlCreateSlider,
  urlGetSliderByApplication,
  urlCreateBenefitsQuestionnaire,
  urlUpdateBenefitsQuestionnaire,
  urlParseBenefitsQuestionnaire,
  urlBenefitsQuestionnaireTemplate,
  urlBenefitsEnvironmentalQuestionnaire,

  // registrationApplication
  urlGetRegistrationApplication,
  urlRegistrationApplicationUpdate,
  urlRegistrationApplicationPrintedForm,

  // Client offers
  sendClientOffer,
  sendOfferToAgent,

  // Open data bot
  urlEDRFOPByInn,
  urlEDRCompanyByEdrpou,
  urlResendEdrCompanyInfo,

  // Roles
  urlRoles,
  urlUserByRole,

  // Departments
  urlDepartments,

  // NBU
  urlCurrencies,
  urlGetMFO,
  urlNbuCurrencyCourses,

  // DFL/DOL PrintedForms
  urlBankDetailsPrintedForm,

  // contact
  urlContactFopCreate,
  urlContactFopUpdate,
  urlContactFopDelete,

  // Contractor (counterparty)
  urlEDRCompany,
  urlGetContactDfls,
  urlGetOneCDflInfo,
  urlGetOneCLeasingObjectByOneCId,
  urlGetContractorDfls,
  urlCreateContractor,
  urlContractorIndastries,
  urlContractorsType,
  urlContractorCategories,
  urlGetContractor,
  urlGetContractorByContact,
  urlCreateContractorAddress,
  urlUpdateContractorAddress,
  urlContractorAddressTypes,
  urlCreateBankAccount,
  urlDeleteBankAccount,
  urlUpdateBankAccount,
  urlContractorActivityTypes,
  urlDeleteContractorAddress,
  urlContractorByEdrpou,
  urlGetAllContractors,
  urlGetContractorsList,
  urlContractorUpdate,
  urlGetLeasingPrograms,
  urlGetContractorDealerTypes,
  urlGetLoyaltyPrograms,
  urlGetDealerPointOfSaleMainDirections,
  urlCreateContactContractor,
  urlDeleteContractorContact,
  urlContractorAddContact,
  urlGetFounderRoles,
  urlCreateFounder,
  urlUpdateFounder,
  urlDeleteFounder,
  urlGetFilteredContractors,
  urlGetExecutiveAuthorities,
  urlGetContractorDirectorPosts,
  urlCreateContractorDirector,
  urlCreateContractorDirectorContact,
  urlCreateContractorCollegiumDirector,
  urlDeleteContractorCollegiumDirector,
  urlDeleteContractorDirector,
  urlCreateCompanyGroup,
  urlUpdateCompanyGroup,
  urlDeleteCompanyGroup,
  urlDeleteContractorPhone,
  urlDeleteContractorEmail,
  urlGetOwnershipsList,
  urlGetContractorLogs,
  urlGetContactLogs,
  urlGetCalculationLogs,

  // Activities
  urlGetActivityCategories,
  urlGetActivityStatuses,
  urlActivityCreate,
  urlGetActivities,
  urlDeleteActivity,
  urlCompleteEvent,
  urlUpdateEvent,
  urlGetAllUserInvitations,
  urlRejectInivation,
  urlAcceptInvitation,
  urlGetFinishActivityStatuses,
  urlGetContractorContacts,

  // Dealers
  urlGetDealerList,
  urlGetDealerRegions,
  urlDealerPointInactivation,
  urlGetDealerPointByContact,
  urlPostCreateDealer,
  urlDealerDuplicates,
  urlGetDealerTypes,
  urlGetDealerCategories,
  urlDealerUpdate,
  urlGetDealer,
  urlDealerPointChangeResponsible,
  urlDeleteDealer,
  urlSetDealerAgent,
  urlDeleteDealerAgent,
  urlUpdateDealerAgent,
  urlCreateDealerOutletSeller,
  urlDeleteDealerOutletSeller,
  urlGetDealerOutletProviders,
  urlGetDealerContacts,
  urlGetDealerEmployees,
  urlGetDealerOutlets,
  urlDeleteDealerOutletPhone,
  urlDeleteDealerOutletEmail,

  // Contacts
  urlDeleteContactCareerSignatureBasis,
  urlUpdateContactCareerSignatureBasis,
  urlCreateContactCareerSignatureBasis,
  urlCreateNewContact,
  urlGetContactsDuplicatesBySurname,
  urtCheckExistPhone,
  urtCheckExistEmail,
  urlContactAddressTypes,
  urlGetContact,
  urlCreateContactAddress,
  urlUpdateContactAddress,
  urlDeleteContactBankAccount,
  urlCreateContactBankAccount,
  urlGetNationalList,
  urlGetInfluenceList,
  urlGetSignKbvList,
  urlGetConnectionsList,
  urlGetGenderList,
  urlContactUpdate,
  urlContactUpdateBankAccount,
  urlDeleteContactAddress,
  urlContactCreateCareer,
  urlContactUpdateCareer,
  urlGetCarrers,
  urlGetDepartments,
  urlGetCareerRoles,
  urlDeleteContactCareer,
  urlContactsGetAll,
  urlContactsGetAllDetailed,
  urlGetContactTypes,
  urlGetEducationTypes,
  urlCreateEducation,
  urlUpdateEducation,
  urlDeleteEducation,
  urlGetContactStatuses,
  urlDeleteContactContractor,
  urlFilterActivities,
  urlGetContacts,
  urlGetContactActivities,
  urlDealerOutletCreateAgent,
  urlDealerOutletAddContactPerson,
  urlDeleteDealerOutletEmployee,
  urlConvertEmployeeToAgent,
  urlRepresentativeCreate,
  urlRepresentativeUpdate,
  urlRepresentativeDelete,
  urlDeleteContactEmail,
  urlDeleteContactPhone,
  urlFamilyStatuses,
  urlGetBasisDocuments,

  // agents
  urlGetAgentList,
  urlGetAllAgents,
  urlGetAgent,
  urlCreateAgent,
  urlDeleteAgent,
  urlUpdateAgent,
  urlUpdateAgencyContract,

  // Comunication history
  urlGetContactCallingHistory,
  urlGetContractorCallingHistory,
  urlGetApplicationCallingHistory,

  // Beneficiar
  urlCreateBeneficiar,
  urlDeleteBeneficiar,
  urlUpdateBeneficiar,
  urlSetBeneficiarOwner,
  urlCreateBeneficiarOwner,
  urlUpdateBeneficiarOwner,
  urlUnsetBeneficiarOwner,

  // Location
  urlCountries,
  urlRegions,
  urlDistricts,
  urlCities,

  // Bank
  urlGetBankAccountTypes,

  // country of origins
  urlGetCountryOfOrigins,

  // profile
  urlCreateClinetOfferTemplate,
  urlUpdateClientOfferTemplate,
  urlDeleteClientOfferTemplate,
  urlGetClientOfferTemplate,
  urlGetClientOfferTemplates,

  // Other
  urlPostUploadImage,
  urlPostDeleteImage,
  urlUploadFiles,
  urlCommentCreate,
  urlCommentUpdate,
  urlCommentDelete,
  urlCommentGetByApplication,

  // FAQ
  urlFaqVideoCreate,
  urlFaqGetAllVideos,
  urlFaqGetVideoById,
  urlFaqDeleteVideoById,
  urlFaqGetAllDocuments,
  urlFaqDeleteDocumentById,
  urlFaqAddDocument,
  urlFaqUpdateDocument,

  // PlanTask
  urlTaskPlanTask,
  urlTaskPlanTaskDelete,
  urlTaskPlanTaskUpdate,
  urlFullPlanTask,

  // Leads
  urlGetLeads,
  urlGetLeadById,
  urlGetLeadStatuses,
  urlUpdateLead,
  urlUpdateLeadStatus,
  urlCreateApplicationFromLead,
  urlCreateLeadComment,
  urlEditLeadComment,
  urlDeleteLeadComment,
  urlGetLeadSendSms,
  urlSendTelegramMsg,

  // LeasingObjects
  urlGetLeasingObjectStatuses,
  urlGetLeasingObjectsList,
  urlGetLeasingObjectSingle,
  urlPhoneCreate,
  urlPhoneUpdate,
  urlPhoneDelete,
  urlEmailCreate,
  urlEmailUpdate,
  urlEmailDelete,
  urlAddressCreate,
  urlAddressUpdate,
  urlAddressDelete,
  urlOpenWithMicrosoft,

  // Scoring
  urlScoringCreate,
  urlScoringSenResult,
  urlScoringSingle,
  urlScoringFinList,
  urlScoringSecurityList,
  urlScoringRiskList,
  urlScoringGuaranteePrintedForm,
  urlScoringAdvancePrintedForm,
}
