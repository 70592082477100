<template>
  <div>
    <SectionLoader v-if="loading"></SectionLoader>
    <div v-show="!loading">
      <div class="d-flex justify-end subtitle-1 pb-1">
        <span v-if="!filter">
          <v-tooltip bottom text="Фільтр">
            <template #activator="{ props }">
              <v-btn icon size="small" v-bind="props" @click="filter = !filter">
                <v-icon>{{ 'mdi-filter-variant' }}</v-icon>
              </v-btn>
            </template>
            <span>Фільтр</span>
          </v-tooltip>
        </span>
      </div>
      <v-fade-transition hide-on-leave>
        <div v-if="filter" class="pt-0 pb-0" style="position: relative">
          <v-btn
            v-if="filter"
            style="position: absolute; top: 5px; right: 5px"
            icon
            size="small"
            @click="closeFilter">
            <v-icon size="small">{{ 'mdi-close' }}</v-icon>
          </v-btn>
          <v-row class="mt-3">
            <v-col :cols="12" :md="4" class="pb-0">
              <v-select
                v-model="statusId"
                label="Статус"
                class="custom-select-icon"
                :items="directoryItems('activityStatuses')"
                item-title="name"
                item-value="id"
                :hide-details="true"
                dense
                @focus="asyncAction('addActivityStatuses')">
                <template v-if="asyncLoading('addActivityStatuses')" #no-data>
                  <Loader />
                </template>
                <template #prepend-inner>
                  <v-btn
                    v-show="statusId"
                    icon
                    size="small"
                    @click="statusId = null">
                    <v-icon class="custom-select-icon" small>
                      {{ 'mdi-close' }}
                    </v-icon>
                  </v-btn>
                </template>
              </v-select>
            </v-col>
            <v-col :cols="12" :md="4" class="pb-0">
              <DatePicker
                v-model:startDate="startDate"
                v-model:endDate="endDate"
                opens="center"
                range
                label="Період" />
            </v-col>
          </v-row>
        </div>
      </v-fade-transition>
      <div>
        <ActivitiesView
          :activityView="1"
          :events="filteredActivities"
          :tHead="[
            { title: 'Назва', key: 'title', sortable: false, align: 'start' },
            {
              title: 'Дата зустрічі',
              key: 'startDate',
              align: 'start',
              sortable: false,
              width: '160px',
            },
            {
              title: 'Стан',
              key: 'customStatus',
              sortable: false,
              align: 'start',
            },
          ]">
        </ActivitiesView>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from '@/components/DatePicker.vue'
import ActivitiesView from '@/components/AcvititiesView.vue'
import Loader from '@/components/Loader.vue'
import { mapState } from 'vuex'
import { urlGetContactActivities } from '@/pages/request'
import SectionLoader from '@/components/section-loader.vue'
import { useSelect } from '@/utils/mixins/useSelect'
export default {
  components: {
    SectionLoader,
    Loader,
    ActivitiesView,
    DatePicker,
  },
  setup() {
    return {
      ...useSelect(),
    }
  },
  data: () => ({
    activities: [],
    filter: false,
    statusId: null,
    startDate: null,
    endDate: null,
    requestDone: false,
    currentTab: 1,
    loading: false,
  }),
  computed: {
    ...mapState({ selectItems: state => state.selectItems }),
    filteredActivities() {
      const ISODate = date => {
        const [d, m, y] = date.substring(0, 10).split('.')
        return `${y}.${m}.${d}`
      }
      const statusFilter = a =>
        this.statusId ? a.status.id === this.statusId : true
      const dateStartFilter = a =>
        this.startDate && this.startDate.length >= 10
          ? new Date(ISODate(a.startDate)) >= new Date(ISODate(this.startDate))
          : true
      const dateEndFilter = a =>
        this.endDate && this.endDate.length >= 10
          ? new Date(ISODate(a.endDate)) <= new Date(ISODate(this.endDate))
          : true
      return this.activities.filter(
        a => statusFilter(a) && dateStartFilter(a) && dateEndFilter(a)
      )
    },
  },
  methods: {
    closeFilter() {
      this.filter = !this.filter
      this.statusId = null
      this.startDate = null
      this.endDate = null
    },
    getContactActivities() {
      this.loading = true
      return this.$axios
        .get(urlGetContactActivities(this.$route.params.id))
        .then(res => this.activities.push(...res.data), (this.loading = false))
        .catch(this.$err)
    },
  },
  mounted() {
    this.getContactActivities()
  },
}
</script>

<style scoped></style>
