<template>
  <v-row>
    <v-col style="margin-top: -15px !important" :cols="12" :sm="12">
      <v-row>
        <v-col :cols="12" :md="4">
          <span class="subtitle">Прізвище</span>
          <v-text-field
            v-model="leadData.surname"
            placeholder="введіть прізвище"
            :readonly="readonly">
          </v-text-field>
        </v-col>
        <v-col :cols="12" :md="4">
          <span class="subtitle">Імʼя</span>
          <v-text-field
            v-model="leadData.name"
            placeholder="введіть імʼя"
            :readonly="readonly"
            :error-messages="nameErr"
            @blur="$v.leadData.name.$touch()">
          </v-text-field>
        </v-col>
        <v-col :cols="12" :md="4">
          <span class="subtitle">По батькові</span>
          <v-text-field
            v-model="leadData.patronymic"
            placeholder="введіть по батькові"
            :readonly="readonly">
          </v-text-field>
        </v-col>
      </v-row>
      <v-row style="margin-top: -20px">
        <v-col :cols="12" :md="4" class="pt-0 pb-0">
          <span class="subtitle">Телефон</span>
          <v-text-field
            id="phoneInput"
            v-model="leadData.phone"
            placeholder="введіть телефон"
            :error-messages="phoneErr"
            :readonly="readonly"
            @input="phoneMask($event, 'phoneInput')"
            @blur="
              () => {
                leadData.phone &&
                  leadData.phone.length === 6 &&
                  (leadData.phone = null)
                $v.leadData.phone.$touch()
              }
            ">
          </v-text-field>
        </v-col>
        <v-col :cols="12" :md="4" class="pt-0 pb-0">
          <span class="subtitle">Email</span>
          <v-text-field
            v-model="leadData.email"
            v-mask:[emailMask]
            placeholder="введіть email"
            :error-messages="emailErr"
            :readonly="readonly"
            @blur="$v.leadData.email.$touch()">
          </v-text-field>
        </v-col>
        <v-col
          :cols="12"
          :md="4"
          class="d-flex align-center"
          style="position: relative">
          <span class="text__link" @click="() => (dialog = true)">
            Деталі
          </span>
          <div v-if="dialog" class="dialog leadDetailsMobileDialog">
            <div class="dialogWrapper">
              <v-btn class="dialogButton" icon @click="() => (dialog = false)">
                <svg
                  width="18"
                  height="19"
                  viewBox="0 0 18 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M11.7693 12.2692L6.23084 6.73072M6.23084 12.2692L11.7693 6.73072M17.3078 9.49995C17.3078 4.91341 13.5866 1.19226 9.00007 1.19226C4.41354 1.19226 0.692383 4.91341 0.692383 9.49995C0.692383 14.0865 4.41354 17.8076 9.00007 17.8076C13.5866 17.8076 17.3078 14.0865 17.3078 9.49995Z"
                    stroke="#C0C1C7"
                    stroke-linecap="round" />
                </svg>
              </v-btn>
              <OtherTable :otherInfo="lead.other" :source="lead.sourceName" />
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row class="mt-5">
        <div class="projectCard">
          <CommentIcon style="margin-right: 5px" />
          <span class="projectTitle">Коментар:</span>
        </div>
      </v-row>
      <v-row style="margin-top: -20px !important">
        <v-col>
          <Comments
            :comments="leadData.comments"
            :label="false"
            :entityTypeId="4"
            :entityId="leadData.id"
            :readonly="readonly" />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import CommentIcon from '@/assets/svg/comment-icon.vue'
import Comments from '@/components/comments/Index.vue'
import { mapState, mapGetters } from 'vuex'
import { phoneMask } from '@/utils/masks'
import { emailMask } from 'best-modules/utils'
import {
  setErrHandler,
  getResponsibleShortName,
  toFormatDate,
} from '@/utils/helperFunc'
import {
  urlCreateLeadComment,
  urlEditLeadComment,
  urlDeleteLeadComment,
} from '@/pages/request'
import OtherTable from '@/pages/leads/components/otherTable.vue'
export default {
  components: {
    OtherTable,
    Comments,
    CommentIcon,
  },
  props: {
    lead: { type: Object },
    v: { type: Object },
    readonly: { type: Boolean, default: false },
  },
  data: () => {
    return {
      loading: false,
      dialog: false,
      emailMask,
    }
  },
  computed: {
    ...mapState({
      clientTypes: state => state.selectItems.clientTypes,
      clientNeeds: state => state.selectItems.clientNeeds,
      user: state => state.user,
    }),
    ...mapGetters({
      activeUsers: 'activeUsers',
    }),
    $v() {
      return this.v
    },
    leadData() {
      return this.lead
    },
    nameErr() {
      return setErrHandler(this.$v.leadData.name)
    },
    phoneErr() {
      return setErrHandler(this.$v.leadData.phone)
    },
    emailErr() {
      return setErrHandler(this.$v.leadData.email)
    },
  },
  methods: {
    phoneMask,
    toFormatDate,
    setErrHandler,
    urlCreateLeadComment,
    urlEditLeadComment,
    urlDeleteLeadComment,
    getResponsibleShortName,
    async addComment({ comment }) {
      this.loading = true
      const req = {
        comment: comment,
        leadId: this.leadData.id,
      }
      const { data } = await this.$axios.post(this.urlCreateLeadComment(), req)
      const comments = this.leadData.comments
      comments.push({ ...data, user: this.user })
      this.$store.commit('setLeadComments', comments)
      this.loading = false
    },
    async editComment(comment) {
      this.loading = true
      const { data } = await this.$axios.post(
        this.urlEditLeadComment(comment.id),
        comment
      )
      this.$store.commit(
        'setLeadComments',
        this.leadData.comments.map(c => (c.id === data.id ? data : c))
      )
      this.loading = false
    },
    async deleteComment(comment) {
      this.loading = true
      await this.$axios.delete(this.urlDeleteLeadComment(comment.id))
      this.$store.commit(
        'setLeadComments',
        this.leadData.comments.filter(c => c.id !== comment.id)
      )
      this.loading = false
    },
  },
}
</script>

<style lang="scss" scoped>
.border-right {
  border-right: 1px solid #ccc;
}
.field-link:hover {
  cursor: pointer !important;
}

.text-field {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.text-title {
  color: #1c1d21;
  font-weight: 600;
  font-size: 17px;
  margin-right: 6px;
  display: block;
}
.text-desc {
  font-weight: 400;
  font-size: 16px;
  color: #1c1d21;
  display: block;
}
.subtitle {
  color: #838282;
  font-weight: 500;
  font-size: 13px;
  display: block;
  margin-bottom: 5px;
}

.text__link {
  cursor: pointer;
  color: #08487a;
  text-decoration-line: underline;
  font-weight: 500;
  font-size: 12px;
}

.projectCard {
  display: flex;
  align-items: center;
  padding: 0 12px;
}

.projectBlock {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  width: 100% !important;
}
.projectTitle {
  display: block;
  color: #08487a;
  font-weight: 600;
  font-size: 16px;
}

.dialog {
  position: absolute;
  background: white;
  left: -500px;
  right: 50px;
  top: 0;
  z-index: 1000000;
  border: 1px solid #d1d1d1;
}

.dialogWrapper {
  padding: 15px;
  position: relative;
  padding-top: 30px;
}

.dialogButton {
  position: absolute;
  right: 0;
  top: 0;
}
</style>
