<template>
  <div>
    <v-fade-transition hide-on-leave>
      <div>
        <Filters :filters="filters" class="ml-2">
          <template #header-action>
            <v-btn
              data-readonly="true"
              variant="flat"
              color="#fc7247"
              class="mr-3"
              @click="createMarketingCompany()">
              <v-icon style="margin-right: 5px">
                {{ 'mdi-plus' }}
              </v-icon>
              Створити&nbsp;
            </v-btn>
          </template>
        </Filters>
        <v-row>
          <v-col v-show="!pageLoad" :cols="12" class="pr-0">
            <v-data-table
              :headers="marketingListHeaders"
              :items="tBody"
              class="calc-table"
              mobileBreakpoint="1200"
              :hide-default-header="!tBody.length"
              hide-default-footer
              :items-per-page="-1"
              @dblclick:row="navigateToMarketing">
              <!-- eslint-disable-next-line -->
              <template #item.campaignName="{ item }">
                <ALink
                  :text="item.campaignName"
                  :route="{ name: 'edit-marketing', params: { id: item.id } }">
                </ALink>
              </template>
              <!-- eslint-disable-next-line -->
              <template #item.isActive="{ item }">
                <span> {{ item.isActive ? 'Активна' : 'Неактивна' }} </span>
              </template>
              <!-- eslint-disable-next-line -->
              <template #item.actions="{ item }">
                <TableCrud
                  :actionsArray="[
                    {
                      action: () =>
                        $router.push({
                          name: 'edit-marketing',
                          params: { id: item.id },
                        }),
                      text: 'Переглянути',
                      icon: 'EyeIcon',
                      customIcon: true,
                    },
                    {
                      action: () => handleCompanyMethod(item.id, 'activation'),
                      disabled: item.isActive,
                      text: 'Активувати',
                      icon: 'mdi-check',
                    },
                    {
                      action: () =>
                        handleCompanyMethod(item.id, 'deactivation'),
                      disabled: !item.isActive,
                      text: 'Деактивувати',
                      icon: 'mdi-close',
                    },
                  ]">
                </TableCrud>
              </template>
            </v-data-table>
            <Pagination
              :getItems="getMarketingCompany"
              :trigger="paginationTrigger" />
          </v-col>
        </v-row>
      </div>
    </v-fade-transition>
    <SectionLoader
      v-if="
        asyncLoading('handleCompanyMethod') && routeMarketingList
      "></SectionLoader>
    <RouterView :key="$route.params.id"></RouterView>
  </div>
</template>
<script>
import SectionLoader from '@/components/section-loader.vue'
import ALink from '@/components/Link.vue'
import Pagination from '@/components/Pagination.vue'
import {
  urlGetMarketingList,
  urlDeactivateMarketingCompany,
} from '@/pages/request'
import { toFormatDate } from '@/utils/helperFunc'
import { mapState } from 'vuex'
import {
  backDate,
  tableDateSort,
  getContactInitials,
  getResponsibleShortName,
} from '@/utils/helperFunc'
import Filters from '@/components/Filters.vue'
import { useSelect } from '@/utils/mixins/useSelect'
import { useFilter } from '@/utils/mixins/useFilter'
import { reactive } from 'vue'
import TableCrud from '@/components/table-crud.vue'
import { marketingListHeaders } from '@/utils/tableHeaders'
export default {
  components: {
    TableCrud,
    Filters,
    SectionLoader,
    ALink,
    Pagination,
  },
  setup() {
    const filters = reactive({
      search: { value: null },
    })
    return {
      ...useSelect(),
      ...useFilter(filters),
      filters,
    }
  },
  data() {
    return {
      marketingListHeaders,
      pageLoad: false,
      tBody: [],
    }
  },
  computed: {
    ...mapState({ selectItems: state => state.selectItems }),
    routeMarketingList() {
      return this.$route.name === 'marketing'
    },
  },
  methods: {
    backDate,
    tableDateSort,
    getContactInitials,
    getResponsibleShortName,
    toFormatDate,
    getMarketingCompany(page = 1) {
      this.pageLoad = true
      return this.$axios
        .get(urlGetMarketingList(page, this.filtersQueryObj))
        .then(res => {
          this.tBody.splice(0)
          this.tBody.push(...res.data.data)
          return res
        })
        .finally(() => (this.pageLoad = false))
        .catch(this.$err)
    },
    createMarketingCompany() {
      this.$store.commit('setDialog', {
        title: '🧰 Створення рекламної компанiї',
        dialogItem: {},
        params: {
          submitText: 'Створити',
          dialogWidth: 600,
          cb: () => {
            this.getMarketingCompany()
            this.pageLoad = false
          },
        },
        action: 'createMarketingCompany',
      })
    },
    handleCompanyMethod(id, action) {
      this.pageLoad = true
      const nowDate = new Date()
      const customDate =
        nowDate.getFullYear() +
        '-' +
        (nowDate.getMonth() + 1).toString().padStart(2, '0') +
        '-' +
        nowDate.getDate().toString().padStart(2, '0')
      const req = {
        isActive: action === 'activation',
        date: customDate,
      }
      this.$axios.post(urlDeactivateMarketingCompany(id), req).then(() => {
        this.getMarketingCompany()
        this.pageLoad = false
      })
    },
    navigateToMarketing(e, row) {
      this.$router.push({ name: 'edit-marketing', params: { id: row.item.id } })
    },
  },
}
</script>
<style lang="scss">
.v-list-item__content {
  padding-left: 15px !important;
}
.container.container--fluid {
  padding-left: 10px !important;
  padding-right: 10px !important;
  margin: 0 auto !important;
}
</style>
